import { handleActions } from 'redux-actions';

const initialState = {
  success: null,
};

const documentUploadReducer = handleActions(
  {
    DOCUMENT_FILE_UPLOAD_COMPLETED: () => ({ success: true }),
    DOCUMENT_FILE_UPLOAD_FAILED: () => ({ success: false }),
    DOCUMENT_FILE_UPLOAD_RESET_STATE: () => ({ success: null }),
  },
  initialState
);

export default documentUploadReducer;
