import { Effect, call, select, takeLatest } from 'redux-saga/effects';

import auditActions from '../actions/audit';
import { isCreditusUser } from '../utils/permissions';
import runner from './runners';

function* fetchAuditApplications() {
  const { userData }: { userData: IBankUserData } = yield select(
    (state: any) => state.auth
  );
  const url = isCreditusUser(userData)
    ? '/api/audit'
    : `/api/banks/${userData.organization.bankId}/audit`;

  yield call(runner, auditActions.creators.fetchAuditApplications, {
    method: 'GET',
    url,
  });
}

function* watchFetchAuditApplications(): Iterator<Effect> {
  yield takeLatest(
    auditActions.types.FETCH_AUDIT_APPLICATIONS.REQUESTED,
    fetchAuditApplications
  );
}

export default {
  watchFetchAuditApplications,
};
