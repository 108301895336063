export default {
  applicationId: {
    required: 'El ID de la socilitud es un campo obligatorio.',
  },
  productId: {
    typeError: 'Selecciona un producto de la lista.',
    required: 'El ID del producto es un campo obligatorio.',
  },
  creditAmount: {
    typeError: 'El monto del crédito debe ser un número.',
    required: 'El monto del crédito es un campo obligatorio.',
  },
  numberPayments: {
    typeError: 'El número de pagos debe ser un número.',
    required: 'El número de pagos es un campo obligatorio.',
  },
  payment: {
    typeError: 'El pago mensual debe ser un número.',
    required: 'El pago mensual es un campo obligatorio.',
  },
  downpayment: {
    typeError: 'El enganche debe ser un número.',
    required: 'El enganche es un campo obligatorio.',
  },
  cat: {
    typeError: 'El CAT debe ser un número.',
    required: 'El CAT es un campo obligatorio.',
  },
};
