import { Formik, FormikProps } from 'formik';
import { isNil } from 'ramda';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import banksActions from '../../actions/banks';
import colors from '../../constants/colors';
import Success from '../../resources/success.png';
import Button from '../Button';
import { FormikDate } from '../FormikInputs';
import LoadingIndicator from '../LoadingIndicator';
import Modal from '../Modal';

const ButtonContainer = styled.div`
  margin: 0px 4px;
`;

const ControlContainer = styled.div`
  display: flex;
  height: 38px;
  justify-content: flex-end;
`;

const DescriptionText = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  text-align: justify;
  padding-bottom: 24px;
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-bottom: 16px;
`;

const ResponseMessage = styled.div`
  display: flex;
  justify-content: center;
`;

const SuccessIcon = styled.img`
  height: 24px;
  margin-right: 8px;
`;

interface IModifyOfferVigencyForm {
  showLoading: boolean;
  success: boolean;
  showModal: boolean;
  formikProps: FormikProps<{ newVigency: string }>;
  closeModal: () => void;
}

const ModifyOfferVigencyForm: React.FunctionComponent<IModifyOfferVigencyForm> = ({
  showLoading,
  success,
  showModal,
  formikProps,
  closeModal,
}) => {
  const renderContent = (): JSX.Element => {
    if (showLoading && isNil(success)) {
      return (
        <LoadingIndicatorContainer>
          <LoadingIndicator />
        </LoadingIndicatorContainer>
      );
    } else if (success) {
      return (
        <ResponseMessage>
          <SuccessIcon src={Success} />
          Fecha cambiada correctamente
        </ResponseMessage>
      );
    } else {
      return (
        <Fragment>
          <DescriptionText>
            Ingresa la nueva fecha de vigencia de la oferta
          </DescriptionText>
          <FormikDate
            label="Fecha de vigencia"
            name="newVigency"
            ignoreDay={false}
          />
        </Fragment>
      );
    }
  };

  const renderControls = (): JSX.Element => (
    <ControlContainer>
      <ButtonContainer>
        <Button variant="secondary" onClick={closeModal}>
          {isNil(success) ? 'Cancelar' : 'Cerrar'}
        </Button>
      </ButtonContainer>
      {isNil(success) ? (
        <ButtonContainer>
          <Button
            variant={!showLoading ? 'primary' : 'inactive'}
            onClick={() => formikProps.submitForm()}
          >
            Aceptar
          </Button>
        </ButtonContainer>
      ) : null}
    </ControlContainer>
  );

  return (
    <Modal
      content={renderContent}
      controls={renderControls}
      header="Modificar Vigencia"
      isOpen={showModal}
      onRequestClose={() => {
        formikProps.resetForm();
        closeModal();
      }}
      shouldCloseOnOverlayClick={false}
      showCloseIcon={false}
      width="400px"
    />
  );
};

interface IModifyOfferVigencyModal {
  showLoading: boolean;
  success: boolean;
  offer: IOffer;
  showModal: boolean;
  closeModal: () => void;
  updateOfferVigency: Function;
}

const dateMoreThanToday = (date: Date): boolean => new Date() < date;

const validationSchema = Yup.object({
  newVigency: Yup.date()
    .typeError('Ingresa una fecha válida')
    .required('Introduce una fecha')
    .test(
      'moreThanToday',
      'La fecha no debe ser menor a la de hoy',
      (date: Date) => dateMoreThanToday(date)
    ),
});

const ModifyOfferVigencyModal: React.FunctionComponent<IModifyOfferVigencyModal> = ({
  showLoading,
  success,
  offer,
  showModal,
  closeModal,
  updateOfferVigency,
}) => {
  return (
    <Formik
      initialValues={{
        newVigency: offer.finalOffer
          ? offer.finalOffer.dateVigency
          : offer.dateVigency,
      }}
      onSubmit={values =>
        updateOfferVigency({
          newVigency: new Date(values.newVigency).toISOString(),
          offerId: offer.id,
        })
      }
      render={formikProps => (
        <ModifyOfferVigencyForm
          closeModal={closeModal}
          formikProps={formikProps}
          showModal={showModal}
          showLoading={showLoading}
          success={success}
        />
      )}
      validationSchema={validationSchema}
    />
  );
};

const mapStateProps = (
  state: any
): { success: boolean; showLoading: boolean } => ({
  success: state.entities.banks.updateOfferVigency.success,
  showLoading: state.entities.banks.updateOfferVigency.showLoading,
});

const creators = {
  updateOfferVigency: banksActions.creators.banksUpdateOfferVigency.request,
};

export default connect(mapStateProps, creators)(ModifyOfferVigencyModal);
