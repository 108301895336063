import { isEmpty } from 'ramda';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import clientUsersActions from '../../actions/clientUsers';
import colors from '../../constants/colors';
import f from '../../constants/formatters';
import Button from '../Button';
import Card from '../Card';
import LoadingState from '../LoadingState';

const { REACT_APP_PUBLIC_ASSETS_URL } = process.env;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const OfferContainer = styled.div`
  width: 100%;
  padding: 20px 10px 35px 10px;
`;

const OfferAmountAndBankLogoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
`;

const OfferBankImage = styled.img`
  width: 70%;
  margin: auto;
`;

const OfferAmount = styled.div`
  margin: 20px auto auto auto;
  font-size: 24px;
  font-weight: bolder;
  justify-content: center;
`;

const OfferAmountText = styled.div`
  font-size: 12px;
  color: ${colors.DUSK75};
  margin: auto;
`;

const OfferInfoContainer = styled.div`
  display: flex;
  line-height: 1.57;
  padding: 0 24px;
  min-height: 40px;
  word-break: break-word;
  padding 0 25px 0 25px;
`;

const OfferInfoDescription = styled.div`
  width: 50%;
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  color: ${colors.DUSK75};
`;

const OfferInfoValue = styled.div`
  font-weight: bold;
  font-size: 14px;
  width: 50%;
  text-align: right;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const OfferTermsDescription = styled.div`
  text-align: center;
  font-size: 12px;
  margin: 0 10%;
  padding-bottom: 15px;
  color: ${colors.DUSK75};
`;

const Separator = styled.div`
  padding-top: 20px;
  width: 100%;
  background-color: ${colors.BACKGROUND};
`;

interface IClientApplicationOffers {
  offer: IOffer;
}

const ClientApplicationOffers: React.FunctionComponent<IClientApplicationOffers> = ({
  offer,
}) => {
  return (
    <Fragment>
      <OfferContainer>
        <OfferAmountAndBankLogoContainer>
          <OfferBankImage
            src={`${REACT_APP_PUBLIC_ASSETS_URL}${offer.bank?.logo}`}
          />
          <OfferAmount>{f.currency(offer.amount)}</OfferAmount>
          <OfferAmountText>Monto</OfferAmountText>
        </OfferAmountAndBankLogoContainer>
        <OfferInfoContainer>
          <OfferInfoDescription>Enganche</OfferInfoDescription>
          <OfferInfoValue>{f.currency(offer.downpayment)}</OfferInfoValue>
        </OfferInfoContainer>
        <OfferInfoContainer>
          <OfferInfoDescription>Pago Periodico</OfferInfoDescription>
          <OfferInfoValue>{f.currency(offer.fullPayment)}</OfferInfoValue>
        </OfferInfoContainer>
        <OfferInfoContainer>
          <OfferInfoDescription>Plazo</OfferInfoDescription>
          <OfferInfoValue>{offer.requestPeriod}</OfferInfoValue>
        </OfferInfoContainer>
        <OfferInfoContainer>
          <OfferInfoDescription>Tasa/CAT</OfferInfoDescription>
          <OfferInfoValue>
            {`${f.percentage(
              Number.parseFloat(offer.interestRate) * 100
            )} / ${f.percentage(Number.parseFloat(offer.cat) * 100)}`}
          </OfferInfoValue>
        </OfferInfoContainer>
        <OfferTermsDescription>
          La presente preautorización está sujeta a cambios dependiendo de la
          evaluación de la institución financiera seleccionada.
        </OfferTermsDescription>
        <ButtonContainer>
          <Link to={`/offer/${offer.id}`}>
            <Button width={'140px'}>Detalles</Button>
          </Link>
        </ButtonContainer>
      </OfferContainer>
    </Fragment>
  );
};

interface IClientApplicationDetailsView {
  applicationId: number;
  clientApplications?: IOfferedApplication[];
  fetchClientApplications: Function;
  history: RouteComponentProps['history'];
}

const ClientApplicationDetailsView: React.FunctionComponent<IClientApplicationDetailsView> = ({
  applicationId,
  clientApplications,
  fetchClientApplications,
  history,
}) => {
  useEffect(() => {
    if (!clientApplications || isEmpty(clientApplications)) {
      fetchClientApplications();
    }
  }, [clientApplications, fetchClientApplications]);

  const offers = clientApplications ? (
    <Fragment>
      {clientApplications
        .filter(clientApplication => {
          return clientApplication.id === applicationId;
        })[0]
        .offers.map(offer => {
          return (
            <Fragment key={offer.id}>
              <ClientApplicationOffers offer={offer} />
              <Separator />
            </Fragment>
          );
        })}
    </Fragment>
  ) : (
    <LoadingState />
  );

  return (
    <Fragment>
      <ControlsContainer>
        <Button onClick={() => history.goBack()} variant="secondary">
          Regresar
        </Button>
      </ControlsContainer>
      <Card header="Preautorizaciones">{offers}</Card>
    </Fragment>
  );
};

const mapStateToProps = (
  state: any
): { clientApplications: IOfferedApplication[] } => ({
  clientApplications: state.entities.clientUsers.clientApplications,
});

const creators = {
  fetchClientApplications:
    clientUsersActions.creators.fetchClientApplications.request,
};

export default connect(mapStateToProps, creators)(ClientApplicationDetailsView);
