import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 96 96';

const SendMessageIcon = ({ fill }: { fill: string }) => (
  <polygon
    points="20.5,71.6 75.5,48 20.5,24.4 20.5,42.8 59.8,48 20.5,53.2"
    fill={fill}
  />
);

export default BaseIcon(SendMessageIcon, viewBox);
