import axios from 'axios';

const { REACT_APP_API_HOST: HOST } = process.env;

const getBanks = (financeType: string) => {
  const searchParams = new URLSearchParams(window.location.search);
  const params: Record<string, any> = {};
  params['financeType'] = financeType;
  if (searchParams.get('promoterUserCode')) {
    params['promoterUserCode'] = searchParams.get('promoterUserCode');
  }
  return axios.get(`${HOST}/api/application-details/banks`, {
    method: 'GET',
    withCredentials: true,
    params: params,
  });
};

export { getBanks };
