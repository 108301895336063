import isNil from 'ramda/src/isNil';
import isEmpty from 'ramda/src/isNil';

import { IProfilingFormComplete } from '../components/ProfilingSectionsForms';

interface ICosigner {
  name: string;
  firstSurname: string;
  secondSurname: string;
  sex: string;
  dateOfBirth: Date;
  countryOfBirth: string;
  nationality: string;
  stateOfBirth: string;
  curp: string;
  taxPayerId: string;
  taxPayerIdComplement: string;
  educationLevel: string;
  maritalStatus: string;
  matrimonialRegime: string;
}

interface ISpouse {
  name: string;
  firstSurname: string;
  secondSurname: string;
  sex: string;
  dateOfBirth: Date;
  countryOfBirth: string;
  nationality: string;
  stateOfBirth: string;
  curp: string;
  taxPayerId: string;
  taxPayerIdComplement: string;
  educationLevel: string;
}

interface IThirdParties {
  cosigner: ICosigner | null;
  spouse: ISpouse | null;
}

interface ICosignerAddress {
  street: string;
  exteriorNumber: string;
  suiteNumber: string;
  postalCode: string;
  neighborhood: string;
  municipality: string;
  city: string;
  state: string;
  country: string;
}

interface IAddress {
  street: string;
  exteriorNumber: string;
  suiteNumber: string;
  postalCode: string;
  neighborhood: string;
  municipality: string;
  city: string;
  state: string;
  country: string;
  cosignerAddress: ICosignerAddress | null;
}

interface IClientEmployment {
  companyName: string;
  industryType: string;
  activityType: string;
  sector: string;
  position: string;
  street: string;
  exteriorNumber: string;
  suiteNumber: string;
  postalCode: string;
  neighborhood: string;
  municipality: string;
  city: string;
  state: string;
  country: string;
  phoneNumber: string;
}

interface IPreviousEmployment {
  companyName: string;
  startDate: Date;
  endDate: Date;
  phoneNumber: string;
}

interface ICosignerEmployment {
  companyName: string;
  activityType: string;
  position: string;
  street: string;
  exteriorNumber: string;
  suiteNumber: string;
  postalCode: string;
  neighborhood: string;
  municipality: string;
  city: string;
  state: string;
  country: string;
  sector: string;
}

interface IEmployment {
  employment: IClientEmployment | null;
  previousEmployment: IPreviousEmployment | null;
  cosignerEmployment: ICosignerEmployment | null;
}

interface IReference {
  name: string;
  firstSurname: string;
  secondSurname: string;
  phoneNumber: string;
  relation: string;
}

interface IReferences {
  references: IReference[];
}

interface ISend {
  consent: boolean;
  nip: string;
  granters: number[];
  dateConsulting: string;
  productType: string;
}

interface IApplicationForm {
  thirdParties: IThirdParties | null;
  address: IAddress;
  employment: IEmployment | null;
  references: IReferences;
  send: ISend;
}

interface IAccount {
  clientId: number;
  verificationId: number;
}

interface IAccountFormValues {
  name: string;
  firstSurname: string;
  secondSurname: string;
  dateOfBirth: Date;
  sex: string;
  countryOfBirth: string;
  nationality: string;
  stateOfBirth: string;
  curp: string;
  taxPayerId: string;
  taxPayerIdComplement: string;
  password: string;
  passwordConfirmation: string;
  acceptsTermsAndConditionsAndPrivacyPolicy: boolean;
  email: string;
  phoneNumber: string;
  biometricVerificationId: number;
}

interface IAccountCreation {
  account: IAccount;
  accountFormValues: IAccountFormValues;
}

interface IApplicationCreation {
  offers?: any;
}

interface IPrefillingAddress {
  street: string;
  interiorNumber: string;
  exteriorNumber: string;
  neighborhood: string;
  postalCode: string;
  district: string;
  state: string;
}

interface IPrefillingData {
  firstSurname: string;
  secondSurname: string;
  name: string;
  dateOfBirth: string;
  stateOfBirth: string;
  countryOfBirth: string;
  nationality: string;
  sex: string;
}

interface IVerificationModal {
  biometricVerificationId: number;
  verificationCompleted: number;
  curp: string;
  address: IPrefillingAddress;
  prefillingData: IPrefillingData;
}

interface IPrevSteps {
  accountCreation: IAccountCreation;
  accountVerificationSucceded: boolean;
  accountDataNotInUse: boolean;
  applicationCreation: IApplicationCreation;
  profiling: IProfilingFormComplete;
  verificationModal: IVerificationModal;
  existentClient: IExistentClient;
}

interface IExistentClient {
  clientAccount: IUser;
  complianceId: number;
  nipVerified: boolean;
}

interface IRootApplicationForm {
  applicationForm: IApplicationForm;
  prevSteps: IPrevSteps;
}

const toIsoString = (date: string | Date) =>
  typeof date === 'string' ? date : date.toISOString();

export const generatePayload = (form: IRootApplicationForm) => {
  const { applicationForm, prevSteps } = form;
  const {
    thirdParties,
    address,
    employment,
    references,
    send,
  } = applicationForm;
  const { accountCreation, profiling, existentClient } = prevSteps;
  const {
    address: profilingAddress,
    applicant: profilingApplicant,
    contact: profilingContact,
    cosigner: profilingCosigner,
    employment: profilingEmployment,
    product: profilingProduct,
  } = profiling;
  const { account } = accountCreation;
  const { cosignerAddress, ...clientAddress } = address;

  const isExistentUser = profilingApplicant.existentUser;

  const hasSpouse =
    profilingApplicant.maritalStatus === 'married' &&
    !isNil(thirdParties) &&
    !isNil(thirdParties.spouse);
  const hasCosigner =
    profilingApplicant.hasCosigner &&
    !profilingCosigner.spouseAsCosigner &&
    !isNil(thirdParties) &&
    !isNil(thirdParties.cosigner);
  const hasSpouseAsCosigner =
    profilingApplicant.hasCosigner && profilingCosigner.spouseAsCosigner;
  const hasCosignerAddress =
    (hasCosigner || hasSpouseAsCosigner) &&
    !profilingCosigner.sameAddressAsSolicitant &&
    !isNil(cosignerAddress);
  const hasRentAddress = profilingAddress.homeType === 'rent';
  const hasEmployment =
    !isNil(employment) &&
    !isNil(employment.employment) &&
    ['employed', 'independent'].includes(profilingEmployment.employmentStatus);
  const hasContract =
    !isNil(employment) &&
    !isNil(employment.employment) &&
    profilingEmployment.employmentStatus === 'employed';
  const hasPreviousEmployment =
    !isNil(employment) &&
    !isNil(employment.previousEmployment) &&
    profilingEmployment.previousEmployment;
  const hasFixedIncome = ['retired', 'employed', 'independent'].includes(
    profilingEmployment.employmentStatus
  );
  const hasOtherIncome = profilingEmployment.sourceOtherIncome !== 'no_apply';
  const hasCosignerFixedIncome =
    (hasCosigner || hasSpouseAsCosigner) &&
    ['retired', 'employed', 'independent'].includes(
      profilingCosigner.employmentStatus
    );
  const hasCosignerEmployment =
    (hasCosigner || hasSpouseAsCosigner) &&
    !isNil(employment) &&
    !isNil(employment.cosignerEmployment) &&
    ['employed', 'independent'].includes(profilingCosigner.employmentStatus);

  const payload = {
    flags: {
      isExistentUser,
      hasSpouse,
      hasSpouseAsCosigner,
      hasCosigner,
      hasCosignerFixedIncome,
      hasCosignerAddress,
      hasContract,
      hasRentAddress,
      hasEmployment,
      hasPreviousEmployment,
      hasFixedIncome,
      hasOtherIncome,
      hasCosignerEmployment,
    },
    clientId: isExistentUser
      ? existentClient.clientAccount.id
      : account.clientId,
    complianceId: isExistentUser
      ? existentClient.complianceId
      : account.verificationId,
    nip: send.nip,
    dateConsulting: send.dateConsulting,
    application: {
      financeType: profilingProduct.productType,
      banks: send.granters,
      product: {
        vehicleCondition: profilingProduct.vehicleType,
        downpayment: profilingProduct.downpayment,
        price: profilingProduct.price,
        manufacturerId: profilingProduct.brand,
        vehicleId: profilingProduct.version,
        model: profilingProduct.model,
        term: profilingProduct.term,
        insuranceType: profilingProduct.insurance,
      },
    },
    personalData: {
      maritalStatus: profilingApplicant.maritalStatus,
      matrimonialRegime:
        !hasSpouse || isEmpty(profilingApplicant.regime)
          ? 'no_apply'
          : profilingApplicant.regime,
      educationLevel: profilingApplicant.academicDegree,
      email: profilingContact.email,
      phoneNumber: profilingContact.phoneNumber,
    },
    thirdParties: {
      hasSpouse,
      spouse: hasSpouse
        ? {
            ...thirdParties!.spouse,
            dateOfBirth: toIsoString(thirdParties!.spouse!.dateOfBirth),
          }
        : null,
      hasCosigner,
      cosigner: hasCosigner
        ? {
            ...thirdParties!.cosigner,
            dateOfBirth: toIsoString(thirdParties!.cosigner!.dateOfBirth),
            matrimonialRegime:
              thirdParties!.cosigner!.maritalStatus !== 'married' ||
              isEmpty(thirdParties!.cosigner!.matrimonialRegime)
                ? 'no_apply'
                : thirdParties!.cosigner!.matrimonialRegime,
          }
        : null,
    },
    address: {
      client: {
        dependentsNumber: profilingAddress.dependents,
        antiquity: toIsoString(profilingAddress.antiquity),
        addressType: profilingAddress.homeType,
        hasRentAddress,
        rent: hasRentAddress ? profilingAddress.rent : null,
        ...clientAddress,
      },
      hasCosignerAddress,
      cosigner: hasCosignerAddress
        ? {
            ...cosignerAddress,
          }
        : null,
    },
    employment: {
      client: {
        employmentType: profilingEmployment.employmentStatus,
        income: profilingEmployment.fixedIncomeQuantity,
        hasOtherIncome,
        otherIncome: hasOtherIncome
          ? {
              source: profilingEmployment.sourceOtherIncome,
              income: profilingEmployment.otherIncomeQuantity,
            }
          : null,
        hasEmployment,
        employment: hasEmployment
          ? {
              hasContract,
              contractType: profilingEmployment.contract,
              startDate: isNil(profilingEmployment.employmentDate)
                ? ''
                : toIsoString(profilingEmployment.employmentDate),
              ...employment!.employment,
            }
          : null,
        hasPreviousEmployment,
        previousEmployment: hasPreviousEmployment
          ? {
              ...employment!.previousEmployment,
              startDate: toIsoString(employment!.previousEmployment!.startDate),
              endDate: toIsoString(employment!.previousEmployment!.endDate),
            }
          : null,
      },
      hasCosignerEmploymentData: hasCosigner || hasSpouseAsCosigner,
      cosigner:
        hasCosigner || hasSpouseAsCosigner
          ? {
              hasCosignerEmployment,
              employmentType: profilingCosigner.employmentStatus,
              income: profilingCosigner.income,
              employment: hasCosignerEmployment
                ? {
                    ...employment!.cosignerEmployment,
                  }
                : null,
            }
          : null,
    },
    references: [
      {
        ...references.references[0],
      },
      {
        ...references.references[1],
      },
    ],
  };

  return payload;
};
