import isNil from 'ramda/src/isNil';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import requestsActions from '../../actions/requests';
import colors from '../../constants/colors';
import { isBankUser } from '../../utils/permissions';
import {
  ApplicationDetailsComponent,
  IApplicationDetails,
  IClientData,
} from '../ApplicationDetailsComponent';
import Button from '../Button';
import DownloadApplicationModal from '../DownloadApplicationModal';
import LoadingIndicator from '../LoadingIndicator';
import SimpleCard from '../SimpleCard';

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const DownloadButtonsContainer = styled.div`
  display: flex;
`;

const DownloadApplicationButtonContainer = styled.div`
  margin-left: 8px;
`;

const LoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 50vh;
  justify-content: center;
  width: 100%;
`;

interface IApplicationDetailsViewProps {
  applicationId: number;
  history: RouteComponentProps['history'];
  getApplicationDetails: Function;
  applicationDetails: IApplicationDetails;
  userData: IUserData;
}

const { REACT_APP_API_HOST: HOST } = process.env;

const getFullName = ({ name, firstSurname, secondSurname }: IClientData) =>
  `${name} ${firstSurname} ${secondSurname}`;

const ApplicationDetailsView: React.FunctionComponent<IApplicationDetailsViewProps> = ({
  history,
  applicationId,
  applicationDetails,
  getApplicationDetails,
  userData,
}) => {
  const [
    isDownloadApplicationModalOpen,
    setDownloadApplicationModalOpen,
  ] = useState(false);

  useEffect(() => {
    getApplicationDetails({ applicationId });
  }, [applicationId, getApplicationDetails]);

  if (!applicationDetails) {
    return (
      <LoadingState>
        <LoadingIndicator />
      </LoadingState>
    );
  }

  const subtitle = isBankUser(userData)
    ? 'Puedes exportar la información de la solicitud en el botón de descargar.'
    : 'Puedes obtener las imágenes de la identificación oficial en el botón de descargar.';
  const downloadUrl = isBankUser(userData)
    ? `${HOST}/api/application/${applicationId}/csv`
    : `${HOST}/api/application/${applicationId}/id-photos/pdf`;
  const downloadButtonText = isBankUser(userData)
    ? 'Descargar CSV'
    : 'Descargar identificación';
  const downloadApplication = isBankUser(userData) ? null : (
    <Fragment>
      <DownloadApplicationButtonContainer>
        <Button onClick={() => setDownloadApplicationModalOpen(true)}>
          Descargar solicitud
        </Button>
      </DownloadApplicationButtonContainer>
      <DownloadApplicationModal
        applicationId={applicationId}
        isOpen={isDownloadApplicationModalOpen}
        onRequestClose={() => setDownloadApplicationModalOpen(false)}
        productType={
          !isNil(applicationDetails.carFinanceApplication)
            ? 'car_finance'
            : 'motorcycle_finance'
        }
      />
    </Fragment>
  );

  return (
    <Fragment>
      <ControlsContainer>
        <Button onClick={() => history.goBack()} variant="secondary">
          Regresar
        </Button>
        <DownloadButtonsContainer>
          <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
            <Button variant="secondary">{downloadButtonText}</Button>
          </a>
          {downloadApplication}
        </DownloadButtonsContainer>
      </ControlsContainer>
      <SimpleCard
        title={`Solicitud - ${getFullName(applicationDetails.clientData)}`}
        subtitle={subtitle}
      >
        <ApplicationDetailsComponent applicationDetails={applicationDetails} />
      </SimpleCard>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  applicationDetails: state.entities.applicationDetails,
});

const creators = {
  getApplicationDetails:
    requestsActions.creators.fetchApplicationDetails.request,
};

export default connect(mapStateToProps, creators)(ApplicationDetailsView);
