import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import React from 'react';

import FormikNumber, { InputMode } from './FormikNumber';

interface IFormikDate {
  label: string;
  name: string;
  inputMode?: InputMode;
  ignoreDay?: boolean;
  disabled?: boolean;
}

const FormikPhone: React.FunctionComponent<IFormikDate> = ({
  label,
  name,
  inputMode = 'text',
  ignoreDay,
  disabled = false,
}) => (
  <FormikNumber<Date, Date>
    disabled={disabled}
    format={ignoreDay ? '##/####' : '##/##/####'}
    formatValue={value => {
      if (isNil(value) || isEmpty(value)) {
        return '';
      }
      return ignoreDay
        ? format(value, 'dd/LL/yyyy').substr(3)
        : format(value, 'dd/LL/yyyy');
    }}
    label={label}
    name={name}
    mask={
      ignoreDay
        ? ['m', 'm', 'a', 'a', 'a', 'a']
        : ['d', 'd', 'm', 'm', 'a', 'a', 'a', 'a']
    }
    parseValue={value =>
      ignoreDay
        ? parse(`01/${value}`, 'dd/LL/yyyy', new Date())
        : parse(value, 'dd/LL/yyyy', new Date())
    }
    placeholder={ignoreDay ? 'mm/aaaa' : 'dd/mm/aaaa'}
    inputMode={inputMode}
  />
);

export default FormikPhone;
