import axios from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import auth from '../actions/auth';
import toastActions from '../actions/toastNotifications';
import {
  buildErrorToast,
  buildWarningToast,
} from '../utils/toastNotifications';
import runner from './runners';

const { REACT_APP_API_HOST: HOST } = process.env;

const addPromoterUserCode = baseUrl => {
  const searchParams = new URLSearchParams(window.location.search);
  const url = `${baseUrl}${
    searchParams.has('promoterUserCode')
      ? `/${searchParams.get('promoterUserCode')}`
      : ''
  }`;
  return url;
};

function* fetchUserData() {
  const url = addPromoterUserCode('/api/account/me');
  yield call(
    runner,
    auth.creators.fetchuserData,
    {
      method: 'GET',
      url: url,
    },
    { snakeCase: false }
  );
}

function* searchForSessionCookies() {
  const url = addPromoterUserCode('/api/account/me');
  yield put(auth.creators.checkSession());
  const { error } = yield call(runner, auth.creators.fetchuserData, {
    method: 'GET',
    url: url,
    withCredentials: true,
    headers: { Accept: 'application/json' },
  });

  if (error) {
    yield put(auth.creators.sessionNotFound(error.response.data));
  } else {
    yield put(auth.creators.sessionValid());
  }
}

function* execLogin({ payload }) {
  const { login } = auth.creators;
  const { email, password, nip } = payload;

  try {
    const data = new FormData();
    data.append('email', email);
    if (password) {
      data.append('password', password);
    } else {
      data.append('nip', nip);
    }

    yield put(login.started());

    const response = yield call(axios, {
      method: 'post',
      url: `${HOST}/auth/login`,
      withCredentials: true,
      data: data,
    });

    yield* fetchUserData();
    yield put(login.completed(response.data));
  } catch (e) {
    yield put(login.failed(e));
  }
}

function* execLogout() {
  yield call(runner, auth.creators.logout, {
    method: 'POST',
    url: '/auth/logout',
  });
}

function* notifyUserLoginFailed(action) {
  const { response } = action.payload;

  if (response.status === 401) {
    const toast = buildErrorToast(
      'El correo electrónico y/o contraseña son incorrectos.',
      5000
    );
    yield put(toastActions.creators.showToast(toast));
  }
}

function* getLoginType(action) {
  const { error } = yield call(runner, auth.creators.getLoginType, {
    method: 'POST',
    url: 'auth/getLoginType',
    payload: action.payload,
  });

  if (error && error.response.status === 401) {
    const toast = buildErrorToast('No se encontró el correo electrónico', 5000);
    yield put(toastActions.creators.showToast(toast));
  }
}

function* watchStartAuthFlow() {
  yield takeLatest(auth.types.AUTH_FLOW_STARTED, searchForSessionCookies);
}

function* watchLoginFailed() {
  yield takeLatest(auth.types.AUTH_LOGIN.FAILED, notifyUserLoginFailed);
}

function* watchLoginRequested() {
  yield takeLatest(auth.types.AUTH_LOGIN.REQUESTED, execLogin);
}

function* watchLogoutRequested() {
  yield takeLatest(auth.types.AUTH_LOGOUT.REQUESTED, execLogout);
}

function* watchSessionExpired() {
  const matchAction = action => {
    if (action.payload && action.payload.response) {
      const { status } = action.payload.response;
      if (status === 401) {
        return true;
      }
    }

    return false;
  };

  const handle = function*() {
    const { userAuthenticated } = yield select(state => state.auth);
    const searchParams = new URLSearchParams(window.location.search);

    if (userAuthenticated && !searchParams.get('promoterUserCode')) {
      try {
        yield call(axios, {
          method: 'get',
          url: `${HOST}/auth/check`,
          withCredentials: true,
          headers: { Accept: 'application/json' },
        });
      } catch (e) {
        yield put(auth.creators.sessionExpired());
        const toast = buildWarningToast(
          'Tu sesión ha expirado. Por favor ingresa de nuevo tus credenciales.',
          7500
        );
        yield put(toastActions.creators.showToast(toast));
      }
    }
  };

  yield takeLatest(matchAction, handle);
}

function* watchGetLoginType() {
  yield takeLatest(auth.types.GET_LOGIN_TYPE.REQUESTED, getLoginType);
}

export default {
  watchStartAuthFlow,
  watchLoginFailed,
  watchLoginRequested,
  watchLogoutRequested,
  watchSessionExpired,
  watchGetLoginType,
};
