import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import { DashboardDateRangeOptions } from '../DashboardView/dashboardDateUtils';

const DashboardPill = styled.div<{ active: boolean }>`
  align-items: center;
  background-color: ${({ active }) =>
    active ? colors.AQUA25 : colors.WHITE50};
  border: solid 1px ${colors.BORDER};
  border-radius: 50px;
  color: ${colors.PRIMARY_TEXT};
  display: flex;
  font-size: 14px;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  height: 30px;
  padding: 8px 12px;
`;

const DashboardPillsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 88px;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;

  & > div {
    margin-right: 4px;
  }
`;

const DashboardPillsLabel = styled.div`
  font-size: 14px;
  padding-left: 8px;
`;

interface IDashboardPills {
  selectedDateRange: DashboardDateRangeOptions;
  onOptionClicked: (range: DashboardDateRangeOptions) => void;
}

const dashboardPillOptions: [DashboardDateRangeOptions, string][] = [
  ['today', 'Ayer vs. Hoy'],
  ['week', 'Semana pasada vs. Semana actual'],
  ['month', 'Mes pasado vs. Mes actual'],
  ['semester', 'Semestre pasado vs. Semestre actual'],
  ['year', 'Año pasado vs. Año Actual'],
];

const DashboardPills: React.FunctionComponent<IDashboardPills> = ({
  onOptionClicked,
  selectedDateRange,
}) => {
  return (
    <DashboardPillsContainer>
      <DashboardPillsLabel>Vistas rápidas:</DashboardPillsLabel>
      {dashboardPillOptions.map((option, i) => (
        <DashboardPill
          key={`${i}`}
          active={selectedDateRange === option[0]}
          onClick={() => onOptionClicked(option[0])}
        >
          {option[1]}
        </DashboardPill>
      ))}
    </DashboardPillsContainer>
  );
};

export default DashboardPills;
