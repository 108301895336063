import { FieldArray, FormikProps } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import colors from '../../constants/colors';
import { FormikPhone, FormikText } from '../FormikInputs';
import { Section } from '../MultiSectionForm';
import errorMsg from './errorMessages';

const Container = styled.div<{ isMobile: boolean }>`
  background-color: ${colors.WHITE};
  ${props => (props.isMobile ? '' : 'display: flex;')}
  height: 100%;
  justify-content: center;
  padding-top: 36px;
  width: 100%;

  & span {
    font-weight: normal;
  }
`;

const Grid = styled.div<{ isMobile: boolean }>`
  ${props =>
    props.isMobile
      ? 'margin: auto; width: 85%;'
      : 'display: grid; grid-column-gap: 24px; grid-template-columns: 280px 280px;'}
`;

const SubSectionName = styled.div<{ isMobile: boolean }>`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: bold;
  ${props =>
    props.isMobile
      ? 'margin: auto; text-align: center; padding-bottom: 10px;'
      : 'width: 128px;'}
`;

const emptyReference = {
  name: '',
  firstSurname: '',
  secondSurname: '',
  phoneNumber: '',
  relation: 'acquaintance',
};

const initialReferenceFormState = {
  references: [{ ...emptyReference }, { ...emptyReference }],
};

const phoneRegExp = /^\d{10}$/;
const referenceSchema = Yup.object().shape({
  name: Yup.string().required(errorMsg.name.required),
  firstSurname: Yup.string().required(errorMsg.firstSurname.required),
  secondSurname: Yup.string().required(errorMsg.secondSurname.required),
  phoneNumber: Yup.string()
    .matches(phoneRegExp, { message: errorMsg.phoneNumber.matches })
    .required(errorMsg.phoneNumber.required),
  relation: Yup.string().required(errorMsg.relation.required),
});

const referenceFormSchema = Yup.object().shape({
  references: Yup.array()
    .of(referenceSchema)
    .required(),
});

interface IReferencesSection {
  isMobile: boolean;
  formikProps: FormikProps<any>;
}

const ReferencesSection: React.FunctionComponent<IReferencesSection> = ({
  isMobile,
  formikProps,
}) => {
  const { values } = formikProps;
  const { references } = values;
  return (
    <FieldArray
      name="references"
      render={() =>
        references.map((reference: any, i: number) => (
          <Container isMobile={isMobile} key={i}>
            <SubSectionName isMobile={isMobile}>
              Referencia {i + 1}
            </SubSectionName>
            <Grid isMobile={isMobile}>
              <FormikText label="NOMBRE" name={`references[${i}].name`} />
              <FormikText
                label="PRIMER APELLIDO"
                name={`references[${i}].firstSurname`}
              />
              <FormikText
                label="SEGUNDO APELLIDO"
                name={`references[${i}].secondSurname`}
              />
              <FormikPhone
                label="TELÉFONO"
                name={`references[${i}].phoneNumber`}
                inputMode="decimal"
              />
            </Grid>
          </Container>
        ))
      }
    />
  );
};

const referencesStateProps = (state: any): { isMobile: boolean } => ({
  isMobile: state.deviceData.isMobile,
});

const ReferencesSectionWithProps = connect(referencesStateProps)(
  ReferencesSection
);

const SectionFormReferences = (
  <Section
    name="references"
    initialValues={initialReferenceFormState}
    sectionSchema={referenceFormSchema}
    component={ReferencesSectionWithProps}
  />
);

export default SectionFormReferences;
