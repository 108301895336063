import { call, takeLatest } from 'redux-saga/effects';

import idVerificationActions from '../actions/idVerification';
import runner from './runners';

function* fetchIdVerifications(action) {
  yield call(
    runner,
    idVerificationActions.creators.fetchBiometricVerifications,
    {
      method: 'GET',
      url: `/api/id-verification`,
    }
  );
}

function* watchFetchIdVerifications() {
  yield takeLatest(
    idVerificationActions.types.FETCH_BIOMETRIC_VERIFICATIONS.REQUESTED,
    fetchIdVerifications
  );
}

function* creatNewValidatedData(action) {
  const { values, biometricVerificationId } = action.payload;
  yield call(
    runner,
    idVerificationActions.creators.createNewValidatedData,
    {
      method: 'POST',
      url: `/api/id-verification/${biometricVerificationId}`,
      payload: values,
    },
    { snakeCase: false }
  );
}

function* watchCreatNewValidatedData() {
  yield takeLatest(
    idVerificationActions.types.CREATE_NEW_VALIDATED_DATA.REQUESTED,
    creatNewValidatedData
  );
}

export default {
  watchFetchIdVerifications,
  watchCreatNewValidatedData,
};
