import { Form, Formik, FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import passwordChangeActions from '../../actions/passwordChange';
import Button from '../Button';
import { FormikText } from '../FormikInputs';

const Grid = styled.div`
  display: grid;
  grid-column-gap: 44px;
  grid-template-columns: repeat(3, minmax(200px, 280px));
  margin-bottom: 12px;
`;

const Padding = styled.div`
  padding: 24px 0;
  padding-left: 30px;
  padding-right: 24px;
`;

interface IPasswordChangeFields {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const initialValues: IPasswordChangeFields = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

const errors = {
  currentPassword: {
    required: 'La contraseña actual es un campo obligatorio.',
  },
  newPassword: {
    min: 'La nueva contraseña debe de tener por lo menos 8 caracteres.',
    required: 'La nueva contraseña es un campo obligatorio.',
  },
  confirmPassword: {
    oneOf: 'Las contraseñas deben de coincidir.',
    required: 'La confirmación de contraseña es un campo obligatorio.',
  },
};

const validationSchema: Yup.ObjectSchema<Yup.Shape<
  {},
  IPasswordChangeFields
>> = Yup.object().shape({
  currentPassword: Yup.string().required(errors.newPassword.required),
  newPassword: Yup.string()
    .min(8, errors.newPassword.min)
    .required(errors.newPassword.required),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], errors.confirmPassword.oneOf)
    .required(errors.confirmPassword.required),
});

interface IPasswordChangeFormProps {
  changingPassword: boolean | undefined;
  formikProps: FormikProps<IPasswordChangeFields>;
}

const PasswordChangeForm: React.FunctionComponent<IPasswordChangeFormProps> = ({
  changingPassword,
  formikProps,
}) => {
  const { resetForm } = formikProps;

  useEffect(() => {
    if (!changingPassword) {
      resetForm();
    }
  }, [changingPassword, resetForm]);

  return (
    <Padding>
      <Form>
        <Grid>
          <FormikText
            disabled={changingPassword}
            label="CONTRASEÑA ACTUAL"
            name="currentPassword"
            type="password"
          />
          <FormikText
            disabled={changingPassword}
            label="NUEVA CONTRASEÑA"
            name="newPassword"
            type="password"
          />
          <FormikText
            disabled={changingPassword}
            label="CONFIRMAR CONTRASEÑA"
            name="confirmPassword"
            type="password"
          />
        </Grid>
        <Button
          type="submit"
          variant={
            formikProps.isValid && !changingPassword ? 'primary' : 'inactive'
          }
        >
          Guardar contraseña nueva
        </Button>
      </Form>
    </Padding>
  );
};

interface IPasswordFormWrapperProps {
  changePassword: (payload: IPasswordChangeFields) => void;
  changingPassword: boolean | undefined;
}

const PasswordFormWrapper: React.FunctionComponent<IPasswordFormWrapperProps> = ({
  changePassword,
  changingPassword,
}) => {
  return (
    <Formik
      render={formikProps => (
        <PasswordChangeForm
          changingPassword={changingPassword}
          formikProps={formikProps}
        />
      )}
      initialValues={initialValues}
      onSubmit={values => changePassword(values)}
      validationSchema={validationSchema}
    />
  );
};

const mapStateToProps = (
  state: any
): { changingPassword: boolean | undefined } => ({
  changingPassword: state.loaders.changingPassword,
});

const creators = {
  changePassword: passwordChangeActions.creators.changePassword.request,
};

export default connect(mapStateToProps, creators)(PasswordFormWrapper);
