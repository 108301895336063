import { handleActions } from 'redux-actions';

const initialState = {
  activating: false,
  activationAttempted: false,
  success: false,
};

const offerActivationReducer = handleActions(
  {
    OFFERS_ACTIVATE_COMPLETED: state => ({
      ...state,
      activating: false,
      activationAttempted: true,
      success: true,
    }),
    OFFERS_ACTIVATE_FAILED: state => ({
      ...state,
      activating: false,
      activationAttempted: true,
      success: false,
    }),
    OFFERS_ACTIVATE_STARTED: state => ({ ...state, activating: true }),
    OFFERS_ACTIVATE_RESET_STATE: () => initialState,
  },
  initialState
);

export default offerActivationReducer;
