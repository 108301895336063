import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 25 23';

const CheckmarkIcon = ({ fill }: { fill: string }) => (
  <path
    d="M12,23C5.9345703,23,1,18.0654297,1,12S5.9345703,1,12,1s11,4.9345703,11,11S18.0654297,23,12,23z M12,3  c-4.9624023,0-9,4.0375977-9,9c0,4.9628906,4.0375977,9,9,9c4.9628906,0,9-4.0371094,9-9C21,7.0375977,16.9628906,3,12,3z   M11.0356445,15.8994141l6.3637695-6.3637695c0.390625-0.390625,0.390625-1.0234375,0-1.4140625s-1.0234375-0.390625-1.4140625,0  l-5.6567383,5.6567383l-2.121582-2.121582c-0.390625-0.390625-1.0234375-0.390625-1.4140625,0s-0.390625,1.0234375,0,1.4145508  l2.8286133,2.828125c0.1953125,0.1953125,0.4511719,0.2929688,0.7070312,0.2929688S10.840332,16.0947266,11.0356445,15.8994141z"
    fill={fill}
    fillRule="evenodd"
  />
);

export default BaseIcon(CheckmarkIcon, viewBox);
