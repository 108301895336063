import { call, put, takeLatest } from 'redux-saga/effects';

import existentClientActions from '../actions/existentClient';
import toastActions from '../actions/toastNotifications';
import { addSearchParamsToPayload } from '../utils/misc';
import { buildErrorToast } from '../utils/toastNotifications';
import runner from './runners';

const UNEXPECTED_ERROR_TOAST_MESSAGE =
  'Hubo un error en la verificación. Podemos atenderte en el chat de ayuda.';
const INCORRECT_CODE =
  'El NIP que está tratando de ingresar es incorrecto. Por favor, intente de nuevo.';

function* authExistentClient({
  payload,
}: IReduxAction<{ email: string; password: string }>): any {
  const newPayload = addSearchParamsToPayload(payload, ['promoterUserCode']);
  yield call(
    runner,
    existentClientActions.creators.clientExists,
    {
      method: 'GET',
      url: '/api/account/client-exists',
      params: newPayload,
    },
    { snakeCase: false }
  );
}

function* watchAuthExistentUser(): any {
  yield takeLatest(
    existentClientActions.types.CLIENT_EXISTS.REQUESTED,
    authExistentClient
  );
}

function* sendNipExistentClient({
  payload,
}: IReduxAction<{
  biometricVerificationId: number;
  clientId: number;
  phoneNumber: string;
}>): any {
  const newPayload = addSearchParamsToPayload(payload, ['promoterUserCode']);
  const { error } = yield call(
    runner,
    existentClientActions.creators.sendNipExistentClient,
    {
      method: 'post',
      url: '/api/requests/existent-client/compliance',
      payload: newPayload,
    },
    { snakeCase: false }
  );
  if (error) {
    const toast = buildErrorToast(UNEXPECTED_ERROR_TOAST_MESSAGE, 5000);
    yield put(toastActions.creators.showToast(toast));
  }
}

function* watchSendNipExistentClient(): any {
  yield takeLatest(
    existentClientActions.types.SEND_NIP_EXISTENT_CLIENT.REQUESTED,
    sendNipExistentClient
  );
}

function* verifyNipExistentClient({
  payload,
}: IReduxAction<{
  complianceNip: string;
  complianceId: number;
  clientId: number;
}>): any {
  const newPayload = addSearchParamsToPayload(payload, ['promoterUserCode']);
  const { error } = yield call(
    runner,
    existentClientActions.creators.verifyNipExistentClient,
    {
      method: 'post',
      url: '/api/requests/existent-client/activate',
      payload: newPayload,
    },
    { snakeCase: false }
  );
  if (error) {
    const message = (() => {
      const statusCode = error.response.status;
      switch (statusCode) {
        case 409:
          return INCORRECT_CODE;
        default:
          return UNEXPECTED_ERROR_TOAST_MESSAGE;
      }
    })();
    const toast = buildErrorToast(message, 5000);
    yield put(toastActions.creators.showToast(toast));
  }
}

function* watchVerifyNipExistentClient(): any {
  yield takeLatest(
    existentClientActions.types.VERIFY_NIP_EXISTENT_CLIENT.REQUESTED,
    verifyNipExistentClient
  );
}

export default {
  watchAuthExistentUser,
  watchSendNipExistentClient,
  watchVerifyNipExistentClient,
};
