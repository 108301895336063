import { handleActions } from 'redux-actions';

const initialState = {};

const newApplicationMultiSectionsReducer = handleActions(
  {
    STORE_SECTIONS_DATA: (state, action) => action.payload,
    CLEAR_NEW_APPLICATION: () => initialState,
  },
  initialState
);

export default newApplicationMultiSectionsReducer;
