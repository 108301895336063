import React, { Fragment, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';

import colors from '../../constants/colors';
import Header from '../Header';
import MainRoutes from '../MainRoutes';
import NavMenu from '../NavMenu';
import NavMenuMobile from '../NavMenuMobile';
import OrganizationInfo from '../OrganizationInfo';

const AppBodyContainer = styled.div<{ isMobile: boolean; height: number }>`
  display: flex;
  height: ${props =>
    props.isMobile
      ? `calc(${props.height}px - 40px - 50px)`
      : 'calc(100vh - 61px)'};
  transition: height ease 0.1s;
`;

const NavMenuContainer = styled.div`
  background-color: ${colors.WHITE};
  border-right: solid 1px ${colors.BORDER};
  box-shadow: 1px 0 0 0 ${colors.BACKGROUND};
  height: 100%;
  overflow: auto;
  width: 268px;
`;

const NavMenuMobileContainer = styled.div`
  background-color: ${colors.MOBILE_BACKGROUND};
  position: fixed;
  backdrop-filter: blur(20px);
  box-shadow: 0 -0.5px 0 0 rgba(0, 0, 0, 0.3);
  overflow-y: hidden;
  height: 50px;
  width: 100%;
  bottom: 0px;
`;

const MainContentContainer = styled.div<{ isMobile: boolean }>`
  /* Taking in account the NavMenu */
  width: ${props => (props.isMobile ? '100%' : 'calc(100% - 268px)')};
  height: 100%;
  overflow: auto;
  padding: ${props => (props.isMobile ? '32px 15px' : '32px')};
`;

const ExtraScrollSpace = styled.div`
  height: 40px;
  width: 100%;
`;

interface IMainContentOwnProps {
  isMobile: boolean;
}

type IMainContentProps = IMainContentOwnProps & RouteComponentProps;

const MainContent: React.FunctionComponent<IMainContentProps> = ({
  location,
  isMobile,
}) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (container.current !== null) {
      container.current.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <MainContentContainer
      id="homeMainContent"
      isMobile={isMobile}
      ref={container}
    >
      <MainRoutes />
      <ExtraScrollSpace />
    </MainContentContainer>
  );
};

const MainContentWithRouter = withRouter(MainContent);

interface IHome {
  deviceData: IDeviceData;
}

const Home: React.FunctionComponent<IHome> = ({ deviceData }) => {
  return (
    <Fragment>
      <Header isMobile={deviceData.isMobile} isiOS={deviceData.isiOS} />
      {deviceData.isMobile ? (
        <Fragment>
          <AppBodyContainer
            isMobile={deviceData.isMobile}
            height={
              deviceData.isiOS
                ? deviceData.clientHeight
                : deviceData.innerHeight
            }
          >
            <MainContentWithRouter isMobile={deviceData.isMobile} />
          </AppBodyContainer>
          <NavMenuMobileContainer>
            <NavMenuMobile />
          </NavMenuMobileContainer>
        </Fragment>
      ) : (
        <AppBodyContainer
          isMobile={deviceData.isMobile}
          height={
            deviceData.isiOS ? deviceData.clientHeight : deviceData.innerHeight
          }
        >
          <NavMenuContainer>
            <OrganizationInfo />
            <NavMenu />
          </NavMenuContainer>
          <MainContentWithRouter isMobile={deviceData.isMobile} />
        </AppBodyContainer>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any): { deviceData: IDeviceData } => ({
  deviceData: state.deviceData,
});

export default connect(mapStateToProps)(Home);
