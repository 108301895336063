import React, { Fragment } from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import Border from '../Border';

const BorderContainer = styled.div`
  padding: 0 4px;
`;

const CardContainer = styled.div`
  background-color: ${colors.WHITE};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 ${colors.BORDER};
`;

const CardTitleContainer = styled.div`
  display: flex;
  padding: 24px 40px;
`;

const ControlsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
`;

const IconContainer = styled.div`
  margin-right: 16px;
  width: 20px;
`;

const Title = styled.div`
  color: ${colors.PRIMARY_TEXT};
  height: 28px;
  font-size: 18px;
  font-weight: bold;
`;

const Subtitle = styled.div`
  color: ${colors.DUSK50};
  font-size: 14px;
`;

const PrimaryCardInnerSeparator = styled.div`
  background-color: ${colors.AQUA};
  height: 1px;
  width: 100%;
`;

interface SimpleCard {
  controls?: React.ComponentType | React.ReactNode;
  icon?: React.ComponentType;
  title: string;
  subtitle?: string;
  isPrimary?: boolean;
}

export const SimpleCardInnerSeparator = () => (
  <BorderContainer>
    <Border />
  </BorderContainer>
);

const SimpleCard: React.FunctionComponent<SimpleCard> = ({
  children,
  controls = Fragment,
  icon,
  title,
  subtitle = '',
  isPrimary = false,
}) => {
  const Icon = icon;
  return (
    <CardContainer>
      <CardTitleContainer>
        {Icon ? (
          <IconContainer>
            <Icon />
          </IconContainer>
        ) : null}
        <div>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </div>
        <ControlsContainer>
          {controls instanceof Function ? controls() : controls}
        </ControlsContainer>
      </CardTitleContainer>
      {isPrimary ? <PrimaryCardInnerSeparator /> : <SimpleCardInnerSeparator />}
      {children}
    </CardContainer>
  );
};

export default SimpleCard;
