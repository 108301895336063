import { Field, FieldProps } from 'formik';
import React from 'react';

import RadioRangeInput from '../RadioRangeInput';

interface IFormikRadioInput {
  disabled?: boolean;
  label: string;
  name: string;
  minimum: number;
  maximum: number;
  lastHasPlus?: boolean;
}

const FormikRadioInput = ({
  disabled,
  label,
  name,
  minimum,
  maximum,
  lastHasPlus = false,
}: IFormikRadioInput) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <RadioRangeInput
        label={label}
        value={field.value}
        onBlur={() => form.setFieldTouched(field.name)}
        onChange={option => form.setFieldValue(field.name, option)}
        disabled={disabled}
        minimum={minimum}
        maximum={maximum}
        lastHasPlus={lastHasPlus}
      />
    )}
  </Field>
);

export default FormikRadioInput;
