import { Form, FormikProps } from 'formik';
import React from 'react';
import styled from 'styled-components';

import Button from '../Button';
import { FormikText } from '../FormikInputs';

const FormContainer = styled.div`
  width: 280px;
`;

interface IUpdateUserForm {
  formikProps: FormikProps<{
    name: string;
    firstSurname: string;
    secondSurname: string;
    email: string;
    phoneNumber: string;
  }>;
}

const UpdateUserDataForm: React.FunctionComponent<IUpdateUserForm> = ({
  formikProps,
}) => {
  return (
    <Form>
      <FormContainer>
        <FormikText label="NOMBRE" name="name" />
        <FormikText label="PRIMER APELLIDO" name="firstSurname" />
        <FormikText label="SEGUNDO APELLIDO" name="secondSurname" />
        <FormikText label="EMAIL" name="email" />
        <FormikText label="NÚMERO DE TELÉFONO" name="phoneNumber" />
        <Button
          onClick={() => {
            formikProps.submitForm();
          }}
        >
          Actualizar datos
        </Button>
      </FormContainer>
    </Form>
  );
};

export default UpdateUserDataForm;
