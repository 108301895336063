import { Field, FieldProps } from 'formik';
import React from 'react';

import { applySelector } from '../../utils/misc';
import CheckboxInput from '../CheckboxInput';

interface FormikCheckbox {
  label: string;
  name: string;
  value: boolean;
}

const FormikCheckbox: React.FunctionComponent<FormikCheckbox> = ({
  label,
  name,
  value,
}) => (
  <Field
    name={name}
    render={({ field, form }: FieldProps) => (
      <CheckboxInput
        error={
          applySelector(form.touched, field.name) &&
          applySelector(form.errors, field.name)
        }
        withErrorMessage={true}
        checked={value === true}
        onBlur={() => form.setFieldTouched(field.name)}
        onChange={checked => form.setFieldValue(field.name, checked)}
        label={label}
      />
    )}
  />
);

export default FormikCheckbox;
