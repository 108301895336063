import { FieldArray, Form, Formik, FormikProps } from 'formik';
import values from 'ramda/src/values';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import finalOffersActions from '../../actions/finalOffers';
import vehicleActions from '../../actions/vehicles';
import colors from '../../constants/colors';
import grammar from '../../constants/grammar';
import Border from '../Border';
import Button from '../Button';
import Card from '../Card';
import DayPickerInput from '../DayPickerInput';
import {
  FormikCurrency,
  FormikDropdown,
  FormikPercentage,
  FormikText,
} from '../FormikInputs';
import MultiCheckbox from '../MultiCheckbox';
import OptionPicker from '../OptionPicker';
import {
  Approbation,
  approbationFormSchema,
  getInitialFormState,
  getModelYearOptions,
  normalizeFinalOfferPayload,
  paymentFrequencyOptions,
  vehicleConditionOptions,
} from './ApprobationForm.utils';

const DocumentsContainer = styled.div`
  background-color: ${colors.WHITE};
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 ${colors.BORDER};
  margin-bottom: 20px;
`;

const DocumentDescriptionContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  width: 400px;
`;

const FlexContainer = styled.div`
  display: flex;
`;

const BottomMargin = styled.div`
  margin-bottom: 20px;
`;

const FormContent = styled.div`
  background-color: ${colors.WHITE};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(224px, 1fr));
  padding: 20px;
`;

const FormSectionTitle = styled.div`
  align-items: center;
  color: ${colors.DUSK50};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 32px;
  padding-left: 40px;
  text-transform: uppercase;
`;

const InputContainer = styled.div`
  height: 88px;
  margin-bottom: 20px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
`;

const SendButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DocumentList = styled.div`
  padding: 20px;
  padding-left: 40px;
`;

const DeleteDocument = styled.div`
  align-items: center;
  color: ${colors.SECONDARY_TEXT};
  cursor: pointer;
  display: flex;
  font-size: 24px;
  transform: rotate(45deg);
`;

const DocumentCreator: React.FunctionComponent<{ formState: Approbation }> = ({
  formState,
}) => (
  <DocumentsContainer>
    <FormSectionTitle>Requisitos</FormSectionTitle>
    <Border />
    <FieldArray
      name="documents"
      render={({ push, remove }) => (
        <DocumentList>
          {formState.documents.map((doc, index) => (
            <FlexContainer key={index}>
              <div>
                <FormikText label="Nombre" name={`documents[${index}].name`} />
              </div>
              <DocumentDescriptionContainer>
                <FormikText
                  label="Descripción"
                  name={`documents[${index}].description`}
                />
              </DocumentDescriptionContainer>
              <DeleteDocument onClick={() => remove(index)}>+</DeleteDocument>
            </FlexContainer>
          ))}
          <BottomMargin>
            <Button onClick={() => push({ name: '', description: '' })}>
              Añadir documento
            </Button>
          </BottomMargin>
        </DocumentList>
      )}
    />
  </DocumentsContainer>
);

interface IVehicleManufacturer {
  id: number;
  name: string;
}

interface IVehicleModel {
  id: number;
  manufacturerId: number;
  model: string;
  vehicleType: string;
  version: string;
  createdAt: string;
  updatedAt: string;
}

interface IApprobationForm {
  fetchVehicleManufacturers: Function;
  fetchVehicleModels: Function;
  formikProps: FormikProps<Approbation>;
  offer: any;
  vehicleManufacturers: Array<IVehicleManufacturer>;
  vehicleModels: Array<IVehicleModel>;
}

interface IApprobationFormWrapper {
  createFinalOffer: Function;
  fetchVehicleManufacturers: Function;
  fetchVehicleModels: Function;
  offer: any;
  templateDocuments: ITemplateDocument[];
  vehicleManufacturers: Array<IVehicleManufacturer>;
  vehicleModels: Array<IVehicleModel>;
}

const ApprobationForm: React.FunctionComponent<IApprobationForm> = ({
  fetchVehicleManufacturers,
  formikProps,
  offer,
  fetchVehicleModels,
  vehicleManufacturers,
  vehicleModels,
}) => {
  const { setFieldTouched, setFieldValue, values: formValues } = formikProps;
  const { vehicleBrand, vehicleCondition } = formValues;
  const { feeType } = formValues;
  const header = `Aprobación - Oferta - ${grammar[offer.request.type]} #${
    offer.id
  }`;

  const { type } = offer.request;
  const modelYearOptions = getModelYearOptions(formValues.vehicleCondition);

  const isMounted = useRef(false);

  useEffect(() => {
    const vehicleType = type === 'automotriz' ? 'car' : 'motorcycle';

    // eslint-disable-next-line @typescript-eslint/camelcase
    fetchVehicleManufacturers({ vehicle_type: vehicleType });
  }, [fetchVehicleManufacturers, type]);

  useEffect(() => {
    const vehicleType = type === 'automotriz' ? 'car' : 'motorcycle';
    fetchVehicleModels({
      manufacturerId: vehicleBrand,
      vehicleType,
    });

    if (isMounted.current) {
      setFieldValue('vehicleModel', '');
    }
  }, [fetchVehicleModels, setFieldValue, type, vehicleBrand]);

  useEffect(() => {
    if (isMounted.current) {
      setFieldValue('vehicleModelYear', '');
    }
  }, [vehicleCondition, setFieldValue]);

  // It is important that this is the last hook, because of the ref
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    }
  });

  const manufacturerOptions = vehicleManufacturers.map(({ id, name }) => ({
    text: name,
    value: id,
  }));

  const vehicleModelOptions = vehicleModels
    .filter(model => `${model.manufacturerId}` === `${formValues.vehicleBrand}`)
    .map(({ id, model }) => ({
      text: model,
      value: id,
    }));

  return (
    <Form>
      <BottomMargin>
        <Card header={header}>
          <FormSectionTitle>Condiciones</FormSectionTitle>
          <Border />
          <FormContent>
            <InputContainer>
              <FormikDropdown
                label="Tipo de producto"
                name="vehicleCondition"
                options={vehicleConditionOptions}
                placeholder="Tipo de producto"
              />
            </InputContainer>
            <InputContainer>
              <FormikDropdown
                label="Marca"
                name="vehicleBrand"
                options={manufacturerOptions}
                placeholder=""
              />
            </InputContainer>
            <InputContainer>
              <FormikDropdown
                label="Versión"
                name="vehicleModel"
                options={vehicleModelOptions}
                placeholder={'Seleccione un modelo'}
              />
            </InputContainer>
            <InputContainer>
              <FormikDropdown
                label="Año"
                name="vehicleModelYear"
                options={modelYearOptions}
                placeholder={'Seleccione un año'}
              />
            </InputContainer>
            <InputContainer>
              <FormikCurrency label="Valor del producto" name="vehicleValue" />
            </InputContainer>
            <InputContainer>
              <FormikText
                label="Nombre del producto financiero"
                name="productName"
              />
            </InputContainer>
            <InputContainer>
              <FormikCurrency label="Enganche" name="downpayment" />
            </InputContainer>
            <InputContainer>
              <FormikCurrency label="Monto del crédito" name="creditAmount" />
            </InputContainer>
            <InputContainer>
              <FormikText label="Plazo" name="numberPayments" />
            </InputContainer>
            <InputContainer>
              <FormikDropdown
                label="Periodicidad"
                name="paymentFrequency"
                options={paymentFrequencyOptions}
                placeholder="Periodicidad"
              />
            </InputContainer>
            <InputContainer>
              <FormikPercentage label="Tasa ordinaria" name="rate" />
            </InputContainer>
            <InputContainer>
              <OptionPicker
                label={'Tasa ordinaria'}
                onBlur={() => setFieldTouched('rateType')}
                onChange={(option: any) =>
                  setFieldValue('rateType', option.value)
                }
                options={[
                  { text: 'Fija', value: 'Fija' },
                  { text: 'Variable', value: 'Variable' },
                ]}
                value={formValues.rateType}
              />
            </InputContainer>
            <InputContainer>
              <FormikPercentage label="Costo anual total" name="cat" />
            </InputContainer>
            <InputContainer>
              {feeType === 'Monto' ? (
                <FormikCurrency label="Comisión por apertura" name="fee" />
              ) : (
                <FormikPercentage label="Comisión por apertura" name="fee" />
              )}
            </InputContainer>
            <InputContainer>
              <OptionPicker
                label={'Comisión por apertura'}
                onBlur={() => setFieldTouched('feeType')}
                onChange={(option: any) =>
                  setFieldValue('feeType', option.value)
                }
                options={[
                  { text: 'Monto', value: 'Monto' },
                  { text: 'Porcentaje', value: 'Porcentaje' },
                ]}
                value={formValues.feeType}
              />
            </InputContainer>
            <InputContainer>
              <FormikCurrency
                label="Otros accesorios"
                name="accessoriesAmount"
              />
            </InputContainer>
            <InputContainer>
              <MultiCheckbox
                label={'Otros accesorios'}
                onBlur={() => setFieldTouched('accessories')}
                onChange={(value: any) => setFieldValue('accessories', value)}
                options={['SD', 'SV', 'GPS']}
                value={formValues.accessories}
              />
            </InputContainer>
            <InputContainer>
              <FormikCurrency
                label="Pago periódico"
                name="periodicPaymentAmount"
              />
            </InputContainer>
            <InputContainer>
              <DayPickerInput
                label={'Fecha de vigencia'}
                onChange={value => setFieldValue('dateVigency', value)}
                onDayPickerHide={() => setFieldTouched('dateVigency')}
                value={formValues.dateVigency}
              />
            </InputContainer>
          </FormContent>
        </Card>
      </BottomMargin>
      <DocumentCreator formState={formValues} />
      <SendButtonContainer>
        <Button type="submit" variant="primary">
          Enviar aprobación
        </Button>
      </SendButtonContainer>
    </Form>
  );
};

const ApprobationFormWrapper: React.FunctionComponent<IApprobationFormWrapper> = ({
  createFinalOffer,
  fetchVehicleManufacturers,
  fetchVehicleModels,
  offer,
  templateDocuments,
  vehicleManufacturers,
  vehicleModels,
}) => {
  return (
    <Formik
      initialValues={getInitialFormState(offer, templateDocuments)}
      onSubmit={values =>
        createFinalOffer({
          offerId: offer.id,
          finalOffer: normalizeFinalOfferPayload(values),
        })
      }
      render={formikProps => (
        <ApprobationForm
          fetchVehicleManufacturers={fetchVehicleManufacturers}
          fetchVehicleModels={fetchVehicleModels}
          formikProps={formikProps}
          offer={offer}
          vehicleManufacturers={vehicleManufacturers}
          vehicleModels={vehicleModels}
        />
      )}
      validationSchema={approbationFormSchema}
    />
  );
};

const mapStateToProps = (
  state: any
): { vehicleManufacturers: any[]; vehicleModels: any[] } => ({
  vehicleManufacturers: values(state.entities.vehicleManufacturers),
  vehicleModels: values(state.entities.vehicleModels),
});

const creators = {
  createFinalOffer: finalOffersActions.creators.createFinalOffer.request,
  fetchVehicleManufacturers:
    vehicleActions.creators.fetchVehicleManufacturers.request,
  fetchVehicleModels: vehicleActions.creators.fetchVehicleModels.request,
};

export default connect(mapStateToProps, creators)(ApprobationFormWrapper);
