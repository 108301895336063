import { handleActions } from 'redux-actions';

const initialState = {};

const offerCountsReducer = handleActions(
  {
    OFFERS_COUNTS_FETCH_COMPLETED: (state, action) => ({
      ...action.payload.data,
    }),
  },
  initialState
);

export default offerCountsReducer;
