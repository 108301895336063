import { Field, FieldProps } from 'formik';
import React from 'react';
import { noop } from 'redux-saga/utils';

import { applySelector } from '../../utils/misc';
import { Option } from '../Dropdown/Dropdown';
import DropdownInput from '../DropdownInput';

interface FormikDropdownInput {
  disabled?: boolean;
  label: string;
  name: string;
  options: Array<Option>;
  placeholder: string;
  sideEffects?(value: string | number): void;
  onOpen?(): void;
  onClose?(): void;
}

const FormikDropdownInput = ({
  disabled,
  label,
  name,
  options,
  placeholder,
  sideEffects = noop,
  onOpen = noop,
  onClose = noop,
}: FormikDropdownInput) => (
  <Field
    name={name}
    render={({ field, form }: FieldProps) => (
      <DropdownInput
        disabled={disabled}
        error={
          applySelector(form.touched, field.name) &&
          applySelector(form.errors, field.name)
        }
        label={label}
        onBlur={() => form.setFieldTouched(field.name)}
        onChange={option => {
          form.setFieldValue(field.name, option.value);
          sideEffects(option.value);
        }}
        options={options}
        placeholder={placeholder}
        value={field.value}
        onOpen={onOpen}
        onClose={onClose}
      />
    )}
  />
);

export default FormikDropdownInput;
