import { isEmpty } from 'ramda';
import isNil from 'ramda/src/isNil';
import prop from 'ramda/src/prop';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import applicationCreationActions from '../../actions/applicationCreation';
import continueApplicationActions from '../../actions/continueApplication';
import colors from '../../constants/colors';
import grammar from '../../constants/grammar';
import { RejectionReasons } from '../../constants/rejectionReasons';
import Icon from '../../resources/icons/Form';
import AddressSection from '../AddressSection';
import Border from '../Border';
import CoverLoading from '../CoverLoading';
import EmploymentSection from '../EmploymentSection';
import LoadingIndicator from '../LoadingIndicator';
import MultiSectionForm from '../MultiSectionForm';
import NewApplicationResult from '../NewApplicationResult';
import {
  ICosignerForm,
  IProfilingFormComplete,
} from '../ProfilingSectionsForms';
import SectionFormReferences from '../SectionFormReferences';
import SectionFormSend from '../SectionFormSend';
import SimpleButton from '../SimpleButton';
import ThirdPartiesSection from '../ThirdPartiesSection';

const RequestCard = styled.div`
  background-color: ${colors.WHITE};
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 ${colors.BORDER};
`;

const RequestHeader = styled.div`
  padding-left: 42px;
  padding-top: 24px;
  padding-bottom: 24px;
  height: 102px;
  display: flex;
  flex-flow: row wrap;
`;

const TopHeader = styled.div`
  height: 28px;
  width: 100%;
  display: flex;
`;

const CoverLoadingText = styled.span`
  padding-top: 12px;
  font-size: 16px;
  font-weight: bold;
`;

const LogoContainer = styled.div`
  padding-top: 4px;
  padding-bottom: 16px;
`;

const TitleContainer = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 18px;
  font-weight: bold;
  line-height: 1.56;
  padding-left: 14px;
`;

const Steps = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 28px;
  padding-top: 28px;
  width: 712px;
`;

const MobileStepsContainer = styled.div`
  margin: auto;
  padding: 16px;
`;

const MobileSteps = styled.div`
  padding: 8px 15px;
  border-radius: 15px;
  background-color: rgba(0, 195, 168, 0.45);
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SubtitleContainer = styled.div`
  color: ${colors.SECONDARY_TEXT};
  font-size: 14px;
  line-height: 1.57;
  padding-left: 32px;
`;

const ButtonsContainer = styled.div<{ isMobile: boolean }>`
  ${props =>
    props.isMobile
      ? 'width: 92%; margin: auto;'
      : 'margin-left: 52px; margin-right: 52px;'}
  padding-bottom: 24px;
  padding-top: 24px;
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const Checkmark = styled.span<{ border: string; color: string }>`
  display: block;
  position: relative;
  height: 24px;
  width: 24px;
  border: solid 1px ${prop('border')};
  background-image: ${prop('color')};
  background-color: ${prop('color')};
  border-radius: 50%;
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: left;
  justify-self: left;

  & input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  & input:checked ~ ${Checkmark}:after {
    content: '';
    margin-left: 8px;
    margin-top: 3px;
    display: block;
    width: 6px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const RadioDescriptor = styled.div<{ color: string }>`
  padding-left: 10px;
  padding-top: 1px;
  color: ${prop('color') || colors.PRIMARY_TEXT};
`;

const getTextColor = (status: string) => {
  switch (status) {
    case 'uncompleted':
      return colors.SECONDARY_TEXT;
    case 'current':
      return colors.PRIMARY_TEXT;
    case 'completed':
      return colors.AQUA;
    default:
      return colors.PRIMARY_TEXT;
  }
};

const Step = ({ text, status }: { text: string; status: string }) => (
  <StepContainer>
    <input checked={status === 'completed'} type="checkbox" readOnly />
    <Checkmark
      border={status === 'uncompleted' ? '#d8dce6' : colors.AQUA}
      color={
        status === 'completed'
          ? colors.AQUA
          : `linear-gradient(to top, #f6f7f9, ${colors.WHITE})`
      }
    />
    <RadioDescriptor color={getTextColor(status)}>
      {grammar[text]}
    </RadioDescriptor>
  </StepContainer>
);

const buildStepArray = (showThirdParty: boolean, showEmployment: boolean) => {
  if (showThirdParty && showEmployment)
    return ['third', 'address', 'employment', 'references', 'send'];
  else if (showThirdParty) return ['third', 'address', 'references', 'send'];
  else if (showEmployment)
    return ['address', 'employment', 'references', 'send'];
  else return ['address', 'references', 'send'];
};

interface ICosignerData {
  spouseAsCosigner: string;
  sameAddressAsSolicitant: string;
  employment: string;
  income?: number;
}

interface IAddressSchema {
  street: string | null;
  interiorNumber: string | null;
  exteriorNumber: string | null;
  neighborhood: string | null;
  postalCode: string | null;
  district: string | null;
  state: string | null;
}

interface INewApplicationViewProps {
  profilingCosigner: ICosignerForm;
  hasSpouse: boolean;
  hasCosigner: boolean;
  hasEmployment: boolean;
  hasPreviousEmployment: boolean;
  product: string;
  accountCreated: boolean;
  showThirdParty: boolean;
  showEmployment: boolean;
  createApplication: Function;
  creatingApplication: boolean;
  newApplication: any;
  receivedOffers: IOffer[] | null;
  prefillingAddress: IAddressSchema;
  addressInId: boolean;
  rejectionReasons: RejectionReasons[];
  redirectToApplicationStart: Function;
  applicationStarted: boolean;
  existentClient: boolean;
  canSubmit: boolean;
  isMobile: boolean;
  accountVerificationSucceded: boolean | null;
  existentClientNipVerified: boolean | null;
  redirectToAccountCreationStart: Function;
  isExistentClient: boolean;
}

const NewApplicationView: React.FunctionComponent<INewApplicationViewProps> = ({
  profilingCosigner,
  hasSpouse,
  hasCosigner,
  hasEmployment,
  hasPreviousEmployment,
  product,
  accountCreated,
  showThirdParty,
  showEmployment,
  createApplication,
  creatingApplication,
  newApplication,
  prefillingAddress,
  addressInId,
  redirectToApplicationStart,
  applicationStarted,
  existentClient,
  receivedOffers,
  rejectionReasons,
  canSubmit,
  isMobile,
  accountVerificationSucceded,
  redirectToAccountCreationStart,
  existentClientNipVerified,
  isExistentClient,
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [steps] = useState<string[]>(
    buildStepArray(showThirdParty, showEmployment)
  );

  const redirectIfMissingPreviousSteps = () => {
    if (!applicationStarted || (isNil(accountCreated) && !existentClient)) {
      redirectToApplicationStart();
    }
  };

  const advanceToResultView = () => {
    if (!isNil(receivedOffers)) {
      setCurrentIndex(steps.length);
    }
  };

  useEffect(redirectIfMissingPreviousSteps, [
    applicationStarted,
    accountCreated,
    redirectToApplicationStart,
  ]);

  useEffect(advanceToResultView, [receivedOffers, steps]);

  useEffect(() => {
    if (!accountVerificationSucceded && !existentClientNipVerified) {
      if (isExistentClient) {
        redirectToApplicationStart();
      } else {
        redirectToAccountCreationStart();
      }
    }
  }, [
    isExistentClient,
    accountVerificationSucceded,
    existentClientNipVerified,
    redirectToApplicationStart,
    redirectToAccountCreationStart,
  ]);

  const getSteps = steps.map((text, i) => {
    if (i < currentIndex)
      return <Step key={i} text={text} status="completed" />;
    else if (i === currentIndex)
      return <Step key={i} text={text} status="current" />;
    else return <Step key={i} text={text} status="uncompleted" />;
  });

  const onSubmit = (values: any) => {
    if (canSubmit) {
      createApplication({ applicationForm: values, prevSteps: newApplication });
    }
  };

  const renderButtons = (onBackClick: () => void, onNextClick: () => void) => (
    <Fragment>
      <Border />
      <ButtonsContainer isMobile={isMobile}>
        <SimpleButton
          variant="left"
          text={
            currentIndex === 0
              ? 'Abandonar solicitud'
              : `Regresar: ${grammar[steps[currentIndex - 1]]}`
          }
          onClick={() =>
            currentIndex === 0 ? redirectToApplicationStart() : onBackClick()
          }
        />
        <SimpleButton
          variant="right"
          text={
            currentIndex === steps.length - 1
              ? 'Finalizar solicitud'
              : `Continuar: ${grammar[steps[currentIndex + 1]]}`
          }
          onClick={onNextClick}
        />
      </ButtonsContainer>
    </Fragment>
  );

  return (
    <Fragment>
      {creatingApplication ? (
        <CoverLoading>
          <LoadingIndicator />
          <CoverLoadingText>
            Conectando con los otorgantes seleccionados
          </CoverLoadingText>
        </CoverLoading>
      ) : null}
      <RequestCard>
        <RequestHeader>
          <TopHeader>
            <LogoContainer>
              <Icon fill={colors.DUSK} size={18} />
            </LogoContainer>
            <TitleContainer>
              {product === 'credit_card'
                ? grammar[product]
                : `${grammar[product]}`}
            </TitleContainer>
          </TopHeader>
          <SubtitleContainer>
            Llena la siguiente información para que podamos recibir tu
            solicitud.
          </SubtitleContainer>
        </RequestHeader>
        <Border />
        <StepsContainer>
          {isMobile ? (
            <MobileStepsContainer>
              <MobileSteps>
                {currentIndex < steps.length
                  ? `${currentIndex + 1}/${steps.length} - ${
                      grammar[steps[currentIndex]]
                    }`
                  : 'Solicitud enviada'}
              </MobileSteps>
            </MobileStepsContainer>
          ) : (
            <Steps>{getSteps}</Steps>
          )}
        </StepsContainer>
        <Border />
        {receivedOffers !== null ? (
          <NewApplicationResult
            offers={receivedOffers}
            rejectionReasons={rejectionReasons}
          />
        ) : (
          <MultiSectionForm
            onSubmit={onSubmit}
            onSectionChange={(sectionName: string, index: number) =>
              setCurrentIndex(index)
            }
            renderButtons={renderButtons}
          >
            {showThirdParty
              ? ThirdPartiesSection({
                  hasCosigner,
                  hasSpouse,
                  spouseAsCosigner:
                    hasCosigner && profilingCosigner.spouseAsCosigner,
                })
              : null}
            {AddressSection({
              shouldAskForCosignerAddress:
                hasCosigner && !profilingCosigner.sameAddressAsSolicitant,
              addressInId,
              prefillingAddress,
            })}
            {showEmployment
              ? EmploymentSection({
                  hasEmployment,
                  hasPreviousEmployment,
                  shouldAskForCosignerEmployment:
                    hasCosigner &&
                    ['employed', 'independent'].includes(
                      profilingCosigner.employmentStatus
                    ),
                })
              : null}
            {SectionFormReferences}
            {SectionFormSend({ productType: product })}
          </MultiSectionForm>
        )}
      </RequestCard>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const newApplication = state.newApplication;
  const { accountVerificationSucceded } = newApplication;
  const {
    address: profilingAddress,
    applicant: profilingApplicant,
    cosigner: profilingCosigner,
    employment: profilingEmployment,
    product: profilingProduct,
  } = newApplication.profiling as IProfilingFormComplete;

  const existentClient = profilingApplicant.existentUser;

  const hasCosigner = profilingApplicant.hasCosigner;
  const hasSpouse = profilingApplicant.maritalStatus === 'married';
  const showThirdParty = hasSpouse || hasCosigner;
  const hasEmployment =
    profilingEmployment.employmentStatus === 'employed' ||
    profilingEmployment.employmentStatus === 'independent';
  const hasPreviousEmployment =
    profilingEmployment.previousEmployment ||
    profilingEmployment.employmentStatus === 'retired';
  const showEmployment = hasEmployment || hasPreviousEmployment;

  return {
    profilingCosigner,
    newApplication,
    hasSpouse,
    hasCosigner,
    hasEmployment,
    hasPreviousEmployment,
    product: profilingProduct.productType,
    showThirdParty,
    showEmployment,
    accountCreated: accountVerificationSucceded,
    creatingApplication: state.loaders.creatingApplication,
    receivedOffers: state.newApplication.applicationCreation.offers,
    addressInId: profilingAddress.addressInId,
    prefillingAddress: state.newApplication.verificationModal.address,
    rejectionReasons: state.newApplication.applicationCreation.rejectionReasons,
    existentClient,
    canSubmit: state.newApplication.applicationCreation.canSubmit,
    isMobile: state.deviceData.isMobile,
    accountVerificationSucceded:
      state.newApplication.accountVerificationSucceded,
    existentClientNipVerified: state.newApplication.existentClient.nipVerified,
    isExistentClient: !isEmpty(
      state.newApplication.existentClient.clientAccount
    ),
  };
};

const creators = {
  createApplication:
    applicationCreationActions.creators.createApplication.request,
  redirectToApplicationStart:
    continueApplicationActions.creators.redirectToApplicationStart,
  redirectToAccountCreationStart:
    continueApplicationActions.creators.redirectToAccountCreationStart,
};

export default withRouter(
  // @ts-ignore
  connect(mapStateToProps, creators)(NewApplicationView)
);
