import { Formik, FormikProps } from 'formik';
import isNil from 'ramda/src/isNil';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import automaticProductsActions from '../../actions/automaticProducts';
import colors from '../../constants/colors';
import Button from '../Button';
import { FormikText } from '../FormikInputs';
import LoadingIndicator from '../LoadingIndicator';
import Modal from '../Modal';

const ButtonContainer = styled.div`
  margin: 0px 4px;
`;

const ModalControlContainer = styled.div`
  height: 36px;
  display: flex;
  justify-content: flex-end;
`;

const ModalHeader = styled.div`
  align-items: center;
  color: ${colors.PRIMARY_TEXT};
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 48px;
`;

const ModalLoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 172px;
  justify-content: center;
  width: 100%;
`;

const ModalTextContent = styled.div`
  font-size: 14px;
  text-align: justify;
  align-items: center;
  display: flex;
  min-height: 56px;
  justify-content: center;
  width: 100%;
  padding-bottom: 16px;
`;

interface IProduct {
  id: number;
  internalName: string;
  commercialName: string;
  productType: string;
  validThrough: string;
  createdAt: string | null;
  updatedAt: string | null;
  active: boolean;
}

interface IActivateModalProps {
  product: IProduct;
  showModal: boolean;
  updateProductStatus: Function;
  requestClose: Function;
  updatingProductStatus: boolean;
  productUpdatedSuccess: boolean;
}

interface IActivateModalFormProps {
  formikProps: FormikProps<{ password: string }>;
  updatingProductStatus: boolean;
  isActive: boolean;
  requestClose: Function;
  productUpdatedSuccess: boolean;
  showModal: boolean;
}

const ActivateModalForm: React.FunctionComponent<IActivateModalFormProps> = ({
  formikProps,
  updatingProductStatus,
  isActive,
  requestClose,
  productUpdatedSuccess,
  showModal,
}) => {
  const [disableControls, setDisableControls] = useState<boolean>(false);
  const { setFieldValue } = formikProps;

  useEffect(() => {
    if (isNil(updatingProductStatus) || !updatingProductStatus) {
      setDisableControls(false);
    } else {
      setDisableControls(true);
    }
  }, [updatingProductStatus]);

  useEffect(() => {
    if (productUpdatedSuccess) {
      requestClose();
    } else {
      setFieldValue('password', '');
    }
  }, [productUpdatedSuccess, requestClose, setFieldValue]);

  const renderHeader = () => (
    <ModalHeader>Cambiar estatus del producto</ModalHeader>
  );

  const renderContent = (formikProps: FormikProps<{ password: string }>) => {
    return updatingProductStatus ? (
      <ModalLoadingState>
        <LoadingIndicator />
      </ModalLoadingState>
    ) : (
      <Fragment>
        <ModalTextContent>
          {isActive
            ? 'Al modificar el estatus del producto a inactivo, se dejará de utilizar la configuración del producto para generar ofertas automáticas, ¿Estás seguro de que deseas continuar? '
            : 'Al modificar el estatus del producto a activo, se utilizará la configuración del producto para generar ofertas automáticas, ¿Estás seguro de que deseas continuar?'}
        </ModalTextContent>
        <FormikText
          label="CONTRASEÑA"
          name="password"
          placeholder="Contraseña"
          type="password"
        />
      </Fragment>
    );
  };

  const renderControls = (formikProps: FormikProps<{ password: string }>) => {
    return (
      <ModalControlContainer>
        <ButtonContainer>
          <Button
            variant="secondary"
            onClick={() => {
              formikProps.resetForm();
              requestClose();
            }}
          >
            Cancelar
          </Button>
        </ButtonContainer>
        <ButtonContainer>
          <Button
            variant={disableControls ? 'inactive' : 'primary'}
            onClick={() => formikProps.submitForm()}
          >
            Confirmar
          </Button>
        </ButtonContainer>
      </ModalControlContainer>
    );
  };

  return (
    <Modal
      header={() => renderHeader()}
      content={() => renderContent(formikProps)}
      controls={() => renderControls(formikProps)}
      isOpen={showModal}
      onRequestClose={() => requestClose()}
      shouldCloseOnOverlayClick={false}
      showCloseIcon={true}
      width={'400px'}
    />
  );
};

const ActivateModal: React.FunctionComponent<IActivateModalProps> = ({
  product,
  showModal,
  updateProductStatus,
  requestClose,
  updatingProductStatus,
  productUpdatedSuccess,
}) => {
  return (
    <Formik
      initialValues={{ password: '' }}
      onSubmit={values => {
        updateProductStatus({
          password: values.password,
          productId: product.id,
        });
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required('Ingresa una contraseña.'),
      })}
      render={formikProps => (
        <ActivateModalForm
          formikProps={formikProps}
          isActive={product.active}
          productUpdatedSuccess={productUpdatedSuccess}
          requestClose={requestClose}
          showModal={showModal}
          updatingProductStatus={updatingProductStatus}
        />
      )}
    />
  );
};

const mapStateToProps = (state: any) => ({
  updatingProductStatus: state.loaders.updatingProductStatus,
  productUpdatedSuccess: state.entities.automaticProducts.productUpdatedSuccess,
});

const creators = {
  updateProductStatus:
    automaticProductsActions.creators.updateProductStatus.request,
};

export default connect(mapStateToProps, creators)(ActivateModal);
