import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 16 20';

const FormIcon = ({ fill }: { fill: string }) => (
  <g>
    <path
      key={0}
      fillRule="nonzero"
      stroke={fill}
      fill={fill}
      strokeWidth=".5"
      d="M16.6 11.6v9.1c0 .8-.3 1.1-1.1 1.1H1.8c-.8 0-1.2-.4-1.2-1.2V2.4c0-.8.4-1.2 1.2-1.2h13.7c.8 0 1.2.4 1.2 1.2-.1 3.1-.1 6.2-.1 9.2zm-.7-.6H1.2V20.7c0 .4.1.5.6.5h13.5c.6 0 .6-.1.6-.6v-9.2-.4zm0-.7v-.2-7.6c0-.4-.2-.5-.6-.5H1.8c-.5 0-.6.1-.6.6v7.7h14.7z"
    />
    <path
      key={1}
      stroke={fill}
      fill={fill}
      strokeWidth=".5"
      d="M11.7 8H8.6c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h3.1c.2 0 .3.1.3.3 0 .2-.1.3-.3.3zM14.9 5.3H8.6c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h6.3c.2 0 .3.1.3.3 0 .2-.1.3-.3.3zM14.2 13.7H2.8c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h11.4c.2 0 .3.1.3.3 0 .2-.2.3-.3.3zM4.8 18.5H2.7c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h2.1c.2 0 .3.1.3.3 0 .2-.1.3-.3.3zM8.6 18.5H6.5c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h2.1c.2 0 .3.1.3.3 0 .2-.1.3-.3.3zM14.2 15.8H2.8c-.2 0-.3-.1-.3-.3 0-.2.1-.3.3-.3h11.4c.2 0 .3.1.3.3 0 .2-.2.3-.3.3z"
    />
    <path
      key={2}
      stroke={fill}
      fill={fill}
      fillRule="nonzero"
      d="M5.001 5.714a.854.854 0 0 0 .856-.857.857.857 0 1 0-.856.857zM5 6.286c-.666 0-2 .334-2 1V8h4v-.714c0-.666-1.334-1-2-1z"
    />
  </g>
);

export default BaseIcon(FormIcon, viewBox);
