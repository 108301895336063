import { Effect, call, takeLatest } from 'redux-saga/effects';

import promoterActions from '../actions/promoter';
import runner from './runners';

interface IFetchPromoterInfoPayload {
  promoterId: number;
}

function* fetchPromoterInfo(
  action: IReduxAction<IFetchPromoterInfoPayload>
): Iterator<Effect> {
  const { promoterId } = action.payload;

  yield call(runner, promoterActions.creators.fetchPromoterInfo, {
    method: 'GET',
    url: `/api/promoter/${promoterId}`,
  });
}

function* watchPromoterInfoFetchRequested() {
  yield takeLatest(
    promoterActions.types.PROMOTERS_INFO_FETCH.REQUESTED,
    fetchPromoterInfo
  );
}

export default {
  watchPromoterInfoFetchRequested,
};
