import { handleActions } from 'redux-actions';

import utils from './utils';

const initialState = {};

const priceQuoteReducer = handleActions(
  {
    CREATE_PRICE_QUOTE_COMPLETED: (state, { payload }) => {
      const priceQuote = payload.offers.map(offer => ({
        ...offer,
        requestPeriod: offer.numberOfPayments,
      }));
      return {
        ...initialState,
        offers: utils.buildDictWithKey(priceQuote, 'id'),
      };
    },
    CLEAR_PRICE_QUOTE_OFFERS: () => initialState,
    SAVE_PRICE_QUOTE_DATA: (state, { payload }) => {
      return {
        ...state,
        formValues: payload,
      };
    },
  },
  initialState
);

export default priceQuoteReducer;
