import { handleActions } from 'redux-actions';

const initialState = {
  userModified: false,
  users: null,
};

const userManagementReducer = handleActions(
  {
    USERS_FETCH_COMPLETED: (state, action) => {
      return {
        userModified: false,
        users: action.payload,
      };
    },
    CHANGE_USER_STATUS_COMPLETED: state => {
      return {
        ...state,
        userModified: true,
      };
    },
    CHANGE_USER_ROLE_COMPLETED: state => {
      return {
        ...state,
        userModified: true,
      };
    },
  },
  initialState
);

export default userManagementReducer;
