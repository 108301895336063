import { FormikProps } from 'formik';
import { Form } from 'formik';
import clone from 'ramda/src/clone';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import keys from 'ramda/src/keys';
import length from 'ramda/src/length';
import values from 'ramda/src/values';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import profilingActions from '../../actions/profiling';
import {
  EDUCATION_LEVEL_OPTIONS,
  EMPLOYMENT_OPTIONS,
  HOME_OPTIONS,
  MARITAL_OPTIONS,
  PRODUCT_OPTIONS,
  REGIME_OPTIONS,
  TRUE_FALSE_OPTIONS,
  VEHICLE_TYPE_OPTIONS,
} from '../../constants/applicationOptions';
import { Suggestions } from '../../hooks/useProfilingSuggestions';
import { getMonthlyPayments } from '../../utils/profiling';
import { Option } from '../Dropdown/Dropdown';
import {
  FormikCurrency,
  FormikDate,
  FormikDropdown,
  FormikPercentageCurrency,
  FormikRadio,
  FormikRadioLabels,
  FormikRadioRange,
} from '../FormikInputs';
import LoadingIndicator from '../LoadingIndicator';
import errors from './errorMessages';

const LoadingState = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 176px;
`;

const FormContainer = styled.div`
  width: 100%;
`;

interface IOption {
  text: string;
  value: number | string;
}

interface IVehicle {
  createdAt: Date;
  id: number;
  manufacturerId: number;
  model: string;
  updatedAt: Date;
  vehicleType: string;
  version: string;
}

interface IManufacturer {
  createdAt: Date;
  id: number;
  name: string;
  updatedAt: Date;
  vehicles: IVehicle[];
}

interface IManufacturers {
  [key: string]: IManufacturer;
}

export interface IApplicantForm {
  existentUser: boolean;
  academicDegree: string;
  creditRecordStatus: string;
  hasCosigner: boolean;
  maritalStatus: string;
  regime: string;
}

export interface ICosignerForm {
  sameAddressAsSolicitant: boolean;
  spouseAsCosigner: boolean;
  employmentStatus: string;
  income: number;
}

export interface IAddressForm {
  homeType: string;
  rent: number;
  antiquity: Date;
  addressInId: boolean;
  dependents: number;
}

export interface IEmploymentForm {
  employmentStatus: string;
  contract: string;
  fixedIncomeQuantity: number;
  employmentDate: Date | undefined;
  sourceOtherIncome: string;
  otherIncomeQuantity: number;
  previousEmployment: boolean;
}

export interface IProductForm {
  productType: string;
  vehicleType: string;
  brand: number;
  version: number;
  model: string;
  price: number;
  downpayment: number;
  term: number;
  insurance: string;
}

export interface IContactForm {
  email: string;
  phoneNumber: string;
}

export interface IExistentAccountForm {
  email: string;
  password: string;
}

export interface IProfilingForm {
  applicant: IApplicantForm;
  cosigner: ICosignerForm;
  address: IAddressForm;
  employment: IEmploymentForm;
  product: IProductForm;
  [key: string]:
    | IApplicantForm
    | ICosignerForm
    | IAddressForm
    | IEmploymentForm
    | IProductForm;
}

export interface IProfilingFormComplete {
  applicant: IApplicantForm;
  cosigner: ICosignerForm;
  address: IAddressForm;
  employment: IEmploymentForm;
  product: IProductForm;
  contact: IContactForm;
  [key: string]:
    | IApplicantForm
    | ICosignerForm
    | IAddressForm
    | IEmploymentForm
    | IProductForm
    | IContactForm;
}

interface IProductFormStateProps {
  promoterProducts: any;
  productsManufacturers: any;
  fetchingProductsManufacturers: any;
}

export const initialValues: IProfilingForm = {
  applicant: {
    existentUser: false,
    academicDegree: '',
    creditRecordStatus: 'no_debt',
    hasCosigner: false,
    maritalStatus: '',
    regime: 'separate',
  },
  address: {
    homeType: '',
    rent: 0,
    antiquity: new Date(''),
    addressInId: true,
    dependents: 0,
  },
  cosigner: {
    sameAddressAsSolicitant: true,
    spouseAsCosigner: false,
    employmentStatus: '',
    income: 0,
  },
  employment: {
    employmentStatus: '',
    contract: '',
    fixedIncomeQuantity: 0,
    employmentDate: undefined,
    sourceOtherIncome: 'no_apply',
    otherIncomeQuantity: 0,
    previousEmployment: false,
  },
  product: {
    productType: 'motorcycle_finance',
    vehicleType: 'new',
    brand: 0,
    version: 0,
    model: '',
    price: 0,
    downpayment: 0,
    term: 0,
    insurance: 'no_insurance',
  },
};

const profileSchema: { [key: string]: any } = {
  applicant: Yup.object().shape({
    academicDegree: Yup.string().required(
      errors.applicant.academicDegree.required
    ),
    creditRecordStatus: Yup.string().required(
      errors.applicant.creditRecordStatus.required
    ),
    maritalStatus: Yup.string().required(
      errors.applicant.maritalStatus.required
    ),
  }),
  cosigner: Yup.object().shape({}),
  address: Yup.object().shape({
    homeType: Yup.string().required(errors.address.homeType.required),
    rent: Yup.number()
      .transform(val => (isNaN(val) ? 0 : val))
      .when('homeType', {
        is: 'rent',
        then: Yup.number()
          .typeError(errors.address.rent.number)
          .moreThan(0, errors.address.rent.moreThanZero)
          .required(errors.address.rent.required),
      }),
    antiquity: Yup.date()
      .typeError(errors.address.antiquity.date)
      .max(new Date(), errors.address.antiquity.date)
      .required(errors.address.antiquity.required),
  }),
  employment: Yup.object().shape({
    employmentStatus: Yup.string().required(
      errors.employment.employmentStatus.required
    ),
    contract: Yup.string().when('employmentStatus', {
      is: val => val === 'employed',
      then: Yup.string().required(errors.employment.contract.required),
    }),
    fixedIncomeQuantity: Yup.number()
      .transform(val => (isNaN(val) ? 0 : val))
      .when('employmentStatus', {
        is: val =>
          val === 'employed' || val === 'independent' || val === 'retired',
        then: Yup.number()
          .moreThan(0, errors.employment.fixedIncomeQuantity.moreThanZero)
          .typeError(errors.employment.fixedIncomeQuantity.number)
          .required(errors.employment.fixedIncomeQuantity.required),
      }),
    sourceOtherIncome: Yup.string().required(
      errors.employment.sourceOtherIncome.required
    ),
    otherIncomeQuantity: Yup.number()
      .transform(val => (isNaN(val) ? 0 : val))
      .when('sourceOtherIncome', {
        is: val => val !== 'no_apply',
        then: Yup.number()
          .moreThan(0, errors.employment.otherIncomeQuantity.moreThanZero)
          .typeError(errors.employment.otherIncomeQuantity.number)
          .required(errors.employment.otherIncomeQuantity.required),
      }),
    employmentDate: Yup.date()
      .transform(val => (isNaN(val.getDate()) ? new Date() : val))
      .when('employmentStatus', {
        is: val => val === 'employed' || val === 'independent',
        then: Yup.date()
          .typeError(errors.employment.employmentDate.date)
          .max(new Date(), errors.employment.employmentDate.date)
          .required(errors.employment.employmentDate.required),
      }),
  }),
  product: Yup.object().shape({
    brand: Yup.number()
      .moreThan(0, errors.product.brand.required)
      .required(errors.product.brand.required),
    version: Yup.number()
      .moreThan(0, errors.product.version.required)
      .required(errors.product.version.required),
    model: Yup.number().required(errors.product.model.required),
    price: Yup.number()
      .typeError(errors.product.price.number)
      .moreThan(0, errors.product.price.moreThanZero)
      .required(errors.product.price.required),
    downpayment: Yup.number()
      .typeError(errors.product.downpayment.number)
      .moreThan(0, errors.product.downpayment.moreThanZero)
      .notOneOf([Yup.ref('price')], errors.product.downpayment.max)
      .required(errors.product.downpayment.required),
    insurance: Yup.string().required(errors.product.insurance.required),
    term: Yup.number()
      .typeError(errors.product.term.number)
      .positive(errors.product.term.required)
      .required(errors.product.term.required),
  }),
};

const cosignerSchema = Yup.object().shape({
  employmentStatus: Yup.string().required(
    errors.cosigner.employmentStatus.required
  ),
  income: Yup.number()
    .transform(val => (isNaN(val) ? 0 : val))
    .when('employmentStatus', {
      is: val => ['employed', 'independent', 'retired'].includes(val),
      then: Yup.number()
        .typeError(errors.cosigner.income.number)
        .positive(errors.cosigner.income.positive)
        .required(errors.cosigner.income.required),
    }),
});

export const getProfilingSchema = (
  applicantHasCosigner: boolean
): { [key: string]: any } => {
  if (applicantHasCosigner) {
    const copy = clone(profileSchema);
    copy.cosigner = cosignerSchema;
    return copy;
  }
  return profileSchema;
};

const ConnectedApplicantForm: React.FunctionComponent<{
  formikProps: FormikProps<IApplicantForm>;
  setDropdownOpen(isOpen: boolean): void;
  suggestions: Suggestions[];
  startProfiling: Function;
}> = ({ formikProps, setDropdownOpen, suggestions, startProfiling }) => {
  useEffect(() => {
    if (formikProps.dirty) {
      startProfiling();
    }
  }, [formikProps.dirty, startProfiling]);
  return (
    <FormContainer>
      <Form>
        <FormikDropdown
          label="NIVEL DE ESTUDIOS"
          name="academicDegree"
          options={EDUCATION_LEVEL_OPTIONS}
          placeholder="Selecciona"
          onOpen={() => setDropdownOpen(true)}
          onClose={() => setDropdownOpen(false)}
        />
        <FormikDropdown
          label="ESTADO CIVIL"
          name="maritalStatus"
          options={MARITAL_OPTIONS}
          placeholder="Selecciona"
          onOpen={() => setDropdownOpen(true)}
          onClose={() => setDropdownOpen(false)}
        />
        {formikProps.values.maritalStatus === 'married' ? (
          <FormikRadio
            label="RÉGIMEN MATRIMONIAL"
            name="regime"
            options={REGIME_OPTIONS}
            alignment="vertical"
          />
        ) : null}
      </Form>
    </FormContainer>
  );
};

const creatorsForm = {
  startProfiling: profilingActions.creators.startProfiling,
};

export const ApplicantForm = connect(
  null,
  creatorsForm
)(ConnectedApplicantForm);

export const CosignerForm: React.FunctionComponent<{
  formikProps: FormikProps<ICosignerForm>;
  completeFormValues?: IProfilingForm;
  setDropdownOpen(isOpen: boolean): void;
}> = ({ formikProps, completeFormValues, setDropdownOpen }) => {
  const maritalStatus = completeFormValues?.applicant.maritalStatus;
  const { setFieldValue } = formikProps;

  useEffect(() => {
    setFieldValue('spouseAsCosigner', maritalStatus === 'married');
  }, [maritalStatus, setFieldValue]);

  return (
    <FormContainer>
      <Form>
        <FormikRadio
          label="¿COMPARTE DOMICILIO CON EL SOLICITANTE?"
          name="sameAddressAsSolicitant"
          alignment="center"
          options={TRUE_FALSE_OPTIONS}
        />
        {completeFormValues?.applicant.maritalStatus === 'married' ? (
          <FormikRadio
            label="¿EL CÓNYUGE ES EL COACREDITADO?"
            name="spouseAsCosigner"
            alignment="center"
            options={TRUE_FALSE_OPTIONS}
          />
        ) : null}
        <FormikDropdown
          label="SITUACIÓN LABORAL DEL COACREDITADO"
          name="employmentStatus"
          options={EMPLOYMENT_OPTIONS}
          placeholder="Selecciona"
          onOpen={() => setDropdownOpen(true)}
          onClose={() => setDropdownOpen(false)}
        />
        {['employed', 'independent', 'retired'].includes(
          formikProps.values.employmentStatus
        ) ? (
          <FormikCurrency
            label="INGRESO FIJO MENSUAL DEL COACREDITADO"
            name="income"
          />
        ) : null}
      </Form>
    </FormContainer>
  );
};

export const AddressForm: React.FunctionComponent<{
  formikProps: FormikProps<IAddressForm>;
  setDropdownOpen(isOpen: boolean): void;
}> = ({ formikProps, setDropdownOpen }) => {
  return (
    <FormContainer>
      <Form>
        <FormikDropdown
          label="TIPO DE DOMICILIO"
          name="homeType"
          options={HOME_OPTIONS}
          placeholder={'Selecciona'}
          onOpen={() => setDropdownOpen(true)}
          onClose={() => setDropdownOpen(false)}
        />
        {formikProps.values.homeType === 'rent' ? (
          <FormikCurrency
            label="RENTA MENSUAL"
            name="rent"
            inputMode="numeric"
          />
        ) : null}
        <FormikDate
          label="FECHA DE INGRESO AL DOMICILIO"
          name="antiquity"
          ignoreDay={true}
          inputMode="numeric"
        />
        <FormikRadio
          label="¿VIVE EN EL DOMICILIO DE SU IDENTIFICACIÓN?"
          name="addressInId"
          alignment="center"
          options={TRUE_FALSE_OPTIONS}
        />
        <FormikRadioRange
          label="DEPENDIENTES ECONÓMICOS"
          name="dependents"
          lastHasPlus={true}
          maximum={8}
          minimum={0}
        />
      </Form>
    </FormContainer>
  );
};

export const EmploymentForm: React.FunctionComponent<{
  formikProps: FormikProps<IEmploymentForm>;
  setDropdownOpen(isOpen: boolean): void;
}> = ({ formikProps, setDropdownOpen }) => {
  const isEmployed = formikProps.values.employmentStatus === 'employed';
  const isIndependent = formikProps.values.employmentStatus === 'independent';
  const isRetired = formikProps.values.employmentStatus === 'retired';
  const employmentStatus = formikProps.values.employmentStatus;
  const { setFieldValue } = formikProps;

  useEffect(() => {
    if (employmentStatus === 'employed') {
      setFieldValue('contract', 'undetermined_term');
    }

    if (employmentStatus === 'retired') {
      setFieldValue('previousEmployment', true);
    } else {
      setFieldValue('previousEmployment', false);
    }
  }, [employmentStatus, setFieldValue]);

  return (
    <FormContainer>
      <Form>
        <FormikDropdown
          label="SITUACIÓN LABORAL"
          name="employmentStatus"
          options={EMPLOYMENT_OPTIONS}
          placeholder="Selecciona"
          onOpen={() => setDropdownOpen(true)}
          onClose={() => setDropdownOpen(false)}
        />
        {isEmployed || isIndependent || isRetired ? (
          <FormikCurrency
            label="INGRESO FIJO MENSUAL"
            name="fixedIncomeQuantity"
            inputMode="numeric"
          />
        ) : null}
        {isEmployed || isIndependent ? (
          <FormikDate
            label="FECHA DE INGRESO LABORAL"
            name="employmentDate"
            ignoreDay={true}
            inputMode="numeric"
          />
        ) : null}
      </Form>
    </FormContainer>
  );
};

const ConnectedProductForm: React.FunctionComponent<{
  formikProps: FormikProps<IProductForm>;
  promoterProducts: {
    carFinance?: number[];
    motorcycleFinance?: number[];
  };
  productsManufacturers: IManufacturers;
  fetchingProductsManufacturers: boolean;
  setDropdownOpen(isOpen: boolean): void;
  suggestions: Suggestions[];
}> = ({
  formikProps,
  promoterProducts,
  productsManufacturers,
  fetchingProductsManufacturers,
  setDropdownOpen,
  suggestions,
}) => {
  const [downpayment, setDownpayment] = useState<number>(
    formikProps.initialValues.downpayment
  );
  const brand = formikProps.values.brand;
  const { setFieldValue } = formikProps;

  useEffect(() => {
    if (
      isEmpty(brand) &&
      !fetchingProductsManufacturers &&
      !isNil(productsManufacturers) &&
      !isEmpty(productsManufacturers)
    ) {
      setFieldValue('brand', values(productsManufacturers)[0].id);
    }
  }, [
    brand,
    productsManufacturers,
    fetchingProductsManufacturers,
    setFieldValue,
  ]);

  useEffect(() => {
    const singleProduct = length(keys(promoterProducts)) === 1;
    if (singleProduct) {
      setFieldValue(
        'productType',
        keys(promoterProducts)[0] === 'carFinance'
          ? 'car_finance'
          : 'motorcycle_finance'
      );
    }
  }, [promoterProducts, setFieldValue]);

  const brandOptions = (): IOption[] =>
    values(productsManufacturers).map((manufacturer: IManufacturer) => ({
      text: manufacturer.name,
      value: manufacturer.id,
    }));

  const versionOptions = (): IOption[] => {
    if (
      fetchingProductsManufacturers ||
      isNil(productsManufacturers) ||
      isEmpty(productsManufacturers) ||
      isEmpty(formikProps.values.brand) ||
      isNil(productsManufacturers[formikProps.values.brand])
    ) {
      return [];
    } else {
      return productsManufacturers[formikProps.values.brand].vehicles.map(
        (vehicle: IVehicle) => ({
          text: vehicle.model,
          value: vehicle.id,
        })
      );
    }
  };

  const newModelOptions = (): IOption[] => {
    const year = new Date().getFullYear();
    const newModelOptions = [
      { text: `${year - 1}`, value: `${year - 1}` },
      { text: `${year}`, value: `${year}` },
      { text: `${year + 1}`, value: `${year + 1}` },
    ];
    return newModelOptions;
  };

  const usedModelOptions = (): IOption[] => {
    const today = new Date();
    const usedOptions: Option[] = [];
    for (let i = today.getFullYear() - 10; i <= today.getFullYear(); i++) {
      usedOptions.push({
        text: i.toString(),
        value: i.toString(),
      });
    }
    return usedOptions;
  };

  const sectionCompleted =
    !isEmpty(formikProps.values.brand) &&
    !isEmpty(formikProps.values.insurance) &&
    !isEmpty(formikProps.values.model) &&
    !isEmpty(formikProps.values.version) &&
    formikProps.values.downpayment > 0 &&
    formikProps.values.price > 0 &&
    formikProps.values.downpayment < formikProps.values.price;

  const brandAndVersion = (() => {
    if (fetchingProductsManufacturers) {
      return (
        <LoadingState>
          <LoadingIndicator />
        </LoadingState>
      );
    } else if (isNil(productsManufacturers) || isEmpty(productsManufacturers)) {
      return null;
    } else {
      return (
        <Fragment>
          <FormikDropdown
            label="MARCA"
            name="brand"
            options={brandOptions()}
            placeholder="Selecciona"
            onOpen={() => setDropdownOpen(true)}
            onClose={() => setDropdownOpen(false)}
          />
          <FormikDropdown
            label="VERSIÓN"
            name="version"
            options={versionOptions()}
            placeholder="Selecciona"
            onOpen={() => setDropdownOpen(true)}
            onClose={() => setDropdownOpen(false)}
          />
        </Fragment>
      );
    }
  })();

  return (
    <FormContainer>
      <Form>
        {length(keys(promoterProducts)) > 1 ? (
          <FormikRadio
            label="TIPO DE PRODUCTO"
            name="productType"
            options={PRODUCT_OPTIONS}
            alignment="center"
          />
        ) : null}
        {brandAndVersion}
        <FormikRadio
          label="TIPO DE PRODUCTO"
          name="vehicleType"
          options={VEHICLE_TYPE_OPTIONS}
          alignment="center"
        />
        <FormikDropdown
          label="MODELO"
          name="model"
          options={
            formikProps.values.vehicleType === 'new'
              ? newModelOptions()
              : usedModelOptions()
          }
          placeholder="Selecciona"
          onOpen={() => setDropdownOpen(true)}
          onClose={() => setDropdownOpen(false)}
        />
        <FormikCurrency
          label="VALOR"
          name="price"
          warning={suggestions.includes(Suggestions.CHANGE_VEHICLE)}
          inputMode="numeric"
        />
        <FormikPercentageCurrency
          label={
            !window.location.search.includes('promoterUserCode')
              ? 'ENGANCHE'
              : 'INGRESA UN ENGANCHE'
          }
          name="downpayment"
          total={formikProps.values.price}
          onBlur={() => {
            setDownpayment(formikProps.values.downpayment);
          }}
          warning={suggestions.includes(Suggestions.INCREASE_DOWNPAYMENT)}
          inputMode="numeric"
        />
        <FormikRadioLabels
          label={
            !window.location.search.includes('promoterUserCode')
              ? 'PLAZO Y PAGO ESTIMADO'
              : 'ELIGE PLAZO Y PAGO ESTIMADO'
          }
          name="term"
          options={getMonthlyPayments(
            promoterProducts,
            formikProps.values.productType,
            formikProps.values.price,
            downpayment,
            formikProps.values.insurance,
            sectionCompleted
          )}
          disabled={!sectionCompleted}
          warning={suggestions.includes(
            Suggestions.INCREASE_NUMBER_OF_PAYMENTS
          )}
        />
      </Form>
    </FormContainer>
  );
};

const mapStateToPropsProductForm = (state: any): IProductFormStateProps => ({
  promoterProducts: state.entities.promoterProducts,
  productsManufacturers: state.entities.productsManufacturers,
  fetchingProductsManufacturers: state.loaders.fetchingProductsManufacturers,
});

export const ProductForm = connect(mapStateToPropsProductForm)(
  ConnectedProductForm
);
