import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import idVerificationActions from '../../actions/idVerification';
import colors from '../../constants/colors';
import Card from '../Card';
import LoadingIndicator from '../LoadingIndicator';
import SmartTable, { TableCell } from '../SmartTable';

const Circle = styled.span<{ color: string }>`
  background-color: ${props => props.color};
  border-radius: 50%;
  height: 12px;
  width: 12px;
  margin-right: 4px;
`;

const Link = styled.span`
  color: ${colors.HYPERLINK};
  text-decoration: underline;
  cursor: pointer;
`;

const LoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 50vh;
  justify-content: center;
  width: 100%;
`;

const StatusContainer = styled.div`
  display: flex;
  vertical-align: middle;
  align-items: center;
  width: 100%;
`;

enum OfferStatus {
  ACCEPTED = 'aceptada',
  CLOSED = 'cerrada',
  REQUESTED = 'solicitada',
}

enum VotingIdStatus {
  NOT_VALIDATED = 'NOT_VALIDATED',
  VALIDATED = 'VALIDATED',
  VALIDATED_DIFFERENCES = 'VALIDATED_DIFFERENCES',
  EXPIRED = 'EXPIRED',
  EXPIRED_DIFFERENCES = 'EXPIRED_DIFFERENCES',
}

const votingIdStatusMap: Record<VotingIdStatus, string> = {
  NOT_VALIDATED: 'Pendiente',
  VALIDATED: 'Vigente y validada',
  VALIDATED_DIFFERENCES: 'Vigente y con diferencias',
  EXPIRED: 'Vencida y validada',
  EXPIRED_DIFFERENCES: 'Vencida y con diferencias',
};

interface IOffer {
  id: number;
  status: OfferStatus;
}

interface IPromoter {
  name: string;
}

interface IApplication {
  id: number;
  offers: IOffer[];
  promoter: IPromoter;
  createdAt: string;
}

interface IClient {
  name: string;
  firstSurname: string;
  secondSurname: string;
}

interface ICompliance {
  id: number;
  client: IClient;
  application: IApplication;
}

interface IBiometrictVerification {
  id: number;
  votingIdStatus: VotingIdStatus;
  compliance: ICompliance;
}

interface IIdVerificationProps {
  biometricVerifications: IBiometrictVerification[];
  fetchBiometricVerifications: Function;
  history: RouteComponentProps['history'];
}

const IdVerificationView: React.FunctionComponent<IIdVerificationProps> = ({
  biometricVerifications,
  fetchBiometricVerifications,
  history,
}) => {
  const [
    sortedBiometricVerifications,
    setSortedBiometricVerifications,
  ] = useState<IBiometrictVerification[]>();

  useEffect(() => {
    fetchBiometricVerifications();
  }, [fetchBiometricVerifications]);

  useEffect(() => {
    if (!(isNil(biometricVerifications) || isEmpty(biometricVerifications))) {
      setSortedBiometricVerifications(
        biometricVerifications.sort((a, b) => {
          return b.id - a.id;
        })
      );
    }
  }, [biometricVerifications]);

  const content = isNil(sortedBiometricVerifications) ? (
    <LoadingState>
      <LoadingIndicator />
    </LoadingState>
  ) : (
    <SmartTable
      data={sortedBiometricVerifications}
      gridColumns={`repeat(6, 1fr) minmax(152px, 1fr)`}
      headers={[
        'ID VERIFICACIÓN',
        'SOLICITUD',
        'OFERTA',
        'FECHA',
        'TITULAR',
        'COMERCIO',
        'ESTATUS',
      ]}
      renderRow={biometricVerification => {
        const id = (
          <Link
            onClick={() =>
              history.push(`/id-verification/${biometricVerification.id}`)
            }
          >
            {biometricVerification.id}
          </Link>
        );

        const acceptedOffer = biometricVerification.compliance.application.offers.find(
          offer => offer.status === OfferStatus.ACCEPTED
        );
        const acceptedOfferId = isNil(acceptedOffer) ? 'S/I' : acceptedOffer.id;
        const clientName = `${biometricVerification.compliance.client.name} ${biometricVerification.compliance.client.firstSurname} ${biometricVerification.compliance.client.secondSurname}`;

        const statusColor = () => {
          switch (biometricVerification.votingIdStatus) {
            case VotingIdStatus.NOT_VALIDATED:
              return colors.ORANGE;
            case VotingIdStatus.VALIDATED:
              return colors.GREEN;
            default:
              return colors.RED;
          }
        };
        const status = (
          <StatusContainer>
            <Circle color={statusColor()} />
            {votingIdStatusMap[biometricVerification.votingIdStatus]}
          </StatusContainer>
        );

        return [
          <TableCell key={0}>{id}</TableCell>,
          <TableCell key={1}>
            {biometricVerification.compliance.application.id}
          </TableCell>,
          <TableCell key={2}>{acceptedOfferId}</TableCell>,
          <TableCell key={3}>
            {biometricVerification.compliance.application.createdAt}
          </TableCell>,
          <TableCell key={4}>{clientName}</TableCell>,
          <TableCell key={5}>
            {biometricVerification.compliance.application.promoter.name}
          </TableCell>,
          <TableCell key={6}>{status}</TableCell>,
        ];
      }}
    />
  );

  return <Card header={'Verificaciones'}>{content}</Card>;
};
const mapStateToProps = (state: any) => ({
  biometricVerifications: state.entities.idVerification.verifications,
});

const creators = {
  fetchBiometricVerifications:
    idVerificationActions.creators.fetchBiometricVerifications.request,
};

export default connect(mapStateToProps, creators)(IdVerificationView);
