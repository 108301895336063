const buildToast = type => (text, dismissIn) => ({
  dismissIn,
  text,
  type,
});

const buildErrorToast = buildToast('error');

const buildSuccessToast = buildToast('success');

const buildWarningToast = buildToast('warning');

export { buildErrorToast, buildSuccessToast, buildWarningToast };
