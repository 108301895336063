import { handleActions } from 'redux-actions';

const initalState = {};

const addDocument = (state, { payload }) => ({
  ...state,
  [payload.documentId]: true,
});
const removeDocument = (state, { payload }) => ({
  ...state,
  [payload.data.id]: false,
});

const documentUpdatingIndicatorsReducer = handleActions(
  {
    DOCUMENT_GET_COMPLETED: removeDocument,
    DOCUMENT_UPDATE_STATUS_REQUESTED: addDocument,
  },
  initalState
);

export default documentUpdatingIndicatorsReducer;
