import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import colors from '../../constants/colors';
import { noop } from '../../utils/misc';
import { InputMode } from '../FormikInputs/FormikNumber';
import Label from '../Label';

const InputStyled = styled.input<{
  disabled?: boolean;
  error?: string;
  warning: boolean;
  isiOS: boolean;
  upperCase: boolean;
}>`
  ${props => (props.upperCase ? 'text-transform: uppercase;' : null)}
  background-color: ${colors.WHITE};
  border: 0;
  border-bottom: solid 2px
    ${({ error, warning }) => {
      if (error) {
        return colors.RED;
      } else if (warning) {
        return colors.ORANGE;
      } else {
        return colors.DUSK25;
      }
    }};
  color: ${({ disabled }) =>
    disabled ? colors.SECONDARY_TEXT : colors.PRIMARY_TEXT};
  font-family: 'Lato', sans-serif;
  ${props =>
    props.isiOS
      ? // make the element larger so that iOS doesn't zoom in on it and then scale it to keep it the same as the other platforms
        `
        font-size: 16px;
        padding: 9.142857143px 13.714285714px;
        width: 114.285714286%;
        transform: scale(0.875);
        transform-origin: left top;
        margin-right: -14.2857143%;
        margin-bottom: -14.2857143%;
        `
      : 'font-size: 14px; padding 8px 12px; width: 100%'};

  :focus {
    border-bottom: solid ${props => (props.isiOS ? '2.285714286' : '2px')}
      ${colors.AQUA};
  }

  ::placeholder {
    color: ${colors.SECONDARY_TEXT};
  }
`;

const ErrorMessageContainer = styled.div`
  margin-top: 4px;
`;

const InputContainer = styled.div<{ withErrorMessage: boolean }>`
  height: ${({ withErrorMessage }) => (withErrorMessage ? '88px' : 'auto')};
`;

interface IProps {
  disabled?: boolean;
  error?: string;
  label?: string;
  name?: string;
  maxLength?: number;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
  onKeyDown?(e: React.KeyboardEvent): void;
  onMouseUp?(e: React.MouseEvent): void;
  placeholder?: string;
  type?: string;
  value: string | number;
  withErrorMessage?: boolean;
  warning?: boolean;
  isiOS: boolean;
  upperCase?: boolean;
  tabIndex?: number;
  inputMode?: InputMode;
}

const Input: React.FunctionComponent<IProps> = ({
  disabled,
  error,
  label = '',
  name,
  maxLength,
  onBlur = noop,
  onChange = noop,
  onFocus = noop,
  onKeyDown = noop,
  onMouseUp = noop,
  placeholder = '',
  type = 'text',
  value,
  withErrorMessage = false,
  warning = false,
  isiOS,
  upperCase = false,
  tabIndex = undefined,
  inputMode = 'text',
}) => {
  const errorComponent =
    error && withErrorMessage ? (
      <ErrorMessageContainer>
        <Label error>{error}</Label>
      </ErrorMessageContainer>
    ) : null;

  return (
    <InputContainer withErrorMessage={withErrorMessage}>
      <div>
        <Label error={false}>{label}</Label>
      </div>
      <div>
        <InputStyled
          disabled={disabled}
          error={error}
          warning={warning}
          name={name}
          maxLength={maxLength}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onMouseUp={onMouseUp}
          placeholder={placeholder}
          type={type}
          value={value}
          isiOS={isiOS}
          upperCase={upperCase}
          tabIndex={tabIndex}
          inputMode={inputMode}
        />
      </div>
      {errorComponent}
    </InputContainer>
  );
};

const mapStateProps = (state: any): { isiOS: boolean } => ({
  isiOS: state.deviceData.isiOS,
});

export default connect(mapStateProps)(Input);
