import { delay } from 'redux-saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import toastActions from '../actions/toastNotifications';
import verificationModalActions from '../actions/verificationModal';
import history from '../history';
import { addSearchParamsToPayload, addSearchParamsToUrl } from '../utils/misc';
import {
  buildErrorToast,
  buildSuccessToast,
} from '../utils/toastNotifications';
import client from './client';
import runner from './runners';

function* createBiometricVerification(action) {
  const params = addSearchParamsToPayload(action.payload, ['promoterUserCode']);
  yield call(
    runner,
    verificationModalActions.creators.startVerification,
    {
      method: 'POST',
      url: '/api/requests/biometric-verification',
      payload: params,
    },
    { snakeCase: false }
  );

  if (action.payload.shouldSendSMS === false) {
    const url = addSearchParamsToUrl('/application/biometric-verification');
    yield call(history.push, url);
  }
}

function* watchCreateBiometricVerificationRequested() {
  yield takeLatest(
    verificationModalActions.types.VERIFICATION_START.REQUESTED,
    createBiometricVerification
  );
}

function* waitBiometricVerification(action) {
  const {
    completed,
    failed,
    started,
  } = verificationModalActions.creators.waitForVerificationCompletion;
  const { biometricVerificationId } = action.payload;

  try {
    yield put(started());
    const payload = addSearchParamsToPayload({}, ['promoterUserCode']);
    while (true) {
      yield delay(5000);

      const {
        data,
      } = yield client.get(
        `/api/biometric-verification/${biometricVerificationId}`,
        { params: payload }
      );
      const biometricVerification = data.biometricVerification;

      if (biometricVerification.used) {
        yield put(completed({ biometricVerification }));
        break;
      }
    }
  } catch (error) {
    yield put(failed());
  }
}

function* watchWaitBiometricVerificationRequested() {
  yield takeLatest(
    verificationModalActions.types.VERIFICATION_WAIT_COMPLETION.REQUESTED,
    waitBiometricVerification
  );
}

function* getCurpFromVerification(action) {
  const payload = addSearchParamsToPayload(action.payload, [
    'promoterUserCode',
  ]);
  yield call(
    runner,
    verificationModalActions.creators.getCurpFromVerification,
    {
      method: 'POST',
      url: '/api/rekognition/extract-data',
      payload: payload,
    },
    { snakeCase: false }
  );
}

function* watchGetCurpFromVerificationRequested() {
  yield takeLatest(
    verificationModalActions.types.VERIFICATION_GET_CURP.REQUESTED,
    getCurpFromVerification
  );
}

function* getPrefillingData(action) {
  const payload = addSearchParamsToPayload({}, ['promoterUserCode']);
  const { error } = yield call(
    runner,
    verificationModalActions.creators.getPrefillingData,
    {
      method: 'GET',
      url: `/api/renapo/prefilling-data/${action.payload.curp}`,
      params: payload,
    },
    { snakeCase: false }
  );

  if (error && error.response && error.response.status === 404) {
    const toast = buildErrorToast(
      'No se encontro la información de esta CURP. Asegurese que sea la correcta.',
      5000
    );
    yield put(toastActions.creators.showToast(toast));
  }
}

function* watchGetPrefillingDataRequested() {
  yield takeLatest(
    verificationModalActions.types.VERIFICATION_GET_PREFILLING_DATA.REQUESTED,
    getPrefillingData
  );
}

function* sendVerificationViaWhatsApp(action) {
  const { phoneNumber } = action.payload;

  const { biometricVerificationId } = yield select(
    state => state.newApplication.verificationModal
  );

  if (!biometricVerificationId) {
    return;
  }

  const payload = addSearchParamsToPayload(
    {
      biometricVerificationId,
      phoneNumber,
    },
    ['promoterUserCode']
  );

  const { error } = yield call(
    runner,
    verificationModalActions.creators.sendVerificationViaWhatsapp,
    {
      method: 'POST',
      payload: payload,
      url: '/api/requests/biometric-verification/resend',
    },
    { snakeCase: false }
  );

  if (error) {
    yield put(
      toastActions.creators.showToast(
        buildErrorToast(
          'Hubo un problema reenviando la liga de verificación. Por favor verifica que el teléfono sea correcto. Si el problema persiste por favor contacta a soporte al número: (331) 803-7895.',
          5000
        )
      )
    );
  } else {
    yield put(
      toastActions.creators.showToast(
        buildSuccessToast('Liga de verificación reenviada con éxito.', 5000)
      )
    );
  }
}

function* watchSendVerificationViaWhatsAppRequested() {
  yield takeLatest(
    verificationModalActions.types.VERIFICATION_SEND_VIA_WHATSAPP.REQUESTED,
    sendVerificationViaWhatsApp
  );
}

export default {
  watchCreateBiometricVerificationRequested,
  watchWaitBiometricVerificationRequested,
  watchGetCurpFromVerificationRequested,
  watchGetPrefillingDataRequested,
  watchSendVerificationViaWhatsAppRequested,
};
