import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

import Button from '../Button';
import ConfirmationDialog from '../ConfirmationDialog';

const DialogContent = styled.div`
  font-size: 14px;
  text-align: justify;
`;

interface IConfirmationDialogButton {
  isReadOnly: boolean;
  buttonText: string;
  dialogTitle: string;
  dialogRender: () => string;
  onDialogConfirm: () => void;
  onDialogCancel: () => void;
}

class ConfirmationDialogButton extends Component<IConfirmationDialogButton> {
  state = {
    open: false,
  };

  onButtonClick = () => {
    this.setState(() => ({
      open: true,
    }));
  };

  onDialogCancel = () => {
    const { onDialogCancel } = this.props;

    this.setState(() => ({
      open: false,
    }));

    onDialogCancel();
  };

  onDialogConfirm = () => {
    const { onDialogConfirm } = this.props;

    this.setState(() => ({
      open: false,
    }));

    onDialogConfirm();
  };

  render() {
    const { open } = this.state;
    const { buttonText, dialogTitle, dialogRender, isReadOnly } = this.props;

    const dialogContent = () => <DialogContent>{dialogRender()}</DialogContent>;

    return (
      <Fragment>
        <Button
          onClick={this.onButtonClick}
          variant={isReadOnly ? 'inactive' : 'primary'}
        >
          {buttonText}
        </Button>
        <ConfirmationDialog
          onCancel={this.onDialogCancel}
          onConfirm={this.onDialogConfirm}
          open={open}
          render={dialogContent}
          title={dialogTitle}
        />
      </Fragment>
    );
  }
}

export default ConfirmationDialogButton;
