export const PROFILE_ID_BANK = 1;
export const PROFILE_ID_PROMOTER = 2;
export const PROFILE_ID_WEB = 4;
export const PROFILE_ID_CREDITUS = 5;

const getUserProfileId = (userData: IUserData): number => userData.user.profile;

const getBankUserRole = (userData: IBankUserData): BankUserRoles =>
  userData.organization.role;

const isClientUser = (userData: IUserData): userData is IClientUserData =>
  userData.type === 'client';

const isCreditusUser = (userData: IUserData): userData is ICreditusUserData =>
  userData.type === 'creditus';

const isBankUser = (userData: IUserData): userData is IBankUserData =>
  userData.type === 'bank';

const isBankSystemAdmin = (userData: IBankUserData): boolean =>
  userData.organization.role === 'system';

const isBankAdmin = (userData: IBankUserData): boolean =>
  ['system', 'admin'].includes(userData.organization.role);

const isBankAnalyst = (userData: IBankUserData): boolean =>
  userData.organization.role === 'analyst';

const isBankSuperAnalyst = (userData: IBankUserData): boolean =>
  userData.organization.role === 'super-analyst';

const isBankLegal = (userData: IBankUserData): boolean =>
  userData.organization.role === 'legal';

const isPromoterUser = (userData: IUserData): userData is IPromoterUserData =>
  userData.type === 'promoter';

const isPromoterSystemAdmin = (userData: IPromoterUserData): boolean =>
  userData.organization.role === 'system';

const isPromoterAdmin = (userData: IPromoterUserData): boolean =>
  ['system', 'admin'].includes(userData.organization.role);

const isPromoterSalesperson = (userData: IPromoterUserData): boolean =>
  userData.organization.role === 'salesperson';

const isPromoterSuperSalesperson = (userData: IPromoterUserData): boolean =>
  userData.organization.role === 'super-salesperson';

const isPromoterFNI = (userData: IPromoterUserData): boolean =>
  userData.organization.role === 'f&i';

export {
  getBankUserRole,
  getUserProfileId,
  isBankAdmin,
  isBankAnalyst,
  isBankLegal,
  isBankSuperAnalyst,
  isBankSystemAdmin,
  isBankUser,
  isClientUser,
  isCreditusUser,
  isPromoterAdmin,
  isPromoterFNI,
  isPromoterSystemAdmin,
  isPromoterSalesperson,
  isPromoterSuperSalesperson,
  isPromoterUser,
};
