const sectionFormSendErrors = {
  street: { required: 'La calle es un campo obligatorio.' },
  exteriorNumber: {
    required: 'El número exterior es un campo obligatorio.',
  },
  postalCode: {
    matches: 'El código postal no es válido.',
    required: 'El código postal es un campo obligatorio.',
  },
  neighborhood: { required: 'La colonia es un campo obligatorio.' },
  cosignerAddress: {
    street: {
      required: 'La calle es un campo obligatorio.',
    },
    exteriorNumber: {
      required: 'El número exterior es un campo obligatorio.',
    },
    postalCode: {
      matches: 'El código postal no es válido.',
      required: 'El código postal es un campo obligatorio.',
    },
    neighborhood: {
      required: 'La colonia es un campo obligatorio.',
    },
  },
};

export default sectionFormSendErrors;
