import { createAction } from 'redux-actions';

import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const BANKS_INFO_FETCH = createAsyncFlowTypes('BANKS_INFO_FETCH');
const BANKS_FETCH = createAsyncFlowTypes('BANKS_FETCH');
const BANKS_FETCH_BY_PRODUCT_TYPE = createAsyncFlowTypes(
  'BANKS_FETCH_BY_PRODUCT_TYPE'
);
const BANKS_UPDATE_OFFER_VIGENCY = createAsyncFlowTypes(
  'BANKS_UPDATE_OFFER_VIGENCY'
);
const CLEAR_BANKS_UPDATE_OFFER_VIGENCY = 'CLEAR_BANKS_UPDATE_OFFER_VIGENCY';

const fetchBankInfo = createAsyncFlowCreators(BANKS_INFO_FETCH);
const fetchBanks = createAsyncFlowCreators(BANKS_FETCH);
const fetchBanksByProductType = createAsyncFlowCreators(
  BANKS_FETCH_BY_PRODUCT_TYPE
);
const banksUpdateOfferVigency = createAsyncFlowCreators(
  BANKS_UPDATE_OFFER_VIGENCY
);
const clearBanksUpdateOfferVigency = createAction(
  CLEAR_BANKS_UPDATE_OFFER_VIGENCY
);

export default {
  types: {
    BANKS_INFO_FETCH,
    BANKS_FETCH,
    BANKS_FETCH_BY_PRODUCT_TYPE,
    BANKS_UPDATE_OFFER_VIGENCY,
    CLEAR_BANKS_UPDATE_OFFER_VIGENCY,
  },
  creators: {
    fetchBankInfo,
    fetchBanks,
    fetchBanksByProductType,
    banksUpdateOfferVigency,
    clearBanksUpdateOfferVigency,
  },
};
