import { FormikProps } from 'formik';
import { useEffect } from 'react';

import calculateCURP from '../utils/curp';

interface Person {
  name: string;
  firstSurname: string;
  secondSurname: string;
  sex: string;
  stateOfBirth: string;
  dateOfBirth: string | Date;
}

const useAutoTaxPayerIdAndCURPFill = (
  formikProps: FormikProps<any>,
  person: Person | null,
  key: string
) => {
  const { setFieldValue } = formikProps;
  const personKey = key === '' ? '' : `${key}.`;

  useEffect(() => {
    if (person === null) {
      return;
    }

    const birthDateAsDate = new Date(person.dateOfBirth);
    const curp = calculateCURP({
      name: person.name,
      firstSurname: person.firstSurname,
      secondSurname: person.secondSurname,
      sex: person.sex === 'male' ? 'H' : 'M',
      state: person.stateOfBirth,
      dateOfBirth: [
        birthDateAsDate.getDate(),
        birthDateAsDate.getMonth() + 1,
        birthDateAsDate.getFullYear(),
      ],
    });

    if (curp) {
      setFieldValue(`${personKey}curp`, curp);
      setFieldValue(`${personKey}taxPayerId`, curp.substring(0, 10));
    } else {
      setFieldValue(`${personKey}curp`, '');
      setFieldValue(`${personKey}taxPayerId`, '');
      setFieldValue(`${personKey}taxPayerIdComplement`, '');
    }
  }, [person, personKey, setFieldValue]);
};

export default useAutoTaxPayerIdAndCURPFill;
