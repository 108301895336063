import length from 'ramda/src/length';
import { useEffect, useState } from 'react';

import { getBanks } from './../utils/newApplication';

const usePromoterBanks = (financeType: string) => {
  const [banks, setBanks] = useState<Array<any>>([]);

  useEffect(() => {
    getBanks(financeType).then(response => {
      const banksRaw = response.data;
      const banksClean = length(banksRaw)
        ? banksRaw.map((item: { bank: { name: any; id: any } }) => {
            return { label: item.bank.name, value: item.bank.id };
          })
        : [];
      setBanks(banksClean);
    });
  }, [financeType]);

  return banks;
};

export default usePromoterBanks;
