const BORDER = '#EAEDF3';
const HYPERLINK = '#58B1E6';
const PRIMARY_TEXT = '#262A3E';
const SECONDARY_TEXT = '#8F93A1';

interface Color {
  r: number;
  g: number;
  b: number;
}

const shade = (color: Color, opacity?: number): string =>
  `rgba(${color.r}, ${color.g}, ${color.b}, ${opacity ? opacity : 1})`;

const AQUA: Color = { r: 0, g: 195, b: 168 };
const BACKGROUND: Color = { r: 243, g: 245, b: 249 };
const MOBILE_BACKGROUND: Color = { r: 249, g: 249, b: 249 };
const DUSK: Color = { r: 66, g: 71, b: 112 };
const GREEN: Color = { r: 52, g: 170, b: 68 };
const ORANGE: Color = { r: 246, g: 171, b: 47 };
const RED: Color = { r: 230, g: 73, b: 45 };
const WHITE: Color = { r: 255, g: 255, b: 255 };

const ALASKA_GRAY = '#EAECEE';
const BLUE_GRAY = '#9EA0A5';
const WATER_BLUE = '#1665D8';

const BOX_SHADOW = '0 1px 3px 0 rgba(0, 0, 0, 0.04)';

export default {
  BORDER,
  HYPERLINK,
  PRIMARY_TEXT,
  SECONDARY_TEXT,

  AQUA: shade(AQUA),
  AQUA75: shade(AQUA, 0.75),
  AQUA50: shade(AQUA, 0.5),
  AQUA25: shade(AQUA, 0.25),

  BACKGROUND: shade(BACKGROUND),
  BACKGROUND75: shade(BACKGROUND, 0.75),
  BACKGROUND50: shade(BACKGROUND, 0.5),
  BACKGROUND25: shade(BACKGROUND, 0.25),

  MOBILE_BACKGROUND: shade(MOBILE_BACKGROUND),
  MOBILE_BACKGROUND75: shade(MOBILE_BACKGROUND, 0.75),
  MOBILE_BACKGROUND50: shade(MOBILE_BACKGROUND, 0.5),
  MOBILE_BACKGROUND25: shade(MOBILE_BACKGROUND, 0.25),

  DUSK: shade(DUSK),
  DUSK75: shade(DUSK, 0.75),
  DUSK50: shade(DUSK, 0.5),
  DUSK25: shade(DUSK, 0.25),

  GREEN: shade(GREEN),
  GREEN75: shade(GREEN, 0.75),
  GREEN50: shade(GREEN, 0.5),
  GREEN25: shade(GREEN, 0.25),

  ORANGE: shade(ORANGE),
  ORANGE75: shade(ORANGE, 0.75),
  ORANGE50: shade(ORANGE, 0.5),
  ORANGE25: shade(ORANGE, 0.25),

  RED: shade(RED),
  RED75: shade(RED, 0.75),
  RED50: shade(RED, 0.5),
  RED25: shade(RED, 0.25),

  WHITE: shade(WHITE),
  WHITE75: shade(WHITE, 0.75),
  WHITE50: shade(WHITE, 0.5),
  WHITE25: shade(WHITE, 0.25),

  ALASKA_GRAY,
  BLUE_GRAY,
  WATER_BLUE,

  BOX_SHADOW,
};
