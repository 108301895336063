import addMinutes from 'date-fns/addMinutes';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import contains from 'ramda/src/contains';

import f from '../constants/formatters';
import grammar from '../constants/grammar';

const finalOfferAvailable = offer =>
  contains(offer.stage, [
    'approval',
    'pre-formalization',
    'formalization',
    'release',
  ]);

const getOfferData = offer => {
  const requestType = offer.request.type;
  const promoterProductTypes = offer.request.promoter.productTypes;
  const promoterProductType =
    requestType === 'automotriz'
      ? promoterProductTypes.find(
          product => product.productType === 'car_finance'
        )
      : promoterProductTypes.find(
          product => product.productType === 'motorcycle_finance'
        );
  const salespersonCommission =
    offer.product.promoterCommission * (1 - promoterProductType.fee);

  if (finalOfferAvailable(offer)) {
    const { finalOffer } = offer;

    return {
      id: offer.id,
      accessories: finalOffer.accessories.map(acc => grammar[acc.type]),
      accessoriesAmount: finalOffer.accessoriesAmount,
      bank: offer.bank,
      cat: finalOffer.cat,
      fee:
        finalOffer.feeType === 'amount'
          ? f.currency(finalOffer.fee)
          : `${(finalOffer.fee * 100).toFixed(2)} %`,
      stage: offer.stage,
      vehicleCondition: finalOffer.vehicleCondition,
      vehicleManufacturer: finalOffer.vehicle.manufacturer.name,
      vehicleModel: finalOffer.vehicle.model,
      vehicleModelYear: finalOffer.year,
      vehicleValue: finalOffer.vehicleValue,
      downpayment: finalOffer.downpayment,
      creditAmount: finalOffer.creditAmount,
      rate: finalOffer.rate,
      rateType: finalOffer.rateType,
      requestedPeriod: `${finalOffer.numberPayments} ${
        grammar[finalOffer.paymentFrequency]
      }`,
      dateOffer: finalOffer.dateOffer,
      dateVigency: finalOffer.dateVigency,
      request: offer.request,
      payment: finalOffer.payment,
      personalData: offer.request.dataPersonals[0],
      productName: finalOffer.productName,
      promoter: offer.request.promoter,
      salespersonCommission,
    };
  } else {
    const { automotiveCredits, motorcycleCredit } = offer.request;
    const vehicleInfo =
      requestType === 'automotriz' ? automotiveCredits[0] : motorcycleCredit[0];

    const value =
      vehicleInfo.unitType === 'new'
        ? vehicleInfo.valueNew
        : vehicleInfo.valuePreowned;

    return {
      id: offer.id,
      accessories: offer.selectedAccesories.map(acc => acc.name),
      accessoriesAmount: offer.accesories,
      bank: offer.bank,
      cat: offer.cat,
      fee:
        offer.feeOptions === 'cash'
          ? f.currency(offer.fee)
          : f.percentage(Number.parseFloat(offer.fee) * 100, 2),
      feeType: offer.feeOptions,
      stage: offer.stage,
      vehicleCondition: vehicleInfo.unitType,
      vehicleManufacturer: vehicleInfo.brand.name,
      vehicleModel: vehicleInfo.version.model,
      vehicleModelYear: vehicleInfo.model,
      vehicleValue: value,
      downpayment: offer.downpayment,
      creditAmount: offer.amount,
      requestedPeriod: offer.requestedPeriod,
      dateOffer: offer.dateOffer,
      dateVigency: offer.dateVigency,
      rate: offer.rate,
      rateType: offer.rateOptions,
      request: offer.request,
      payment: offer.fullPayment,
      personalData: offer.request.dataPersonals[0],
      productName: offer.nameProduct,
      promoter: offer.request.promoter,
      salespersonCommission,
    };
  }
};

const getRemainingOfferValidityDays = validityDate => {
  const date = new Date(validityDate);
  const timeZoneOffset = date.getTimezoneOffset();

  return differenceInCalendarDays(addMinutes(date, timeZoneOffset), new Date());
};

export { finalOfferAvailable, getOfferData, getRemainingOfferValidityDays };
