import { handleActions } from 'redux-actions';

const initialState = null;

const applicationDetailsReducer = handleActions(
  {
    APPLICATION_DETAILS_FETCH_COMPLETED: (state, { payload }) => {
      return payload;
    },
  },
  initialState
);

export default applicationDetailsReducer;
