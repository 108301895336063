import { Form, Formik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import colors from '../../constants/colors';
import whatsAppLogo from '../../resources/whatsapp-logo.svg';
import Button from '../Button';
import ExpandIndicator from '../ExpandIndicator';
import { FormikPhone } from '../FormikInputs';

const MAX_RESEND_TRIES = 2;
const TIMEOUT = 30000;

const SendViaWhatsAppFormTitleContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 12px;
`;

const WhatsAppLogoImage = styled.img`
  height: 40px;
`;

const WhatsAppText = styled.span`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
`;

const SendViaWhatsAppDrawerContainer = styled.div<{ visible: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: ${({ visible }) => (visible ? '18px' : 0)};
  overflow: hidden;
  transition: height ease 0.5s;
`;

const getHiddenContentHeight = ({
  expanded,
  tryLimitReached,
}: {
  expanded: boolean;
  tryLimitReached: boolean;
}): string | number => {
  if (expanded) {
    return tryLimitReached ? '60px' : '184px';
  }

  return '0px';
};

const HiddenContent = styled.div<{
  expanded: boolean;
  tryLimitReached: boolean;
}>`
  height: ${getHiddenContentHeight};
  overflow: hidden;
  transition: height ease 0.5s;
`;

const whatsAppOptInFormValidationSchema: Yup.ObjectSchema<Yup.Shape<
  {},
  { phoneNumber: string }
>> = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(/^\d{10}$/, 'El teléfono celular debe de tener 10 dígitos.')
    .required('El télefono celular es obligatorio.'),
});

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4px;
`;

interface ISendViaWhatsAppFormProps {
  formText: string;
  initialPhoneValue?: string;
  inputDisabled?: boolean;
  onSubmit: (phoneNumber: string) => any;
}

const SendViaWhatsAppForm: React.FunctionComponent<ISendViaWhatsAppFormProps> = ({
  formText,
  initialPhoneValue,
  inputDisabled,
  onSubmit,
}) => {
  return (
    <div>
      <SendViaWhatsAppFormTitleContainer>
        <WhatsAppText>{formText}</WhatsAppText>
        <WhatsAppLogoImage src={whatsAppLogo} />
      </SendViaWhatsAppFormTitleContainer>
      <Formik
        initialValues={{ phoneNumber: initialPhoneValue || '' }}
        render={() => (
          <Fragment>
            <Form>
              <FormikPhone
                disabled={inputDisabled}
                label="TELÉFONO CELULAR"
                name="phoneNumber"
              />
              <SubmitButtonContainer>
                <Button type="submit">Reenviar</Button>
              </SubmitButtonContainer>
            </Form>
          </Fragment>
        )}
        onSubmit={values => onSubmit(values.phoneNumber)}
        validationSchema={whatsAppOptInFormValidationSchema}
      />
    </div>
  );
};

interface ISendViaWhatsAppProps {
  drawerText: string;
  formText: string;
  initialPhoneValue?: string;
  inputDisabled?: boolean;
  onSubmit: (phoneNumber: string) => any;
}

const TryLimitReachedMessageContainer = styled.div`
  color: ${colors.PRIMARY_TEXT};
  display: flex;
  font-size: 14px;
  text-align: center;
`;

const TryLimitReachedMessage = () => (
  <TryLimitReachedMessageContainer>
    <WhatsAppText>
      Has excedido el número de intentos. Por favor contacta a soporte al
      número: (331) 803-7895
    </WhatsAppText>
  </TryLimitReachedMessageContainer>
);

const SendViaWhatsApp: React.FunctionComponent<ISendViaWhatsAppProps> = ({
  drawerText,
  formText,
  initialPhoneValue,
  inputDisabled,
  onSubmit,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [tries, setTries] = useState<number>(0);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, TIMEOUT);
  }, []);

  const tryLimitReached = tries > MAX_RESEND_TRIES;

  return (
    <Fragment>
      <SendViaWhatsAppDrawerContainer
        onClick={() => setExpanded(!expanded)}
        visible={visible}
      >
        <WhatsAppText>{drawerText}</WhatsAppText>
        <ExpandIndicator expanded={expanded} />
      </SendViaWhatsAppDrawerContainer>
      <HiddenContent expanded={expanded} tryLimitReached={tryLimitReached}>
        {tryLimitReached ? (
          <TryLimitReachedMessage />
        ) : (
          <SendViaWhatsAppForm
            inputDisabled={inputDisabled}
            formText={formText}
            initialPhoneValue={initialPhoneValue}
            onSubmit={phoneNumber => {
              onSubmit(phoneNumber);
              setTries(tries + 1);
            }}
          />
        )}
      </HiddenContent>
    </Fragment>
  );
};

export default SendViaWhatsApp;
