export enum RejectionReasons {
  ApplicantAgeMin = 'applicant_age_min',
  ApplicantAgeMax = 'applicant_age_max',
  CosignerAgeMin = 'cosigner_age_min',
  CosignerAgeMax = 'cosigner_age_max',
  CreditAmountMin = 'credit_amount_min',
  CreditAmountMax = 'credit_amount_max',
  CreditLimitAmountRequestedRationMin = 'credit_limit_amount_requested_ratio_min',
  EconomicDependentsMax = 'economic_dependents_max',
  EducationLevelMin = 'education_level_min',
  FicoScoreMin = 'fico_score_min',
  HousingType = 'housing_type',
  HousingExpensesMax = 'housing_expenses_max',
  IncomeMin = 'income_min',
  IndebtednessLevelMax = 'indebtedness_level_max',
  MonthlyEstimatedPaymentTotalIncomeRatio = 'monthly_estimated_payment_total_income_ratio',
  MonthsInEmploymentMin = 'months_in_employment_min',
  MonthsInResidenceMin = 'months_in_residence_min',
  NoCreditHistory = 'no_credit_history',
  OutstandingLatePaymentsAmountCurrent = 'outstanding_late_payments_amount_current',
  OutstandingLatePaymentsAmountHistoric = 'outstanding_late_payments_amount_historic',
  StateRestriction = 'state_restriction',
  VehicleAgeMax = 'vehicle_age_max',
  VehicleValueMin = 'vehicle_value_min',
  VehicleValueMax = 'vehicle_value_max',
  NoReason = 'no_reason',
}

export const rejectionReasonsMessage: Record<RejectionReasons, string> = {
  [RejectionReasons.ApplicantAgeMin]:
    'La edad del solicitante incumple con las políticas de los otorgantes seleccionados',
  [RejectionReasons.ApplicantAgeMax]:
    'La edad del solicitante incumple con las políticas de los otorgantes seleccionados',
  [RejectionReasons.CosignerAgeMin]:
    'La edad del coacreditado incumple con las políticas de los otorgantes seleccionados',
  [RejectionReasons.CosignerAgeMax]:
    'La edad del coacreditado incumple con las políticas de los otorgantes seleccionados',
  [RejectionReasons.CreditAmountMin]:
    'Los otorgantes seleccionados no cuentan con productos para el monto de crédito solicitado',
  [RejectionReasons.CreditAmountMax]:
    'Los otorgantes seleccionados no cuentan con productos para el monto de crédito solicitado',
  [RejectionReasons.CreditLimitAmountRequestedRationMin]:
    'El solicitante no cuenta con suficiente historial crediticio de acuerdo a las políticas de los otorgantes seleccionados',
  [RejectionReasons.EconomicDependentsMax]:
    'El número de dependientes económicos del solicitante incumple con las políticas de los otorgantes seleccionados',
  [RejectionReasons.EducationLevelMin]:
    'El grado de estudios del solicitante incumple con las políticas de los otorgantes seleccionados',
  [RejectionReasons.FicoScoreMin]:
    'El historial crediticio del solicitante se encuentra fuera de los parámetros aceptables para los otorgantes seleccionados',
  [RejectionReasons.HousingExpensesMax]:
    'El pago de renta del solicitante excede las políticas de los otorgantes seleccionados',
  [RejectionReasons.HousingType]:
    'El tipo de domicilio del solicitante incumple con las políticas de los otorgantes seleccionados',
  [RejectionReasons.IncomeMin]:
    'Los ingresos totales del solicitante incumplen las políticas de los otorgantes seleccionados',
  [RejectionReasons.IndebtednessLevelMax]:
    'El solicitante presenta un alto nivel de endeudamiento',
  [RejectionReasons.MonthlyEstimatedPaymentTotalIncomeRatio]:
    'El pago mensual estimado es muy elevado para los ingresos del solicitante',
  [RejectionReasons.MonthsInEmploymentMin]:
    'La antigüedad de empleo del solicitante incumple las políticas de los otorgantes seleccionados',
  [RejectionReasons.MonthsInResidenceMin]:
    'La antigüedad de domicilio del solicitante incumple las políticas de los otorgantes seleccionados',
  [RejectionReasons.NoCreditHistory]:
    'El historial crediticio del solicitante se encuentra fuera de los parámetros aceptables para los otorgantes seleccionados',
  [RejectionReasons.OutstandingLatePaymentsAmountCurrent]:
    'El solicitante presenta atrasos actuales relevantes en su historial crediticio',
  [RejectionReasons.OutstandingLatePaymentsAmountHistoric]:
    'El solicitante presenta atrasos históricos relevantes en su historial crediticio',
  [RejectionReasons.StateRestriction]:
    'El domicilio del solicitante no se encuentra en el área de cobertura de los otorgantes seleccionados',
  [RejectionReasons.VehicleAgeMax]:
    'Los otorgantes seleccionados no cuentan con productos para bienes con la antigüedad declarada',
  [RejectionReasons.VehicleValueMin]:
    'Los otorgantes seleccionados no cuentan con productos para bienes con el valor declarado',
  [RejectionReasons.VehicleValueMax]:
    'Los otorgantes seleccionados no cuentan con productos para bienes con el valor declarado',
  [RejectionReasons.NoReason]:
    'Los otorgantes seleccionados no cuentan con productos para las características solicitadas',
};
