import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const USERS_FETCH = createAsyncFlowTypes('USERS_FETCH');
const CREATE_USER = createAsyncFlowTypes('CREATE_USER');
const CHANGE_USER_STATUS = createAsyncFlowTypes('CHANGE_USER_STATUS');
const CHANGE_USER_ROLE = createAsyncFlowTypes('CHANGE_USER_ROLE');

const fetchUsers = createAsyncFlowCreators(USERS_FETCH);
const createUser = createAsyncFlowCreators(CREATE_USER);
const changeUserStatus = createAsyncFlowCreators(CHANGE_USER_STATUS);
const changeUserRole = createAsyncFlowCreators(CHANGE_USER_ROLE);

export default {
  types: {
    USERS_FETCH,
    CREATE_USER,
    CHANGE_USER_STATUS,
    CHANGE_USER_ROLE,
  },
  creators: {
    fetchUsers,
    createUser,
    changeUserStatus,
    changeUserRole,
  },
};
