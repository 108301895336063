import React from 'react';

import FormikNumber from './FormikNumber';

interface FormikPercentage {
  label: string;
  name: string;
}

const FormikPercentage: React.FunctionComponent<FormikPercentage> = ({
  label,
  name,
}) => (
  <FormikNumber<string | number, number>
    formatValue={value =>
      typeof value === 'string'
        ? (Number.parseFloat(value) * 100).toFixed(2)
        : (value * 100).toFixed(2)
    }
    label={label}
    name={name}
    parseValue={value => Number.parseFloat(value.replace(/[%,]/g, '')) / 100}
    suffix={' %'}
    thousandSeparator={true}
  />
);

export default FormikPercentage;
