import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import { noop } from '../../utils/misc';
import Checkbox from './../Checkbox';
import Label from '../Label';

const ErrorMessageContainer = styled.div`
  margin-top: 8px;
`;

const InputContainer = styled.div<{ withErrorMessage: boolean }>`
  white-space: nowrap;
  height: ${({ withErrorMessage }) => (withErrorMessage ? '28px' : 'auto')};
  width: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
`;

const CheckboxText = styled.span`
  font-size: 14px;
  padding-left: 24px;
  padding-top: 1px;
  display: block;
  color: ${colors.SECONDARY_TEXT};
`;

interface Props {
  error?: string;
  withErrorMessage?: boolean;
  checked: boolean;
  onBlur?(e: React.FocusEvent<HTMLLabelElement>): void;
  onChange?(checked: boolean): void;
  label?: string;
}

const CheckboxInput: React.FunctionComponent<Props> = ({
  checked = false,
  onBlur = noop,
  onChange = noop,
  error,
  withErrorMessage = false,
  label,
}) => {
  const errorComponent =
    error && withErrorMessage ? (
      <ErrorMessageContainer>
        <Label error>{error}</Label>
      </ErrorMessageContainer>
    ) : null;

  return (
    <InputContainer withErrorMessage={withErrorMessage}>
      <CheckboxContainer>
        <Checkbox checked={checked} onBlur={onBlur} onChange={onChange} />
        <CheckboxText>{label}</CheckboxText>
      </CheckboxContainer>
      {errorComponent}
    </InputContainer>
  );
};

export default CheckboxInput;
