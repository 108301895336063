import { call, select, takeLatest } from 'redux-saga/effects';

import commissionsActions from '../actions/commissions';
import runner from './runners';

interface IFetchCommissionsPayload {
  type: CommissionsTypes;
}

function* fetchCommissions({
  payload,
}: IReduxAction<IFetchCommissionsPayload>) {
  const userData: IUserData = yield select((state: any) => state.auth.userData);

  const url = (() => {
    switch (userData.type) {
      case 'creditus': {
        return `api/commissions/creditus/${payload.type}`;
      }
      case 'bank': {
        return `api/commissions/bank/${userData.organization.bankId}`;
      }
      case 'promoter': {
        return `api/commissions/promoter/${userData.organization.promoterId}`;
      }
    }
  })();

  yield call(runner, commissionsActions.creators.fetchCommissions, {
    method: 'GET',
    url,
  });
}

function* watchFetchCommissionsRequested() {
  yield takeLatest(
    commissionsActions.types.COMMISSIONS_FETCH.REQUESTED,
    fetchCommissions
  );
}

export default {
  watchFetchCommissionsRequested,
};
