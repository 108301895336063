import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const CLIENT_EXISTS = createAsyncFlowTypes('CLIENT_EXISTS');
const SEND_NIP_EXISTENT_CLIENT = createAsyncFlowTypes(
  'SEND_NIP_EXISTENT_CLIENT'
);
const VERIFY_NIP_EXISTENT_CLIENT = createAsyncFlowTypes(
  'VERIFY_NIP_EXISTENT_CLIENT'
);

const clientExists = createAsyncFlowCreators(CLIENT_EXISTS);
const sendNipExistentClient = createAsyncFlowCreators(SEND_NIP_EXISTENT_CLIENT);
const verifyNipExistentClient = createAsyncFlowCreators(
  VERIFY_NIP_EXISTENT_CLIENT
);

export default {
  types: {
    CLIENT_EXISTS,
    SEND_NIP_EXISTENT_CLIENT,
    VERIFY_NIP_EXISTENT_CLIENT,
  },
  creators: {
    clientExists,
    sendNipExistentClient,
    verifyNipExistentClient,
  },
};
