import { Form, Formik } from 'formik';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';

import bankProductsActions from '../../actions/automaticProducts';
import offersActions from '../../actions/offers';
import Button from '../Button';
import {
  FormikCurrency,
  FormikDropdown,
  FormikPercentage,
  FormikSimpleNumber,
  FormikText,
} from '../FormikInputs';
import SimpleCard from '../SimpleCard';
import errorMessages from './errorMessages';

interface IProduct {
  id: number;
  internalName: string;
  commercialName: string;
  productType: string;
  validThrough: string;
  createdAt: string | null;
  updatedAt: string | null;
  active: boolean;
}

interface IBankCreateManualOfferStateProps {
  bankProducts: IProduct[] | null;
}

interface IBankCreateManualOfferDispatchProps {
  fetchBankProducts: () => void;
  createManualOffer: (payload: IManualOfferForm) => void;
}

interface IBankCreateManualOfferOwnProps {
  applicationId: number;
  history: RouteComponentProps['history'];
}

type IBankCreateManualOffer = IBankCreateManualOfferStateProps &
  IBankCreateManualOfferDispatchProps &
  IBankCreateManualOfferOwnProps;

interface IManualOfferForm {
  applicationId: number;
  productId: number;
  creditAmount: number;
  numberPayments: number;
  payment: number;
  downpayment: number;
  cat: number;
}

const BackButtonContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  padding: 24px 40px;
`;

const SubmitButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-top: 12px;
  width: 100%;
`;

const validationSchema = Yup.object<IManualOfferForm>({
  applicationId: Yup.number().required(errorMessages.applicationId.required),
  productId: Yup.number()
    .typeError(errorMessages.productId.typeError)
    .required(errorMessages.productId.required),
  creditAmount: Yup.number()
    .typeError(errorMessages.creditAmount.typeError)
    .required(errorMessages.creditAmount.required),
  numberPayments: Yup.number()
    .typeError(errorMessages.numberPayments.typeError)
    .required(errorMessages.numberPayments.required),
  payment: Yup.number()
    .typeError(errorMessages.payment.typeError)
    .required(errorMessages.payment.required),
  downpayment: Yup.number()
    .typeError(errorMessages.downpayment.typeError)
    .required(errorMessages.downpayment.required),
  cat: Yup.number()
    .typeError(errorMessages.cat.typeError)
    .required(errorMessages.cat.required),
});

const formInitialValues: IManualOfferForm = {
  applicationId: NaN,
  productId: NaN,
  creditAmount: NaN,
  numberPayments: NaN,
  payment: NaN,
  downpayment: NaN,
  cat: NaN,
};

const BankCreateManualOffer: React.FunctionComponent<IBankCreateManualOffer> = ({
  applicationId,
  bankProducts,
  createManualOffer,
  fetchBankProducts,
  history,
}) => {
  useEffect(() => {
    fetchBankProducts();
  }, [fetchBankProducts]);

  const productOptions =
    bankProducts === null
      ? []
      : bankProducts.map(product => ({
          text: `${product.id} - ${product.internalName}`,
          value: product.id,
        }));

  return (
    <Fragment>
      <BackButtonContainer>
        <Button onClick={() => history.goBack()} variant="secondary">
          Regresar
        </Button>
      </BackButtonContainer>
      <Formik
        initialValues={{ ...formInitialValues, applicationId }}
        onSubmit={values => createManualOffer(values)}
        validationSchema={validationSchema}
      >
        <Form>
          <SimpleCard
            title="Crear oferta manual"
            subtitle="Ingresa los datos solicitados"
          >
            <Grid>
              <FormikText label="ID SOLICITUD" name="applicationId" disabled />
              <FormikDropdown
                label="ID PRODUCTO"
                name="productId"
                options={productOptions}
                placeholder="Selecciona un producto"
              />
              <FormikCurrency label="MONTO CRÉDITO" name="creditAmount" />
              <FormikSimpleNumber
                label="NÚMERO DE PAGOS"
                name="numberPayments"
              />
              <FormikCurrency label="PAGO MENSUAL" name="payment" />
              <FormikCurrency label="ENGANCHE" name="downpayment" />
              <FormikPercentage label="CAT" name="cat" />
            </Grid>
          </SimpleCard>
          <SubmitButtonContainer>
            <Button variant="primary" type="submit">
              Crear
            </Button>
          </SubmitButtonContainer>
        </Form>
      </Formik>
    </Fragment>
  );
};

const mapStateToProps = (state: any): IBankCreateManualOfferStateProps => ({
  bankProducts: state.entities.automaticProducts.products,
});

const creators = {
  createManualOffer: offersActions.creators.createManualOffer.request,
  fetchBankProducts:
    bankProductsActions.creators.fetchBankAutomaticProducts.request,
};

export default connect(mapStateToProps, creators)(BankCreateManualOffer);
