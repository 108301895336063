import isNil from 'ramda/src/isNil';
import prop from 'ramda/src/prop';
import React, { Children, Fragment, useState } from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import Border from '../Border';
import Card from '../Card';
import ExpandIndicator from '../ExpandIndicator';

interface IExpandableSectionList {
  expandedSectionOverflow?: 'hidden' | 'visible';
  disabledSections: boolean[];
  initialExpandedSection?: number;
  sectionHeights: number[];
  sectionTitles: string[];
  title: string;
}

const ExpandIndicatorContainer = styled.div`
  margin-left: 16px;
  margin-right: 8px;
`;

const ExpandableSectionContainer = styled.div`
  margin-top: 8px;
`;

const Section = styled.div<{
  expanded: boolean;
  height: string;
  overflow: string;
}>`
  height: ${({ expanded, height }) => (expanded ? height : '0')};
  overflow: ${prop('overflow')};
  transition: height ease 0.75s;
`;

const SectionContent = styled.div`
  background-color: ${colors.WHITE};
`;

const TitleContainer = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  color: ${colors.DUSK50};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 32px;
  line-height: 1.5;
  text-transform: uppercase;
`;

interface IExpandableSection {
  disabled: boolean;
  expanded: boolean;
  height: string;
  onSectionClick: () => void;
  overflow: 'hidden' | 'visible';
  title: string;
}

const ExpandableSection: React.FunctionComponent<IExpandableSection> = ({
  children,
  disabled,
  expanded,
  height,
  onSectionClick,
  overflow,
  title,
}) => {
  return (
    <div>
      <TitleContainer onClick={disabled ? () => {} : onSectionClick}>
        <ExpandIndicatorContainer>
          <ExpandIndicator disabled={disabled} expanded={expanded} />
        </ExpandIndicatorContainer>
        {title}
      </TitleContainer>
      <Border />
      <Section expanded={expanded} height={height} overflow={overflow}>
        <SectionContent>{children}</SectionContent>
      </Section>
    </div>
  );
};

const ExpandableSectionList: React.FunctionComponent<IExpandableSectionList> = ({
  children,
  disabledSections,
  initialExpandedSection,
  expandedSectionOverflow,
  sectionHeights,
  sectionTitles,
  title,
}) => {
  const [expandedSection, setExpandedSection] = useState<number | null>(
    isNil(initialExpandedSection) ? null : initialExpandedSection
  );

  const childrenArray = Children.toArray(children);

  if (childrenArray.length < 1) {
    throw new Error(
      'Error: No children were passed to the ExpandableSectionList component'
    );
  }

  return (
    <Fragment>
      <Card header={title}>
        <ExpandableSection
          disabled={disabledSections[0]}
          expanded={expandedSection === 0}
          height={`${sectionHeights[0]}px`}
          onSectionClick={() =>
            setExpandedSection(expandedSection === 0 ? null : 0)
          }
          overflow={
            expandedSection === 0 && !isNil(expandedSectionOverflow)
              ? expandedSectionOverflow
              : 'hidden'
          }
          title={sectionTitles[0]}
        >
          {childrenArray[0]}
        </ExpandableSection>
      </Card>
      {childrenArray.map((child, i) =>
        i === 0 ? null : (
          <ExpandableSectionContainer key={i}>
            <ExpandableSection
              disabled={disabledSections[i]}
              expanded={expandedSection === i}
              height={`${sectionHeights[i]}px`}
              onSectionClick={() =>
                setExpandedSection(expandedSection === i ? null : i)
              }
              overflow={
                expandedSection === i && !isNil(expandedSectionOverflow)
                  ? expandedSectionOverflow
                  : 'hidden'
              }
              title={sectionTitles[i]}
            >
              {child}
            </ExpandableSection>
          </ExpandableSectionContainer>
        )
      )}
    </Fragment>
  );
};

export default ExpandableSectionList;
