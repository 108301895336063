import * as d3 from 'd3';
import max from 'ramda/src/max';
import prop from 'ramda/src/prop';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import f from '../../constants/formatters';
import TrendIcon from '../../resources/icons/Trend';
import Label from '../Label';

const QuickInfoContainer = styled.div`
  background-color: ${colors.WHITE};
  border: solid 1px ${colors.BORDER};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  width: 100%;
  height: 100%;
`;

const QuickInfoContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 52px;
`;

const QuickInfoMainIndicatorText = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 36px;
  margin-bottom: 12px;
`;

const QuickInfoLegendText = styled.text<{ fontSize: string }>`
  font-size: ${({ fontSize }) => fontSize};
`;

const QuickInfoTrendContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
`;

const QuickInfoTrendText = styled.div<{ positive: boolean }>`
  color: ${({ positive }) => (positive ? colors.GREEN : colors.RED)};
  font-size: 14px;
  margin-right: 8px;
`;

const QuickInfoBarText = styled.text<{ fill: string; fontWeight: string }>`
  fill: ${prop('fill')};
  font-size: 14px;
  font-weight: ${prop('fontWeight')};
`;

const QuickInfoD3ComponentParent = styled.div`
  width: 100%;
`;

interface IQuickInfoD3ComponentProps {
  data: [number, number];
  format: 'none' | 'currency';
  labels: [string, string];
  legendsFontSize?: 'normal' | 'small';
}

const QuickInfoD3Component: React.FunctionComponent<IQuickInfoD3ComponentProps> = ({
  data,
  format,
  labels,
  legendsFontSize = 'normal',
}) => {
  const [width, setWidth] = useState(320);

  const parentContainer = useRef<SVGSVGElement | null>(null);

  const parentContainerWidth =
    parentContainer.current?.parentElement?.offsetWidth;

  useEffect(() => {
    if (parentContainerWidth) {
      setWidth(parentContainerWidth);
    }
  }, [parentContainerWidth]);

  const x = d3.scaleLinear().range([0, width]);
  x.domain([0, max(data[0], data[1])]);

  const legends = [...labels];

  return (
    <QuickInfoD3ComponentParent>
      <svg ref={parentContainer} height="100px" width={`${width}px`}>
        <g>
          {legends.map((legend, i) => (
            <Fragment key={i}>
              <rect
                x={i * (width / 2)}
                y="2px"
                width="10px"
                height="10px"
                fill={i === 0 ? colors.WATER_BLUE : colors.BLUE_GRAY}
                rx="2"
                ry="2"
              />
              <QuickInfoLegendText
                fontSize={legendsFontSize === 'small' ? '14px' : '16px'}
                x={i * (width / 2) + 20}
                y="12px"
              >
                {legend}
              </QuickInfoLegendText>
            </Fragment>
          ))}
        </g>
        <g transform={'translate(0, 32)'}>
          <rect
            fill={colors.WATER_BLUE}
            x="0px"
            y="0px"
            width={data[0] === 0 ? 5 : x(data[0])}
            height="30px"
            rx={2}
            ry={2}
          />
          <rect
            fill={colors.ALASKA_GRAY}
            x="0px"
            y="38px"
            width={data[1] === 0 ? 5 : x(data[1])}
            height="30px"
            rx={2}
            ry={2}
          />
        </g>
        <g transform={'translate(8, 52)'}>
          <QuickInfoBarText fill={colors.WHITE} fontWeight={'bold'}>
            {format === 'currency' ? f.currency(data[0]) : data[0]}
          </QuickInfoBarText>
          <QuickInfoBarText
            fill={colors.PRIMARY_TEXT}
            fontWeight={'normal'}
            y="38px"
          >
            {format === 'currency' ? f.currency(data[1]) : data[1]}
          </QuickInfoBarText>
        </g>
      </svg>
    </QuickInfoD3ComponentParent>
  );
};

interface IQuickInfoCardProps {
  data: [number, number];
  format?: 'none' | 'currency';
  labels: [string, string];
  legendsFontSize?: 'normal' | 'small';
  title: string;
}

const QuickInfoCard: React.FunctionComponent<IQuickInfoCardProps> = ({
  data,
  format = 'none',
  labels,
  legendsFontSize = 'normal',
  title,
}) => {
  const coefficient = data[0] / data[1] - 1;
  const isCoefficientNaN = Number.isNaN(coefficient);
  const isCoefficientInfinity = !Number.isFinite(coefficient);
  const isTrendPositive = coefficient > 0;

  const displayNumber = (() => {
    if (isCoefficientNaN) {
      return '0.00%';
    }
    if (isCoefficientInfinity) {
      return '∞';
    }

    return `${(coefficient * 100).toFixed(2)}%`;
  })();

  return (
    <QuickInfoContainer>
      <QuickInfoContent>
        <div>
          <Label>{title}</Label>
        </div>
        <QuickInfoMainIndicatorText>
          {format === 'currency' ? f.currency(data[0]) : data[0]}
        </QuickInfoMainIndicatorText>
        <QuickInfoTrendContainer>
          <QuickInfoTrendText positive={isTrendPositive}>
            {displayNumber}
          </QuickInfoTrendText>
          <TrendIcon
            fill={isTrendPositive ? colors.GREEN : colors.RED}
            rotate={isTrendPositive ? 0 : 180}
            size={20}
          />
        </QuickInfoTrendContainer>
        <QuickInfoD3Component
          data={data}
          format={format}
          labels={labels}
          legendsFontSize={legendsFontSize}
        />
      </QuickInfoContent>
    </QuickInfoContainer>
  );
};

export default QuickInfoCard;
