import colors from '../constants/colors';

function getColor(score: number) {
  if (score <= 333) {
    return colors.RED;
  }
  if (score <= 666) {
    return colors.ORANGE;
  }
  if (score <= 1000) {
    return colors.AQUA;
  }

  return colors.DUSK50;
}

function getGrade(score: number) {
  if (score <= 111) {
    return 'C3';
  }
  if (score <= 222) {
    return 'C2';
  }
  if (score <= 333) {
    return 'C1';
  }
  if (score <= 444) {
    return 'B3';
  }
  if (score <= 555) {
    return 'B2';
  }
  if (score <= 666) {
    return 'B1';
  }
  if (score <= 777) {
    return 'A3';
  }
  if (score <= 889) {
    return 'A2';
  }
  if (score <= 1000) {
    return 'A1';
  }
}

export { getColor, getGrade };
