import length from 'ramda/src/length';
import prop from 'ramda/src/prop';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import Border from '../Border';
import ExpandableRow from '../ExpandableRow';
import ExpandIndicator from '../ExpandIndicator';
import { TableHeader } from '../SmartTable';

interface ExpandableTable<T> {
  data: Array<T>;
  gridColumns: string;
  headers: Array<string>;
  rowHeights: Array<number>;
  renderHiddenRow(datum: T, index: number): JSX.Element;
  renderVisibleRow(datum: T, index: number): JSX.Element;
}

const Background = styled.div`
  background-color: ${colors.BACKGROUND};
`;

const ExpandIndicatorContainer = styled.div`
  align-items: center;
  display: flex;
  width: 12px;
`;

const HiddenRowContainer = styled.div`
  padding-right: 12px;
`;

const TableHeadersGrid = styled.div<{ gridColumns: string }>`
  align-items: center;
  background-color: ${colors.WHITE};
  display: grid;
  grid-template-columns: ${prop('gridColumns')};
  padding-left: 24px;
  padding-right: 44px;
`;

const TableRowGrid = styled.div<{ gridColumns: string }>`
  align-items: center;
  display: grid;
  grid-template-columns: ${prop('gridColumns')};
  height: 60px;
  padding: 0 0;
  width: 100%;
`;

const VisibleRowContainer = styled.div`
  display: flex;
`;

function ExpandableTable<T>({
  data,
  gridColumns,
  headers,
  renderHiddenRow,
  renderVisibleRow,
  rowHeights,
}: ExpandableTable<T>): JSX.Element {
  const rowCount = length(data);
  const tableHeaders = headers.map((header, i) => (
    <TableHeader key={i}>{header}</TableHeader>
  ));

  const rows = data.map((datum, i) => (
    <Fragment key={i}>
      <ExpandableRow
        height={rowHeights[i]}
        renderHiddenContent={() => (
          <HiddenRowContainer>{renderHiddenRow(datum, i)}</HiddenRowContainer>
        )}
        renderVisibleContent={expanded => (
          <VisibleRowContainer>
            <TableRowGrid gridColumns={gridColumns}>
              {renderVisibleRow(datum, i)}
            </TableRowGrid>
            <ExpandIndicatorContainer>
              <ExpandIndicator expanded={expanded} disabled={false} />
            </ExpandIndicatorContainer>
          </VisibleRowContainer>
        )}
      />
      {i !== rowCount - 1 ? <Border /> : null}
    </Fragment>
  ));

  return (
    <Fragment>
      <Background>
        <TableHeadersGrid gridColumns={gridColumns}>
          {tableHeaders}
        </TableHeadersGrid>
        <Border />
        {rows}
      </Background>
    </Fragment>
  );
}

export default ExpandableTable;
