import compareAsc from 'date-fns/compareAsc';
import parse from 'date-fns/parse';
import values from 'ramda/src/values';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import productsActions from '../../actions/products';
import colors from '../../constants/colors';
import { isBankUser } from '../../utils/permissions';
import Card from '../Card';
import FilterSearch from '../FilterSearch';
import GrantedRow from '../GrantedRow';
import LoadingIndicator from '../LoadingIndicator';
import Table from '../Table';
import withUserData from '../UserDataHOC';

const TABLE_HEADERS_BANK = [
  {
    text: 'ID Oferta',
    width: '10%',
  },
  {
    text: 'Producto',
    width: '10%',
  },
  {
    text: 'Tipo',
    width: '12.5%',
  },
  {
    text: 'Monto',
    width: '15%',
  },
  {
    text: 'Comercio',
    width: '20%',
  },
  {
    text: 'Titular',
    width: '17.5%',
  },
  {
    text: 'Otorgado',
    width: '15%',
  },
];

const TABLE_HEADERS_PROMOTER = [
  {
    text: 'ID Oferta',
    width: '10%',
  },
  {
    text: 'Otorgante',
    width: '10%',
  },
  {
    text: 'Tipo',
    width: '12.5%',
  },
  {
    text: 'Monto',
    width: '15%',
  },
  {
    text: 'Asesor',
    width: '20%',
  },
  {
    text: 'Titular',
    width: '17.5%',
  },
  {
    text: 'Colocado',
    width: '15%',
  },
];

const Background = styled.div`
  background-color: ${colors.BACKGROUND};
  width: 100%;
`;

const BottomMargin = styled.div`
  margin-bottom: 20px;
`;

const LoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 50vh;
  justify-content: center;
  width: 100%;
`;

class GrantedProductsView extends Component {
  componentDidMount() {
    const { requestProductsGranted } = this.props;
    requestProductsGranted();
  }

  render() {
    const {
      grantedProducts,
      fetchingGrantedProducts,
      userData,
      requestProductsGranted,
    } = this.props;
    const rowProps = { stage: 'granted' };
    const header = `Productos ${
      isBankUser(userData) ? 'otorgados' : 'colocados'
    }`;

    const sortedProducts = grantedProducts.sort((a, b) => {
      const dateA = parse(
        a.finalOffer.grantedDate.split(' ')[0],
        'yyyy-MM-dd',
        new Date()
      );
      const dateB = parse(
        b.finalOffer.grantedDate.split(' ')[0],
        'yyyy-MM-dd',
        new Date()
      );
      return compareAsc(dateA, dateB) * -1;
    });

    const content = fetchingGrantedProducts ? (
      <LoadingState>
        <LoadingIndicator />
      </LoadingState>
    ) : grantedProducts.length > 0 ? (
      <Background>
        <Table
          data={sortedProducts}
          headers={
            isBankUser(userData) ? TABLE_HEADERS_BANK : TABLE_HEADERS_PROMOTER
          }
          rowComponent={GrantedRow}
          rowProps={rowProps}
        />
      </Background>
    ) : (
      <LoadingState>Sin Resultados</LoadingState>
    );
    return (
      <BottomMargin>
        <Card
          header={header}
          controls={<FilterSearch action={requestProductsGranted} />}
        >
          {content}
        </Card>
      </BottomMargin>
    );
  }
}

const mapStateToProps = state => ({
  fetchingGrantedProducts: state.loaders.fetchingGrantedProducts,
  grantedProducts: values(state.entities.grantedProducts),
});

const creators = {
  requestProductsGranted: productsActions.creators.fetchProductsGranted.request,
};

export default withUserData(
  connect(mapStateToProps, creators)(GrantedProductsView)
);
