import not from 'ramda/src/not';
import { Component } from 'react';
import { connect } from 'react-redux';

import offerActions from '../../actions/offers';

const withOfferFetching = renderFunction => {
  class OfferFetching extends Component {
    componentDidMount() {
      const { fetchOffer, match } = this.props;
      const { offerId } = match.params;

      fetchOffer({ id: offerId });
    }

    render() {
      const { offer, match } = this.props;
      const { offerId } = match.params;
      const loading = not(offer);

      return renderFunction(offer, loading, offerId);
    }
  }

  const mapStateToProps = (state, ownProps) => ({
    offer: state.entities.offers[ownProps.match.params.offerId],
  });

  const creators = {
    fetchOffer: offerActions.creators.fetchOfferById.request,
  };

  return connect(mapStateToProps, creators)(OfferFetching);
};

export default withOfferFetching;
