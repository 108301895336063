import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import colors from '../../constants/colors';
import CreditusLogo from '../../resources/logo.png';
import CreditusLogoName from '../../resources/logoName.png';
import Avatar from '../Avatar';

const HeaderContainer = styled.div<{ isMobile: boolean; isiOS: boolean }>`
  background-color: ${colors.WHITE};
  box-shadow: 0 1px 0 0 ${colors.BORDER};
  display: flex;
  height: ${props => (props.isMobile ? '40px' : '60px')};
  justify-content: space-between;
  margin-bottom: 1px;
  width: 100%;
  ${props =>
    props.isiOS
      ? 'position: -webkit-sticky; position: sticky; top: 0; transform: translateZ(0); -webkit-transform: translateZ(0);'
      : ''}
`;

const CreditusLogoImg = styled.img<{ isMobile: boolean }>`
  height: ${props => (props.isMobile ? '20px' : '28px')};
  margin: auto 0px auto ${props => (props.isMobile ? '32px' : '64px')};
`;

const TitleContainer = styled.div`
  color: ${colors.DUSK50};
  font-size: 7.3px;
  font-weight: bold;
  height: 10px;
`;

const NameContainer = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 9.8px;
  font-weight: bold;
  height: 15px;
`;

const OrganizationInfoContainer = styled.div`
  display: flex;
  margin: auto 0px;
  height: 20px;
`;

const LogoContainer = styled.div`
  display: flex;
`;

const OrganizationInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Separator = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${colors.AQUA};
  margin: 0px 6px;
`;

const getOrganizationTitle = (userData: IUserData): string => {
  switch (userData.type) {
    case 'bank':
      return 'Institución';
    case 'promoter':
      return 'Comercio';
    case 'creditus':
      return 'Creditus';
    default:
      return 'Bienvenido';
  }
};

const getOrganizationName = (userData: IUserData): string => {
  switch (userData.type) {
    case 'bank':
      return userData.organization.bank.name;
    case 'promoter':
      return userData.organization.promoter.name;
    case 'creditus':
      return 'Administrador Creditus';
    default:
      return `${userData.user.name} ${userData.user.firstSurname}`;
  }
};

interface IHeaderStateProps {
  userData: IUserData;
}

interface IHeaderOwnProps {
  isiOS: boolean;
  isMobile: boolean;
}

const Header: React.FunctionComponent<IHeaderStateProps & IHeaderOwnProps> = ({
  isiOS,
  userData,
  isMobile,
}) => (
  <HeaderContainer isMobile={isMobile} isiOS={isiOS}>
    <LogoContainer>
      <CreditusLogoImg
        src={isMobile ? CreditusLogo : CreditusLogoName}
        isMobile={isMobile}
      />
      {isMobile ? (
        <OrganizationInfoContainer>
          <Separator />
          <OrganizationInfo>
            <TitleContainer>{getOrganizationTitle(userData)}</TitleContainer>
            <NameContainer>{getOrganizationName(userData)}</NameContainer>
          </OrganizationInfo>
        </OrganizationInfoContainer>
      ) : null}
    </LogoContainer>
    {/*
    // @ts-ignore */}
    {!window.location.search.includes('promoterUserCode') && (
      <Avatar isMobile={isMobile} />
    )}
  </HeaderContainer>
);

const mapStateToProps = (state: any): { userData: IUserData } => ({
  userData: state.auth.userData,
});

export default connect(mapStateToProps)(Header);
