import { createAction } from 'redux-actions';

import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const OFFERS_COUNTS_FETCH = createAsyncFlowTypes('OFFERS_COUNTS_FETCH');
const OFFERS_FETCH = createAsyncFlowTypes('OFFERS_FETCH');
const OFFERS_FETCH_BY_ID = createAsyncFlowTypes('OFFERS_FETCH_BY_ID');
const OFFERS_FETCH_REQUIRED_DOCUMENTS = createAsyncFlowTypes(
  'OFFERS_FETCH_REQUIRED_DOCUMENTS'
);
const OFFERS_ACTIVATE = createAsyncFlowTypes('OFFERS_ACTIVATE');
const OFFERS_ACTIVATE_RESET_STATE = 'OFFERS_ACTIVATE_RESET_STATE';
const OFFERS_MANUAL_CREATE = createAsyncFlowTypes('OFFERS_MANUAL_CREATE');
const OFFERS_MOVE_TO_FORMALIZATION = createAsyncFlowTypes(
  'OFFERS_MOVE_TO_FORMALIZATION'
);
const OFFERS_MOVE_TO_RELEASE = createAsyncFlowTypes('OFFERS_MOVE_TO_RELEASE');
const OFFERS_CREATE_PAYMENT_DOCUMENT = createAsyncFlowTypes(
  'OFFERS_CREATE_PAYMENT_DOCUMENT'
);
const REDIRECT_TO_OFFERED = 'REDIRECT_TO_OFFERED';

const activateOffer = createAsyncFlowCreators(OFFERS_ACTIVATE);
const fetchOffers = createAsyncFlowCreators(OFFERS_FETCH);
const fetchOfferById = createAsyncFlowCreators(OFFERS_FETCH_BY_ID);
const fetchOfferCounts = createAsyncFlowCreators(OFFERS_COUNTS_FETCH);
const fetchOfferRequiredDocuments = createAsyncFlowCreators(
  OFFERS_FETCH_REQUIRED_DOCUMENTS
);
const moveOfferToFormalization = createAsyncFlowCreators(
  OFFERS_MOVE_TO_FORMALIZATION
);
const moveOfferToRelease = createAsyncFlowCreators(OFFERS_MOVE_TO_RELEASE);
const resetOffersActivationState = createAction(OFFERS_ACTIVATE_RESET_STATE);
const createPaymentDocument = createAsyncFlowCreators(
  OFFERS_CREATE_PAYMENT_DOCUMENT
);
const createManualOffer = createAsyncFlowCreators(OFFERS_MANUAL_CREATE);
const redirectToOffered = createAction(REDIRECT_TO_OFFERED);

export default {
  types: {
    OFFERS_COUNTS_FETCH,
    OFFERS_CREATE_PAYMENT_DOCUMENT,
    OFFERS_FETCH,
    OFFERS_FETCH_BY_ID,
    OFFERS_FETCH_REQUIRED_DOCUMENTS,
    OFFERS_ACTIVATE,
    OFFERS_ACTIVATE_RESET_STATE,
    OFFERS_MANUAL_CREATE,
    OFFERS_MOVE_TO_FORMALIZATION,
    OFFERS_MOVE_TO_RELEASE,
    REDIRECT_TO_OFFERED,
  },
  creators: {
    activateOffer,
    createManualOffer,
    createPaymentDocument,
    fetchOffers,
    fetchOfferById,
    fetchOfferCounts,
    fetchOfferRequiredDocuments,
    moveOfferToFormalization,
    moveOfferToRelease,
    resetOffersActivationState,
    redirectToOffered,
  },
};
