import keys from 'ramda/src/keys';
import not from 'ramda/src/not';

const toCamelCase = key => {
  const newKey = [];

  for (let i = 0; i < key.length; i += 1) {
    const char = key[i];
    if (char === '_') {
      newKey.push(key[i + 1].toUpperCase());
      i += 1;
    } else {
      newKey.push(char);
    }
  }

  return newKey.join('');
};

const toSnakeCase = key => {
  const newKey = [];

  key.split('').forEach(char => {
    if (char === char.toUpperCase() && Number.isNaN(char * 1)) {
      newKey.push('_');
      newKey.push(char.toLowerCase());
    } else {
      newKey.push(char);
    }
  });

  return newKey.join('');
};

const isObject = value => typeof value === 'object';

const deepKeyTransform = keyTransform => obj => {
  if (not(isObject(obj)) || obj === null) {
    return obj;
  }

  if (obj.constructor === Array) {
    return obj.map(innerValue => deepKeyTransform(keyTransform)(innerValue));
  }

  const newObj = {};

  keys(obj).forEach(key => {
    const transformedKey = keyTransform(key);
    const value = obj[key];

    if (isObject(value)) {
      newObj[transformedKey] = deepKeyTransform(keyTransform)(value);
    } else {
      newObj[transformedKey] = value;
    }
  });

  return newObj;
};

const deepToCamelCase = deepKeyTransform(toCamelCase);
const deepToSnakeCase = deepKeyTransform(toSnakeCase);

export default {
  deepToCamelCase,
  deepToSnakeCase,
};
