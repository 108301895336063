import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import documentActions from '../../actions/documents';
import offerActions from '../../actions/offers';
import colors from '../../constants/colors';
import CheckmarkIcon from '../../resources/icons/Checkmark';
import TrashIcon from '../../resources/icons/Trash';
import Button from '../Button';
import Card from '../Card';
import FilePickerButton from '../FilePickerButton';
import LoadingState from '../LoadingState';

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const DocumentInfoContainer = styled.div`
  margin: 40px;
  padding-bottom: 40px;
  text-align: center;
`;

const DocumentNameContainer = styled.div`
  font-size: 18px;
  color: ${colors.PRIMARY_TEXT};
  font-weight: bold;
  padding-bottom: 20px;
`;

const DocumentDescriptionContainer = styled.div`
  font-size: 14px;
  color: ${colors.SECONDARY_TEXT};
  padding-bottom: 15px;
`;

const SendDocumentInstructions = styled.div`
  font-size: 14px;
  color: ${colors.PRIMARY_TEXT};
  padding-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const FilesInfoContainer = styled.div`
  padding: 20px 0;
`;

const FileInfo = styled.div`
  display: flex;
  flex-direction: space-around;
  justify-content: center;
  font-size: 14px;
  color: ${colors.SECONDARY_TEXT};
`;

const CheckmarkIconContainer = styled.div`
  width: 100%
  margin: auto;
`;

const TrashIconContainer = styled.div`
width: 100%
margin: auto;
`;

const BoldText = styled.div`
  font-weight: bold;
`;

interface IOfferDocument {
  id: number;
  name: string;
  description: string;
  extension: null | string;
  status: DocumentStatus;
  stage: string;
  offerId: number;
  bankId: number;
  reviewer: string;
  type: string;
  url: null | string;
  created_at: null | Date;
  updated_at: null | Date;
}

interface IClientUploadDocumentView {
  offerId: number;
  documentId: number;
  applicationId: number;
  document: IOfferDocument;
  documentIsUploading: boolean | null;
  fetchOfferDocuments: Function;
  upload: Function;
  history: RouteComponentProps['history'];
}

const ClientUploadDocumentView: React.FunctionComponent<IClientUploadDocumentView> = ({
  offerId,
  documentId,
  applicationId,
  document,
  documentIsUploading,
  fetchOfferDocuments,
  upload,
  history,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  useEffect(() => {
    fetchOfferDocuments({ offerId });
  }, [offerId, fetchOfferDocuments]);

  const documentInfo = (): JSX.Element => {
    if (!document) {
      return <LoadingState />;
    } else {
      return (
        <Fragment>
          <ControlsContainer>
            <Button onClick={() => history.goBack()} variant="secondary">
              Regresar
            </Button>
          </ControlsContainer>
          <Card header="Enviar documento">
            <DocumentInfoContainer>
              <DocumentNameContainer>{document.name}</DocumentNameContainer>
              <DocumentDescriptionContainer>
                {document.description}
              </DocumentDescriptionContainer>
              <SendDocumentInstructions>
                Da click en el botón para añadir los documentos que sean
                requeridos.
                <BoldText>Envía un solo pdf o múltiples imágenes</BoldText>
              </SendDocumentInstructions>
              <ButtonContainer>
                <FilePickerButton
                  id={`${document.id}-${document.name}`}
                  onChange={event => {
                    if (event.target.files) {
                      setFiles([...files, event.target.files[0]]);
                    }
                  }}
                  onClick={() => {
                    return 0;
                  }}
                  text="Añadir documento"
                  variant="secondary"
                />
              </ButtonContainer>
              <FilesInfoContainer>
                {files
                  ? files.map((file, i) => (
                      <FileInfo key={i}>
                        <CheckmarkIconContainer>
                          <CheckmarkIcon size={28} fill={colors.GREEN} />
                        </CheckmarkIconContainer>
                        {file.name}
                        <TrashIconContainer
                          onClick={() => {
                            const newFiles = files.filter(
                              (file, index) => i !== index
                            );
                            setFiles(newFiles);
                          }}
                        >
                          <TrashIcon size={23} fill={colors.PRIMARY_TEXT} />
                        </TrashIconContainer>
                      </FileInfo>
                    ))
                  : null}
              </FilesInfoContainer>
              <SendDocumentInstructions>
                Cuando termines da click para enviar tus documentos.
              </SendDocumentInstructions>
              <ButtonContainer>
                {!documentIsUploading ? (
                  <Button
                    variant={files.length ? 'primary' : 'inactive'}
                    onClick={() => {
                      upload({
                        fileId: document.id,
                        offerId: offerId,
                        files: files,
                      });
                    }}
                  >
                    Enviar documentos
                  </Button>
                ) : (
                  <LoadingState />
                )}
              </ButtonContainer>
            </DocumentInfoContainer>
          </Card>
        </Fragment>
      );
    }
  };

  return documentInfo();
};

const mapStateToProps = (
  state: any,
  ownProps: any
): { document: IOfferDocument; documentIsUploading: boolean | null } => {
  const { documentId } = ownProps;
  return {
    document: state.entities.offerDocuments[documentId],
    documentIsUploading: state.loaders.uploadingFiles,
  };
};

const creators = {
  fetchOfferDocuments:
    offerActions.creators.fetchOfferRequiredDocuments.request,
  upload: documentActions.creators.uploadDocumentFile.request,
};

export default connect(mapStateToProps, creators)(ClientUploadDocumentView);
