import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const INVOICE_FETCH = createAsyncFlowTypes('INVOICE_FETCH');
const INVOICE_DOCUMENT_FILE_UPLOAD = createAsyncFlowTypes(
  'INVOICE_DOCUMENT_FILE_UPLOAD'
);
const INVOICE_DOCUMENT_STATUS_UPDATE = createAsyncFlowTypes(
  'INVOICE_DOCUMENT_STATUS_UPDATE'
);
const INVOICE_COMMISSIONS_FETCH = createAsyncFlowTypes(
  'INVOICE_COMMISSIONS_FETCH'
);
const INVOICES_FETCH = createAsyncFlowTypes('INVOICES_FETCH');

const fetchInvoice = createAsyncFlowCreators(INVOICE_FETCH);
const fetchInvoiceCommissions = createAsyncFlowCreators(
  INVOICE_COMMISSIONS_FETCH
);
const fetchInvoices = createAsyncFlowCreators(INVOICES_FETCH);
const updateInvoiceDocumentStatus = createAsyncFlowCreators(
  INVOICE_DOCUMENT_STATUS_UPDATE
);
const uploadInvoiceDocumentFile = createAsyncFlowCreators(
  INVOICE_DOCUMENT_FILE_UPLOAD
);

export default {
  types: {
    INVOICE_COMMISSIONS_FETCH,
    INVOICE_DOCUMENT_FILE_UPLOAD,
    INVOICE_DOCUMENT_STATUS_UPDATE,
    INVOICE_FETCH,
    INVOICES_FETCH,
  },
  creators: {
    fetchInvoice,
    fetchInvoiceCommissions,
    fetchInvoices,
    uploadInvoiceDocumentFile,
    updateInvoiceDocumentStatus,
  },
};
