import React from 'react';

import colors from '../../constants/colors';

interface IIcon {
  fill: string;
  rotate?: number;
  size: number;
}

const BaseIcon = (
  Path: React.ComponentType<{ fill: string }>,
  viewBox: string
): React.FunctionComponent<IIcon> => {
  return ({ fill, rotate, size }) => {
    return (
      <svg
        width={`${size}px`}
        height={`${size}px`}
        transform={`rotate(${rotate || 0})`}
        viewBox={viewBox}
      >
        <Path fill={fill || colors.DUSK} />
      </svg>
    );
  };
};

export default BaseIcon;
