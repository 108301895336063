import React, { Fragment, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';

import Header from '../Header';
import MainRoutes from '../MainRoutes';

const AppBodyContainer = styled.div<{ isMobile: boolean; height: number }>`
  display: flex;
  height: ${props =>
    props.isMobile ? `calc(${props.height}px - 40px)` : 'calc(100vh - 61px)'};
  transition: height ease 0.1s;
`;

const MainContentContainer = styled.div`
  /* Taking in account the NavMenu */
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 32px;
`;

const ExtraScrollSpace = styled.div`
  height: 40px;
  width: 100%;
`;

type IMainContentProps = RouteComponentProps;

const MainContent: React.FunctionComponent<IMainContentProps> = ({
  location,
}) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (container.current !== null) {
      container.current.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <MainContentContainer id="homeMainContent" ref={container}>
      <MainRoutes />
      <ExtraScrollSpace />
    </MainContentContainer>
  );
};

const MainContentWithRouter = withRouter(MainContent);

interface IClientProfilingWrapper {
  deviceData: IDeviceData;
}

const ClientProfilingWrapper: React.FunctionComponent<IClientProfilingWrapper> = ({
  deviceData,
}) => {
  return (
    <Fragment>
      <Header isMobile={deviceData.isMobile} isiOS={deviceData.isiOS} />
      {deviceData.isMobile ? (
        <Fragment>
          <AppBodyContainer
            isMobile={deviceData.isMobile}
            height={
              deviceData.isiOS
                ? deviceData.clientHeight
                : deviceData.innerHeight
            }
          >
            <MainContentWithRouter />
          </AppBodyContainer>
        </Fragment>
      ) : (
        <AppBodyContainer
          isMobile={deviceData.isMobile}
          height={
            deviceData.isiOS ? deviceData.clientHeight : deviceData.innerHeight
          }
        >
          <MainContentWithRouter />
        </AppBodyContainer>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any): any => ({
  deviceData: state.deviceData,
});

export default connect(mapStateToProps)(ClientProfilingWrapper);
