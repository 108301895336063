import React from 'react';
import styled from 'styled-components';

import LoadingIndicator from '../LoadingIndicator';

const LoadingIndicatorContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const LoadingState: React.FunctionComponent = () => (
  <LoadingIndicatorContainer>
    <LoadingIndicator />
  </LoadingIndicatorContainer>
);

export default LoadingState;
