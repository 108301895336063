import { Form, Formik, FormikProps } from 'formik';
import clone from 'ramda/src/clone';
import keys from 'ramda/src/keys';
import length from 'ramda/src/length';
import reduce from 'ramda/src/reduce';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import multiSectionFormActions from '../../actions/newApplicationMultiSections';

interface Section {
  component?: React.ComponentType<{ formikProps: FormikProps<any> }>;
  initialValues: any;
  name: string;
  sectionSchema: Yup.ObjectSchema<Yup.Shape<{}, {}>>;
  render?(props: FormikProps<any>): JSX.Element;
}

export const Section: React.FunctionComponent<Section> = () => {
  return null;
};

interface MultiSectionFormProps {
  onSubmit(values: any): void;
  onSectionChange(sectionName: string, index: number): void;
  renderButtons(
    onBackButtonClick: () => void,
    onNextButtonClick: () => void
  ): JSX.Element;
  storeSectionsData: Function;
  sections: { [key: string]: any };
  children: React.ReactNode;
}

interface MultiSectionFormState {
  currentSection: number;
  sectionMemory: { [key: string]: any };
}

class MultiSectionForm extends Component<
  MultiSectionFormProps,
  MultiSectionFormState
> {
  state: MultiSectionFormState = {
    currentSection: 0,
    sectionMemory: this.props.sections,
  };

  change = () => {
    this.props.onSectionChange(
      (React.Children.toArray(this.props.children)[
        this.state.currentSection
      ] as any).props.name,
      this.state.currentSection
    );
  };

  nextSection = (values: any) => {
    const { currentSection, sectionMemory } = this.state;
    const { children, storeSectionsData } = this.props;
    const sectionCount = length(React.Children.toArray(children));

    const sectionProps: Section = (React.Children.toArray(children)[
      this.state.currentSection
    ] as any).props;

    const newSectionMemory = { ...sectionMemory, [sectionProps.name]: values };
    const shouldSubmitForm = currentSection === sectionCount - 1;

    const setStateCallback = () => {
      storeSectionsData(newSectionMemory);
      if (shouldSubmitForm) {
        this.props.onSubmit(this.state.sectionMemory);
      } else {
        this.change();
      }
    };

    this.setState(
      oldState => ({
        currentSection: Math.min(oldState.currentSection + 1, sectionCount - 1),
        sectionMemory: newSectionMemory,
      }),
      setStateCallback
    );
  };

  previousSection = (formikProps: FormikProps<any>) => {
    const { currentSection, sectionMemory } = this.state;
    const { children, storeSectionsData } = this.props;
    const { values: formValues, errors } = formikProps;

    const sectionProps: Section = (React.Children.toArray(children)[
      currentSection
    ] as any).props;

    // Current form section may have errors, clean them
    const cleaned = reduce(
      (acc, errorKey) => {
        acc[errorKey] = sectionProps.initialValues[errorKey];
        return acc;
      },
      clone(formValues),
      keys(errors)
    );

    const newSectionMemory = {
      ...sectionMemory,
      [sectionProps.name]: cleaned,
    };

    const sectionCallback = () => {
      storeSectionsData(newSectionMemory);
      this.change();
    };

    this.setState(
      oldState => ({
        currentSection: Math.max(oldState.currentSection - 1, 0),
        sectionMemory: newSectionMemory,
      }),
      sectionCallback
    );
  };

  render() {
    const { currentSection, sectionMemory } = this.state;
    const { children, renderButtons } = this.props;
    const sectionProps: Section = (React.Children.toArray(children)[
      currentSection
    ] as any).props;

    const getChildren = (formikProps: FormikProps<any>) => {
      if (typeof sectionProps.render === 'function') {
        return sectionProps.render(formikProps);
      } else if (sectionProps.component) {
        return React.createElement(sectionProps.component, { formikProps });
      }

      return null;
    };

    return (
      <Formik
        key={currentSection}
        initialValues={
          sectionMemory[sectionProps.name] || sectionProps.initialValues
        }
        onSubmit={this.nextSection}
        render={(formikProps: FormikProps<any>) => (
          <Form>
            {getChildren(formikProps)}
            {renderButtons(
              () => this.previousSection(formikProps),
              formikProps.submitForm
            )}
          </Form>
        )}
        validationSchema={sectionProps.sectionSchema}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  sections: state.newApplication.sections,
});

const creators = {
  storeSectionsData: multiSectionFormActions.creators.storeSectionsData,
};

export default connect(mapStateToProps, creators)(MultiSectionForm);
