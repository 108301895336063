import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import prop from 'ramda/src/prop';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import userManagementActions from '../../actions/userManagement';
import colors from '../../constants/colors';
import grammar from '../../constants/grammar';
import {
  IUserRow,
  UserOption,
  canModifyAdmins,
  getAvailableUsersToCreate,
  getRoleString,
  rowIsAdmin,
  rowIsSystemAdmin,
} from '../../utils/userCreation';
import Button from '../Button';
import Card from '../Card';
import DropdownInput from '../DropdownInput';
import LoadingIndicator from '../LoadingIndicator';
import Table, { TableCell } from '../SmartTable';

const Link = styled.span<{ color: string }>`
  color: ${prop('color')};
  text-decoration: underline;
  cursor: pointer;
`;

const CustomTableCell = styled(TableCell)`
  padding-right: 8px;
  word-break: break-word;
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

const DropdownContainer = styled.div`
  width: 160px;
  padding-top: 48px;
`;

interface IUsersTable {
  users: IUserRow[];
  canModifyAdmins: boolean;
  changeUserStatus: Function;
  changeUserRole: Function;
  availableUsersToCreate: UserOption[];
}

interface IUserViewProps {
  fetchUsers: Function;
  changeUserStatus: Function;
  changeUserRole: Function;
  userData: IUserData;
  history: RouteComponentProps['history'];
}

interface IUserViewReduxStateProps {
  users: IUserRow[] | null;
  userModified: boolean;
  fetchingUsers: boolean;
}

type IUserView = IUserViewProps & IUserViewReduxStateProps;

const getPhoneNumber = (
  phoneNumber: string,
  phoneExtension?: string
): string => {
  return phoneExtension ? `${phoneNumber} ext. ${phoneExtension}` : phoneNumber;
};

const getUserRow = (
  userRow: IUserRow,
  canModifyAdmins: boolean,
  availableUsersToCreate: UserOption[],
  changeUserStatus: Function,
  changeUserRole: Function
): JSX.Element[] => {
  const user = userRow.user;

  const rowIsModifiable = !(
    rowIsSystemAdmin(userRow) ||
    (rowIsAdmin(userRow) && !canModifyAdmins)
  );

  const status = grammar[user.status];
  const name = `${user.name} ${user.firstSurname} ${user.secondSurname}`;
  const phoneNumber = getPhoneNumber(
    user.phoneNumber,
    user.phoneExtension || ''
  );

  const roleText = getRoleString(userRow);
  const initialOption = availableUsersToCreate.find(
    option => option.value === userRow.role
  );
  const roleComponent =
    rowIsModifiable && !isNil(initialOption) ? (
      <DropdownContainer>
        <DropdownInput
          label=""
          onChange={option =>
            changeUserRole({ userId: user.id, userRole: option.value })
          }
          options={availableUsersToCreate}
          value={initialOption.value}
        />
      </DropdownContainer>
    ) : (
      roleText
    );

  const linkColor = user.status === 'activo' ? colors.RED : colors.GREEN;
  const linkText = user.status === 'activo' ? 'Desactivar' : 'Activar';

  const actions = rowIsModifiable ? (
    <Link
      onClick={() => changeUserStatus({ userId: user.id })}
      color={linkColor}
    >
      {linkText}
    </Link>
  ) : null;

  return [
    <CustomTableCell key={0}>{status}</CustomTableCell>,
    <CustomTableCell key={1}>{name}</CustomTableCell>,
    <CustomTableCell key={2}>{user.email}</CustomTableCell>,
    <CustomTableCell key={3}>{roleComponent}</CustomTableCell>,
    <CustomTableCell key={4}>{phoneNumber}</CustomTableCell>,
    <CustomTableCell key={5}>{actions}</CustomTableCell>,
  ];
};

const UsersTable: React.FunctionComponent<IUsersTable> = ({
  users,
  canModifyAdmins,
  availableUsersToCreate,
  changeUserStatus,
  changeUserRole,
}) => (
  <Table
    data={users}
    gridColumns={`100px repeat(5, 1fr)`}
    headers={['Estatus', 'Nombre', 'Email', 'Permisos', 'Teléfono', 'Acciones']}
    renderRow={userRow =>
      getUserRow(
        userRow,
        canModifyAdmins,
        availableUsersToCreate,
        changeUserStatus,
        changeUserRole
      )
    }
  />
);

const UsersView: React.FunctionComponent<IUserView> = ({
  users,
  fetchUsers,
  fetchingUsers,
  userData,
  history,
  userModified,
  changeUserStatus,
  changeUserRole,
}) => {
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, userModified]);

  const content =
    fetchingUsers || isNil(users) || isEmpty(users) ? (
      <LoadingIndicatorContainer>
        <LoadingIndicator />
      </LoadingIndicatorContainer>
    ) : (
      <UsersTable
        users={users}
        canModifyAdmins={canModifyAdmins(userData)}
        availableUsersToCreate={getAvailableUsersToCreate(userData)}
        changeUserStatus={changeUserStatus}
        changeUserRole={changeUserRole}
      />
    );

  const addUserButton = fetchingUsers ? null : (
    <Button onClick={() => history.push('/users/new')}>Crear usuario</Button>
  );

  return (
    <Card header={'Usuarios'} controls={addUserButton}>
      {content}
    </Card>
  );
};

const mapStateToProps = (state: any): IUserViewReduxStateProps => ({
  users: state.entities.userManagement.users,
  userModified: state.entities.userManagement.userModified,
  fetchingUsers: state.loaders.fetchingUsers,
});

const creators = {
  fetchUsers: userManagementActions.creators.fetchUsers.request,
  changeUserStatus: userManagementActions.creators.changeUserStatus.request,
  changeUserRole: userManagementActions.creators.changeUserRole.request,
};

export default connect(mapStateToProps, creators)(UsersView);
