const profilingContactFormErrors = {
  email: {
    email: 'El correo electrónico no es válido.',
    required: 'El correo electrónico es obligatorio.',
  },
  phoneNumber: {
    matches: 'El teléfono debe de tener 10 dígitos.',
    required: 'El teléfono es obligatorio.',
  },
  password: {
    required: 'Ingresa una contraseña.',
  },
};

export default profilingContactFormErrors;
