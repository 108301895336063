import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 100 100';

const PhoneIcon = ({ fill }: { fill: string }) => (
  <path
    d="M73,85.5c-0.3,0-0.7,0-1,0c-11-0.6-28.2-5.6-40.6-17.6C19.3,56.4,12.1,41.8,12,29C11.9,17.5,22.1,10.2,22.6,9.9  c0.3-0.2,0.7-0.4,1.1-0.4h7.7c0.7,0,1.4,0.4,1.8,1L43,28.4c0.4,0.7,0.3,1.5-0.1,2.1l-7,9.6c1.6,2.5,5.7,8.5,10.7,12.3  c5.2,4,12.7,8.5,15.7,10.2l8.5-8.7c0.6-0.6,1.5-0.8,2.3-0.4l18.4,8.9c0.7,0.3,1.1,1,1.1,1.8v9c0,0.4-0.1,0.8-0.4,1.2  C92,74.8,84.1,85.5,73,85.5z M24.4,13.5C22.5,15,15.9,20.9,16,29c0.1,11.8,6.9,25.3,18.2,36.1c11.7,11.2,27.8,15.8,38.1,16.5  c8.3,0.5,14.9-7,16.5-9v-7.1l-16-7.7l-8.6,8.8c-0.6,0.6-1.6,0.8-2.4,0.4c-0.4-0.2-10.7-6-17.5-11.3C37.2,50.2,32,41.4,31.8,41  c-0.4-0.7-0.4-1.5,0.1-2.2l7-9.7l-8.7-15.7H24.4z"
    fill={fill}
    fillRule="evenodd"
  />
);

export default BaseIcon(PhoneIcon, viewBox);
