import { call, put, select, takeLatest } from 'redux-saga/effects';

import offerMessagesActions from '../actions/offerMessages';
import runner from './runners';

const getId = (() => {
  let id = -1;

  return () => {
    id += 1;
    return id;
  };
})();

function* fetchOfferMessages(action) {
  const { offerId } = action.payload;

  yield call(
    runner,
    offerMessagesActions.creators.fetchOfferMessages,
    {
      method: 'GET',
      url: `/api/offers/${offerId}/messages`,
    },
    { originalAction: action }
  );
}

function* watchFetchOfferMessagesRequested() {
  yield takeLatest(
    offerMessagesActions.types.OFFER_MESSAGES_FETCH.REQUESTED,
    fetchOfferMessages
  );
}

function* createOfferMessage(action) {
  const { offerId, content } = action.payload;
  const { user } = yield select(state => state.auth.userData);

  const message = {
    id: `optimist-${getId()}`,
    offerId,
    content,
    userId: user.id,
    read: 1,
    sysmessage: 0,
    createdAt: new Date().toISOString(),
    creator: { ...user },
  };

  yield put(
    offerMessagesActions.creators.createOfferMessageOptimistic({ message })
  );

  yield call(runner, offerMessagesActions.creators.createOfferMessage, {
    method: 'POST',
    payload: { message: content },
    url: `/api/offers/${offerId}/messages`,
  });
}

function* watchCreateOfferMessageRequested() {
  yield takeLatest(
    offerMessagesActions.types.OFFER_MESSAGES_CREATE.REQUESTED,
    createOfferMessage
  );
}

export default {
  watchFetchOfferMessagesRequested,
  watchCreateOfferMessageRequested,
};
