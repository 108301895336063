import { Field, FieldProps } from 'formik';
import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

import { applySelector } from '../../utils/misc';
import Input from '../Input';

export type InputMode =
  | 'text'
  | 'tel'
  | 'search'
  | 'none'
  | 'url'
  | 'email'
  | 'numeric'
  | 'decimal';

interface FormikNumber<T, U> {
  disabled?: boolean;
  formatValue(value: T): number | string;
  parseValue(value: string): U;
  label: string;
  name: string;
  inputMode?: InputMode;
  warning?: boolean;
}

type FormikNumberProps<T, U> = FormikNumber<T, U> & NumberFormatProps;

interface CustomInputProps {
  fieldProps: FieldProps;
  label: string;
  warning?: boolean;
}

type InternalInputProps = CustomInputProps & React.HTMLProps<HTMLInputElement>;

const InternalInput: React.FunctionComponent<InternalInputProps> = ({
  fieldProps,
  label,
  warning = false,
  ...inputProps
}) => {
  const { field, form } = fieldProps;
  return (
    <Input
      {...inputProps}
      value={`${inputProps.value}`}
      error={
        applySelector(form.touched, field.name) &&
        applySelector(form.errors, field.name)
      }
      label={label}
      withErrorMessage={true}
      warning={warning}
    />
  );
};

function FormikNumber<T, U>({
  formatValue,
  label,
  name,
  parseValue,
  inputMode = 'text',
  warning = false,
  ...numberFormatProps
}: FormikNumberProps<T, U>): JSX.Element {
  return (
    <Field
      name={name}
      render={({ field, form }: FieldProps) => (
        <NumberFormat
          {...numberFormatProps}
          value={formatValue(field.value)}
          customInput={InternalInput}
          fieldProps={{ field, form }}
          label={label}
          onBlur={val => {
            form.setFieldValue(field.name, parseValue(val.target.value));
            form.setFieldTouched(field.name);
          }}
          warning={warning}
          inputMode={inputMode}
        />
      )}
    />
  );
}

export default FormikNumber;
