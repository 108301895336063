import { call, takeLatest } from 'redux-saga/effects';

import promotersClientsActions from '../actions/promotersClients';
import runner from './runners';

function* fetchPromotersClients(action) {
  yield call(runner, promotersClientsActions.creators.fetchPromotersClients, {
    method: 'GET',
    url: '/api/promoter/clients',
    params: action.payload,
  });
}

function* watchPromotersClientsFetchRequested() {
  yield takeLatest(
    promotersClientsActions.types.PROMOTERS_CLIENTS_FETCH.REQUESTED,
    fetchPromotersClients
  );
}

export default {
  watchPromotersClientsFetchRequested,
};
