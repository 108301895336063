import { handleActions } from 'redux-actions';

const initialState = {
  loading: false,
  verificationCompleted: false,
  error: '',
};

const biometricVerificationReducer = handleActions(
  {
    BIOMETRIC_VERIFICATION_REQUESTED: state => ({
      ...state,
      loading: true,
      error: '',
    }),
    BIOMETRIC_VERIFICATION_COMPLETED: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        verificationCompleted: true,
      };
    },
    BIOMETRIC_VERIFICATION_FAILED: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        error: payload.response.data.error,
      };
    },
    CLEAR_NEW_APPLICATION: () => initialState,
  },
  initialState
);

export default biometricVerificationReducer;
