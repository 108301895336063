import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import auditApplicationActions from '../../actions/audit';
import colors from '../../constants/colors';
import f from '../../constants/formatters';
import grammar from '../../constants/grammar';
import Card from '../Card';
import LoadingIndicator from '../LoadingIndicator';
import SmartTable, { TableCell } from '../SmartTable';

const LoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 50vh;
  justify-content: center;
  width: 100%;
`;

const Link = styled.span`
  color: ${colors.HYPERLINK};
  text-decoration: underline;
  cursor: pointer;
`;

interface IApplication {
  id: number;
  date: string;
  type: string;
  amount: string;
  term: string;
  status: string;
}

interface IAuditViewProps {
  fetchAuditApplications: Function;
  applications: IApplication[];
  history: RouteComponentProps['history'];
}

const AuditView: React.FunctionComponent<IAuditViewProps> = ({
  fetchAuditApplications,
  applications,
  history,
}) => {
  const [sortedApplications, setSortedApplications] = useState<
    IApplication[]
  >();

  useEffect(() => {
    fetchAuditApplications();
  }, [fetchAuditApplications]);

  useEffect(() => {
    if (!(isNil(applications) || isEmpty(applications))) {
      setSortedApplications(
        applications.sort((a, b) => {
          return b.id - a.id;
        })
      );
    }
  }, [applications]);

  const content = isNil(sortedApplications) ? (
    <LoadingState>
      <LoadingIndicator />
    </LoadingState>
  ) : (
    <SmartTable
      data={sortedApplications}
      gridColumns={`repeat(6, 1fr)`}
      headers={['ID SOLICITUD', 'FECHA', 'TIPO', 'MONTO', 'PLAZO', 'ESTATUS']}
      renderRow={application => {
        const id = (
          <Link onClick={() => history.push(`/audit/${application.id}`)}>
            {application.id}
          </Link>
        );

        return [
          <TableCell key={0}>{id}</TableCell>,
          <TableCell key={1}>{application.date}</TableCell>,
          <TableCell key={2}>{grammar[application.type]}</TableCell>,
          <TableCell key={3}>{f.currency(application.amount)}</TableCell>,
          <TableCell key={4}>{`${application.term} meses`}</TableCell>,
          <TableCell key={5}>{grammar[application.status]}</TableCell>,
        ];
      }}
    />
  );

  return <Card header={'Auditoria'}>{content}</Card>;
};

const mapStateToProps = (state: any) => ({
  applications: state.entities.audit.applications,
});

const creators = {
  fetchAuditApplications:
    auditApplicationActions.creators.fetchAuditApplications.request,
};

export default connect(mapStateToProps, creators)(AuditView);
