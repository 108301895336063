import isNil from 'ramda/src/isNil';
import not from 'ramda/src/not';
import React from 'react';
import ReactModal from 'react-modal';
import styled from 'styled-components';

import colors from '../../constants/colors';

if (not(process.env.NODE_ENV === 'test')) {
  ReactModal.setAppElement('#root');
}

const overlayStyle = {
  backgroundColor: '#0000004f',
};

const Content = styled.div`
  padding: 24px 32px;
`;

const Controls = styled.div`
  padding: 12px 24px;
`;

const HeaderContainer = styled.div`
  display: flex;
  padding: 0 24px;
`;

const HeaderDefault = styled.div`
  align-items: center;
  color: ${colors.PRIMARY_TEXT};
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 48px;
`;

const Separator = styled.div`
  background-color: #ebeef4;
  height: 2px;
  width: 100%;
`;

const CloseModal = styled.div`
  align-items: center;
  color: ${colors.DUSK50};
  cursor: pointer;
  display: flex;
  font-size: 22px;
  font-weight: bold;
  margin-left: auto;
  transform: rotate(-315deg);
`;

interface Modal {
  content: () => JSX.Element;
  controls: () => JSX.Element;
  header: string | (() => JSX.Element);
  isOpen: boolean;
  onRequestClose: (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => void;
  overflow?: string;
  shouldCloseOnOverlayClick: boolean;
  showCloseIcon?: boolean;
  width: string;
  height?: string;
}

const Modal: React.FunctionComponent<Modal> = ({
  content,
  controls,
  header,
  isOpen,
  onRequestClose,
  overflow = 'auto',
  shouldCloseOnOverlayClick,
  showCloseIcon = true,
  width,
  height,
}) => {
  const contentStyle = {
    border: `solid 1px ${colors.BORDER}`,
    bottom: 'auto',
    top: isNil(height) ? '20vh' : `calc(50vh - (${height} / 2))`,
    left: `calc(50vw - (${width} / 2))`,
    width,
    padding: '0',
    overflow,
  };

  const modalStyle = {
    content: contentStyle,
    overlay: overlayStyle,
  };

  const headerElement =
    typeof header === 'string' ? (
      <HeaderDefault>{header}</HeaderDefault>
    ) : (
      header()
    );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={modalStyle}
    >
      <HeaderContainer>
        {headerElement}
        {showCloseIcon ? (
          <CloseModal onClick={onRequestClose}>+</CloseModal>
        ) : null}
      </HeaderContainer>
      <Separator />
      <Content>{content()}</Content>
      <Separator />
      <Controls>{controls()}</Controls>
    </ReactModal>
  );
};

export default Modal;
