import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth';
import values from 'ramda/src/values';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import invoicesActions from '../../actions/invoices';
import colors from '../../constants/colors';
import f from '../../constants/formatters';
import { invoices as invoicesGrammar } from '../../constants/grammar';
import { mapInvoiceStatusToColor } from '../../constants/invoices';
import Card from '../Card';
import ColorLabel from '../ColorLabel';
import InternalLink from '../InternalLink';
import {
  InvoicesAmountCard,
  InvoicesAmountCardList,
} from '../InvoicesAmountCard';
import InvoicesViewHeader from '../InvoicesViewHeader';
import LoadingIndicator from '../LoadingIndicator';
import Table, { TableCell } from '../SmartTable';

const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
`;

const MarginBottom = styled.div`
  margin-bottom: 24px;
`;

interface IInvoicesTableConfig {
  header: string;
  gridColumn: string;
  headers: string[];
  renderRow(invoices: any): JSX.Element[];
}

interface IInvoicesViewConfig {
  cards: InvoiceStatus[];
  title: string;
  type?: 'payable' | 'receivable';
  currentInvoicesTableConfig: IInvoicesTableConfig;
  paidInvoicesTableConfig: IInvoicesTableConfig;
}

interface IInvoicesViewInternal {
  fetchInvoices: (payload: {
    startDate?: Date;
    endDate?: Date;
    type?: 'payable' | 'receivable';
  }) => void;
  fetchingInvoices: boolean;
  invoices: IInvoice[];
}

interface IInvoicesTables {
  currentInvoices: IInvoice[];
  currentInvoicesTableConfig: IInvoicesTableConfig;
  paidInvoices: IInvoice[];
  paidInvoicesTableConfig: IInvoicesTableConfig;
}

const shouldShowCard = (cards: InvoiceStatus[], type: InvoiceStatus) =>
  cards.includes(type);

export const renderInvoiceRow = ({
  showRecipientName,
}: {
  showRecipientName: boolean;
}) => (invoice: IInvoice) => {
  const columns = [
    <TableCell key={0}>
      <InternalLink text={invoice.id} to={`/invoices/${invoice.id}`} />
    </TableCell>,
    <TableCell key={1}>
      {format(invoice.startDate, 'dd/MM/yyyy')} -{' '}
      {format(invoice.endDate, 'dd/MM/yyyy')}
    </TableCell>,
    <TableCell key={2}>{f.currency(invoice.totalBeforeTaxes)}</TableCell>,
    <TableCell key={3}>{f.currency(invoice.taxes)}</TableCell>,
    <TableCell key={4}>{f.currency(invoice.totalDue)}</TableCell>,
  ];

  if (showRecipientName) {
    columns.push(<TableCell key={5}>{invoice.recipientLegalName}</TableCell>);
  }

  columns.push(
    <TableCell key={6}>
      <ColorLabel color={mapInvoiceStatusToColor[invoice.status]} width="120px">
        {invoicesGrammar[invoice.status]}
      </ColorLabel>
    </TableCell>
  );

  return columns;
};

const sumInvoicesTotals = (invoices: IInvoice[]) =>
  invoices.reduce<number>((total, invoice) => total + invoice.totalDue, 0);

const InvoicesTables: React.FunctionComponent<RouteComponentProps &
  IInvoicesTables> = ({
  history,
  currentInvoices,
  currentInvoicesTableConfig,
  paidInvoices,
  paidInvoicesTableConfig,
}) => {
  return (
    <Fragment>
      <MarginBottom>
        <Card header={currentInvoicesTableConfig.header}>
          <Table
            data={currentInvoices}
            gridColumns={currentInvoicesTableConfig.gridColumn}
            headers={currentInvoicesTableConfig.headers}
            onRowClick={invoice => history.push(`/invoices/${invoice.id}`)}
            renderRow={currentInvoicesTableConfig.renderRow}
          />
        </Card>
      </MarginBottom>
      <MarginBottom>
        <Card header={paidInvoicesTableConfig.header}>
          <Table
            data={paidInvoices}
            gridColumns={paidInvoicesTableConfig.gridColumn}
            headers={paidInvoicesTableConfig.headers}
            onRowClick={invoice => history.push(`/invoices/${invoice.id}`)}
            renderRow={paidInvoicesTableConfig.renderRow}
          />
        </Card>
      </MarginBottom>
    </Fragment>
  );
};

const InvoicesTablesWithRouter = withRouter(InvoicesTables);

const buildInvoicesView = ({
  cards,
  title,
  type,
  currentInvoicesTableConfig,
  paidInvoicesTableConfig,
}: IInvoicesViewConfig): React.ComponentType => {
  const ConfigurableInvoicesView: React.FunctionComponent<IInvoicesViewInternal> = ({
    fetchInvoices,
    fetchingInvoices,
    invoices,
  }) => {
    const [selectedMonth, setMonth] = useState(startOfMonth(new Date()));

    useEffect(() => {
      fetchInvoices({
        startDate: selectedMonth,
        endDate: addMonths(selectedMonth, 1),
        type,
      });
    }, [fetchInvoices, selectedMonth]);

    const pendingInvoices = invoices.filter(
      invoice => invoice.status === 'pending'
    );
    const inValidationInvoices = invoices.filter(
      invoice => invoice.status === 'validation'
    );
    const paidInvoices = invoices.filter(invoice => invoice.status === 'paid');

    return (
      <Fragment>
        <MarginBottom>
          <InvoicesViewHeader
            onMonthChange={month => setMonth(month)}
            selectedMonth={selectedMonth}
            title={title}
          />
        </MarginBottom>
        <MarginBottom>
          <InvoicesAmountCardList>
            {shouldShowCard(cards, 'paid') ? (
              <InvoicesAmountCard
                amount={sumInvoicesTotals(paidInvoices)}
                color={colors.GREEN}
                title={'PAGADO'}
              />
            ) : null}
            {shouldShowCard(cards, 'validation') ? (
              <InvoicesAmountCard
                amount={sumInvoicesTotals(inValidationInvoices)}
                color={colors.ORANGE}
                title={'EN VALIDACIÓN'}
              />
            ) : null}
            {shouldShowCard(cards, 'pending') ? (
              <InvoicesAmountCard
                amount={sumInvoicesTotals(pendingInvoices)}
                color={colors.RED}
                title={'POR FACTURAR'}
              />
            ) : null}
          </InvoicesAmountCardList>
        </MarginBottom>
        {fetchingInvoices ? (
          <LoadingIndicatorContainer>
            <LoadingIndicator />
          </LoadingIndicatorContainer>
        ) : (
          <InvoicesTablesWithRouter
            currentInvoices={[...pendingInvoices, ...inValidationInvoices]}
            currentInvoicesTableConfig={currentInvoicesTableConfig}
            paidInvoices={paidInvoices}
            paidInvoicesTableConfig={paidInvoicesTableConfig}
          />
        )}
      </Fragment>
    );
  };

  return connect(
    (state: any) => ({
      invoices: values(state.entities.invoices),
      fetchingInvoices: state.entities.invoices === null,
    }),
    { fetchInvoices: invoicesActions.creators.fetchInvoices.request }
  )(ConfigurableInvoicesView);
};

export default buildInvoicesView;
