import { Formik, FormikProps } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';
import styled from 'styled-components';
import * as Yup from 'yup';

import forgotPasswordActions from '../../actions/forgotPassword';
import colors from '../../constants/colors';
import LogoName from '../../resources/logoName.png';
import Border from '../Border';
import Button from '../Button';
import { FormikText } from '../FormikInputs';
import LoadingIndicator from '../LoadingIndicator';

const Logo = styled.img`
  height: 100%;
`;

const LogoContainer = styled.div`
  align-items: center;
  display: flex;
  height: 3.5em;
  justify-content: center;
  padding: 0.7em;
`;

const Title = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 5em;
  padding: 0.6em;
`;

const TitleText = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 0.2em;
`;

const TitleSubText = styled.div`
  color: ${colors.SECONDARY_TEXT};
  text-align: center;
`;

const MainContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2em;
  padding: 0.6em 1em;
`;

const FormContainer = styled.div`
  padding-top: 40px;
  width: 240px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PageContent = styled.div`
  background-color: ${colors.WHITE};
  color: ${colors.PRIMARY_TEXT};
  font-family: 'Lato', sans-serif;
  height: 100vh;
  margin: 0;
`;

const LoadingIndicatorContainer = styled.div`
  margin-bottom: 0.5em;
`;

const LoadingStateContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 20em;
  justify-content: center;
  text-align: center;
`;

const SuccessContainer = styled(LoadingStateContainer)`
  font-weight: bold;
`;

const errors = {
  newPassword: {
    min: 'La nueva contraseña debe de tener por lo menos 8 caracteres.',
    required: 'La nueva contraseña es un campo obligatorio.',
  },
  confirmNewPassword: {
    oneOf: 'Las contraseñas deben de coincidir.',
    required: 'La confirmación de contraseña es un campo obligatorio.',
  },
};

interface IPasswordChangeForm {
  formikProps: FormikProps<{ newPassword: string; confirmPassword: string }>;
  changingPassword: boolean;
}

interface IPasswordRecoveryView {
  match: match<{ token: string }>;
  changingPassword: boolean;
  passwordChanged: boolean;
  resetPassword: ({
    token,
    newPassword,
    confirmPassword,
  }: {
    token: string;
    newPassword: string;
    confirmPassword: string;
  }) => void;
}

const LoadingState: React.FunctionComponent = () => {
  return (
    <LoadingStateContainer>
      <LoadingIndicatorContainer>
        <LoadingIndicator />
      </LoadingIndicatorContainer>
      Por favor espera mientras se restablece su contraseña
    </LoadingStateContainer>
  );
};

const PasswordChangeForm: React.FunctionComponent<IPasswordChangeForm> = ({
  changingPassword,
  formikProps,
}) => {
  return (
    <FormContainer>
      <FormikText
        disabled={changingPassword}
        label="NUEVA CONTRASEÑA"
        name="newPassword"
        type="password"
      />
      <FormikText
        disabled={changingPassword}
        label="CONFIRMAR CONTRASEÑA"
        name="confirmPassword"
        type="password"
      />
      <Button
        variant={
          formikProps.isValid && !changingPassword ? 'primary' : 'inactive'
        }
        onClick={() => formikProps.submitForm()}
      >
        Guardar nueva contraseña
      </Button>
    </FormContainer>
  );
};

const PasswordRecoveryView: React.FunctionComponent<IPasswordRecoveryView> = ({
  match,
  changingPassword,
  passwordChanged,
  resetPassword,
}) => {
  const renderContent = () => {
    if (changingPassword) {
      return <LoadingState />;
    } else if (passwordChanged) {
      return (
        <SuccessContainer>
          Tu contraseña fue restablecida con éxito.
        </SuccessContainer>
      );
    } else {
      return (
        <Formik
          initialValues={{ newPassword: '', confirmPassword: '' }}
          onSubmit={values =>
            resetPassword({ ...values, token: match.params.token })
          }
          render={formikProps => (
            <PasswordChangeForm
              changingPassword={changingPassword}
              formikProps={formikProps}
            />
          )}
          validationSchema={Yup.object().shape({
            newPassword: Yup.string()
              .min(8, errors.newPassword.min)
              .required(errors.newPassword.required),
            confirmPassword: Yup.string()
              .oneOf(
                [Yup.ref('newPassword'), null],
                errors.confirmNewPassword.oneOf
              )
              .required(errors.confirmNewPassword.required),
          })}
        />
      );
    }
  };

  return (
    <PageContent>
      <LogoContainer>
        <Logo src={LogoName} />
      </LogoContainer>
      <Border />
      <Title>
        <TitleText>Restablece tu contraseña</TitleText>
        <TitleSubText>
          Al finalizar el proceso podrás ingresar a tu cuenta con tu nueva
          contraseña
        </TitleSubText>
      </Title>
      <Border />
      <MainContent>{renderContent()}</MainContent>
    </PageContent>
  );
};

const mapStateToProps = (state: any) => ({
  changingPassword: state.loaders.changingPassword,
  passwordChanged: state.forgotPassword.passwordChanged,
});

const creators = {
  resetPassword: forgotPasswordActions.creators.resetPassword.request,
};

export default connect(mapStateToProps, creators)(PasswordRecoveryView);
