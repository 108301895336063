import React from 'react';
import styled from 'styled-components';

import Dropdown from '../Dropdown';
import { Option } from '../Dropdown/Dropdown';
import Label from '../Label';

const Container = styled.div`
  height: 88px;
`;

const ErrorContainer = styled.div`
  padding-top: 1px;
`;

interface DropdownInput {
  disabled?: boolean;
  error?: string;
  label: string;
  onBlur?(): void;
  onChange(selectedOption: Option): void;
  onClose?(): void;
  onOpen?(): void;
  options: Array<Option>;
  placeholder?: string;
  value: string | number;
}

const DropdownInput: React.FunctionComponent<DropdownInput> = ({
  disabled,
  error,
  label,
  onBlur,
  onChange,
  onClose,
  onOpen,
  options,
  placeholder,
  value,
}) => (
  <Container>
    <div>
      <Label>{label}</Label>
    </div>
    <Dropdown
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      onClose={onClose}
      onOpen={onOpen}
      options={options}
      placeholder={placeholder}
      value={value}
    />
    <ErrorContainer>
      <Label error={true}>{error}</Label>
    </ErrorContainer>
  </Container>
);

export default DropdownInput;
