import { isBankAnalyst, isBankUser } from '../../utils/permissions';

const allStages = [
  {
    stage: 'analysis',
    text: 'Revisión',
  },
  {
    stage: 'approval',
    text: 'Aprobación',
  },
  {
    stage: 'pre-formalization',
    text: 'Formalización',
  },
  {
    stage: 'formalization',
    text: 'Digitalización',
  },
  {
    stage: 'release',
    text: 'Liberación',
  },
];

const bankAnalystStages = [
  {
    stage: 'analysis',
    text: 'Revisión',
  },
];

const getStagesConfig = userData => {
  if (isBankAnalyst(userData)) {
    return bankAnalystStages;
  }

  return allStages;
};

const getMode = (userData, stage) => {
  if (stage === 'pre-formalization' || stage === 'release') {
    return isBankUser(userData) ? 'upload' : 'review';
  }

  return isBankUser(userData) ? 'review' : 'upload';
};

export { getStagesConfig, getMode };
