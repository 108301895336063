import { call, put, takeLatest } from 'redux-saga/effects';

import biometricVerification from '../actions/biometricVerification';
import toastActions from '../actions/toastNotifications';
import history from '../history';
import { addSearchParamsToUrl } from '../utils/misc';
import { buildErrorToast } from '../utils/toastNotifications';
import runner from './runners';

function* sendBiometricVerification(action) {
  const { biometricVerificationId, data } = action.payload;
  const { error } = yield call(
    runner,
    biometricVerification.creators.biometricVerification,
    {
      method: 'POST',
      url: `/api/biometric-verification/${biometricVerificationId}`,
      payload: data,
    }
  );

  if (error) {
    let toastMessage =
      'Ha ocurrido un error. Por favor contacta a soporte al 33-1803-7895.';
    switch (error.response.data.error) {
      case 'FRONT_ID_PHOTO_IS_BW':
        toastMessage =
          'Se detectó que el frente de la identificación está en blanco y negro. Por favor, utiliza fotografías o imágenes escaneadas a color.';
        break;
      case 'BACK_ID_PHOTO_IS_BW':
        toastMessage =
          'Se detectó que el reverso de la identificación está en blanco y negro. Por favor, utiliza fotografías o imágenes escaneadas a color.';
        break;
      case 'SELFIE_IS_BW':
        toastMessage =
          'Se detectó que la selfie está en blanco y negro. Por favor, utiliza una fotografía a color.';
        break;
      case 'FRONT_ID_PHOTO_CONTAINS_NO_ID':
        toastMessage =
          'No se detectó el frente de la identificación. Por favor, realiza una nueva captura. El frente de la identificación debe verse completa y claramente.';
        break;
      case 'SELFIE_CONTAINS_NO_VALID_FACE':
        toastMessage =
          'No se detectó una cara de una persona en la selfie. Por favor, realiza una nueva captura. La cara del sujeto debe debe verse claramente y en el primer plano de la imágen. ';
        break;
      case 'SELFIE_CONTAINS_ID':
        toastMessage =
          'Se detectó una identificación en la selfie. Por favor, realiza una nueva captura donde no se vea una identificación. La cara del sujeto debe debe verse claramente y en el primer plano de la imágen.';
        break;
      case 'BACK_ID_PHOTO_IS_INVALID':
        toastMessage =
          'No se detectó el reverso de la identificación. Por favor, realiza una nueva captura. El reverso de la identificación debe verse completa y claramente.';
        break;
      case 'SELFIE_AND_ID_TOO_SIMILAR':
        toastMessage =
          'Se detectó la misma imágen en la selfie y el frente de la identificación. Por favor, no utilice una identificación como selfie.';
        break;
      case 'SELFIE_AND_ID_VERY_DIFFERENT':
        toastMessage =
          'Se detectó que el sujeto de la selfie no es el mismo que el del frente de la identificación. Las imágenes tienen que ser de la misma persona.';
        break;
      case 'DIFFERENT_SELFIE_TO_PREVIOUS_APPLICATION':
        toastMessage =
          'Se detectó que el sujeto de la selfie no es el mismo que el de verificaciones previas. Las imágenes tienen que ser de la misma persona.';
        break;
      case 'BIOMETRIC_VERIFICATION_NOT_FOUND':
        toastMessage =
          'La liga de la verificación que está tratando de utilizar es inválida o no existe. Por favor realiza una nueva verificación y sigue el nuevo enlace.';
        break;
      case 'BIOMETRIC_VERIFICATION_ALREADY_USED':
        toastMessage =
          'La liga de la verificación que está tratando de utilizar ya fue usada. Por favor realiza una nueva verificación y sigue el nuevo enlace.';
        break;
      default:
        break;
    }
    const toast = buildErrorToast(toastMessage, 10000);
    yield put(toastActions.creators.showToast(toast));
  }
  const url = addSearchParamsToUrl('/application/profiling');
  yield call(history.push, url);
}

function* watchSendBiometricVerification() {
  yield takeLatest(
    biometricVerification.types.BIOMETRIC_VERIFICATION.REQUESTED,
    sendBiometricVerification
  );
}

export default { watchSendBiometricVerification };
