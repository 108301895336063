import merge from 'ramda/src/merge';
import { handleActions } from 'redux-actions';

import utils from './utils';

const initialState = {
  updateOfferVigency: {
    showLoading: null,
    success: null,
  },
};

const banksReducer = handleActions(
  {
    BANKS_INFO_FETCH_COMPLETED: (state, action) => ({
      ...state,
      [action.payload.id]: action.payload,
    }),
    BANKS_FETCH_COMPLETED: (state, action) =>
      merge(state, utils.buildDictWithKey(action.payload, 'id')),
    BANKS_FETCH_BY_PRODUCT_TYPE_COMPLETED: (state, action) =>
      merge(state, utils.buildDictWithKey(action.payload, 'id')),
    BANKS_UPDATE_OFFER_VIGENCY_REQUESTED: state => ({
      ...state,
      updateOfferVigency: {
        ...state.offerVigency,
        showLoading: true,
      },
    }),
    BANKS_UPDATE_OFFER_VIGENCY_COMPLETED: state => ({
      ...state,
      updateOfferVigency: {
        showLoading: false,
        success: true,
      },
    }),
    BANKS_UPDATE_OFFER_VIGENCY_FAILED: state => ({
      ...state,
      updateOfferVigency: {
        showLoading: false,
        success: false,
      },
    }),
    CLEAR_BANKS_UPDATE_OFFER_VIGENCY: state => ({
      ...state,
      updateOfferVigency: {
        showLoading: null,
        success: null,
      },
    }),
  },
  initialState
);

export default banksReducer;
