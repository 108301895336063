import { createAction } from 'redux-actions';

import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const CLEAR_PRICE_QUOTE_OFFERS = 'CLEAR_PRICE_QUOTE_OFFERS';
const CREATE_PRICE_QUOTE = createAsyncFlowTypes('CREATE_PRICE_QUOTE');
const SAVE_PRICE_QUOTE_DATA = 'SAVE_PRICE_QUOTE_DATA';

const clearPriceQuoteOffers = createAction(CLEAR_PRICE_QUOTE_OFFERS);
const createPriceQuote = createAsyncFlowCreators(CREATE_PRICE_QUOTE);
const savePriceQuoteData = createAction(SAVE_PRICE_QUOTE_DATA);

export default {
  types: {
    CREATE_PRICE_QUOTE,
    CLEAR_PRICE_QUOTE_OFFERS,
    SAVE_PRICE_QUOTE_DATA,
  },
  creators: {
    createPriceQuote,
    clearPriceQuoteOffers,
    savePriceQuoteData,
  },
};
