import isNil from 'ramda/src/not';
import prop from 'ramda/src/prop';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import banksActions from '../../actions/banks';
import colors from '../../constants/colors';
import grammar from '../../constants/grammar';
import Border from '../Border';
import Card from '../Card';
import LoadingIndicator from '../LoadingIndicator';

const { REACT_APP_PUBLIC_ASSETS_URL } = process.env;

const BankLogo = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

const BankLogoContainer = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 #eaedf3;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 16px 0;
`;

const DataField = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 1.57;
  padding: 0 24px;
  min-height: 60px;
  word-break: break-word;
`;

const FieldName = styled.div`
  font-weight: bold;
  width: 40%;
`;

const FieldValue = styled.div`
  padding: 12px 0;
  padding-left: 8px;
  width: 60%;
`;

const Grid = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: 80px auto 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    'logo . .'
    'details invoices products';
`;

const GridArea = styled.div<{ name: string }>`
  grid-area: ${prop('name')};
  height: 100%;
  width: 100%;
`;

const HeadersContainer = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 0 24px;
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ProductCount = styled(FieldName)`
  font-size: 12px;
  font-weight: normal;
  width: 40%;
`;

const ProductName = styled(ProductCount)`
  width: 60%;
`;

const TableHeader = styled.div`
  align-items: center;
  color: ${colors.DUSK50};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 36px;
  line-height: 1.5;
  text-transform: uppercase;
`;

interface IBankProductType {
  productType: string;
  count: number;
}

interface IBankProfileView {
  bank?: IBank & { productTypes: IBankProductType[] };
  bankId: number;
  fetchBankInfo: Function;
}

const BankProductsList: React.FunctionComponent<{
  productTypes: IBankProductType[];
}> = ({ productTypes }) => {
  const productsList = productTypes.map((productType, i) => (
    <Fragment key={i}>
      <Border />
      <DataField>
        <ProductName>{grammar[productType.productType]}</ProductName>
        <ProductCount>{productType.count}</ProductCount>
      </DataField>
    </Fragment>
  ));

  return (
    <Card header={'Productos a otorgar'}>
      <HeadersContainer>
        <TableHeader>Producto</TableHeader>
        <TableHeader>Cantidad</TableHeader>
      </HeadersContainer>
      {productsList}
    </Card>
  );
};

const BankProfileView: React.FunctionComponent<IBankProfileView> = ({
  bank,
  bankId,
  fetchBankInfo,
}) => {
  useEffect(() => {
    if (isNil(bank)) {
      fetchBankInfo({ bankId });
    }
  }, [bank, bankId, fetchBankInfo]);

  if (!bank) {
    return (
      <LoadingIndicatorContainer>
        <LoadingIndicator />
      </LoadingIndicatorContainer>
    );
  }

  const address = `${bank.street} ${bank.streetNumber}, ${bank.neighborhood} CP ${bank.postalCode} ${bank.municipality}, ${bank.state}, ${bank.country}`;

  const logoURL = `${REACT_APP_PUBLIC_ASSETS_URL}${bank.logo}`;

  return (
    <Grid>
      <GridArea name={'logo'}>
        <BankLogoContainer>
          <BankLogo src={logoURL} />
        </BankLogoContainer>
      </GridArea>
      <GridArea name={'details'}>
        <Card header={'Detalles de la Institución'}>
          <DataField>
            <FieldName>Nombre comercial</FieldName>
            <FieldValue>{bank.name}</FieldValue>
          </DataField>
          <Border />
          <DataField>
            <FieldName>Email</FieldName>
            <FieldValue>{bank.email}</FieldValue>
          </DataField>
          <Border />
          <DataField>
            <FieldName>Sitio web</FieldName>
            <FieldValue>{bank.webPage}</FieldValue>
          </DataField>
          <Border />
          <DataField>
            <FieldName>Teléfono</FieldName>
            <FieldValue>{bank.phoneNumber}</FieldValue>
          </DataField>
        </Card>
      </GridArea>
      <GridArea name={'invoices'}>
        <Card header={'Datos para Facturación'}>
          <DataField>
            <FieldName>Razón social</FieldName>
            <FieldValue>{bank.legalName}</FieldValue>
          </DataField>
          <Border />
          <DataField>
            <FieldName>RFC</FieldName>
            <FieldValue>{bank.taxpayerId}</FieldValue>
          </DataField>
          <Border />
          <DataField>
            <FieldName>Dirección</FieldName>
            <FieldValue>{address}</FieldValue>
          </DataField>
          <Border />
          <DataField>
            <FieldName>Email</FieldName>
            <FieldValue>{bank.email}</FieldValue>
          </DataField>
          <Border />
        </Card>
      </GridArea>
      <GridArea name="products">
        <BankProductsList productTypes={bank.productTypes} />
      </GridArea>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  const { userData }: { userData: IBankUserData } = state.auth;
  const { bankId } = userData.organization;

  return {
    bank: state.entities.banks[bankId],
    bankId,
  };
};

const creators = {
  fetchBankInfo: banksActions.creators.fetchBankInfo.request,
};

export default connect(mapStateToProps, creators)(BankProfileView);
