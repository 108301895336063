import { Formik, FormikProps } from 'formik';
import isNil from 'ramda/src/isNil';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import forgotPasswordActions from '../../actions/forgotPassword';
import colors from '../../constants/colors';
import Success from '../../resources/success.png';
import { isEmail } from '../../utils/misc';
import Border from '../Border';
import Button from '../Button';
import { FormikRadio, FormikText } from '../FormikInputs';
import LoadingIndicator from '../LoadingIndicator';
import errorMsg from './errorMessages';

const ButtonContainer = styled.div`
  margin: 0px 4px;
`;

const DescriptionText = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  text-align: justify;
  padding-bottom: 24px;
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-bottom: 16px;
`;

const SuccessIcon = styled.img`
  height: 24px;
  margin-right: 8px;
`;

const ResponseMessage = styled.div`
  display: flex;
  justify-content: center;
`;

const TopPadding = styled.div`
  padding-top: 20vh;
`;

const FormContainer = styled.div`
  background-color: ${colors.WHITE};
  border: 1px solid ${colors.BORDER};
  box-shadow: 0 1px 0 0 ${colors.BORDER};
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 20px 0px;
  width: 380px;
`;

const ControlsContainer = styled.div`
  padding: 20px 40px 0px 0px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const MainFormContainer = styled.div`
  align-items: center;
  padding: 20px 40px;
`;

const Title = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 18px;
  font-weight: bold;
  padding: 0px 40px;
  margin-bottom: 20px;
`;

interface IHiddenData {
  hiddenEmail: string;
  hiddenPhoneNumber: string;
}

interface IEmailForm {
  returnToLogin: () => void;
  formikProps: FormikProps<{
    email: string;
  }>;
  showLoading: boolean;
}

interface IContactForm {
  returnToLogin: () => void;
  formikProps: FormikProps<{ contactOption: string }>;
  showLoading: boolean;
  hiddenData: IHiddenData;
  recoverySent: boolean | null;
}

interface IEmailFormCard {
  email: string;
  returnToLogin: () => void;
  checkAccountExistsWithEmail: ({ email }: { email: string }) => void;
  checkingAccountWithEmail: boolean;
  setEmail: (email: string) => void;
}

interface IContactFormCard {
  email: string;
  returnToLogin: () => void;
  hiddenData: IHiddenData;
  sendPasswordRecovery: ({
    contactOption,
    email,
  }: {
    contactOption: string;
    email: string;
  }) => void;
  sendingPasswordRecovery: boolean;
  recoverySent: boolean | null;
}

interface IForgotPasswordModal {
  initialEmail: string;
  returnToLogin: () => void;
  hiddenData: IHiddenData | null;
  checkAccountExistsWithEmail: ({ email }: { email: string }) => void;
  checkingAccountWithEmail: boolean;
  sendPasswordRecovery: ({
    contactOption,
    email,
  }: {
    contactOption: string;
    email: string;
  }) => void;
  sendingPasswordRecovery: boolean;
  recoverySent: boolean | null;
  resetPasswordRecovery: () => void;
}

interface ICustomCard {
  controls: JSX.Element;
  content: JSX.Element;
}

const CustomCard: React.FunctionComponent<ICustomCard> = ({
  controls,
  content,
}) => (
  <TopPadding>
    <FormContainer>
      <Title>Restablece tu contraseña</Title>
      <Border />
      <MainFormContainer>{content}</MainFormContainer>
      <Border />
      <ControlsContainer>{controls}</ControlsContainer>
    </FormContainer>
  </TopPadding>
);

const EmailForm: React.FunctionComponent<IEmailForm> = ({
  returnToLogin,
  formikProps,
  showLoading,
}) => {
  const renderContent = () =>
    showLoading ? (
      <LoadingIndicatorContainer>
        <LoadingIndicator />
      </LoadingIndicatorContainer>
    ) : (
      <Fragment>
        <DescriptionText>
          Ingresa el correo electrónico de tu cuenta.
        </DescriptionText>
        <FormikText label="CORREO ELECTRÓNICO" name="email" />
      </Fragment>
    );

  const renderControls = () => (
    <Fragment>
      <ButtonContainer>
        <Button variant="secondary" onClick={returnToLogin}>
          Cancelar
        </Button>
      </ButtonContainer>
      <ButtonContainer>
        <Button
          variant={formikProps.isValid && !showLoading ? 'primary' : 'inactive'}
          onClick={() => formikProps.submitForm()}
        >
          Siguiente
        </Button>
      </ButtonContainer>
    </Fragment>
  );

  return <CustomCard content={renderContent()} controls={renderControls()} />;
};

const EmailFormCard: React.FunctionComponent<IEmailFormCard> = ({
  email,
  returnToLogin,
  checkAccountExistsWithEmail,
  checkingAccountWithEmail,
  setEmail,
}) => {
  return (
    <Formik
      isInitialValid={isEmail(email)}
      initialValues={{ email }}
      onSubmit={values => {
        setEmail(values.email);
        checkAccountExistsWithEmail(values);
      }}
      render={formikProps => (
        <EmailForm
          returnToLogin={returnToLogin}
          formikProps={formikProps}
          showLoading={checkingAccountWithEmail}
        />
      )}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(errorMsg.email.email)
          .required(errorMsg.email.required),
      })}
    />
  );
};

const ContactForm: React.FunctionComponent<IContactForm> = ({
  returnToLogin,
  formikProps,
  showLoading,
  hiddenData,
  recoverySent,
}) => {
  const renderContent = () => {
    if (showLoading) {
      return (
        <LoadingIndicatorContainer>
          <LoadingIndicator />
        </LoadingIndicatorContainer>
      );
    } else if (!isNil(recoverySent)) {
      return recoverySent ? (
        <ResponseMessage>
          <SuccessIcon src={Success} />
          Enlace enviado correctamente.
        </ResponseMessage>
      ) : (
        <ResponseMessage>El enlace no pudo ser enviado.</ResponseMessage>
      );
    } else {
      return (
        <Fragment>
          <DescriptionText>
            ¿Qué medio deseas utilizar para restablecer tu contraseña?
          </DescriptionText>
          <FormikRadio
            label="MÉTODO DE CONTACTO"
            name="contactOption"
            options={[
              {
                text: `Utilizar mi celular: ${hiddenData.hiddenPhoneNumber}`,
                value: 'phoneNumber',
              },
              {
                text: `Utilizar mi correo: ${hiddenData.hiddenEmail}`,
                value: 'email',
              },
            ]}
            alignment="vertical"
          />
        </Fragment>
      );
    }
  };

  const renderControls = () => (
    <Fragment>
      <ButtonContainer>
        <Button variant="secondary" onClick={returnToLogin}>
          {isNil(recoverySent) ? 'Cancelar' : 'Cerrar'}
        </Button>
      </ButtonContainer>
      {isNil(recoverySent) ? (
        <ButtonContainer>
          <Button
            variant={!showLoading ? 'primary' : 'inactive'}
            onClick={() => formikProps.submitForm()}
          >
            Siguiente
          </Button>
        </ButtonContainer>
      ) : null}
    </Fragment>
  );

  return <CustomCard content={renderContent()} controls={renderControls()} />;
};

const ContactFormCard: React.FunctionComponent<IContactFormCard> = ({
  returnToLogin,
  email,
  hiddenData,
  sendPasswordRecovery,
  sendingPasswordRecovery,
  recoverySent,
}) => {
  return (
    <Formik
      initialValues={{ contactOption: 'phoneNumber' }}
      onSubmit={values =>
        sendPasswordRecovery({
          contactOption: values.contactOption,
          email,
        })
      }
      render={formikProps => (
        <ContactForm
          returnToLogin={returnToLogin}
          formikProps={formikProps}
          showLoading={sendingPasswordRecovery}
          hiddenData={hiddenData}
          recoverySent={recoverySent}
        />
      )}
      validationSchema={null}
    />
  );
};

const ForgotPasswordLogin: React.FunctionComponent<IForgotPasswordModal> = ({
  checkAccountExistsWithEmail,
  checkingAccountWithEmail,
  hiddenData,
  sendPasswordRecovery,
  sendingPasswordRecovery,
  recoverySent,
  resetPasswordRecovery,
  initialEmail,
  returnToLogin,
}) => {
  const [email, setEmail] = useState<string>('');

  const returnToLoginAndClean = () => {
    resetPasswordRecovery();
    returnToLogin();
  };

  return isNil(hiddenData) ? (
    <EmailFormCard
      checkAccountExistsWithEmail={checkAccountExistsWithEmail}
      checkingAccountWithEmail={checkingAccountWithEmail}
      email={initialEmail}
      setEmail={setEmail}
      returnToLogin={returnToLoginAndClean}
    />
  ) : (
    <ContactFormCard
      email={email}
      hiddenData={hiddenData}
      sendPasswordRecovery={sendPasswordRecovery}
      sendingPasswordRecovery={sendingPasswordRecovery}
      recoverySent={recoverySent}
      returnToLogin={returnToLoginAndClean}
    />
  );
};

const mapStateToProps = (state: any) => ({
  hiddenData: state.forgotPassword.hiddenData,
  recoverySent: state.forgotPassword.recoverySent,
  checkingAccountWithEmail: state.loaders.checkingAccountWithEmail,
  sendingPasswordRecovery: state.loaders.sendingPasswordRecovery,
});

const creators = {
  checkAccountExistsWithEmail:
    forgotPasswordActions.creators.checkAccountExistsWithEmail.request,
  sendPasswordRecovery:
    forgotPasswordActions.creators.sendPasswordRecovery.request,
  resetPasswordRecovery: forgotPasswordActions.creators.resetPasswordRecovery,
};

export default connect(mapStateToProps, creators)(ForgotPasswordLogin);
