import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../constants/colors';
import ExpandIndicator from '../ExpandIndicator';
import ExternalLink from '../ExternalLink';

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const Container = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: 60px;
  padding-right: 24px;
`;

const IconContainer = styled.div`
  box-sizing: border-box;
  padding-left: 32px;
`;

const ItemActiveIndicator = styled.div`
  background-color: ${({ isActive }) => (isActive ? colors.AQUA : 'inherit')};
  height: 100%;
  width: 4px;
`;

const OptionContainer = styled.div`
  align-items: center;
  display: flex;
  height: 32px;
  margin-bottom: 6px;
`;

const MainContainer = styled.div`
  background-color: ${({ active }) =>
    active ? colors.BACKGROUND : colors.WHITE};

  ${StyledLink}:last-child > ${OptionContainer} {
    margin-bottom: 0;
    padding-bottom: 12px;
  }
`;

const OptionTextContainer = styled.div`
  box-sizing: border-box;
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  padding-left: 64px;
  padding-right: 24px;
`;

const Slider = styled.div`
  overflow: hidden;
  height: ${({ active, expandedContentHeight }) =>
    active ? expandedContentHeight : 0}px;
  transition: height ease 0.5s;
`;

const TextContainer = styled.div`
  box-sizing: border-box;
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  padding-left: 12px;
  padding-right: 24px;
`;

const Option = ({ external, path, text }) => {
  const LinkComponent = external ? ExternalLink : StyledLink;

  const children = ({ match }) => (
    <LinkComponent to={path}>
      <OptionContainer>
        <ItemActiveIndicator isActive={match} />
        <OptionTextContainer>{text}</OptionTextContainer>
      </OptionContainer>
    </LinkComponent>
  );

  return <Route children={children} exact={true} path={path} />;
};

class MultiNavOptionControl extends Component {
  state = {
    isOpen: false,
  };

  toggleIsOpen = () => {
    this.setState(oldState => ({
      isOpen: !oldState.isOpen,
    }));
  };

  render() {
    const { config, location } = this.props;
    const { icon: Icon, options, text } = config;

    const childMatches = options.some(
      option => option.path === location.pathname
    );
    const isOpen = this.state.isOpen || childMatches;

    const expandedContentHeight = 40 * options.length;

    const optionComponents = options.map((option, i) => (
      <Option
        external={option.external}
        key={i}
        path={option.path}
        text={option.text}
      />
    ));

    return (
      <MainContainer active={childMatches}>
        <Container onClick={this.toggleIsOpen}>
          <IconContainer>
            <Icon size={18} />
          </IconContainer>
          <TextContainer>{text}</TextContainer>
          <ExpandIndicator expanded={isOpen} />
        </Container>
        <Slider active={isOpen} expandedContentHeight={expandedContentHeight}>
          {optionComponents}
        </Slider>
      </MainContainer>
    );
  }
}

const MultiNavOption = ({ config }) => {
  const children = ({ location }) => (
    <MultiNavOptionControl config={config} location={location} />
  );

  return <Route children={children} path="/" />;
};

export default MultiNavOption;
