import { call, put, takeLatest } from 'redux-saga/effects';

import profileSettings from '../actions/profileSettings';
import toastActions from '../actions/toastNotifications';
import { buildErrorToast } from '../utils/toastNotifications';
import runner from './runners';

function* changeNotifications(action) {
  const { notifications } = action.payload;

  const { error } = yield call(
    runner,
    profileSettings.creators.changeNotifications,
    {
      method: 'PUT',
      url: `/api/account/notifications`,
      payload: {
        notifications,
      },
    }
  );

  if (error) {
    const toast = buildErrorToast(
      'No pudo realizarse el cambio de ajustes. Contacta a soporte si el problema persiste.',
      3000
    );
    yield put(toastActions.creators.showToast(toast));
  }
}

function* watchChangeNotifications() {
  yield takeLatest(
    profileSettings.types.CHANGE_NOTIFICATIONS.REQUESTED,
    changeNotifications
  );
}

function* fetchNotifications(action) {
  const { notifications } = action.payload;

  yield call(runner, profileSettings.creators.fetchNotifications, {
    method: 'GET',
    url: `/api/account/notifications`,
    payload: {
      notifications,
    },
  });
}

function* watchFetchNotifications() {
  yield takeLatest(
    profileSettings.types.FETCH_NOTIFICATIONS.REQUESTED,
    fetchNotifications
  );
}

function* changeFollowUpEmails(action) {
  const { followUpEmails } = action.payload;
  const { error } = yield call(
    runner,
    profileSettings.creators.changeFollowUpEmails,
    {
      method: 'PUT',
      url: '/api/account/follow-up-emails',
      payload: {
        followUpEmails,
      },
    },
    { snakeCase: false }
  );
  if (error) {
    const toast = buildErrorToast(
      'No pudo realizarse el cambio de ajustes. Contacta a soporte si el problema persiste.',
      3000
    );
    yield put(toastActions.creators.showToast(toast));
  }
}

function* watchChangeFollowUpEmails() {
  yield takeLatest(
    profileSettings.types.CHANGE_FOLLOW_UP_EMAILS.REQUESTED,
    changeFollowUpEmails
  );
}

function* fetchFollowUpEmails(action) {
  const { followUpEmails } = action.payload;
  yield call(runner, profileSettings.creators.fetchFollowUpEmails, {
    method: 'GET',
    url: '/api/account/follow-up-emails',
    payload: {
      followUpEmails,
    },
  });
}

function* watchFetchFollowUpEmails() {
  yield takeLatest(
    profileSettings.types.FETCH_FOLLOW_UP_EMAILS.REQUESTED,
    fetchFollowUpEmails
  );
}

export default {
  watchChangeNotifications,
  watchFetchNotifications,
  watchChangeFollowUpEmails,
  watchFetchFollowUpEmails,
};
