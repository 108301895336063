import React, { Fragment } from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';

const Border = styled.div`
  background-color: ${colors.BORDER};
  height: 1px;
  width: 100%;
`;

const TableHeaderContainer = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 36px;
  padding-left: 24px;
  padding-right: 32px;
  width: 100%;
`;

const Header = styled.div`
  color: ${colors.DUSK50};
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  width: ${({ width }) => width};
`;

const TableHeader = ({ headers }) => (
  <Fragment>
    <TableHeaderContainer>
      {headers.map((header, i) => (
        <Header key={i} width={header.width}>
          {header.text}
        </Header>
      ))}
    </TableHeaderContainer>
    <Border />
  </Fragment>
);

export default TableHeader;
