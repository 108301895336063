import { handleActions } from 'redux-actions';

const initialState = {
  products: null,
  productDetails: null,
  productUpdatedSuccess: null,
};

const automaticProductsReducer = handleActions(
  {
    FETCH_BANK_AUTOMATIC_PRODUCTS_COMPLETED: (state, { payload }) => {
      return { ...initialState, products: payload.products };
    },
    FETCH_BANK_PRODUCT_DETAILS_COMPLETED: (state, { payload }) => {
      return { ...state, productDetails: payload.productDetails };
    },
    UPDATE_PRODUCT_STATUS_STARTED: state => {
      return { ...state, productUpdatedSuccess: null };
    },
    UPDATE_PRODUCT_STATUS_COMPLETED: state => {
      return { ...state, productUpdatedSuccess: true };
    },
    UPDATE_PRODUCT_STATUS_FAILED: state => {
      return { ...state, productUpdatedSuccess: false };
    },
  },
  initialState
);

export default automaticProductsReducer;
