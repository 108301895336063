const grammar = {
  aceptada: 'Aceptada',
  automotriz: 'Crédito automotriz',
  motocicleta: 'Crédito motocicleta',
  new: 'Nuevo',
  nuevo: 'Nuevo',
  seminuevo: 'Seminuevo',
  used: 'Seminuevo',
  activated: 'Aceptada',
  activo: 'Activo',
  inactivo: 'Inactivo',
  analysis: 'Revisión',
  approval: 'Aprobación',
  'pre-formalization': 'Formalización',
  formalization: 'Digitalización',
  release: 'Liberación',
  validation: 'Validación',
  rejected: 'Rechazado',
  required: 'Requerido',
  approved: 'Aceptado',
  fixed: 'Fija',
  variable: 'Variable',
  weekly: 'semanas',
  fortnightly: 'quincenas',
  monthly: 'meses',
  yearly: 'años',
  damage_insurance: 'Seguro de daños',
  gps: 'GPS',
  life_insurance: 'Seguro de vida',
  granted: 'Otorgado',
  car_finance: 'Crédito automotriz',
  credit_card: 'Tarjetas de crédito',
  mortgage: 'Hipotecario',
  motorcycle_finance: 'Crédito motocicleta',
  third: 'Terceros',
  address: 'Domicilio',
  employment: 'Empleo',
  references: 'Referencias',
  send: 'Envío',
  vehicleCondition: 'Tipo de producto',
  brand: 'Marca',
  version: 'Versión',
  model: 'Modelo',
  price: 'Valor',
  downpayment: 'Enganche',
  term: 'Plazo',
  creditNumberPayments: 'Plazo',
  insuranceType: 'Tipo de seguro',
  creditInsuranceType: 'Tipo de seguro',
  name: 'Nombre',
  firstSurname: 'Primer apellido',
  secondSurname: 'Segundo apellido',
  dateOfBirth: 'Fecha de nacimiento',
  sex: 'Sexo',
  countryOfBirth: 'País de nacimiento',
  nationality: 'Nacionalidad',
  stateOfBirth: 'Estado de nacimiento',
  maritalStatus: 'Estado civil',
  matrimonialRegime: 'Régimen matrimonial',
  curp: 'CURP',
  rfc: 'RFC',
  taxpayerId: 'RFC',
  educationLevel: 'Grado de estudios',
  dependentsNumber: 'Dependientes económicos',
  street: 'Calle',
  exteriorNumber: 'Número exterior',
  suiteNumber: 'Número interior',
  postalCode: 'Código postal',
  neighborhood: 'Colonia',
  municipality: 'Municipio',
  city: 'Ciudad',
  state: 'Estado',
  addressType: 'Tipo de domicilio',
  rent: 'Importe de la renta',
  antiquity: 'Antiguedad en el domicilio',
  phoneNumber: 'Teléfono',
  relation: 'Relación',
  employmentType: 'Situación laboral',
  contractType: 'Tipo de contrato',
  companyName: 'Nombre de la empresa',
  industryType: 'Tipo de industria',
  activityType: 'Giro',
  companySector: 'Sector',
  position: 'Cargo',
  fixedIncome: 'Ingreso fijo',
  dateEntryCompany: 'Fecha de ingreso',
  sourceOthers: 'Fuente de otros ingresos',
  otherIncome: 'Otros ingresos',
  dateTerminationCompany: 'Fecha de terminación',
  email: 'Correo electrónico',
  age: 'Edad',
  yearsOld: 'Edad',
  yearsInEmployment: 'Años en empleo',
  monthsInEmployment: 'Meses en empleo',
  yearsInAddress: 'Años en domicilio',
  monthsInAddress: 'Meses en domicilio',
  noOffers: 'Sin ofertas',
  offered: 'Ofertada',
  accepted: 'Aceptada',
  si: 'Sí',
  no: 'No',
  offerPending: 'Pendiente',
  offerRejected: 'Rechazada',
  offerExpired: 'Expirada',
  constant: 'Constante',
  elementary: 'Primaria',
  middle: 'Secundaria',
  independent: 'Independiente',
  employed: 'Empleado',
  retired: 'Retirado',
  housewife: 'Ama de casa',
  noApplication: 'Sin solicitud',
  offersPending: 'Ofertas sin aceptar',
  offersPendingExpired: 'Ofertas sin aceptar ya expiradas',
  finalOfferExpired: 'Oferta aprobada ya expirada',
} as { [key: string]: string };

export const invoices = {
  pending: 'facturar',
  validation: 'validación',
  paid: 'pagado',
};

export default grammar;
