import React from 'react';
import { Link, Route } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../constants/colors';
import ExternalLink from '../ExternalLink';

const FlexItemContainer = styled.div`
  margin: auto;
`;

const IconContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 76px;
  align-items: center;
  justify-content: center;
`;

const TextContainer = styled.label<{ match: boolean }>`
  color: ${props => (props.match ? colors.AQUA : colors.DUSK)};
  height: 12px;
  /* font-family: SFProText; */
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.24px;
  text-align: center;
  padding-top: 4px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const NavOptionMobile = ({ config }: { config: any }) => {
  const { external, icon: Icon, path, text } = config;

  const LinkComponent = external ? ExternalLink : StyledLink;

  const children = ({ match }: { match: boolean }) => (
    <FlexItemContainer>
      <LinkComponent to={path}>
        <IconContainer>
          <Icon size={18} fill={match ? colors.AQUA : colors.DUSK} />
          <TextContainer match={match}>{text}</TextContainer>
        </IconContainer>
      </LinkComponent>
    </FlexItemContainer>
  );

  return <Route children={children} path={path} />;
};

export default NavOptionMobile;
