import { handleActions } from 'redux-actions';

const initialState = {
  notifications: null,
  followUpEmails: null,
};

const productsReducer = handleActions(
  {
    CHANGE_NOTIFICATIONS_COMPLETED: (state, { payload }) => {
      return { ...state, notifications: payload.notifications };
    },
    FETCH_NOTIFICATIONS_COMPLETED: (state, { payload }) => {
      return { ...state, notifications: payload.notifications };
    },
    CHANGE_FOLLOW_UP_EMAILS_COMPLETED: (state, { payload }) => {
      return { ...state, followUpEmails: payload.followUpEmails };
    },
    FETCH_FOLLOW_UP_EMAILS_COMPLETED: (state, { payload }) => {
      return { ...state, followUpEmails: payload.followUpEmails };
    },
  },
  initialState
);

export default productsReducer;
