import { createAction } from 'redux-actions';

const REDIRECT_TO_APPLICATION_START = 'REDIRECT_TO_APPLICATION_START';
const REDIRECT_TO_PROFILING_START = 'REDIRECT_TO_PROFILING_START';
const REDIRECT_TO_ACCOUNT_CREATION_START = 'REDIRECT_TO_ACCOUNT_CREATION_START';
const REDIRECT_TO_APPLICATION_SECTIONS_START =
  'REDIRECT_TO_APPLICATION_SECTIONS_START';

const CLEAR_NEW_APPLICATION = 'CLEAR_NEW_APPLICATION';

const redirectToApplicationStart = createAction(REDIRECT_TO_APPLICATION_START);
const redirectToProfilingStart = createAction(REDIRECT_TO_PROFILING_START);
const redirectToAccountCreationStart = createAction(
  REDIRECT_TO_ACCOUNT_CREATION_START
);
const redirectToApplicationSectionsStart = createAction(
  REDIRECT_TO_APPLICATION_SECTIONS_START
);

const clearNewApplication = createAction(CLEAR_NEW_APPLICATION);

export default {
  types: {
    REDIRECT_TO_APPLICATION_START,
    REDIRECT_TO_PROFILING_START,
    REDIRECT_TO_ACCOUNT_CREATION_START,
    REDIRECT_TO_APPLICATION_SECTIONS_START,
    CLEAR_NEW_APPLICATION,
  },
  creators: {
    redirectToApplicationStart,
    redirectToProfilingStart,
    redirectToAccountCreationStart,
    redirectToApplicationSectionsStart,
    clearNewApplication,
  },
};
