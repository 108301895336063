import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

import auth from '../../actions/auth';
import deviceDataActions from '../../actions/deviceData';
import colors from '../../constants/colors';
import history from '../../history';
import Home from '../Home';
import LoadingIndicator from '../LoadingIndicator';
import Login from '../Login';
import NoUserWrapper from '../NoUserWrapper';
import PasswordRecoveryView from '../PasswordRecoveryView';
import ToastNotificationsProvider from '../ToastNotificationsProvider';

const AppGlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    height: 100%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    background-color: ${colors.BACKGROUND};
    color: ${colors.PRIMARY_TEXT};
    font-family: 'Lato', sans-serif;
    min-height: 100vh;
    margin: 0;
    min-height: -webkit-fill-available;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
      outline: none;
  }
`;

const AppContainer = styled.div`
  background-color: ${colors.BACKGROUND};
`;

const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
`;

const LoadingComponent = (): JSX.Element => (
  <LoadingContainer>
    <LoadingIndicator />
  </LoadingContainer>
);

interface IAppProps {
  checkingSession: boolean;
  startAuthFlow: () => void;
  userAuthenticated: boolean;
  setDeviceData: Function;
}

const obtainDeviceData = (setDeviceData: Function) => {
  const isiOS = !!navigator.userAgent.match(/iPad|iPhone|iPod/);
  const { innerWidth, innerHeight } = window;
  const { documentElement } = document;
  const { clientWidth, clientHeight } = documentElement;
  const widthToCompare = isiOS ? clientWidth : innerWidth;
  const heightToCompare = isiOS ? clientHeight : innerHeight;
  const isMobile = isiOS || navigator.userAgent.match(/Android/);

  setDeviceData({
    isMobile,
    isiOS,
    clientWidth,
    clientHeight,
    innerWidth,
    innerHeight,
    widthToCompare,
    heightToCompare,
  });
};

class App extends Component<IAppProps> {
  componentDidMount() {
    const { startAuthFlow, setDeviceData } = this.props;
    obtainDeviceData(setDeviceData);
    if (process.env.NODE_ENV === 'development') {
      window.addEventListener('resize', () => obtainDeviceData(setDeviceData));
    }

    startAuthFlow();
  }

  render() {
    const { checkingSession, userAuthenticated } = this.props;

    const mainComponent = (() => {
      if (checkingSession) {
        return LoadingComponent;
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('promoterUserCode')) {
          return NoUserWrapper;
        } else if (userAuthenticated) {
          return Home;
        } else {
          return Login;
        }
      }
    })();

    return (
      <Fragment>
        <AppGlobalStyle />
        <AppContainer>
          <ToastNotificationsProvider />
          <Router history={history}>
            <Switch>
              <Route
                path="/password-recovery/:token"
                component={PasswordRecoveryView}
              />
              <Route path="/" component={mainComponent} />
              <Redirect from="*" to="/" />
            </Switch>
          </Router>
        </AppContainer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  checkingSession: state.loaders.checkingSession,
  userAuthenticated: state.auth.userAuthenticated,
});

const creators = {
  startAuthFlow: auth.creators.startAuthFlow,
  setDeviceData: deviceDataActions.creators.setDeviceData,
};

export default connect(mapStateToProps, creators)(App);
