import { createAction } from 'redux-actions';

const PUSH_TOAST = 'PUSH_TOAST';
const REMOVE_TOAST = 'REMOVE_TOAST';
const SHOW_TOAST = 'SHOW_TOAST';

const pushToast = createAction(PUSH_TOAST);
const removeToast = createAction(REMOVE_TOAST);
const showToast = createAction(SHOW_TOAST);

export default {
  types: {
    PUSH_TOAST,
    REMOVE_TOAST,
    SHOW_TOAST,
  },
  creators: {
    pushToast,
    removeToast,
    showToast,
  },
};
