import styled from 'styled-components';

import colors from '../../constants/colors';

const Border = styled.div`
  background-color: ${colors.BORDER};
  height: 1px;
  width: 100%;
`;

export default Border;
