import { handleActions } from 'redux-actions';

const initialState = null;

// TODO(team): refactor this reducer
const requestRiskReducer = handleActions(
  {
    REQUEST_RISK_PROFILE_FETCH_COMPLETED: (state, action) =>
      action.payload.data,
  },
  initialState
);

export default requestRiskReducer;
