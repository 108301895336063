import { handleActions } from 'redux-actions';

const initialState = {};

const offerMessagesReducer = handleActions(
  {
    OFFER_MESSAGES_FETCH_COMPLETED: (state, { payload }) => {
      const { response, meta } = payload;

      return {
        ...state,
        [meta.originalAction.payload.offerId]: response.data,
      };
    },
    OFFER_MESSAGES_CREATE_OPTIMISTIC: (state, { payload }) => {
      const { message } = payload;

      const offerMessages = [...state[message.offerId]];
      offerMessages.push(message);

      return { ...state, [message.offerId]: offerMessages };
    },
  },
  initialState
);

export default offerMessagesReducer;
