import { handleActions } from 'redux-actions';

import utils from './utils';

const initialState = [];

const offerDocumentsReducer = handleActions(
  {
    OFFERS_FETCH_REQUIRED_DOCUMENTS_COMPLETED: (state, action) =>
      utils.buildDictWithKey(action.payload.data, 'id'),
    DOCUMENT_GET_COMPLETED: (state, action) => {
      const { data } = action.payload;

      return { ...state, [data.id]: data };
    },
  },
  initialState
);

export default offerDocumentsReducer;
