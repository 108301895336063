import { Form, FormikProps } from 'formik';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
  INSURANCE_OPTIONS,
  PRODUCT_OPTIONS,
  TERM_OPTIONS,
  VEHICLE_TYPE_OPTIONS,
} from '../../constants/applicationOptions';
import Button from '../Button';
import Card from '../Card';
import { Option } from '../Dropdown/Dropdown';
import {
  FormikCurrency,
  FormikDropdown,
  FormikPercentageCurrency,
  FormikRadio,
} from '../FormikInputs';
import errors from '../ProfilingSectionsForms/errorMessages';

const FormContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-left: 24px;
  padding-right: 30px;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
`;

const FormElement = styled.div`
  padding-left: 10%;
  padding-right: 10%;
`;

interface IVehicleManufacturer {
  id: number;
  name: string;
}

interface IVehicleModel {
  id: number;
  manufacturerId: number;
  model: string;
  vehicleType: string;
  version: string;
  createdAt: string;
  updatedAt: string;
}

interface IProductForm {
  productType: string;
  vehicleModel: string;
  brand: string;
  unitType: string;
  vehicleModelYear: string;
  price: number;
  downpayment: number;
  insurance: string;
  numberOfPayments: number;
}

export const priceQuoteSchema = Yup.object().shape({
  vehicleModel: Yup.string().required(errors.product.version.required),
  brand: Yup.string().required(errors.product.brand.required),
  vehicleModelYear: Yup.number().required(errors.product.model.required),
  price: Yup.number()
    .typeError(errors.product.price.number)
    .moreThan(0, errors.product.price.moreThanZero)
    .required(errors.product.price.required),
  downpayment: Yup.number()
    .typeError(errors.product.downpayment.number)
    .moreThan(0, errors.product.downpayment.moreThanZero)
    .notOneOf([Yup.ref('price')], errors.product.downpayment.max)
    .required(errors.product.downpayment.required),
  insurance: Yup.string().required(errors.product.insurance.required),
  // we are using the same error messages than the profiling sections so numberOfPayments is the same as term
  numberOfPayments: Yup.number()
    .typeError(errors.product.term.number)
    .positive(errors.product.term.required)
    .required(errors.product.term.required),
});

interface IPriceQuotingForm {
  fetchProductManufacturers: Function;
  fetchVehicleModels: Function;
  productManufacturers: Array<IVehicleManufacturer>;
  vehicleModels: Array<IVehicleModel>;
  formikProps: FormikProps<IProductForm>;
}

const PriceQuotingForm: React.FunctionComponent<IPriceQuotingForm> = ({
  fetchProductManufacturers,
  fetchVehicleModels,
  productManufacturers,
  vehicleModels,
  formikProps,
}) => {
  useEffect(() => {
    fetchProductManufacturers({ type: formikProps.values.productType });
  }, [fetchProductManufacturers, formikProps.values.productType]);

  useEffect(() => {
    const vehicleType =
      formikProps.values.productType === 'car_finance' ? 'car' : 'motorcycle';
    fetchVehicleModels({
      manufacturerId: formikProps.values.brand,
      vehicleType,
    });
  }, [
    fetchVehicleModels,
    formikProps.values.productType,
    formikProps.values.brand,
  ]);

  const priceQuotingButon = (
    <Button
      variant={formikProps.isValid ? 'primary' : 'inactive'}
      onClick={() => formikProps.submitForm()}
    >
      Cotizar
    </Button>
  );

  const manufacturerOptions = productManufacturers.map(({ id, name }) => ({
    text: name,
    value: id,
  }));

  const vehicleModelOptions = vehicleModels
    .filter(
      model => `${model.manufacturerId}` === `${formikProps.values.brand}`
    )
    .map(({ id, model }) => ({
      text: model,
      value: id,
    }));

  const newModelOptions = (): Option[] => {
    const year = new Date().getFullYear();
    const newModelOptions = [
      { text: `${year - 1}`, value: `${year - 1}` },
      { text: `${year}`, value: `${year}` },
      { text: `${year + 1}`, value: `${year + 1}` },
    ];
    return newModelOptions;
  };

  const usedModelOptions = (): Option[] => {
    const today = new Date();
    const usedOptions: Option[] = [];
    for (let i = today.getFullYear() - 10; i <= today.getFullYear(); i++) {
      usedOptions.push({
        text: i.toString(),
        value: i.toString(),
      });
    }
    return usedOptions;
  };

  const getTermOptions = (): Option[] => {
    const usedOptions: Option[] = [];
    TERM_OPTIONS.forEach(option => {
      usedOptions.push({
        text: `${option} Meses`,
        value: option.toString(),
      });
    });
    return usedOptions;
  };

  return (
    <Card header="Cotizador" controls={priceQuotingButon}>
      <Form>
        <FormContainer>
          <FormElement>
            <FormikRadio
              label="TIPO DE PRODUCTO"
              name="productType"
              options={PRODUCT_OPTIONS}
            />
          </FormElement>
          <FormElement>
            <FormikDropdown
              name="brand"
              label="MARCA"
              options={manufacturerOptions}
              placeholder="Selecciona"
            />
          </FormElement>
          <FormElement>
            <FormikDropdown
              name="vehicleModel"
              label="VERSIÓN"
              options={vehicleModelOptions}
              placeholder="Selecciona"
            />
          </FormElement>
          <FormElement>
            <FormikRadio
              label="TIPO DE PRODUCTO"
              name="unitType"
              options={VEHICLE_TYPE_OPTIONS}
            />
          </FormElement>
          <FormElement>
            <FormikCurrency label="VALOR" name="price" />
          </FormElement>
          <FormElement>
            <FormikDropdown
              name="vehicleModelYear"
              label="MODELO"
              options={
                formikProps.values.unitType === 'new'
                  ? newModelOptions()
                  : usedModelOptions()
              }
              placeholder="Selecciona"
            />
          </FormElement>
          <FormElement>
            <FormikDropdown
              name="insurance"
              label="TIPO DE SEGURO"
              options={INSURANCE_OPTIONS}
              placeholder="Selecciona"
            />
          </FormElement>
          <FormElement>
            <FormikDropdown
              name="numberOfPayments"
              label="PLAZO"
              options={getTermOptions()}
              placeholder="Selecciona"
            />
          </FormElement>
          <FormElement>
            <FormikPercentageCurrency
              label="TIPO DE ENGANCHE"
              name="downpayment"
              total={formikProps.values.price}
            />
          </FormElement>
        </FormContainer>
      </Form>
    </Card>
  );
};

export default PriceQuotingForm;
