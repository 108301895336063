import { createAction } from 'redux-actions';

const STORE_SECTIONS_DATA = 'STORE_SECTIONS_DATA';

const storeSectionsData = createAction(STORE_SECTIONS_DATA);

export default {
  types: {
    STORE_SECTIONS_DATA,
  },
  creators: {
    storeSectionsData,
  },
};
