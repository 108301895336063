import keys from 'ramda/src/keys';
import length from 'ramda/src/length';
import not from 'ramda/src/not';
import { call, put } from 'redux-saga/effects';

import client from './client';
import transformers from './transformers';

const { deepToCamelCase, deepToSnakeCase } = transformers;

const getPayload = (payload, snakeCase) => {
  if (not(payload)) {
    return undefined;
  }
  if (payload.constructor === FormData) {
    return payload;
  }
  if (snakeCase) {
    return deepToSnakeCase(payload);
  }

  return payload;
};

function* runner(creators, requestConfig, options = {}) {
  const { headers = {}, method, params, payload, url } = requestConfig;

  const { originalAction, snakeCase = true } = options;
  const meta = {};

  if (originalAction) {
    meta.originalAction = originalAction;
  }

  if (length(keys(meta))) {
    yield put(creators.started({ meta }));
  } else {
    yield put(creators.started());
  }

  try {
    const { data } = yield call(client, {
      data: getPayload(payload, snakeCase),
      headers,
      method,
      params,
      url,
    });

    const json = deepToCamelCase(data);

    if (length(keys(meta))) {
      yield put(creators.completed({ meta, response: json }));
    } else {
      yield put(creators.completed(json));
    }
    return { json };
  } catch (error) {
    if (length(keys(meta))) {
      yield put(creators.failed({ meta, error }));
    } else {
      yield put(creators.failed(error));
    }
    return { error };
  }
}

export default runner;
