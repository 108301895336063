import buildInvoicesView, {
  renderInvoiceRow,
} from '../ConfigurableInvoicesView';

export default buildInvoicesView({
  cards: ['paid', 'validation', 'pending'],
  title: 'Cobros',
  type: 'receivable',
  currentInvoicesTableConfig: {
    header: 'Cobros en proceso',
    gridColumn: '15% 20% 15% 10% 10% 15% 15%',
    headers: [
      'ID FACTURA',
      'PERIODO DE FACTURACIÓN',
      'COMISIONES',
      'IVA',
      'TOTAL',
      'OTORGANTE',
      'ESTATUS',
    ],
    renderRow: renderInvoiceRow({ showRecipientName: true }),
  },
  paidInvoicesTableConfig: {
    header: 'Historial de cobros',
    gridColumn: '15% 20% 15% 10% 10% 15% 15%',
    headers: [
      'ID FACTURA',
      'PERIODO DE FACTURACIÓN',
      'COMISIONES',
      'IVA',
      'TOTAL',
      'OTORGANTE',
      'ESTATUS',
    ],
    renderRow: renderInvoiceRow({ showRecipientName: true }),
  },
});
