const currency = (value: string | number) => {
  const cleaned = `${value}`
    .replace('$', '')
    .split(',')
    .join('');

  const number = Number.parseFloat(cleaned).toFixed(2);
  let point = number.indexOf('.');

  let positionsToLeft = 0;
  const formatted: Array<string> = [];

  while (point > 0) {
    point -= 1;
    positionsToLeft += 1;
    formatted.unshift(number[point]);

    if (positionsToLeft === 3 && point !== 0) {
      formatted.unshift(',');
      positionsToLeft = 0;
    }
  }

  formatted.push('.');
  formatted.push(number[number.length - 2]);
  formatted.push(number[number.length - 1]);

  return `$${formatted.join('')}`;
};

const date = (value: string) => {
  return value.split('-').join('/');
};

const percentage = (value: string | number, decimals: number = 2) => {
  const cleaned = `${value}`.replace('%', '');

  const number = Number.parseFloat(cleaned);

  return `${number.toFixed(decimals)}%`;
};

const capitalize = (word: string) =>
  `${word.charAt(0).toUpperCase()}${word.slice(1)}`;

const capitalizeFirstLetterLowerRest = (word: string) =>
  `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`;

export default {
  currency,
  date,
  percentage,
  capitalize,
  capitalizeFirstLetterLowerRest,
};
