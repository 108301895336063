import isNil from 'ramda/src/isNil';
import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import deviceSizes from '../../constants/deviceSizes';
import f from '../../constants/formatters';
import {
  getOverdueAmountColor,
  getScoreColor,
  getScoreText,
} from '../../utils/creditHistoryAccounts';

const CreditHistoryText = styled.div<{ color: string }>`
  font-size: 14px;
  color: ${({ color }) => color};
  margin: 4px;
  text-align: center;
`;

const CreditHistoryValueContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CreditHistoryDataContainer = styled.div`
  @media (max-width: ${deviceSizes.tablet.width}) {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  @media (min-width: ${deviceSizes.tablet.width}) {
    display: flex;
  }

  margin-bottom: 12px;
  justify-content: space-around;
`;

interface ICreditHistoryDataSection {
  creditHistoryData: ICreditHistoryData | null;
}

const CreditHistoryDataSection: React.FunctionComponent<ICreditHistoryDataSection> = ({
  creditHistoryData,
}) => {
  if (isNil(creditHistoryData)) {
    return null;
  }
  const debtPercentageText =
    creditHistoryData.debtPercentage > 0
      ? f.percentage(creditHistoryData.debtPercentage * 100)
      : 'N/A';
  const approvedAmountColor = (() => {
    if (creditHistoryData.approvedAmountRelation >= 1.5) {
      return colors.GREEN;
    } else if (creditHistoryData.approvedAmountRelation >= 1) {
      return colors.ORANGE;
    } else if (creditHistoryData.approvedAmountRelation > 0) {
      return colors.RED;
    } else {
      return colors.SECONDARY_TEXT;
    }
  })();
  const debtPercentageColor = (() => {
    if (creditHistoryData.debtPercentage >= 0.3) {
      return colors.RED;
    } else if (creditHistoryData.ficoScore >= 0.15) {
      return colors.ORANGE;
    } else if (creditHistoryData.ficoScore > 0) {
      return colors.GREEN;
    } else {
      return colors.SECONDARY_TEXT;
    }
  })();
  const historyTitles = [
    'Estado del historial',
    'Monto aprobado',
    'Monto vencido',
    'Endeudamiento',
  ];
  const historyValues = [
    getScoreText(creditHistoryData.ficoScore),
    f.currency(creditHistoryData.approvedAmount),
    f.currency(creditHistoryData.overdueAmount),
    debtPercentageText,
  ];
  const historyColors = [
    getScoreColor(creditHistoryData.ficoScore),
    approvedAmountColor,
    getOverdueAmountColor(creditHistoryData.overdueAmount),
    debtPercentageColor,
  ];

  return (
    <CreditHistoryDataContainer>
      {historyTitles.map((title, i) => (
        <CreditHistoryValueContainer key={i}>
          <CreditHistoryText color={colors.PRIMARY_TEXT}>
            {title}
          </CreditHistoryText>
          <CreditHistoryText color={historyColors[i]}>
            {historyValues[i]}
          </CreditHistoryText>
        </CreditHistoryValueContainer>
      ))}
    </CreditHistoryDataContainer>
  );
};

export default CreditHistoryDataSection;
