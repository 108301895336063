import { Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import offersActions from '../../actions/offers';
import Button from '../Button';
import { FormikSimpleNumber } from '../FormikInputs';
import SimpleCard from '../SimpleCard';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  padding: 24px 40px;
`;

const SubmitButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-top: 12px;
  width: 100%;
`;

interface IManualOfferForm {
  applicationId: number;
  bankId: number;
  productId: number;
  creditAmount: number;
  numberPayments: number;
  payment: number;
  downpayment: number;
  cat: number;
}

const validationSchema = Yup.object<IManualOfferForm>({
  applicationId: Yup.number().required(),
  bankId: Yup.number().required(),
  productId: Yup.number().required(),
  creditAmount: Yup.number().required(),
  numberPayments: Yup.number().required(),
  payment: Yup.number().required(),
  downpayment: Yup.number().required(),
  cat: Yup.number().required(),
});

const formInitialValues = {
  applicationId: NaN,
  bankId: NaN,
  productId: NaN,
  creditAmount: NaN,
  numberPayments: NaN,
  payment: NaN,
  downpayment: NaN,
  cat: NaN,
};

interface ICreateManualOfferForm {
  onSubmit: (values: IManualOfferForm) => void;
}

const CreateManualOfferForm: React.FunctionComponent<ICreateManualOfferForm> = ({
  onSubmit,
}) => {
  return (
    <Formik<IManualOfferForm>
      initialValues={formInitialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <SimpleCard
          title="Crear oferta manual"
          subtitle="We shouldn't be doing this often (or at all)"
        >
          <Grid>
            <FormikSimpleNumber label="ID SOLICITUD" name="applicationId" />
            <FormikSimpleNumber label="ID OTORGANTE" name="bankId" />
            <FormikSimpleNumber label="ID PRODUCTO" name="productId" />
            <FormikSimpleNumber label="MONTO CRÉDITO" name="creditAmount" />
            <FormikSimpleNumber label="NÚMERO DE PAGOS" name="numberPayments" />
            <FormikSimpleNumber label="PAGO MENSUAL" name="payment" />
            <FormikSimpleNumber label="ENGANCHE" name="downpayment" />
            <FormikSimpleNumber label="CAT (DECIMAL)" name="cat" />
          </Grid>
        </SimpleCard>
        <SubmitButtonContainer>
          <Button type="submit">Crear</Button>
        </SubmitButtonContainer>
      </Form>
    </Formik>
  );
};

interface ICreateManualOfferViewDispatchProps {
  createManualOffer: (payload: IManualOfferForm) => void;
}

const CreateManualOfferView: React.FunctionComponent<ICreateManualOfferViewDispatchProps> = ({
  createManualOffer,
}) => {
  return <CreateManualOfferForm onSubmit={createManualOffer} />;
};

const creators = {
  createManualOffer: offersActions.creators.createManualOffer.request,
};

export default connect(null, creators)(CreateManualOfferView);
