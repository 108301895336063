import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import addYears from 'date-fns/addYears';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInYears from 'date-fns/differenceInYears';
import startOfDay from 'date-fns/startOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import startOfYear from 'date-fns/startOfYear';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import subWeeks from 'date-fns/subWeeks';
import subYears from 'date-fns/subYears';

export type DashboardDateRangeOptions =
  | 'today'
  | 'week'
  | 'month'
  | 'semester'
  | 'year'
  | 'custom';

const getTodayDateRange = () => {
  const today = new Date();

  return {
    currentPeriod: {
      startDate: startOfDay(today),
      endDate: startOfDay(addDays(today, 1)),
    },
    previousPeriod: {
      startDate: startOfDay(subDays(today, 1)),
      endDate: startOfDay(today),
    },
  };
};

const getWeekDateRange = () => {
  const today = new Date();

  return {
    currentPeriod: {
      startDate: startOfWeek(today),
      endDate: startOfWeek(addWeeks(today, 1)),
    },
    previousPeriod: {
      startDate: startOfWeek(subWeeks(today, 1)),
      endDate: startOfWeek(today),
    },
  };
};

const getMonthDateRange = () => {
  const today = new Date();

  return {
    currentPeriod: {
      startDate: startOfMonth(today),
      endDate: startOfMonth(addMonths(today, 1)),
    },
    previousPeriod: {
      startDate: startOfMonth(subMonths(today, 1)),
      endDate: startOfMonth(today),
    },
  };
};

const getSemesterDateRange = () => {
  const today = new Date();
  const monthsToAdd = today.getMonth() > 5 ? 6 : 0;
  const base = addMonths(startOfYear(today), monthsToAdd);

  return {
    currentPeriod: {
      startDate: base,
      endDate: addMonths(base, 6),
    },
    previousPeriod: {
      startDate: subMonths(base, 6),
      endDate: base,
    },
  };
};

const getYearDateRange = () => {
  const today = new Date();

  return {
    currentPeriod: {
      startDate: startOfYear(today),
      endDate: startOfYear(addYears(today, 1)),
    },
    previousPeriod: {
      startDate: startOfYear(subYears(today, 1)),
      endDate: startOfYear(today),
    },
  };
};

export const getDatesForSelectedDateRange = (
  selectedDateRange: DashboardDateRangeOptions
) => {
  switch (selectedDateRange) {
    case 'today':
      return getTodayDateRange();
    case 'week':
      return getWeekDateRange();
    case 'month':
      return getMonthDateRange();
    case 'semester':
      return getSemesterDateRange();
    case 'year':
      return getYearDateRange();
    case 'custom':
      return getMonthDateRange();
  }
};

export const getDatesForCustomDateRange = (startDate: Date, endDate: Date) => {
  const diffInDays = differenceInDays(addDays(endDate, 1), startDate);

  return {
    currentPeriod: {
      startDate: startDate,
      endDate: addDays(startDate, diffInDays),
    },
    previousPeriod: {
      startDate: subDays(startDate, diffInDays),
      endDate: startDate,
    },
  };
};

export const getDateRangeConfigForDataAggregation = (
  dateRange: Exclude<DashboardDateRangeOptions, 'custom'>
) => {
  switch (dateRange) {
    case 'today':
      return {
        addTime: addDays,
        diffTime: differenceInDays,
        labelFormat: 'iii',
        timeIntervals: [0],
      };

    case 'week':
      return {
        addTime: addDays,
        diffTime: differenceInDays,
        labelFormat: 'iii',
        timeIntervals: [0, 1, 2, 3, 4, 5, 6],
      };

    case 'month':
      return {
        addTime: addMonths,
        diffTime: differenceInMonths,
        labelFormat: 'LLL',
        timeIntervals: [0],
      };

    case 'semester':
      return {
        addTime: addMonths,
        diffTime: differenceInMonths,
        labelFormat: 'LLL',
        timeIntervals: [0, 1, 2, 3, 4, 5],
      };

    case 'year':
      return {
        addTime: addYears,
        diffTime: differenceInYears,
        labelFormat: 'yyyy',
        timeIntervals: [0],
      };
  }
};

export const getCustomDateRangeConfigForDataAggregation = (
  startDate: Date,
  endDate: Date
) => ({
  addTime: addDays,
  diffTime: differenceInDays,
  labelFormat: 'MMM dd',
  timeIntervals: [...Array(differenceInDays(endDate, startDate))].map(
    (x, i) => i
  ),
});
