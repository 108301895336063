import prop from 'ramda/src/prop';
import React from 'react';
import styled from 'styled-components';

const InvoicesAmountCardListContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const InvoicesAmountCarListGrid = styled.div<{ columnCount: number }>`
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: repeat(${prop('columnCount')}, minmax(240px, 320px));
`;

const InvoicesAmountCardList: React.FunctionComponent = ({ children }) => {
  const nonNullChildrenCount = React.Children.toArray(children).reduce<number>(
    (acc, child) => (child === null ? acc : acc + 1),
    0
  );

  return (
    <InvoicesAmountCardListContainer>
      <InvoicesAmountCarListGrid columnCount={nonNullChildrenCount}>
        {children}
      </InvoicesAmountCarListGrid>
    </InvoicesAmountCardListContainer>
  );
};

export default InvoicesAmountCardList;
