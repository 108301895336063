import buildInvoicesView, {
  renderInvoiceRow,
} from '../ConfigurableInvoicesView';

export default buildInvoicesView({
  cards: ['paid', 'validation', 'pending'],
  title: 'Pagos',
  currentInvoicesTableConfig: {
    header: 'Pagos en proceso',
    gridColumn: '15% 20% 15% 15% 15% 20%',
    headers: [
      'ID FACTURA',
      'PERIODO DE FACTURACIÓN',
      'COMISIONES',
      'IVA',
      'TOTAL',
      'ESTATUS',
    ],
    renderRow: renderInvoiceRow({ showRecipientName: false }),
  },
  paidInvoicesTableConfig: {
    header: 'Historial de pagos',
    gridColumn: '15% 20% 15% 15% 15% 20%',
    headers: [
      'ID FACTURA',
      'PERIODO DE FACTURACIÓN',
      'COMISIONES',
      'IVA',
      'TOTAL',
      'ESTATUS',
    ],
    renderRow: renderInvoiceRow({ showRecipientName: false }),
  },
});
