import compareAsc from 'date-fns/compareAsc';
import format from 'date-fns/format';
import isNil from 'ramda/src/isNil';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import automaticProductsActions from '../../actions/automaticProducts';
import colors from '../../constants/colors';
import grammar from '../../constants/grammar';
import Button from '../Button';
import Card from '../Card';
import LoadingIndicator from '../LoadingIndicator';
import ProductsActivateModal from '../ProductsActivateModal';
import SmartTable, { TableCell } from '../SmartTable';

const Link = styled.span<{ color: string }>`
  color: ${props => props.color};
  text-decoration: underline;
  cursor: pointer;
`;

const LoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 50vh;
  justify-content: center;
  width: 100%;
`;

interface IProduct {
  id: number;
  internalName: string;
  commercialName: string;
  productType: string;
  validThrough: string;
  createdAt: string | null;
  updatedAt: string | null;
  active: boolean;
}

interface IProductsViewProps {
  products: IProduct[];
  fetchBankAutomaticProducts: Function;
  updateProductStatus: Function;
  history: RouteComponentProps['history'];
  updatingProductStatus: boolean;
  productUpdatedSuccess: boolean;
}

const ProductsView: React.FunctionComponent<IProductsViewProps> = ({
  products,
  fetchBankAutomaticProducts,
  history,
}) => {
  const [sortedProducts, setSortedProducts] = useState<IProduct[]>();
  const [showActivateModal, setShowActivateModal] = useState<boolean>(false);
  const [modalProduct, setModalProduct] = useState<IProduct | null>();

  useEffect(() => {
    fetchBankAutomaticProducts();
  }, [fetchBankAutomaticProducts]);

  useEffect(() => {
    if (!isNil(products)) {
      setSortedProducts(products.sort(sortProducts));
    }
  }, [products]);

  const sortProducts = (a: IProduct, b: IProduct) => {
    if (isNil(a.createdAt) && isNil(b.createdAt)) {
      return 0;
    } else if (isNil(a.createdAt)) {
      return 1;
    } else if (isNil(b.createdAt)) {
      return -1;
    }

    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return compareAsc(dateA, dateB) * -1;
  };

  const content = isNil(sortedProducts) ? (
    <LoadingState>
      <LoadingIndicator />
    </LoadingState>
  ) : (
    <SmartTable
      data={sortedProducts}
      gridColumns={`200px repeat(5, 1fr) 60px`}
      headers={[
        'Interno',
        'Externo',
        'Tipo',
        'Alta',
        'Vigencia',
        'Modificación',
        'Estatus',
      ]}
      renderRow={product => {
        const internalName = (
          <Link
            color={colors.HYPERLINK}
            onClick={() => history.push(`products/${product.id}`)}
          >
            {product.internalName}
          </Link>
        );

        const status = (
          <Link
            color={product.active ? colors.GREEN : colors.RED}
            onClick={() => {
              setModalProduct(product);
              setShowActivateModal(true);
            }}
          >
            {product.active ? 'Activo' : 'Inactivo'}
          </Link>
        );

        return [
          <TableCell key={0}>{internalName}</TableCell>,
          <TableCell key={1}>{product.commercialName}</TableCell>,
          <TableCell key={2}>{grammar[product.productType]}</TableCell>,
          <TableCell key={3}>
            {isNil(product.createdAt)
              ? 'No disponible'
              : format(new Date(product.createdAt), 'yyyy-MM-dd')}
          </TableCell>,
          <TableCell key={4}>{product.validThrough}</TableCell>,
          <TableCell key={5}>
            {isNil(product.updatedAt) || product.updatedAt === product.createdAt
              ? 'Sin modificar'
              : format(new Date(product.updatedAt), 'yyyy-MM-dd')}
          </TableCell>,
          <TableCell key={6}>{status}</TableCell>,
        ];
      }}
    />
  );

  return (
    <Fragment>
      <Card
        header={'Productos'}
        controls={<Button variant="primary">Importar productos</Button>}
      >
        {content}
      </Card>
      {isNil(modalProduct) ? null : (
        <ProductsActivateModal
          product={modalProduct}
          showModal={showActivateModal}
          requestClose={() => {
            setShowActivateModal(false);
            setModalProduct(null);
          }}
        />
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  products: state.entities.automaticProducts.products,
});

const creators = {
  fetchBankAutomaticProducts:
    automaticProductsActions.creators.fetchBankAutomaticProducts.request,
};

export default connect(mapStateToProps, creators)(ProductsView);
