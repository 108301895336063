import { createAction } from 'redux-actions';

import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const OFFER_MESSAGES_FETCH = createAsyncFlowTypes('OFFER_MESSAGES_FETCH');
const OFFER_MESSAGES_CREATE = createAsyncFlowTypes('OFFER_MESSAGES_CREATE');
const OFFER_MESSAGES_CREATE_OPTIMISTIC = 'OFFER_MESSAGES_CREATE_OPTIMISTIC';

const fetchOfferMessages = createAsyncFlowCreators(OFFER_MESSAGES_FETCH);
const createOfferMessage = createAsyncFlowCreators(OFFER_MESSAGES_CREATE);
const createOfferMessageOptimistic = createAction(
  OFFER_MESSAGES_CREATE_OPTIMISTIC
);

export default {
  types: {
    OFFER_MESSAGES_FETCH,
    OFFER_MESSAGES_CREATE,
    OFFER_MESSAGES_CREATE_OPTIMISTIC,
  },
  creators: {
    fetchOfferMessages,
    createOfferMessage,
    createOfferMessageOptimistic,
  },
};
