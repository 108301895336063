const documentStatusDropdownOptions = [
  {
    text: 'Aceptado',
    value: 'approved',
  },
  {
    text: 'Rechazado',
    value: 'rejected',
  },
  {
    text: 'Validación',
    value: 'validation',
  },
];

const documentStatusOptions = [
  {
    text: 'Requerido',
    value: 'required',
  },
  ...documentStatusDropdownOptions,
];

export { documentStatusDropdownOptions, documentStatusOptions };
