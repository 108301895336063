import { handleActions } from 'redux-actions';

const initialState = null;

const accountDataCheckReducer = handleActions(
  {
    CHECK_EXISTING_CLIENT_DATA_COMPLETED: () => true,
    CHECK_EXISTING_CLIENT_DATA_FAILED: () => false,
    CHECK_EXISTING_CLIENT_DATA_CLEAR: () => initialState,
    CLEAR_NEW_APPLICATION: () => initialState,
  },
  initialState
);

export default accountDataCheckReducer;
