import { FormikProps } from 'formik';
import isEmpty from 'ramda/src/isEmpty';
import React, { Fragment, useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
  EDUCATION_LEVEL_OPTIONS,
  MARITAL_OPTIONS,
  REGIME_OPTIONS,
  SEX_OPTIONS,
  STATE_OF_BIRTH_OPTIONS,
} from '../../constants/applicationOptions';
import colors from '../../constants/colors';
import useAutoTaxPayerIdAndCURPFill from '../../hooks/useAutoTaxPayerIdAndCURPFill';
import useClearStateOfBirth from '../../hooks/useClearStateOfBirth';
import useFetchCountries from '../../hooks/useFetchCountries';
import usePostalCodeOptions from '../../hooks/usePostalCodeOptions';
import usePostalCodeRelatedFieldsAutoFill from '../../hooks/usePostalCodeRelatedFieldsAutoFill';
import usePostalCodeRelatedFieldsCleanup from '../../hooks/usePostalCodeRelatedFieldsCleanup';
import {
  dateNotBiggerThanTodayValidation,
  legalAgeValidation,
} from '../../utils/dateValidation';
import { applySelector } from '../../utils/misc';
import { FormikDate, FormikDropdown, FormikText } from '../FormikInputs';
import { Section } from '../MultiSectionForm';
import errorMsg from './errorMessages';

const Container = styled.div<{ isMobile: boolean }>`
  background-color: ${colors.WHITE};
  ${props => (props.isMobile ? '' : 'display: flex;')}
  height: 100%;
  justify-content: center;
  padding-top: ${props => (props.isMobile ? '0px' : '36px')};
  width: 100%;

  & span {
    font-weight: normal;
  }
`;

const Grid = styled.div<{ isMobile: boolean }>`
  ${props =>
    props.isMobile
      ? 'margin: auto; width: 85%;'
      : 'display: grid; grid-column-gap: 24px; grid-template-columns: 280px 280px;'}
`;

const SubSectionName = styled.div<{ isMobile: boolean }>`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: bold;
  ${props =>
    props.isMobile
      ? 'margin: auto; text-align: center; padding: 18px 0 10px;'
      : 'width: 128px;'}
`;

const TaxPayerInputsContainer = styled.div<{ isMobile: boolean }>`
  margin: auto;
  display: grid;
  ${props =>
    props.isMobile
      ? 'grid-template-columns: 68% 31%;'
      : 'grid-template-columns: 192px 80px;'}
  grid-column-gap: 8px;
`;

const cosignerSchema = Yup.object().shape({
  name: Yup.string().required(errorMsg.cosigner.name.required),
  firstSurname: Yup.string().required(errorMsg.cosigner.firstSurname.required),
  secondSurname: Yup.string().required(
    errorMsg.cosigner.secondSurname.required
  ),
  dateOfBirth: Yup.date()
    .typeError(errorMsg.cosigner.dateOfBirth.typeError)
    .test(
      'future-date',
      errorMsg.cosigner.dateOfBirth.testBigger,
      dateNotBiggerThanTodayValidation
    )
    .test('legal-age', errorMsg.cosigner.dateOfBirth.test, legalAgeValidation)
    .required(errorMsg.cosigner.dateOfBirth.required),
  sex: Yup.string()
    .matches(/female|male/)
    .required(errorMsg.cosigner.sex.required),
  countryOfBirth: Yup.string().required(
    errorMsg.cosigner.countryOfBirth.required
  ),
  nationality: Yup.string().required(errorMsg.cosigner.nationality.required),
  stateOfBirth: Yup.string().required(errorMsg.cosigner.stateOfBirth.required),
  curp: Yup.string().required(errorMsg.cosigner.curp.required),
  taxPayerId: Yup.string().required(errorMsg.cosigner.taxPayerId.required),
  taxPayerIdComplement: Yup.string().matches(/^[a-zA-Z0-9]{3}$/, {
    excludeEmptyString: true,
    message: errorMsg.cosigner.taxPayerIdComplement.matches,
  }),
  educationLevel: Yup.string().required(
    errorMsg.cosigner.educationLevel.required
  ),
  maritalStatus: Yup.string().required(
    errorMsg.cosigner.maritalStatus.required
  ),
  matrimonialRegime: Yup.string().when('maritalStatus', {
    is: 'married',
    then: Yup.string().required(errorMsg.cosigner.matrimonialRegime.required),
  }),
});

const spouseSchema = Yup.object().shape({
  name: Yup.string().required(errorMsg.spouse.name.required),
  firstSurname: Yup.string().required(errorMsg.spouse.firstSurname.required),
  secondSurname: Yup.string().required(errorMsg.spouse.secondSurname.required),
  dateOfBirth: Yup.date()
    .typeError(errorMsg.spouse.dateOfBirth.typeError)
    .test(
      'future-date',
      errorMsg.spouse.dateOfBirth.testBigger,
      dateNotBiggerThanTodayValidation
    )
    .test('legal-age', errorMsg.spouse.dateOfBirth.test, legalAgeValidation)
    .required(errorMsg.spouse.dateOfBirth.required),
  sex: Yup.string()
    .matches(/female|male/)
    .required(errorMsg.spouse.sex.required),
  countryOfBirth: Yup.string().required(
    errorMsg.spouse.countryOfBirth.required
  ),
  nationality: Yup.string().required(errorMsg.spouse.nationality.required),
  stateOfBirth: Yup.string().required(errorMsg.spouse.stateOfBirth.required),
  curp: Yup.string().required(errorMsg.spouse.curp.required),
  taxPayerId: Yup.string().required(errorMsg.spouse.taxPayerId.required),
  taxPayerIdComplement: Yup.string().matches(/^[a-zA-Z0-9]{3}$/, {
    excludeEmptyString: true,
    message: errorMsg.spouse.taxPayerIdComplement.matches,
  }),
  educationLevel: Yup.string().required(
    errorMsg.spouse.educationLevel.required
  ),
});

const getThirdPartiesSectionInitialValues = ({
  hasCosigner,
  hasSpouse,
  spouseAsCosigner,
}: IThirdPartiesSectionConfig) => {
  const cosignerInitialValues = {
    name: '',
    firstSurname: '',
    secondSurname: '',
    sex: '',
    dateOfBirth: '',
    countryOfBirth: '',
    nationality: '',
    stateOfBirth: '',
    curp: '',
    taxPayerId: '',
    taxPayerIdComplement: '',
    educationLevel: '',
    maritalStatus: '',
    matrimonialRegime: '',
  };

  const spouseInitialValues = {
    name: '',
    firstSurname: '',
    secondSurname: '',
    sex: '',
    dateOfBirth: '',
    countryOfBirth: '',
    nationality: '',
    stateOfBirth: '',
    curp: '',
    taxPayerId: '',
    taxPayerIdComplement: '',
    educationLevel: '',
  };

  return {
    cosigner: hasCosigner && !spouseAsCosigner ? cosignerInitialValues : null,
    spouse: hasSpouse ? spouseInitialValues : null,
  };
};

const getThirdPartiesSectionSchema = ({
  hasCosigner,
  hasSpouse,
  spouseAsCosigner,
}: IThirdPartiesSectionConfig) => {
  return Yup.object().shape({
    cosigner: cosignerSchema.nullable(!hasCosigner || spouseAsCosigner),
    spouse: spouseSchema.nullable(!hasSpouse),
  });
};

interface IThirdPartiesSection {
  isMobile: boolean;
  formikProps: FormikProps<any>;
}

const ThirdPartiesSectionComponent: React.FunctionComponent<IThirdPartiesSection> = ({
  isMobile,
  formikProps,
}) => {
  const { cosigner, spouse } = formikProps.values;

  const renderCosignerSubSection = formikProps.values.cosigner !== null;
  const renderSpouseSubSection = formikProps.values.spouse !== null;

  useAutoTaxPayerIdAndCURPFill(formikProps, spouse, 'spouse');
  useAutoTaxPayerIdAndCURPFill(formikProps, cosigner, 'cosigner');

  const cosignerAddreesPostalCodeOptions = usePostalCodeOptions(
    formikProps,
    'cosigner.address.postalCode'
  );
  usePostalCodeRelatedFieldsAutoFill(
    formikProps,
    cosignerAddreesPostalCodeOptions,
    'cosigner.address'
  );
  usePostalCodeRelatedFieldsCleanup(formikProps, 'cosigner.address');

  const cosignerEemploymentPostalCodeOptions = usePostalCodeOptions(
    formikProps,
    'cosigner.employment.postalCode'
  );
  usePostalCodeRelatedFieldsAutoFill(
    formikProps,
    cosignerEemploymentPostalCodeOptions,
    'cosigner.employment'
  );
  usePostalCodeRelatedFieldsCleanup(formikProps, 'cosigner.employment');

  const countries = useFetchCountries();

  const countryOfBirthOptions = useMemo(
    () =>
      countries.map(country => ({
        text: country.country,
        value: country.country,
      })),
    [countries]
  );

  const nationalitiesOptions = useMemo(
    () =>
      countries.map(country => ({
        text: country.nationality,
        value: country.key,
      })),
    [countries]
  );

  const isStateOfBirthDisabledSpouse =
    !isEmpty(applySelector(formikProps.values, 'spouse.countryOfBirth')) &&
    applySelector(formikProps.values, 'spouse.countryOfBirth') !== 'México';
  const isStateOfBirthDisabledCosigner =
    !isEmpty(applySelector(formikProps.values, 'cosigner.countryOfBirth')) &&
    applySelector(formikProps.values, 'cosigner.countryOfBirth') !== 'México';

  useClearStateOfBirth(formikProps, 'spouse');
  useClearStateOfBirth(formikProps, 'cosigner');

  return (
    <Fragment>
      {renderSpouseSubSection ? (
        <Fragment>
          <Container isMobile={isMobile}>
            <SubSectionName isMobile={isMobile}>Cónyuge</SubSectionName>
            <Grid isMobile={isMobile}>
              <FormikText label="NOMBRES" name="spouse.name" />
              <FormikText label="PRIMER APELLIDO" name="spouse.firstSurname" />
              <FormikText
                label="SEGUNDO APELLIDO"
                name="spouse.secondSurname"
              />
              <FormikDropdown
                label="GÉNERO"
                name="spouse.sex"
                options={SEX_OPTIONS}
                placeholder="Género"
              />
              <FormikDate
                label="FECHA DE NACIMIENTO"
                name="spouse.dateOfBirth"
              />
              <FormikDropdown
                label="PAÍS DE NACIMIENTO"
                name="spouse.countryOfBirth"
                options={countryOfBirthOptions}
                placeholder="Selecciona un país"
              />
              <FormikDropdown
                label="NACIONALIDAD"
                name="spouse.nationality"
                options={nationalitiesOptions}
                placeholder="Selecciona un nacionalidad"
              />
              <FormikDropdown
                label="ESTADO DE NACIMIENTO"
                name="spouse.stateOfBirth"
                options={STATE_OF_BIRTH_OPTIONS}
                placeholder="Selecciona un estado"
                disabled={isStateOfBirthDisabledSpouse}
              />
              <FormikText label="CURP" name="spouse.curp" />
              <TaxPayerInputsContainer isMobile={isMobile}>
                <FormikText label="RFC" name="spouse.taxPayerId" />
                <FormikText
                  label="HOMOCLAVE"
                  name="spouse.taxPayerIdComplement"
                />
              </TaxPayerInputsContainer>
              <FormikDropdown
                label="GRADO DE ESTUDIOS"
                name="spouse.educationLevel"
                options={EDUCATION_LEVEL_OPTIONS}
                placeholder="Selecciona una opción"
              />
            </Grid>
          </Container>
        </Fragment>
      ) : null}
      {renderCosignerSubSection ? (
        <Container isMobile={isMobile}>
          <SubSectionName isMobile={isMobile}>Coacreditado</SubSectionName>
          <Grid isMobile={isMobile}>
            <FormikText label="NOMBRES" name="cosigner.name" />
            <FormikText label="PRIMER APELLIDO" name="cosigner.firstSurname" />
            <FormikText
              label="SEGUNDO APELLIDO"
              name="cosigner.secondSurname"
            />
            <FormikDropdown
              label="GÉNERO"
              name="cosigner.sex"
              options={SEX_OPTIONS}
              placeholder="Género"
            />
            <FormikDate
              label="FECHA DE NACIMIENTO"
              name="cosigner.dateOfBirth"
            />
            <FormikDropdown
              label="PAÍS DE NACIMIENTO"
              name="cosigner.countryOfBirth"
              options={countryOfBirthOptions}
              placeholder="Selecciona un país"
            />
            <FormikDropdown
              label="NACIONALIDAD"
              name="cosigner.nationality"
              options={nationalitiesOptions}
              placeholder="Selecciona un nacionalidad"
            />
            <FormikDropdown
              label="ESTADO DE NACIMIENTO"
              name="cosigner.stateOfBirth"
              options={STATE_OF_BIRTH_OPTIONS}
              placeholder="Selecciona un estado"
              disabled={isStateOfBirthDisabledCosigner}
            />
            <FormikDropdown
              label="ESTADO CIVIL"
              name="cosigner.maritalStatus"
              options={MARITAL_OPTIONS}
              placeholder="Selecciona un estado civil"
            />
            {formikProps.values.cosigner.maritalStatus === 'married' ? (
              <FormikDropdown
                label="RÉGIMEN MATRIMONIAL"
                name="cosigner.matrimonialRegime"
                options={REGIME_OPTIONS}
                placeholder="Selecciona un régimen matrimonial"
              />
            ) : null}
            <FormikText label="CURP" name="cosigner.curp" />
            <TaxPayerInputsContainer isMobile={isMobile}>
              <FormikText label="RFC" name="cosigner.taxPayerId" />
              <FormikText
                label="HOMOCLAVE"
                name="cosigner.taxPayerIdComplement"
              />
            </TaxPayerInputsContainer>
            <FormikDropdown
              label="GRADO DE ESTUDIOS"
              name="cosigner.educationLevel"
              options={EDUCATION_LEVEL_OPTIONS}
              placeholder="Selecciona una opción"
            />
          </Grid>
        </Container>
      ) : null}
    </Fragment>
  );
};

const thirdPartiesStateProps = (state: any): { isMobile: boolean } => ({
  isMobile: state.deviceData.isMobile,
});

const ConnectedThirdPartiesSection = connect(thirdPartiesStateProps)(
  ThirdPartiesSectionComponent
);

interface IThirdPartiesSectionConfig {
  hasCosigner: boolean;
  hasSpouse: boolean;
  spouseAsCosigner: boolean;
}

const ThirdPartiesSection = ({
  hasCosigner,
  hasSpouse,
  spouseAsCosigner,
}: IThirdPartiesSectionConfig) => {
  return (
    <Section
      component={ConnectedThirdPartiesSection}
      initialValues={getThirdPartiesSectionInitialValues({
        hasCosigner,
        hasSpouse,
        spouseAsCosigner,
      })}
      name="thirdParties"
      sectionSchema={getThirdPartiesSectionSchema({
        hasCosigner,
        hasSpouse,
        spouseAsCosigner,
      })}
    />
  );
};

export default ThirdPartiesSection;
