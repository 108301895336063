import React from 'react';
import styled from 'styled-components';

import { noop } from '../../utils/misc';

const Checkmark = styled.span`
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #dddddd;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const Container = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  height: 20px;
  user-select: none;
  width: 20px;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ ${Checkmark} {
    background-color: #bbbbbb;
  }

  & input:checked ~ ${Checkmark} {
    background-color: #00c3a8;
  }

  & input:checked ~ ${Checkmark}:after {
    display: block;
  }

  & ${Checkmark}:after {
    left: 7px;
    top: 4px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

interface Props {
  checked: boolean;
  onBlur?(e: React.FocusEvent<HTMLLabelElement>): void;
  onChange?(checked: boolean): void;
}

const Checkbox: React.FunctionComponent<Props> = ({
  checked = false,
  onBlur = noop,
  onChange = noop,
}) => {
  return (
    <Container onBlur={onBlur}>
      <input
        checked={checked}
        type="checkbox"
        onChange={e => onChange(e.target.checked)}
      />
      <Checkmark />
    </Container>
  );
};

export default Checkbox;
