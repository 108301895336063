import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import min from 'ramda/src/min';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import clientUsersActions from '../../actions/clientUsers';
import colors from '../../constants/colors';
import f from '../../constants/formatters';
import {
  getOverdueAmountColor,
  getScoreColor,
  getScoreText,
  translateCreditHistoryCreditType,
} from '../../utils/creditHistoryAccounts';
import Button from '../Button';
import Card from '../Card';
import DropdownInput from '../DropdownInput';
import LoadingState from '../LoadingState';
import SmartTable, { TableCell } from '../SmartTable';

const DropdownContainer = styled.div`
  width: 160px;
  padding-top: 48px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const HeaderContainer = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  border: solid 1px ${colors.BORDER};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 36px 30px;
  padding-right: 20px;
  margin-bottom: 24px;
`;

const DataCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const ValueContainer = styled.div<{ size: string; color: string }>`
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
  text-align: center;
`;

const SimpleDataCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: ${colors.WHITE};
  border: solid 1px ${colors.BORDER};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  height: 100px;
  width: 300px;
  padding: 20px 80px;
`;

const BackButtonContainer = styled.div`
  margin-bottom: 20px;
`;

const Border = styled.div`
  width: 100%;
  background-color: ${colors.BACKGROUND};
  padding-bottom: 25px;
`;

interface ICreditHistoryAccount {
  id: number;
  applicationId: number;
  externalUpdatedAt: string;
  bankName: string;
  open: string | null;
  liabilityType: string;
  type: string;
  creditType: string;
  monetaryUnit: string;
  numberOfPayments: string;
  paymentFrequency: string;
  periodicPayment: string;
  openedAt: string;
  lastPaymentAt: string | null;
  closedAt: string | null;
  reportedAt: string;
  approvedCredit: string | null;
  outstandingBalance: string;
  creditLimit: string | null;
  overdueBalance: string;
  overduePayments: string;
  currentPaymentStatus: string;
  paymentHistory: string;
  moneyLaunderingCode: string | null;
  worstOverduePayment: string | null;
  worstOverduePaymentAt: string | null;
  worstOverdueBalance: string | null;
  createdAt: Date;
  updatedAt: Date | null;
}

interface ICreditHistoryAddress {
  street: string;
  neighborhood: string;
  municipality: string;
  city: string;
  state: string;
  zipCode: string;
  moveInDate: string;
  phoneNumber: string;
  type: string;
  settlementType: string;
  domicileRegistryDate: string;
  createdAt: string;
  updatedAt: string;
}

interface ICreditHistoryConsultation {
  consultationDate: string;
  granterKey: string;
  granterName: string;
  granterPhoneNumber: string;
  creditType: string;
  monetaryUnitKey: string;
  creditAmount: string;
  responsabilityType: string;
  createdAt: string;
  updatedAt: string;
}

interface ICreditHistoryQuery {
  queryDate: string;
  creditHistoryAccounts: ICreditHistoryAccount[];
  creditHistoryAddresses?: ICreditHistoryAddress[];
  creditHistoryConsultations?: ICreditHistoryConsultation[];
  approvedAmount: number;
  overdueAmount: number;
  ficoScore: number;
}

interface IClientCreditHistoryView {
  fetchCreditHistoryAccounts: Function;
  creditHistoryQueries: ICreditHistoryQuery[] | null;
  applicationId: number | undefined;
  history: RouteComponentProps['history'];
}

interface IClientCreditHistoryComponent {
  creditHistory: ICreditHistoryQuery;
}

const getCurrencyFormat = (value: string | null): string =>
  f.currency(!(isNil(value) || isEmpty(value)) ? value : 0);

const CreditHistoryAccountRow = (
  account: ICreditHistoryAccount
): JSX.Element[] => {
  const limit = (() => {
    const creditLimit = account.creditLimit;
    const approvedCredit = account.approvedCredit;
    if (
      !(
        isNil(creditLimit) ||
        isEmpty(creditLimit) ||
        isNil(approvedCredit) ||
        isEmpty(approvedCredit)
      )
    ) {
      return f.currency(
        min(Number.parseFloat(creditLimit), Number.parseFloat(approvedCredit))
      );
    } else if (
      !isNil(account.approvedCredit) &&
      !isEmpty(account.approvedCredit)
    ) {
      return f.currency(account.approvedCredit);
    } else if (!isNil(account.creditLimit) && !isEmpty(account.creditLimit)) {
      return f.currency(account.creditLimit);
    } else {
      return 'N/A';
    }
  })();
  const creditType = !(isNil(account.creditType) || isEmpty(account.creditType))
    ? translateCreditHistoryCreditType[account.creditType]
    : 'N/A';
  const openedAt = !(isNil(account.openedAt) || isEmpty(account.openedAt))
    ? format(parse(account.openedAt, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
    : '--/--/----';
  const closedAt = !(isNil(account.closedAt) || isEmpty(account.closedAt))
    ? format(parse(account.closedAt, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
    : '--/--/----';
  const worstOverduePayment =
    !(
      isNil(account.worstOverduePayment) || isEmpty(account.worstOverduePayment)
    ) && Number.parseInt(account.worstOverduePayment) > 0
      ? `Hasta ${account.worstOverduePayment} periodos`
      : 'Sin atrasos';
  const overdueBalance = getCurrencyFormat(account.overdueBalance);
  const periodicPayment = getCurrencyFormat(account.periodicPayment);
  const outstandingBalance = getCurrencyFormat(account.outstandingBalance);

  return [
    <TableCell key={0}>{creditType}</TableCell>,
    <TableCell key={1}>{account.bankName}</TableCell>,
    <TableCell key={2}>{openedAt}</TableCell>,
    <TableCell key={3}>{closedAt}</TableCell>,
    <TableCell key={4}>{limit}</TableCell>,
    <TableCell key={5}>{outstandingBalance}</TableCell>,
    <TableCell key={6}>{overdueBalance}</TableCell>,
    <TableCell key={7}>{periodicPayment}</TableCell>,
    <TableCell key={8}>{worstOverduePayment}</TableCell>,
  ];
};

interface IClientCreditHistoryAddressesComponent {
  addresses: ICreditHistoryAddress[];
}

const ClientCreditAddressesComponent: React.FunctionComponent<IClientCreditHistoryAddressesComponent> = ({
  addresses,
}) => {
  const getFullAddress = (address: ICreditHistoryAddress): string => {
    return `${address.street} Col. ${address.neighborhood}. ${address.city}. ${address.state}. CP ${address.zipCode}`;
  };

  return (
    <Fragment>
      <Card header="Domicilios">
        <SmartTable
          data={addresses}
          gridColumns={'repeat(3, 10% 15% 70%)'}
          headers={['NÚMERO', 'FECHA', 'DOMICILIO']}
          renderRow={(address, index) => {
            return [
              <TableCell key={0}>{index + 1}</TableCell>,
              <TableCell key={1}>{f.date(address.moveInDate)}</TableCell>,
              <TableCell key={2}>{getFullAddress(address)}</TableCell>,
            ];
          }}
        />
      </Card>
    </Fragment>
  );
};

interface IClientCreditHistoryConsultationsComponent {
  consultations: ICreditHistoryConsultation[];
}

const ClientCreditHistoryConsultationsComponent: React.FunctionComponent<IClientCreditHistoryConsultationsComponent> = ({
  consultations,
}) => {
  return (
    <Fragment>
      <Card header="Consultas">
        <SmartTable
          data={consultations}
          gridColumns={'repeat(5, 1fr)'}
          headers={['FECHA', 'OTORGANTE', 'TIPO', 'MONTO', 'MONEDA']}
          renderRow={consultation => {
            return [
              <TableCell key={0}>
                {f.date(consultation.consultationDate)}
              </TableCell>,
              <TableCell key={1}>{consultation.granterName}</TableCell>,
              <TableCell key={2}>{consultation.creditType}</TableCell>,
              <TableCell key={3}>
                {f.currency(consultation.creditAmount)}
              </TableCell>,
              <TableCell key={4}>
                {consultation.monetaryUnitKey
                  ? consultation.monetaryUnitKey
                  : '--'}
              </TableCell>,
            ];
          }}
        />
      </Card>
    </Fragment>
  );
};

const ClientCreditHistoryComponent: React.FunctionComponent<IClientCreditHistoryComponent> = ({
  creditHistory,
}) => {
  const {
    approvedAmount,
    ficoScore,
    overdueAmount,
    creditHistoryAccounts,
  } = creditHistory;

  const approvedAmountColor = (() => {
    if (approvedAmount >= 35000) {
      return colors.GREEN;
    } else if (approvedAmount >= 15000) {
      return colors.ORANGE;
    } else if (approvedAmount > 0) {
      return colors.RED;
    } else {
      return colors.SECONDARY_TEXT;
    }
  })();

  return (
    <Fragment>
      <DataCardsContainer>
        <SimpleDataCardContainer>
          <ValueContainer color={colors.SECONDARY_TEXT} size="12px">
            ESTADO DEL HISTORIAL
          </ValueContainer>
          <ValueContainer color={getScoreColor(ficoScore)} size="22px">
            {getScoreText(ficoScore)}
          </ValueContainer>
        </SimpleDataCardContainer>
        <SimpleDataCardContainer>
          <ValueContainer color={colors.SECONDARY_TEXT} size="12px">
            MONTO HISTORIAL
          </ValueContainer>
          <ValueContainer color={approvedAmountColor} size="22px">
            {f.currency(approvedAmount)}
          </ValueContainer>
        </SimpleDataCardContainer>
        <SimpleDataCardContainer>
          <ValueContainer color={colors.SECONDARY_TEXT} size="12px">
            MONTO VENCIDO
          </ValueContainer>
          <ValueContainer
            color={getOverdueAmountColor(overdueAmount)}
            size="22px"
          >
            {f.currency(overdueAmount)}
          </ValueContainer>
        </SimpleDataCardContainer>
      </DataCardsContainer>
      {creditHistory.creditHistoryAddresses ? (
        <Fragment>
          <ClientCreditAddressesComponent
            addresses={creditHistory.creditHistoryAddresses}
          />
          <Border />
        </Fragment>
      ) : null}
      <Card header="Cuentas">
        <SmartTable
          data={creditHistoryAccounts}
          gridColumns={`repeat(9, 1fr)`}
          headers={[
            'PRODUCTO',
            'OTORGANTE',
            'APERTURA',
            'CIERRE',
            'LÍMITE',
            'SALDO',
            'VENCIDO',
            'PAGO',
            'PEOR ATRASO',
          ]}
          renderRow={CreditHistoryAccountRow}
        />
      </Card>
      {creditHistory.creditHistoryConsultations ? (
        <Fragment>
          <Border />
          <ClientCreditHistoryConsultationsComponent
            consultations={creditHistory.creditHistoryConsultations}
          />
        </Fragment>
      ) : null}
    </Fragment>
  );
};

type Params = {
  requestId: string;
};

const ClientCreditHistoryView: React.FunctionComponent<IClientCreditHistoryView> = ({
  fetchCreditHistoryAccounts,
  creditHistoryQueries,
  applicationId,
  history,
}) => {
  const [selectedQuery, setSelectedQuery] = useState<number>(0);
  useEffect(() => {
    fetchCreditHistoryAccounts({ applicationId });
  }, [fetchCreditHistoryAccounts, applicationId]);

  if (isNil(creditHistoryQueries)) {
    return <LoadingState />;
  }

  const queryDates = creditHistoryQueries.map(query => query.queryDate);
  const selectedCreditHistory = creditHistoryQueries[selectedQuery];

  return (
    <Fragment>
      {applicationId ? (
        <BackButtonContainer>
          <Button variant="secondary" onClick={() => history.goBack()}>
            Regresar
          </Button>
        </BackButtonContainer>
      ) : null}
      <HeaderContainer>
        <Title>Historial crediticio</Title>
        <DropdownContainer>
          <DropdownInput
            label=""
            onChange={option => setSelectedQuery(option.value as number)}
            options={queryDates.map((date, i) => ({
              text: format(Date.parse(date), 'dd/MM/yyyy'),
              value: i,
            }))}
            value={selectedQuery}
          />
        </DropdownContainer>
      </HeaderContainer>
      <ClientCreditHistoryComponent creditHistory={selectedCreditHistory} />
    </Fragment>
  );
};

const mapStateToProps = (
  state: any
): { creditHistoryQueries: ICreditHistoryQuery[] | null } => ({
  creditHistoryQueries: state.entities.clientUsers.creditHistoryQueries,
});

const creators = {
  fetchCreditHistoryAccounts:
    clientUsersActions.creators.fetchClientCreditHistory.request,
};

export default connect(mapStateToProps, creators)(ClientCreditHistoryView);
