const errorMessages = {
  vehicleCondition: {
    required: 'El tipo del producto es un campo obligatorio.',
  },
  vehicleBrand: {
    required: 'La marca es un campo obligatorio.',
  },
  vehicleModel: {
    required: 'La versión es un campo obligatorio.',
  },
  vehicleModelYear: {
    required: 'El año es un campo obligatorio.',
  },
  vehicleValue: {
    typeError: 'El valor del producto no es válido.',
    positive: 'El valor del producto no es válido.',
    required: 'El valor del producto es un campo obligatorio.',
  },
  productName: {
    required: 'El nombre del producto financiero es un campo obligatorio.',
  },
  downpayment: {
    typeError: 'El enganche no es válido.',
    positive: 'El enganche no es válido.',
    required: 'El enganche es un campo obligatorio.',
  },
  creditAmount: {
    typeError: 'El monto del crédito no es válido.',
    positive: 'El monto del crédito no es válido.',
    required: 'El monto del crédito es un campo obligatorio.',
  },
  numberPayments: {
    typeError: 'El plazo no es válido.',
    positive: 'El plazo no es válido.',
    required: 'El plazo es un campo obligatorio.',
  },
  paymentFrequency: {
    required: 'La periodicidad es un campo obligatorio.',
  },
  rate: {
    typeError: 'La tasa ordinaria no es válida.',
    positive: 'La tasa ordinaria no es válida.',
    required: 'La tasa ordinaria es un campo obligatorio.',
  },
  rateType: {
    required: '',
  },
  cat: {
    typeError: 'El CAT no es válido.',
    positive: 'El CAT no es válido.',
    required: 'El CAT es un campo obligatorio.',
  },
  fee: {
    typeError: 'La comisión por apertura no es válida.',
    required: 'La comisión por apertura es obligatoria',
  },
  feeType: {
    required: '',
  },
  accessoriesAmount: {
    typeError: 'El campo de otros accesorios no es válido.',
    required: 'El campo de otros accesorios es obligatorio.',
  },
  payment: {
    typeError: 'El pago periódico no es válido.',
    positive: 'El pago periódico no es válido.',
    required: 'El pago periódico es un campo obligatorio. ',
  },
  dateVigency: {
    test: 'La fecha de vigencia no puede ser anterior al día de hoy.',
    required: 'La fecha de vigencia es obligatoria',
  },
  documents: {
    name: {
      required: 'El nombre es un campo obligatorio.',
    },
    description: {
      required: 'La descripción es un campo obligatorio.',
    },
    required: '',
  },
};

export default errorMessages;
