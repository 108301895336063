import { Formik } from 'formik';
import values from 'ramda/src/values';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import priceQuoteActions from '../../actions/priceQuote';
import productsActions from '../../actions/products';
import vehicleActions from '../../actions/vehicles';
import colors from '../../constants/colors';
import f from '../../constants/formatters';
import filterRelevantOffers from '../../utils/filterRelevantOffers';
import Button from '../Button';
import PriceQuotingForm, { priceQuoteSchema } from '../PriceQuoteForm';

const { REACT_APP_PUBLIC_ASSETS_URL } = process.env;

const PriceQuoteContainer = styled.div`
  margin-top: 25px;
  width: 100;
`;

const PriceQuoteHeaderContainer = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  border: solid 1px ${colors.BORDER};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  height: 70px;
  justify-content: space-between;
  padding: 36px 30px;
  padding-right: 20px;
  margin-bottom: 12px;
`;

const HeaderText = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 18px;
  font-weight: bold;
`;

const OffersContainer = styled.div`
  border: solid 1px ${colors.BORDER};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  margin-top: 8px;
  display: grid;
  grid-template-columns: 0.2% 20% 13% 13% 13% 13% 13% 13%;
`;

const OfferContainerBorder = styled.div`
  background-color: ${colors.AQUA};
`;

const OfferElement = styled.div`
  padding-left: 5%;
  padding-right: 10%;
  padding-top: 12px;
  padding-bottom: 12px;
`;

const BankLogoContainer = styled.div`
  height: 90px;
`;

const BankLogo = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

const OfferElementTitle = styled.div`
  color: #8f93a1;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
`;

const OfferElementValue = styled.div`
  padding-top: 9px;
  font-family: 'Lato';
`;

interface IVehicleManufacturer {
  id: number;
  name: string;
}

interface IVehicleModel {
  id: number;
  manufacturerId: number;
  model: string;
  vehicleType: string;
  version: string;
  createdAt: string;
  updatedAt: string;
}

interface IOfferElementData {
  label: string;
  value: string;
}

const OfferElementData: React.FunctionComponent<IOfferElementData> = ({
  label,
  value,
}) => {
  return (
    <OfferElement>
      <OfferElementTitle>{label}</OfferElementTitle>
      <OfferElementValue>{value}</OfferElementValue>
    </OfferElement>
  );
};

interface IOfferSection {
  controls: React.ReactNode;
  priceQuoteOffers: Array<IOffer>;
}

const OfferSection: React.FunctionComponent<IOfferSection> = ({
  controls = Fragment,
  priceQuoteOffers,
}) => {
  const filteredOffers = filterRelevantOffers(priceQuoteOffers);
  const offers = filteredOffers.map(
    offer =>
      offer.bank && (
        <OffersContainer key={offer.id}>
          <OfferContainerBorder />
          <OfferElement>
            <BankLogoContainer>
              <BankLogo src={REACT_APP_PUBLIC_ASSETS_URL + offer.bank.logo} />
            </BankLogoContainer>
          </OfferElement>
          <OfferElementData label={'NOMBRE'} value={offer.productName} />
          <OfferElementData label={'MONTO'} value={f.currency(offer.amount)} />
          <OfferElementData label={'PLAZO'} value={offer.requestPeriod} />
          <OfferElementData
            label={'ENGANCHE'}
            value={f.currency(offer.downpayment)}
          />
          <OfferElementData
            label={'TASA/CAT'}
            value={`${f.percentage(offer.interestRate)} / ${f.percentage(
              offer.cat
            )}`}
          />
          <OfferElementData
            label={'PAGO PERIÓDICO'}
            value={f.currency(offer.fullPayment)}
          />
        </OffersContainer>
      )
  );

  return (
    <PriceQuoteContainer>
      <PriceQuoteHeaderContainer>
        <HeaderText>Productos</HeaderText>
        {controls instanceof Function ? controls() : controls}
      </PriceQuoteHeaderContainer>
      {offers}
    </PriceQuoteContainer>
  );
};

interface IPriceQuotingViewWrapper {
  fetchProductManufacturers: Function;
  fetchVehicleModels: Function;
  createPriceQuote: Function;
  clearPriceQuoteOffers: Function;
  savePriceQuoteData: Function;
  productManufacturers: Array<IVehicleManufacturer>;
  vehicleModels: Array<IVehicleModel>;
  priceQuoteOffers: Array<IOffer>;
  history: RouteComponentProps['history'];
}

const PriceQuotingWrapper: React.FunctionComponent<IPriceQuotingViewWrapper> = ({
  fetchProductManufacturers,
  fetchVehicleModels,
  createPriceQuote,
  clearPriceQuoteOffers,
  savePriceQuoteData,
  productManufacturers,
  vehicleModels,
  priceQuoteOffers,
  history,
}) => {
  const [formikValues, setFormikValues] = useState();
  useEffect(() => {
    clearPriceQuoteOffers();
  }, [clearPriceQuoteOffers]);

  const submitForm = (formikProps: any): void => {
    setFormikValues(formikProps);
    createPriceQuote(formikProps);
  };

  const createApplicationButton = (
    <Button
      variant="primary"
      onClick={() => {
        savePriceQuoteData(formikValues);
        history.push('/application/profiling');
      }}
    >
      Crear Solicitud
    </Button>
  );

  return (
    <div>
      <Formik
        initialValues={{
          productType: 'motorcycle_finance',
          vehicleModel: '',
          brand: '',
          unitType: 'new',
          vehicleModelYear: '',
          price: 0,
          downpayment: 0,
          insurance: '',
          numberOfPayments: 0,
        }}
        onSubmit={submitForm}
        render={formikProps => (
          <PriceQuotingForm
            fetchProductManufacturers={fetchProductManufacturers}
            fetchVehicleModels={fetchVehicleModels}
            productManufacturers={productManufacturers}
            vehicleModels={vehicleModels}
            formikProps={formikProps}
          />
        )}
        validationSchema={priceQuoteSchema}
      />
      {priceQuoteOffers.length > 0 && (
        <OfferSection
          priceQuoteOffers={priceQuoteOffers}
          controls={createApplicationButton}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: any): any => ({
  productManufacturers: values(state.entities.productsManufacturers),
  vehicleModels: values(state.entities.vehicleModels),
  priceQuoteOffers: values(state.entities.priceQuote.offers),
});

const creators = {
  fetchProductManufacturers:
    productsActions.creators.fetchProductsManufacturers.request,
  fetchVehicleModels: vehicleActions.creators.fetchVehicleModels.request,
  createPriceQuote: priceQuoteActions.creators.createPriceQuote.request,
  clearPriceQuoteOffers: priceQuoteActions.creators.clearPriceQuoteOffers,
  savePriceQuoteData: priceQuoteActions.creators.savePriceQuoteData,
};

export default connect(mapStateToProps, creators)(PriceQuotingWrapper);
