import merge from 'ramda/src/merge';
import { handleActions } from 'redux-actions';

import commissionsActions from '../actions/commissions';
import invoicesActions from '../actions/invoices';
import utils from './utils';

const initialState = null;

const commissionsReducer = handleActions(
  {
    [commissionsActions.types.COMMISSIONS_FETCH.STARTED]: () => initialState,
    [commissionsActions.types.COMMISSIONS_FETCH.COMPLETED]: (state, action) =>
      utils.buildDictWithKey(action.payload, 'id'),
    [invoicesActions.types.INVOICE_COMMISSIONS_FETCH.COMPLETED]: (
      state,
      action
    ) => {
      return merge(state, utils.buildDictWithKey(action.payload, 'id'));
    },
  },
  initialState
);

export default commissionsReducer;
