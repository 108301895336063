const profilingSectionsFormErrors = {
  applicant: {
    academicDegree: {
      required: 'El nivel de estudios es un campo obligatorio.',
    },
    creditRecordStatus: {
      required: 'El estado del historial es un campo obligatorio.',
    },
    maritalStatus: {
      required: 'El estado civil es un campo obligatorio.',
    },
  },
  cosigner: {
    employmentStatus: {
      required: 'La situación laboral del coacredito es obligatoria.',
    },
    income: {
      number: 'El ingreso de coacreditado no es válido.',
      positive: 'El ingreso no puede ser negativo o cero.',
      required: 'El ingreso del coacreditado es un campo obligatorio.',
    },
  },
  address: {
    homeType: {
      required: 'El tipo de domicilio es un campo obligatorio.',
    },
    rent: {
      number: 'La renta no es válida.',
      moreThanZero: 'La renta no puede ser cero.',
      required: 'La renta es un campo obligatorio.',
    },
    antiquity: {
      date: 'La fecha de ingreso al domicilio no es válida.',
      required: 'La fecha es un campo obligatorio.',
    },
  },
  employment: {
    employmentStatus: {
      required: 'La situación laboral es un campo obligatorio.',
    },
    contract: {
      required: 'El tipo de contrato es un campo obligatorio.',
    },
    fixedIncomeQuantity: {
      moreThanZero: 'El ingreso fijo no puede ser cero.',
      number: ' El ingreso fijo no es válido.',
      required: 'El ingreso fijo es un campo obligatorio.',
    },
    employmentDate: {
      date: 'La fecha de ingreso no es válida.',
      required: 'La fecha de ingreso es un campo obligatorio',
    },
    sourceOtherIncome: {
      required: 'La fuente de otros ingresos es un campo obligatorio.',
    },
    otherIncomeQuantity: {
      moreThanZero: 'El campo de otros ingresos no puede ser cero.',
      number: 'El campo de otros ingresos no es válido',
      required: 'El campo de otros ingresos es obligatorio.',
    },
    previousEmployment: {
      required: 'La selección del empleo anterior es obligatoria.',
    },
  },
  product: {
    brand: {
      required: 'La marca es un campo obligatorio.',
    },
    version: {
      required: 'La versión es un campo obligatorio.',
    },
    model: {
      required: 'El modelo es un campo obligatorio.',
    },
    price: {
      number: 'El valor no es válido.',
      moreThanZero: 'El valor no puede ser cero.',
      required: 'El valor es un campo obligatorio.',
    },
    downpayment: {
      moreThanZero: 'El enganche no puede ser cero.',
      max: 'El enganche tiene que ser menor al precio',
      number: 'El enganche no es válido.',
      required: ' El enganche es un campo obligatorio.',
    },
    term: {
      number: 'El plazo no es válido.',
      required: 'El plazo es un campo obligatorio.',
    },
    insurance: {
      required: 'El tipo de seguro es un campo obligatorio.',
    },
  },
};

export default profilingSectionsFormErrors;
