import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import parse from 'date-fns/parse';
import keys from 'ramda/src/keys';
import * as Yup from 'yup';

import errors from './errorMessages';

const paymentFrequencyOptions = [
  { text: 'Semanas', value: 'weekly' },
  { text: 'Quincenas', value: 'fortnights' },
  { text: 'Meses', value: 'monthly' },
  { text: 'Años', value: 'years' },
];

const vehicleConditionOptions = [
  { text: 'Nuevo', value: 'new' },
  { text: 'Seminuevo', value: 'used' },
];

const getModelYearOptions = (vehicleCondition: string) => {
  const currentYear = parseInt(format(new Date(), 'yyyy'));

  if (vehicleCondition === 'new') {
    return [
      { text: `${currentYear - 1}`, value: `${currentYear - 1}` },
      { text: `${currentYear}`, value: `${currentYear}` },
      { text: `${currentYear + 1}`, value: `${currentYear + 1}` },
    ];
  }

  const options = [];

  // For used vehicles allow up to 10 years old models
  for (let i = 0; i <= 10; i += 1) {
    options.push({
      text: `${currentYear - i}`,
      value: `${currentYear - i}`,
    });
  }

  return [
    { text: `${currentYear + 1}`, value: `${currentYear + 1}` },
    ...options,
  ];
};

const sanitazeAmount = (amount: number | string) =>
  amount.toString().replace(',', '');

const normalizeFinalOfferPayload = (payload: Approbation) => {
  const normalized: any = [];

  // Extract values from dropdown options
  keys(payload).forEach(key => {
    const value: any = payload[key];
    if (value.text && value.value) {
      normalized[key] = value.value;
    }
  });

  normalized.vehicleId = payload.vehicleModel;
  normalized.vehicleYear = payload.vehicleModelYear;
  normalized.vehicleBrand = payload.vehicleBrand;
  normalized.vehicleCondition = payload.vehicleCondition;
  normalized.numberPayments = Number.parseInt(`${payload.numberPayments}`);
  normalized.paymentFrequency = payload.paymentFrequency;
  normalized.productName = payload.productName;
  normalized.documents = payload.documents;
  normalized.interestRate = payload.rate;
  normalized.cat = payload.cat;
  normalized.fee = payload.fee;
  normalized.vehicleValue = payload.vehicleValue;
  normalized.downpayment = payload.downpayment;
  normalized.creditAmount = payload.creditAmount;
  normalized.periodicPaymentAmount = payload.periodicPaymentAmount;
  normalized.accessoriesAmount = payload.accessoriesAmount;

  normalized.interestRateType =
    payload.rateType === 'Fija' ? 'fixed' : 'variable';
  normalized.feeType = payload.feeType === 'Monto' ? 'amount' : 'percentage';
  normalized.dateOffer = new Date().toISOString();
  normalized.dateVigency = payload.dateVigency.toISOString();

  const accessoriesValues: { [index: string]: string } = {
    GPS: 'gps',
    SD: 'damage_insurance',
    SV: 'life_insurance',
  };

  normalized.accessories = payload.accessories.map(
    accessory => accessoriesValues[accessory]
  );

  return { ...normalized };
};

const dateVigencyTest = (date: Date) =>
  isSameDay(date, new Date()) || isAfter(date, new Date());

export interface Document {
  name: string;
  description: string;
}

export interface Approbation {
  vehicleCondition: string;
  vehicleBrand: string;
  vehicleModel: string;
  vehicleModelYear: string;
  vehicleValue: number;
  productName: string;
  downpayment: number;
  creditAmount: number;
  numberPayments: number | string;
  paymentFrequency: string;
  rate: number;
  rateType: string;
  cat: number;
  fee: number;
  feeType: string;
  accessoriesAmount: number;
  accessories: Array<string>;
  periodicPaymentAmount: number;
  dateVigency: Date;
  documents: Array<Document>;
}

const approbationFormSchema: Yup.ObjectSchema<Yup.Shape<
  {},
  Approbation
>> = Yup.object().shape({
  vehicleCondition: Yup.string().required(errors.vehicleCondition.required),
  vehicleBrand: Yup.string().required(errors.vehicleBrand.required),
  vehicleModel: Yup.string().required(errors.vehicleModel.required),
  vehicleModelYear: Yup.string().required(errors.vehicleModelYear.required),
  vehicleValue: Yup.number()
    .typeError(errors.vehicleValue.typeError)
    .positive(errors.vehicleValue.positive)
    .required(errors.vehicleValue.required),
  productName: Yup.string().required(errors.productName.required),
  downpayment: Yup.number()
    .typeError(errors.downpayment.typeError)
    .positive(errors.downpayment.positive)
    .required(errors.downpayment.required),
  creditAmount: Yup.number()
    .typeError(errors.creditAmount.typeError)
    .positive(errors.creditAmount.positive)
    .required(errors.creditAmount.required),
  numberPayments: Yup.number()
    .typeError(errors.numberPayments.typeError)
    .positive(errors.numberPayments.positive)
    .required(errors.numberPayments.required),
  paymentFrequency: Yup.string().required(errors.paymentFrequency.required),
  rate: Yup.number()
    .typeError(errors.rate.typeError)
    .positive(errors.rate.positive)
    .required(errors.rate.required),
  rateType: Yup.string().required(errors.rateType.required),
  cat: Yup.number()
    .typeError(errors.cat.typeError)
    .positive(errors.cat.positive)
    .required(errors.cat.required),
  fee: Yup.number()
    .typeError(errors.fee.typeError)
    .required(errors.fee.required),
  feeType: Yup.string().required(errors.feeType.required),
  accessoriesAmount: Yup.number()
    .typeError(errors.accessoriesAmount.typeError)
    .required(errors.accessoriesAmount.required),
  accessories: Yup.array().of(Yup.string()),
  periodicPaymentAmount: Yup.number()
    .typeError(errors.payment.typeError)
    .positive(errors.payment.positive)
    .required(errors.payment.required),
  dateVigency: Yup.date()
    .test('', errors.dateVigency.test, dateVigencyTest)
    .required(errors.dateVigency.required),
  documents: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required(errors.documents.name.required),
        description: Yup.string().required(
          errors.documents.description.required
        ),
      })
    )
    .required(errors.documents.required),
});

const getVehicleDataFromRequest = (request: any) => {
  if (request.type === 'automotriz') {
    return request.automotiveCredits[0];
  }

  return request.motorcycleCredit[0];
};

const getVehicleValue = (creditData: any): number => {
  const value: string =
    creditData.unitType === 'nuevo'
      ? creditData.valueNew
      : creditData.valuePreowned;

  return Number.parseFloat(value.replace(/,/g, ''));
};

const getNumberOfPayments = (offer: any): number =>
  Number.parseInt(offer.requestedPeriod.split(' ')[0]);

const getPaymentFrequency = (offer: any): string => {
  if (offer.requestedPeriod.toLowerCase().includes('meses')) {
    return paymentFrequencyOptions[2].value;
  }

  return paymentFrequencyOptions[3].value;
};

const accessoriesMap: { [index: string]: string } = {
  'Seguro de Vida': 'SV',
  'Seguro de Daños': 'SD',
  'Seguro de auto': 'SD',
};

const getAccessoriesOptions = (offerAccessories: any): Array<string> =>
  offerAccessories.map((accessory: any) =>
    accessoriesMap[accessory.name]
      ? accessoriesMap[accessory.name]
      : accessory.name
  );

const getVehicleCondition = (creditData: any) =>
  creditData.unitType === 'new'
    ? vehicleConditionOptions[0]
    : vehicleConditionOptions[0];

const getRate = (offer: any) => parseFloat(offer.rate.replace('%', ''));

const getInitialFormState = (
  offer: any,
  templateDocuments: ITemplateDocument[]
): Approbation => {
  const creditData = getVehicleDataFromRequest(offer.request);
  return {
    vehicleCondition: getVehicleCondition(creditData).value,
    vehicleBrand: creditData.brand.id,
    vehicleModel: creditData.version.id,
    vehicleModelYear: `${creditData.model}`,
    vehicleValue: getVehicleValue(creditData),
    productName: offer.nameProduct,
    downpayment: offer.downpayment,
    creditAmount: parseFloat(offer.amount),
    numberPayments: getNumberOfPayments(offer),
    paymentFrequency: getPaymentFrequency(offer),
    rate: getRate(offer),
    rateType: offer.rateOptions === 'fixed' ? 'Fija' : 'Variable',
    cat: parseFloat(offer.cat),
    fee: parseFloat(offer.fee),
    feeType: offer.feeOptions === 'cash' ? 'Monto' : 'Porcentaje',
    accessoriesAmount: offer.accesories,
    accessories: getAccessoriesOptions(offer.selectedAccesories),
    periodicPaymentAmount: parseFloat(offer.fullPayment),
    dateVigency: parse(offer.dateVigency, 'yyyy-MM-dd', new Date()),
    documents: templateDocuments.map(doc => ({
      name: doc.name,
      description: doc.description,
    })),
  };
};

export {
  approbationFormSchema,
  getInitialFormState,
  getModelYearOptions,
  getVehicleDataFromRequest,
  normalizeFinalOfferPayload,
  paymentFrequencyOptions,
  sanitazeAmount,
  vehicleConditionOptions,
};
