import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../constants/colors';

const LinkWrapper = styled.span`
  & a {
    color: ${colors.HYPERLINK};
    cursor: pointer;
    text-decoration: underline;
  }
`;

interface IInternalLink {
  text: number | string;
  to: string;
}

const InternalLink: React.FunctionComponent<IInternalLink> = ({ text, to }) => (
  <LinkWrapper>
    <Link to={to}>{text}</Link>
  </LinkWrapper>
);

export default InternalLink;
