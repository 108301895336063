import parse from 'date-fns/parse';
import { Form, Formik, FormikProps } from 'formik';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import accountCreationActions from '../../actions/accountCreation';
import continueApplicationActions from '../../actions/continueApplication';
import {
  SEX_OPTIONS,
  STATE_OF_BIRTH_OPTIONS,
} from '../../constants/applicationOptions';
import colors from '../../constants/colors';
import useAutoRFCAndCURPFill from '../../hooks/useAutoTaxPayerIdAndCURPFill';
import useFetchCountries from '../../hooks/useFetchCountries';
import {
  dateNotBiggerThanTodayValidation,
  legalAgeValidation,
} from '../../utils/dateValidation';
import Button from '../Button';
import {
  FormikCheckbox,
  FormikDate,
  FormikDropdown,
  FormikText,
} from '../FormikInputs';
import LoadingIndicator from '../LoadingIndicator';
import NIPVerificationModal from '../NIPVerificationModal';
import SimpleCard from '../SimpleCard';
import errors from './errorMessages';

const { REACT_APP_API_HOST } = process.env;

const FormContainer = styled.div<{ isMobile: boolean }>`
  background-color: ${colors.WHITE};
  display: ${props => (props.isMobile ? null : 'flex')};
  height: 100%;
  justify-content: center;
  padding: ${props => (props.isMobile ? '25px 0;' : '36px 0;')}
  width: 100%;

  & span {
    font-weight: normal;
  }
`;

const Grid = styled.div<{ isMobile: boolean }>`
  ${props =>
    props.isMobile
      ? 'width: 85%; margin: auto; padding-top: 17px;'
      : 'display: grid; grid-column-gap: 24px; grid-template-columns: 280px 280px;'}
`;

const SubSectionName = styled.div<{ isMobile: boolean }>`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: bold;
  width: ${props => (props.isMobile ? '100%' : '128px')};
  ${props => (props.isMobile ? 'text-align: center;' : null)}
`;

// apparently the version of typescript we're using needs to do this when the style it's extending has props
const CreateAccountControls = styled(FormContainer as any)`
  height: auto;
  padding-top: 0;
  padding-bottom: 36px;
  ${props => (props.isMobile ? 'margin: auto; width: 85%;' : 'width: 100%;')}
`;

const ButtonContainer = styled.div`
  height: 38px;
`;

const TaxPayerInputsContainer = styled.div<{ isMobile: boolean }>`
  margin: auto;
  display: grid;
  ${props =>
    props.isMobile
      ? 'grid-template-columns: 68% 31%;'
      : 'grid-template-columns: 192px 80px;'}
  grid-column-gap: 8px;
`;

const TermsAndConditions = styled.div<{ isMobile: boolean }>`
  ${props =>
    props.isMobile
      ? 'text-align: center;'
      : 'padding-left: 128px; width: 712px;'}
`;

const TermsAndConditionsContainer = styled.div`
  display: flex;
  padding-bottom: 32px;
`;

const TermsAndConditionsText = styled.div`
  color: ${colors.DUSK50};
  font-size: 14px;
  padding-left: 12px;

  a {
    color: ${colors.HYPERLINK};
  }
`;

const TermsAndConditionsLegend = (): JSX.Element => (
  <TermsAndConditionsText>
    Acepto los&nbsp;
    <a
      href={`${REACT_APP_API_HOST}/terminos-y-condiciones`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Términos y condiciones
    </a>{' '}
    &nbsp;de uso y el&nbsp;
    <a
      href={`${REACT_APP_API_HOST}/aviso-de-privacidad`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Aviso de privacidad
    </a>
    .
  </TermsAndConditionsText>
);

interface IAccountCreation {
  name: string;
  firstSurname: string;
  secondSurname: string;
  dateOfBirth: Date;
  sex: string;
  countryOfBirth: string;
  nationality: string;
  stateOfBirth: string;
  curp: string;
  taxPayerId: string;
  taxPayerIdComplement: string;
  acceptsTermsAndConditionsAndPrivacyPolicy: boolean;
  electronicSignatureCertificate?: string;
}

const accountCreationInitialState: IAccountCreation = {
  name: '',
  firstSurname: '',
  secondSurname: '',
  dateOfBirth: new Date(),
  sex: '',
  countryOfBirth: '',
  nationality: '',
  stateOfBirth: '',
  curp: '',
  taxPayerId: '',
  taxPayerIdComplement: '',
  acceptsTermsAndConditionsAndPrivacyPolicy: false,
  electronicSignatureCertificate: '',
};

const accountCreationValidationSchema: Yup.ObjectSchema<Yup.Shape<
  {},
  IAccountCreation
>> = Yup.object().shape({
  name: Yup.string().required(errors.name.required),
  firstSurname: Yup.string().required(errors.firstSurname.required),
  secondSurname: Yup.string().required(errors.secondSurname.required),
  dateOfBirth: Yup.date()
    .test(
      'future-date',
      errors.dateOfBirth.testBigger,
      dateNotBiggerThanTodayValidation
    )
    .test('legal-age', errors.dateOfBirth.test, legalAgeValidation)
    .typeError(errors.dateOfBirth.typeError)
    .required(),
  sex: Yup.string().required(errors.sex.required),
  countryOfBirth: Yup.string().required(errors.countryOfBirth.required),
  nationality: Yup.string().required(errors.nationality.required),
  stateOfBirth: Yup.string().required(errors.stateOfBirth.required),
  curp: Yup.string()
    .length(18, errors.curp.length)
    .required(errors.curp.required),
  taxPayerId: Yup.string()
    .length(10, errors.taxPayerId.required)
    .required(errors.taxPayerId.required),
  taxPayerIdComplement: Yup.string().matches(/^[a-zA-Z0-9]{3}$/, {
    excludeEmptyString: true,
    message: errors.taxPayerIdComplement.matches,
  }),
  acceptsTermsAndConditionsAndPrivacyPolicy: Yup.bool()
    .test(
      'terms and conditions',
      errors.acceptsTermsAndConditionsAndPrivacyPolicy.test,
      val => val === true
    )
    .required(),
});

interface IAccountCreationForm {
  creatingClientAccount: boolean;
  accountAlreadyCreated: boolean;
  isMobile: boolean;
  canSubmit: boolean;
  formikProps: FormikProps<IAccountCreation>;
}

const AccountCreationForm: React.FunctionComponent<IAccountCreationForm> = ({
  creatingClientAccount,
  accountAlreadyCreated,
  isMobile,
  canSubmit,
  formikProps,
}) => {
  const countries = useFetchCountries();
  useAutoRFCAndCURPFill(formikProps, formikProps.values, '');

  const isStateOfBirthDisabled =
    !isEmpty(formikProps.values.countryOfBirth) &&
    formikProps.values.countryOfBirth !== 'México';

  useEffect(() => {
    if (isStateOfBirthDisabled) {
      formikProps.setFieldValue('stateOfBirth', 'NE');
    } else if (formikProps.values.stateOfBirth === 'NE') {
      formikProps.setFieldValue('stateOfBirth', '');
    }
  }, [formikProps, isStateOfBirthDisabled]);

  const countryOfBirthOptions = useMemo(
    () =>
      countries.map(country => ({
        text: country.country,
        value: country.country,
      })),
    [countries]
  );

  const nationalitiesOptions = useMemo(
    () =>
      countries.map(country => ({
        text: country.nationality,
        value: country.key,
      })),
    [countries]
  );

  return (
    <Form>
      <FormContainer isMobile={isMobile}>
        <SubSectionName isMobile={isMobile}>
          Información del solicitante
        </SubSectionName>
        <Grid isMobile={isMobile}>
          <FormikText
            label="NOMBRE"
            name="name"
            disabled={accountAlreadyCreated}
          />
          <FormikText
            label="PRIMER APELLIDO"
            name="firstSurname"
            disabled={accountAlreadyCreated}
          />
          <FormikText
            label="SEGUNDO APELLIDO"
            name="secondSurname"
            disabled={accountAlreadyCreated}
          />
          <FormikDate
            label="FECHA DE NACIMIENTO"
            name="dateOfBirth"
            disabled={accountAlreadyCreated}
          />
          <FormikDropdown
            label="SEXO"
            name="sex"
            options={SEX_OPTIONS}
            placeholder="Sexo"
            disabled={accountAlreadyCreated}
          />
          <FormikDropdown
            label="PAÍS DE NACIMIENTO"
            name="countryOfBirth"
            options={countryOfBirthOptions}
            placeholder="País de nacimiento"
            disabled={accountAlreadyCreated}
          />
          <FormikDropdown
            label="NACIONALIDAD"
            name="nationality"
            options={nationalitiesOptions}
            placeholder="Nacionalidad"
            disabled={accountAlreadyCreated}
          />
          <FormikDropdown
            label="ESTADO DE NACIMIENTO"
            name="stateOfBirth"
            options={STATE_OF_BIRTH_OPTIONS}
            placeholder="Estado de nacimiento"
            disabled={isStateOfBirthDisabled || accountAlreadyCreated}
          />
          <FormikText
            label="CURP"
            name="curp"
            disabled={accountAlreadyCreated}
          />
          <TaxPayerInputsContainer isMobile={isMobile}>
            <FormikText
              label="RFC"
              name="taxPayerId"
              disabled={accountAlreadyCreated}
            />
            <FormikText
              label="HOMOCLAVE"
              name="taxPayerIdComplement"
              disabled={accountAlreadyCreated}
              upperCase={true}
            />
          </TaxPayerInputsContainer>
          <FormikText
            label="C. FIRMA ELEC. (OPCIONAL)"
            name="electronicSignatureCertificate"
          />
        </Grid>
      </FormContainer>
      <CreateAccountControls isMobile={isMobile}>
        <TermsAndConditions isMobile={isMobile}>
          <TermsAndConditionsContainer>
            <FormikCheckbox
              label=""
              name="acceptsTermsAndConditionsAndPrivacyPolicy"
              value={
                formikProps.values.acceptsTermsAndConditionsAndPrivacyPolicy
              }
            />
            <TermsAndConditionsLegend />
          </TermsAndConditionsContainer>
          <ButtonContainer>
            {creatingClientAccount ? (
              <LoadingIndicator />
            ) : (
              <Button
                onClick={() => {
                  if (canSubmit) {
                    formikProps.submitForm();
                  }
                }}
              >
                Crear cuenta
              </Button>
            )}
          </ButtonContainer>
        </TermsAndConditions>
      </CreateAccountControls>
    </Form>
  );
};

interface IPrefillingData {
  firstSurname: string;
  secondSurname: string;
  name: string;
  stateOfBirth: string;
  dateOfBirth: string;
  nationality: string;
  sex: string;
}

interface IAccountCreationView {
  createClientAccount: Function;
  creatingClientAccount: boolean;
  profilingCompleted: boolean;
  email: string;
  phoneNumber: string;
  biometricVerificationId: number;
  prefillingData: IPrefillingData;
  redirectToApplicationStart: Function;
  applicationStarted: boolean;
  accountAlreadyCreated: boolean;
  deviceData: IDeviceData;
  canSubmit: boolean;
}

const AccountCreationView: React.FunctionComponent<IAccountCreationView> = ({
  createClientAccount,
  creatingClientAccount,
  profilingCompleted,
  email,
  phoneNumber,
  biometricVerificationId,
  prefillingData,
  redirectToApplicationStart,
  applicationStarted,
  accountAlreadyCreated,
  deviceData,
  canSubmit,
}) => {
  useEffect(() => {
    if (!applicationStarted || !profilingCompleted) {
      redirectToApplicationStart();
    }
  }, [applicationStarted, profilingCompleted, redirectToApplicationStart]);

  const handleSubmitButtonClicked = (values: IAccountCreation): void => {
    createClientAccount({
      client: { ...values, email, phoneNumber, biometricVerificationId },
    });
  };

  const dateOfBirth = isNil(prefillingData)
    ? new Date()
    : parse(prefillingData.dateOfBirth, 'dd/MM/yyyy', new Date());

  return (
    <SimpleCard
      title="Crea tu cuenta"
      subtitle="Llena la siguiente información para que podamos recibir tu solicitud"
    >
      <Formik
        initialValues={{
          ...accountCreationInitialState,
          ...prefillingData,
          dateOfBirth,
        }}
        render={formikProps => (
          <AccountCreationForm
            accountAlreadyCreated={accountAlreadyCreated}
            creatingClientAccount={creatingClientAccount}
            isMobile={deviceData.isMobile}
            formikProps={formikProps}
            canSubmit={canSubmit}
          />
        )}
        onSubmit={handleSubmitButtonClicked}
        validationSchema={accountCreationValidationSchema}
      />
      <NIPVerificationModal />
    </SimpleCard>
  );
};

const mapStateToProps = (state: any): any => ({
  creatingClientAccount: state.loaders.creatingClientAccount,
  email: state.newApplication.profiling.contact.email,
  phoneNumber: state.newApplication.profiling.contact.phoneNumber,
  biometricVerificationId:
    state.newApplication.verificationModal.biometricVerificationId,
  profilingCompleted: Object.keys(state.newApplication.profiling).every(
    key => !isEmpty(state.newApplication.profiling[key])
  ),
  prefillingData: state.newApplication.verificationModal.prefillingData,
  accountAlreadyCreated: !isNil(state.newApplication.accountCreation.account),
  deviceData: state.deviceData,
  canSubmit: state.newApplication.accountCreation.canSubmit,
});

const creators = {
  createClientAccount:
    accountCreationActions.creators.createClientAccount.request,
  redirectToApplicationStart:
    continueApplicationActions.creators.redirectToApplicationStart,
};

export default connect(mapStateToProps, creators)(AccountCreationView);
