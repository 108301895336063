interface ISectionNamesTranslation {
  asset: string;
  client: string;
  spouse: string;
  cosigner: string;
  address: string;
  employment: string;
  employmentAddress: string;
  previousEmployment: string;
  references: string;
  [key: string]: string;
}

const sectionNamesTranslations: ISectionNamesTranslation = {
  asset: 'Producto solicitado',
  client: 'Información del solicitante',
  spouse: 'Cónyuge',
  cosigner: 'Coacreditado',
  address: 'Dirección del solicitante',
  cosignerAddress: 'Dirección del coacreditado',
  employment: 'Datos del empleo',
  employmentAddress: 'Dirección del empleo',
  cosignerEmployment: 'Datos del empleo del coacreditado',
  cosignerEmploymentAddress: 'Dirección del empleo del coacreditado',
  previousEmployment: 'Empleo anterior',
  references: 'Referencia',
};

export default sectionNamesTranslations;
