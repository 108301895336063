import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import documentsActions from '../../actions/documents';
import colors from '../../constants/colors';
import Success from '../../resources/success.png';
import Button from '../Button';
import LoadingIndicator from '../LoadingIndicator';
import Modal from '../Modal';

const ButtonContainer = styled.div`
  display: flex;
  height: 36px;
  justify-content: flex-end;
`;

const ModalHeader = styled.div`
  align-items: center;
  color: ${colors.PRIMARY_TEXT};
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 48px;
`;

const ModalContent = styled.div`
  align-items: center;
  display: flex;
  height: 56px;
  justify-content: center;
  width: 100%;
`;

const SuccessIcon = styled.img`
  height: 24px;
  margin-right: 8px;
`;

const renderContent = (documentUpload, uploadingFiles) => () => {
  const content = (() => {
    // documentUpload could be null, don't use !
    if (documentUpload === false) {
      return 'El archivo no pudo ser cargado';
    } else if (documentUpload) {
      return (
        <Fragment>
          <SuccessIcon src={Success} />
          Archivo cargado correctamente
        </Fragment>
      );
    }

    return <LoadingIndicator />;
  })();

  return <ModalContent>{content}</ModalContent>;
};

const renderControls = (documentUpload, handleClick) => () => (
  <ButtonContainer>
    {documentUpload !== null ? (
      <Button onClick={handleClick}>Aceptar</Button>
    ) : null}
  </ButtonContainer>
);

const FileUploadModal = ({ documentUpload, dismiss, uploadingFiles }) => {
  const isModalOpen = uploadingFiles || documentUpload !== null;

  return (
    <Modal
      content={renderContent(documentUpload, uploadingFiles)}
      controls={renderControls(documentUpload, dismiss)}
      header={() => <ModalHeader>Carga de documentos</ModalHeader>}
      isOpen={isModalOpen}
      onRequestClose={dismiss}
      shouldCloseOnOverlayClick={false}
      width={'524px'}
    />
  );
};

const mapStateToProps = state => ({
  documentUpload: state.documentUpload.success,
  uploadingFiles: state.loaders.uploadingFiles,
});

const creators = {
  dismiss: documentsActions.creators.clearFileUploadState,
};

export default connect(mapStateToProps, creators)(FileUploadModal);
