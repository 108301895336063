import * as d3 from 'd3';
import identity from 'ramda/src/identity';
import isNil from 'ramda/src/isNil';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';

const LegendsGroup = styled.g`
  & text {
    fill: ${colors.PRIMARY_TEXT} !important;
  }
`;

const LineChartHTMLParent = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledSVG = styled.svg`
  & text {
    font-family: 'Lato';
    fill: ${colors.BLUE_GRAY};
  }
`;

interface ILineChart {
  data: [string, number, number][];
  format?: 'none' | 'currency';
  legends: [string, string];
}

const getYDomain = (data: ILineChart['data']): number => {
  const min = 6;
  const maxValueFromData = d3.max(data, d => Math.max(d[1], d[2]));

  if (isNil(maxValueFromData) || maxValueFromData < min) {
    return min;
  } else {
    return Math.ceil(maxValueFromData * 1.2);
  }
};

const tickFormat = (
  text: string,
  index: number,
  domainLength: number
): string => (index % Math.floor(domainLength / 6) === 0 ? text : '');

const LineChart: React.FunctionComponent<ILineChart> = ({
  data,
  format = 'none',
  legends,
}) => {
  const chartMargins = {
    top: 92,
    right: 32,
    left: 60,
    bottom: 68,
  };

  const legendsMargins = {
    top: 24,
    left: 32,
  };

  const [width, setWidth] = useState(600);
  const [height, setHeight] = useState(300);

  const parentContainer = useRef<HTMLDivElement | null>(null);

  const parentContainerWidth = parentContainer.current?.offsetWidth;
  const parentContainerHeight = parentContainer.current?.offsetHeight;

  useEffect(() => {
    if (parentContainerWidth && parentContainerHeight) {
      setWidth(parentContainerWidth);
      setHeight(parentContainerHeight);
    }
  }, [parentContainerWidth, parentContainerHeight]);

  const xAxisRef = useRef<SVGSVGElement | null>(null);
  const yAxisRef = useRef<SVGSVGElement | null>(null);

  const effectiveHeight = height - chartMargins.top - chartMargins.bottom;
  const effectiveWidth = width - chartMargins.left - chartMargins.right;

  const x = d3
    .scaleBand()
    .range([0, effectiveWidth])
    .padding(1);

  const y = d3.scaleLinear().range([effectiveHeight, 0]);

  x.domain(data.map(d => d[0]));
  y.domain([0, getYDomain(data)]);

  const formatFunction = format === 'currency' ? d3.format('.2s') : identity;

  useEffect(() => {
    if (xAxisRef.current !== null) {
      d3.select(xAxisRef.current)
        .call(
          d3
            .axisBottom(x)
            .tickFormat((d, i) => tickFormat(d, i, data.length))
            .tickSize(0)
            .tickPadding(20)
        )
        .call(g => g.select('.domain').attr('stroke', colors.ALASKA_GRAY));
    }

    if (yAxisRef.current !== null) {
      d3.select(yAxisRef.current)
        .call(
          d3
            .axisLeft(y)
            .tickSize(-effectiveWidth)
            .ticks(5)
            .tickFormat(formatFunction as any)
        )
        .call(g => g.select('.domain').remove())
        .call(g =>
          g.selectAll('.tick line').attr('stroke', colors.ALASKA_GRAY)
        );
    }
  }, [data, effectiveWidth, formatFunction, width, height, x, y]);

  const path = d3.line<[string, number, number]>();

  path.x(d => x(d[0]) || 0);
  path.y(d => y(d[1]));
  const blueSeries = path(data);

  path.y(d => y(d[2]));
  const graySeries = path(data);

  if (blueSeries === null || graySeries === null) {
    throw new Error('could not generate path data for custom date range');
  }

  return (
    <LineChartHTMLParent ref={parentContainer}>
      <StyledSVG width={`${width}px`} height={`${height}px`}>
        <LegendsGroup
          transform={`translate(${legendsMargins.left}, ${legendsMargins.top})`}
        >
          {legends.map((legend, i) => (
            <Fragment key={`${i}`}>
              <rect
                x={`${i * 160}`}
                y="8px"
                width="10px"
                height="10px"
                rx="2"
                ry="2"
                fill={i === 0 ? colors.WATER_BLUE : colors.BLUE_GRAY}
              />
              <text fill={colors.PRIMARY_TEXT} x={`${i * 160 + 20}`} y="18px">
                {legend}
              </text>
            </Fragment>
          ))}
        </LegendsGroup>
        <g transform={`translate(${chartMargins.left}, ${chartMargins.top})`}>
          <g>
            <g ref={xAxisRef} transform={`translate(0, ${effectiveHeight})`} />
            <g ref={yAxisRef} />
          </g>
          <g>
            <path
              d={graySeries}
              fill="none"
              stroke={colors.ALASKA_GRAY}
              strokeWidth={2}
            />
            <path
              d={blueSeries}
              fill="none"
              stroke={colors.WATER_BLUE}
              strokeWidth={2}
            />
          </g>
        </g>
      </StyledSVG>
    </LineChartHTMLParent>
  );
};

export default LineChart;
