import { BankUserRoles, PromoterUserRoles } from '../constants/enums';
import {
  isBankAdmin,
  isBankSystemAdmin,
  isBankUser,
  isPromoterAdmin,
  isPromoterSystemAdmin,
  isPromoterUser,
} from './permissions';

interface IRowUserBank {
  role: BankUserRoles;
  bankId: number;
  userId: number;
  user: IUser;
}

interface IRowUserPromoter {
  role: PromoterUserRoles;
  promoterId: number;
  userId: number;
  user: IUser;
}

export type IUserRow = IRowUserBank | IRowUserPromoter;

export const isBankRow = (userRow: IUserRow): userRow is IRowUserBank =>
  userRow.user.profile === 1;

export const isPromoterRow = (userRow: IUserRow): userRow is IRowUserPromoter =>
  userRow.user.profile === 2;

export const rowIsAdmin = (userRow: IUserRow): boolean =>
  (isBankRow(userRow) && userRow.role === BankUserRoles.ADMIN) ||
  (isPromoterRow(userRow) && userRow.role === PromoterUserRoles.ADMIN);

export const rowIsSystemAdmin = (userRow: IUserRow): boolean =>
  (isBankRow(userRow) && userRow.role === BankUserRoles.SYSTEM) ||
  (isPromoterRow(userRow) && userRow.role === PromoterUserRoles.SYSTEM);

export const canModifyAdmins = (userData: IUserData): boolean => {
  return (
    (isBankUser(userData) && isBankSystemAdmin(userData)) ||
    (isPromoterUser(userData) && isPromoterSystemAdmin(userData))
  );
};

export const getRoleString = (userRow: IUserRow): string => {
  if (isBankRow(userRow)) {
    switch (userRow.role) {
      case BankUserRoles.SYSTEM:
        return 'Administrador maestro';
      case BankUserRoles.ADMIN:
        return 'Administrador';
      case BankUserRoles.LEGAL:
        return 'Jurídico';
      case BankUserRoles.SUPERANALYST:
        return 'Super analista';
      case BankUserRoles.ANALYST:
        return 'Analista';
      case BankUserRoles.ACCOUNTANT:
        return 'Contador';
    }
  } else {
    switch (userRow.role) {
      case PromoterUserRoles.SYSTEM:
        return 'Administrador maestro';
      case PromoterUserRoles.ADMIN:
        return 'Administrador';
      case PromoterUserRoles.FNI:
        return 'F&I';
      case PromoterUserRoles.SUPERSALESPERSON:
        return 'Super asesor';
      case PromoterUserRoles.SALESPERSON:
        return 'Asesor';
      case PromoterUserRoles.ACCOUNTANT:
        return 'Contador';
    }
  }
};

export type UserOption = {
  text: string;
  value: string;
};

const promoterAdminUserOptions: UserOption[] = [
  {
    text: 'F&I',
    value: 'f&i',
  },
  {
    text: 'Asesor',
    value: 'salesperson',
  },
  {
    text: 'Super asesor',
    value: 'super-salesperson',
  },
];

const promoterSystemAdminUserOptions: UserOption[] = [
  ...promoterAdminUserOptions,
  {
    text: 'Administrador',
    value: 'admin',
  },
];

const bankAdminUserOptions: UserOption[] = [
  {
    text: 'Analista',
    value: 'analyst',
  },
  {
    text: 'Super analista',
    value: 'super-analyst',
  },
  {
    text: 'Jurídico',
    value: 'legal',
  },
];

const bankSystemAdminUserOptions: UserOption[] = [
  ...bankAdminUserOptions,
  {
    text: 'Administrador',
    value: 'admin',
  },
];

export const getAvailableUsersToCreate = (
  userData: IUserData
): UserOption[] => {
  if (isPromoterUser(userData)) {
    if (isPromoterSystemAdmin(userData)) {
      return promoterSystemAdminUserOptions;
    } else if (isPromoterAdmin(userData)) {
      return promoterAdminUserOptions;
    }
  } else if (isBankUser(userData)) {
    if (isBankSystemAdmin(userData)) {
      return bankSystemAdminUserOptions;
    } else if (isBankAdmin(userData)) {
      return bankAdminUserOptions;
    }
  }
  return [];
};
