import { FormikProps } from 'formik';
import length from 'ramda/src/length';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import usePromoterBanks from './../../hooks/usePromoterBanks';
import colors from '../../constants/colors';
import { FormikCheckbox, FormikText } from '../FormikInputs';
import { Section } from '../MultiSectionForm';
import errorMsg from './errorMessages';

const AuthorizationContainer = styled.div`
  color: ${colors.SECONDARY_TEXT};
  font-size: 14px;
  text-align: justify;
  padding-bottom: 24px;
`;

const FormContainer = styled.div<{ isMobile: boolean }>`
  background-color: ${colors.WHITE};
  ${props => (props.isMobile ? '' : 'display: flex;')}
  height: 100%;
  justify-content: center;
  padding-top: ${props => (props.isMobile ? '0px' : '36px')};
  width: 100%;

  & span {
    font-weight: normal;
  }
`;

const Grid = styled.div<{ isMobile: boolean }>`
  ${props =>
    props.isMobile
      ? 'margin: auto; width: 85%;'
      : 'display: grid; grid-column-gap: 36px; grid-template-columns: 128px 584px;'}
`;

const LegendContainer = styled.div`
  color: ${colors.SECONDARY_TEXT};
  padding-bottom: 8px;
  text-align: justify;
`;

const SubSectionName = styled.div<{ isMobile: boolean }>`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: bold;
  ${props =>
    props.isMobile
      ? 'margin: auto; text-align: center; padding: 18px 0 10px;'
      : 'width: 128px;'}
`;

const initialSendFormState = {
  consent: false,
  nip: '',
  granters: [],
  dateConsulting: '',
};

const NipTipText = styled.div<{ hasError: boolean }>`
  color: ${colors.SECONDARY_TEXT};
  font-size: 12px;
  font-weight: bold;
  ${props => (props.hasError ? 'margin-top: -25px;' : 'margin-top: 0px;')}
`;

const nipRegExp = /^\d{6}$/;
const sendFormSchema = Yup.object().shape({
  consent: Yup.bool()
    .test('consent', errorMsg.consent.test, val => val === true)
    .required(errorMsg.consent.required),
  nip: Yup.string()
    .matches(nipRegExp, {
      message: errorMsg.nip.matches,
    })
    .required(errorMsg.nip.required),
  granters: Yup.array()
    .test('length', errorMsg.granters.test, arr => length(arr) >= 1)
    .required(errorMsg.granters.required),
  dateConsulting: Yup.date().required(),
});

interface ISendSectionComponent {
  isMobile: boolean;
  formikProps: FormikProps<any>;
}

const SendSectionComponent: React.FunctionComponent<ISendSectionComponent> = ({
  isMobile,
  formikProps,
}) => {
  const { setFieldValue, errors } = formikProps;
  const { consent } = formikProps.values;
  const bankOptions = usePromoterBanks(formikProps.values.productType);

  useEffect(() => {
    setFieldValue(
      'granters',
      bankOptions.map(option => option.value)
    );
  }, [bankOptions, setFieldValue]);

  useEffect(() => {
    setFieldValue('dateConsulting', consent ? new Date().toISOString() : null);
  }, [consent, setFieldValue]);

  return (
    <FormContainer isMobile={isMobile}>
      <Grid isMobile={isMobile}>
        <SubSectionName isMobile={isMobile}>Permisos</SubSectionName>
        <div>
          <AuthorizationContainer>
            Declaro bajo promesa de decir verdad que actúo en mi nombre y por
            cuenta propia para los efectos de este trámite del que soy
            beneficiario y autorizo expresamente a Creditus Soluciones
            Tecnológicas SAPI de CV, para que lleve a cabo investigaciones sobre
            mi comportamiento crediticio en las Sociedades de Información
            Crediticia que estime conveniente. Conozco la naturaleza y alcance
            de la información que se solicitará, del uso que se le dará y que se
            podrá realizar consultas periódicas de mi historial crediticio.
            Consiento que esta autorización tenga vigencia de 3 años contados a
            partir de hoy, y en su caso mientras tengamos relación jurídica.
          </AuthorizationContainer>
          <FormikCheckbox
            name="consent"
            label="Acepto consulta"
            value={consent}
          />
        </div>
        <SubSectionName isMobile={isMobile}>NIP</SubSectionName>
        <div>
          <LegendContainer>Por favor inserte su NIP.</LegendContainer>
          <FormikText name="nip" label="NIP" inputMode="decimal" />
          <NipTipText hasError={errors.nip === undefined ? true : false}>
            Tip: Tu NIP lo ricibiste via SMS al crear la cuenta
          </NipTipText>
        </div>
      </Grid>
    </FormContainer>
  );
};

const SendSectionStateProps = (state: any): { isMobile: boolean } => ({
  isMobile: state.deviceData.isMobile,
});

const ConnectedSendSectionComponent = connect(SendSectionStateProps)(
  SendSectionComponent
);

interface ISectionFormSendConfig {
  productType: string;
}

const SectionFormSend = ({
  productType,
}: ISectionFormSendConfig): React.ReactNode => {
  return (
    <Section
      name="send"
      initialValues={{
        ...initialSendFormState,
        productType,
      }}
      sectionSchema={sendFormSchema}
      component={ConnectedSendSectionComponent}
    />
  );
};

export default SectionFormSend;
