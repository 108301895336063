import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import clientUsersActions from '../../actions/clientUsers';
import colors from '../../constants/colors';
import deviceSizes from '../../constants/deviceSizes';
import Card from '../Card';
import LoadingState from '../LoadingState';
import SmartTable, { TableCell } from '../SmartTable';

const Background = styled.div`
  background-color: ${colors.WHITE};
  width: 100%;
`;

const StatusContainer = styled.div`
  background-color: ${colors.GREEN};
  border-radius: 4px;
  width: 80px;
  height: 16px;
  color: ${colors.WHITE};
  text-align: center;
`;

interface IClientProcessesView {
  clientApplications: IOfferedApplication[];
  fetchClientApplications: Function;
  deviceWidth: number;
  history: RouteComponentProps['history'];
}

const getOfferStatus = (offer: IOffer): string => {
  if (offer.status === 'solicitada') {
    return 'Ofertas';
  } else if (
    offer.stage === 'pre-activation' ||
    offer.stage === 'activated' ||
    offer.stage === 'analysis'
  ) {
    return 'Documentos';
  } else if (offer.stage === 'approval') {
    return 'Aprobada';
  } else if (
    offer.stage === 'formalization' ||
    offer.stage === 'pre-formalization'
  ) {
    return 'Firma';
  } else {
    return 'Entrega';
  }
};

const ClientApplicationsView: React.FunctionComponent<IClientProcessesView> = ({
  clientApplications,
  fetchClientApplications,
  deviceWidth,
  history,
}) => {
  useEffect(() => {
    fetchClientApplications();
  }, [fetchClientApplications]);

  const headers =
    deviceWidth < deviceSizes.tablet.width
      ? ['ID', 'COMERCIO', 'PRODUCTO', 'ESTATUS']
      : ['ID', 'COMERCIO', 'MARCA', 'PRODUCTO', 'PRECIO', 'ASESOR', 'ESTATUS'];

  const gridColumns =
    deviceWidth < deviceSizes.tablet.width
      ? 'repeat(4, 10% 30% 35% 25%)'
      : 'repeat(7, 10% 15% 15% 15% 15% 15% 15%)';

  const applications = clientApplications ? (
    <Background>
      <Card header="Compras en proceso">
        <SmartTable
          data={clientApplications}
          gridColumns={gridColumns}
          headers={headers}
          onRowClick={clientApplication => {
            const url =
              clientApplication.offers.length === 1 &&
              clientApplication.offers[0].status === 'aceptada'
                ? `/offered-applications/${clientApplication.id}/offer/${clientApplication.offers[0].id}`
                : `/offered-applications/${clientApplication.id}`;
            history.push(url);
          }}
          renderRow={clientApplication => {
            return deviceWidth < deviceSizes.tablet.width
              ? [
                  <TableCell key={0}>{clientApplication.id}</TableCell>,
                  <TableCell key={1}>
                    {clientApplication.promoter.name}
                  </TableCell>,
                  <TableCell key={2}>
                    {clientApplication.carFinanceApplication
                      ? clientApplication.carFinanceApplication.vehicle.model
                      : clientApplication.motorcycleFinanceApplication?.vehicle
                          .model}
                  </TableCell>,
                  <TableCell key={3}>
                    <StatusContainer>
                      {getOfferStatus(clientApplication.offers[0])}
                    </StatusContainer>
                  </TableCell>,
                ]
              : [
                  <TableCell key={0}>{clientApplication.id}</TableCell>,
                  <TableCell key={1}>
                    {clientApplication.promoter.name}
                  </TableCell>,
                  <TableCell key={2}>
                    {clientApplication.carFinanceApplication
                      ? clientApplication.carFinanceApplication
                          .vehicleManufacturer.name
                      : clientApplication.motorcycleFinanceApplication
                          ?.vehicleManufacturer.name}
                  </TableCell>,
                  <TableCell key={3}>
                    {clientApplication.carFinanceApplication
                      ? clientApplication.carFinanceApplication.vehicle.model
                      : clientApplication.motorcycleFinanceApplication?.vehicle
                          .model}
                  </TableCell>,
                  <TableCell key={4}>
                    {clientApplication.carFinanceApplication
                      ? clientApplication.carFinanceApplication.valueNew
                      : clientApplication.motorcycleFinanceApplication
                          ?.valueNew}
                  </TableCell>,
                  <TableCell key={5}>
                    {`${clientApplication.compliance.promoter.name} ${clientApplication.compliance.promoter.firstSurname} ${clientApplication.compliance.promoter.secondSurname}`}
                  </TableCell>,
                  <TableCell key={6}>
                    <StatusContainer>
                      {getOfferStatus(clientApplication.offers[0])}
                    </StatusContainer>
                  </TableCell>,
                ];
          }}
        />
      </Card>
    </Background>
  ) : (
    <LoadingState />
  );

  return <Fragment>{applications}</Fragment>;
};

const mapStateToProps = (
  state: any
): { clientApplications: IOfferedApplication[]; deviceWidth: number } => ({
  clientApplications: state.entities.clientUsers.clientApplications,
  deviceWidth: state.deviceData.clientWidth,
});

const creators = {
  fetchClientApplications:
    clientUsersActions.creators.fetchClientApplications.request,
};

export default connect(mapStateToProps, creators)(ClientApplicationsView);
