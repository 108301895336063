import isNil from 'ramda/src/isNil';
import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';

const ToggleSwitch = styled.label<{ disabled: boolean }>`
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:disabled + span::before {
    background-color: ${colors.BACKGROUND50};
  }

  input:checked + span::before {
    transform: translateX(18px);
  }

  input:checked + span {
    border: solid 1px
      ${props => (props.disabled ? colors.BACKGROUND : colors.AQUA)};
    background-color: ${props =>
      props.disabled ? colors.BACKGROUND : colors.AQUA};
  }

  & span {
    width: 38px;
    height: 20px;
    border-radius: 10px;
    border: solid 1px ${colors.BACKGROUND};
    background-color: ${colors.BACKGROUND};
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;

    &::before {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      background-color: ${colors.WHITE};
      position: absolute;
      content: '';
      top: 1px;
      bottom: 2px;
      left: 1px;
      transition: 0.4s;
    }
  }
`;

const ToogleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const ToggleTitle = styled.div`
  font-size: 14px;
  line-height: 1.57;
  color: ${colors.PRIMARY_TEXT};
`;

const ToggleSubtitle = styled.div`
  font-size: 14px;
  line-height: 1.57;
  color: ${colors.SECONDARY_TEXT};
`;

interface IToggleCheckboxProps {
  onChange: (checked: boolean) => void;
  value: boolean;
  title?: string;
  subtitle?: string;
  disabled?: boolean;
}

const ToggleCheckbox: React.FunctionComponent<IToggleCheckboxProps> = ({
  onChange,
  value,
  title,
  subtitle,
  disabled,
}) => {
  return (
    <ToogleContainer>
      <ToggleSwitch disabled={isNil(disabled) ? false : disabled}>
        <input
          type="checkbox"
          onChange={e => {
            onChange(e.target.checked);
          }}
          checked={value}
          disabled={isNil(disabled) ? false : disabled}
        />
        <span />
      </ToggleSwitch>
      {isNil(title) && isNil(subtitle) ? null : (
        <DescriptionContainer>
          <ToggleTitle>{title}</ToggleTitle>
          <ToggleSubtitle>{subtitle}</ToggleSubtitle>
        </DescriptionContainer>
      )}
    </ToogleContainer>
  );
};
export default ToggleCheckbox;
