import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const PRODUCTS_GRANTED_FETCH = createAsyncFlowTypes('PRODUCTS_GRANTED_FETCH');
const PRODUCTS_MANUFACTURERS_FETCH = createAsyncFlowTypes(
  'PRODUCTS_MANUFACTURERS_FETCH'
);
const PROMOTER_PRODUCTS_FETCH = createAsyncFlowTypes('PROMOTER_PRODUCTS_FETCH');

const fetchProductsGranted = createAsyncFlowCreators(PRODUCTS_GRANTED_FETCH);
const fetchProductsManufacturers = createAsyncFlowCreators(
  PRODUCTS_MANUFACTURERS_FETCH
);
const fetchPromoterProducts = createAsyncFlowCreators(PROMOTER_PRODUCTS_FETCH);

export default {
  types: {
    PRODUCTS_GRANTED_FETCH,
    PRODUCTS_MANUFACTURERS_FETCH,
    PROMOTER_PRODUCTS_FETCH,
  },
  creators: {
    fetchProductsGranted,
    fetchProductsManufacturers,
    fetchPromoterProducts,
  },
};
