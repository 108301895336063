import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const CREDITUS_UPDATE_OFFER_VIGENCY = createAsyncFlowTypes(
  'CREDITUS_UPDATE_OFFER_VIGENCY'
);
const CREDITUS_FETCH_USERS = createAsyncFlowTypes('CREDITUS_FETCH_USERS');
const CREDITUS_UPDATE_USER = createAsyncFlowTypes('UPDATE_USER');

const updateOfferVigency = createAsyncFlowCreators(
  CREDITUS_UPDATE_OFFER_VIGENCY
);
const creditusFetchUsers = createAsyncFlowCreators(CREDITUS_FETCH_USERS);
const creditusUpdateUser = createAsyncFlowCreators(CREDITUS_UPDATE_USER);

export default {
  types: {
    CREDITUS_UPDATE_OFFER_VIGENCY,
    CREDITUS_FETCH_USERS,
    CREDITUS_UPDATE_USER,
  },
  creators: {
    updateOfferVigency,
    creditusFetchUsers,
    creditusUpdateUser,
  },
};
