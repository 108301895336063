import styled from 'styled-components';

import colors from '../../constants/colors';

const TableCell = styled.div<{ width: string }>`
  color: ${colors.PRIMARY_TEXT};
  font-size: 12px;
  width: ${({ width }) => width};
`;

export default TableCell;
