import { handleActions } from 'redux-actions';

const initialValue = {
  data: null,
  dates: null,
  dateRange: null,
};

const statsReducer = handleActions(
  {
    STATS_FETCH_COMPLETED: (state, { payload }) => {
      return {
        ...state,
        data: payload.data,
        dates: payload.dates,
        dateRange: payload.dateRange,
      };
    },
  },
  initialValue
);

export default statsReducer;
