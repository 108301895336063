import identity from 'ramda/src/identity';
import React from 'react';

import FormikNumber, { InputMode } from './FormikNumber';

interface IFormikCurrency {
  label: string;
  name: string;
  inputMode?: InputMode;
  warning?: boolean;
}

const FormikCurrency: React.FunctionComponent<IFormikCurrency> = ({
  label,
  name,
  inputMode = 'text',
  warning = false,
}) => (
  <FormikNumber<number, number>
    formatValue={identity}
    decimalScale={2}
    fixedDecimalScale={true}
    label={label}
    name={name}
    parseValue={value => Number.parseFloat(value.replace(/[,$]/g, ''))}
    prefix={'$ '}
    thousandSeparator={true}
    allowNegative={false}
    warning={warning}
    inputMode={inputMode}
  />
);

export default FormikCurrency;
