import { combineReducers } from 'redux';

import authActions from '../actions/auth';
import applicationDetails from './applicationDetails';
import audit from './audit';
import auth from './auth';
import automaticProducts from './automaticProducts';
import banks from './banks';
import clientUsers from './clientUsers';
import commissions from './commissions';
import customerSupport from './customerSupport';
import deviceData from './deviceData';
import documentUpdatingIndicators from './documentUpdatingIndicators';
import documentUpload from './documentUpload';
import forgotPassword from './forgotPassword';
import idVerification from './idVerification';
import invoices from './invoices';
import loaders from './loaders';
import newApplication from './newApplication';
import offerActivation from './offerActivation';
import offerCounts from './offerCounts';
import offerDocuments from './offerDocuments';
import offerMessages from './offerMessages';
import offers from './offers';
import priceQuote from './priceQuote';
import grantedProducts from './products';
import productsManufacturers from './productsManufacturers';
import profileSettings from './profileSettings';
import promoter from './promoter';
import promoterProducts from './promoterProducts';
import promotersClients from './promotersClients';
import requestRisk from './requestRisk';
import requestsOffered from './requests';
import sendApplication from './sendApplicationToClient';
import stats from './stats';
import toastNotifications from './toastNotifications';
import userManagement from './userManagement';
import vehicleManufacturers from './vehicleManufacturers';
import vehicleModels from './vehicleModels';

const rootReducer = combineReducers({
  auth,
  customerSupport,
  deviceData,
  documentUpdatingIndicators,
  documentUpload,
  entities: combineReducers({
    applicationDetails,
    audit,
    automaticProducts,
    banks,
    clientUsers,
    commissions,
    grantedProducts,
    idVerification,
    invoices,
    offers,
    offerDocuments,
    offerMessages,
    priceQuote,
    productsManufacturers,
    profileSettings,
    promoter,
    promotersClients,
    promoterProducts,
    requestsOffered,
    requestRisk,
    userManagement,
    vehicleManufacturers,
    vehicleModels,
  }),
  forgotPassword,
  loaders,
  newApplication,
  offerActivation,
  offerCounts,
  sendApplication,
  stats,
  toastNotifications,
});

const proxiedRootReducer = (state, action) => {
  if (
    action.type === authActions.types.AUTH_LOGOUT.COMPLETED ||
    action.type === authActions.types.AUTH_SESSION_EXPIRED
  ) {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

export default proxiedRootReducer;
