import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const STATS_FETCH = createAsyncFlowTypes('STATS_FETCH');

const fetchStats = createAsyncFlowCreators(STATS_FETCH);

export default {
  types: {
    STATS_FETCH,
  },
  creators: {
    fetchStats,
  },
};
