import isEmpty from 'ramda/src/isEmpty';
import prop from 'ramda/src/prop';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import promoterActions from '../../actions/promoter';
import colors from '../../constants/colors';
import grammar from '../../constants/grammar';
import Border from '../Border';
import Card from '../Card';
import LoadingIndicator from '../LoadingIndicator';
import Table, { TableCell } from '../SmartTable';

const { REACT_APP_PUBLIC_ASSETS_URL } = process.env;

const TABLE_HEADERS_PRODUCT = ['Producto', 'Marcas', 'Otorgantes'];

const CustomTableCell = styled(TableCell)<{ height: number }>`
  padding: 12px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  grid-row-gap: 8px;
  height: ${props => `${props.height}px`};
  min-height: 40px;
`;

const DataField = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 1.57;
  padding: 0 24px;
  min-height: 60px;
  word-break: break-word;
`;

const FieldName = styled.div`
  font-weight: bold;
  width: 40%;
`;

const FieldValue = styled.div`
  padding: 12px 0;
  padding-left: 8px;
  width: 60%;
`;

const Grid = styled.div`
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: 80px auto 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    'logo . .'
    'details admin payment'
    'details products products';
`;

const GridArea = styled.div<{ name: string }>`
  grid-area: ${prop('name')};
  height: 100%;
  width: 100%;
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const PromoterLogo = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

const PromoterLogoContainer = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 #eaedf3;
  display: flex;
  justify-content: center;
  height: 100%;
  padding: 16px 0;
`;

interface IProductType {
  productType: string;
  fee: number;
}

interface IBankData {
  name: string;
}

interface IPromoterBank {
  productType: string;
  bank: IBankData;
}

interface IPromoterAdmin {
  name: string;
  firstSurname: string;
  secondSurname: string;
  email: string;
  phoneNumber: string;
}

interface IManufacturer {
  name: string;
}

interface IPromoterManufacturer {
  vehicleType: string;
  manufacturer: IManufacturer;
}

interface IPromoter {
  name: string;
  legalName: string;
  rfc: string;
  street: string;
  streetNumber: string;
  suiteNumber: string;
  postalCode: string;
  neighborhood: string;
  municipality: string;
  city: string;
  state: string;
  country: string;
  phoneNumber: string;
  logo: string;
  accountBank: string;
  accountNumber: string;
  accountClabe: string;
  productTypes: IProductType[];
  promoterBanks: IPromoterBank[];
  promoterManufacturers: IPromoterManufacturer[];
}

interface IPromoterData {
  promoter: IPromoter;
  promoterAdmin: IPromoterAdmin;
}

interface IPromoterProfileViewProps {
  promoterId: number;
  promoterData: IPromoterData;
  fetchPromoterInfo: Function;
  fetchingPromoterData: boolean;
}

interface IProductElement {
  name: string;
  brands: string[];
  banks: string[];
}

const RowComponent = (product: IProductElement) => {
  const rowHeight = Math.max(
    14 * product.brands.length + 8 * (product.brands.length - 1) + 24,
    14 * product.banks.length + 8 * (product.banks.length - 1) + 24
  );
  return [
    <CustomTableCell key={0} height={rowHeight}>
      {grammar[product.name]}
    </CustomTableCell>,
    <CustomTableCell key={2} height={rowHeight}>
      {product.brands.map((brand, i) => (
        <span key={i}>{brand}</span>
      ))}
    </CustomTableCell>,
    <CustomTableCell key={3} height={rowHeight}>
      {product.banks.map((bank, i) => (
        <span key={i}>{bank}</span>
      ))}
    </CustomTableCell>,
  ];
};

const PromoterProfileView: React.FunctionComponent<IPromoterProfileViewProps> = ({
  promoterId,
  promoterData,
  fetchPromoterInfo,
  fetchingPromoterData,
}) => {
  useEffect(() => {
    fetchPromoterInfo({ promoterId });
  }, [fetchPromoterInfo, promoterId]);

  if (fetchingPromoterData || isEmpty(promoterData)) {
    return (
      <LoadingIndicatorContainer>
        <LoadingIndicator />
      </LoadingIndicatorContainer>
    );
  }

  const fullName = `${promoterData.promoterAdmin.name} ${promoterData.promoterAdmin.firstSurname} ${promoterData.promoterAdmin.secondSurname}`;

  const logoURL = `${REACT_APP_PUBLIC_ASSETS_URL}${promoterData.promoter.logo}`;

  const address = `${promoterData.promoter.street} ${promoterData.promoter.streetNumber}, ${promoterData.promoter.neighborhood} CP ${promoterData.promoter.postalCode} ${promoterData.promoter.municipality}, ${promoterData.promoter.state}, ${promoterData.promoter.country}`;

  const reduceBrands = (
    result: string[],
    promoterManufacturer: IPromoterManufacturer,
    productType: string
  ) => {
    if (
      (productType === 'motorcycle_finance' &&
        promoterManufacturer.vehicleType === 'motorcycle') ||
      (productType === 'car_finance' &&
        promoterManufacturer.vehicleType === 'car')
    ) {
      result.push(promoterManufacturer.manufacturer.name);
    }
    return result;
  };

  const reduceBanks = (
    result: string[],
    promoterBank: IPromoterBank,
    productType: string
  ) => {
    if (productType === promoterBank.productType) {
      result.push(promoterBank.bank.name);
    }
    return result;
  };

  const productsArray: IProductElement[] = promoterData.promoter.productTypes.map(
    product => {
      return {
        name: product.productType,
        brands: promoterData.promoter.promoterManufacturers.reduce(
          (result: string[], promoterManufacturer: IPromoterManufacturer) =>
            reduceBrands(result, promoterManufacturer, product.productType),
          []
        ),
        banks: promoterData.promoter.promoterBanks.reduce(
          (result: string[], promoterBank: IPromoterBank) =>
            reduceBanks(result, promoterBank, product.productType),
          []
        ),
      };
    }
  );

  return (
    <Grid>
      <GridArea name={'logo'}>
        <PromoterLogoContainer>
          <PromoterLogo src={logoURL} />
        </PromoterLogoContainer>
      </GridArea>
      <GridArea name={'details'}>
        <Card header={'Detalles del comercio'}>
          <DataField>
            <FieldName>Nombre comercial</FieldName>
            <FieldValue>{promoterData.promoter.name}</FieldValue>
          </DataField>
          <Border />
          <DataField>
            <FieldName>Razón social</FieldName>
            <FieldValue>{promoterData.promoter.legalName}</FieldValue>
          </DataField>
          <Border />
          <DataField>
            <FieldName>RFC</FieldName>
            <FieldValue>{promoterData.promoter.rfc}</FieldValue>
          </DataField>
          <Border />
          <DataField>
            <FieldName>Dirección</FieldName>
            <FieldValue>{address}</FieldValue>
          </DataField>
          <Border />
          <DataField>
            <FieldName>Teléfono</FieldName>
            <FieldValue>{promoterData.promoter.phoneNumber}</FieldValue>
          </DataField>
        </Card>
      </GridArea>
      <GridArea name={'admin'}>
        <Card header={'Datos del administrador'}>
          <DataField>
            <FieldName>Nombre</FieldName>
            <FieldValue>{fullName}</FieldValue>
          </DataField>
          <Border />
          <DataField>
            <FieldName>Email</FieldName>
            <FieldValue>{promoterData.promoterAdmin.email}</FieldValue>
          </DataField>
          <Border />
          <DataField>
            <FieldName>Teléfono</FieldName>
            <FieldValue>{promoterData.promoterAdmin.phoneNumber}</FieldValue>
          </DataField>
        </Card>
      </GridArea>
      <GridArea name={'payment'}>
        <Card header={'Datos de pago'}>
          <DataField>
            <FieldName>Institución</FieldName>
            <FieldValue>{promoterData.promoter.accountBank}</FieldValue>
          </DataField>
          <Border />
          <DataField>
            <FieldName>Cuenta</FieldName>
            <FieldValue>{promoterData.promoter.accountNumber}</FieldValue>
          </DataField>
          <Border />
          <DataField>
            <FieldName>CLABE</FieldName>
            <FieldValue>{promoterData.promoter.accountClabe}</FieldValue>
          </DataField>
        </Card>
      </GridArea>
      <GridArea name={'products'}>
        <Card header={'Productos'}>
          <Table
            data={productsArray}
            headers={TABLE_HEADERS_PRODUCT}
            gridColumns={'repeat(3, 1fr)'}
            renderRow={RowComponent}
          />
        </Card>
      </GridArea>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  const { userData }: { userData: IPromoterUserData } = state.auth;

  return {
    promoterId: userData.organization.promoterId,
    promoterData: state.entities.promoter,
    fetchingPromoterData: state.loaders.fetchingPromoterData,
  };
};

const creators = {
  fetchPromoterInfo: promoterActions.creators.fetchPromoterInfo.request,
};

export default connect(mapStateToProps, creators)(PromoterProfileView);
