import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const PROMOTERS_INFO_FETCH = createAsyncFlowTypes('PROMOTERS_INFO_FETCH');

const fetchPromoterInfo = createAsyncFlowCreators(PROMOTERS_INFO_FETCH);

export default {
  types: {
    PROMOTERS_INFO_FETCH,
  },
  creators: {
    fetchPromoterInfo,
  },
};
