import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 40.5799 35.751202';

const ProductsIcon = ({ fill }: { fill: string }) => (
  <g transform="translate(-133.7165,-145.6191)">
    <path
      fill={fill}
      d="m 172.875,177.573 -2.9537,-2e-4 v -30.246 c 0,-0.943 -0.7647,-1.7077 -1.7078,-1.7077 h -28.1758 c -0.943,0 -1.7077,0.7647 -1.7077,1.7077 v 30.246 l -2.9537,1e-4 c -0.943,0 -1.6598,1.0613 -1.6598,2.0044 0,0.9431 0.7168,1.7929 1.6598,1.7929 l 37.4987,10e-5 c 0.943,0 1.4214,-0.9284 1.4214,-1.8715 0,-0.9429 -0.4784,-1.9258 -1.4214,-1.9258 z m -31.1297,-28.5386 h 24.7607 v 28.5384 h -24.7607 z"
    />
    <path
      fill={fill}
      d="m 151.308,150.54 h -5.6352 c -0.8017,0 -1.4514,0.6501 -1.4514,1.4516 v 5.635 c 0,0.8015 0.6499,1.4514 1.4514,1.4514 l 5.6352,1e-4 c 0.8017,0 1.4514,-0.6499 1.4514,-1.4515 v -5.635 c 0,-0.8017 -0.6497,-1.4516 -1.4514,-1.4516 z m -1.4516,5.6352 h -2.7322 v -2.7322 h 2.7322 z"
    />
    <path
      fill={fill}
      d="m 162.579,150.54 h -5.6353 c -0.8016,0 -1.4514,0.6501 -1.4514,1.4516 v 5.635 c 0,0.8015 0.6499,1.4514 1.4514,1.4514 l 5.6353,1e-4 c 0.8017,0 1.4514,-0.6499 1.4514,-1.4515 v -5.635 c 0,-0.8017 -0.6499,-1.4516 -1.4514,-1.4516 z m -1.4516,5.6352 h -2.7321 v -2.7322 h 2.7321 z"
    />
    <path
      fill={fill}
      d="m 151.308,161.81 h -5.6352 c -0.8017,0 -1.4514,0.6501 -1.4514,1.4516 v 5.635 c 0,0.8017 0.6499,1.4514 1.4514,1.4514 l 5.6352,10e-5 c 0.8017,0 1.4514,-0.6499 1.4514,-1.4514 v -5.6351 c 0,-0.8017 -0.6497,-1.4516 -1.4514,-1.4516 z m -1.4516,5.6352 h -2.7322 v -2.7322 h 2.7322 z"
    />
    <path
      fill={fill}
      d="m 162.579,161.81 h -5.6353 c -0.8016,0 -1.4514,0.6501 -1.4514,1.4516 v 5.635 c 0,0.8017 0.6499,1.4514 1.4514,1.4514 l 5.6353,10e-5 c 0.8017,0 1.4514,-0.6499 1.4514,-1.4514 v -5.6351 c 0,-0.8017 -0.6499,-1.4516 -1.4514,-1.4516 z m -1.4516,5.6352 h -2.7321 v -2.7322 h 2.7321 z"
    />
  </g>
);

export default BaseIcon(ProductsIcon, viewBox);
