import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import colors from '../../constants/colors';

const Container = styled.div`
  border-bottom: 1px solid ${colors.BORDER};
  box-sizing: border-box;
  height: 72px;
  margin-left: 32px;
  margin-right: 24px;
  margin-top: 24px;
  width: calc(100% - 56px);
`;

const TitleContainer = styled.div`
  color: ${colors.DUSK50};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
`;

const NameContainer = styled.div`
  color: ${colors.PRIMARY_TEXT};
  margin-top: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.75;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const getOrganizationTitle = (userData: IUserData) => {
  switch (userData.type) {
    case 'bank':
      return 'Institución';
    case 'promoter':
      return 'Comercio';
    case 'creditus':
      return 'Creditus';
    case 'client':
      return 'Bienvenido';
    default:
      return '';
  }
};

const getOrganizationName = (userData: IUserData) => {
  switch (userData.type) {
    case 'bank':
      return userData.organization.bank.name;
    case 'promoter':
      return userData.organization.promoter.name;
    case 'creditus':
      return 'Administrador Creditus';
    case 'client':
      return `${userData.user.name} ${userData.user.firstSurname}`;
    default:
      return '';
  }
};

interface IOrganizationInfoStateProps {
  userData: IUserData;
}

const OrganizationInfo: React.FunctionComponent<IOrganizationInfoStateProps> = ({
  userData,
}) => (
  <Container>
    <TitleContainer>{getOrganizationTitle(userData)}</TitleContainer>
    <NameContainer>{getOrganizationName(userData)}</NameContainer>
  </Container>
);

const mapStateToProps = (state: any) => ({
  userData: state.auth.userData,
});

export default connect(mapStateToProps)(OrganizationInfo);
