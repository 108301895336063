import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const FETCH_BIOMETRIC_VERIFICATIONS = createAsyncFlowTypes(
  'FETCH_BIOMETRIC_VERIFICATIONS'
);
const CREATE_NEW_VALIDATED_DATA = createAsyncFlowTypes(
  'CREATE_NEW_VALIDATED_DATA'
);
const fetchBiometricVerifications = createAsyncFlowCreators(
  FETCH_BIOMETRIC_VERIFICATIONS
);
const createNewValidatedData = createAsyncFlowCreators(
  CREATE_NEW_VALIDATED_DATA
);

export default {
  types: {
    FETCH_BIOMETRIC_VERIFICATIONS,
    CREATE_NEW_VALIDATED_DATA,
  },
  creators: {
    fetchBiometricVerifications,
    createNewValidatedData,
  },
};
