import colors from '../constants/colors';

export const translateCreditHistoryCreditType: {
  [key: string]: string;
} = {
  AA: 'Arrendamiento Automotriz',
  HB: 'Hipotecario Bancario',
  AB: 'Automotriz Bancario',
  HE: 'Préstamo Tipo Home Equity',
  AE: 'Física Actividad Empresarial',
  HV: 'Hipotecario O Vivienda',
  AM: 'Aparatos/Muebles',
  LC: 'Línea De Crédito',
  AR: 'Arrendamiento',
  MC: 'Mejoras A La Casa',
  AV: 'Aviación',
  NG: 'Préstamo No Garantizado',
  BC: 'Banca Comunal',
  PB: 'Préstamo Personal Bancario',
  BL: 'Bote/Lancha',
  PC: 'Procampo',
  BR: 'Bienes Raíces',
  PE: 'Préstamo Para Estudiante',
  CA: 'Compra De Automóvil',
  PG: 'Préstamo Garantizado',
  CC: 'Crédito Al Consumo',
  PQ: 'Préstamo Quirografario',
  CF: 'Crédito Fiscal',
  PM: 'Préstamo Empresarial',
  CO: 'Consolidación',
  PN: 'Préstamo de Nomina',
  CP: 'Crédito Personal Al Consumo',
  PP: 'Préstamo Personal',
  ED: 'EditorialSHSegunda Hipoteca',
  EQ: 'Equipo',
  TC: 'Tarjeta De Crédito',
  FF: 'Fondeo Fira',
  TD: 'Tarjeta Departamental',
  FI: 'FianzaTGTarjeta Garantizada',
  FT: 'Factoraje',
  TS: 'Tarjeta De Servicios',
  GS: 'Grupo Solidario',
  VR: 'Vehículo Recreativo',
  OT: 'Otros',
  NC: 'Desconocido',
};

export const getScoreText = (ficoScore: number) => {
  if (ficoScore >= 680) {
    return 'Bueno';
  } else if (ficoScore >= 560) {
    return 'Regular';
  } else if (ficoScore >= 300) {
    return 'Malo';
  } else {
    return 'Sin historial';
  }
};

export const getScoreColor = (ficoScore: number) => {
  if (ficoScore >= 680) {
    return colors.GREEN;
  } else if (ficoScore >= 560) {
    return colors.ORANGE;
  } else if (ficoScore >= 300) {
    return colors.RED;
  } else {
    return colors.SECONDARY_TEXT;
  }
};

export const getOverdueAmountColor = (overdueAmount: number) => {
  if (overdueAmount >= 5000) {
    return colors.RED;
  } else if (overdueAmount > 0) {
    return colors.ORANGE;
  } else {
    return colors.SECONDARY_TEXT;
  }
};
