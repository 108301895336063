import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import DayPickerInput from '../DayPickerInput';

const DashboardDateRangeControlContainer = styled.div`
  align-items: center;
  color: ${colors.PRIMARY_TEXT};
  display: flex;
  margin-right: 48px;

  & input {
    width: 136px;
  }

  & > div {
    margin-right: 8px;
  }
`;

const DashboardDateRangeControlText = styled.div`
  font-size: 14px;
`;

interface IDashboardDateRangeControl {
  startDate: Date;
  endDate: Date;
  onDateRangeChanged: (startDate: Date, endDate: Date) => void;
}

const DashboardDateRangeControl: React.FunctionComponent<IDashboardDateRangeControl> = ({
  startDate,
  endDate,
  onDateRangeChanged,
}) => {
  return (
    <DashboardDateRangeControlContainer>
      <DashboardDateRangeControlText>
        Periodo personalizado
      </DashboardDateRangeControlText>
      <DashboardDateRangeControlText>De:</DashboardDateRangeControlText>
      <div>
        <DayPickerInput
          label=""
          value={startDate}
          onChange={value => onDateRangeChanged(value, endDate)}
        />
      </div>
      <DashboardDateRangeControlText>A:</DashboardDateRangeControlText>
      <div>
        <DayPickerInput
          label=""
          value={endDate}
          onChange={value => onDateRangeChanged(startDate, value)}
        />
      </div>
    </DashboardDateRangeControlContainer>
  );
};

export default DashboardDateRangeControl;
