import styled from 'styled-components';

import colors from '../../constants/colors';

const CreditusScore = styled.div`
  align-items: center;
  background-color: ${colors.GREEN};
  border-radius: 4px;
  color: ${colors.WHITE};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 22px;
  justify-content: center;
  text-align: center;
  width: 88px;
`;

export default CreditusScore;
