import { handleActions } from 'redux-actions';

const initialState = {
  userAuthenticated: false,
  userData: null,
  isClient: null,
  phoneNumber: null,
};

const mapProfileToUserType = profile => {
  switch (profile) {
    case 1:
      return 'bank';
    case 2:
      return 'promoter';
    case 5:
      return 'creditus';
    default:
      return 'client';
  }
};

const patchUserdataWithType = userData => ({
  ...userData,
  type: mapProfileToUserType(userData.user.profile),
});

const authReducer = handleActions(
  {
    GET_LOGIN_TYPE_COMPLETED: (state, action) => ({
      ...state,
      isClient: action.payload.isClient,
      phoneNumber: action.payload.phoneNumber
        ? action.payload.phoneNumber
        : null,
    }),
    AUTH_SESSION_VALID: (state, action) => {
      return { ...state, userAuthenticated: true };
    },
    AUTH_LOGIN_COMPLETED: (state, action) => {
      return { ...state, userAuthenticated: true };
    },
    AUTH_SESSION_NOT_FOUND: (state, action) => {
      return { ...state, userAuthenticated: false };
    },
    AUTH_USER_DATA_FETCH_COMPLETED: (state, action) => {
      return { ...state, userData: patchUserdataWithType(action.payload) };
    },
  },
  initialState
);

export default authReducer;
