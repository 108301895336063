import not from 'ramda/src/not';
import { call, put, takeLatest } from 'redux-saga/effects';

import passwordChangeActions from '../actions/passwordChange';
import toastActions from '../actions/toastNotifications';
import {
  buildErrorToast,
  buildSuccessToast,
} from '../utils/toastNotifications';
import runner from './runners';

const getPasswordChangeToast = error => {
  const dismissIn = 5000;

  if (not(error)) {
    return buildSuccessToast(
      'El cambio de contraseña fue realizado con éxito.',
      dismissIn
    );
  } else if (error) {
    const { status } = error.response;
    const errorToastText = (() => {
      if (status === 409) {
        return 'La contraseña actual no es correcta, por favor intenta de nuevo.';
      } else if (status === 422) {
        return 'La nueva contraseña no puede ser igual a la contraseña actual.';
      } else {
        return 'No pudo realizarse el cambio de contraseña. Contacta a soporte si el problema persiste.';
      }
    })();

    return buildErrorToast(errorToastText, dismissIn);
  }
};

function* changePassword(action) {
  const { payload } = action;

  const { error } = yield call(
    runner,
    passwordChangeActions.creators.changePassword,
    {
      method: 'PATCH',
      url: '/api/account/me/password',
      payload,
    },
    {
      snakeCase: false,
    }
  );

  yield put(toastActions.creators.showToast(getPasswordChangeToast(error)));
}

function* watchChangePasswordRequested() {
  yield takeLatest(
    passwordChangeActions.types.PASSWORD_CHANGE.REQUESTED,
    changePassword
  );
}

export default {
  watchChangePasswordRequested,
};
