import format from 'date-fns/format';
import { es } from 'date-fns/locale';
import startOfMonth from 'date-fns/startOfMonth';
import subMonths from 'date-fns/subMonths';
import React from 'react';
import styled from 'styled-components';

import f from '../../constants/formatters';
import Dropdown from '../Dropdown';
import { Option } from '../Dropdown/Dropdown';
import ViewHeader from '../ViewHeader';

const InvoicesDateControlText = styled.div`
  font-size: 14px;
  margin-right: 8px;
`;

const InvoicesDateControlContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const InvoicesDateControlDropdownContainer = styled.div`
  width: 160px;
`;

const getMonthOptions = (): Option[] => {
  const options: Option[] = [];

  for (let i = 0; i < 12; i += 1) {
    const date = subMonths(startOfMonth(new Date()), i);
    options.push({
      text: f.capitalize(format(date, 'LLLL yy', { locale: es })),
      value: date.toISOString(),
    });
  }

  return options;
};

interface IInvoiceViewHeader {
  onMonthChange(date: Date): void;
  selectedMonth: Date;
  title: string;
}

const InvoicesViewHeader: React.FunctionComponent<IInvoiceViewHeader> = ({
  onMonthChange,
  selectedMonth,
  title,
}) => {
  return (
    <ViewHeader title={title}>
      <InvoicesDateControlContainer>
        <InvoicesDateControlText>Periodo:</InvoicesDateControlText>
        <InvoicesDateControlDropdownContainer>
          <Dropdown
            onChange={option => {
              onMonthChange(new Date(option.value));
            }}
            options={getMonthOptions()}
            value={selectedMonth.toISOString()}
          />
        </InvoicesDateControlDropdownContainer>
      </InvoicesDateControlContainer>
    </ViewHeader>
  );
};

export default InvoicesViewHeader;
