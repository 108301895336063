import { createAction } from 'redux-actions';

import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const ACCOUNT_WITH_EMAIL_CHECK = createAsyncFlowTypes(
  'ACCOUNT_WITH_EMAIL_CHECK'
);
const SEND_PASSWORD_RECOVERY = createAsyncFlowTypes('SEND_PASSWORD_RECOVERY');
const RESET_PASSWORD = createAsyncFlowTypes('RESET_PASSWORD');
const RESET_PASSWORD_RECOVERY = 'RESET_PASSWORD_RECOVERY';

const checkAccountExistsWithEmail = createAsyncFlowCreators(
  ACCOUNT_WITH_EMAIL_CHECK
);
const sendPasswordRecovery = createAsyncFlowCreators(SEND_PASSWORD_RECOVERY);
const resetPassword = createAsyncFlowCreators(RESET_PASSWORD);
const resetPasswordRecovery = createAction(RESET_PASSWORD_RECOVERY);

export default {
  types: {
    ACCOUNT_WITH_EMAIL_CHECK,
    SEND_PASSWORD_RECOVERY,
    RESET_PASSWORD,
    RESET_PASSWORD_RECOVERY,
  },
  creators: {
    checkAccountExistsWithEmail,
    sendPasswordRecovery,
    resetPassword,
    resetPasswordRecovery,
  },
};
