import { Field, FieldProps } from 'formik';
import React from 'react';

import { applySelector, noop } from '../../utils/misc';
import Input from '../Input';
import { InputMode } from './FormikNumber';

interface IFormikText {
  disabled?: boolean;
  label: string;
  maxLength?: number;
  name: string;
  onBlurSideEffects?(e: React.FocusEvent<HTMLInputElement>): void;
  placeholder?: string;
  type?: string;
  upperCase?: boolean;
  tabIndex?: number;
  inputMode?: InputMode;
}

const FormikText: React.FunctionComponent<IFormikText> = ({
  disabled = false,
  label,
  maxLength,
  name,
  onBlurSideEffects = noop,
  placeholder,
  type = 'text',
  upperCase = false,
  tabIndex = undefined,
  inputMode = 'text',
}) => (
  <Field
    name={name}
    render={({ field, form }: FieldProps) => (
      <Input
        {...field}
        disabled={disabled}
        error={
          applySelector(form.touched, field.name) &&
          applySelector(form.errors, field.name)
        }
        label={label}
        maxLength={maxLength}
        withErrorMessage={true}
        onBlur={e => {
          field.onBlur(e);
          onBlurSideEffects(e);
        }}
        placeholder={placeholder}
        type={type}
        upperCase={upperCase}
        tabIndex={tabIndex}
        inputMode={inputMode}
      />
    )}
  />
);

export default FormikText;
