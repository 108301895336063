import axios from 'axios';
import { useEffect, useState } from 'react';

import client from '../sagas/client';

export interface ICountry {
  country: string;
  nationality: string;
  key: string;
}

const useFetchCountries = () => {
  const [countries, setCountries] = useState<ICountry[]>([]);

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    client
      .get('/api/countries', {
        cancelToken: source.token,
      })
      .then(response => {
        setCountries(response.data.data);
      })
      .catch(e => {
        if (!axios.isCancel(e)) {
          setCountries([]);
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  return countries;
};

export default useFetchCountries;
