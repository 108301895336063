import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 39.330842 42.109502';

const GrantedProductsIcon = ({ fill }: { fill: string }) => (
  <g transform="translate(-120.80622,-150.5395)">
    <path
      fill={fill}
      d="m 124.896,192.649 c -0.1665,-0.007 -0.2882,-0.0187 -0.408,-0.0374 -1.1304,-0.1591 -2.1335,-0.7467 -2.8203,-1.6582 -0.6869,-0.9114 -0.9769,-2.0362 -0.8198,-3.1666 l 3.7393,-22.1325 c 0.1628,-2.1298 1.8884,-3.7973 4.0275,-3.8834 0,0 23.714,0 23.7252,0 2.1317,0.0899 3.8553,1.7555 4.0181,3.8834 l 3.7318,22.0932 c 0.0225,0.1666 0.0356,0.2901 0.0412,0.4136 0.0599,1.1342 -0.3256,2.2271 -1.0892,3.0768 -0.7617,0.8478 -1.8098,1.3494 -2.9495,1.4093 l -31.1607,0.002 c -0.0131,0 -0.0243,0 -0.0356,0 z m 3.7917,-28.0727 c -0.7037,0.0356 -1.2633,0.6008 -1.3044,1.3213 -0.002,0.0505 -0.008,0.1029 -0.0169,0.1554 l -3.743,22.1624 c -0.0487,0.3556 0.0505,0.7337 0.2863,1.0481 0.2359,0.3125 0.5783,0.5146 0.9657,0.5689 0.0412,0.006 0.0843,0.009 0.1273,0.0112 l 31.0166,-0.002 c 0.3238,-0.0187 0.6793,-0.1946 0.9357,-0.4809 0.2602,-0.2901 0.393,-0.6644 0.3725,-1.0556 -0.002,-0.0467 -0.007,-0.0898 -0.0131,-0.131 L 153.577,166.053 c -0.007,-0.0525 -0.0132,-0.0974 -0.0169,-0.1442 -0.0393,-0.7298 -0.612,-1.2988 -1.3325,-1.3325 z"
    />
    <path
      fill={fill}
      d="m 147.371,159.068 c -0.6719,0 -1.2502,-0.4791 -1.3774,-1.1398 -0.5072,-2.6463 -2.8297,-4.5721 -5.5247,-4.5814 -2.6725,0.009 -4.9913,1.9239 -5.5097,4.5534 -0.1292,0.655 -0.7075,1.1322 -1.3756,1.1322 0,0 0,0 0,0 -0.0899,0 -0.1797,-0.009 -0.2714,-0.0262 -0.3687,-0.073 -0.6868,-0.2845 -0.8946,-0.5951 -0.2096,-0.3107 -0.2844,-0.685 -0.2114,-1.0537 0.7748,-3.9377 4.2502,-6.8048 8.2627,-6.8179 4.0406,0.0131 7.5216,2.899 8.2814,6.861 0.1441,0.7598 -0.3556,1.4972 -1.1154,1.6431 -0.0861,0.0169 -0.1759,0.0244 -0.2639,0.0244 z"
    />
    <polyline
      fill="none"
      stroke={fill}
      strokeWidth="3"
      points="133.579,177.084 137.464,181.721 147.363,172.698 "
    />
  </g>
);

export default BaseIcon(GrantedProductsIcon, viewBox);
