import { Formik, FormikProps } from 'formik';
import { isEmpty, isNil, values } from 'ramda';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import SendApplicationToClient from '../../actions/sendApplication';
import colors from '../../constants/colors';
import DownloadIcon from '../../resources/icons/Download';
import Success from '../../resources/success.png';
import Button from '../Button';
import {
  FormikCurrency,
  FormikDropdown,
  FormikRadio,
  FormikText,
} from '../FormikInputs';
import LoadingIndicator from '../LoadingIndicator';
import Modal from '../Modal';
import errors from '../ProfilingSectionsForms/errorMessages';
import contactErrors from '../ProfilingSuggestionsCards/errorMessages';

const LoadingState = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 176px;
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-bottom: 16px;
`;

const SuccessIcon = styled.img`
  height: 24px;
  margin-right: 8px;
`;

const ResponseMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ResponseMessageText = styled.div`
  display: flex;
  justify-content: center;
`;

const DescriptionText = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  text-align: justify;
  padding-bottom: 24px;
`;

const ButtonContainer = styled.div`
  margin: 0px 4px;
`;

const ControlContainer = styled.div`
  display: flex;
  height: 38px;
  justify-content: flex-end;
`;

const QRCodeContainer = styled.div`
  margin: auto;
  height: 230px;
  width: 230px;
`;

const DownloadButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    margin: 0 10%;
  }
`;

const DownloadIconContainer = styled.div`
  height: 24px;
  padding-top: 4px;
`;

interface IOption {
  text: string;
  value: number | string;
}

interface IVehicle {
  createdAt: Date;
  id: number;
  manufacturerId: number;
  model: string;
  updatedAt: Date;
  vehicleType: string;
  version: string;
}

interface IManufacturer {
  createdAt: Date;
  id: number;
  name: string;
  updatedAt: Date;
  vehicles: IVehicle[];
}

interface ISendApplicationForm {
  showLoading: boolean | null;
  linkSent: boolean | null;
  showModal: boolean;
  qrCode: string | null;
  promoterProducts: Record<string, any>;
  productsManufacturers: Record<string, any>;
  fetchingProductsManufacturers: boolean;
  formikProps: FormikProps<{
    contactOption: string;
    data: string;
    brand: number;
    vehicleModel: number;
    price: number;
    vehicleModelYear: number;
  }>;
  closeModal: () => void;
}

const SendApplicationForm: React.FunctionComponent<ISendApplicationForm> = ({
  showLoading,
  linkSent,
  showModal,
  formikProps,
  qrCode,
  promoterProducts,
  productsManufacturers,
  fetchingProductsManufacturers,
  closeModal,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [, setDropdownOpen] = useState(false);
  const brandOptions = (): IOption[] =>
    values(productsManufacturers).map((manufacturer: IManufacturer) => ({
      text: manufacturer.name,
      value: manufacturer.id,
    }));

  const vehicleModelOptions = (): IOption[] => {
    if (
      fetchingProductsManufacturers ||
      isNil(productsManufacturers) ||
      isEmpty(productsManufacturers) ||
      isEmpty(formikProps.values.brand) ||
      isNil(productsManufacturers[formikProps.values.brand])
    ) {
      return [];
    } else {
      return productsManufacturers[formikProps.values.brand].vehicles.map(
        (vehicle: IVehicle) => ({
          text: vehicle.model,
          value: vehicle.id,
        })
      );
    }
  };

  const newModelOptions = (): IOption[] => {
    const year = new Date().getFullYear();
    const newModelOptions = [
      { text: `${year - 1}`, value: `${year - 1}` },
      { text: `${year}`, value: `${year}` },
      { text: `${year + 1}`, value: `${year + 1}` },
    ];
    return newModelOptions;
  };

  const brandAndVersion = (
    <Fragment>
      {fetchingProductsManufacturers ? (
        <LoadingState>
          <LoadingIndicator />
        </LoadingState>
      ) : (
        <Fragment>
          <DescriptionText>Datos del producto 1/2</DescriptionText>
          <FormikDropdown
            label="MARCA"
            name="brand"
            options={brandOptions()}
            placeholder="Selecciona"
            onOpen={() => setDropdownOpen(true)}
            onClose={() => setDropdownOpen(false)}
          />
          <FormikDropdown
            label="VERSIÓN"
            name="vehicleModel"
            options={vehicleModelOptions()}
            placeholder="Selecciona"
            onOpen={() => setDropdownOpen(true)}
            onClose={() => setDropdownOpen(false)}
          />
        </Fragment>
      )}
    </Fragment>
  );

  const priceForm = (
    <Fragment>
      <DescriptionText>Datos del producto 2/2</DescriptionText>
      <FormikDropdown
        label="MODELO"
        name="vehicleModelYear"
        options={newModelOptions()}
        placeholder="Selecciona"
        onOpen={() => setDropdownOpen(true)}
        onClose={() => setDropdownOpen(false)}
      />
      <FormikCurrency label="VALOR" name="price" />
    </Fragment>
  );

  const contactForm = (
    <Fragment>
      <DescriptionText>
        ¿Cual medio deseas usar para enviar la liga de la aplicación?
      </DescriptionText>
      <FormikRadio
        label="MÉTODO DE CONTACTO"
        name="contactOption"
        options={[
          {
            text: 'Enviar por mensaje de texto',
            value: 'phoneNumber',
          },
          {
            text: 'Enviar por correo electrónico',
            value: 'email',
          },
          {
            text: 'Generar código QR',
            value: 'qrCode',
          },
        ]}
        alignment="vertical"
        height="120px"
      />
      {formikProps.values.contactOption !== 'qrCode' ? (
        <Fragment>
          <DescriptionText>
            {formikProps.values.contactOption === 'phoneNumber'
              ? 'Ingresa el número telefónico del cliente al que deseas enviarle la liga.'
              : 'Ingresa el correo electrónico del cliente al que deseas enviarle la liga.'}
          </DescriptionText>
          <FormikText
            label={
              formikProps.values.contactOption === 'email'
                ? 'CORREO ELECTRÓNICO'
                : 'NÚMERO TELEFÓNICO'
            }
            name="data"
          />
        </Fragment>
      ) : null}
    </Fragment>
  );

  const steps = [brandAndVersion, priceForm, contactForm];

  const renderContent = (): JSX.Element => {
    if (showLoading && isNil(linkSent) && isNil(qrCode)) {
      return (
        <LoadingIndicatorContainer>
          <LoadingIndicator />
        </LoadingIndicatorContainer>
      );
    } else if (!isNil(linkSent)) {
      return linkSent ? (
        <ResponseMessage>
          <ResponseMessageText>
            <SuccessIcon src={Success} />
            {qrCode
              ? 'QR generado correctamente'
              : 'Enlace enviado correctamente'}
          </ResponseMessageText>
          {qrCode ? (
            <Fragment>
              <QRCodeContainer>
                <img
                  src={qrCode}
                  alt="qrCode"
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              </QRCodeContainer>
              <DownloadButtonsContainer>
                <a href={qrCode} download="qrCode.png">
                  <DownloadIconContainer>
                    <DownloadIcon fill={colors.PRIMARY_TEXT} size={22} />
                  </DownloadIconContainer>
                </a>
              </DownloadButtonsContainer>
            </Fragment>
          ) : null}
        </ResponseMessage>
      ) : (
        <ResponseMessage>
          El enlace no pudo ser enviado correctamente
        </ResponseMessage>
      );
    } else {
      return steps[currentStep];
    }
  };

  const renderControls = (): JSX.Element => (
    <ControlContainer>
      <ButtonContainer>
        <Button
          variant="secondary"
          onClick={() => {
            if (currentStep > 0) {
              setCurrentStep(currentStep - 1);
            }
            if (!isNil(linkSent) || currentStep === 0) {
              formikProps.resetForm();
              setCurrentStep(0);
              closeModal();
            }
          }}
        >
          {!isNil(linkSent) || currentStep === 0 ? 'Cerrar' : 'Anterior'}
        </Button>
      </ButtonContainer>
      {isNil(linkSent) ? (
        <ButtonContainer>
          <Button
            variant={!showLoading ? 'primary' : 'inactive'}
            onClick={async () => {
              if (currentStep < steps.length - 1) {
                switch (currentStep) {
                  case 0:
                    if (
                      formikProps.touched.brand &&
                      formikProps.touched.vehicleModel &&
                      !formikProps.errors.brand &&
                      !formikProps.errors.vehicleModel
                    ) {
                      setCurrentStep(currentStep + 1);
                    } else {
                      /* this is a hacky way to get the error to show up on the form but the component is not a multiSectionForm
                      because multisection form renders its own controls and we wanted to use the ones from the modal */
                      formikProps.setTouched({
                        ...formikProps.touched,
                        brand: true,
                        vehicleModel: true,
                      });
                    }
                    break;
                  case 1:
                    if (
                      formikProps.touched.vehicleModelYear &&
                      formikProps.touched.price &&
                      !formikProps.errors.vehicleModelYear &&
                      !formikProps.errors.price
                    ) {
                      setCurrentStep(currentStep + 1);
                    } else {
                      formikProps.setTouched({
                        ...formikProps.touched,
                        price: true,
                        vehicleModelYear: true,
                      });
                    }
                    break;
                }
              } else {
                formikProps.submitForm();
              }
            }}
          >
            Siguiente
          </Button>
        </ButtonContainer>
      ) : null}
    </ControlContainer>
  );

  return (
    <Modal
      content={renderContent}
      controls={renderControls}
      header="Enviar aplicación al cliente"
      isOpen={showModal}
      onRequestClose={() => {
        formikProps.resetForm();
        setCurrentStep(0);
        closeModal();
      }}
      shouldCloseOnOverlayClick={false}
      showCloseIcon={false}
      width="400px"
    />
  );
};

const mapFormStateProps = (
  state: any
): {
  promoterProducts: Record<string, any>;
  productsManufacturers: Record<string, any>;
  fetchingProductsManufacturers: boolean;
} => ({
  promoterProducts: state.entities.promoterProducts,
  productsManufacturers: state.entities.productsManufacturers,
  fetchingProductsManufacturers: state.loaders.fetchingProductsManufacturers,
});

const ConnectedSendApplicationForm = connect(mapFormStateProps)(
  SendApplicationForm
);

const modalValidationSchema = Yup.object().shape({
  vehicleModel: Yup.number()
    .moreThan(0, errors.product.version.required)
    .required(errors.product.version.required),
  brand: Yup.number()
    .moreThan(0, errors.product.brand.required)
    .required(errors.product.brand.required),
  vehicleModelYear: Yup.number()
    .moreThan(0, errors.product.model.required)
    .required(errors.product.model.required),
  price: Yup.number()
    .typeError(errors.product.price.number)
    .moreThan(0, errors.product.price.moreThanZero)
    .required(errors.product.price.required),
  contactOption: Yup.string().required('El tipo de contacto es obligatorio'),
  data: Yup.string().when('contactOption', {
    is: 'phoneNumber',
    then: Yup.string()
      .matches(/^\d{10}$/, contactErrors.phoneNumber.matches)
      .required(contactErrors.phoneNumber.required),
    otherwise: Yup.string().when('contactOption', {
      is: 'email',
      then: Yup.string()
        .email(contactErrors.email.email)
        .required(contactErrors.email.required),
      otherwise: Yup.string().notRequired(),
    }),
  }),
});

interface ISendApplicationModal {
  showLoading: boolean | null;
  linkSent: boolean | null;
  showModal: boolean;
  qrCode: string | null;
  closeModal: () => void;
  sendApplicationToClient: Function;
}

const SendApplicationModal: React.FunctionComponent<ISendApplicationModal> = ({
  showLoading,
  linkSent,
  showModal,
  qrCode,
  closeModal,
  sendApplicationToClient,
}) => {
  return (
    <Formik
      initialValues={{
        contactOption: 'phoneNumber',
        data: '',
        brand: 0,
        vehicleModel: 0,
        price: 0,
        vehicleModelYear: 0,
      }}
      onSubmit={values => {
        sendApplicationToClient(values);
      }}
      render={formikProps => (
        <ConnectedSendApplicationForm
          closeModal={closeModal}
          formikProps={formikProps}
          showModal={showModal}
          showLoading={showLoading}
          linkSent={linkSent}
          qrCode={qrCode}
        />
      )}
      validationSchema={modalValidationSchema}
    />
  );
};

const mapStateProps = (
  state: any
): {
  showLoading: boolean | null;
  linkSent: boolean | null;
  qrCode: string | null;
} => ({
  showLoading: state.sendApplication.showLoading,
  linkSent: state.sendApplication.linkSent,
  qrCode: state.sendApplication.qrCode,
});

const creators = {
  sendApplicationToClient:
    SendApplicationToClient.creators.sendApplicationToClient.request,
};

export default connect(mapStateProps, creators)(SendApplicationModal);
