import axios from 'axios';

const applySelector = (object: any, selector: string): any => {
  if (!object || !selector) {
    return undefined;
  }

  const parts = selector.split('.');
  const regex = /\[[0-9]+\]$/;

  const operations: Array<string> = [];

  parts.forEach(part => {
    const match = regex.exec(part);

    if (match) {
      operations.push(part.substring(0, match.index));
      operations.push(part.substring(match.index + 1, part.length - 1));
    } else {
      operations.push(part);
    }
  });

  let result = object;

  for (let i = 0; i < operations.length; i += 1) {
    if (result === null) {
      return undefined;
    }

    result = result[operations[i]];

    if (result === undefined) {
      return undefined;
    }
  }

  return result;
};

const getAvatarLetters = (user: IUserData['user']): string => {
  if (user.firstSurname) {
    return `${user.name[0]}${user.firstSurname[0]}`.toUpperCase();
  }

  return `${user.name[0]}${user.name[1]}`.toUpperCase();
};

const getPostalCodeInfo = (postalCode: number | string): Promise<any> =>
  axios.get('https://cot.credipass.com.mx/services/cp', {
    method: 'GET',
    params: {
      cp: postalCode,
    },
  });

const noop = (): void => {
  // do nothing
};

const isEmail = (email: string): boolean => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};

const addSearchParamsToUrl = (url: string, params?: string[]): string => {
  const urlParams = new URLSearchParams(window.location.search);
  let newUrl = `${url}/?`;
  if (params) {
    Array.from(urlParams.entries()).forEach(entry => {
      if (!params.includes(entry[0])) {
        urlParams.delete(entry[0]);
      }
    });
  }
  newUrl += urlParams.toString();
  return newUrl;
};

const addSearchParamsToPayload = (
  payload: Record<any, any>,
  params?: string[]
): Record<any, any> => {
  const newPayload = { ...payload };
  const searchParams = new URLSearchParams(window.location.search);
  params
    ? params.forEach(param => {
        if (searchParams.has(param)) {
          newPayload[param] = searchParams.get(param);
        }
      })
    : Array.from(searchParams.entries()).forEach(entry => {
        newPayload[entry[0]] = entry[1];
      });
  return newPayload;
};

export {
  applySelector,
  getAvatarLetters,
  getPostalCodeInfo,
  isEmail,
  noop,
  addSearchParamsToUrl,
  addSearchParamsToPayload,
};
