import { Formik, FormikProps } from 'formik';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import verificationModalActions from '../../actions/verificationModal';
import colors from '../../constants/colors';
import Success from '../../resources/success.png';
import Button from '../Button';
import { FormikText } from '../FormikInputs';
import LoadingIndicator from '../LoadingIndicator';
import Modal from '../Modal';
import errorMsg from './errorMessages';

const ControlContainer = styled.div`
  display: flex;
  height: 38px;
  justify-content: flex-end;
`;

const DescriptionText = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  text-align: justify;
  padding-bottom: 24px;
`;

const InputContainer = styled.div`
  & input {
    font-family: 'Roboto Mono', monospace;
  }
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const MonoText = styled.span`
  font-family: 'Roboto Mono', monospace;
`;

const SuccessContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const SuccessIcon = styled.img`
  height: 24px;
  margin-right: 8px;
  display: flex;
`;

interface ICurpCheck {
  curp: string;
}

interface ICurpCheckForm {
  curp: string;
  closeModal: () => void;
  showModal: boolean;
  closeEnabled: boolean;
  obtainedCurpData: boolean;
  showLoading: boolean;
  formikProps: FormikProps<ICurpCheck>;
}

interface IVerificationModal {
  curp: string;
  getPrefillingData: Function;
  obtainedCurpData: boolean;
  waitingForPrefillingData: boolean;
  existentClientData: IUser;
}

const CurpCheckModalForm: React.FunctionComponent<ICurpCheckForm> = ({
  curp,
  closeModal,
  showModal,
  closeEnabled,
  obtainedCurpData,
  showLoading,
  formikProps,
}) => {
  const { setFieldValue } = formikProps;

  useEffect(() => {
    setFieldValue('curp', curp);
  }, [curp, setFieldValue]);

  const renderContent = () => {
    if (obtainedCurpData) {
      return (
        <SuccessContent>
          <SuccessIcon src={Success} />
          Verificación exitosa
        </SuccessContent>
      );
    } else if (showLoading) {
      return (
        <Fragment>
          <LoadingIndicatorContainer>
            <LoadingIndicator />
          </LoadingIndicatorContainer>
          <DescriptionText>
            Por favor espera mientras concluye la extracción de datos.
          </DescriptionText>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <DescriptionText>
            {isEmpty(curp)
              ? 'No pudimos detectar automáticamente tu CURP. Por favor escribela.'
              : 'Por favor confirma que esta es tu CURP.'}
          </DescriptionText>
          <InputContainer>
            <FormikText label="CURP" name="curp" />
          </InputContainer>
          {!isEmpty(curp) ? (
            <DescriptionText>
              Revisa que el número <MonoText>0</MonoText> y la letra{' '}
              <MonoText>O</MonoText> no se hayan intercambiado.
            </DescriptionText>
          ) : null}
        </Fragment>
      );
    }
  };

  return (
    <Modal
      content={() => renderContent()}
      controls={() => (
        <ControlContainer>
          {!(showLoading || obtainedCurpData) ? (
            <Button onClick={() => formikProps.submitForm()}>Continuar</Button>
          ) : null}
        </ControlContainer>
      )}
      header="Verificación de identidad"
      isOpen={showModal}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      showCloseIcon={closeEnabled}
      width={'400px'}
    />
  );
};

const VerificationModalCurp: React.FunctionComponent<IVerificationModal> = ({
  curp,
  getPrefillingData,
  obtainedCurpData,
  waitingForPrefillingData,
  existentClientData,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [closeEnabled, setCloseEnabled] = useState<boolean>(true);

  useEffect(() => {
    if (!isNil(curp) && isNil(existentClientData)) {
      setShowModal(true);
    }
  }, [curp, existentClientData]);

  const obtainCurpData = (values: ICurpCheck) => {
    setCloseEnabled(false);
    getPrefillingData({ curp: values.curp });
  };

  const curpRegex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

  return (
    <Formik
      initialValues={{ curp: curp }}
      onSubmit={values => obtainCurpData(values)}
      render={formikProps => (
        <CurpCheckModalForm
          closeModal={() => setShowModal(false)}
          closeEnabled={closeEnabled}
          formikProps={formikProps}
          curp={curp}
          showModal={showModal}
          obtainedCurpData={obtainedCurpData}
          showLoading={waitingForPrefillingData}
        />
      )}
      validationSchema={Yup.object().shape({
        curp: Yup.string()
          .matches(curpRegex, errorMsg.curp.matches)
          .required(errorMsg.curp.required),
      })}
    />
  );
};

const mapStateToProps = (state: any) => ({
  waitingForPrefillingData: state.loaders.waitingForPrefillingData,
  obtainedCurpData: !isNil(
    state.newApplication.verificationModal.prefillingData
  ),
  curp: state.newApplication.verificationModal.curp,
  existentClientData: state.newApplication.existentClient.clientAccount,
});

const creators = {
  getPrefillingData:
    verificationModalActions.creators.getPrefillingData.request,
};

export default connect(mapStateToProps, creators)(VerificationModalCurp);
