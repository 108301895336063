import identity from 'ramda/src/identity';
import React from 'react';

import FormikNumber, { InputMode } from './FormikNumber';

interface IFormikPhone {
  disabled?: boolean;
  label: string;
  name: string;
  inputMode?: InputMode;
}

const FormikPhone: React.FunctionComponent<IFormikPhone> = ({
  disabled,
  label,
  name,
  inputMode = 'text',
}) => (
  <FormikNumber<string, string>
    disabled={disabled}
    format={'(###) ###-####'}
    formatValue={identity}
    label={label}
    name={name}
    parseValue={value => value.replace(/[,-\s()]/g, '')}
    inputMode={inputMode}
  />
);

export default FormikPhone;
