import { handleActions } from 'redux-actions';

const initialState = {
  application: null,
  offers: null,
  rejectionReasons: null,
  creditHistoryData: null,
  canSubmit: true,
  banksContactInfo: null,
};

const applicationCreationReducer = handleActions(
  {
    APPLICATION_CREATION_REQUESTED: (state, { payload }) => ({
      ...state,
      canSubmit: false,
    }),
    APPLICATION_CREATION_COMPLETED: (state, { payload }) => {
      return {
        application: payload.application,
        offers: payload.offers,
        rejectionReasons: payload.rejectionReasons,
        creditHistoryData: payload.creditHistoryData,
        banksContactInfo: payload.banksContactInfo,
      };
    },
    APPLICATION_CREATION_FAILED: (state, { payload }) => ({
      ...state,
      canSubmit: true,
    }),
    CLEAR_NEW_APPLICATION: () => initialState,
  },
  initialState
);

export default applicationCreationReducer;
