import differenceInYears from 'date-fns/differenceInYears';

const legalAgeValidation = (value: Date) => {
  return Math.abs(differenceInYears(value, new Date())) >= 18;
};

const dateNotBiggerThanTodayValidation = (value: Date) => {
  return differenceInYears(value, new Date()) < 0;
};

export { dateNotBiggerThanTodayValidation, legalAgeValidation };
