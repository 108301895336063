import { FormikProps } from 'formik';
import isNil from 'ramda/src/isNil';
import { useEffect } from 'react';

import { applySelector } from '../utils/misc';

const usePostalCodeRelatedFieldsAutoFill = (
  formikProps: FormikProps<any>,
  options: any,
  fieldGroupName?: string
) => {
  const { setFieldValue } = formikProps;
  const suffixedFieldGroupName = isNil(fieldGroupName)
    ? ''
    : `${fieldGroupName}.`;
  const neighborhood = applySelector(
    formikProps.values,
    `${suffixedFieldGroupName}neighborhood`
  );

  useEffect(() => {
    if (neighborhood) {
      const option = options.find(
        (option: any) => option.colonia === neighborhood
      );
      if (option) {
        setFieldValue(
          `${suffixedFieldGroupName}municipality`,
          option.municipio
        );
        setFieldValue(`${suffixedFieldGroupName}city`, option.ciudad);
        setFieldValue(`${suffixedFieldGroupName}state`, option.estado);
        setFieldValue(`${suffixedFieldGroupName}country`, 'México');
      }
    }
  }, [neighborhood, options, setFieldValue, suffixedFieldGroupName]);
};

export default usePostalCodeRelatedFieldsAutoFill;
