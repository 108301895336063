import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import useUserData from '../../hooks/useUserData';
import {
  isBankUser,
  isCreditusUser,
  isPromoterUser,
} from '../../utils/permissions';
import BankInvoicesView from '../BankInvoicesView';
import CreditusPayableInvoicesView from '../CreditusPayableInvoicesView';
import CreditusReceivableInvoicesView from '../CreditusReceivableInvoicesView';
import PromoterInvoicesView from '../PromoterInvoicesView';

type InvoicesViewProps = RouteComponentProps<{
  type: 'payable' | 'receivable';
}>;

const InvoicesView: React.FunctionComponent<InvoicesViewProps> = ({
  match,
}) => {
  const userData = useUserData();

  const { type } = match.params;

  if (type === 'payable') {
    if (isBankUser(userData)) {
      return <BankInvoicesView />;
    } else if (isCreditusUser(userData)) {
      return <CreditusPayableInvoicesView />;
    }
  } else {
    if (isPromoterUser(userData)) {
      return <PromoterInvoicesView />;
    } else if (isCreditusUser(userData)) {
      return <CreditusReceivableInvoicesView />;
    }
  }

  return <Redirect to="/" />;
};

export default InvoicesView;
