import compareAsc from 'date-fns/compareAsc';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import values from 'ramda/src/values';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import promotersClientsActions from '../../actions/promotersClients';
import colors from '../../constants/colors';
import grammar from '../../constants/grammar';
import Card from '../Card';
import FilterSearch from '../FilterSearch';
import LoadingIndicator from '../LoadingIndicator';
import Table, { TableCell } from '../SmartTable';

const LoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 50vh;
  justify-content: center;
  width: 100%;
`;

const Link = styled.span`
  color: ${colors.HYPERLINK};
  text-decoration: underline;
  cursor: pointer;
`;

interface IPromoter {
  id: number;
  name: string;
  firstSurname: string;
}

interface IClient {
  id: number;
  name: string;
  firstSurname: string;
  phoneNumber: string;
  email: string;
  createdAt: string;
}

interface IEmployment {
  id: number;
  giroCompany: string;
}

interface IApplication {
  applicationId: number;
  employments: IEmployment[];
}

interface IComplianceComponent {
  id: number;
  applicationId: number;
  promoter: IPromoter;
  client: IClient;
  bestOfferStatus: string;
}

interface IClientsTable {
  clients: IComplianceComponent[];
  history: RouteComponentProps['history'];
}

interface IClientsView {
  clients: IComplianceComponent[];
  requestClients: Function;
  fetchingClients: boolean;
  history: RouteComponentProps['history'];
}

const ClientsTable: React.FunctionComponent<IClientsTable> = ({
  clients,
  history,
}) => {
  const sortedClients = clients.sort((a, b) => {
    if (isNil(a.client.createdAt) && isNil(b.client.createdAt)) {
      return 0;
    } else if (isNil(a.client.createdAt)) {
      return 1;
    } else if (isNil(b.client.createdAt)) {
      return -1;
    }

    const dateA = new Date(a.client.createdAt);
    const dateB = new Date(b.client.createdAt);
    return compareAsc(dateA, dateB) * -1;
  });

  return (
    <Table
      data={sortedClients}
      gridColumns={`repeat(7, 5% 25% 10% 15% 15% 15% 15%)`}
      headers={[
        'ID',
        'Nombre',
        'Teléfono',
        'Email',
        'Asesor',
        'Fecha de alta',
        'Estatus',
      ]}
      renderRow={clientComponent => {
        const promoterName = `${clientComponent.promoter.name} ${clientComponent.promoter.firstSurname}`;
        const date = isNil(clientComponent.client.createdAt)
          ? 'Fecha no disponible'
          : format(
              parse(
                clientComponent.client.createdAt,
                'yyyy-MM-dd HH:mm:ss',
                new Date()
              ),
              'dd/MM/yyyy'
            );
        const clientName = `${clientComponent.client.name} ${clientComponent.client.firstSurname}`;
        const applicationIdLink = (
          <Link
            onClick={() =>
              history.push(
                `/application-details/${clientComponent.applicationId}`
              )
            }
          >
            {clientComponent.applicationId}
          </Link>
        );
        return [
          <TableCell key={0}>
            {clientComponent.bestOfferStatus === 'noApplication'
              ? 'N/A'
              : applicationIdLink}
          </TableCell>,
          <TableCell key={1}>{clientName}</TableCell>,
          <TableCell key={2}>{clientComponent.client.phoneNumber}</TableCell>,
          <TableCell key={3}>{clientComponent.client.email}</TableCell>,
          <TableCell key={4}>{promoterName}</TableCell>,
          <TableCell key={5}>{date}</TableCell>,
          <TableCell key={6}>
            {grammar[clientComponent.bestOfferStatus]}
          </TableCell>,
        ];
      }}
    />
  );
};

const ClientsView: React.FunctionComponent<IClientsView> = ({
  clients,
  fetchingClients,
  history,
  requestClients,
}) => {
  useEffect(() => {
    requestClients();
  }, [requestClients]);

  const content = fetchingClients ? (
    <LoadingState>
      <LoadingIndicator />
    </LoadingState>
  ) : isEmpty(clients) ? (
    <LoadingState>Sin resultados.</LoadingState>
  ) : (
    <ClientsTable clients={clients} history={history} />
  );

  return (
    <Card
      controls={<FilterSearch action={requestClients} />}
      header="Solicitudes"
    >
      {content}
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  fetchingClients: state.loaders.fetchingPromotersClients,
  clients: values(state.entities.promotersClients),
});

const creators = {
  requestClients:
    promotersClientsActions.creators.fetchPromotersClients.request,
};

export default connect(mapStateToProps, creators)(ClientsView);
