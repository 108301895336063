import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';

const TriangleLeft = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 10px solid ${colors.AQUA};
  margin: auto 12px;
`;

const TriangleRight = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid ${colors.AQUA};
  margin: auto 12px;
`;

const ButtonContainer = styled.div`
  display: inline-flex;
  padding: 4px;
  cursor: pointer;
`;

const TextContainer = styled.div<{ variant: string }>`
  text-align: ${props => (props.variant === 'left' ? 'left' : 'right')};
`;

interface ISimpleButton {
  variant: string;
  text: string;
  onClick: () => any;
}

const SimpleButton: React.JSXElementConstructor<ISimpleButton> = ({
  variant,
  text,
  onClick,
}) => (
  <ButtonContainer onClick={onClick}>
    {variant === 'left' ? <TriangleLeft /> : null}
    <TextContainer variant={variant}>{text}</TextContainer>
    {variant === 'right' ? <TriangleRight /> : null}
  </ButtonContainer>
);

export default SimpleButton;
