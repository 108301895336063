import { delay } from 'redux-saga';
import { call, select, takeLatest } from 'redux-saga/effects';

import accountCreation from '../actions/accountCreation';
import authActions from '../actions/auth';
import continueApplicationActions from '../actions/continueApplication';
import existentClientActions from '../actions/existentClient';
import finalOfferActions from '../actions/finalOffers';
import idVerificationActions from '../actions/idVerification';
import offersActions from '../actions/offers';
import userManagementActions from '../actions/userManagement';
import verificationModalActions from '../actions/verificationModal';
import history from '../history';
import { addSearchParamsToUrl } from '../utils/misc';
import { isBankAnalyst } from '../utils/permissions';

function* redirectAfterFinalOfferCreated({ payload }) {
  const { originalAction } = payload.meta;
  const { offerId } = originalAction.payload;

  let route = '';
  const { userData } = yield select(state => state.auth);

  if (isBankAnalyst(userData)) {
    route = '/pipeline/analysis';
  } else {
    route = `/pipeline/offer/${offerId}`;
  }

  yield call(history.push, route);
}

function* redirectAfterFormalizationDocsCreated({ payload }) {
  const { originalAction } = payload.meta;
  const { offerId } = originalAction.payload;

  yield call(history.push, `/pipeline/offer/${offerId}`);
}

function* watchFinalOfferCreated() {
  yield takeLatest(
    finalOfferActions.types.FINAL_OFFER_CREATE.COMPLETED,
    redirectAfterFinalOfferCreated
  );
}

function* redirectAfterOfferActivated({ payload }) {
  yield call(history.push, '/pipeline/activated');
}

function* watchOfferActivated() {
  yield takeLatest(
    offersActions.types.OFFERS_ACTIVATE.COMPLETED,
    redirectAfterOfferActivated
  );
}

function* watchFormalizationDocsCreated() {
  yield takeLatest(
    finalOfferActions.types.FINAL_OFFER_CREATE_FORMALIZATION_DOCUMENTS
      .COMPLETED,
    redirectAfterFormalizationDocsCreated
  );
}

function* watchManualOfferCreated() {
  yield takeLatest(
    offersActions.types.OFFERS_MANUAL_CREATE.COMPLETED,
    function*() {
      yield delay(2000);
      yield call(history.goBack);
    }
  );
}

function* watchClientAccountVerified() {
  yield takeLatest(
    accountCreation.types.ACCOUNT_CREATION_CLIENT_VERIFICATION.COMPLETED,
    function*() {
      const url = addSearchParamsToUrl('/application/new');
      yield call(history.push, url);
    }
  );
}

function* watchVerificationModalDone() {
  yield takeLatest(
    [
      verificationModalActions.types.VERIFICATION_GET_PREFILLING_DATA.COMPLETED,
      verificationModalActions.types.VERIFICATION_GET_PREFILLING_DATA.FAILED,
    ],
    function*({ type, payload }) {
      if (
        type ===
          verificationModalActions.types.VERIFICATION_GET_PREFILLING_DATA
            .FAILED &&
        payload.response.status === 404
      ) {
        return;
      }

      yield delay(2500);
      const url = addSearchParamsToUrl('/application/account');
      yield call(history.push, url);
    }
  );
}

function* watchUserCreated() {
  yield takeLatest(
    userManagementActions.types.CREATE_USER.COMPLETED,
    function*() {
      yield call(history.push, '/users');
    }
  );
}

function* watchValidatedDataCreation() {
  yield takeLatest(
    idVerificationActions.types.CREATE_NEW_VALIDATED_DATA.COMPLETED,
    function*() {
      yield call(history.push, '/id-verification');
    }
  );
}

function* watchRedirectToApplicationStart() {
  yield takeLatest(
    continueApplicationActions.types.REDIRECT_TO_APPLICATION_START,
    function*() {
      const url = addSearchParamsToUrl('/application/continue');
      yield call(history.push, url);
    }
  );
}

function* watchStartProfiling() {
  yield takeLatest(
    continueApplicationActions.types.REDIRECT_TO_PROFILING_START,
    function*() {
      const url = addSearchParamsToUrl('/application/profiling');
      yield call(history.push, url);
    }
  );
}

function* watchStartAccountCreation() {
  yield takeLatest(
    continueApplicationActions.types.REDIRECT_TO_ACCOUNT_CREATION_START,
    function*() {
      const url = addSearchParamsToUrl('/application/account');
      yield call(history.push, url);
    }
  );
}

function* watchStartApplicationSection() {
  yield takeLatest(
    continueApplicationActions.types.REDIRECT_TO_APPLICATION_SECTIONS_START,
    function*() {
      const url = addSearchParamsToUrl('/application/new');
      yield call(history.push, url);
    }
  );
}

function* watchStartApplicationSectionExistentUser() {
  yield takeLatest(
    existentClientActions.types.VERIFY_NIP_EXISTENT_CLIENT.COMPLETED,
    function*() {
      const url = addSearchParamsToUrl('/application/new');
      yield call(history.push, url);
    }
  );
}

function* watchRedirectToOffered() {
  yield takeLatest(offersActions.types.REDIRECT_TO_OFFERED, function*() {
    yield delay(1000);
    yield call(history.push, '/pipeline/offered');
  });
}

function* watchLogoutCompleted() {
  yield takeLatest(authActions.types.AUTH_LOGOUT.COMPLETED, function*() {
    yield call(history.push, '/login');
  });
}

export default {
  watchClientAccountVerified,
  watchFinalOfferCreated,
  watchFormalizationDocsCreated,
  watchLogoutCompleted,
  watchManualOfferCreated,
  watchOfferActivated,
  watchRedirectToApplicationStart,
  watchRedirectToOffered,
  watchStartProfiling,
  watchStartAccountCreation,
  watchStartApplicationSection,
  watchStartApplicationSectionExistentUser,
  watchUserCreated,
  watchValidatedDataCreation,
  watchVerificationModalDone,
};
