type ResizeImageCallback = (resizedPhoto: File) => void;

const BASE_DIMENSION = 1600;

const calculateResizedDimensions = (
  image: HTMLImageElement
): [number, number] => {
  const width = image.width;
  const height = image.height;

  if (width <= BASE_DIMENSION && height <= BASE_DIMENSION) {
    return [width, height];
  }

  if (width > height) {
    const aspectRatio = width / height;
    return [BASE_DIMENSION, BASE_DIMENSION / aspectRatio];
  } else {
    const aspectRatio = height / width;
    return [BASE_DIMENSION / aspectRatio, BASE_DIMENSION];
  }
};

export const resizeImage = (
  photo: File,
  callback: ResizeImageCallback
): void => {
  const fileReader = new FileReader();
  const originalFileName = photo.name;

  fileReader.onload = event => {
    if (event === null || event.target === null) {
      return;
    }

    const result = (event.target as FileReader).result;
    if (result === null) {
      return;
    }

    const image = new Image();

    image.src = result.toString();
    image.onload = () => {
      const [outputWidth, outputHeight] = calculateResizedDimensions(image);

      const canvas = document.createElement('canvas');
      canvas.width = outputWidth;
      canvas.height = outputHeight;

      const context = canvas.getContext('2d');

      if (context) {
        context.drawImage(image, 0, 0, outputWidth, outputHeight);
        context.canvas.toBlob(
          blob => {
            if (blob) {
              const file = new File([blob], originalFileName, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              });
              callback(file);
            }
          },
          'image/jpeg',
          1
        );
      }
    };
  };

  fileReader.readAsDataURL(photo);
};
