import addMinutes from 'date-fns/addMinutes';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import banks from '../../actions/banks';
import offerActions from '../../actions/offers';
import colors from '../../constants/colors';
import f from '../../constants/formatters';
import grammar from '../../constants/grammar';
import { getOfferData } from '../../utils/offers';
import { isBankUser } from '../../utils/permissions';
import Button from '../Button';
import Chat from '../Chat';
import ExpandableCard from '../ExpandableCard';
import MultiSingleRowTable from '../MultiSingleRowTable';
import OfferDocuments from '../OfferDocuments';
import UpdateOfferVigencyModal from '../UpdateOfferVigencyModal';
import withUserData from '../UserDataHOC';

const SECONDARY_WHITE = '#F6F7F9';

const BackButtonContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const OfferDetailContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const ViewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const List = styled.ul`
  padding-inline-start: 16px;
`;

const Buttons = styled.div`
  display: flex;
`;

const DropdownButton = styled.div`
  background-image: linear-gradient(
    to top,
    ${SECONDARY_WHITE},
    ${colors.WHITE}
  );
  border: solid 1px ${colors.AQUA};
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05),
    inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
  box-sizing: border-box;
  color: ${colors.AQUA};
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.2px;
  text-align: center;
  padding: 7px 0px;
  width: 70px;
`;

const Dropdown = styled.div`
  position: relative;
`;

const DropDownContent = styled.div`
  display: none;
  position: absolute;
  right: 0;
  z-index: 1;

  ${Dropdown}:hover & {
    display: block;
  }
`;

const SPECIAL_MENU_BORDER_COLOR = 'rgb(216, 220, 230)';

const OptionsContainer = styled.div`
  margin-top: 5px;
  border-radius: 2px;
  border: 1px solid ${SPECIAL_MENU_BORDER_COLOR};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 ${colors.BACKGROUND};
  background-color: ${colors.WHITE};
`;

const Option = styled.div<{ disabled?: true }>`
  background-color: ${({ disabled }) =>
    disabled ? colors.BACKGROUND : colors.WHITE};
  color: ${({ disabled }) =>
    disabled ? colors.SECONDARY_TEXT : colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.57;
  padding: 9px 15px;
  width: 180px;

  &:hover {
    background-color: ${colors.BACKGROUND};
  }
`;

interface IDataPersonal {
  name: string;
  lastName: string;
  secondSurname: string;
}

interface IRequest {
  dataPersonals: IDataPersonal[];
}

interface IOfferRecordView {
  fetchOfferById: Function;
  offer: IOffer | null;
  match: any;
  history: RouteComponentProps['history'];
  userData: IUserData;
  clearBankUpdateVigencyModal: Function;
}

interface IOfferDetailProps {
  offer: IOffer;
}

interface IOfferDetailExpandedContentProps {
  offer: IOffer;
}

const OfferDetailExpandedContent: React.FunctionComponent<IOfferDetailExpandedContentProps> = ({
  offer,
}) => {
  const offerData = getOfferData(offer);
  const accessoriesList = offerData.accessories.map(
    (accessory: any, i: number) => <li key={i}>{accessory}</li>
  );
  const accessories = !isEmpty(accessoriesList) ? (
    <List>{accessoriesList}</List>
  ) : (
    'Sin accesorios'
  );

  const date = new Date(offerData.dateVigency);
  const timeZoneOffset = date.getTimezoneOffset();
  const daysRemaining = differenceInCalendarDays(
    addMinutes(date, timeZoneOffset),
    new Date()
  );
  const offerValidity = daysRemaining === 0 ? 'Último día' : `${daysRemaining}`;

  const headers = [
    ['Producto', 'Marca', 'Modelo', 'Año', 'Precio', 'Enganche'],
    [
      'Monto crédito',
      'Plazo',
      'Tasa interés',
      'CAT',
      'Comisión Apertura',
      'Accesorios',
    ],
    [
      'Accesorios',
      'Pago periódico',
      'Fecha oferta',
      'Fecha vigencia',
      'Días restantes',
      'Comisión',
    ],
  ];

  const values = [
    [
      offerData.productName,
      offerData.vehicleManufacturer,
      offerData.vehicleModel,
      offerData.vehicleModelYear,
      f.currency(offerData.vehicleValue),
      f.currency(offerData.downpayment),
    ],
    [
      f.currency(offerData.creditAmount),
      offerData.requestedPeriod,
      `${f.percentage(Number.parseFloat(offerData.rate) * 100)} (${
        grammar[offerData.rateType]
      })`,
      f.percentage(Number.parseFloat(offerData.cat) * 100),
      offerData.fee,
      f.currency(offerData.accessoriesAmount),
    ],
    [
      accessories,
      f.currency(offerData.payment),
      f.date(offerData.dateOffer),
      f.date(offerData.dateVigency),
      offerValidity,
      f.percentage(offerData.salespersonCommission * 100),
    ],
  ];

  return (
    <MultiSingleRowTable
      headersArray={headers}
      valuesArray={values}
      gridColumns={'repeat(6, 1fr)'}
    />
  );
};

const OfferDetail: React.FunctionComponent<IOfferDetailProps> = ({ offer }) => {
  return (
    <OfferDetailContainer>
      <ExpandableCard
        status={`En ${grammar[offer.stage]}`}
        height={'288px'}
        title={`Expediente de oferta #${
          offer.id
        } - ${(offer as any).request.dataPersonals[0].name.toUpperCase()} ${(offer as any).request.dataPersonals[0].lastName.toUpperCase()} ${(offer as any).request.dataPersonals[0].secondSurname.toUpperCase()}`}
      >
        <OfferDetailExpandedContent offer={offer} />
      </ExpandableCard>
    </OfferDetailContainer>
  );
};

const OfferRecordView: React.FunctionComponent<IOfferRecordView> = ({
  fetchOfferById,
  offer,
  match,
  userData,
  history,
  clearBankUpdateVigencyModal,
}) => {
  const offerId = match.params.offerId;
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (isNil(offer)) {
      fetchOfferById({ id: offerId });
    }
  }, [fetchOfferById, offer, offerId]);

  if (isNil(offer)) {
    return null;
  }

  const riskProfileNavigationButton = (
    <Buttons>
      {isBankUser(userData) ? (
        <Fragment>
          <Dropdown>
            <DropdownButton>...</DropdownButton>
            <DropDownContent>
              <OptionsContainer>
                <Option
                  onClick={() =>
                    history.push(`/application-details/${offer.requestId}`)
                  }
                >
                  <span>Solicitud</span>
                </Option>
                <Option
                  onClick={() => {
                    history.push(`/risk-profile/${offer.requestId}`);
                  }}
                >
                  <span>Perfil de riesgo</span>
                </Option>
                <Option
                  onClick={() => {
                    history.push(
                      `/pipeline/offer/${offer.id}/${offer.requestId}/credit-history`
                    );
                  }}
                >
                  <span>Historial</span>
                </Option>
                <Option
                  onClick={() => {
                    clearBankUpdateVigencyModal();
                    setShowModal(true);
                  }}
                >
                  <span>Vigencia</span>
                </Option>
              </OptionsContainer>
            </DropDownContent>
          </Dropdown>
        </Fragment>
      ) : null}
    </Buttons>
  );

  return (
    <Fragment>
      <BackButtonContainer>
        <Button
          variant="secondary"
          onClick={() =>
            offer.stage === 'granted'
              ? history.push('/granted-products')
              : history.push(`/pipeline/${offer.stage}`)
          }
        >
          Regresar
        </Button>
        {riskProfileNavigationButton}
      </BackButtonContainer>
      <ViewContainer>
        <OfferDetail offer={offer} />
        {/*
          // @ts-ignore */}
        <OfferDocuments offer={offer} />
        <Chat offer={offer} />
      </ViewContainer>
      <UpdateOfferVigencyModal
        offer={offer}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
      />
    </Fragment>
  );
};

const mapStateToProps = (
  state: any,
  ownProps: any
): { userData: IUserData; offer: IOffer } => ({
  userData: state.auth.userData,
  offer: state.entities.offers[ownProps.match.params.offerId],
});

const creators = {
  fetchOfferById: offerActions.creators.fetchOfferById.request,
  clearBankUpdateVigencyModal: banks.creators.clearBanksUpdateOfferVigency,
};

export default withUserData(
  connect(mapStateToProps, creators)(OfferRecordView)
);
