import isEmpty from 'ramda/src/isEmpty';
import React, { Component } from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import SendMessageIcon from '../../resources/icons/SendMessageIcon';
import { isCreditusUser } from '../../utils/permissions';

const MessageBoxContainer = styled.div`
  height: 120px;
  padding: 30px;
`;

const MessageBoxContent = styled.div`
  align-items: center;
  display: flex;
`;

const SendButton = styled.div<{ disabled: boolean }>`
  background-color: ${({ disabled }) =>
    disabled ? colors.WHITE : colors.ORANGE};
  border: 1px solid
    ${({ disabled }) => (disabled ? colors.BORDER : colors.ORANGE)};
  border-radius: 12px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  height: 24px;
  margin-left: 12px;
  width: 24px;
`;

const TextArea = styled.textarea<{ disabled: boolean }>`
  background-color: ${colors.WHITE};
  border: 1px solid ${colors.BORDER};
  border-radius: 3px;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  height: 60px;
  padding: 8px;
  resize: none;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'text')};

  :focus {
    border-color: ${colors.ORANGE};
  }
`;

const TextAreaContainer = styled.div`
  flex-grow: 1;
`;

interface IMessageBox {
  offerId: number;
  sendMessage: Function;
  userData: IUserData;
}

class MessageBox extends Component<IMessageBox> {
  state = {
    message: '',
  };

  handleMessageChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (isCreditusUser(this.props.userData)) {
      return;
    }

    const { value } = event.target;
    this.setState(() => ({
      message: value,
    }));
  };

  handleTextAreaKeydown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { key } = event;

    if (key === 'Enter') {
      event.preventDefault();
      this.sendMessage();
    }
  };

  sendMessage = () => {
    const { message } = this.state;
    const { offerId, sendMessage } = this.props;

    if (isEmpty(message.trim())) {
      return;
    }

    sendMessage({ offerId, content: message.trim() });

    this.setState(() => ({
      message: '',
    }));
  };

  render() {
    const { userData } = this.props;
    const { message } = this.state;

    const sendButtonDisabled = isEmpty(message) || isCreditusUser(userData);

    return (
      <MessageBoxContainer>
        <MessageBoxContent>
          <TextAreaContainer>
            <TextArea
              onChange={this.handleMessageChange}
              onKeyDown={this.handleTextAreaKeydown}
              value={message}
              placeholder="Escribe aquí..."
              disabled={isCreditusUser(userData)}
            />
          </TextAreaContainer>
          <SendButton disabled={sendButtonDisabled} onClick={this.sendMessage}>
            <SendMessageIcon
              fill={sendButtonDisabled ? colors.BORDER : colors.WHITE}
              size={22}
            />
          </SendButton>
        </MessageBoxContent>
      </MessageBoxContainer>
    );
  }
}

export default MessageBox;
