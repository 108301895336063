import { createAction } from 'redux-actions';

const SET_DEVICE_DATA = 'SET_DEVICE_DATA';

const setDeviceData = createAction(SET_DEVICE_DATA);

export default {
  types: {
    SET_DEVICE_DATA,
  },
  creators: {
    setDeviceData,
  },
};
