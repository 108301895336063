import { Form, Formik, FormikProps } from 'formik';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import auth from '../../actions/auth';
import colors from '../../constants/colors';
import CreditusLogo from '../../resources/logoName.png';
import Button from '../Button';
import ForgotPasswordLogin from '../ForgotPasswordLogin';
import { FormikText } from '../FormikInputs';
import LoadingIndicator from '../LoadingIndicator';

const SHOW_RESEND_NIP_TEXT_TIMER = 10000;
const RESEND_NIP_TRY_LIMIT = 3;

const DisclaimerText = styled.div`
  padding: 2px 0px;
  font-size: 10px;
  color: ${colors.SECONDARY_TEXT};
  font-weight: 300;
  letter-spacing: 0.14px;
  text-align: left;
  width: 100%;
  a {
    color: ${colors.HYPERLINK};
  }
`;

const NipDisclaimerText = styled.div<{ showResendNip: boolean }>`
  padding: 2px 0px;
  font-size: 10px;
  color: ${colors.SECONDARY_TEXT};
  font-weight: 300;
  letter-spacing: 0.14px;
  text-align: left;
  width: 100%;
  a {
    color: ${colors.HYPERLINK};
  }
  ${props =>
    props.showResendNip
      ? 'height: auto; overflow: visible; opacity: 1;'
      : 'height: 0px; overflow: hidden; opacity: 0;'}
  transition-property: height, overflow, opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease;
`;

const Link = styled.span`
  color: ${colors.HYPERLINK};
  text-decoration: underline;
  cursor: pointer;
`;

const LoginFormTopPadding = styled.div`
  padding-top: 20vh;
`;

const LoginFormContainer = styled.div`
  align-content: center;
  align-items: center;
  background-color: ${colors.WHITE};
  border: 1px solid ${colors.BORDER};
  box-shadow: 0 1px 0 0 ${colors.BORDER};
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 40px;
  width: 380px;
`;

const LogoContainer = styled.div`
  margin-bottom: 32px;
`;

const ControlsContainer = styled.div`
  align-items: center;
  display: flex;
  height: 40px;
  margin-top: 16px;
`;

const CreditusLogoImg = styled.img`
  width: 220px;
  margin: auto;
  display: block;
`;

const InputContainer = styled.div`
  margin-top: 8px;
  width: 100%;
`;

const PasswordContainer = styled.div<{ isClient: boolean | null }>`
  ${props =>
    props.isClient === false
      ? 'height: 105px; overflow: visible; opacity: 1;'
      : 'height: 0px; overflow: hidden; opacity: 0;'}
  margin-top: 8px;
  width: 100%;
  transition-property: height, overflow, opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease;
`;

const NipHeaderText = styled.div`
  width: 100%;
  font-size: 14px;
  padding-bottom: 5px;
`;
interface ILoginPayload {
  email: string;
  password?: string;
  nip?: string;
}

const errorMessages = {
  email: {
    email: 'La dirección de correo electrónico no es válida.',
    required: 'Ingresa un correo electrónico.',
  },
  password: {
    required: 'Ingresa una contraseña.',
  },
  nip: {
    required: 'Ingresa tu nip',
  },
};

const validationSchema: Yup.ObjectSchema<Yup.Shape<
  {},
  ILoginPayload
>> = Yup.object().shape({
  isClient: Yup.boolean(),
  email: Yup.string()
    .email(errorMessages.email.email)
    .required(errorMessages.email.required),
  password: Yup.string().when('isClient', {
    is: false,
    then: Yup.string().required(errorMessages.password.required),
    otherwise: Yup.string().notRequired(),
  }),
  nip: Yup.string().when('isClient', {
    is: true,
    then: Yup.string().required(errorMessages.nip.required),
    otherwise: Yup.string().notRequired(),
  }),
});

interface ILoginProps {
  login: (payload: ILoginPayload) => void;
  loggingIn: boolean;
  checkingLoginType: boolean;
  isClient: boolean | null;
  phoneNumber: string | null;
  getLoginType: Function;
}

interface ILoginFormProps {
  formikProps: FormikProps<ILoginPayload>;
  loggingIn: boolean;
  isClient: boolean | null;
  phoneNumber: string | null;
  checkingLoginType: boolean;
  setShowForgotPassword: (show: boolean) => void;
  setUserEmail: (email: string) => void;
  getLoginType: Function;
}

const LoginForm: React.FunctionComponent<ILoginFormProps> = ({
  formikProps,
  loggingIn,
  isClient,
  phoneNumber,
  setShowForgotPassword,
  setUserEmail,
  checkingLoginType,
  getLoginType,
}) => {
  const [showResendNip, setShowResendNip] = useState(false);
  const [resendNipTries, setResetNipTries] = useState(0);

  setTimeout(() => {
    if (isClient) {
      setShowResendNip(true);
    }
  }, SHOW_RESEND_NIP_TEXT_TIMER);
  const clearPasswordAfterAttempt = (): void => {
    if (!loggingIn) {
      formikProps.setFieldValue('password', '');
    }
  };

  const saveEmailValue = (): void => {
    if (!isEmpty(formikProps.values.email) && isNil(formikProps.errors.email)) {
      setUserEmail(formikProps.values.email);
    }
  };

  const setIsClient = (): void => {
    formikProps.setFieldValue('isClient', isClient);
  };

  useEffect(clearPasswordAfterAttempt, [loggingIn]);

  useEffect(saveEmailValue, [
    formikProps.values.email,
    formikProps.errors.email,
  ]);

  useEffect(setIsClient, [isClient]);

  const handleEnterOnForm = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      isClient === null
        ? getLoginType({ email: formikProps.values.email })
        : formikProps.submitForm();
    }
  };

  const controls =
    loggingIn || checkingLoginType ? (
      <LoadingIndicator />
    ) : (
      <Button
        type="button"
        variant={'primary'}
        onClick={() =>
          isClient === null
            ? getLoginType({ email: formikProps.values.email })
            : formikProps.submitForm()
        }
      >
        Login
      </Button>
    );

  return (
    <Form>
      <LoginFormTopPadding>
        <LoginFormContainer onKeyPress={handleEnterOnForm}>
          <LogoContainer>
            <CreditusLogoImg src={CreditusLogo} />
          </LogoContainer>
          {isClient ? (
            <Fragment>
              <NipHeaderText>
                Enviamos un código de autenticación al número: {phoneNumber}
              </NipHeaderText>
              <InputContainer>
                <FormikText
                  label="CÓDIGO"
                  name="nip"
                  placeholder="Código"
                  type="text"
                  tabIndex={isClient ? undefined : -1}
                  inputMode="decimal"
                />
              </InputContainer>
              <NipDisclaimerText showResendNip={showResendNip}>
                {resendNipTries < RESEND_NIP_TRY_LIMIT ? (
                  <Fragment>
                    Si no haz recibido tu código da&nbsp;
                    <Link
                      onClick={() => {
                        if (!checkingLoginType) {
                          setResetNipTries(resendNipTries + 1);
                          getLoginType({ email: formikProps.values.email });
                        }
                      }}
                    >
                      click aquí.
                    </Link>
                  </Fragment>
                ) : (
                  <Fragment>
                    Has excedido el número de intentos. Por favor contacta a
                    soporte al número: (331) 803-7895
                  </Fragment>
                )}
              </NipDisclaimerText>
            </Fragment>
          ) : (
            <Fragment>
              <InputContainer>
                <FormikText
                  label="CORREO ELECTRÓNICO"
                  name="email"
                  placeholder="Correo electrónico"
                  type="email"
                />
              </InputContainer>
              <PasswordContainer isClient={isClient}>
                <FormikText
                  label="CONTRASEÑA"
                  name="password"
                  placeholder="Contraseña"
                  type="password"
                  tabIndex={isClient === false ? undefined : -1}
                />
                <DisclaimerText>
                  Si no recuerdas tu contraseña da&nbsp;
                  <Link onClick={() => setShowForgotPassword(true)}>
                    click aquí.
                  </Link>
                </DisclaimerText>
              </PasswordContainer>
            </Fragment>
          )}
          <ControlsContainer>{controls}</ControlsContainer>
        </LoginFormContainer>
      </LoginFormTopPadding>
    </Form>
  );
};

const initialValues = {
  email: '',
  password: '',
  nip: '',
  isClient: true,
};

const Login: React.FunctionComponent<ILoginProps> = ({
  login,
  loggingIn,
  checkingLoginType,
  isClient,
  phoneNumber,
  getLoginType,
}) => {
  const [showForgotPassword, setShowForgotPassword] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');

  return showForgotPassword ? (
    <ForgotPasswordLogin
      initialEmail={userEmail}
      returnToLogin={() => setShowForgotPassword(false)}
    />
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={values => {
        isClient
          ? login({ email: values.email, nip: values.nip })
          : login({ email: values.email, password: values.password });
      }}
      render={formikProps => (
        <LoginForm
          formikProps={formikProps}
          loggingIn={loggingIn}
          setShowForgotPassword={setShowForgotPassword}
          setUserEmail={setUserEmail}
          isClient={isClient}
          checkingLoginType={checkingLoginType}
          getLoginType={getLoginType}
          phoneNumber={phoneNumber}
        />
      )}
      validationSchema={validationSchema}
    />
  );
};

const mapStateToProps = (
  state: any
): {
  loggingIn: boolean;
  checkingLoginType: boolean;
  isClient: boolean | null;
  phoneNumber: string | null;
} => ({
  loggingIn: state.loaders.loggingIn,
  checkingLoginType: state.loaders.checkingLoginType,
  isClient: state.auth.isClient,
  phoneNumber: state.auth.phoneNumber,
});

const creators = {
  login: auth.creators.login.request,
  getLoginType: auth.creators.getLoginType.request,
};

export default connect(mapStateToProps, creators)(Login);
