import { Formik, FormikProps } from 'formik';
import { isEmpty } from 'ramda';
import isNil from 'ramda/src/isNil';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import profilingActions from '../../actions/profiling';
import verificationModalActions from '../../actions/verificationModal';
import colors from '../../constants/colors';
import Button from '../Button';
import { FormikPhone } from '../FormikInputs';
import LoadingIndicator from '../LoadingIndicator';
import Modal from '../Modal';
import SendViaWhatsApp from '../SendViaWhatsApp';
import errorMsg from './errorMessages';

const ControlContainer = styled.div`
  display: flex;
  height: 38px;
  justify-content: flex-end;
`;

const ModalHeader = styled.div`
  align-items: center;
  color: ${colors.PRIMARY_TEXT};
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 48px;
`;

const DescriptionText = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  text-align: justify;
  padding-bottom: 24px;
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-bottom: 16px;
`;

interface IVerification {
  phoneNumber: string;
}

interface IVerificationForm {
  closeModal: () => void;
  showModal: boolean;
  closeEnabled: boolean;
  showLoading: boolean;
  formikProps: FormikProps<IVerification>;
  sendVerificationViaWhatsapp: Function;
}

interface IVerificationModalStateProps {
  verificationStarted: boolean;
  waitingForVerification: boolean;
  waitingForCurp: boolean;
  biometricVerificationId: number;
  verificationCompleted: boolean;
  curp: string;
  accountDataNotInUse: boolean;
  applicantContact: {
    email: string;
    phoneNumber: string;
  };
  existentClientData: IUser;
}

interface IVerificationModalDispatchProps {
  clearExistingClientData: Function;
  getCurpFromVerification: Function;
  sendVerificationViaWhatsapp: Function;
  startVerification: Function;
  waitForVerificationCompletion: Function;
}

type IVerificationModal = IVerificationModalDispatchProps &
  IVerificationModalStateProps;

const ProfilingModalForm: React.FunctionComponent<IVerificationForm> = ({
  closeModal,
  closeEnabled,
  formikProps,
  sendVerificationViaWhatsapp,
  showModal,
  showLoading,
}) => {
  const renderContent = (): JSX.Element =>
    showLoading ? (
      <Fragment>
        <LoadingIndicatorContainer>
          <LoadingIndicator />
        </LoadingIndicatorContainer>
        <DescriptionText>
          Por favor espera mientras concluye el proceso de verificación en tu
          celular.
        </DescriptionText>
        <SendViaWhatsApp
          drawerText="¿Aún no recibes la liga de verificación? Da click aquí"
          formText="Recibe tu liga de verificación por"
          onSubmit={phoneNumber => sendVerificationViaWhatsapp({ phoneNumber })}
        />
      </Fragment>
    ) : (
      <Fragment>
        <DescriptionText>
          Te enviaremos un SMS con una liga para iniciar el proceso, ingresa el
          teléfono que deseas utilizar.
        </DescriptionText>
        <FormikPhone label="TELÉFONO CELULAR" name="phoneNumber" />
      </Fragment>
    );

  return (
    <Modal
      content={() => renderContent()}
      controls={() => (
        <ControlContainer>
          {formikProps.isValid && !showLoading ? (
            <Button onClick={() => formikProps.submitForm()}>Iniciar</Button>
          ) : null}
        </ControlContainer>
      )}
      header={() => <ModalHeader>Verificación de identidad</ModalHeader>}
      isOpen={showModal}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      showCloseIcon={closeEnabled}
      width={'400px'}
    />
  );
};

const VerificationModal: React.FunctionComponent<IVerificationModal> = ({
  biometricVerificationId,
  startVerification,
  waitForVerificationCompletion,
  getCurpFromVerification,
  verificationStarted,
  waitingForVerification,
  verificationCompleted,
  waitingForCurp,
  sendVerificationViaWhatsapp,
  accountDataNotInUse,
  curp,
  clearExistingClientData,
  applicantContact,
  existentClientData,
}) => {
  const [closeEnabled, setCloseEnabled] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleStartVerification = (values: IVerification): void => {
    startVerification({
      phoneNumber: values.phoneNumber,
      email: applicantContact.email,
      shouldSendSMS: true,
    });
  };

  useEffect(() => {
    if (
      !isNil(applicantContact) &&
      !(
        isEmpty(applicantContact.email) || isEmpty(applicantContact.phoneNumber)
      ) &&
      (accountDataNotInUse || !isNil(existentClientData)) &&
      isNil(curp)
    ) {
      setShowModal(true);
    } else if (!isNil(curp)) {
      setShowModal(false);
    }
  }, [applicantContact, accountDataNotInUse, curp, existentClientData]);

  useEffect(() => {
    if (!isNil(biometricVerificationId) && isNil(curp)) {
      waitForVerificationCompletion({ biometricVerificationId });
    }
  }, [biometricVerificationId, curp, waitForVerificationCompletion]);

  useEffect(() => {
    if (biometricVerificationId) {
      setCloseEnabled(false);
    }
  }, [biometricVerificationId]);

  useEffect(() => {
    if (verificationCompleted) {
      getCurpFromVerification({ biometricVerificationId });
    }
  }, [biometricVerificationId, verificationCompleted, getCurpFromVerification]);

  return !isNil(applicantContact) ? (
    <Formik
      isInitialValid={true}
      initialValues={{ phoneNumber: applicantContact.phoneNumber }}
      onSubmit={values => handleStartVerification(values)}
      render={formikProps => (
        <ProfilingModalForm
          closeModal={() => {
            clearExistingClientData();
            setShowModal(false);
          }}
          closeEnabled={closeEnabled}
          formikProps={formikProps}
          sendVerificationViaWhatsapp={sendVerificationViaWhatsapp}
          showModal={showModal}
          showLoading={
            waitingForVerification || waitingForCurp || verificationStarted
          }
        />
      )}
      validationSchema={Yup.object().shape({
        phoneNumber: Yup.string()
          .matches(/^\d{10}$/, errorMsg.phoneNumber.matches)
          .required(errorMsg.phoneNumber.required),
      })}
    />
  ) : null;
};

const mapStateToProps = (state: any): any => ({
  verificationStarted: state.loaders.verificationStarted,
  waitingForVerification: state.loaders.waitingForVerification,
  waitingForCurp: state.loaders.waitingForCurp,
  biometricVerificationId:
    state.newApplication.verificationModal.biometricVerificationId,
  verificationCompleted:
    state.newApplication.verificationModal.verificationCompleted,
  curp: state.newApplication.verificationModal.curp,
  accountDataNotInUse: state.newApplication.accountDataNotInUse,
  applicantContact: state.newApplication.profiling.contact,
  existentClientData: state.newApplication.existentClient.clientAccount,
});

const creators = {
  startVerification:
    verificationModalActions.creators.startVerification.request,
  sendVerificationViaWhatsapp:
    verificationModalActions.creators.sendVerificationViaWhatsapp.request,
  waitForVerificationCompletion:
    verificationModalActions.creators.waitForVerificationCompletion.request,
  getCurpFromVerification:
    verificationModalActions.creators.getCurpFromVerification.request,
  clearExistingClientData: profilingActions.creators.clearExistingClientData,
};

export default connect<
  IVerificationModalStateProps,
  IVerificationModalDispatchProps,
  {}
>(
  mapStateToProps,
  creators
)(VerificationModal);
