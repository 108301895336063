import React, { Component } from 'react';
import styled from 'styled-components';

import SearchLeft from '../../resources/icons/SearchLeft';
import Input from '../Input';

const SearchBar = styled.div`
  display: flex;
`;
const SearchIconContainer = styled.div`
  padding-top: 10px;
`;

class FilterSearch extends Component {
  state = {
    filter: '',
    typingTimeout: null,
  };

  filtering() {
    const { action } = this.props;
    action({
      filter: this.state.filter,
    });
  }

  render() {
    return (
      <SearchBar>
        <Input
          onChange={event => {
            clearTimeout(this.state.typingTimeout);
            const filter = event.target.value;
            this.setState(() => ({
              filter,
              typingTimeout: setTimeout(this.filtering.bind(this), 600),
            }));
          }}
          value={this.state.filter}
        />
        <SearchIconContainer>
          <SearchLeft size={15} />
        </SearchIconContainer>
      </SearchBar>
    );
  }
}

export default FilterSearch;
