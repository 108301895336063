import format from 'date-fns/format';
import es from 'date-fns/locale/es';
import isNil from 'ramda/src/isNil';
import prop from 'ramda/src/prop';
import React, { Component } from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';

const MessagesListContainer = styled.div`
  height: 480px;
  overflow: auto;
  padding: 8px 28px;
`;

const MessageContainer = styled.div`
  display: grid;
  grid-template-columns: ${({ align }) =>
    align === 'right' ? '1fr 20px 28px;' : '28px 20px 1fr;'};
  margin-bottom: 12px;
`;

const AvatarCircle = styled.div`
  align-items: center;
  background-color: ${prop('color')};
  border-radius: 19px;
  display: flex;
  height: 28px;
  justify-content: center;
  text-align: center;
  width: 28px;
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const Tooltip = styled.span`
  background-color: ${colors.SECONDARY_TEXT};
  color: ${colors.WHITE};
  text-align: center;
  font-size: 12px;
  width: 160px;
  margin-top: 4px;
  padding: 4px 0;
  border-radius: 6px;
  position: absolute;
  visibility: hidden;
  z-index: 100;

  ${TooltipContainer}:hover & {
    visibility: visible;
  }
`;

const AvatarSpan = styled.span`
  color: ${colors.WHITE};
  cursor: default;
  font-size: 12px;
  margin-bottom: 2px;
`;

const Avatar = ({ color, letters }) => (
  <AvatarCircle color={color}>
    <AvatarSpan>{letters}</AvatarSpan>
  </AvatarCircle>
);

const VerticalSeparatorContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const VerticalSeparator = styled.div`
  background-color: ${prop('color')};
  width: 1px;
`;

const MessageContent = styled.div`
  padding-left: ${({ align }) => (align === 'right' ? '44px' : '0px')};
  padding-right: ${({ align }) => (align === 'left' ? '44px' : '0px')};
  text-align: ${prop('align')};
`;

const MessageText = styled.div`
  font-size: 12px;
  margin-bottom: 8px;
  word-break: break-word;
`;

const DateText = styled.div`
  color: ${colors.SECONDARY_TEXT};
  font-size: 9px;

  &::first-letter {
    text-transform: capitalize;
  }
`;

const getAvatarLetters = user => {
  if (user.firstSurname) {
    return `${user.name[0]}${user.firstSurname[0]}`.toUpperCase();
  }

  return `${user.name[0]}${user.name[1]}`.toUpperCase();
};

const Message = ({ align, message, otherParty }) => {
  const date = format(
    new Date(message.createdAt),
    "cccc d 'de' MMMM, YYYY | HH:mm",
    { locale: es, awareOfUnicodeTokens: true }
  );

  const color = (() => {
    if (message.sysmessage) {
      return colors.SECONDARY_TEXT;
    } else {
      return align === 'right' ? colors.DUSK : colors.ORANGE;
    }
  })();

  const creatorName =
    message.sysmessage && isNil(message.creator)
      ? 'Sistema | Creditus'
      : `${message.creator.name} ${message.creator.firstSurname} | ${otherParty}`;

  const avatar = (
    <TooltipContainer>
      <Avatar
        color={color}
        letters={message.sysmessage ? 'C' : getAvatarLetters(message.creator)}
      />
      {align === 'left' ? <Tooltip>{creatorName}</Tooltip> : null}
    </TooltipContainer>
  );

  const messageComponent = (
    <MessageContent align={align}>
      <MessageText>{message.content}</MessageText>
      <DateText>{date}</DateText>
    </MessageContent>
  );
  const separator = (
    <VerticalSeparatorContainer>
      <VerticalSeparator color={color} />
    </VerticalSeparatorContainer>
  );

  if (align === 'right') {
    return (
      <MessageContainer align={align}>
        {messageComponent}
        {separator}
        {avatar}
      </MessageContainer>
    );
  }

  return (
    <MessageContainer align={align}>
      {avatar}
      {separator}
      {messageComponent}
    </MessageContainer>
  );
};

class MessagesList extends Component {
  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom() {
    this.el.scrollTop = this.el.scrollHeight;
  }

  render() {
    const { messages, userId, otherParty } = this.props;

    const messagesList = messages.map(message => {
      const align = message.userId === userId ? 'right' : 'left';

      return (
        <Message
          key={message.id}
          align={align}
          message={message}
          otherParty={otherParty}
        />
      );
    });

    return (
      <MessagesListContainer
        ref={el => {
          this.el = el;
        }}
      >
        {messagesList}
      </MessagesListContainer>
    );
  }
}

export default MessagesList;
