import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 38.033628 38.377789';

const AuditIcon = ({ fill }: { fill: string }) => (
  <g transform="translate(-107.9503,-179.78685)">
    <path
      fill={fill}
      d="m 110.067,183.411 c 0,-0.8257 0.5853,-1.4111 1.4111,-1.4111 h 29.2805 c 1.6068,0 2.1167,0.5099 2.1167,2.1167 v 2.4694 H 110.067 Z m 0,5.6445 h 32.8083 v 8.1139 h 2.0992 c 0.0117,-0.8218 0.0174,-0.8111 0.0174,-1.7639 v -12.3472 c 0,-1.6029 -0.6518,-2.1354 -1.4111,-2.8223 -1.099,-0.2895 -0.7919,-0.5126 -3.8705,-0.4328 l -27.8794,0.0801 c -2.2952,0 -3.8806,0.8909 -3.8806,3.175 v 24.6944 c 0,2.5367 0.6591,4.5861 3.175,4.5861 h 7.0555 c 0.8129,0 1.0584,-0.2455 1.0584,-1.0583 v -1.0584 h -7.0556 c -1.6069,0 -2.1166,-0.5097 -2.1166,-2.1166 z"
    />
    <circle
      fill={fill}
      stroke={fill}
      strokeWidth="0.2"
      r="10.556"
      cy="203.11"
      cx="130.823"
    />
    <circle
      fill="white"
      stroke={fill}
      strokeWidth="0.2"
      r="8.1199999"
      cy="203.11"
      cx="130.823"
    />
    <line
      fill="none"
      stroke={fill}
      strokeWidth="3"
      y2="217.076"
      x2="144.952"
      y1="209.687"
      x1="137.157"
    />
    <polyline
      fill="none"
      stroke={fill}
      strokeWidth="2"
      points="127.047,203.191 129.564,206.195 135.979,200.349 "
    />
  </g>
);

export default BaseIcon(AuditIcon, viewBox);
