import isNil from 'ramda/src/isNil';
import length from 'ramda/src/length';
import prop from 'ramda/src/prop';
import React, { Fragment } from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import Border from '../Border';

interface Table<T> {
  data: Array<T>;
  gridColumns: string;
  headers: string[];
  onRowClick?: (datum: T) => void;
  renderRow(datum: T, index: number): JSX.Element[];
}

export const TableCell = styled.div`
  align-items: center;
  color: ${colors.PRIMARY_TEXT};
  font-size: 12px;
  display: flex;
  height: 60px;
`;

export const TableHeader = styled.div`
  align-items: center;
  color: ${colors.DUSK50};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 36px;
  line-height: 1.5;
  text-transform: uppercase;
`;

const TableRowGrid = styled.div<{
  gridColumns: string;
  showPointer: boolean;
}>`
  align-items: center;
  display: grid;
  grid-template-columns: ${prop('gridColumns')};
  padding: 0 24px;
  cursor: ${({ showPointer }) => (showPointer ? 'pointer' : '')};
`;

const Table = function<T>({
  data,
  gridColumns,
  headers,
  onRowClick,
  renderRow,
}: Table<T>) {
  const rowCount = length(data);
  const tableHeaders = headers.map((header, i) => (
    <TableHeader key={i}>{header}</TableHeader>
  ));

  const rows = data.map((datum, i) => {
    return (
      <Fragment key={i}>
        <TableRowGrid
          gridColumns={gridColumns}
          onClick={() => onRowClick && onRowClick(datum)}
          showPointer={!isNil(onRowClick)}
        >
          {renderRow(datum, i)}
        </TableRowGrid>
        {i !== rowCount - 1 ? <Border /> : null}
      </Fragment>
    );
  });

  return (
    <Fragment>
      <TableRowGrid gridColumns={gridColumns} showPointer={false}>
        {tableHeaders}
      </TableRowGrid>
      <Border />
      {rows}
    </Fragment>
  );
};

export default Table;
