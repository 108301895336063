import isEmpty from 'ramda/src/isEmpty';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import applicationCreationActions from '../../actions/applicationCreation';
import continueApplicationActions from '../../actions/continueApplication';
import colors from '../../constants/colors';
import {
  RejectionReasons,
  rejectionReasonsMessage,
} from '../../constants/rejectionReasons';
import filterRelevantOffers from '../../utils/filterRelevantOffers';
import { addSearchParamsToUrl } from '../../utils/misc';
import Border from '../Border';
import Button from '../Button';
import CreditHistoryDataSection from '../CreditHistoryDataSection';

const ButtonContainer = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Checkmark = styled.span`
  display: block;
  position: relative;
  height: 80px;
  width: 80px;
  border: dashed 2px ${colors.AQUA};
  background-color: ${colors.BACKGROUND};
  border-radius: 50%;
  margin: 8px 0px;

  &:before {
    content: '';
    margin-left: 32px;
    margin-top: 24px;
    display: block;
    width: 12px;
    height: 24px;
    border: solid ${colors.AQUA};
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
`;

const Crossmark = styled.div`
  display: block;
  position: relative;
  height: 80px;
  width: 80px;
  border: dashed 2px ${colors.RED};
  background-color: ${colors.BACKGROUND};
  border-radius: 50%;
  margin: 8px 0px;

  &:after,
  &:before {
    position: absolute;
    left: 36px;
    top: 26px;
    content: ' ';
    height: 26px;
    width: 4px;
    background-color: ${colors.RED};
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  padding: 10px 0px;
`;

const ContentTitle = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 20px;
  font-weight: bold;
  margin: 4px 0px;
`;

const ContentSubtitle = styled.div`
  color: ${colors.SECONDARY_TEXT};
  font-size: 14px;
  margin: 4px 0px;
  width: 78%;
  line-height: 20px;
`;

const HeaderTitle = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 28px;
  font-weight: bold;
  margin: 4px 0px;
`;

const HeaderSubtitle = styled.div`
  color: ${colors.SECONDARY_TEXT};
  font-size: 14px;
  margin: 4px 0px;
  width: 78%;
  line-height: 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  padding: 20px 0px;
`;

const Star = styled.label<{ isSelected: boolean }>`
  font-weight: bold;
  padding: 4px;
  vertical-align: middle;
  font-size: 30px;
  color: ${props => (props.isSelected ? colors.AQUA : colors.DUSK50)};
  transition: color 0.2s ease-out;

  &:hover {
    cursor: pointer;
  }
`;

const StarsContainer = styled.div`
  display: flex;
`;

const TextArea = styled.textarea<{ isMobile: boolean }>`
  background-color: ${colors.WHITE};
  border: 1px solid ${colors.BORDER};
  margin: 8px 0px;
  border-radius: 3px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  height: 60px;
  resize: none;
  width: ${props => (props.isMobile ? '95%' : '400px')};

  :focus {
    border-color: ${colors.AQUA};
  }
`;

const RejectionReasonsListContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 12px 0px;
  text-align: center;
`;

const ResultContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  ${props =>
    props.isMobile ? 'margin: auto; padding: 40px;' : 'padding: 40px 200px;'}
`;

const RejectionReasonsTitle = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
`;

const RejectionReasonText = styled.div`
  color: ${colors.SECONDARY_TEXT};
  font-size: 14px;
  margin-bottom: 4px;
`;

const BanksContactInfoContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 12px 0px;
  text-align: center;
`;

const BanksContactInfoTitle = styled.div`
  color: ${colors.SECONDARY_TEXT};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 12px;
  width: 78%;
`;

const BanksContactInfoText = styled.div`
  color: ${colors.SECONDARY_TEXT};
  font-size: 14px;
  margin-bottom: 12px;
`;

const ClientNextStepsTitle = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 20px;
  font-weight: bold;
  margin: 4px 0px;
`;

const ClientNextStepsText = styled.div`
  color: ${colors.SECONDARY_TEXT};
  font-size: 14px;
  margin: 4px 0px;
  width: 90%;
  line-height: 20px;
`;

const Strong = styled.div`
  font-weight: bold;
  color: ${colors.PRIMARY_TEXT};
  display: inline;
`;

interface INewApplicationResult {
  applicationId: number;
  clearNewApplication: Function;
  offers: IOffer[];
  rejectionReasons: RejectionReasons[];
  sendApplicationFeedback: Function;
  creditHistoryData: ICreditHistoryData | null;
  isMobile: boolean;
  banksContactInfo: IBankContactInfo[];
}

interface IStarRating {
  rating: number;
  setRating: Function;
}

interface IRejectionReasonsList {
  rejectionReasons: RejectionReasons[];
}

const RejectionReasonsList: React.FunctionComponent<IRejectionReasonsList> = ({
  rejectionReasons,
}) => {
  return (
    <RejectionReasonsListContainer>
      <RejectionReasonsTitle>
        Las razones por las que no encontramos un producto para tu solicitud
        son:
      </RejectionReasonsTitle>
      {rejectionReasons.slice(0, 3).map((reason, i) => (
        <RejectionReasonText key={i}>
          - {rejectionReasonsMessage[reason]}
        </RejectionReasonText>
      ))}
    </RejectionReasonsListContainer>
  );
};

interface IBankContactInfo {
  name: string;
  phoneNumber: string;
}
interface IBanksContactInfo {
  ficoScore: number;
  requestId: number;
  banksContactInfo: IBankContactInfo[];
}

const BanksContactInfo: React.FunctionComponent<IBanksContactInfo> = ({
  ficoScore,
  requestId,
  banksContactInfo,
}) => {
  const banksContactInfoElements = banksContactInfo.map(bankContactInfo => {
    return (
      <BanksContactInfoText>
        {bankContactInfo.name} - {bankContactInfo.phoneNumber}
      </BanksContactInfoText>
    );
  });
  return ficoScore >= 560 ? (
    <BanksContactInfoContainer>
      <BanksContactInfoTitle>
        Si estimas que tu cliente es sujeto de crédito y deseas que algún
        otorgante realice una verificación manual por favor comunícate con ellos
        y solicita la verificación del siguiente folio: {requestId}
      </BanksContactInfoTitle>
      <BanksContactInfoText>Información de contacto:</BanksContactInfoText>
      {banksContactInfoElements}
    </BanksContactInfoContainer>
  ) : null;
};

const StarRating: React.FunctionComponent<IStarRating> = ({
  rating,
  setRating,
}) => {
  const [tempRating, setTempRating] = useState<number>(0);

  const stars = [];
  for (let index = 0; index < 5; index++) {
    stars.push(
      <Star
        key={index}
        onClick={() => {
          setTempRating(index + 1);
          setRating(index + 1);
        }}
        onMouseOver={() => {
          setTempRating(rating);
          setRating(index + 1);
        }}
        onMouseOut={() => {
          setRating(tempRating);
          setTempRating(0);
        }}
        isSelected={index + 1 <= rating}
      >
        ☆
      </Star>
    );
  }

  return <StarsContainer>{stars}</StarsContainer>;
};

const NewApplicationResult: React.FunctionComponent<INewApplicationResult> = ({
  offers,
  sendApplicationFeedback,
  clearNewApplication,
  applicationId,
  rejectionReasons,
  creditHistoryData,
  isMobile,
  banksContactInfo,
}) => {
  const [message, setMessage] = useState<string>('');
  const [rating, setRating] = useState<number>(0);

  const relevantOffers = filterRelevantOffers(offers);
  const hasOffers = relevantOffers.length > 0;

  const send = (): void => {
    if (!(isEmpty(message) && rating === 0)) {
      sendApplicationFeedback({
        applicationId,
        feedbackMessage: isEmpty(message) ? 'N/A' : message,
        rating: rating > 0 ? rating : 'N/A',
      });
    } else {
      clearNewApplication();
    }
    if (window.location.search.includes('promoterUserCode')) {
      window.open('/', '_blank')?.focus();
    }
  };

  return (
    <ResultContainer isMobile={isMobile}>
      {!window.location.search.includes('promoterUserCode') ? (
        <HeaderContainer>
          {hasOffers ? (
            <Fragment>
              <Checkmark />
              <HeaderTitle>
                {`Encontramos ${relevantOffers.length} oferta(s) para tu solicitud`}
              </HeaderTitle>
              <HeaderSubtitle>
                Puedes ver el detalle de la(s) oferta(s) en la sección de
                trámites. Recuerda que las caracteristicas de cualquier oferta
                pueden variar con lo solicitado.
              </HeaderSubtitle>
            </Fragment>
          ) : (
            <Fragment>
              <Crossmark />
              <HeaderTitle>
                No encontramos ninguna oferta para tu solicitud
              </HeaderTitle>
              <HeaderSubtitle>
                Lamentablemente no encontramos ninguna oferta con los otorgantes
                seleccionados. Recuerda que en nuestra búsqueda se toma en
                cuenta tu historial crediticio.
              </HeaderSubtitle>
            </Fragment>
          )}
        </HeaderContainer>
      ) : (
        <HeaderContainer>
          {hasOffers ? (
            <Fragment>
              <Checkmark />
              <HeaderTitle>
                {`Encontramos ${relevantOffers.length} producto(s) para tu solicitud`}
              </HeaderTitle>
            </Fragment>
          ) : (
            <Fragment>
              <Crossmark />
              <HeaderTitle>
                No encontramos ningún producto para tu solicitud
              </HeaderTitle>
              <HeaderSubtitle>
                Lamentablemente no encontramos ningún producto con las
                instituciones seleccionadas. Por favor comunícate con tu asesor
                para buscar otra alternativa.
              </HeaderSubtitle>
            </Fragment>
          )}
        </HeaderContainer>
      )}
      <Border />
      {!hasOffers ? (
        <Fragment>
          <RejectionReasonsList
            rejectionReasons={
              rejectionReasons.length
                ? rejectionReasons
                : [RejectionReasons.NoReason]
            }
          />
          <CreditHistoryDataSection creditHistoryData={creditHistoryData} />
          <Border />
          {creditHistoryData && banksContactInfo ? (
            <BanksContactInfo
              requestId={applicationId}
              banksContactInfo={banksContactInfo}
              ficoScore={creditHistoryData.ficoScore}
            />
          ) : null}
        </Fragment>
      ) : window.location.search.includes('promoterUserCode') ? (
        <Fragment>
          <ContentContainer>
            <ClientNextStepsTitle>¿Qué sigue?</ClientNextStepsTitle>
            <ClientNextStepsText>
              <Strong>1.</Strong> Da clic en el botón "Ver ofertas" para poder
              ingresar a tu cuenta con el correo electrónico que registraste.
            </ClientNextStepsText>
            <ClientNextStepsText>
              <Strong>2.</Strong> Revisa las ofertas y elige la que mejor se
              adecúe a tus necesidades.
            </ClientNextStepsText>
            <ClientNextStepsText>
              <Strong>3.</Strong> Sube tu documentación y ponte en contacto con
              tu asesor para finalizar tu compra.
            </ClientNextStepsText>
          </ContentContainer>
          <Border />
          <ContentContainer>
            <ClientNextStepsText>
              En caso de tener cualquier duda por favor ponte en contacto con tu
              asesor.
            </ClientNextStepsText>
            <ClientNextStepsText>
              Recuerda que las características de cualquier precalificación
              variar dependiendo la revisión en tus documentosy tu perfil
              crediticio.
            </ClientNextStepsText>
          </ContentContainer>
        </Fragment>
      ) : null}
      <Border />
      {!window.location.search.includes('promoterUserCode') ? (
        <ContentContainer>
          <ContentTitle>
            ¿Cómo fue tu experiencia al subir tu solicitud?
          </ContentTitle>
          <ContentSubtitle>
            Califica tu experiencia y cuentanos que podríamos mejorar.
          </ContentSubtitle>
          <StarRating rating={rating} setRating={setRating} />
          <TextArea
            isMobile={isMobile}
            onChange={event => setMessage(event.target.value)}
            value={message}
          />
        </ContentContainer>
      ) : null}
      <ButtonContainer>
        {hasOffers ? (
          !window.location.search.includes('promoterUserCode') ? (
            <Link to={'/pipeline/offered'}>
              <Button onClick={send} variant="primary">
                Ir a tramites
              </Button>
            </Link>
          ) : (
            <Button onClick={send} variant="primary">
              Ver ofertas
            </Button>
          )
        ) : (
          <Link to={addSearchParamsToUrl('/application/profiling')}>
            <Button onClick={send} variant="primary">
              Finalizar
            </Button>
          </Link>
        )}
      </ButtonContainer>
    </ResultContainer>
  );
};

const mapStateToProps = (state: any) => ({
  applicationId: state.newApplication.applicationCreation.application.id,
  creditHistoryData: state.newApplication.applicationCreation.creditHistoryData,
  isMobile: state.deviceData.isMobile,
  banksContactInfo: state.newApplication.applicationCreation.banksContactInfo,
});

const creators = {
  sendApplicationFeedback:
    applicationCreationActions.creators.sendApplicationFeedback.request,
  clearNewApplication: continueApplicationActions.creators.clearNewApplication,
};

export default connect(mapStateToProps, creators)(NewApplicationResult);
