import React, { Fragment } from 'react';
import styled from 'styled-components';

import Border from '../Border';
import Card from '../Card';

interface IInfoCard {
  data: [string, React.ReactNode][];
  title: string;
}

const Row = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  line-height: 1.57;
  padding: 0 24px;
  min-height: 60px;
  word-break: break-word;
`;

const RowKey = styled.div`
  font-weight: bold;
  width: 40%;
`;

const RowValue = styled.div`
  padding: 12px 0;
  padding-left: 8px;
  width: 60%;
`;

const InfoCard: React.FunctionComponent<IInfoCard> = ({ data, title }) => {
  return (
    <Card header={title}>
      {data.map((pair, i) => (
        <Fragment key={i}>
          <Row>
            <RowKey>{pair[0]}</RowKey>
            <RowValue>{pair[1]}</RowValue>
          </Row>
          {i !== data.length - 1 ? <Border /> : null}
        </Fragment>
      ))}
    </Card>
  );
};

export default InfoCard;
