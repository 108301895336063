import addMinutes from 'date-fns/addMinutes';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../constants/colors';
import f from '../../constants/formatters';
import grammar from '../../constants/grammar';
import useUserData from '../../hooks/useUserData';
import { getOfferData } from '../../utils/offers';
import {
  isBankUser,
  isCreditusUser,
  isPromoterSalesperson,
  isPromoterUser,
} from '../../utils/permissions';
import Border from '../Border';
import Button from '../Button';
import CreditusScore from '../CreditusScore';
import ExapandableRow from '../ExpandableRow';
import ExpandIndicator from '../ExpandIndicator';
import TableCell from '../TableCell';

const { REACT_APP_API_HOST: HOST } = process.env;

const VisibleRowContainer = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  width: 100%;
`;

const ExpandedSectionContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100px;
`;

const SizebleDiv = styled.div<{ width: string }>`
  width: ${({ width }) => width};
`;

const Label = styled.div`
  color: ${colors.DUSK50};
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 12px;
  text-transform: uppercase;
`;

const ButtonMargin = styled.div`
  margin-bottom: 12px;
`;

const Text = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 12px;
`;

const LabelAndText = ({
  label,
  text,
  width,
}: {
  label: string;
  text: string;
  width: string;
}) => (
  <SizebleDiv width={width}>
    <Label>{label}</Label>
    <Text>{text}</Text>
  </SizebleDiv>
);

interface ISecondaryButton {
  offerId: number;
  requestId: number;
  stage: string;
}

const SecondaryButton: React.FunctionComponent<ISecondaryButton> = ({
  offerId,
  requestId,
  stage,
}: ISecondaryButton) => {
  const userData = useUserData();
  const ButtonTemplate = ({ link, text }: { link: string; text: string }) => (
    <div>
      <a href={link}>
        <Button
          variant={isCreditusUser(userData) ? 'inactive' : 'secondary'}
          width={'140px'}
        >
          {text}
        </Button>
      </a>
    </div>
  );

  if (stage === 'activated' && !isBankUser(userData)) {
    return (
      <ButtonTemplate
        link={`${HOST}/api/requests/${requestId}/offers/${offerId}/pdf`}
        text={'Descargar docs.'}
      />
    );
  }

  return null;
};

interface IHiddenRowContent {
  offer: any;
  stage: string;
  offerRecordButtonDisabled: boolean;
}

const HiddenRowContent = ({
  offer,
  stage,
  offerRecordButtonDisabled,
}: IHiddenRowContent) => {
  const { id: offerId } = offer;
  const { id: requestId } = offer.request;

  return (
    <ExpandedSectionContainer>
      <LabelAndText
        label={'Condición'}
        text={grammar[offer.vehicleCondition]}
        width={'10%'}
      />
      <LabelAndText
        label={'Marca'}
        text={offer.vehicleManufacturer}
        width={'10%'}
      />
      <LabelAndText
        label={'Versión'}
        text={offer.vehicleModel}
        width={'12.5%'}
      />
      <LabelAndText
        label={'Valor del producto'}
        text={f.currency(offer.vehicleValue)}
        width={'15%'}
      />
      <LabelAndText
        label={'Enganche'}
        text={f.currency(offer.downpayment)}
        width={'20%'}
      />
      <LabelAndText
        label={'Plazo ofertado'}
        text={offer.requestedPeriod}
        width={'17.5%'}
      />
      <SizebleDiv width={'15%'}>
        <ButtonMargin>
          <Link to={`/pipeline/offer/${offerId}`}>
            <Button
              width={'140px'}
              variant={offerRecordButtonDisabled ? 'inactive' : 'primary'}
            >
              Expediente
            </Button>
          </Link>
        </ButtonMargin>
        <SecondaryButton
          offerId={offerId}
          requestId={requestId}
          stage={stage}
        />
      </SizebleDiv>
    </ExpandedSectionContainer>
  );
};

const getFullName = ({
  name,
  firstSurname,
  lastName,
  secondSurname,
}: {
  name: string;
  firstSurname: string;
  lastName: string;
  secondSurname: string;
}) => `${name} ${firstSurname || lastName} ${secondSurname}`;

const getRemainingOfferValidityDays = (validityDate: string) => {
  const date = new Date(validityDate);
  const timeZoneOffset = date.getTimezoneOffset();
  const daysRemaining = differenceInCalendarDays(
    addMinutes(date, timeZoneOffset),
    new Date()
  );
  return daysRemaining === 0 ? 'Último día' : daysRemaining;
};

interface IVisibleRowContent {
  offer: any;
  expanded: boolean;
  userData: IUserData;
}

const VisibleRowContent: React.FunctionComponent<IVisibleRowContent> = ({
  offer,
  expanded,
  userData,
}: IVisibleRowContent) => {
  const secondColumnValue = isBankUser(userData)
    ? offer.productName
    : offer.bank.name;
  const fifthColumnValue = isBankUser(userData)
    ? offer.promoter.name
    : getFullName(offer.request.user);

  return (
    <VisibleRowContainer>
      <TableCell width={'10%'}>{offer.id}</TableCell>
      <TableCell width={'10%'}>{secondColumnValue}</TableCell>
      <TableCell width={'12.5%'}>{grammar[offer.request.type]}</TableCell>
      <TableCell width={'15%'}>{f.currency(offer.creditAmount)}</TableCell>
      <TableCell width={'20%'}>{fifthColumnValue}</TableCell>
      <TableCell width={'17.5%'}>{getFullName(offer.personalData)}</TableCell>
      <TableCell width={'10%'}>
        <CreditusScore>
          {getRemainingOfferValidityDays(offer.dateVigency)}
        </CreditusScore>
      </TableCell>
      <TableCell width={'5%'}>
        <ExpandIndicator expanded={expanded} />
      </TableCell>
    </VisibleRowContainer>
  );
};

interface IOfferRowComponent {
  dataLength: number;
  datum: any;
  index: number;
  stage: string;
  userData: IUserData;
}

const RowComponent = ({
  dataLength,
  datum,
  index,
  stage,
  userData,
}: IOfferRowComponent) => {
  // Offer data is based on the stage (initial offer or final offer)
  const offer = getOfferData(datum);
  const border = dataLength === index + 1 ? null : <Border />;

  const offerRecordButtonDisabled =
    isPromoterUser(userData) &&
    isPromoterSalesperson(userData) &&
    !['offered', 'activated'].includes(stage);

  return (
    <Fragment>
      <ExapandableRow
        renderVisibleContent={expanded => (
          <VisibleRowContent
            offer={offer}
            expanded={expanded}
            userData={userData}
          />
        )}
        renderHiddenContent={() => (
          <HiddenRowContent
            offer={offer}
            stage={stage}
            offerRecordButtonDisabled={offerRecordButtonDisabled}
          />
        )}
      />
      {border}
    </Fragment>
  );
};

export default RowComponent;
