import { Formik, FormikProps } from 'formik';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import keys from 'ramda/src/keys';
import take from 'ramda/src/take';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';

import idVerificationActions from '../../actions/idVerification';
import colors from '../../constants/colors';
import f from '../../constants/formatters';
import BiometricVerificationPhotos from '../BiometricVerificationPhotos';
import Button from '../Button';
import Card from '../Card';
import { FormikDropdown, FormikText } from '../FormikInputs';
import LoadingIndicator from '../LoadingIndicator';

const BackButtonContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
`;

const DataContainer = styled.div`
  padding-bottom: 24px;
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 2fr 3fr;
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0px 20px;
`;

const InputContainer = styled.div`
  margin-bottom: 20px;
  height: 60px;
  width: 220px;
`;

const LoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 50vh;
  justify-content: center;
  width: 100%;
`;

const PairContainer = styled.div`
  display: flex;
  padding-top: 24px;
`;

const SectionName = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
  width: 220px;
`;

const SendButtonContainer = styled.div`
  height: 38px;
`;

enum VotingIdDifferences {
  WITH_DIFFERENCES = 'WITH_DIFFERENCES',
  WITHOUT_DIFFERENCES = 'WITHOUT_DIFFERENCES',
}

enum VotingIdValid {
  VALID = 'VALID',
  EXPIRED = 'EXPIRED',
}

interface IIdExtractedData {
  voterKey: string;
  emissionNumber: string;
  emissionYear: string;
  registrationYear: string;
  cic: string;
  ocr: string;
  expirationYear: string;
  [key: string]: string;
}

interface IBiometricVerificationPhoto {
  id: number;
}

interface IClient {
  name: string;
  firstSurname: string;
  secondSurname: string;
}

interface ICompliance {
  id: number;
  client: IClient;
}

interface IBiometrictVerification {
  id: number;
  photos: IBiometricVerificationPhoto[];
  idExtractedData: IIdExtractedData;
  compliance: ICompliance;
}

interface IIdVerificationDetailsStateProps {
  biometricVerifications: IBiometrictVerification[];
  creatingNewValidatedData: boolean;
}

interface IIdVerificationDetailsDispatchProps {
  fetchBiometricVerifications: Function;
  createNewValidatedData: Function;
}

interface IIdVerificationDetailsOwnProps {
  history: RouteComponentProps['history'];
  biometricVerificationId: string;
}

type IIdVerificationDetailsProps = IIdVerificationDetailsOwnProps &
  IIdVerificationDetailsDispatchProps &
  IIdVerificationDetailsStateProps;

interface ICreateValidatedData {
  validatedVoterKey: string;
  validatedEmissionNumber: string;
  validatedEmissionYear: string;
  validatedRegistrationYear: string;
  validatedExpirationYear: string;
  validatedCic: string;
  validatedOcr: string;
  voterKey: string;
  emissionNumber: string;
  emissionYear: string;
  registrationYear: string;
  expirationYear: string;
  cic: string;
  ocr: string;
  valid: string;
  differences: string;
}

interface ICreateValidatedDataKey extends ICreateValidatedData {
  [key: string]: string;
}

interface IIdVerificationForm {
  formikProps: FormikProps<ICreateValidatedDataKey>;
  creatingNewValidatedData: boolean;
  biometricVerification: IBiometrictVerification;
}

const extractedDataFieldMap: Record<string, string> = {
  voterKey: 'CLAVE DE ELECTOR',
  emissionNumber: 'NÚMERO DE EMISIÓN',
  emissionYear: 'AÑO DE EMISIÓN',
  registrationYear: 'AÑO DE REGISTRO',
  cic: 'CIC',
  ocr: 'OCR',
  expirationYear: 'AÑO DE VIGENCIA',
  validatedVoterKey: 'CLAVE DE ELECTOR',
  validatedEmissionNumber: 'NÚMERO DE EMISIÓN',
  validatedEmissionYear: 'AÑO DE EMISIÓN',
  validatedRegistrationYear: 'AÑO DE REGISTRO',
  validatedCic: 'CIC',
  validatedOcr: 'OCR',
  validatedExpirationYear: 'AÑO DE VIGENCIA',
  valid: 'ESTATUS',
  differences: 'DIFERENCIAS',
};

const statusOptions = [
  {
    text: 'Vigente',
    value: VotingIdValid.VALID,
  },
  { text: 'Vencida', value: VotingIdValid.EXPIRED },
];

const initialValues: ICreateValidatedDataKey = {
  validatedVoterKey: '',
  validatedEmissionNumber: '',
  validatedEmissionYear: '',
  validatedRegistrationYear: '',
  validatedExpirationYear: '',
  validatedCic: '',
  validatedOcr: '',
  voterKey: '',
  emissionNumber: '',
  emissionYear: '',
  registrationYear: '',
  expirationYear: '',
  cic: '',
  ocr: '',
  valid: '',
  differences: '',
};

const validatedFields = [
  'validatedVoterKey',
  'validatedEmissionNumber',
  'validatedEmissionYear',
  'validatedRegistrationYear',
  'validatedExpirationYear',
  'validatedCic',
  'validatedOcr',
];

const createValidatedDataSchema: Yup.ObjectSchema<Yup.Shape<
  {},
  ICreateValidatedData
>> = Yup.object().shape({
  voterKey: Yup.string()
    .required('La clave de elector es obligatoria')
    .matches(/^[A-Z]{6}[0-9]{8}(H|M)[0-9]{3}$/, {
      excludeEmptyString: true,
      message: 'La clave de elector no es válida',
    }),
  emissionNumber: Yup.string()
    .required('El número de emisión es obligatorio')
    .matches(/^[0-9]{2}$/, {
      excludeEmptyString: true,
      message: 'El número de emisión no es válido',
    }),
  emissionYear: Yup.string()
    .required('El año de misión es obligatorio')
    .matches(/^[0-9]{4}$/, {
      excludeEmptyString: true,
      message: 'El año de emisión no es válido',
    }),
  registrationYear: Yup.string()
    .required('El año de registro es obligatorio')
    .matches(/^[0-9]{4}$/, {
      excludeEmptyString: true,
      message: 'El año de registro no es válido',
    }),
  expirationYear: Yup.string()
    .required('El año de expiración es obligatorio')
    .matches(/^[0-9]{4}$/, {
      excludeEmptyString: true,
      message: 'El año de expiración no es válido',
    }),
  cic: Yup.string()
    .required('El CIC es obligatorio es obligatorio')
    .matches(/^[0-9]{9}$/, {
      excludeEmptyString: true,
      message: 'El CIC no es válido',
    }),
  ocr: Yup.string()
    .required('El OCR es obligatorio es obligatorio')
    .matches(/^[0-9]{13}$/, {
      excludeEmptyString: true,
      message: 'El OCR no es válido',
    }),
  validatedVoterKey: Yup.string()
    .required('La clave de elector es obligatoria')
    .matches(/^[A-Z]{6}[0-9]{8}(H|M)[0-9]{3}$/, {
      excludeEmptyString: true,
      message: 'La clave de elector no es válida',
    }),
  validatedEmissionNumber: Yup.string()
    .required('El número de emisión es obligatorio')
    .matches(/^[0-9]{2}$/, {
      excludeEmptyString: true,
      message: 'El número de emisión no es válido',
    }),
  validatedEmissionYear: Yup.string()
    .required('El año de misión es obligatorio')
    .matches(/^[0-9]{4}$/, {
      excludeEmptyString: true,
      message: 'El año de emisión no es válido',
    }),
  validatedRegistrationYear: Yup.string()
    .required('El año de registro es obligatorio')
    .matches(/^[0-9]{4}$/, {
      excludeEmptyString: true,
      message: 'El año de registro no es válido',
    }),
  validatedExpirationYear: Yup.string()
    .required('El año de expiración es obligatorio')
    .matches(/^[0-9]{4}$/, {
      excludeEmptyString: true,
      message: 'El año de expiración no es válido',
    }),
  validatedCic: Yup.string()
    .required('El CIC es obligatorio es obligatorio')
    .matches(/^[0-9]{9}$/, {
      excludeEmptyString: true,
      message: 'El CIC no es válido',
    }),
  validatedOcr: Yup.string()
    .required('El OCR es obligatorio es obligatorio')
    .matches(/^[0-9]{13}$/, {
      excludeEmptyString: true,
      message: 'El OCR no es válido',
    }),
  valid: Yup.string().required('El estatus es obligatorio'),
  differences: Yup.string().required(),
});

const IdVerificationForm: React.FunctionComponent<IIdVerificationForm> = ({
  formikProps,
  creatingNewValidatedData,
  biometricVerification,
}) => {
  const { setFieldValue, values } = formikProps;
  const { idExtractedData } = biometricVerification;

  useEffect(() => {
    const isStatusField = (key: string): boolean =>
      key === 'valid' || key === 'differences';
    const missingFields = keys(values).some(
      key => !isStatusField(key) && values[key] === ''
    );

    if (!missingFields) {
      const differentFields = keys(idExtractedData).some(key => {
        const validatedKey = `validated${f.capitalize(key)}`;
        return values[validatedKey] !== values[key];
      });
      if (differentFields) {
        setFieldValue('differences', VotingIdDifferences.WITH_DIFFERENCES);
      } else {
        setFieldValue('differences', VotingIdDifferences.WITHOUT_DIFFERENCES);
      }
    }
  }, [idExtractedData, values, setFieldValue]);

  return (
    <PairContainer>
      <FieldsContainer>
        <SectionName>Identificación</SectionName>
        {keys(biometricVerification.idExtractedData).map((key, i) => (
          <InputContainer key={i}>
            <FormikText name={key} label={extractedDataFieldMap[key]} />
          </InputContainer>
        ))}
      </FieldsContainer>
      <FieldsContainer>
        <SectionName>Instituto Nacional Electoral</SectionName>
        {validatedFields.map((key, i) => (
          <InputContainer key={i}>
            <FormikText name={key} label={extractedDataFieldMap[key]} />
          </InputContainer>
        ))}
        <InputContainer>
          <FormikText
            name={'differences'}
            label={extractedDataFieldMap['differences']}
            disabled={true}
          />
        </InputContainer>
        <InputContainer>
          <FormikDropdown
            name={'valid'}
            placeholder={'Selecciona un estatus'}
            label={extractedDataFieldMap['valid']}
            options={statusOptions}
          />
        </InputContainer>
        <SendButtonContainer>
          {creatingNewValidatedData ? (
            <LoadingIndicator />
          ) : (
            <Button onClick={() => formikProps.submitForm()}>Guardar</Button>
          )}
        </SendButtonContainer>
      </FieldsContainer>
    </PairContainer>
  );
};

const IdVerificationView: React.FunctionComponent<IIdVerificationDetailsProps> = ({
  biometricVerifications,
  fetchBiometricVerifications,
  createNewValidatedData,
  history,
  biometricVerificationId,
  creatingNewValidatedData,
}) => {
  const [biometricVerification, setBiometricVerification] = useState<
    IBiometrictVerification
  >();
  useEffect(() => {
    if (isNil(biometricVerifications) || isEmpty(biometricVerifications)) {
      fetchBiometricVerifications();
    } else {
      const parsedBiometricVerificationId = Number.parseInt(
        biometricVerificationId
      );
      const biometricVerification = biometricVerifications.find(
        biometricVerification =>
          biometricVerification.id === parsedBiometricVerificationId
      );
      isNil(biometricVerification)
        ? history.push('/id-verification')
        : setBiometricVerification(biometricVerification);
    }
  }, [
    biometricVerificationId,
    biometricVerifications,
    fetchBiometricVerifications,
    history,
  ]);

  const content = isNil(biometricVerification) ? (
    <LoadingState>
      <LoadingIndicator />
    </LoadingState>
  ) : (
    <DataContainer>
      <BiometricVerificationPhotos
        photos={take(2, biometricVerification.photos)}
      />
      <Formik
        initialValues={{
          ...initialValues,
          ...biometricVerification.idExtractedData,
        }}
        render={formikProps => (
          <IdVerificationForm
            formikProps={formikProps}
            biometricVerification={biometricVerification}
            creatingNewValidatedData={creatingNewValidatedData}
          />
        )}
        onSubmit={values =>
          createNewValidatedData({
            values,
            biometricVerificationId,
          })
        }
        validationSchema={createValidatedDataSchema}
      />
    </DataContainer>
  );

  const cardTitle = isNil(biometricVerification)
    ? `Verificación ${biometricVerificationId}`
    : `Verificación ${biometricVerificationId} - ${biometricVerification.compliance.client.name} ${biometricVerification.compliance.client.firstSurname} ${biometricVerification.compliance.client.secondSurname}`;

  return (
    <Fragment>
      <BackButtonContainer>
        <Button variant="secondary" onClick={() => history.goBack()}>
          Regresar
        </Button>
      </BackButtonContainer>
      <Card header={cardTitle}>{content}</Card>
    </Fragment>
  );
};
const mapStateToProps = (state: any): IIdVerificationDetailsStateProps => ({
  biometricVerifications: state.entities.idVerification.verifications,
  creatingNewValidatedData: state.loaders.creatingNewValidatedData,
});

const creators: IIdVerificationDetailsDispatchProps = {
  fetchBiometricVerifications:
    idVerificationActions.creators.fetchBiometricVerifications.request,
  createNewValidatedData:
    idVerificationActions.creators.createNewValidatedData.request,
};

export default connect(mapStateToProps, creators)(IdVerificationView);
