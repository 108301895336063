import { handleActions } from 'redux-actions';

const initialState = {
  account: null,
  accountFormValues: null,
  canSubmit: true,
};

const accountCreationReducer = handleActions(
  {
    ACCOUNT_CREATION_CLIENT_COMPLETED: (state, { payload }) => {
      return { ...state, account: payload, canSubmit: true };
    },
    ACCOUNT_CREATION_CLIENT_REQUESTED: (state, { payload }) => {
      return { ...state, accountFormValues: payload.client, canSubmit: false };
    },
    ACCOUNT_CREATION_CLIENT_FAILED: state => {
      return { ...state, canSubmit: true };
    },
    CLEAR_NEW_APPLICATION: () => initialState,
  },
  initialState
);

export default accountCreationReducer;
