import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../constants/colors';
import f from '../../constants/formatters';
import grammar from '../../constants/grammar';
import { isBankUser } from '../../utils/permissions';
import Border from '../Border';
import Button from '../Button';
import ExpandableRow from '../ExpandableRow';
import ExpandIndicator from '../ExpandIndicator';
import TableCell from '../TableCell';
import withUserData from '../UserDataHOC';

const VisibleRowContainer = styled.div`
  align-items: center;
  display: flex;
  height: 60px;
  width: 100%;
`;

const ExpandedSectionContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100px;
`;

const SizebleDiv = styled.div`
  width: ${({ width }) => width};
`;

const Label = styled.div`
  color: ${colors.DUSK50};
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 12px;
  text-transform: uppercase;
`;

const ButtonMargin = styled.div`
  margin-bottom: 12px;
`;

const Text = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 12px;
`;

const LabelAndText = ({ label, text, width }) => (
  <SizebleDiv width={width}>
    <Label>{label}</Label>
    <Text>{text}</Text>
  </SizebleDiv>
);

const HiddenRowContent = ({ product, stage }) => {
  const { id: offerId } = product;
  return (
    <ExpandedSectionContainer>
      <LabelAndText
        label={'Condición'}
        text={grammar[product.finalOffer.vehicleCondition]}
        width={'10%'}
      />
      <LabelAndText
        label={'Marca'}
        text={product.finalOffer.vehicle.manufacturer.name}
        width={'10%'}
      />
      <LabelAndText
        label={'Versión'}
        text={product.finalOffer.vehicle.model}
        width={'12.5%'}
      />
      <LabelAndText
        label={'Valor del producto'}
        text={f.currency(product.finalOffer.vehicleValue)}
        width={'15%'}
      />
      <LabelAndText
        label={'Enganche'}
        text={f.currency(product.finalOffer.downpayment)}
        width={'20%'}
      />
      <LabelAndText
        label={'Plazo'}
        text={`${product.finalOffer.numberPayments} ${
          grammar[product.finalOffer.paymentFrequency]
        }`}
        width={'17.5%'}
      />
      <SizebleDiv width={'15%'}>
        <ButtonMargin>
          <Link to={`/pipeline/offer/${offerId}`}>
            <Button width={'140px'}>Expediente</Button>
          </Link>
        </ButtonMargin>
      </SizebleDiv>
    </ExpandedSectionContainer>
  );
};

const getFullName = ({ name, lastName, firstSurname, secondSurname }) =>
  lastName
    ? `${name} ${lastName} ${secondSurname}`
    : `${name} ${firstSurname} ${secondSurname}`;

const VisibleRowContent = withUserData(({ product, expanded, userData }) => {
  const secondColumnValue = isBankUser(userData)
    ? product.nameProduct
    : product.bank.name;
  const fifthColumnValue = isBankUser(userData)
    ? product.request.promoter.name
    : getFullName(product.request.user);

  return (
    <VisibleRowContainer>
      <TableCell width={'10%'}>{product.id}</TableCell>
      <TableCell width={'10%'}>{secondColumnValue}</TableCell>
      <TableCell width={'12.5%'}>{grammar[product.request.type]}</TableCell>
      <TableCell width={'15%'}>
        {f.currency(product.finalOffer.creditAmount)}
      </TableCell>
      <TableCell width={'20%'}>{fifthColumnValue}</TableCell>
      <TableCell width={'17.5%'}>
        {getFullName(product.request.dataPersonals[0])}
      </TableCell>
      <TableCell width={'10%'}>
        {f.date(product.finalOffer.grantedDate.split(' ')[0])}
      </TableCell>
      <TableCell width={'5%'}>
        <ExpandIndicator expanded={expanded} />
      </TableCell>
    </VisibleRowContainer>
  );
});

const RowComponent = ({ dataLength, datum, index, stage }) => {
  const product = datum;
  const border = dataLength === index + 1 ? null : <Border />;
  return (
    <Fragment>
      <ExpandableRow
        renderVisibleContent={expanded => (
          <VisibleRowContent product={product} expanded={expanded} />
        )}
        renderHiddenContent={() => (
          <HiddenRowContent product={product} stage={stage} />
        )}
      />
      {border}
    </Fragment>
  );
};

export default RowComponent;
