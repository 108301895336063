import { FormikProps } from 'formik';
import isNil from 'ramda/src/isNil';
import length from 'ramda/src/length';
import not from 'ramda/src/not';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import colors from '../../constants/colors';
import usePostalCodeOptions from '../../hooks/usePostalCodeOptions';
import usePostalCodeRelatedFieldsAutoFill from '../../hooks/usePostalCodeRelatedFieldsAutoFill';
import usePostalCodeRelatedFieldsCleanup from '../../hooks/usePostalCodeRelatedFieldsCleanup';
import { FormikDropdown, FormikText } from '../FormikInputs';
import { Section } from '../MultiSectionForm';
import errorMsg from './errorMessages';

const postalCodeRegExp = /^\d{5}$/;

const Container = styled.div<{ isMobile: boolean }>`
  background-color: ${colors.WHITE};
  ${props => (props.isMobile ? '' : 'display: flex;')}
  height: 100%;
  justify-content: center;
  padding-top: 36px;
  width: 100%;

  & span {
    font-weight: normal;
  }
`;

const Grid = styled.div<{ isMobile: boolean }>`
  ${props =>
    props.isMobile
      ? 'margin: auto; width: 85%;'
      : 'display: grid; grid-column-gap: 24px; grid-template-columns: 280px 280px;'}
`;

const SubSectionName = styled.div<{ isMobile: boolean }>`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: bold;
  ${props =>
    props.isMobile
      ? 'margin: auto; text-align: center; padding-bottom: 10px;'
      : 'width: 128px;'}
`;

const cosignerAddressSchema = Yup.object().shape({
  street: Yup.string().required(errorMsg.cosignerAddress.street.required),
  exteriorNumber: Yup.string().required(
    errorMsg.cosignerAddress.exteriorNumber.required
  ),
  suiteNumber: Yup.string(),
  postalCode: Yup.string()
    .matches(postalCodeRegExp, {
      message: errorMsg.cosignerAddress.postalCode.matches,
    })
    .required(errorMsg.cosignerAddress.postalCode.required),
  neighborhood: Yup.string().required(
    errorMsg.cosignerAddress.neighborhood.required
  ),
  municipality: Yup.string().required(),
  city: Yup.string(),
  state: Yup.string().required(),
  country: Yup.string().required(),
});

const getAddressSectionSchema = (shouldAskForCosignerAddress: boolean) => {
  const addressSectionSchema = Yup.object().shape({
    street: Yup.string().required(errorMsg.street.required),
    exteriorNumber: Yup.string().required(errorMsg.exteriorNumber.required),
    suiteNumber: Yup.string(),
    postalCode: Yup.string()
      .matches(postalCodeRegExp, { message: errorMsg.postalCode.matches })
      .required(errorMsg.postalCode.required),
    neighborhood: Yup.string().required(errorMsg.neighborhood.required),
    municipality: Yup.string().required(),
    city: Yup.string(),
    state: Yup.string().required(),
    country: Yup.string().required(),
    cosignerAddress: cosignerAddressSchema.nullable(
      !shouldAskForCosignerAddress
    ),
  });

  return addressSectionSchema;
};

const getAddressSectionInitialValues = (
  shouldAskForCosignerAddress: boolean,
  prefillingAddress: IAddressSchema,
  addressInId: boolean
) => {
  const cosignerAddressInitialValues = {
    street: '',
    exteriorNumber: '',
    suiteNumber: '',
    postalCode: '',
    neighborhood: '',
    municipality: '',
    city: '',
    state: '',
    country: '',
  };

  const addressInitialValues = {
    street: '',
    exteriorNumber: '',
    suiteNumber: '',
    postalCode: '',
    neighborhood: '',
    municipality: '',
    city: '',
    state: '',
    country: '',
    cosignerAddress: shouldAskForCosignerAddress
      ? cosignerAddressInitialValues
      : null,
  };

  const addressInitialValuesPrefilling = isNil(prefillingAddress)
    ? addressInitialValues
    : {
        street: prefillingAddress.street || '',
        exteriorNumber: prefillingAddress.exteriorNumber || '',
        suiteNumber: prefillingAddress.interiorNumber || '',
        postalCode: prefillingAddress.postalCode || '',
        neighborhood: prefillingAddress.neighborhood || '',
        municipality: prefillingAddress.district || '',
        city: '',
        state: '',
        country: '',
        cosignerAddress: shouldAskForCosignerAddress
          ? cosignerAddressInitialValues
          : null,
      };

  return addressInId ? addressInitialValuesPrefilling : addressInitialValues;
};

interface IAddressSchema {
  street: string | null;
  interiorNumber: string | null;
  exteriorNumber: string | null;
  neighborhood: string | null;
  postalCode: string | null;
  district: string | null;
  state: string | null;
}

interface IAddressSectionComponent {
  isMobile: boolean;
  formikProps: FormikProps<any>;
}

const AddressSectionComponent: React.FunctionComponent<IAddressSectionComponent> = ({
  isMobile,
  formikProps,
}) => {
  const options = usePostalCodeOptions(formikProps, 'postalCode');
  usePostalCodeRelatedFieldsAutoFill(formikProps, options);
  usePostalCodeRelatedFieldsCleanup(formikProps, formikProps.values.postalCode);

  const cosignerAddressOptions = usePostalCodeOptions(
    formikProps,
    'cosignerAddress.postalCode'
  );
  usePostalCodeRelatedFieldsAutoFill(
    formikProps,
    cosignerAddressOptions,
    'cosignerAddress'
  );
  usePostalCodeRelatedFieldsCleanup(
    formikProps,
    formikProps.values.cosignerAddress?.postalCode,
    'cosignerAddress'
  );

  const shouldRenderCosignerAddressInputs =
    formikProps.values.cosignerAddress !== null;

  return (
    <Fragment>
      <Container isMobile={isMobile}>
        <SubSectionName isMobile={isMobile}>
          Domicilio del solicitante
        </SubSectionName>
        <Grid isMobile={isMobile}>
          <FormikText label="CALLE" name="street" />
          <FormikText
            label="NÚMERO EXTERIOR"
            name="exteriorNumber"
            inputMode="numeric"
          />
          <FormikText label="NÚMERO INTERIOR" name="suiteNumber" />
          <FormikText
            label="CÓDIGO POSTAL"
            name="postalCode"
            maxLength={5}
            inputMode="numeric"
          />
          <FormikDropdown
            disabled={not(length(options))}
            label="COLONIA"
            name="neighborhood"
            options={options.map(option => ({
              text: option.colonia,
              value: option.colonia,
            }))}
            placeholder="Colonia"
          />
        </Grid>
      </Container>
      {shouldRenderCosignerAddressInputs ? (
        <Container isMobile={isMobile}>
          <SubSectionName isMobile={isMobile}>
            Domicilio del coacreditado
          </SubSectionName>
          <Grid isMobile={isMobile}>
            <FormikText label="CALLE" name="cosignerAddress.street" />
            <FormikText
              label="NÚMERO EXTERIOR"
              name="cosignerAddress.exteriorNumber"
              inputMode="numeric"
            />
            <FormikText
              label="NÚMERO INTERIOR"
              name="cosignerAddress.suiteNumber"
            />
            <FormikText
              label="CÓDIGO POSTAL"
              name="cosignerAddress.postalCode"
              maxLength={5}
              inputMode="numeric"
            />
            <FormikDropdown
              disabled={not(length(cosignerAddressOptions))}
              label="COLONIA"
              name="cosignerAddress.neighborhood"
              options={cosignerAddressOptions.map(option => ({
                text: option.colonia,
                value: option.colonia,
              }))}
              placeholder="Colonia"
            />
          </Grid>
        </Container>
      ) : null}
    </Fragment>
  );
};

const addressStateProps = (state: any): { isMobile: boolean } => ({
  isMobile: state.deviceData.isMobile,
});

const AddressSectionWithProps = connect(addressStateProps)(
  AddressSectionComponent
);

interface IAddressSectionConfig {
  shouldAskForCosignerAddress: boolean;
  addressInId: boolean;
  prefillingAddress: IAddressSchema;
}

const AddressSection = ({
  shouldAskForCosignerAddress,
  addressInId,
  prefillingAddress,
}: IAddressSectionConfig) => (
  <Section
    component={AddressSectionWithProps}
    initialValues={getAddressSectionInitialValues(
      shouldAskForCosignerAddress,
      prefillingAddress,
      addressInId
    )}
    name="address"
    sectionSchema={getAddressSectionSchema(shouldAskForCosignerAddress)}
  />
);

export default AddressSection;
