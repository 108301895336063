import contains from 'ramda/src/contains';
import without from 'ramda/src/without';
import React from 'react';
import styled from 'styled-components';

import Checkbox from '../Checkbox';
import Label from '../Label';

const Checkboxes = styled.div`
  display: flex;
  justify-content: space-between;
  width: 171px;
`;

const CheckboxContainer = styled.div`
  display: flex;
`;

const CheckboxText = styled.div`
  font-size: 14px;
  margin-left: 8px;
`;

const LabelContainer = styled.div`
  margin-bottom: 15px;
`;

const MultiCheckbox = ({ label, onBlur, onChange, options, value }) => {
  const handleOptionChange = (option, optionChecked) => {
    if (optionChecked) {
      onChange([...value, option]);
    } else {
      onChange(without([option], value));
    }
  };

  return (
    <div>
      <LabelContainer>
        <Label>{label}</Label>
      </LabelContainer>
      <Checkboxes>
        {options.map((option, i) => (
          <CheckboxContainer key={i}>
            <Checkbox
              checked={contains(option, value)}
              onBlur={onBlur}
              onChange={checked => handleOptionChange(option, checked)}
            />
            <CheckboxText>{option}</CheckboxText>
          </CheckboxContainer>
        ))}
      </Checkboxes>
    </div>
  );
};

export default MultiCheckbox;
