import React, { Fragment } from 'react';

import Border from '../Border';
import SmartTable, { TableCell } from '../SmartTable';

export const getTableValues = (
  values: Array<any>,
  numberOfColumns: number
): Array<any> => {
  const tableValues: Array<Array<any>> = [];
  for (let i = 0; i < values.length; i += numberOfColumns) {
    tableValues.push(values.slice(i, i + numberOfColumns));
  }

  return tableValues;
};

interface IMultiSingleRowTable {
  headersArray: string[][];
  valuesArray: string[][];
  gridColumns: string;
}

const MultiSingleRowTable: React.FunctionComponent<IMultiSingleRowTable> = ({
  headersArray,
  valuesArray,
  gridColumns,
}) => {
  const rowQty = headersArray.length;

  return (
    <Fragment>
      {headersArray.map((headers, i) => (
        <Fragment key={i}>
          <SmartTable
            data={[null]}
            gridColumns={gridColumns}
            headers={headers}
            renderRow={() =>
              valuesArray[i].map((value, i) => (
                <TableCell key={i}>{value}</TableCell>
              ))
            }
          />
          {i < rowQty - 1 ? <Border /> : null}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default MultiSingleRowTable;
