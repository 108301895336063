import { call, put, takeLatest } from 'redux-saga/effects';

import creditusActions from '../actions/creditus';
import toastActions from '../actions/toastNotifications';
import {
  buildErrorToast,
  buildSuccessToast,
} from '../utils/toastNotifications';
import runner from './runners';

function* createPromoter(action: IReduxAction<any>) {
  const { error } = yield call(
    runner,
    creditusActions.creators.createPromoter,
    {
      method: 'POST',
      payload: action.payload,
      url: 'api/promoters',
    },
    { snakeCase: false }
  );

  yield put(
    toastActions.creators.showToast(
      error
        ? buildErrorToast('Error', 3000)
        : buildSuccessToast('Success', 3000)
    )
  );
}

function* watchCreatePromoterRequested() {
  yield takeLatest(
    creditusActions.types.CREDITUS_PROMOTER_CREATE.REQUESTED,
    createPromoter
  );
}

export default {
  watchCreatePromoterRequested,
};
