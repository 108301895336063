import styled from 'styled-components';

import colors from '../../constants/colors';

const getStatusIndicatorColor = (status: DocumentStatus) => {
  switch (status) {
    case 'approved':
      return colors.GREEN;
    case 'rejected':
      return colors.RED;
    case 'required':
      return colors.ORANGE;
    case 'validation':
    default:
      return colors.SECONDARY_TEXT;
  }
};

const StatusIndicator = styled.div<{ status: DocumentStatus }>`
  border-radius: 6px;
  background-color: ${({ status }) => getStatusIndicatorColor(status)};
  height: 12px;
  margin-right: 8px;
  width: 12px;
`;

export default StatusIndicator;
