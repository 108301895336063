import { call, takeLatest } from 'redux-saga/effects';

import productsActions from '../actions/products';
import { addSearchParamsToPayload } from '../utils/misc';
import runner from './runners';

function* fetchProductsGranted(action) {
  yield call(runner, productsActions.creators.fetchProductsGranted, {
    method: 'GET',
    url: '/api/offers/granted',
    params: action.payload,
  });
}

function* fetchPromoterProducts(action) {
  const params = addSearchParamsToPayload(action.payload, ['promoterUserCode']);
  yield call(runner, productsActions.creators.fetchPromoterProducts, {
    method: 'GET',
    url: 'api/promoters/products',
    params: params,
  });
}

function* fetchProductsManufacturers(action) {
  const params = addSearchParamsToPayload(action.payload, ['promoterUserCode']);
  yield call(runner, productsActions.creators.fetchProductsManufacturers, {
    method: 'GET',
    url: 'api/promoters/vehicles',
    params: params,
  });
}

function* watchProductGrantedFetchRequested() {
  yield takeLatest(
    productsActions.types.PRODUCTS_GRANTED_FETCH.REQUESTED,
    fetchProductsGranted
  );
}

function* watchPromoterProductsFetchRequested() {
  yield takeLatest(
    productsActions.types.PROMOTER_PRODUCTS_FETCH.REQUESTED,
    fetchPromoterProducts
  );
}

function* watchProductsManufacturersRequested() {
  yield takeLatest(
    productsActions.types.PRODUCTS_MANUFACTURERS_FETCH.REQUESTED,
    fetchProductsManufacturers
  );
}

export default {
  watchProductGrantedFetchRequested,
  watchProductsManufacturersRequested,
  watchPromoterProductsFetchRequested,
};
