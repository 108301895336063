import { differenceInMonths, differenceInYears } from 'date-fns';
import isNil from 'ramda/src/isNil';

import { STATE_OF_BIRTH_OPTIONS } from '../../constants/applicationOptions';
import f from '../../constants/formatters';
import { ICountry } from '../../hooks/useFetchCountries';

interface IVehicleManufacturer {
  name: string;
}

interface IVehicle {
  model: string;
}

interface IVehicleFinanceApplication {
  vehicleCondition: string;
  model: string;
  valueNew: number;
  valuePreowned: number;
  downpayment: number;
  creditNumberPayments: number;
  creditInsuranceType: string;
  vehicle: IVehicle;
  vehicleManufacturer: IVehicleManufacturer;
}

interface IClient {
  email: string;
  phoneNumber: string;
}

interface ICompliance {
  client: IClient;
}

interface ICosignerData {
  name: string;
  firstSurname: string;
  secondSurname: string;
  sex: string;
  dateOfBirth: string;
  countryOfBirth: string;
  nationality: string;
  stateOfBirth: string;
  maritalStatus: string;
  matrimonialRegime: string;
  taxpayerId: string;
  curp: string;
  educationLevel: string;
  income: number;
  employmentType: string;
  employmentName: string;
  employmentActivity: string;
  employmentPosition: string;
  employmentSector: string;
  employmentStreet: string;
  employmentExteriorNumber: string;
  employmentSuiteNumber: string;
  employmentPostalCode: string;
  employmentNeighborhood: string;
  employmentCity: string;
  employmentMunicipality: string;
  employmentState: string;
  employmentCountry: string;
  addressStreet: string;
  addressExteriorNumber: string;
  addressSuiteNumber: string;
  addressPostalCode: string;
  addressNeighborhood: string;
  addressMunicipality: string;
  addressCity: string;
  addressState: string;
  addressCountry: string;
}

interface IReferences {
  ref1Name: string;
  ref1FirstSurname: string;
  ref1SecondSurname: string;
  ref1PhoneNumber: string;
  ref1Relation: string;
  ref2Name: string;
  ref2FirstSurname: string;
  ref2SecondSurname: string;
  ref2PhoneNumber: string;
  ref2Relation: string;
}

interface IClientPreviousEmployment {
  companyName: string;
  startDate: string;
  endDate: string;
  companyPhoneNumber: string;
}

interface IClientEmployment {
  income: number;
  otherIncome: number;
  soureOtherIncome: string;
  companyIndustryType: string;
  companyName: string;
  companyActivity: string;
  employmentType: string;
  contractType: string;
  position: string;
  companySector: string;
  startDate: string;
  companyPhoneNumber: string;
  street: string;
  exteriorNumber: string;
  suiteNumber: string;
  city: string;
  postalCode: string;
  neighborhood: string;
  municipality: string;
  state: string;
  country: string;
}

export interface IClientData {
  name: string;
  firstSurname: string;
  secondSurname: string;
  sex: string;
  dateOfBirth: string;
  age: number;
  countryOfBirth: string;
  nationality: string;
  stateOfBirth: string;
  maritalStatus: string;
  matrimonialRegime: string;
  taxpayerId: string;
  curp: string;
  educationLevel: string;
  nameSpouse: string;
  firstSurnameSpouse: string;
  secondSurnameSpouse: string;
  sexSpouse: string;
  dateOfBirthSpouse: string;
  ageSpouse: number;
  countryOfBirthSpouse: string;
  nationalitySpouse: string;
  stateOfBirthSpouse: string;
  taxpayerIdSpouse: string;
  curpSpouse: string;
  educationLevelSpouse: string;
  hasCosigner: string;
  hasEmployment: string;
  hasPreviousEmployment: string;
}

interface IClientAddress {
  street: string;
  exteriorNumber: string;
  suiteNumber: string;
  postalCode: string;
  neighborhood: string;
  municipality: string;
  city: string;
  state: string;
  country: string;
  addressType: string;
  monthlyRent: number;
  antiquity: string;
  dependentsQuantity: number;
}

export type IApplicationDetails =
  | {
      clientAddress: IClientAddress;
      clientData: IClientData;
      clientEmployment: IClientEmployment;
      clientPreviousEmployment: IClientPreviousEmployment | null;
      references: IReferences;
      cosignerData: ICosignerData | null;
      compliance: ICompliance;
      motorcycleFinanceApplication: IVehicleFinanceApplication;
      carFinanceApplication: null;
    }
  | {
      clientAddress: IClientAddress;
      clientData: IClientData;
      clientEmployment: IClientEmployment;
      clientPreviousEmployment: IClientPreviousEmployment | null;
      references: IReferences;
      cosignerData: ICosignerData | null;
      compliance: ICompliance;
      motorcycleFinanceApplication: null;
      carFinanceApplication: IVehicleFinanceApplication;
    };

enum YesNoOptionsSpanish {
  YES = 'si',
  NO = 'no',
}

const translations: Record<string, string> = {
  new: 'Nuevo',
  used: 'Seminuevo',
  fixed_term: 'Tiempo determinado',
  undetermined_term: 'Tiempo indeterminado',
  temporary: 'Temporal',
  trial_period: 'Periodo de prueba',
  H: 'Hombre',
  M: 'Mujer',
};

export const buildApplicationDetailsObject = (
  applicationDetails: IApplicationDetails,
  countries: ICountry[]
) => {
  const clientData = applicationDetails.clientData;
  const clientAddress = applicationDetails.clientAddress;
  const cosignerData = applicationDetails.cosignerData;
  const clientEmployment = applicationDetails.clientEmployment;
  const clientPreviousEmployment = applicationDetails.clientPreviousEmployment;
  const clientContact = applicationDetails.compliance.client;
  const references = applicationDetails.references;

  const spouseNationality = countries.find(
    country => country.key === clientData.nationalitySpouse
  );
  const spouseStateOfBirth = STATE_OF_BIRTH_OPTIONS.find(
    state => state.value === clientData.stateOfBirthSpouse
  );
  const spouse =
    clientData.maritalStatus === 'casado'
      ? {
          name: clientData.nameSpouse,
          firstSurname: clientData.firstSurnameSpouse,
          secondSurname: clientData.secondSurnameSpouse,
          dateOfBirth: clientData.dateOfBirthSpouse,
          sex: translations[clientData.sexSpouse],
          countryOfBirth: clientData.countryOfBirthSpouse,
          nationality: isNil(spouseNationality)
            ? ''
            : spouseNationality.nationality,
          stateOfBirth: isNil(spouseStateOfBirth)
            ? ''
            : spouseStateOfBirth.text,
          taxpayerId: clientData.taxpayerIdSpouse,
          curp: clientData.curpSpouse,
          educationLevel: clientData.educationLevelSpouse,
        }
      : null;

  const cosignerNationality = countries.find(
    country => !isNil(cosignerData) && country.key === cosignerData.nationality
  );
  const cosignerStateOfBirth = STATE_OF_BIRTH_OPTIONS.find(
    state => !isNil(cosignerData) && state.value === cosignerData.stateOfBirth
  );
  const cosigner =
    clientData.hasCosigner === YesNoOptionsSpanish.YES && !isNil(cosignerData)
      ? {
          name: cosignerData.name,
          firstSurname: cosignerData.firstSurname,
          secondSurname: cosignerData.secondSurname,
          dateOfBirth: cosignerData.dateOfBirth,
          sex: translations[cosignerData.sex],
          countryOfBirth: cosignerData.countryOfBirth,
          nationality: isNil(cosignerNationality)
            ? ''
            : cosignerNationality.nationality,
          stateOfBirth: isNil(cosignerStateOfBirth)
            ? ''
            : cosignerStateOfBirth.text,
          maritalStatus: cosignerData.maritalStatus,
          matrimonialRegime: cosignerData.matrimonialRegime,
          curp: cosignerData.curp,
          taxpayerId: cosignerData.taxpayerId,
          educationLevel: cosignerData.educationLevel,
        }
      : null;

  const cosignerAddress =
    clientData.hasCosigner === YesNoOptionsSpanish.YES && !isNil(cosignerData)
      ? {
          street: cosignerData.addressStreet,
          exteriorNumber: cosignerData.addressExteriorNumber,
          suiteNumber: cosignerData.addressSuiteNumber,
          postalCode: cosignerData.addressPostalCode,
          neighborhood: cosignerData.addressNeighborhood,
          municipality: cosignerData.addressMunicipality,
          city: cosignerData.addressCity,
          state: cosignerData.addressState,
        }
      : null;

  const cosignerEmployment = (() => {
    if (
      clientData.hasCosigner === YesNoOptionsSpanish.YES &&
      !isNil(cosignerData)
    ) {
      if (
        cosignerData.employmentType === 'empleado' ||
        cosignerData.employmentType === 'independiente'
      ) {
        return {
          employmentType: cosignerData.employmentType,
          companyName: cosignerData.employmentName,
          activityType: cosignerData.employmentActivity,
          companySector: cosignerData.employmentSector,
          position: cosignerData.employmentPosition,
          fixedIncome: f.currency(cosignerData.income),
        };
      } else {
        return {
          employmentType: cosignerData.employmentType,
          fixedIncome: f.currency(cosignerData.income),
        };
      }
    } else {
      return null;
    }
  })();

  const cosignerEmploymentAddress =
    clientData.hasCosigner === YesNoOptionsSpanish.YES &&
    !isNil(cosignerData) &&
    (cosignerData.employmentType === 'empleado' ||
      cosignerData.employmentType === 'independiente')
      ? {
          street: cosignerData.employmentStreet,
          exteriorNumber: cosignerData.employmentExteriorNumber,
          suiteNumber: cosignerData.employmentSuiteNumber,
          postalCode: cosignerData.employmentPostalCode,
          neighborhood: cosignerData.employmentNeighborhood,
          city: cosignerData.employmentCity,
          municipality: cosignerData.employmentMunicipality,
          state: cosignerData.employmentState,
        }
      : null;

  const employment =
    clientEmployment.employmentType === 'empleado' ||
    clientEmployment.employmentType === 'independiente'
      ? {
          employmentType: clientEmployment.employmentType,
          contractType: translations[clientEmployment.contractType],
          companyName: clientEmployment.companyName,
          industryType: clientEmployment.companyIndustryType,
          activityType: clientEmployment.companyActivity,
          companySector: clientEmployment.companySector,
          position: clientEmployment.position,
          phoneNumber: clientEmployment.companyPhoneNumber,
          fixedIncome: f.currency(clientEmployment.income),
          dateEntryCompany: clientEmployment.startDate,
          yearsInEmployment: differenceInYears(
            new Date(),
            clientEmployment.startDate
          ),
          monthsInEmployment:
            differenceInMonths(new Date(), clientEmployment.startDate) -
            differenceInYears(new Date(), clientEmployment.startDate) * 12,
          sourceOthers: clientEmployment.soureOtherIncome,
          otherIncome: f.currency(clientEmployment.otherIncome),
        }
      : {
          employmentType: clientEmployment.employmentType,
          fixedIncome: f.currency(clientEmployment.income),
          sourceOthers: clientEmployment.soureOtherIncome,
          otherIncome: f.currency(clientEmployment.otherIncome),
        };

  const employmentAddress =
    clientEmployment.employmentType === 'empleado' ||
    clientEmployment.employmentType === 'independiente'
      ? {
          street: clientEmployment.street,
          exteriorNumber: clientEmployment.exteriorNumber,
          suiteNumber: clientEmployment.suiteNumber,
          postalCode: clientEmployment.postalCode,
          neighborhood: clientEmployment.neighborhood,
          municipality: clientEmployment.municipality,
          city: clientEmployment.city,
          state: clientEmployment.state,
        }
      : null;

  const previousEmployment =
    clientData.hasPreviousEmployment === YesNoOptionsSpanish.YES &&
    !isNil(clientPreviousEmployment)
      ? {
          companyName: clientPreviousEmployment.companyName,
          dateEntryCompany: clientPreviousEmployment.startDate,
          dateTerminationCompany: clientPreviousEmployment.endDate,
          phoneNumber: clientPreviousEmployment.companyPhoneNumber,
        }
      : null;

  const vehicleCredit =
    applicationDetails.motorcycleFinanceApplication ||
    applicationDetails.carFinanceApplication;

  const clientNationality = countries.find(
    country => country.key === clientData.nationality
  );
  const clientStateOfBirth = STATE_OF_BIRTH_OPTIONS.find(
    state => state.value === clientData.stateOfBirth
  );
  const result = {
    asset: {
      vehicleCondition: translations[vehicleCredit.vehicleCondition],
      brand: vehicleCredit.vehicleManufacturer.name,
      version: vehicleCredit.vehicle.model,
      model: vehicleCredit.model,
      price: f.currency(
        vehicleCredit.valueNew > 0
          ? vehicleCredit.valueNew
          : vehicleCredit.valuePreowned
      ),
      downpayment: f.currency(vehicleCredit.downpayment),
      creditNumberPayments: vehicleCredit.creditNumberPayments,
      creditInsuranceType: vehicleCredit.creditInsuranceType,
    },
    client: {
      name: clientData.name,
      firstSurname: clientData.firstSurname,
      secondSurname: clientData.secondSurname,
      dateOfBirth: clientData.dateOfBirth,
      age: clientData.age,
      sex: translations[clientData.sex],
      countryOfBirth: clientData.countryOfBirth,
      nationality: isNil(clientNationality)
        ? ''
        : clientNationality.nationality,
      stateOfBirth: isNil(clientStateOfBirth) ? '' : clientStateOfBirth.text,
      maritalStatus: clientData.maritalStatus,
      matrimonialRegime: clientData.matrimonialRegime,
      curp: clientData.curp,
      taxpayerId: clientData.taxpayerId,
      educationLevel: clientData.educationLevel,
      email: clientContact.email,
      phoneNumber: clientContact.phoneNumber,
    },
    references: [
      {
        name: references.ref1Name,
        firstSurname: references.ref1FirstSurname,
        secondSurname: references.ref1SecondSurname,
        phoneNumber: references.ref1PhoneNumber,
        relation: references.ref1Relation,
      },
      {
        name: references.ref2Name,
        firstSurname: references.ref2FirstSurname,
        secondSurname: references.ref2SecondSurname,
        phoneNumber: references.ref2PhoneNumber,
        relation: references.ref2Relation,
      },
    ],
    address: {
      dependentsNumber: clientAddress.dependentsQuantity,
      street: clientAddress.street,
      exteriorNumber: clientAddress.exteriorNumber,
      suiteNumber: clientAddress.suiteNumber,
      postalCode: clientAddress.postalCode,
      neighborhood: clientAddress.neighborhood,
      municipality: clientAddress.municipality,
      city: clientAddress.city,
      state: clientAddress.state,
      addressType: clientAddress.addressType,
      rent:
        clientAddress.monthlyRent > 0
          ? f.currency(clientAddress.monthlyRent)
          : '',
      yearsInAddress: differenceInYears(
        new Date(),
        new Date(clientAddress.antiquity)
      ),
      monthsInAddress:
        differenceInMonths(new Date(), new Date(clientAddress.antiquity)) -
        differenceInYears(new Date(), new Date(clientAddress.antiquity)) * 12,
    },
    spouse,
    cosigner,
    cosignerAddress,
    cosignerEmployment,
    cosignerEmploymentAddress,
    employment,
    employmentAddress,
    previousEmployment,
  };

  return result;
};
