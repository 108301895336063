import { call, takeLatest } from 'redux-saga/effects';

import vehiclesActions from '../actions/vehicles';
import runner from './runners';

function* fetchManufacturers(action) {
  yield call(runner, vehiclesActions.creators.fetchVehicleManufacturers, {
    method: 'GET',
    url: '/api/manufacturers',
    params: action.payload,
  });
}

function* watchFetchManufacturersRequested() {
  yield takeLatest(
    vehiclesActions.types.VEHICLES_FETCH_MANUFACTURERS.REQUESTED,
    fetchManufacturers
  );
}

function* fetchModels(action) {
  const { manufacturerId, vehicleType } = action.payload;

  yield call(runner, vehiclesActions.creators.fetchVehicleModels, {
    method: 'GET',
    url: `/api/manufacturer/${manufacturerId}/vehicle/${vehicleType}`,
  });
}

function* watchFetchModelsRequested() {
  yield takeLatest(
    vehiclesActions.types.VEHICLES_FETCH_MODELS.REQUESTED,
    fetchModels
  );
}

export default {
  watchFetchManufacturersRequested,
  watchFetchModelsRequested,
};
