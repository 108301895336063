import { handleActions } from 'redux-actions';

import utils from './utils';

const initialState = null;

const invoicesReducer = handleActions(
  {
    INVOICES_FETCH_COMPLETED: (state, { payload }) =>
      utils.buildDictWithKey(payload, 'id'),
    INVOICE_FETCH_COMPLETED: (state, { payload }) => ({
      ...state,
      [payload.id]: payload,
    }),
  },
  initialState
);

export default invoicesReducer;
