import prop from 'ramda/src/prop';
import styled from 'styled-components';

import colors from '../../constants/colors';

const ColorLabel = styled.div<{ color: string; width?: string }>`
  align-items: center;
  background-color: ${prop('color')};
  border-radius: 4px;
  color: ${colors.WHITE};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 22px;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  width: ${({ width }) => width || 'auto'};
`;

export default ColorLabel;
