import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../constants/colors';
import withUserData from '../UserDataHOC';
import getNavigatorConfig from './navigatorConfig';

const StagesContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 8px;
  padding-top: 8px;
`;

const TitleContainer = styled.div`
  align-items: center;
  border-bottom: solid 1px ${colors.BORDER};
  display: flex;
  height: 76px;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const Container = styled.div`
  background-color: ${colors.WHITE};
  margin-bottom: 24px;
`;

const StageContainer = styled.div`
  height: 80px;
  overflow: visible;
  pointer-events: none;
  position: relative;
  width: ${({ type }) => (type === 'first' ? '116px' : '138px')};
`;

const TextContainer = styled.div`
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  padding-left: 28px;
  pointer-events: none;
  position: absolute;
  width: ${({ type }) => (type === 'first' ? '116px' : '138px')};
`;

const AlignLeft = styled.div`
  text-align: right;
`;

const getSVGWidth = ({ type }) => {
  switch (type) {
    case 'first':
      return '150px';
    default:
      return '170px';
  }
};

const Svg = styled.svg`
  height: 80px;
  left: 0;
  position: absolute;
  top: 0;
  width: ${getSVGWidth};
`;

const Polygon = styled.polygon`
  cursor: pointer;
  fill: ${({ active }) => (active ? colors.AQUA25 : colors.WHITE)};
  fill-rule: nonzero;
  pointer-events: all;
`;

const Polyline = styled.polyline`
  fill: none;
  stroke: ${({ active }) => (active ? colors.AQUA : colors.BORDER)};
  stroke-width: 2;
`;

const Text = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: ${({ fontWeight }) => fontWeight || '300'};
`;

const getPolygonPoints = stageType => {
  switch (stageType) {
    case 'first':
      return '2,2 114,2 148,38 114,78 2,78';
    case 'last':
      return '2,2 136,2 136,78 2,78 34,38';
    default:
      return '2,2 136,2 170,38 136,78 2,78 34,38';
  }
};

const getFirstStagePolyline = (active, beforeActive) => (
  <Polyline
    active={active}
    points={
      beforeActive
        ? '116,2 2,2 2,78 116,78'
        : '2,2 116,2 148,39 116,78 2,78 2,2'
    }
  />
);

const getLastStagePolyline = active => (
  <Polyline
    active={active}
    points={
      active ? '2,2 136,2 136,78 2,78 34,38 2,2' : '2,2 136,2 136,78 2,78'
    }
  />
);

const getIntermediateStagePolyline = (active, beforeActive) =>
  beforeActive ? (
    [
      <Polyline key={0} points={'2,2 138,2'} />,
      <Polyline key={1} points={'2,78 138,78'} />,
    ]
  ) : (
    <Polyline
      active={active}
      points={
        active
          ? '2,2 136,2 168,38 136,78 2,78 34,38 2,2'
          : '2,2 138,2 168,38 138,78 2,78'
      }
    />
  );

const getPolyline = (stageType, active, beforeActive) => {
  switch (stageType) {
    case 'first':
      return getFirstStagePolyline(active, beforeActive);
    case 'last':
      return getLastStagePolyline(active);
    default:
      return getIntermediateStagePolyline(active, beforeActive);
  }
};

const Stage = withRouter(
  ({ active, beforeActive, count, history, path, quantity, text, type }) => (
    <StageContainer type={type}>
      <Svg type={type}>
        <Polygon
          active={active}
          onClick={() => history.push(path)}
          points={getPolygonPoints(type)}
        />
        {getPolyline(type, active, beforeActive)}
      </Svg>
      <TextContainer type={type}>
        <AlignLeft>
          <Text>{text}</Text>
          <Text fontWeight={'bold'}>{count}</Text>
        </AlignLeft>
      </TextContainer>
    </StageContainer>
  )
);

const getStepTypeByIndex = (steps, index) => {
  if (index === 0) {
    return 'first';
  }
  if (index === steps.length - 1) {
    return 'last';
  }
  return 'intermediate';
};

const PipelineNavigator = withUserData(({ match, userData }) => {
  const steps = getNavigatorConfig(userData);
  const activeStep = steps.findIndex(step => step.path === match.url);

  const stages = steps.map(({ path, stateSelector, text }, i) => {
    const stageType = getStepTypeByIndex(steps, i);

    const ConnectedStage = connect(stateSelector)(({ count }) => (
      <Stage
        active={activeStep === i}
        beforeActive={activeStep === i + 1}
        count={count}
        path={path}
        text={text}
        type={stageType}
      />
    ));

    return <ConnectedStage key={i} />;
  });

  return (
    <Container>
      <TitleContainer>
        <Title>Proceso</Title>
      </TitleContainer>
      <StagesContainer>{stages}</StagesContainer>
    </Container>
  );
});

export default () => (
  <Route children={({ match }) => <PipelineNavigator match={match} />} />
);
