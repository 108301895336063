import isNil from 'ramda/src/isNil';
import { Effect, call, put, select, takeLatest } from 'redux-saga/effects';

import toastActions from '../actions/toastNotifications';
import userManagementActions from '../actions/userManagement';
import { BankUserRoles, PromoterUserRoles } from '../constants/enums';
import { isBankUser, isPromoterUser } from '../utils/permissions';
import {
  buildErrorToast,
  buildSuccessToast,
} from '../utils/toastNotifications';
import runner from './runners';

function* fetchUsers(): Iterator<Effect> {
  // eslint-disable-next-line
  // @ts-ignore
  const { userData }: { userData: IUserData } = yield select(
    (state: any) => state.auth
  );

  const endpoint = (() => {
    if (isBankUser(userData)) {
      return `/api/banks/${userData.organization.bankId}/users`;
    } else if (isPromoterUser(userData)) {
      return `/api/promoters/users`;
    }
    return null;
  })();

  if (endpoint === null) {
    return;
  }

  yield call(runner, userManagementActions.creators.fetchUsers, {
    method: 'GET',
    url: endpoint,
  });
}

function* createUser(action: IReduxAction<any>): Iterator<Effect> {
  // eslint-disable-next-line
  // @ts-ignore
  const { userData }: { userData: IUserData } = yield select(
    (state: any) => state.auth
  );
  const { values } = action.payload;

  const endpoint = (() => {
    if (isBankUser(userData)) {
      return `/api/banks/${userData.organization.bankId}/user`;
    }
    if (isPromoterUser(userData)) {
      return `/api/promoters/user`;
    }
    return null;
  })();

  if (endpoint === null) {
    return;
  }

  // eslint-disable-next-line
  // @ts-ignore
  const { error } = yield call(
    runner,
    userManagementActions.creators.createUser,
    {
      method: 'POST',
      url: endpoint,
      payload: values,
    },
    { snakeCase: isBankUser(userData) }
  );

  const toast = (() => {
    if (isNil(error)) {
      return buildSuccessToast('Usuario creado exitosamente.', 5000);
    } else if (
      !isNil(error.response.data.error) &&
      error.response.data.error === 'EMAIL_ALREADY_IN_USE'
    ) {
      return buildErrorToast(
        'El correo del usuario que está tratando de crear ya está en uso. Por favor, utilice otro.',
        5000
      );
    } else {
      return buildErrorToast(
        'Hubo un error al crear el usuario. Podemos atenderte en el chat de ayuda.',
        5000
      );
    }
  })();
  yield put(toastActions.creators.showToast(toast));
}

interface IChangeUserStatusPayload {
  userId: number;
}

function* changeUserStatus(
  action: IReduxAction<IChangeUserStatusPayload>
): Iterator<Effect> {
  // eslint-disable-next-line
  // @ts-ignore
  const { userData }: { userData: IUserData } = yield select(
    (state: any) => state.auth
  );
  const { userId } = action.payload;

  const endpoint = (() => {
    if (isBankUser(userData)) {
      return `/api/banks/${userData.organization.bankId}/user/${userId}/status`;
    }
    if (isPromoterUser(userData)) {
      return `/api/promoter/${userData.organization.promoterId}/user/${userId}/status`;
    }
    return null;
  })();

  if (endpoint === null) {
    return;
  }

  // eslint-disable-next-line
  // @ts-ignore
  const { error } = yield call(
    runner,
    userManagementActions.creators.changeUserStatus,
    {
      method: 'PUT',
      url: endpoint,
    }
  );

  const toast = error
    ? buildErrorToast(
        `Hubo un error al modificar el usuario. Podemos atenderte en el chat de ayuda.`,
        5000
      )
    : buildSuccessToast('Usuario modificado exitosamente.', 5000);
  yield put(toastActions.creators.showToast(toast));
}

interface IChangeUserRolePayload {
  userId: number;
  userRole: BankUserRoles | PromoterUserRoles;
}

function* changeUserRole(
  action: IReduxAction<IChangeUserRolePayload>
): Iterator<Effect> {
  // eslint-disable-next-line
  // @ts-ignore
  const { userData }: { userData: IUserData } = yield select(
    (state: any) => state.auth
  );
  const { userId, userRole } = action.payload;

  const endpoint = (() => {
    if (isBankUser(userData)) {
      return `/api/banks/${userData.organization.bankId}/user/${userId}/role`;
    }
    if (isPromoterUser(userData)) {
      return `/api/promoters/user/${userId}/role`;
    }
    return null;
  })();

  if (endpoint === null) {
    return;
  }

  // eslint-disable-next-line
  // @ts-ignore
  const { error } = yield call(
    runner,
    userManagementActions.creators.changeUserRole,
    {
      method: 'PUT',
      url: endpoint,
      payload: { userRole },
    },
    { snakeCase: false }
  );

  const toast = error
    ? buildErrorToast(
        `Hubo un error al modificar el usuario. Podemos atenderte en el chat de ayuda.`,
        5000
      )
    : buildSuccessToast('Usuario modificado exitosamente.', 5000);
  yield put(toastActions.creators.showToast(toast));
}

function* watchFetchUsersRequested(): Iterator<Effect> {
  yield takeLatest(
    userManagementActions.types.USERS_FETCH.REQUESTED,
    fetchUsers
  );
}

function* watchCreateUserRequested(): Iterator<Effect> {
  yield takeLatest(
    userManagementActions.types.CREATE_USER.REQUESTED,
    createUser
  );
}

function* watchChangeUserStatusRequested(): Iterator<Effect> {
  yield takeLatest(
    userManagementActions.types.CHANGE_USER_STATUS.REQUESTED,
    changeUserStatus
  );
}

function* watchChangeUserRoleRequested(): Iterator<Effect> {
  yield takeLatest(
    userManagementActions.types.CHANGE_USER_ROLE.REQUESTED,
    changeUserRole
  );
}

export default {
  watchFetchUsersRequested,
  watchCreateUserRequested,
  watchChangeUserStatusRequested,
  watchChangeUserRoleRequested,
};
