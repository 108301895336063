import React, { Component } from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';

const RowContainer = styled.div<{ expanded: boolean }>`
  box-shadow: ${({ expanded }) => (expanded ? '0 2px 4px 0 #D7DAE5' : 'none')};
  display: flex;
  margin-bottom: ${({ expanded }) => (expanded ? '8px' : '0')};
  margin-top: ${({ expanded }) => (expanded ? '4px' : '0')};
`;

const Content = styled.div<{ expanded: boolean; height: number }>`
  background-color: ${({ expanded }) =>
    expanded ? colors.BACKGROUND : colors.WHITE};
  height: ${({ expanded, height }) => (expanded ? `${height}px` : '60px')};
  overflow: hidden;
  padding-left: 20px;
  padding-right: 32px;
  transition: height ease 0.5s;
  width: 100%;
`;

const SelectedIndicator = styled.div<{ expanded: boolean; height: number }>`
  background-color: ${({ expanded }) =>
    expanded ? colors.AQUA : colors.WHITE};
  height: ${({ expanded, height }) => (expanded ? `${height}px` : '60px')};
  overflow: hidden;
  transition: height ease 0.5s;
  width: 4px;
`;

interface Props {
  height: number;
  renderHiddenContent(expanded: boolean): JSX.Element;
  renderVisibleContent(expanded: boolean): JSX.Element;
}

interface State {
  expanded: boolean;
}

class ExpandableRow extends Component<Props, State> {
  state = {
    expanded: false,
  };

  static defaultProps = {
    height: 160,
  };

  toggleRowExpanded = () => {
    if (window.getSelection()?.toString()) {
      return;
    }

    this.setState(oldState => ({
      expanded: !oldState.expanded,
    }));
  };

  render() {
    const { expanded } = this.state;
    const { height, renderHiddenContent, renderVisibleContent } = this.props;

    return (
      <RowContainer expanded={expanded} onClick={this.toggleRowExpanded}>
        <SelectedIndicator expanded={expanded} height={height} />
        <Content expanded={expanded} height={height}>
          {renderVisibleContent(expanded)}
          {renderHiddenContent(expanded)}
        </Content>
      </RowContainer>
    );
  }
}

export default ExpandableRow;
