import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 20 20';

const PortfolioIcon = ({ fill }: { fill: string }) => (
  <path
    d="M2 4V3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a1 1 0 0 1-2 0 1 1 0 0 0-1-1H5a1 1 0 0 0-1 1 1 1 0 0 1-2 0zm10 9a2 2 0 1 1-3.999.001A2 2 0 0 1 12 13zm6-2.343L16.343 9C17.972 9 18 9.384 18 10.657zM16.343 17L18 15.343C18 16.97 17.62 17 16.343 17zm-9.858 0l-4-4 4-4h7.03l4 4-4 4h-7.03zM2 15.343L3.657 17C2.03 17 2 16.62 2 15.343zM3.657 9L2 10.657C2 9.028 2.384 9 3.657 9zM18 7H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"
    fill={fill}
  />
);

export default BaseIcon(PortfolioIcon, viewBox);
