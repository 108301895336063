import format from 'date-fns/format';
import parse from 'date-fns/parse';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import keys from 'ramda/src/keys';
import not from 'ramda/src/not';
import prop from 'ramda/src/prop';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import requestsActions from '../../actions/requests';
import { STATE_OF_BIRTH_OPTIONS } from '../../constants/applicationOptions';
import colors from '../../constants/colors';
import f from '../../constants/formatters';
import grammar from '../../constants/grammar';
import BiometricVerificationPhotos from '../BiometricVerificationPhotos';
import Border from '../Border';
import Button from '../Button';
import LoadingIndicator from '../LoadingIndicator';
import RiskGrade from '../RiskGrade';
import SimpleCard from '../SimpleCard';

const percentage = (number: number) => f.percentage(number * 100);

const BackButtonContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const BlackListsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 16px;
  margin-top: 16px;
`;

const ClientNameContainer = styled.div`
  font-size: 26px;
  font-weight: bold;
`;

const CreditMetricsGrid = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
`;

const CreditMetricContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 36px;
  grid-column-gap: 8px;
  width: 100%;
`;

const CustomLabel = styled.div`
  color: ${colors.SECONDARY_TEXT};
  font-size: 14px;
  margin-right: 12px;
`;

const DifferencesGrid = styled.div`
  padding-top: 24px;
  display: grid;
  grid-row-gap: 12px;
  grid-template-rows: 24px auto;
  grid-template-columns: repeat(3, minmax(auto, 200px));
  grid-template-areas:
    '. declaredTitle registeredTitle'
    'fields declaredFields registeredFields';
`;

const DifferencesList = styled.div<{ color: string; name: string }>`
  grid-area: ${prop('name')};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-row-gap: 8px;
  font-size: 14px;
  color: ${prop('color')};
`;

const DifferencesTitle = styled.div<{ name: string }>`
  grid-area: ${prop('name')};
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
`;

const ExternalLink = styled.a`
  color: ${colors.HYPERLINK};
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
`;

const FlexAlignCenter = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const FlexContainer = styled.div`
  align-items: center;
  display: flex;
`;

const Grid = styled.div`
  color: ${colors.PRIMARY_TEXT};
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 1fr 2fr;
  padding-bottom: 20px;
`;

const IdentitySectionContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  padding-left: 0;
`;

const LoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 50vh;
  justify-content: center;
  width: 100%;
`;

const SectionPadding = styled.div`
  padding: 24px;
  padding-left: 0;
`;

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const Value = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
`;

enum VotingIdStatus {
  NOT_VALIDATED = 'NOT_VALIDATED',
  VALIDATED = 'VALIDATED',
  VALIDATED_DIFFERENCES = 'VALIDATED_DIFFERENCES',
  EXPIRED = 'EXPIRED',
  EXPIRED_DIFFERENCES = 'EXPIRED_DIFFERENCES',
}

const VotingIdStatusMap: Record<VotingIdStatus, string> = {
  NOT_VALIDATED: 'Sin validar',
  VALIDATED: 'Vigente y validada',
  VALIDATED_DIFFERENCES: 'Vigente y con diferencias',
  EXPIRED: 'Vencida y validada',
  EXPIRED_DIFFERENCES: 'Vencida y con diferencias',
};

interface IBlackListMatch {
  id: number;
  requestId: number;
  blackList: {
    code: string;
    description: string;
  };
  kinship: {
    code: string;
    description: string;
  };
  type: {
    code: string;
    description: string;
  };
  coincidence: {
    code: string;
    description: string;
  };
  createdAt?: Date;
  updatedAt?: Date;
}

interface IBlackListSection {
  blackListMatches: Array<IBlackListMatch>;
}

interface IIdValidatedData {
  cic: string;
  curp: string;
  emissionNumber: string;
  emissionYear: string;
  expirationYear: string;
  ocr: string;
  registrationYear: string;
  voterKey: string;
}

interface IBiometricVerification {
  id: number;
  email: string;
  phoneNumber: string;
  lat: number;
  lng: number;
  used: number;
  votingIdStatus: VotingIdStatus;
  createdAt: Date;
  updatedAt: Date;
  faceMatchPercentage: number;
  idValidatedData: IIdValidatedData | null;
}

interface ICreditMetrics {
  creditusScore: number;
  creditHistoryMonths: number;
  queryAmount: number;
  totalAccounts: number;
  openAccounts: number;
  delinquentAccounts: number;
  requestedAmountOfApprovedCredits: number;
  pastDueCreditsOfApprovedCredits: number;
  openCreditsOfApprovedCredits: number;
  outstandingBalanceOfApprovedCredits: number;
  outstandingBalanceOfOpenCredits: number;
  delinquentCreditsOfOpenCredits: number;
  indebtnessLevel: number;
}

interface ICurpData {
  name: string;
  firstSurname: string;
  secondSurname: string;
  sex: string;
  dateOfBirth: string;
  stateOfBirth: string;
  [key: string]: string;
}

interface IRenapoDifferences {
  curp: string;
  renapo?: ICurpData;
  declared: ICurpData;
}

interface IRiskProfile {
  blackLists: Array<IBlackListMatch>;
  biometric?: IBiometricVerification;
  name: string;
  metrics: ICreditMetrics;
  photos: Array<{ id: number }>;
  renapoDifferences: IRenapoDifferences;
}

interface IRiskProfileViewProps {
  requestId: number;
  history: RouteComponentProps['history'];
  fetchRequestRisk: Function;
  requestRiskProfile: IRiskProfile;
}

interface ICreditMetricsProps {
  creditMetrics: ICreditMetrics;
}

interface IIdentityProps {
  requestRiskProfile: IRiskProfile;
}

const IdentitySection: React.FunctionComponent<IIdentityProps> = ({
  requestRiskProfile,
}) => {
  const { biometric, metrics, name } = requestRiskProfile;

  const lat = biometric ? biometric.lat : '';
  const lng = biometric ? biometric.lng : '';
  const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

  const faceMatchPercentage =
    !requestRiskProfile.biometric ||
    isNil(requestRiskProfile.biometric.faceMatchPercentage) ||
    not(requestRiskProfile.biometric.faceMatchPercentage)
      ? 'S/I'
      : f.percentage(requestRiskProfile.biometric.faceMatchPercentage);

  return (
    <Fragment>
      <IdentitySectionContent>
        <ClientNameContainer>{name}</ClientNameContainer>
        <RiskGrade score={metrics.creditusScore} />
      </IdentitySectionContent>
      <Border />
      <SectionPadding>
        <Subtitle>Identidad</Subtitle>
        <FlexAlignCenter>
          <FlexContainer>
            <CustomLabel>Coincidencia facial</CustomLabel>
            <Value>{faceMatchPercentage}</Value>
          </FlexContainer>
          <FlexContainer>
            <CustomLabel>Ubicación</CustomLabel>
            <ExternalLink
              href={googleMapsURL}
              rel="noopener noreferrer"
              target="_blank"
            >{`${lat}, ${lng}`}</ExternalLink>
          </FlexContainer>
        </FlexAlignCenter>
      </SectionPadding>
      <Border />
    </Fragment>
  );
};

const BlackListsSection: React.FunctionComponent<IBlackListSection> = ({
  blackListMatches,
}) => {
  const content = isEmpty(blackListMatches) ? (
    <FlexContainer>
      <CustomLabel>Estado</CustomLabel>
      <Value>Sin coincidencias</Value>
    </FlexContainer>
  ) : (
    <BlackListsGrid>
      {blackListMatches.map(match => (
        <Fragment key={match.id}>
          <FlexContainer>
            <CustomLabel>Lista</CustomLabel>
            <Value>{match.blackList.description}</Value>
          </FlexContainer>
          <FlexContainer>
            <CustomLabel>Tipo</CustomLabel>
            <Value>{match.type.description}</Value>
          </FlexContainer>
          <FlexContainer>
            <CustomLabel>Parentesco</CustomLabel>
            <Value>{match.kinship.description}</Value>
          </FlexContainer>
          <FlexContainer>
            <CustomLabel>Coincidencia</CustomLabel>
            <Value>{match.coincidence.description}</Value>
          </FlexContainer>
        </Fragment>
      ))}
    </BlackListsGrid>
  );

  return (
    <Fragment>
      <SectionPadding>
        <Subtitle>Listas negras</Subtitle>
        {content}
      </SectionPadding>
      <Border />
    </Fragment>
  );
};

const CreditMetrics: React.FunctionComponent<ICreditMetricsProps> = ({
  creditMetrics,
}) => {
  const {
    creditHistoryMonths,
    delinquentAccounts,
    delinquentCreditsOfOpenCredits,
    openAccounts,
    openCreditsOfApprovedCredits,
    outstandingBalanceOfApprovedCredits,
    outstandingBalanceOfOpenCredits,
    pastDueCreditsOfApprovedCredits,
    queryAmount,
    requestedAmountOfApprovedCredits,
    totalAccounts,
    indebtnessLevel,
  } = creditMetrics;

  return (
    <Fragment>
      <SectionPadding>
        <Subtitle>Calidad crediticia</Subtitle>
        <CreditMetricsGrid>
          <CreditMetricContainer>
            <CustomLabel>Duración</CustomLabel>
            <Value>{creditHistoryMonths} meses</Value>
          </CreditMetricContainer>
          <CreditMetricContainer>
            <CustomLabel>Cuentas históricas</CustomLabel>
            <Value>{totalAccounts}</Value>
          </CreditMetricContainer>
          <CreditMetricContainer>
            <CustomLabel>Consultas</CustomLabel>
            <Value>{queryAmount}</Value>
          </CreditMetricContainer>
          <CreditMetricContainer>
            <CustomLabel>Quebrantos</CustomLabel>
            <Value>{delinquentAccounts}</Value>
          </CreditMetricContainer>
          <CreditMetricContainer>
            <CustomLabel>Cuentas abiertas</CustomLabel>
            <Value>{openAccounts}</Value>
          </CreditMetricContainer>
          <CreditMetricContainer>
            <CustomLabel>Pagos mensuales / ingresos mensuales</CustomLabel>
            <Value>{percentage(indebtnessLevel)}</Value>
          </CreditMetricContainer>
          <CreditMetricContainer>
            <CustomLabel>Monto solicitado / Monto aprobado</CustomLabel>
            <Value>{percentage(requestedAmountOfApprovedCredits)}</Value>
          </CreditMetricContainer>
          <CreditMetricContainer>
            <CustomLabel>Monto vencido / Monto aprobado</CustomLabel>
            <Value>{percentage(pastDueCreditsOfApprovedCredits)}</Value>
          </CreditMetricContainer>
          <CreditMetricContainer>
            <CustomLabel>Monto abierto / Monto aprobado</CustomLabel>
            <Value>{percentage(openCreditsOfApprovedCredits)}</Value>
          </CreditMetricContainer>
          <CreditMetricContainer>
            <CustomLabel>Saldo insoluto / Monto aprobado</CustomLabel>
            <Value>{percentage(outstandingBalanceOfApprovedCredits)}</Value>
          </CreditMetricContainer>
          <CreditMetricContainer>
            <CustomLabel>Saldo insoluto / Monto vigente</CustomLabel>
            <Value>{percentage(outstandingBalanceOfOpenCredits)}</Value>
          </CreditMetricContainer>
          <CreditMetricContainer>
            <CustomLabel>Monto quebranto / aprobado</CustomLabel>
            <Value>{percentage(delinquentCreditsOfOpenCredits)}</Value>
          </CreditMetricContainer>
        </CreditMetricsGrid>
      </SectionPadding>
      <Border />
    </Fragment>
  );
};

const IneSection = ({
  requestRiskProfile,
}: {
  requestRiskProfile: IRiskProfile;
}) => {
  const status = isNil(requestRiskProfile.biometric)
    ? 'S/I'
    : VotingIdStatusMap[requestRiskProfile.biometric.votingIdStatus];
  const voterKey =
    isNil(requestRiskProfile.biometric) ||
    isNil(requestRiskProfile.biometric.idValidatedData) ||
    isEmpty(requestRiskProfile.biometric.idValidatedData.voterKey)
      ? 'S/I'
      : requestRiskProfile.biometric.idValidatedData.voterKey;

  return (
    <Fragment>
      <SectionPadding>
        <Subtitle>Instituto Nacional Electoral</Subtitle>
        <FlexAlignCenter>
          <FlexContainer>
            <CustomLabel>Estado</CustomLabel>
            <Value>{status}</Value>
          </FlexContainer>
          <FlexContainer>
            <CustomLabel>Clave de elector</CustomLabel>
            <Value>{voterKey}</Value>
          </FlexContainer>
        </FlexAlignCenter>
      </SectionPadding>
      <Border />
    </Fragment>
  );
};

const RenapoSection: React.FunctionComponent<{
  requestRiskProfile: IRiskProfile;
}> = ({ requestRiskProfile }) => {
  const differencesArray: string[] = [];

  const status = () => {
    if (not(isNil(requestRiskProfile.renapoDifferences.renapo))) {
      keys(requestRiskProfile.renapoDifferences.declared).forEach(
        (value: string) => {
          if (
            requestRiskProfile.renapoDifferences.declared[
              value
            ].toUpperCase() !==
            requestRiskProfile.renapoDifferences.renapo![value].toUpperCase()
          ) {
            differencesArray.push(value);
          }
        }
      );
      return isEmpty(differencesArray) ? 'Validada' : 'Con diferencias';
    } else {
      return 'Sin verificar';
    }
  };
  const curp = requestRiskProfile.renapoDifferences.curp;

  const formatField = (field: string, values: ICurpData) => {
    if (field === 'sex') {
      return values.sex === 'male' ? 'HOMBRE' : 'MUJER';
    } else if (field === 'dateOfBirth') {
      return format(
        parse(values.dateOfBirth, 'yyyy-MM-dd', new Date()),
        'dd/MM/yyyy'
      );
    } else if (field === 'stateOfBirth') {
      const stateObject = STATE_OF_BIRTH_OPTIONS.find(state => {
        return state.value === values.stateOfBirth;
      });
      return isNil(stateObject) ? 'S/I' : stateObject!.text.toUpperCase();
    }
    return values[field].toUpperCase();
  };

  const differences = () =>
    isEmpty(differencesArray) ? null : (
      <DifferencesGrid>
        <DifferencesTitle name={'declaredTitle'}>Declarado</DifferencesTitle>
        <DifferencesTitle name={'registeredTitle'}>
          Registro oficial
        </DifferencesTitle>
        <DifferencesList color={colors.SECONDARY_TEXT} name={'fields'}>
          {differencesArray.map((field: string, index: number) => (
            <span key={index}>{grammar[field]}</span>
          ))}
        </DifferencesList>
        <DifferencesList color={colors.PRIMARY_TEXT} name={'declaredFields'}>
          {differencesArray.map((field: string, index: number) => (
            <span key={index}>
              {formatField(
                field,
                requestRiskProfile.renapoDifferences.declared
              )}
            </span>
          ))}
        </DifferencesList>
        <DifferencesList color={colors.PRIMARY_TEXT} name={'registeredFields'}>
          {differencesArray.map((field: string, index: number) => (
            <span key={index}>
              {formatField(field, requestRiskProfile.renapoDifferences.renapo!)}
            </span>
          ))}
        </DifferencesList>
      </DifferencesGrid>
    );

  return (
    <Fragment>
      <SectionPadding>
        <Subtitle>Registro Nacional de Población</Subtitle>
        <FlexAlignCenter>
          <FlexContainer>
            <CustomLabel>Estado</CustomLabel>
            <Value>{status()}</Value>
          </FlexContainer>
          <FlexContainer>
            <CustomLabel>CURP</CustomLabel>
            <Value>{curp}</Value>
          </FlexContainer>
        </FlexAlignCenter>
        {differences()}
      </SectionPadding>
      <Border />
    </Fragment>
  );
};

const RiskProfileView: React.FunctionComponent<IRiskProfileViewProps> = ({
  history,
  requestId,
  fetchRequestRisk,
  requestRiskProfile,
}) => {
  useEffect(() => {
    fetchRequestRisk({ requestId });
  }, [fetchRequestRisk, requestId]);

  if (not(requestRiskProfile)) {
    return (
      <LoadingState>
        <LoadingIndicator />
      </LoadingState>
    );
  }

  return (
    <Fragment>
      <BackButtonContainer>
        <Button variant="secondary" onClick={() => history.goBack()}>
          Regresar
        </Button>
      </BackButtonContainer>
      <SimpleCard
        title={'Perfil de Riesgo'}
        subtitle={
          'El score refleja el conjunto de factores que impactan el riesgo crediticio del solicitante'
        }
      >
        <Grid>
          <BiometricVerificationPhotos photos={requestRiskProfile.photos} />
          <div>
            <IdentitySection requestRiskProfile={requestRiskProfile} />
            <BlackListsSection
              blackListMatches={requestRiskProfile.blackLists}
            />
            <IneSection requestRiskProfile={requestRiskProfile} />
            <RenapoSection requestRiskProfile={requestRiskProfile} />
            <CreditMetrics creditMetrics={requestRiskProfile.metrics} />
          </div>
        </Grid>
      </SimpleCard>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  requestRiskProfile: state.entities.requestRisk,
});

const creators = {
  fetchRequestRisk: requestsActions.creators.fetchRequestRisk.request,
};

export default connect(mapStateToProps, creators)(RiskProfileView);
