import last from 'ramda/src/last';
import React, { Fragment } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import colors from '../../constants/colors';
import { noop } from '../../utils/misc';
import Label from '../Label';

const ErrorMessageContainer = styled.div`
  margin-top: 4px;
`;

const ArrowUp = styled.div`
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 3px solid black;
  height: 0;
  margin-bottom: 4px;
  width: 0;
`;

const ArrowDown = styled.div`
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid black;
  height: 0;
  width: 0;
`;

const DropdownIndicatorStyle = styled.div`
  padding: 4px 12px;
  margin-right: 4px;
`;

const ClearIndicatorStyle = styled.div`
  color: ${colors.DUSK75};
  cursor: pointer;
  font-size: 22px;
  font-weight: bold;
  transform: rotate(-315deg);
  padding-top: 4px;
  padding-right: 6px;
  &:hover {
    color: ${colors.DUSK};
  }
`;

const ClearIndicatorComponent = (props: any) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div {...restInnerProps} ref={ref}>
      <ClearIndicatorStyle>+</ClearIndicatorStyle>
    </div>
  );
};

const DropdownIndicatorComponent = (props: any) => (
  <DropdownIndicatorStyle>
    <ArrowUp />
    <ArrowDown />
  </DropdownIndicatorStyle>
);

interface IOption {
  value: string;
  label: string;
}

interface IDropdownSelect {
  error?: string;
  withErrorMessage?: boolean;
  onChange?(value: IOption[]): void;
  options: IOption[];
  values: IOption[];
  customStyle?: object;
  placeholder: string;
}

const selectAllOption = { label: 'Seleccionar todos', value: '*' };

const DropdownSelect: React.FunctionComponent<IDropdownSelect> = ({
  onChange = noop,
  options,
  error,
  customStyle,
  placeholder,
  withErrorMessage = false,
  values,
}) => {
  const errorComponent =
    error && withErrorMessage ? (
      <ErrorMessageContainer>
        <Label error>{error}</Label>
      </ErrorMessageContainer>
    ) : null;

  return (
    <Fragment>
      <Select
        isMulti
        defaultValue={[selectAllOption]}
        closeMenuOnSelect={false}
        value={values}
        onChange={(selected: any) =>
          last(selected) !== selectAllOption
            ? onChange(selected)
            : onChange(options)
        }
        options={[selectAllOption, ...options]}
        styles={{
          placeholder: (styles: any) => ({
            ...styles,
            color: colors.PRIMARY_TEXT,
            fontWeight: 'normal',
            fontSize: '14px',
            paddingLeft: '8px',
          }),
          input: (styles: any) => ({
            ...styles,
            color: colors.PRIMARY_TEXT,
            fontWeight: 'normal',
            fontSize: '14px',
            paddingLeft: '8px',
          }),
          option: (styles: any) => ({
            ...styles,
            color: colors.PRIMARY_TEXT,
            fontWeight: 'normal',
            fontSize: '14px',
            paddingLeft: '16px',
          }),
          ...customStyle,
        }}
        placeholder={placeholder}
        components={{
          DropdownIndicator: DropdownIndicatorComponent,
          ClearIndicator: ClearIndicatorComponent,
        }}
      />
      {errorComponent}
    </Fragment>
  );
};

export default DropdownSelect;
