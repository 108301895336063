import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import deviceSizes from '../../constants/deviceSizes';
import f from '../../constants/formatters';
import { getOfferData } from '../../utils/offers';
import Border from '../Border';
import Card from '../Card';
import Table, { TableCell } from '../SmartTable';

const TAX_MX = 0.16;

interface PaymentInformation {
  initialBalance: number;
  interests: number;
  outstandingBalance: number;
  payment: number;
  paymentBeforeTaxes: number;
  paymentNumber: number;
  paymentCapital: number;
  taxes: number;
}

const payments = (
  totalCreditAmount: number,
  interestRate: number,
  numberOfPayments: number,
  tax: number,
  paymentFrequency: number
) => {
  const payments: Array<PaymentInformation> = [];

  const calcPayment = (
    payment: number,
    totalCreditAmount: number,
    interestRate: number,
    numberOfPayments: number,
    tax: number,
    balance: number
  ) => {
    if (payment <= 0) {
      return;
    }

    const interest = balance * (interestRate / paymentFrequency);
    const paymentTax = interest * tax;
    const term1 = Math.pow(
      1 + (interestRate * (1 + tax)) / paymentFrequency,
      numberOfPayments
    );
    const term2 = (interestRate * (1 + tax)) / paymentFrequency;
    const term3 =
      Math.pow(
        1 + (interestRate * (1 + tax)) / paymentFrequency,
        numberOfPayments
      ) - 1;
    const paymentT = totalCreditAmount * ((term1 * term2) / term3);

    const paymentCapital = paymentT - interest - paymentTax;
    const paymentBeforeTaxes = paymentCapital + interest;
    const outstanding = balance - paymentCapital;

    payments.push({
      initialBalance: balance,
      interests: interest,
      outstandingBalance: Math.abs(outstanding),
      payment: paymentT,
      paymentBeforeTaxes,
      paymentNumber: numberOfPayments - payment + 1,
      paymentCapital,
      taxes: paymentTax,
    });

    calcPayment(
      payment - 1,
      totalCreditAmount,
      interestRate,
      numberOfPayments,
      tax,
      outstanding
    );
  };

  calcPayment(
    numberOfPayments,
    totalCreditAmount,
    interestRate,
    numberOfPayments,
    tax,
    totalCreditAmount
  );

  return payments;
};

const Caption = styled.div`
  color: ${colors.DUSK50};
  font-size: 9px;
  padding: 12px 24px;
`;

const CustomTableCell = styled(TableCell)`
  height: 40px;
`;

interface IPaymentTable {
  offer: IOffer;
  deviceWidth: number;
}

const PaymentTable: React.FunctionComponent<IPaymentTable> = ({
  offer,
  deviceWidth,
}) => {
  const formatted = getOfferData(offer);
  const creditAmount = Number.parseFloat(formatted.creditAmount);
  const interestRate = Number.parseFloat(formatted.rate);
  const numberOfPayments = Number.parseFloat(formatted.requestedPeriod);
  const paymentFrequency = 12;

  const paymentsList = payments(
    creditAmount,
    interestRate,
    numberOfPayments,
    TAX_MX,
    paymentFrequency
  );

  const headers = [
    'Número',
    'Saldo inicial',
    'Interés',
    'Impuestos',
    'Pago capital',
    'Pago total',
    'Saldo final',
  ];

  const mobileHeaders = ['Número', 'Pago total', 'Saldo final'];

  return (
    <Card header={'Tabla de pagos estimada'}>
      <Table
        data={paymentsList}
        gridColumns={
          deviceWidth > deviceSizes.tablet.width
            ? 'repeat(7, 1fr)'
            : 'repeat(3, 1fr)'
        }
        headers={
          deviceWidth > deviceSizes.tablet.width ? headers : mobileHeaders
        }
        renderRow={payment => {
          return deviceWidth > deviceSizes.tablet.width
            ? [
                <CustomTableCell key={0}>
                  #{payment.paymentNumber}
                </CustomTableCell>,
                <CustomTableCell key={1}>
                  {f.currency(payment.initialBalance)}
                </CustomTableCell>,
                <CustomTableCell key={2}>
                  {f.currency(payment.interests)}
                </CustomTableCell>,
                <CustomTableCell key={3}>
                  {f.currency(payment.taxes)}
                </CustomTableCell>,
                <CustomTableCell key={4}>
                  {f.currency(payment.paymentCapital)}
                </CustomTableCell>,
                <CustomTableCell key={5}>
                  {f.currency(payment.payment)}
                </CustomTableCell>,
                <CustomTableCell key={6}>
                  {f.currency(payment.outstandingBalance)}
                </CustomTableCell>,
              ]
            : [
                <CustomTableCell key={0}>
                  #{payment.paymentNumber}
                </CustomTableCell>,
                <CustomTableCell key={1}>
                  {f.currency(payment.payment)}
                </CustomTableCell>,
                <CustomTableCell key={2}>
                  {f.currency(payment.outstandingBalance)}
                </CustomTableCell>,
              ];
        }}
      />
      <Border />
      <Caption>
        La tabla de pagos fue generada por Creditus utilizando los parámetros de
        la oferta sólo para fines ilustrativos por lo que pueden existir
        variaciones con la tabla de pagos proporcionada por el otorgante.
      </Caption>
    </Card>
  );
};

export default PaymentTable;
