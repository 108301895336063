import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import auditApplicationActions from '../../actions/audit';
import colors from '../../constants/colors';
import f from '../../constants/formatters';
import grammar from '../../constants/grammar';
import { isCreditusUser } from '../../utils/permissions';
import Button from '../Button';
import Card from '../Card';
import ExpandableCard from '../ExpandableCard';
import LoadingIndicator from '../LoadingIndicator';
import MultiSingleRowTable from '../MultiSingleRowTable';

const BackButtonContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const CreditHistoryButtonContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  // this is a hacky way to separate elements but the 'gap' property
  // is fairly new and we don't know if our users have up to date browsers
  & > button {
    margin-left: 10px;
  }
`;

const CustomCardMargin = styled.div`
  padding-top: 40px;
`;

const LoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 50vh;
  justify-content: center;
  width: 100%;
`;

interface IApplicationDetails {
  vehicleCondition: string;
  vehicleModel: string;
  vehiclePrice: string;
  creditType: string;
  creditAmount: string;
  creditDownpayment: string;
  creditTerm: string;
  clientAge: number;
  clientEducationLevel: string;
  clientDependents: number;
  employmentStatus: string;
  employmentMonths: number | null;
  employmentTotalRevenue: number;
  cosigner: string;
  cosignerAge: number | null;
  addressType: string;
  addressRent: number | null;
  addressMonths: string | null;
  addressState: string;
  fico: number;
  accountCreditLimit: number;
  accountCreditApproved: number;
  accountInsoluteBalance: number;
  previousDebt: number;
  historyRequested: number;
  outstandingLatePaymentsCurrentAmount: number;
  outstandingLatePaymentsAmountHistoric: number;
  promoterName: string;
  promoterUserName: string;
}

interface IOffer {
  id: number;
  status: string;
  name: string;
  type: string;
  downpayment: number;
  creditAmount: number;
  term: string;
  interestRate: number;
  interestRateType: string;
  fee: number;
  feeType: string;
  accessoriesPrice: number;
  accessoriesNames: string[];
  periodicPayment: number;
  debt: number;
  monthlyPayment: number;
  historyOffered: number;
  dateOffer: string;
  dateVigency: string;
  bankName: string;
}

interface IApplication {
  id: number;
  date: string;
  type: string;
  amount: string;
  term: string;
  status: string;
  applicationDetails: IApplicationDetails;
  offers: IOffer[];
  canCreateManualOffer: boolean;
}

interface IAuditDetailsViewProps {
  history: RouteComponentProps['history'];
  requestId: string;
  applications: IApplication[];
  fetchAuditApplications: Function;
  userData: IUserData;
}

interface IAuditDetailsTableProps {
  application: IApplication;
}

interface IOffersSectionTableProps {
  offer: IOffer;
  userData: IUserData;
}

const AuditDetailsTable: React.FunctionComponent<IAuditDetailsTableProps> = ({
  application,
}) => {
  const headers = [
    ['PRODUCTO', 'CONDICIÓN', 'AÑO', 'PRECIO', 'ENGANCHE', 'MONTO CRÉDITO'],
    [
      'PLAZO',
      'EDAD',
      'EDUCACIÓN',
      'DEPENDIENTES',
      'COACREDITADO',
      'EDAD DEL COACREDITADO',
    ],
    [
      'TIPO DE DOMICILIO',
      'RENTA MENSUAL',
      'MESES EN DOMICILIO',
      'UBICACIÓN',
      'SITUACIÓN LABORAL',
      'MESES EN LABOR',
    ],
    [
      'INGRESO TOTAL',
      'FICO',
      'LÍMITE',
      'APROBADO',
      'INSOLUTO',
      'ENDEUDAMIENTO PREVIO',
    ],
    ['HISTORIAL / SOLICITADO', 'ATRASO ACTUAL', 'ATRASO HISTÓRICO'],
  ];

  const values = [
    [
      grammar[application.applicationDetails.creditType],
      grammar[application.applicationDetails.vehicleCondition],
      application.applicationDetails.vehicleModel,
      f.currency(application.applicationDetails.vehiclePrice),
      f.currency(application.applicationDetails.creditDownpayment),
      f.currency(application.applicationDetails.creditAmount),
    ],
    [
      `${application.applicationDetails.creditTerm} meses`,
      `${application.applicationDetails.clientAge} años`,
      f.capitalizeFirstLetterLowerRest(
        application.applicationDetails.clientEducationLevel
      ),
      `${application.applicationDetails.clientDependents}`,
      grammar[application.applicationDetails.cosigner],
      application.applicationDetails.cosignerAge
        ? `${application.applicationDetails.cosignerAge} años`
        : 'N/A',
    ],
    [
      f.capitalizeFirstLetterLowerRest(
        application.applicationDetails.addressType
      ),
      application.applicationDetails.addressRent
        ? f.currency(application.applicationDetails.addressRent)
        : 'N/A',
      `${application.applicationDetails.addressMonths} meses`,
      f.capitalizeFirstLetterLowerRest(
        application.applicationDetails.addressState
      ),
      f.capitalizeFirstLetterLowerRest(
        application.applicationDetails.employmentStatus
      ),
      application.applicationDetails.employmentMonths
        ? `${application.applicationDetails.employmentMonths} meses`
        : 'N/A',
    ],
    [
      f.currency(application.applicationDetails.employmentTotalRevenue),
      `${application.applicationDetails.fico}`,
      f.currency(application.applicationDetails.accountCreditLimit),
      f.currency(application.applicationDetails.accountCreditApproved),
      f.currency(application.applicationDetails.accountInsoluteBalance),
      `${application.applicationDetails.previousDebt.toFixed(2)}`,
    ],
    [
      `${application.applicationDetails.historyRequested.toFixed(2)}`,
      f.currency(
        application.applicationDetails.outstandingLatePaymentsCurrentAmount
      ),
      f.currency(
        application.applicationDetails.outstandingLatePaymentsAmountHistoric
      ),
    ],
  ];

  return (
    <MultiSingleRowTable
      headersArray={headers}
      valuesArray={values}
      gridColumns={'repeat(6, 1fr)'}
    />
  );
};

const OffersSectionTable: React.FunctionComponent<IOffersSectionTableProps> = ({
  offer,
  userData,
}) => {
  const headers = [
    [
      'ID OFERTA',
      'PRODUCTO',
      'TIPO DE PRODUCTO',
      'ENGANCHE SOLICITADO',
      'MONTO CRÉDITO',
      'PLAZO',
    ],
    [
      'TASA DE INTERÉS',
      'COMISIÓN APERTURA',
      'ACCESORIOS',
      'ACCESORIOS',
      'PAGO PERIÓDICO',
      'ENDEUDAMIENTO',
    ],
    ['MENSUALIDAD', 'HISTORIAL / OFERTADO', 'FECHA OFERTA', 'FECHA VIGENCIA'],
  ];
  const values = [
    [
      `${offer.id}`,
      offer.name,
      grammar[offer.type],
      f.currency(offer.downpayment),
      f.currency(offer.creditAmount),
      offer.term,
    ],
    [
      `${f.percentage(offer.interestRate * 100)} (${
        grammar[offer.interestRateType]
      })`,
      offer.feeType === 'cash'
        ? f.currency(offer.fee)
        : f.percentage(offer.fee * 100),
      f.currency(offer.accessoriesPrice),
      isEmpty(offer.accessoriesNames)
        ? 'Sin accesorios'
        : offer.accessoriesNames.join(', '),
      f.currency(offer.periodicPayment),
      `${offer.debt.toFixed(2)}`,
    ],
    [
      `${offer.monthlyPayment.toFixed(2)}`,
      `${offer.historyOffered.toFixed(2)}`,
      offer.dateOffer,
      offer.dateVigency,
    ],
  ];

  const bankName = isCreditusUser(userData) ? ` - ${offer.bankName}` : '';

  return (
    <ExpandableCard
      status={grammar[offer.status]}
      title={`Oferta #${offer.id}${bankName}`}
      height={'288px'}
    >
      <MultiSingleRowTable
        headersArray={headers}
        valuesArray={values}
        gridColumns={'repeat(6, 1fr)'}
      />
    </ExpandableCard>
  );
};

const AuditDetailsView: React.FunctionComponent<IAuditDetailsViewProps> = ({
  history,
  requestId,
  applications,
  fetchAuditApplications,
  userData,
}) => {
  const [application, setApplication] = useState<IApplication>();

  useEffect(() => {
    if (isNil(applications) || isEmpty(applications)) {
      fetchAuditApplications();
    } else {
      const app = applications.find(
        application => application.id === Number.parseInt(requestId)
      );
      isNil(app) ? history.push('/audit') : setApplication(app);
    }
  }, [applications, fetchAuditApplications, history, requestId]);

  const promoterName = !isNil(application)
    ? ` - ${application.applicationDetails.promoterName}`
    : '';

  // the promoterUserName is the same for all offers
  const promoterUserName = !isNil(application)
    ? ` - ${application.applicationDetails.promoterUserName
        .toLowerCase()
        .split(' ')
        .filter(val => val) // check for empty strings and remove them
        .map(name => name[0].toUpperCase() + name.slice(1))
        .join(' ')}`
    : '';

  return (
    <Fragment>
      <BackButtonContainer>
        <Button variant="secondary" onClick={() => history.goBack()}>
          Regresar
        </Button>
        <CreditHistoryButtonContainer>
          <Button
            variant="secondary"
            onClick={() => history.push(`/audit/${requestId}/credit-history`)}
          >
            Historial
          </Button>
          {application?.canCreateManualOffer && (
            <Button
              variant="primary"
              onClick={() =>
                history.push(`/applications/${requestId}/offer/new`)
              }
            >
              Crear oferta
            </Button>
          )}
        </CreditHistoryButtonContainer>
      </BackButtonContainer>
      {isNil(application) ? (
        <LoadingState>
          <LoadingIndicator />
        </LoadingState>
      ) : (
        <Fragment>
          <Card
            header={`Solicitud ${
              isNil(application)
                ? ''
                : `${application.id}${promoterName}${promoterUserName}`
            }`}
          >
            <AuditDetailsTable application={application} />
          </Card>
          {application.offers.map((offer, i) => (
            <CustomCardMargin key={i}>
              <OffersSectionTable offer={offer} userData={userData} />
            </CustomCardMargin>
          ))}
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state: any): { applications: IApplication[] } => ({
  applications: state.entities.audit.applications,
});

const creators = {
  fetchAuditApplications:
    auditApplicationActions.creators.fetchAuditApplications.request,
};

export default connect(mapStateToProps, creators)(AuditDetailsView);
