import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import documentsActions from '../actions/documents';
import offersActions from '../actions/offers';
import toastActions from '../actions/toastNotifications';
import history from '../history';
import {
  buildErrorToast,
  buildSuccessToast,
} from '../utils/toastNotifications';
import runner from './runners';

function* watchDocumentFileUpload() {
  function* uploadFile(action) {
    const { offerId, fileId, files } = action.payload;

    const payload = new FormData();
    files.forEach(file => {
      payload.append('files[]', file);
    });

    const { error } = yield call(
      runner,
      documentsActions.creators.uploadDocumentFile,
      {
        payload,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'POST',
        url: `/api/offers/${offerId}/files/${fileId}`,
      }
    );

    if (error) {
      if (error.response.data.error === 'UNSUPPORTED_FILE_FORMAT') {
        const toast = buildErrorToast(
          'Uno de los archivos tiene un formato no soportado, solo se soportan archivos jpg, png, jpeg o un solo pdf',
          5000
        );
        yield put(toastActions.creators.showToast(toast));
      } else if (error.response.data.error === 'DOCUMENT_IS_ALREADY_APPROVED') {
        const toast = buildErrorToast(
          'Este documento ya fue aprobado anteriormente',
          5000
        );
        yield put(toastActions.creators.showToast(toast));
      } else {
        const toast = buildErrorToast(
          'Hubo un error al subir los documentos. Si el problema persiste por favor contacta a soporte al número: (331) 803-7895.',
          5000
        );
        yield put(toastActions.creators.showToast(toast));
      }
    } else {
      if (window.location.href.includes('offered-applications')) {
        const toast = buildSuccessToast(
          'El documento fue subido con éxito',
          3000
        );
        yield put(toastActions.creators.showToast(toast));
        yield call(history.goBack);
      }
    }

    yield put(
      offersActions.creators.fetchOfferRequiredDocuments.request({ offerId })
    );
  }

  yield takeLatest(
    documentsActions.types.DOCUMENT_FILE_UPLOAD.REQUESTED,
    uploadFile
  );
}

function* getDocument(actions) {
  const { documentId, offerId } = actions.payload;

  yield call(runner, documentsActions.creators.getDocument, {
    method: 'GET',
    url: `/api/offers/${offerId}/documents/${documentId}`,
  });
}

function* updateDocumentStatus(action) {
  const { documentId, offerId, reason, status } = action.payload;

  yield call(runner, documentsActions.creators.updateDocumentStatus, {
    method: 'PATCH',
    payload: { reason, status },
    url: `/api/offers/${offerId}/documents/${documentId}`,
  });

  yield call(getDocument, { payload: { offerId, documentId } });
}

function* watchDocumentStatusUpdateRequested() {
  yield takeEvery(
    documentsActions.types.DOCUMENT_UPDATE_STATUS.REQUESTED,
    updateDocumentStatus
  );
}

export default {
  watchDocumentFileUpload,
  watchDocumentStatusUpdateRequested,
};
