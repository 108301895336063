import { FormikProps } from 'formik';
import isNil from 'ramda/src/isNil';
import { useEffect, useState } from 'react';

import { applySelector } from '../utils/misc';

const usePostalCodeRelatedFieldsCleanup = (
  formikProps: FormikProps<any>,
  initialPostalCode: string = '',
  fieldGroupName?: string
) => {
  const [lastPostalCode, setLastPostalCode] = useState<string>(
    initialPostalCode
  );

  const { setFieldValue, values } = formikProps;
  const suffixedFieldGroupName = isNil(fieldGroupName)
    ? ''
    : `${fieldGroupName}.`;
  const postalCode = applySelector(
    formikProps.values,
    `${suffixedFieldGroupName}postalCode`
  );
  const fieldsExist = !isNil(values[`${suffixedFieldGroupName}neighborhood`]);

  useEffect(() => {
    if (fieldsExist && postalCode !== lastPostalCode) {
      setFieldValue(`${suffixedFieldGroupName}neighborhood`, '');
      setFieldValue(`${suffixedFieldGroupName}municipality`, '');
      setFieldValue(`${suffixedFieldGroupName}city`, '');
      setFieldValue(`${suffixedFieldGroupName}state`, '');
      setFieldValue(`${suffixedFieldGroupName}country`, '');
      setLastPostalCode(postalCode);
    }
  }, [
    lastPostalCode,
    postalCode,
    setFieldValue,
    suffixedFieldGroupName,
    fieldsExist,
  ]);
};

export default usePostalCodeRelatedFieldsCleanup;
