import { handleActions } from 'redux-actions';

import utils from './utils';

const initialState = {};

const vehicleModelsReducer = handleActions(
  {
    VEHICLES_FETCH_MODELS_COMPLETED: (state, action) => ({
      ...state,
      ...utils.buildDictWithKey(action.payload, 'id'),
    }),
  },
  initialState
);

export default vehicleModelsReducer;
