import {
  isBankAdmin,
  isBankUser,
  isCreditusUser,
  isPromoterAdmin,
  isPromoterUser,
} from '../../utils/permissions';

type FormatTypes = 'none' | 'currency';

export interface IQuickInfoCardConfig {
  format?: FormatTypes;
  key: string;
  title: string;
}

interface IBankAdminStats {
  bankAcceptedOffers: number;
  bankApplications: number;
  bankCommissions: Array<{
    creditAmount: number;
    createdAt: string;
  }>;
  bankFinalOffers: number;
  bankGrantedOffers: number;
  bankOfferedApplications: number;
  bankTotalGrantedCredit: number;
}

interface IPromoterSale {
  vehicleValue: number;
  date: string;
}

interface IPromoterAdminStats {
  sales: IPromoterSale[];
  applications: number;
  offeredApplications: number;
  finalOffers: number;
  commissions: number;
}

export interface IDashboardConfig {
  mainChart: {
    format: FormatTypes;
    getEntities: (data: any) => any[];
    getEntityDate: (entity: any) => Date;
    getEntityValue: (entity: any) => number;
    title: string;
  };
  quickInfoCards: IQuickInfoCardConfig[];
}

const bankAdminDashboadConfig: IDashboardConfig = {
  mainChart: {
    format: 'none',
    getEntities: (data: IBankAdminStats) => data.bankCommissions,
    getEntityDate: (commission: any) => new Date(commission.createdAt),
    getEntityValue: () => 1,
    title: 'Colocación',
  },
  quickInfoCards: [
    {
      key: 'bankApplications',
      title: 'SOLICITUDES',
    },
    {
      key: 'bankOfferedApplications',
      title: 'OFERTADAS',
    },
    {
      key: 'bankAcceptedOffers',
      title: 'ACEPTADAS',
    },
    {
      key: 'bankFinalOffers',
      title: 'APROBADAS',
    },
    {
      key: 'bankGrantedOffers',
      title: 'OTORGADAS',
    },
    {
      key: 'bankTotalGrantedCredit',
      title: 'TOTAL COLOCADO',
      format: 'currency',
    },
  ],
};

interface ICreditusCommission {
  amount: number;
  createdAt: string;
}

interface ICreditusAdminStats {
  commissions: ICreditusCommission[];
  bankCount: number;
  promoterCount: number;
  activeSalespersonUsers: number;
  activeBankAnalystUsers: number;
  clientUsers: number;
  applications: number;
  offeredApplications: number;
  offers: number;
  acceptedOffers: number;
  finalOffers: number;
  grantedOffers: number;
  totalCreditAmountGranted: number;
}

const creditusAdminDashboardConfig: IDashboardConfig = {
  mainChart: {
    format: 'currency',
    getEntities: (data: ICreditusAdminStats) => data.commissions,
    getEntityDate: (entity: ICreditusCommission) => new Date(entity.createdAt),
    getEntityValue: (entity: ICreditusCommission) => entity.amount,
    title: 'Comisiones',
  },
  quickInfoCards: [
    {
      key: 'bankCount',
      title: 'Número de otorgantes',
    },
    {
      key: 'promoterCount',
      title: 'Número de promotores',
    },
    {
      key: 'activeSalespersonUsers',
      title: 'Asesores',
    },
    {
      key: 'activeBankAnalystUsers',
      title: 'Analistas',
    },
    {
      key: 'clientUsers',
      title: 'Clientes',
    },
    {
      key: 'applications',
      title: 'Solicitudes',
    },
    {
      key: 'offeredApplications',
      title: 'Ofertadas',
    },
    {
      key: 'offers',
      title: 'Ofertas',
    },
    {
      key: 'acceptedOffers',
      title: 'Aceptadas',
    },
    {
      key: 'finalOffers',
      title: 'Aprobaciones',
    },
    {
      key: 'grantedOffers',
      title: 'Colocadas',
    },
    {
      key: 'totalCreditAmountGranted',
      title: 'Monto colocado',
      format: 'currency',
    },
  ],
};

const promoterAdminDashboardConfig: IDashboardConfig = {
  mainChart: {
    format: 'currency',
    getEntities: (data: IPromoterAdminStats) => data.sales,
    getEntityDate: (entity: IPromoterSale) => new Date(entity.date),
    getEntityValue: (entity: IPromoterSale) => entity.vehicleValue,
    title: 'Ventas',
  },
  quickInfoCards: [
    {
      key: 'applications',
      title: 'Solicitudes',
    },
    {
      key: 'offeredApplications',
      title: 'Solicitudes ofertadas',
    },
    {
      key: 'finalOffers',
      title: 'Aprobaciones',
    },
    {
      key: 'sales.length',
      title: 'Ventas',
    },
    {
      key: 'commissions',
      title: 'Comisiones',
      format: 'currency',
    },
  ],
};

export const getDashboardConfiguration = (
  userData: IUserData
): IDashboardConfig | null => {
  if (isBankUser(userData) && isBankAdmin(userData)) {
    return bankAdminDashboadConfig;
  } else if (isPromoterUser(userData) && isPromoterAdmin(userData)) {
    return promoterAdminDashboardConfig;
  } else if (isCreditusUser(userData)) {
    return creditusAdminDashboardConfig;
  } else {
    return null;
  }
};
