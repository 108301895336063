import accountCreationActions from '../actions/accountCreation';
import applicationCreationActions from '../actions/applicationCreation';
import authActions from '../actions/auth';
import automaticProductsActions from '../actions/automaticProducts';
import documentsActions from '../actions/documents';
import existentClientActions from '../actions/existentClient';
import finalOffersActions from '../actions/finalOffers';
import forgotPasswordActions from '../actions/forgotPassword';
import idVerificationActions from '../actions/idVerification';
import offerActions from '../actions/offers';
import passwordChangeActions from '../actions/passwordChange';
import productsActions from '../actions/products';
import profileSettingsActions from '../actions/profileSettings';
import promoterActions from '../actions/promoter';
import promotersClientsActions from '../actions/promotersClients';
import requestsActions from '../actions/requests';
import userManagementActions from '../actions/userManagement';
import verificationModalActions from '../actions/verificationModal';

const LOADERS = [
  {
    key: 'changingPassword',
    loading: passwordChangeActions.types.PASSWORD_CHANGE.STARTED,
    loaded: [
      passwordChangeActions.types.PASSWORD_CHANGE.COMPLETED,
      passwordChangeActions.types.PASSWORD_CHANGE.FAILED,
    ],
  },
  {
    key: 'checkingSession',
    loading: authActions.types.AUTH_FLOW_STARTED,
    loaded: [
      authActions.types.AUTH_SESSION_NOT_FOUND,
      authActions.types.AUTH_USER_DATA_FETCH.COMPLETED,
    ],
  },
  {
    key: 'loggingIn',
    loading: authActions.types.AUTH_LOGIN.STARTED,
    loaded: [
      authActions.types.AUTH_LOGIN.COMPLETED,
      authActions.types.AUTH_LOGIN.FAILED,
    ],
  },
  {
    key: 'checkingLoginType',
    loading: authActions.types.GET_LOGIN_TYPE.STARTED,
    loaded: [
      authActions.types.GET_LOGIN_TYPE.COMPLETED,
      authActions.types.GET_LOGIN_TYPE.FAILED,
    ],
  },
  {
    key: 'fetchingUsers',
    loading: userManagementActions.types.USERS_FETCH.STARTED,
    loaded: [
      userManagementActions.types.USERS_FETCH.COMPLETED,
      userManagementActions.types.USERS_FETCH.FAILED,
    ],
  },
  {
    key: 'fetchingOffers',
    loading: offerActions.types.OFFERS_FETCH.STARTED,
    loaded: [
      offerActions.types.OFFERS_FETCH.COMPLETED,
      offerActions.types.OFFERS_FETCH.FAILED,
    ],
  },
  {
    key: 'updatingFormalizationDate',
    loading:
      finalOffersActions.types.FINAL_OFFER_UPDATE_FORMALIZATION_DATE.REQUESTED,
    loaded: [
      finalOffersActions.types.FINAL_OFFER_UPDATE_FORMALIZATION_DATE.FAILED,
      offerActions.types.OFFERS_FETCH_BY_ID.COMPLETED,
      offerActions.types.OFFERS_FETCH_BY_ID.FAILED,
    ],
  },
  {
    key: 'updatingFormalizationDateStatus',
    loading:
      finalOffersActions.types.FINAL_OFFER_UPDATE_FORMALIZATION_DATE_STATUS
        .REQUESTED,
    loaded: [
      finalOffersActions.types.FINAL_OFFER_UPDATE_FORMALIZATION_DATE_STATUS
        .FAILED,
      offerActions.types.OFFERS_FETCH_BY_ID.COMPLETED,
      offerActions.types.OFFERS_FETCH_BY_ID.FAILED,
    ],
  },
  {
    key: 'uploadingFiles',
    loading: documentsActions.types.DOCUMENT_FILE_UPLOAD.STARTED,
    loaded: [
      documentsActions.types.DOCUMENT_FILE_UPLOAD.COMPLETED,
      documentsActions.types.DOCUMENT_FILE_UPLOAD.FAILED,
    ],
  },
  {
    key: 'fetchingGrantedProducts',
    loading: productsActions.types.PRODUCTS_GRANTED_FETCH.STARTED,
    loaded: [
      productsActions.types.PRODUCTS_GRANTED_FETCH.COMPLETED,
      productsActions.types.PRODUCTS_GRANTED_FETCH.FAILED,
    ],
  },

  {
    key: 'fetchingProductsManufacturers',
    loading: productsActions.types.PRODUCTS_MANUFACTURERS_FETCH.STARTED,
    loaded: [
      productsActions.types.PRODUCTS_MANUFACTURERS_FETCH.COMPLETED,
      productsActions.types.PRODUCTS_MANUFACTURERS_FETCH.FAILED,
    ],
  },
  {
    key: 'fetchingPromoterProducts',
    loading: productsActions.types.PROMOTER_PRODUCTS_FETCH.STARTED,
    loaded: [
      productsActions.types.PROMOTER_PRODUCTS_FETCH.COMPLETED,
      productsActions.types.PROMOTER_PRODUCTS_FETCH.FAILED,
    ],
  },
  {
    key: 'fetchingRequestsOffered',
    loading: requestsActions.types.REQUESTS_OFFERED_FETCH.STARTED,
    loaded: [
      requestsActions.types.REQUESTS_OFFERED_FETCH.COMPLETED,
      requestsActions.types.REQUESTS_OFFERED_FETCH.FAILED,
    ],
  },
  {
    key: 'fetchingPromotersClients',
    loading: promotersClientsActions.types.PROMOTERS_CLIENTS_FETCH.STARTED,
    loaded: [
      promotersClientsActions.types.PROMOTERS_CLIENTS_FETCH.COMPLETED,
      promotersClientsActions.types.PROMOTERS_CLIENTS_FETCH.FAILED,
    ],
  },
  {
    key: 'creatingClientAccount',
    loading: accountCreationActions.types.ACCOUNT_CREATION_CLIENT.STARTED,
    loaded: [
      accountCreationActions.types.ACCOUNT_CREATION_CLIENT.COMPLETED,
      accountCreationActions.types.ACCOUNT_CREATION_CLIENT.FAILED,
    ],
  },
  {
    key: 'creatingFormalizationDocuments',
    loading:
      finalOffersActions.types.FINAL_OFFER_CREATE_FORMALIZATION_DOCUMENTS
        .STARTED,
    loaded: [
      finalOffersActions.types.FINAL_OFFER_CREATE_FORMALIZATION_DOCUMENTS
        .COMPLETED,
      finalOffersActions.types.FINAL_OFFER_CREATE_FORMALIZATION_DOCUMENTS
        .FAILED,
    ],
  },
  {
    key: 'verifyingClientAccount',
    loading:
      accountCreationActions.types.ACCOUNT_CREATION_CLIENT_VERIFICATION.STARTED,
    loaded: [
      accountCreationActions.types.ACCOUNT_CREATION_CLIENT_VERIFICATION
        .COMPLETED,
      accountCreationActions.types.ACCOUNT_CREATION_CLIENT_VERIFICATION.FAILED,
    ],
  },
  {
    key: 'creatingApplication',
    loading: applicationCreationActions.types.APPLICATION_CREATION.STARTED,
    loaded: [
      applicationCreationActions.types.APPLICATION_CREATION.COMPLETED,
      applicationCreationActions.types.APPLICATION_CREATION.FAILED,
    ],
  },
  {
    key: 'verificationStarted',
    loading: verificationModalActions.types.VERIFICATION_START.REQUESTED,
    loaded: [
      verificationModalActions.types.VERIFICATION_START.COMPLETED,
      verificationModalActions.types.VERIFICATION_START.FAILED,
    ],
  },
  {
    key: 'waitingForVerification',
    loading:
      verificationModalActions.types.VERIFICATION_WAIT_COMPLETION.REQUESTED,
    loaded: [
      verificationModalActions.types.VERIFICATION_WAIT_COMPLETION.COMPLETED,
      verificationModalActions.types.VERIFICATION_WAIT_COMPLETION.FAILED,
    ],
  },
  {
    key: 'waitingForCurp',
    loading: verificationModalActions.types.VERIFICATION_GET_CURP.REQUESTED,
    loaded: [
      verificationModalActions.types.VERIFICATION_GET_CURP.COMPLETED,
      verificationModalActions.types.VERIFICATION_GET_CURP.FAILED,
    ],
  },
  {
    key: 'waitingForPrefillingData',
    loading:
      verificationModalActions.types.VERIFICATION_GET_PREFILLING_DATA.REQUESTED,
    loaded: [
      verificationModalActions.types.VERIFICATION_GET_PREFILLING_DATA.COMPLETED,
      verificationModalActions.types.VERIFICATION_GET_PREFILLING_DATA.FAILED,
    ],
  },
  {
    key: 'fetchingPromoterData',
    loading: promoterActions.types.PROMOTERS_INFO_FETCH.REQUESTED,
    loaded: [
      promoterActions.types.PROMOTERS_INFO_FETCH.COMPLETED,
      promoterActions.types.PROMOTERS_INFO_FETCH.FAILED,
    ],
  },
  {
    key: 'updatingProductStatus',
    loading: automaticProductsActions.types.UPDATE_PRODUCT_STATUS.REQUESTED,
    loaded: [
      automaticProductsActions.types.UPDATE_PRODUCT_STATUS.COMPLETED,
      automaticProductsActions.types.UPDATE_PRODUCT_STATUS.FAILED,
    ],
  },
  {
    key: 'updatingNotifications',
    loading: profileSettingsActions.types.CHANGE_NOTIFICATIONS.REQUESTED,
    loaded: [
      profileSettingsActions.types.CHANGE_NOTIFICATIONS.COMPLETED,
      profileSettingsActions.types.CHANGE_NOTIFICATIONS.FAILED,
    ],
  },
  {
    key: 'updatingFollowUpEmails',
    loading: profileSettingsActions.types.CHANGE_FOLLOW_UP_EMAILS.REQUESTED,
    loaded: [
      profileSettingsActions.types.CHANGE_FOLLOW_UP_EMAILS.COMPLETED,
      profileSettingsActions.types.CHANGE_FOLLOW_UP_EMAILS.FAILED,
    ],
  },
  {
    key: 'creatingNewValidatedData',
    loading: idVerificationActions.types.CREATE_NEW_VALIDATED_DATA.REQUESTED,
    loaded: [
      idVerificationActions.types.CREATE_NEW_VALIDATED_DATA.COMPLETED,
      idVerificationActions.types.CREATE_NEW_VALIDATED_DATA.FAILED,
    ],
  },
  {
    key: 'uploadingPaymentDocument',
    loading: offerActions.types.OFFERS_MOVE_TO_RELEASE.REQUESTED,
    loaded: [
      offerActions.types.OFFERS_MOVE_TO_RELEASE.COMPLETED,
      offerActions.types.OFFERS_MOVE_TO_RELEASE.FAILED,
    ],
  },
  {
    key: 'sendingNip',
    loading: existentClientActions.types.SEND_NIP_EXISTENT_CLIENT.REQUESTED,
    loaded: [
      existentClientActions.types.SEND_NIP_EXISTENT_CLIENT.COMPLETED,
      existentClientActions.types.SEND_NIP_EXISTENT_CLIENT.FAILED,
    ],
  },
  {
    key: 'verifyingNip',
    loading: existentClientActions.types.VERIFY_NIP_EXISTENT_CLIENT.REQUESTED,
    loaded: [
      existentClientActions.types.VERIFY_NIP_EXISTENT_CLIENT.COMPLETED,
      existentClientActions.types.VERIFY_NIP_EXISTENT_CLIENT.FAILED,
    ],
  },
  {
    key: 'checkingAccountWithEmail',
    loading: forgotPasswordActions.types.ACCOUNT_WITH_EMAIL_CHECK.REQUESTED,
    loaded: [
      forgotPasswordActions.types.ACCOUNT_WITH_EMAIL_CHECK.COMPLETED,
      forgotPasswordActions.types.ACCOUNT_WITH_EMAIL_CHECK.FAILED,
    ],
  },
  {
    key: 'sendingPasswordRecovery',
    loading: forgotPasswordActions.types.SEND_PASSWORD_RECOVERY.REQUESTED,
    loaded: [
      forgotPasswordActions.types.SEND_PASSWORD_RECOVERY.COMPLETED,
      forgotPasswordActions.types.SEND_PASSWORD_RECOVERY.FAILED,
    ],
  },
  {
    key: 'changingPassword',
    loading: forgotPasswordActions.types.RESET_PASSWORD.REQUESTED,
    loaded: [
      forgotPasswordActions.types.RESET_PASSWORD.COMPLETED,
      forgotPasswordActions.types.RESET_PASSWORD.FAILED,
    ],
  },
];

const pushToActionBucket = (registry, action, handler) => {
  if (!registry[action]) {
    registry[action] = [];
  }

  registry[action].push(handler);
};

const buildRegistry = loaders =>
  loaders.reduce((registry, loaderConfig) => {
    const { key, loading, loaded } = loaderConfig;

    if (loading.constructor === Array) {
      loading.forEach(action =>
        pushToActionBucket(registry, action, { key, value: true })
      );
    } else {
      pushToActionBucket(registry, loading, { key, value: true });
    }

    if (loaded.constructor === Array) {
      loaded.forEach(action =>
        pushToActionBucket(registry, action, { key, value: false })
      );
    } else {
      pushToActionBucket(registry, loaded, { key, value: false });
    }

    return registry;
  }, {});

const loadersReducer = () => {
  const actionsRegistry = buildRegistry(LOADERS);

  return (state = {}, { type }) => {
    if (actionsRegistry[type]) {
      const newSubState = actionsRegistry[type].reduce(
        (newSubState, loader) => {
          newSubState[loader.key] = loader.value;
          return newSubState;
        },
        {}
      );

      return { ...state, ...newSubState };
    }

    return state;
  };
};

export default loadersReducer();
