import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const FETCH_BANK_AUTOMATIC_PRODUCTS = createAsyncFlowTypes(
  'FETCH_BANK_AUTOMATIC_PRODUCTS'
);
const FETCH_BANK_PRODUCT_DETAILS = createAsyncFlowTypes(
  'FETCH_BANK_PRODUCT_DETAILS'
);
const UPDATE_PRODUCT_STATUS = createAsyncFlowTypes('UPDATE_PRODUCT_STATUS');

const fetchBankAutomaticProducts = createAsyncFlowCreators(
  FETCH_BANK_AUTOMATIC_PRODUCTS
);
const fetchBankProductDetails = createAsyncFlowCreators(
  FETCH_BANK_PRODUCT_DETAILS
);
const updateProductStatus = createAsyncFlowCreators(UPDATE_PRODUCT_STATUS);

export default {
  types: {
    FETCH_BANK_AUTOMATIC_PRODUCTS,
    FETCH_BANK_PRODUCT_DETAILS,
    UPDATE_PRODUCT_STATUS,
  },
  creators: {
    fetchBankAutomaticProducts,
    fetchBankProductDetails,
    updateProductStatus,
  },
};
