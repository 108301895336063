import { handleActions } from 'redux-actions';

import utils from './utils';

const initialState = {};

const requestsReducer = handleActions(
  {
    REQUESTS_OFFERED_FETCH_COMPLETED: (state, action) =>
      utils.buildDictWithKey(action.payload.data, 'id'),
  },
  initialState
);

export default requestsReducer;
