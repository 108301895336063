import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const VEHICLES_FETCH_MANUFACTURERS = createAsyncFlowTypes(
  'VEHICLES_FETCH_MANUFACTURERS'
);
const VEHICLES_FETCH_MODELS = createAsyncFlowTypes('VEHICLES_FETCH_MODELS');

const fetchVehicleManufacturers = createAsyncFlowCreators(
  VEHICLES_FETCH_MANUFACTURERS
);
const fetchVehicleModels = createAsyncFlowCreators(VEHICLES_FETCH_MODELS);

export default {
  types: {
    VEHICLES_FETCH_MANUFACTURERS,
    VEHICLES_FETCH_MODELS,
  },
  creators: {
    fetchVehicleManufacturers,
    fetchVehicleModels,
  },
};
