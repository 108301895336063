import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Cover = styled.div`
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${colors.BACKGROUND75};
  z-index: 9999;
`;

const CoverLoading: React.FunctionComponent = ({ children }) => {
  return (
    <Cover>
      <Content>{children}</Content>
    </Cover>
  );
};

export default CoverLoading;
