import axios from 'axios';

const { REACT_APP_API_HOST: HOST } = process.env;

const client = axios.create({
  baseURL: HOST,
  withCredentials: true,
});

export default client;
