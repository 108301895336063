import { handleActions } from 'redux-actions';

const initialState = null;

const accountVerificationSuccededReducer = handleActions(
  {
    ACCOUNT_CREATION_CLIENT_VERIFICATION_COMPLETED: () => true,
    ACCOUNT_CREATION_CLIENT_VERIFICATION_FAILED: () => false,
    CLEAR_NEW_APPLICATION: () => initialState,
  },
  initialState
);

export default accountVerificationSuccededReducer;
