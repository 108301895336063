import { handleActions } from 'redux-actions';

const initialState = {
  applications: null,
};

const auditReducer = handleActions(
  {
    FETCH_AUDIT_APPLICATIONS_COMPLETED: (state, { payload }) => {
      return { applications: payload.applications };
    },
  },
  initialState
);

export default auditReducer;
