import { handleActions } from 'redux-actions';

const initialState = {
  isExistentClient: true,
  clientAccount: null,
  complianceId: null,
  nipVerified: null,
};

const existentClientReducer = handleActions(
  {
    CLIENT_EXISTS_COMPLETED: (state, { payload }) => ({
      clientAccount: payload.clientAccount,
      complianceId: null,
      nipVerified: null,
      isExistentClient: payload.isExistentClient,
    }),
    CLIENT_EXISTS_CLIENT_FAILED: (state, { payload }) => initialState,
    SEND_NIP_EXISTENT_CLIENT_REQUESTED: (state, { payload }) => ({
      ...state,
      complianceId: null,
    }),
    SEND_NIP_EXISTENT_CLIENT_COMPLETED: (state, { payload }) => ({
      ...state,
      complianceId: payload.complianceId,
    }),
    VERIFY_NIP_EXISTENT_CLIENT_REQUESTED: (state, { payload }) => ({
      ...state,
      nipVerified: null,
    }),
    VERIFY_NIP_EXISTENT_CLIENT_COMPLETED: (state, { payload }) => ({
      ...state,
      nipVerified: true,
    }),
    VERIFY_NIP_EXISTENT_CLIENT_FAILED: (state, { payload }) => ({
      ...state,
      nipVerified: false,
    }),
    CLEAR_NEW_APPLICATION: () => initialState,
  },
  initialState
);

export default existentClientReducer;
