import compareDesc from 'date-fns/compareDesc';
import format from 'date-fns/format';
import values from 'ramda/src/values';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import commissionsActions from '../../actions/commissions';
import colors from '../../constants/colors';
import f from '../../constants/formatters';
import { isCreditusUser } from '../../utils/permissions';
import Card from '../Card';
import ExpandableTable from '../ExpandableTable';
import LoadingIndicator from '../LoadingIndicator';
import { TableCell } from '../SmartTable';

const EXPANDED_ROW_HEIGHT = 160;

interface ICreditusCommission extends ICommission {
  offer: {
    id: number;
    bank: {
      name: string;
    };
    product: {
      commercialName: string;
      internalName: string;
      productType: string;
    };
    application: {
      id: number;
      promoter: {
        name: string;
      };
    };
  };
}

const productTypes: { [key: string]: string } = {
  car_finance: 'Crédito automotriz',
  motorcycle_finance: 'Crédito motocicleta',
};

const ExpandedSectionContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100px;
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30vh;
`;

const SizebleDiv = styled.div<{ width: string }>`
  width: ${({ width }) => width};
`;

interface ILabelAndTextProps {
  label: string;
  text: string;
  width: string;
}

const LabelAndText: React.FunctionComponent<ILabelAndTextProps> = ({
  label,
  text,
  width,
}) => (
  <SizebleDiv width={width}>
    <Label>{label}</Label>
    <Text>{text}</Text>
  </SizebleDiv>
);

const Label = styled.div`
  color: ${colors.DUSK50};
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 12px;
  text-transform: uppercase;
`;

const Text = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 12px;
`;

const configurableRenderVisibleRow = ({
  showOrganizationName,
}: {
  showOrganizationName: 'bank' | 'promoter';
}) => (commission: ICreditusCommission) => {
  const organizationName =
    showOrganizationName === 'bank'
      ? commission.offer.bank.name
      : commission.offer.application.promoter.name;

  return (
    <Fragment>
      <TableCell>{commission.id}</TableCell>
      <TableCell>{commission.offerId}</TableCell>
      <TableCell>{format(commission.createdAt, 'dd/MM/y')}</TableCell>
      <TableCell>{organizationName}</TableCell>
      <TableCell>
        {productTypes[commission.offer.product.productType]}
      </TableCell>
      <TableCell>{f.currency(commission.total)}</TableCell>
      <TableCell>{commission.invoiceId || 'No facturado'}</TableCell>
    </Fragment>
  );
};

const configurableRenderHiddenRow = ({
  showProductName,
}: {
  showProductName: 'commercial' | 'internal';
}) => (commission: ICreditusCommission) => {
  const productName =
    showProductName === 'commercial'
      ? commission.offer.product.commercialName
      : commission.offer.product.internalName;
  return (
    <ExpandedSectionContainer>
      <LabelAndText label="Producto" text={productName} width="20%" />
      <LabelAndText
        label="Monto"
        text={f.currency(commission.creditAmount)}
        width="10%"
      />
      <LabelAndText
        label="% de comisión"
        text={f.percentage(commission.factor * 100)}
        width="20%"
      />
      <LabelAndText
        label="Comisión"
        text={f.currency(commission.totalBeforeTaxes)}
        width="20%"
      />
      <LabelAndText
        label="IVA"
        text={f.currency(commission.taxes)}
        width="15%"
      />
    </ExpandedSectionContainer>
  );
};

export const CommissionsTable: React.FunctionComponent<{
  commissions: ICreditusCommission[];
  isCreditusUser: boolean;
  type: 'payable' | 'receivable';
}> = ({ commissions, isCreditusUser, type }) => {
  const renderHiddenRow = (() => {
    if (type === 'payable') {
      return isCreditusUser
        ? configurableRenderHiddenRow({ showProductName: 'internal' })
        : configurableRenderHiddenRow({ showProductName: 'internal' });
    } else {
      return isCreditusUser
        ? configurableRenderHiddenRow({ showProductName: 'internal' })
        : configurableRenderHiddenRow({ showProductName: 'commercial' });
    }
  })();

  const renderVisibleRow = (() => {
    if (type === 'payable') {
      return isCreditusUser
        ? configurableRenderVisibleRow({ showOrganizationName: 'promoter' })
        : configurableRenderVisibleRow({ showOrganizationName: 'promoter' });
    } else {
      return isCreditusUser
        ? configurableRenderVisibleRow({ showOrganizationName: 'bank' })
        : configurableRenderVisibleRow({ showOrganizationName: 'bank' });
    }
  })();

  const tableHeaders = [
    'Comisión',
    'Oferta',
    'Fecha',
    type === 'payable' ? 'Comercio' : 'Otorgante',
    'Tipo',
    'Total',
    'Factura',
  ];

  return (
    <ExpandableTable
      data={commissions.sort((a, b) => compareDesc(a.createdAt, b.createdAt))}
      gridColumns={`10% 10% 10% 20% 20% 15% 15%`}
      headers={tableHeaders}
      rowHeights={commissions.map(() => EXPANDED_ROW_HEIGHT)}
      renderHiddenRow={renderHiddenRow}
      renderVisibleRow={renderVisibleRow}
    />
  );
};

interface IStateProps {
  commissions: ICreditusCommission[] | null;
  userData: IUserData;
}

interface IDispatchProps {
  fetchCommissions: ({ type }: { type: CommissionsTypes }) => void;
}

type CommissionsViewProps = IStateProps &
  IDispatchProps &
  RouteComponentProps<{ type: CommissionsTypes }>;

const CommissionsView: React.FunctionComponent<CommissionsViewProps> = ({
  commissions,
  fetchCommissions,
  match,
  userData,
}) => {
  const { type } = match.params;

  useEffect(() => {
    fetchCommissions({ type });
  }, [fetchCommissions, type]);

  return (
    <Card header="Comisiones">
      {commissions === null ? (
        <LoadingIndicatorContainer>
          <LoadingIndicator />
        </LoadingIndicatorContainer>
      ) : (
        <CommissionsTable
          commissions={commissions}
          isCreditusUser={isCreditusUser(userData)}
          type={type}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (state: any) => ({
  commissions:
    state.entities.commissions === null
      ? state.entities.commissions
      : values(state.entities.commissions),
  userData: state.auth.userData,
});

const creators = {
  fetchCommissions: commissionsActions.creators.fetchCommissions.request,
};

export default connect(mapStateToProps, creators)(CommissionsView);
