import { delay } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';

import toastNotificationsActions from '../actions/toastNotifications';

const getId = (() => {
  let id = -1;

  return () => {
    id += 1;
    return id;
  };
})();

function* pushToast(action) {
  const { dismissIn } = action.payload;
  const id = getId();

  yield put(
    toastNotificationsActions.creators.pushToast({ ...action.payload, id })
  );

  if (dismissIn) {
    yield delay(dismissIn);

    yield put(toastNotificationsActions.creators.removeToast({ id }));
  }
}

function* watchShowToast() {
  yield takeEvery(toastNotificationsActions.types.SHOW_TOAST, pushToast);
}

export default {
  watchShowToast,
};
