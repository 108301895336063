import prop from 'ramda/src/prop';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import toastNotificationsActions from '../../actions/toastNotifications';
import colors from '../../constants/colors';
import deviceSizes from '../../constants/deviceSizes';
import ImportantIcon from '../../resources/icons/Important';
import SuccessIcon from '../../resources/icons/Success';

const Container = styled.div<{
  centerOnMainContent: boolean;
  isMobile: boolean;
}>`
  position: fixed;
  left: ${props =>
    props.centerOnMainContent && !props.isMobile ? '308px' : '40px'};
  right: 40px;
  top: 5vh;
  z-index: 1000;
`;

const NotificationContainer = styled.div<{ backgroundColor: string }>`
  align-items: center;
  background-color: ${prop('backgroundColor')};
  border-radius: 4px;
  color: ${colors.WHITE};
  display: flex;
  margin-bottom: 20px;
  min-height: 52px;
  padding: 16px;

  @media (max-width: ${deviceSizes.laptop.width}) {
    width: 800px;
  }
`;

const Text = styled.div`
  color: ${colors.WHITE};
  font-size: 14px;
  margin-left: 20px;
`;

const toastConfigurations = {
  success: {
    backgroundColor: colors.GREEN,
    icon: SuccessIcon,
  },
  error: {
    backgroundColor: colors.RED,
    icon: ImportantIcon,
  },
  warning: {
    backgroundColor: colors.ORANGE,
    icon: ImportantIcon,
  },
};

type ToastType = 'success' | 'error' | 'warning';

interface IDismissToastPayload {
  id: number;
}

interface INotification {
  id: number;
  text: string;
  type: ToastType;
}

interface INotificationComponent extends INotification {
  dismiss: (payload: IDismissToastPayload) => void;
}

interface IToastNotificationsProvider {
  dismiss: (payload: IDismissToastPayload) => void;
  notifications: Array<INotification>;
  userAuthenticated: boolean;
  isMobile: boolean;
}

const getToastConfig = (type: ToastType) =>
  toastConfigurations[type] || toastConfigurations['warning'];

const Notification: React.FunctionComponent<INotificationComponent> = ({
  dismiss,
  id,
  text,
  type,
}) => {
  const { backgroundColor, icon: Icon } = getToastConfig(type);

  return (
    <NotificationContainer
      backgroundColor={backgroundColor}
      onClick={() => dismiss({ id })}
    >
      <Icon fill={colors.WHITE} size={20} />
      <Text>{text}</Text>
    </NotificationContainer>
  );
};

const ToastNotificationsProvider: React.FunctionComponent<IToastNotificationsProvider> = ({
  dismiss,
  notifications,
  userAuthenticated,
  isMobile,
}) => {
  return (
    <Container centerOnMainContent={userAuthenticated} isMobile={isMobile}>
      {notifications.map((notification, i) => (
        <Notification
          key={i}
          dismiss={dismiss}
          id={notification.id}
          text={notification.text}
          type={notification.type}
        />
      ))}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  notifications: state.toastNotifications,
  userAuthenticated: state.auth.userAuthenticated,
  isMobile: state.deviceData.isMobile,
});

const creators = {
  dismiss: toastNotificationsActions.creators.removeToast,
};

export default connect(mapStateToProps, creators)(ToastNotificationsProvider);
