import { handleActions } from 'redux-actions';

const initialState = {
  creditHistoryQueries: null,
  clientApplications: null,
};

const clientUsersReducer = handleActions(
  {
    CLIENT_CREDIT_HISTORY_FETCH_COMPLETED: (state, { payload }) => ({
      ...initialState,
      creditHistoryQueries: payload,
    }),
    FETCH_CLIENT_APPLICATIONS_COMPLETED: (state, { payload }) => ({
      ...initialState,
      clientApplications: payload,
    }),
  },
  initialState
);

export default clientUsersReducer;
