import { useEffect, useState } from 'react';

import store from '../store';

const useUserData = () => {
  const [userData, setUserData] = useState<IUserData>(
    store.getState().auth.userData
  );

  const { userAuthenticated, userData: storeUserData } = store.getState().auth;

  useEffect(() => {
    if (userAuthenticated) {
      setUserData(storeUserData);
    }
  }, [storeUserData, userAuthenticated]);

  return userData;
};

export default useUserData;
