import { FormikProps } from 'formik';
import length from 'ramda/src/length';
import { useEffect, useState } from 'react';

import toastNotificationsActions from '../actions/toastNotifications';
import store from '../store';
import { applySelector, getPostalCodeInfo } from '../utils/misc';
import { buildErrorToast } from '../utils/toastNotifications';

const postalCodeRegExp = /^\d{5}$/;

const showErrorToast = () => {
  store.dispatch(
    toastNotificationsActions.creators.showToast(
      buildErrorToast(
        'El código postal insertado no existe o no está registrado.',
        3000
      )
    )
  );
};

const usePostalCodeOptions = (
  formikProps: FormikProps<any>,
  fieldSelector: string = 'postalCode'
) => {
  const [options, setOptions] = useState<Array<any>>([]);
  const postalCode = applySelector(formikProps.values, fieldSelector);

  useEffect(() => {
    if (postalCode && postalCodeRegExp.test(postalCode)) {
      getPostalCodeInfo(postalCode).then(response => {
        if (length(response.data)) {
          setOptions(response.data);
        } else {
          setOptions([]);
          showErrorToast();
        }
      });
    } else {
      setOptions([]);
    }
  }, [postalCode]);
  return options;
};

export default usePostalCodeOptions;
