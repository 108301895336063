import { handleActions } from 'redux-actions';

const initialState = {
  verifications: null,
};

const idVerificationReducer = handleActions(
  {
    FETCH_BIOMETRIC_VERIFICATIONS_COMPLETED: (state, { payload }) => {
      return { verifications: payload.verifications };
    },
  },
  initialState
);

export default idVerificationReducer;
