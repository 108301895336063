import { FieldArray, Form, Formik } from 'formik';
import values from 'ramda/src/values';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import banksActions from '../../actions/banks';
import creditusActions from '../../actions/creditus';
import colors from '../../constants/colors';
import grammar from '../../constants/grammar';
import client from '../../sagas/client';
import Button from '../Button';
import Dropdown from '../Dropdown';
import { FormikPhone, FormikText } from '../FormikInputs';
import Input from '../Input';
import SimpleCard from '../SimpleCard';

const DeleteIconContainer = styled.div`
  align-items: center;
  color: ${colors.SECONDARY_TEXT};
  cursor: pointer;
  display: flex;
  font-size: 24px;
  transform: rotate(45deg);
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  padding: 24px 40px;
`;

const IntraCardMargin = styled.div`
  margin-top: 24px;
`;

const ListItemContainer = styled.div`
  align-items: center;
  background-color: ${colors.ALASKA_GRAY};
  border-radius: 8px;
  display: inline-flex;
  padding: 8px;
`;

const ListItemChildrenContainer = styled.div`
  margin-right: 16px;
`;

const Pre = styled.pre`
  background-color: ${colors.ALASKA_GRAY};
  display: inline-block;
  margin: 24px;
  padding: 24px;
`;

const PromoterConfigurationControls = styled.div`
  align-items: center;
  display: grid;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-columns: repeat(3, 280px);
`;

const PromoterConfigurationSection = styled.div`
  margin-bottom: 24px;
`;

const SubmitButtonContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  margin-top: 12px;
  width: 100%;
`;

const Subtitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`;

type VehicleTypes = 'car' | 'motorcycle';

interface IPromoterBank {
  bankId: number;
  productType: ProductTypes;
}

interface IPromoterManufacturer {
  manufacturerId: number;
  vehicleType: VehicleTypes;
}

interface IPromoterProductType {
  commission: number;
  productType: ProductTypes;
}

interface IPromoterSystemAdminUser {
  name: string;
  firstSurname: string;
  secondSurname: string;
  email: string;
  phoneNumber: string;
  password: string;
}

interface ICreatePromoterForm {
  name: string;
  legalName: string;
  taxpayerId: string;
  street: string;
  streetNumber: string;
  suiteNumber?: string;
  postalCode: string;
  neighborhood: string;
  municipality: string;
  city: string;
  state: string;
  country: string;
  phoneNumber: string;
  logo: string;
  accountBank: string;
  accountNumber: string;
  accountClabe: string;
  promoterBanks: IPromoterBank[];
  promoterProductTypes: IPromoterProductType[];
  promoterManufacturers: IPromoterManufacturer[];
  promoterSystemAdminUser: IPromoterSystemAdminUser;
}

const initialValues: ICreatePromoterForm = {
  name: '',
  legalName: '',
  taxpayerId: '',
  street: '',
  streetNumber: '',
  suiteNumber: '',
  postalCode: '',
  neighborhood: '',
  municipality: '',
  city: '',
  state: '',
  country: '',
  phoneNumber: '',
  logo: '',
  accountBank: '',
  accountNumber: '',
  accountClabe: '',
  promoterBanks: [],
  promoterProductTypes: [],
  promoterManufacturers: [],
  promoterSystemAdminUser: {
    name: '',
    firstSurname: '',
    secondSurname: '',
    email: '',
    phoneNumber: '',
    password: '',
  },
};

const promoterBanks = Yup.object<IPromoterBank>({
  bankId: Yup.number().required(),
  productType: Yup.mixed().oneOf(['car_finance', 'motorcycle_finance']),
});

const productTypes = Yup.object<IPromoterProductType>({
  commission: Yup.number().required(),
  productType: Yup.mixed().oneOf(['car_finance', 'motorcycle_finance']),
});

const promoterManufacturer = Yup.object<IPromoterManufacturer>({
  manufacturerId: Yup.number().required(),
  vehicleType: Yup.mixed().oneOf(['car', 'motorcycle']),
});

const promoterSystemAdminUser = Yup.object<IPromoterSystemAdminUser>({
  name: Yup.string().required(),
  firstSurname: Yup.string().required(),
  secondSurname: Yup.string().required(),
  email: Yup.string().required(),
  phoneNumber: Yup.string().required(),
  password: Yup.string().required(),
});

const validationSchema = Yup.object().shape<ICreatePromoterForm>({
  name: Yup.string().required(),
  legalName: Yup.string().required(),
  taxpayerId: Yup.string().required(),
  street: Yup.string().required(),
  streetNumber: Yup.string().required(),
  suiteNumber: Yup.string(),
  postalCode: Yup.string().required(),
  neighborhood: Yup.string().required(),
  municipality: Yup.string().required(),
  city: Yup.string().required(),
  state: Yup.string().required(),
  country: Yup.string().required(),
  phoneNumber: Yup.string().required(),
  logo: Yup.string().required(),
  accountBank: Yup.string().required(),
  accountNumber: Yup.string().required(),
  accountClabe: Yup.string().required(),
  promoterBanks: Yup.array()
    .of(promoterBanks)
    .required(),
  promoterProductTypes: Yup.array()
    .of(productTypes)
    .required(),
  promoterManufacturers: Yup.array()
    .of(promoterManufacturer)
    .required(),
  promoterSystemAdminUser,
});

const productTypesOptions = [
  { text: 'Autos', value: 'car_finance' },
  { text: 'Motos', value: 'motorcycle_finance' },
];

const PromoterBankForm: React.FunctionComponent<{
  banks: IBank[];
  createPromoterBank: (promoterBank: IPromoterBank) => void;
}> = ({ banks, createPromoterBank }) => {
  const [bankId, setBankId] = useState<string>('');
  const [productType, setProductType] = useState<string>('');

  return (
    <PromoterConfigurationControls>
      <Dropdown
        onChange={option => setBankId(`${option.value}`)}
        options={banks.map(({ id, name }) => ({
          text: name,
          value: `${id}`,
        }))}
        placeholder="Banco"
        value={bankId}
      />
      <Dropdown
        onChange={option => setProductType(`${option.value}`)}
        options={productTypesOptions}
        placeholder="Tipo de producto"
        value={productType}
      />
      <Button
        onClick={() =>
          createPromoterBank({
            bankId: Number.parseInt(bankId),
            productType: productType as ProductTypes,
          })
        }
      >
        Agregar
      </Button>
    </PromoterConfigurationControls>
  );
};

const PromoterProductTypeForm: React.FunctionComponent<{
  createProductType: (promoterProductType: IPromoterProductType) => void;
}> = ({ createProductType }) => {
  const [commission, setCommission] = useState<string>('');
  const [productType, setProductType] = useState<string>('');

  return (
    <PromoterConfigurationControls>
      <Dropdown
        onChange={option => setProductType(`${option.value}`)}
        options={productTypesOptions}
        placeholder="Tipo de producto"
        value={productType}
      />
      <Input
        label="Comisión (%)"
        onChange={event => setCommission(event.target.value)}
        value={commission}
      />
      <Button
        onClick={() =>
          createProductType({
            commission: Number.parseFloat(commission),
            productType: productType as ProductTypes,
          })
        }
      >
        Agregar
      </Button>
    </PromoterConfigurationControls>
  );
};

interface IVehicleManufacturer {
  id: number;
  name: string;
}

const vehicleTypesOptions = [
  { text: 'Autos', value: 'car' },
  { text: 'Motos', value: 'motorcycle' },
];

const PromoterManufacturerForm: React.FunctionComponent<{
  createPromoterManufacturer: (
    promoterManufacturer: IPromoterManufacturer
  ) => void;
  manufacturers: IVehicleManufacturer[];
}> = ({ createPromoterManufacturer, manufacturers }) => {
  const [manufacturer, setCommission] = useState<string>('');
  const [vehicleType, setVehicleType] = useState<string>('');

  return (
    <PromoterConfigurationControls>
      <Dropdown
        onChange={option => setCommission(`${option.value}`)}
        options={manufacturers.map(({ id, name }) => ({
          text: name,
          value: `${id}`,
        }))}
        placeholder="Marca"
        value={manufacturer}
      />
      <Dropdown
        onChange={option => setVehicleType(`${option.value}`)}
        options={vehicleTypesOptions}
        placeholder="Tipo de producto"
        value={vehicleType}
      />
      <Button
        onClick={() =>
          createPromoterManufacturer({
            manufacturerId: Number.parseInt(manufacturer),
            vehicleType: vehicleType as VehicleTypes,
          })
        }
      >
        Agregar
      </Button>
    </PromoterConfigurationControls>
  );
};

const ListItem: React.FunctionComponent<{ onClick: () => void }> = ({
  children,
  onClick,
}) => {
  return (
    <ListItemContainer>
      <ListItemChildrenContainer>{children}</ListItemChildrenContainer>
      <DeleteIconContainer onClick={onClick}>+</DeleteIconContainer>
    </ListItemContainer>
  );
};

const PromoterProductTypeListItem: React.FunctionComponent<{
  index: number;
  promoterProductType: IPromoterProductType;
  remove: (index: number) => void;
}> = ({ index, promoterProductType, remove }) => {
  return (
    <ListItem onClick={() => remove(index)}>
      {promoterProductType.commission} -{' '}
      {grammar[promoterProductType.productType]}
    </ListItem>
  );
};

const PromoterBankListItem: React.FunctionComponent<{
  index: number;
  bankName: string;
  promoterBank: IPromoterBank;
  remove: (index: number) => void;
}> = ({ index, bankName, promoterBank, remove }) => {
  return (
    <ListItem onClick={() => remove(index)}>
      {bankName} - {grammar[promoterBank.productType]}
    </ListItem>
  );
};

const vehicleTypeTranslation = {
  car: 'Autos',
  motorcycle: 'Motos',
};

const PromoterManufacturerListItem: React.FunctionComponent<{
  index: number;
  promoterManufacturer: IPromoterManufacturer;
  promoterName: string;
  remove: (index: number) => void;
}> = ({ index, promoterManufacturer, promoterName, remove }) => {
  return (
    <ListItem onClick={() => remove(index)}>
      {promoterName} -{' '}
      {vehicleTypeTranslation[promoterManufacturer.vehicleType]}
    </ListItem>
  );
};

interface ICreatePromoterViewStateProps {
  banks: { [key: string]: IBank };
}

interface ICreatePromoterViewDispatchProps {
  createPromoter: (formValues: ICreatePromoterForm) => void;
  fetchBanks: () => void;
}

type ICreatePromoterView = ICreatePromoterViewStateProps &
  ICreatePromoterViewDispatchProps;

const CreatePromoterView: React.FunctionComponent<ICreatePromoterView> = ({
  banks,
  createPromoter,
  fetchBanks,
}) => {
  const [manufactures, setManufacturers] = useState<
    IVehicleManufacturer[] | null
  >(null);
  useEffect(() => {
    fetchBanks();
  }, [fetchBanks]);
  useEffect(() => {
    client
      .get('api/vehicles/manufacturers')
      .then(response => setManufacturers(response.data));
  }, []);

  const manufacturerDict: { [key: number]: IVehicleManufacturer } =
    manufactures === null
      ? {}
      : manufactures.reduce((acc, manufacturer) => {
          acc[manufacturer.id] = manufacturer;
          return acc;
        }, {} as { [key: number]: IVehicleManufacturer });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={values => createPromoter(values)}
      render={formikProps => (
        <Form>
          <SimpleCard title="Crear comercio" subtitle="Información general">
            <Grid>
              <FormikText label="Nombre" name="name" />
              <FormikText label="Razón social" name="legalName" />
              <FormikText label="RFC" name="taxpayerId" />
              <FormikText label="Calle" name="street" />
              <FormikText
                label="Número Ext."
                name="streetNumber"
                inputMode="numeric"
              />
              <FormikText label="Número Int." name="suiteNumber" />
              <FormikText
                label="Código postal"
                name="postalCode"
                inputMode="numeric"
              />
              <FormikText label="Colonia" name="neighborhood" />
              <FormikText label="Municipio" name="municipality" />
              <FormikText label="Ciudad" name="city" />
              <FormikText label="Estado" name="state" />
              <FormikText label="País" name="country" />
              <FormikPhone label="Teléfono" name="phoneNumber" />
              <FormikText label="URL logo" name="logo" />
              <FormikText label="Banco" name="accountBank" />
              <FormikText label="Número cuenta" name="accountNumber" />
              <FormikText label="Clabe" name="accountClabe" />
            </Grid>
          </SimpleCard>
          <IntraCardMargin>
            <SimpleCard
              title="Usuario administrador"
              subtitle="Información personal"
            >
              <Grid>
                <FormikText
                  label="Nombre"
                  name="promoterSystemAdminUser.name"
                />
                <FormikText
                  label="Primer apellido"
                  name="promoterSystemAdminUser.firstSurname"
                />
                <FormikText
                  label="Segundo apellido"
                  name="promoterSystemAdminUser.secondSurname"
                />
                <FormikText
                  label="Email"
                  name="promoterSystemAdminUser.email"
                />
                <FormikPhone
                  label="Teléfono"
                  name="promoterSystemAdminUser.phoneNumber"
                />
                <FormikText
                  label="Contraseña temporal"
                  name="promoterSystemAdminUser.password"
                />
              </Grid>
            </SimpleCard>
          </IntraCardMargin>
          <IntraCardMargin>
            <SimpleCard title="Configuración" subtitle="Configuración">
              <div style={{ padding: '24px 40px' }}>
                <PromoterConfigurationSection>
                  <Subtitle>Productos</Subtitle>
                  <FieldArray
                    name="promoterProductTypes"
                    render={({ push, remove }) => (
                      <div>
                        <PromoterProductTypeForm createProductType={push} />
                        {formikProps.values.promoterProductTypes.map(
                          (promoterProductType, i) => (
                            <PromoterProductTypeListItem
                              key={i}
                              index={i}
                              promoterProductType={promoterProductType}
                              remove={remove}
                            />
                          )
                        )}
                      </div>
                    )}
                  />
                </PromoterConfigurationSection>
                <PromoterConfigurationSection>
                  <Subtitle>Otorgantes</Subtitle>
                  <FieldArray
                    name="promoterBanks"
                    render={({ push, remove }) => (
                      <div>
                        <PromoterBankForm
                          banks={values(banks)}
                          createPromoterBank={push}
                        />
                        {formikProps.values.promoterBanks.map(
                          (promoterBank, i) => (
                            <PromoterBankListItem
                              key={i}
                              index={i}
                              bankName={banks[promoterBank.bankId].name}
                              promoterBank={promoterBank}
                              remove={remove}
                            />
                          )
                        )}
                      </div>
                    )}
                  />
                </PromoterConfigurationSection>
                <PromoterConfigurationSection>
                  <Subtitle>Marcas</Subtitle>
                  <FieldArray
                    name="promoterManufacturers"
                    render={({ push, remove }) => (
                      <div>
                        <PromoterManufacturerForm
                          createPromoterManufacturer={push}
                          manufacturers={
                            manufactures === null
                              ? []
                              : manufactures.map(({ id, name }) => ({
                                  id,
                                  name,
                                }))
                          }
                        />
                        {formikProps.values.promoterManufacturers.map(
                          (promoterManufacturer, i) => (
                            <PromoterManufacturerListItem
                              key={i}
                              index={i}
                              promoterManufacturer={promoterManufacturer}
                              promoterName={
                                manufacturerDict[
                                  promoterManufacturer.manufacturerId
                                ].name
                              }
                              remove={remove}
                            />
                          )
                        )}
                      </div>
                    )}
                  />
                </PromoterConfigurationSection>
              </div>
              <Pre>{JSON.stringify(formikProps.values, null, 2)}</Pre>
              <Pre>{JSON.stringify(formikProps.errors, null, 2)}</Pre>
            </SimpleCard>
          </IntraCardMargin>
          <SubmitButtonContainer>
            <Button type="submit">Crear</Button>
          </SubmitButtonContainer>
        </Form>
      )}
      validationSchema={validationSchema}
    />
  );
};

const mapStateToProps = (state: any) => ({
  banks: state.entities.banks,
});

const creators = {
  createPromoter: creditusActions.creators.createPromoter.request,
  fetchBanks: banksActions.creators.fetchBanks.request,
};

export default connect(mapStateToProps, creators)(CreatePromoterView);
