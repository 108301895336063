import { Formik, FormikProps } from 'formik';
import isEmpty from 'ramda/src/isEmpty';
import values from 'ramda/src/values';
import React, { Fragment, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import colors from '../../constants/colors';
import {
  IProfilingSuggestionResult,
  ProfilingStatus,
  Suggestions,
} from '../../hooks/useProfilingSuggestions';
import Button from '../Button';
import { FormikPhone, FormikText } from '../FormikInputs';
import { IContactForm, IExistentAccountForm } from '../ProfilingSectionsForms';
import errors from './errorMessages';

const CardsContainer = styled.div`
  height: 656px;
  display: flex;
  flex-direction: column;
`;

const ContactCardMargin = styled.div`
  margin-top: 12px;
`;

const ContactCardContent = styled.div<{ height: string }>`
  height: ${props => props.height};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px 28px 24px;
`;

const BasicCardContainer = styled.div<{ borderColor: string; width: string }>`
  margin-top: 8px;
  width: ${({ width }) => width};
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05),
    inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
  border: solid 1px ${({ borderColor }) => borderColor};
  background-color: ${colors.WHITE};
`;

const DescriptionHeader = styled.div`
  padding: 0px 22px;
`;

const ListHeader = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;
`;

const FillingFormDescription = styled.div`
  height: 160px;
  font-size: 14px;
  color: ${colors.PRIMARY_TEXT};
  text-align: center;
  padding: 44px 28px;
`;

const FormFilledDescription = styled.div`
  min-height: 100px;
  font-size: 14px;
  color: ${colors.PRIMARY_TEXT};
  text-align: center;
  padding: 16px 28px;
`;

const ProbabilityText = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: bold;
`;

const SuggestionText = styled.div`
  font-size: 14px;
  line-height: 1.21;
  color: ${colors.PRIMARY_TEXT};
  position: relative;
  left: 20px;
  text-align: left;
`;

const CustomList = styled.ul`
  padding-left: 12px;
`;

const ValidationDescriptionText = styled.div`
  height: 36px;
  font-size: 14px;
  color: ${colors.PRIMARY_TEXT};
  text-align: center;
  padding: 0px 8px;
`;

const DisclaimerText = styled.div`
  padding: 2px 0px;
  font-size: 10px;
  color: ${colors.SECONDARY_TEXT};
  font-weight: 300;
  letter-spacing: 0.14px;
  a {
    color: ${colors.HYPERLINK};
  }
`;

const ContactFormContainer = styled.div`
  padding-top: 12px;
`;

const ContactFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubmitButtonContainer = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
`;

const suggestionTextMap: Record<Suggestions, string> = {
  CHANGE_VEHICLE: 'Cambiar el producto por uno de menor valor.',
  INCREASE_DOWNPAYMENT: 'Subir el monto del enganche.',
  INCREASE_NUMBER_OF_PAYMENTS: 'Aumentar el plazo.',
  USE_COSIGNER: 'Estimar que se puede solicitar un coacreditado.',
};

const probabilityColorMap: Record<ProfilingStatus, string> = {
  FILLING: colors.AQUA,
  HIGH_PROBABILITY: colors.GREEN,
  MEDIUM_PROBABILITY: colors.ORANGE,
  LOW_PROBABILITY: colors.RED,
};

const probabilityTextMap: Record<ProfilingStatus, string> = {
  FILLING: '',
  HIGH_PROBABILITY: 'alta probabilidad',
  MEDIUM_PROBABILITY: 'probabilidad',
  LOW_PROBABILITY: 'baja probabilidad',
};

interface IProfilingSuggestionCards {
  profilingResult: IProfilingSuggestionResult;
  submitProfilingForm: (
    existentValues: IContactForm | IExistentAccountForm
  ) => void;
  existentAccount: boolean;
}

interface IBasicCard {
  children: React.ReactNode;
  color: string;
  deviceData: IDeviceData;
}

interface IContactCard {
  existentAccount: boolean;
  color: string;
  existentProfilingData: any;
  submitProfilingForm: (
    existentValues: IContactForm | IExistentAccountForm
  ) => void;
}

interface IContactFormComponent {
  existentAccount: boolean;
  formikProps: FormikProps<IContactForm>;
}

interface IExistentUserFormComponent {
  formikProps: FormikProps<IExistentAccountForm>;
}

const ConnectedBasicCard: FunctionComponent<IBasicCard> = ({
  color,
  children,
  deviceData,
}) => {
  return (
    <BasicCardContainer
      width={deviceData.isMobile ? '100%' : '270px'}
      borderColor={color}
    >
      {children}
    </BasicCardContainer>
  );
};

const mapStateToProps = (state: any): { deviceData: IDeviceData } => ({
  deviceData: state.deviceData,
});

const BasicCard = connect(mapStateToProps)(ConnectedBasicCard);

const ContactFormComponent: FunctionComponent<IContactFormComponent> = ({
  existentAccount,
  formikProps,
}) => {
  return (
    <ContactFieldsContainer>
      <FormikText
        label="CORREO DEL SOLICITANTE"
        name="email"
        inputMode="email"
      />
      {existentAccount ? null : (
        <FormikPhone
          label="TELÉFONO DEL SOLICITANTE"
          name="phoneNumber"
          inputMode="numeric"
        />
      )}
      <DisclaimerText>
        Te enviaremos un SMS con la liga donde podrás ingresar tus datos. Tus
        datos estarán seguros y protegidos.
      </DisclaimerText>
      <SubmitButtonContainer>
        <Button
          variant={formikProps.isValid ? 'primary' : 'inactive'}
          onClick={() => formikProps.submitForm()}
        >
          Validar
        </Button>
      </SubmitButtonContainer>
    </ContactFieldsContainer>
  );
};

const ConnectedContactCard: FunctionComponent<IContactCard> = ({
  existentAccount,
  color,
  submitProfilingForm,
  existentProfilingData,
}) => {
  const initialValues = (() => {
    if (existentAccount) {
      return isEmpty(existentProfilingData)
        ? { email: '' }
        : { email: existentProfilingData.contact.email };
    } else {
      return isEmpty(existentProfilingData)
        ? { email: '', phoneNumber: '' }
        : existentProfilingData.contact;
    }
  })();
  const validationSchema = existentAccount
    ? Yup.object().shape({
        email: Yup.string()
          .email(errors.email.email)
          .required(errors.email.required),
      })
    : Yup.object().shape({
        email: Yup.string()
          .email(errors.email.email)
          .required(errors.email.required),
        phoneNumber: Yup.string()
          .matches(/^\d{10}$/, errors.phoneNumber.matches)
          .required(errors.phoneNumber.required),
      });

  const formComponent = (
    formikProps: FormikProps<IContactForm>
  ): JSX.Element => (
    <ContactFormComponent
      formikProps={formikProps}
      existentAccount={existentAccount}
    />
  );

  return (
    <ContactCardMargin>
      <BasicCard color={color}>
        <ContactCardContent height={existentAccount ? '416px' : '356px'}>
          <ValidationDescriptionText>
            Ahora validaremos tu identidad para seguir con tu solicitud.
          </ValidationDescriptionText>
          <ContactFormContainer>
            <Formik
              isInitialValid={!isEmpty(existentProfilingData)}
              initialValues={initialValues}
              onSubmit={values => submitProfilingForm(values)}
              validationSchema={validationSchema}
            >
              {formComponent}
            </Formik>
          </ContactFormContainer>
        </ContactCardContent>
      </BasicCard>
    </ContactCardMargin>
  );
};

const mapStateToPropsContactCard = (
  state: any
): { existentProfilingData: any } => ({
  existentProfilingData: state.newApplication.profiling,
});

const ContactCard = connect(mapStateToPropsContactCard)(ConnectedContactCard);

const ProfilingSuggestionCards: FunctionComponent<IProfilingSuggestionCards> = ({
  profilingResult,
  submitProfilingForm,
  existentAccount,
}) => {
  const isFilling = profilingResult.profilingStatus === ProfilingStatus.FILLING;

  const fillingDescriptionCard = (
    <FillingFormDescription>
      Al terminar de llenar los datos, te informaremos cuánta probabilidad
      tienes de adquirir un crédito para el producto que estás buscando.
    </FillingFormDescription>
  );

  const resultDescriptionCard = (
    <FormFilledDescription>
      <DescriptionHeader>
        ¡Creemos que cuentas con
        <ProbabilityText
          color={probabilityColorMap[profilingResult.profilingStatus]}
        >
          {probabilityTextMap[profilingResult.profilingStatus]}
        </ProbabilityText>
        de adquirir un crédito para el producto que buscas!
      </DescriptionHeader>
      {!isEmpty(profilingResult.suggestions) ? (
        <Fragment>
          <ListHeader>
            Para aumentar tus probabilidades, te recomendamos:
          </ListHeader>
          <CustomList>
            {values(profilingResult.suggestions)
              .reduce((acc, current) => acc.concat(current), [])
              .map((suggestion, i) => (
                <li key={i}>
                  <SuggestionText>
                    {suggestionTextMap[suggestion]}
                  </SuggestionText>
                </li>
              ))}
          </CustomList>
        </Fragment>
      ) : null}
    </FormFilledDescription>
  );

  const descriptionCard = isFilling
    ? fillingDescriptionCard
    : resultDescriptionCard;

  return (
    <CardsContainer>
      <BasicCard color={probabilityColorMap[profilingResult.profilingStatus]}>
        {descriptionCard}
      </BasicCard>
      {isFilling ? null : (
        <ContactCard
          existentAccount={existentAccount}
          submitProfilingForm={submitProfilingForm}
          color={
            profilingResult.profilingStatus === ProfilingStatus.HIGH_PROBABILITY
              ? colors.GREEN
              : colors.BORDER
          }
        />
      )}
    </CardsContainer>
  );
};

export default ProfilingSuggestionCards;
