import React from 'react';
import styled from 'styled-components';

const { REACT_APP_API_HOST: API_HOST } = process.env;

interface IPhoto {
  id: number;
}

interface IBiometricVerificationPhotos {
  photos: Array<IPhoto>;
}

const Image = styled.img`
  padding: 8px 0;
  padding-left: 24px;
  width: 100%;
`;

const ImagesContainer = styled.div`
  margin-top: 16px;
`;

const BiometricVerificationPhotos: React.FunctionComponent<IBiometricVerificationPhotos> = ({
  photos,
}) => {
  return (
    <ImagesContainer>
      {photos.map(photo => (
        <Image
          key={photo.id}
          src={`${API_HOST}/api/biometric-verification/photos/${photo.id}`}
        />
      ))}
    </ImagesContainer>
  );
};

export default BiometricVerificationPhotos;
