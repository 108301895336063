import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 16 16';

const UserIcon = ({ fill }: { fill: string }) => (
  <path
    d="M20 18.586L18.586 20l-5.657-5.657 1.414-1.414L20 18.586zM7 12c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5zM7 0a7 7 0 1 0 0 14A7 7 0 0 0 7 0z"
    fill={fill}
  />
);

export default BaseIcon(UserIcon, viewBox);
