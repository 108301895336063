import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const CLIENT_CREDIT_HISTORY_FETCH = createAsyncFlowTypes(
  'CLIENT_CREDIT_HISTORY_FETCH'
);
const FETCH_CLIENT_APPLICATIONS = createAsyncFlowTypes(
  'FETCH_CLIENT_APPLICATIONS'
);
const CLIENT_ACTIVATE_OFFER = createAsyncFlowTypes('CLIENT_ACTIVATE_OFFER');
const FETCH_CLIENT_OFFER_DOCUMENTS = createAsyncFlowTypes(
  'FETCH_CLIENT_OFFER_DOCUMENTS'
);

const fetchClientCreditHistory = createAsyncFlowCreators(
  CLIENT_CREDIT_HISTORY_FETCH
);
const fetchClientApplications = createAsyncFlowCreators(
  FETCH_CLIENT_APPLICATIONS
);
const clientActivateOffer = createAsyncFlowCreators(CLIENT_ACTIVATE_OFFER);
const fetchClientOfferDocuments = createAsyncFlowCreators(
  FETCH_CLIENT_OFFER_DOCUMENTS
);

export default {
  types: {
    CLIENT_CREDIT_HISTORY_FETCH,
    FETCH_CLIENT_APPLICATIONS,
    CLIENT_ACTIVATE_OFFER,
    FETCH_CLIENT_OFFER_DOCUMENTS,
  },
  creators: {
    fetchClientCreditHistory,
    fetchClientApplications,
    clientActivateOffer,
    fetchClientOfferDocuments,
  },
};
