import * as d3 from 'd3';
import { isNil } from 'ramda';
import identity from 'ramda/src/identity';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';

const DualScaleBarChartHTMLParent = styled.div`
  height: 100%;
  width: 100%;
`;

const LegendsGroup = styled.g`
  & text {
    fill: ${colors.PRIMARY_TEXT} !important;
  }
`;

const StyledSVG = styled.svg`
  & text {
    font-family: 'Lato';
    fill: ${colors.BLUE_GRAY};
  }
`;

interface IDualScaleBarChart {
  data: Array<[string, number, number]>;
  format?: 'none' | 'currency';
  legends: [string, string];
}

const getYDomain = (data: IDualScaleBarChart['data']): number => {
  const min = 6;

  const maxValueFromData = d3.max(data, d => Math.max(d[1], d[2]));

  if (isNil(maxValueFromData) || maxValueFromData < min) {
    return min;
  } else {
    return Math.ceil(maxValueFromData * 1.2);
  }
};

const DualScaleBarChart: React.FunctionComponent<IDualScaleBarChart> = ({
  data,
  format = 'none',
  legends,
}) => {
  const chartMargins = {
    top: 92,
    right: 32,
    left: 60,
    bottom: 68,
  };

  const legendsMargins = {
    top: 24,
    left: 32,
  };

  const [width, setWidth] = useState(600);
  const [height, setHeight] = useState(300);

  const parentContainer = useRef<HTMLDivElement | null>(null);

  const parentContainerWidth = parentContainer.current?.clientWidth;
  const parentContainerHeight = parentContainer.current?.clientHeight;

  useEffect(() => {
    if (parentContainerWidth && parentContainerHeight) {
      setWidth(parentContainerWidth);
      setHeight(parentContainerHeight);
    }
  }, [parentContainerWidth, parentContainerHeight]);

  const xAxisRef = useRef<SVGSVGElement | null>(null);
  const yAxisRef = useRef<SVGSVGElement | null>(null);

  const effectiveHeight = height - chartMargins.top - chartMargins.bottom;
  const effectiveWidth = width - chartMargins.left - chartMargins.right;

  const x = d3
    .scaleBand()
    .range([0, effectiveWidth])
    .padding(0.5);

  const y = d3.scaleLinear().range([effectiveHeight, 0]);

  x.domain(data.map(d => `${d[0]}`));
  y.domain([0, getYDomain(data)]);

  const formatFunction = format === 'currency' ? d3.format('.2s') : identity;

  useEffect(() => {
    if (xAxisRef.current !== null) {
      d3.select(xAxisRef.current)
        .call(
          d3
            .axisBottom(x)
            .tickSize(0)
            .tickPadding(20)
        )
        .call(g => g.select('.domain').attr('stroke', colors.ALASKA_GRAY));
    }

    if (yAxisRef.current !== null) {
      d3.select(yAxisRef.current)
        .call(
          d3
            .axisLeft(y)
            .tickSize(-effectiveWidth)
            .ticks(5)
            .tickFormat(formatFunction as any)
        )
        .call(g => g.select('.domain').remove())
        .call(g =>
          g.selectAll('.tick line').attr('stroke', colors.ALASKA_GRAY)
        );
    }
  }, [data, effectiveWidth, formatFunction, width, height, x, y]);

  const getRectangleDimensions = (datum: [string, number]) => {
    const minHeight = 0.1;

    return {
      x: x(`${datum[0]}`) || 0,
      y: datum[1] > 0 ? y(datum[1]) : y(minHeight),
      width: x.bandwidth(),
      height: effectiveHeight - (datum[1] > 0 ? y(datum[1]) : y(minHeight)),
    };
  };

  return (
    <DualScaleBarChartHTMLParent ref={parentContainer}>
      <StyledSVG width={`${width}px`} height={`${height}px`}>
        <LegendsGroup
          transform={`translate(${legendsMargins.left}, ${legendsMargins.top})`}
        >
          {legends.map((legend, i) => (
            <Fragment key={`${i}`}>
              <rect
                x={`${i * 150}`}
                y="8px"
                width="10px"
                height="10px"
                rx="2"
                ry="2"
                fill={i === 0 ? colors.WATER_BLUE : colors.BLUE_GRAY}
              />
              <text fill={colors.PRIMARY_TEXT} x={`${i * 150 + 20}`} y="18px">
                {legend}
              </text>
            </Fragment>
          ))}
        </LegendsGroup>
        <g transform={`translate(${chartMargins.left}, ${chartMargins.top})`}>
          <g>
            <g ref={xAxisRef} transform={`translate(0, ${effectiveHeight})`} />
            <g ref={yAxisRef} />
          </g>
          <g>
            {data.map(d => {
              const grayRect = getRectangleDimensions([d[0], d[2]]);
              const blueRect = getRectangleDimensions([d[0], d[1]]);

              return (
                <Fragment key={`${d[0]}`}>
                  <rect
                    fill={colors.ALASKA_GRAY}
                    x={grayRect.x}
                    width={grayRect.width * 0.45}
                    y={grayRect.y}
                    height={grayRect.height}
                    rx={2}
                    ry={2}
                  />
                  <rect
                    fill={colors.WATER_BLUE}
                    x={blueRect.x + x.bandwidth() * 0.55}
                    width={blueRect.width * 0.45}
                    y={blueRect.y}
                    height={blueRect.height}
                    rx={2}
                    ry={2}
                  />
                </Fragment>
              );
            })}
          </g>
        </g>
      </StyledSVG>
    </DualScaleBarChartHTMLParent>
  );
};

export default DualScaleBarChart;
