import isNil from 'ramda/src/isNil';
import mean from 'ramda/src/mean';

import { TERM_OPTIONS } from '../constants/applicationOptions';

const MAX_FEE = 0.04;
const MIN_FEE = 0.02;

const MAX_INTEREST_RATE_CAR_FINANCE = 0.2;
const MIN_INTEREST_RATE_CAR_FINANCE = 0.17;

const MAX_INTEREST_RATE_MOTORCYCLE_FINANCE = 0.44;
const MIN_INTEREST_RATE_MOTORCYCLE_FINANCE = 0.4;

const TAX_RATE = 0.16;

const CAR_INSURANCE_AVG_RATE = 0.07;
const MOTORCYCLE_INSURANCE_AVG_RATE = 0.2;

const getInterestRateRange = (productType: string) => {
  switch (productType) {
    case 'motorcycle_finance':
      return {
        max: MAX_INTEREST_RATE_MOTORCYCLE_FINANCE,
        min: MIN_INTEREST_RATE_MOTORCYCLE_FINANCE,
        avg: mean([
          MAX_INTEREST_RATE_MOTORCYCLE_FINANCE,
          MIN_INTEREST_RATE_MOTORCYCLE_FINANCE,
        ]),
      };
    case 'car_finance':
    default:
      return {
        max: MAX_INTEREST_RATE_CAR_FINANCE,
        min: MIN_INTEREST_RATE_CAR_FINANCE,
        avg: mean([
          MAX_INTEREST_RATE_CAR_FINANCE,
          MIN_INTEREST_RATE_CAR_FINANCE,
        ]),
      };
  }
};

const getInsuranceRate = (productType: string, insuranceType: string) => {
  if (insuranceType === 'credit') {
    return productType === 'motorcycle_finance'
      ? MOTORCYCLE_INSURANCE_AVG_RATE
      : CAR_INSURANCE_AVG_RATE;
  } else {
    return 0;
  }
};

const calculatePayment = (
  numberOfPayments: number,
  vehicleValue: number,
  downpayment: number,
  interestRate: number,
  fee: number,
  insuranceRate: number
): number => {
  const creditAmount = vehicleValue - downpayment;
  const finalCreditAmount =
    creditAmount +
    fee * creditAmount * (1 + TAX_RATE) +
    insuranceRate * vehicleValue;

  const term1 = Math.pow(
    1 + (interestRate * (1 + TAX_RATE)) / 12,
    numberOfPayments
  );
  const term2 = (interestRate * (1 + TAX_RATE)) / 12;
  const term3 = term1 - 1;

  return (finalCreditAmount * term1 * term2) / term3;
};

const averageMonthlyPayment = (
  productType: string,
  numberOfPayments: number,
  vehicleValue: number,
  downpayment: number,
  insuranceRate: number
): number =>
  calculatePayment(
    numberOfPayments,
    vehicleValue,
    downpayment,
    getInterestRateRange(productType).avg,
    mean([MAX_FEE, MIN_FEE]),
    insuranceRate
  );

export const getMonthlyPayments = (
  promoterProducts: {
    carFinance?: number[];
    motorcycleFinance?: number[];
    [key: string]: number[] | undefined;
  },
  productType: string,
  vehicleValue: number,
  downpayment: number,
  insuranceType: string,
  sectionCompleted: boolean
) => {
  const termOptions =
    promoterProducts[
      productType === 'car_finance' ? 'carFinance' : 'motorcycleFinance'
    ];
  if (
    !isNil(termOptions) &&
    sectionCompleted &&
    vehicleValue > 0 &&
    downpayment > 0 &&
    downpayment < vehicleValue
  ) {
    return termOptions.map(term => ({
      quantity: averageMonthlyPayment(
        productType,
        term,
        vehicleValue,
        downpayment,
        getInsuranceRate(productType, insuranceType)
      ),
      value: term,
    }));
  } else {
    return TERM_OPTIONS.map(term => ({ quantity: 0, value: term }));
  }
};
