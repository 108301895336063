import isNil from 'ramda/src/isNil';
import React from 'react';
import { Link, Route } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../constants/colors';
import ExternalLink from '../ExternalLink';

const ActiveIndicator = styled.div`
  background-color: ${({ active }) => (active ? colors.AQUA : colors.WHITE)};
  height: 100%;
  width: 4px;
`;

const Container = styled.div`
  align-items: center;
  background-color: ${({ active }) =>
    active ? colors.BACKGROUND : colors.WHITE};
  box-sizing: border-box;
  display: flex;
  height: 60px;
`;

const IconContainer = styled.div`
  box-sizing: border-box;
  padding-left: 28px;
`;

const TextContainer = styled.div`
  box-sizing: border-box;
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  padding-left: 12px;
  padding-right: 24px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const NavOption = ({ config }) => {
  const { external, icon: Icon, path, text } = config;

  const LinkComponent = external ? ExternalLink : StyledLink;
  const children = ({ match, location }) => {
    const isInApplicationProcess =
      path === '/application/continue' &&
      [
        '/application/continue',
        '/application/profiling',
        '/application/account',
        '/application/new',
      ].includes(location.pathname);
    const isActive = !isNil(match) || isInApplicationProcess;
    return (
      <LinkComponent to={path}>
        <Container active={isActive}>
          <ActiveIndicator active={isActive} />
          <IconContainer>
            <Icon size={18} />
          </IconContainer>
          <TextContainer>{text}</TextContainer>
        </Container>
      </LinkComponent>
    );
  };

  return <Route children={children} path={path} />;
};

export default NavOption;
