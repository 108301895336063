import { Formik } from 'formik';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';
import * as Yup from 'yup';

import userManagementActions from '../../actions/userManagement';
import { getAvailableUsersToCreate } from '../../utils/userCreation';
import Button from '../Button';
import { FormikDropdown, FormikPhone, FormikText } from '../FormikInputs';
import SimpleCard from '../SimpleCard';
import withUserData from '../UserDataHOC';
import errors from './errorMessages';

const Grid = styled.div`
  padding: 40px;
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  width: 100%;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  text-align: right;
  padding-right: 40px;
  padding-bottom: 40px;
`;

interface ICreateUserFormReduxProps {
  userData: IUserData;
  createUser: Function;
}

type ICreateUserFormProps = ICreateUserFormReduxProps & RouteComponentProps;

interface IUserCreation {
  name: string;
  firstSurname: string;
  secondSurname: string;
  email: string;
  phoneNumber: string;
  userType: string;
  password: string;
  passwordConfirmation: string;
}

const initialUserCreationValues: IUserCreation = {
  name: '',
  firstSurname: '',
  secondSurname: '',
  email: '',
  phoneNumber: '',
  userType: '',
  password: '',
  passwordConfirmation: '',
};

const createUserValidationSchema: Yup.ObjectSchema<Yup.Shape<
  {},
  IUserCreation
>> = Yup.object().shape({
  name: Yup.string().required(errors.name.required),
  firstSurname: Yup.string().required(errors.firstSurname.required),
  secondSurname: Yup.string().required(errors.secondSurname.required),
  userType: Yup.string().required(errors.userType.required),
  email: Yup.string()
    .email(errors.email.email)
    .required(errors.email.required),
  phoneNumber: Yup.string()
    .matches(/^\d{10}$/, errors.phoneNumber.matches)
    .required(errors.phoneNumber.required),
  password: Yup.string()
    .min(8, errors.password.min)
    .required(errors.password.required),
  passwordConfirmation: Yup.string()
    .min(8, errors.passwordConfirmation.min)
    .oneOf([Yup.ref('password'), null], errors.passwordConfirmation.oneOf)
    .required(errors.passwordConfirmation.required),
});

const CreateUserForm: React.FunctionComponent<ICreateUserFormProps> = ({
  userData,
  history,
  createUser,
}) => {
  const userOptions = getAvailableUsersToCreate(userData);

  const handleSubmitButtonClicked = (values: IUserCreation): void => {
    createUser({ values });
  };

  return (
    <Fragment>
      <ControlsContainer>
        <Button onClick={() => history.goBack()} variant="secondary">
          Regresar
        </Button>
      </ControlsContainer>
      <SimpleCard
        title={'Crear usuario'}
        subtitle={'Completa la siguiente información para crear un usuario'}
      >
        <Formik
          initialValues={initialUserCreationValues}
          render={formikProps => (
            <Fragment>
              <Grid>
                <FormikText label="NOMBRE" name="name" />
                <FormikText label="PRIMER APELLIDO" name="firstSurname" />
                <FormikText label="SEGUNDO APELLIDO" name="secondSurname" />
                <FormikText label="CORREO ELECTRÓNICO" name="email" />
                <FormikPhone label="TELÉFONO CELULAR" name="phoneNumber" />
                <FormikDropdown
                  label="TIPO DE USUARIO"
                  name="userType"
                  options={userOptions}
                  placeholder={'Selecciona'}
                />
                <FormikText
                  label="CONTRASEÑA"
                  name="password"
                  type="password"
                />
                <FormikText
                  label="CONFIRMAR CONTRASEÑA"
                  name="passwordConfirmation"
                  type="password"
                />
              </Grid>
              <ButtonContainer>
                <Button
                  type="submit"
                  variant={formikProps.isValid ? 'primary' : 'inactive'}
                  onClick={() => formikProps.submitForm()}
                >
                  Crear usuario
                </Button>
              </ButtonContainer>
            </Fragment>
          )}
          onSubmit={handleSubmitButtonClicked}
          validationSchema={createUserValidationSchema}
        />
      </SimpleCard>
    </Fragment>
  );
};

const creators = {
  createUser: userManagementActions.creators.createUser.request,
};

export default withRouter(
  withUserData(connect(null, creators)(CreateUserForm))
);
