import React from 'react';
import styled from 'styled-components';

import Card from '../Card';
import Table from '../SmartTable';

const RequirementName = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  min-height: 60px;
  padding: 12px 0;
  padding-right: 8px;
  word-break: break-word;
`;

const OfferRequirementsContainer = styled.div`
  margin-bottom: 20px;
`;

const RequirementDescription = styled(RequirementName)`
  font-weight: normal;
`;

interface OfferRequirement {
  id: number;
  offerId: number;
  name: string;
  description: string;
}

interface Props {
  requirements: Array<OfferRequirement>;
}

const OfferRequirements: React.FunctionComponent<Props> = ({
  requirements,
}) => {
  const table = (
    <Table
      data={requirements}
      gridColumns={`40% 60%`}
      headers={['Nombre', 'Descripción']}
      renderRow={(requirement: OfferRequirement) => {
        return [
          <RequirementName key={0}>{requirement.name}</RequirementName>,
          <RequirementDescription key={1}>
            {requirement.description}
          </RequirementDescription>,
        ];
      }}
    />
  );

  return (
    <OfferRequirementsContainer>
      <Card header={'Requisitos'}>{table}</Card>
    </OfferRequirementsContainer>
  );
};

export default OfferRequirements;
