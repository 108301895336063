import format from 'date-fns/format';
import values from 'ramda/src/values';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import applicationActions from '../../actions/requests';
import colors from '../../constants/colors';
import f from '../../constants/formatters';
import grammar from '../../constants/grammar';
import useUserData from '../../hooks/useUserData';
import filterRelevantOffers from '../../utils/filterRelevantOffers';
import { isPromoterSalesperson } from '../../utils/permissions';
import Button from '../Button';
import Card from '../Card';
import ExpandableTable from '../ExpandableTable';
import LoadingIndicator from '../LoadingIndicator';
import { TableCell } from '../SmartTable';

const { REACT_APP_PUBLIC_ASSETS_URL } = process.env;

const Background = styled.div`
  background-color: ${colors.BACKGROUND};
  width: 100%;
`;

const BottomMargin = styled.div`
  margin-bottom: 20px;
`;

const LoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 50vh;
  justify-content: center;
  width: 100%;
`;

const ExpandedSectionContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100px;
`;

const LabelAndText: React.FunctionComponent<{
  label: string;
  text: string;
  width: string;
}> = ({ label, text, width }) => (
  <SizebleDiv width={width}>
    <Label>{label}</Label>
    <Text>{text}</Text>
  </SizebleDiv>
);

const Label = styled.div`
  color: ${colors.DUSK50};
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 12px;
  text-transform: uppercase;
`;

const Text = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 12px;
`;

const ButtonMargin = styled.div`
  margin-bottom: 12px;
`;
const SizebleDiv = styled.div<{ width: string }>`
  width: ${({ width }) => width};
`;

const BankLogo = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

const BankLogoContainer = styled.div`
  height: 100%;
  padding: 16px 24px;
  width: 17.5%;
`;

interface IOfferedApplication {
  id: number;
  offers: IOffer[];
  type: string;
  carFinanceApplication: {
    creditAmount: number;
    vehicle: {
      model: string;
    };
    vehicleManufacturer: {
      name: string;
    };
  };
  motorcycleFinanceApplication: {
    creditAmount: number;
    vehicle: {
      model: string;
    };
    vehicleManufacturer: {
      name: string;
    };
  };
  compliance: {
    clientId: number;
    userId: number;
    client: IUser;
    promoter: IUser;
  };
  createdAt: string;
  updatedAt: string | null;
}

const getFullName = ({
  name,
  lastName,
  firstSurname,
  secondSurname,
}: {
  name: string;
  lastName?: string;
  firstSurname: string;
  secondSurname: string;
}) =>
  lastName
    ? `${name} ${lastName} ${secondSurname}`
    : `${name} ${firstSurname} ${secondSurname}`;

const getDataDependingPermission = (
  isSales: boolean,
  application: IOfferedApplication
) => {
  const {
    carFinanceApplication,
    motorcycleFinanceApplication,
    type,
    compliance,
  } = application;
  if (isSales) {
    return type === 'automotriz'
      ? `${carFinanceApplication.vehicleManufacturer.name} ${carFinanceApplication.vehicle.model}`
      : `${motorcycleFinanceApplication.vehicleManufacturer.name} ${motorcycleFinanceApplication.vehicle.model}`;
  } else {
    return compliance.userId === compliance.clientId
      ? '-'
      : getFullName(compliance.promoter);
  }
};

const TABLE_HEADERS_SALES = [
  'Id de solicitud',
  'Tipo de producto',
  'Monto solicitado',
  'Modelo',
  'Titular',
  'Fecha',
  'Ofertas',
];

const TABLE_HEADERS_ADMIN = [
  'Id de solicitud',
  'Tipo de producto',
  'Monto solicitado',
  'Asesor',
  'Titular',
  'Fecha',
  'Ofertas',
];

interface IOfferedApplicationsStateProps {
  fetchingOfferedApplications: boolean;
  offeredApplications: IOfferedApplication[];
}

interface IOfferedApplicationsDispatchProps {
  fetchOfferedApplications: Function;
}

type IOfferedApplicationProps = IOfferedApplicationsStateProps &
  IOfferedApplicationsDispatchProps;

const OfferedApplicationsView: React.FunctionComponent<IOfferedApplicationProps> = ({
  fetchingOfferedApplications,
  fetchOfferedApplications,
  offeredApplications,
}) => {
  useEffect(() => {
    fetchOfferedApplications();
  }, [fetchOfferedApplications]);

  const userData = useUserData();

  const content =
    fetchingOfferedApplications || !userData ? (
      <LoadingState>
        <LoadingIndicator />
      </LoadingState>
    ) : offeredApplications.length > 0 ? (
      <Background>
        <ExpandableTable
          rowHeights={offeredApplications.map(
            application =>
              filterRelevantOffers(application.offers).length * 100 + 60
          )}
          data={offeredApplications}
          gridColumns={`repeat(7, 1fr)`}
          headers={
            isPromoterSalesperson(userData as IPromoterUserData)
              ? TABLE_HEADERS_SALES
              : TABLE_HEADERS_ADMIN
          }
          renderVisibleRow={application => {
            return (
              <Fragment>
                <TableCell key={0}>{application.id}</TableCell>
                <TableCell key={1}>{grammar[application.type]}</TableCell>
                <TableCell key={2}>
                  {f.currency(
                    application.type === 'automotriz'
                      ? application.carFinanceApplication.creditAmount
                      : application.motorcycleFinanceApplication.creditAmount
                  )}
                </TableCell>
                <TableCell key={3}>
                  {getDataDependingPermission(
                    isPromoterSalesperson(userData as IPromoterUserData),
                    application
                  )}
                </TableCell>
                <TableCell key={4}>
                  {getFullName(application.compliance.client)}
                </TableCell>
                <TableCell key={5}>
                  {format(application.createdAt, 'yyyy/LL/dd')}
                </TableCell>
                <TableCell key={6}>
                  {filterRelevantOffers(application.offers).length}
                </TableCell>
              </Fragment>
            );
          }}
          renderHiddenRow={application => {
            const filteredOffers = filterRelevantOffers(application.offers);

            return (
              <Fragment>
                {filteredOffers.map(offer => (
                  <ExpandedSectionContainer key={offer.id}>
                    <BankLogoContainer>
                      <BankLogo
                        src={`${REACT_APP_PUBLIC_ASSETS_URL}${
                          offer.bank ? offer.bank.logo : ''
                        }`}
                      />
                    </BankLogoContainer>
                    <LabelAndText
                      label={'Nombre'}
                      text={offer.productName}
                      width={'10%'}
                    />
                    <LabelAndText
                      label={'Monto ofertado'}
                      text={f.currency(offer.amount)}
                      width={'10%'}
                    />
                    <LabelAndText
                      label={'Plazo'}
                      text={offer.requestPeriod}
                      width={'10%'}
                    />
                    <LabelAndText
                      label={'Enganche'}
                      text={f.currency(offer.downpayment)}
                      width={'10%'}
                    />
                    <LabelAndText
                      label={'Tasa / CAT'}
                      text={`${f.percentage(
                        Number.parseFloat(offer.interestRate) * 100
                      )} / ${f.percentage(Number.parseFloat(offer.cat) * 100)}`}
                      width={'15%'}
                    />
                    <LabelAndText
                      label={'Pago periódico'}
                      text={f.currency(offer.fullPayment)}
                      width={'15%'}
                    />
                    <SizebleDiv width={'12.5%'}>
                      <ButtonMargin>
                        <Link to={`/offer/${offer.id}`}>
                          <Button width={'140px'}>Detalles</Button>
                        </Link>
                      </ButtonMargin>
                    </SizebleDiv>
                  </ExpandedSectionContainer>
                ))}
              </Fragment>
            );
          }}
        />
      </Background>
    ) : (
      <LoadingState>Sin Resultados</LoadingState>
    );
  return (
    <BottomMargin>
      <Card header={'Solicitudes'}>{content}</Card>
    </BottomMargin>
  );
};

const mapStateToProps = (state: any) => ({
  fetchingOfferedApplications: state.loaders.fetchingRequestsOffered,
  offeredApplications: values(state.entities.requestsOffered),
  userData: state.auth.userData,
});

const creators = {
  fetchOfferedApplications:
    applicationActions.creators.fetchRequestsOffered.request,
};

export default connect(mapStateToProps, creators)(OfferedApplicationsView);
