import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import accountCreationActions from '../../actions/accountCreation';
import colors from '../../constants/colors';
import { noop } from '../../utils/misc';
import Button from '../Button';
import Input from '../Input';
import LoadingIndicator from '../LoadingIndicator';
import Modal from '../Modal';
import SendViaWhatsApp from '../SendViaWhatsApp';

const ControlContainer = styled.div`
  display: flex;
  height: 38px;
  justify-content: flex-end;
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 80px;
`;

const ModalHeader = styled.div`
  align-items: center;
  color: ${colors.PRIMARY_TEXT};
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 48px;
`;

interface NIPVerification {
  account: {
    clientId: string;
    verificationId: string;
  };
  clientPhoneNumber: string;
  sendClientAccountNipViaWhatsapp: Function;
  verifyClientAccount: Function;
  verificationSucceded: boolean | null;
  verifyingAccount: boolean;
}

const NIPVerificationModal: React.FunctionComponent<NIPVerification> = ({
  account,
  clientPhoneNumber,
  sendClientAccountNipViaWhatsapp,
  verifyClientAccount,
  verificationSucceded,
  verifyingAccount,
}) => {
  const [nip, setNip] = useState<string>('');

  const handleClickOnVerificationButton = () => {
    const { verificationId, clientId } = account;
    verifyClientAccount({ verificationId, clientId, nip });
  };

  return (
    <Modal
      content={() => (
        <Fragment>
          <Input
            label="Ingresa el NIP que enviamos a tu celular."
            onChange={e => setNip(e.target.value)}
            value={nip}
            error={
              verificationSucceded === false
                ? 'El NIP ingresado es incorrecto.'
                : ''
            }
            withErrorMessage
            inputMode="numeric"
          />
          <SendViaWhatsApp
            drawerText="¿Aún no recibes el NIP? Da click aquí"
            formText="Recibe tu NIP por"
            initialPhoneValue={clientPhoneNumber}
            inputDisabled={true}
            onSubmit={phoneNumber =>
              sendClientAccountNipViaWhatsapp({ phoneNumber })
            }
          />
        </Fragment>
      )}
      controls={() => (
        <ControlContainer>
          {verifyingAccount ? (
            <LoadingIndicatorContainer>
              <LoadingIndicator />
            </LoadingIndicatorContainer>
          ) : (
            <Button onClick={handleClickOnVerificationButton}>Verificar</Button>
          )}
        </ControlContainer>
      )}
      header={() => <ModalHeader>Verificación</ModalHeader>}
      isOpen={Boolean(account)}
      onRequestClose={noop}
      shouldCloseOnOverlayClick={false}
      showCloseIcon={false}
      width={'400px'}
    />
  );
};

const mapStateToProps = (state: any) => ({
  account: state.newApplication.accountCreation.account,
  clientPhoneNumber: state.newApplication.profiling.contact.phoneNumber,
  verificationSucceded: state.newApplication.accountVerificationSucceded,
  verifyingAccount: state.loaders.verifyingClientAccount,
});

const creators = {
  sendClientAccountNipViaWhatsapp:
    accountCreationActions.creators.sendClientAccountNipViaWhatsapp.request,
  verifyClientAccount:
    accountCreationActions.creators.verifyClientAccount.request,
};

export default connect(mapStateToProps, creators)(NIPVerificationModal);
