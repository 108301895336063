import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import getMenuConfig from '../../utils/navMenuConfiguration';
import NavOptionMobile from '../NavOptionMobile';

const NavOptionsMobileContainer = styled.div`
  display: flex;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 8px;
`;

interface INavMenuMobileProps {
  userData: IUserData;
}

const NavMenuMobile: React.FunctionComponent<INavMenuMobileProps> = ({
  userData,
}) => {
  const menuConfig = getMenuConfig(userData);

  const options = menuConfig.map((option, i) => (
    <Fragment key={i}>
      <NavOptionMobile config={option} />
    </Fragment>
  ));

  return <NavOptionsMobileContainer>{options}</NavOptionsMobileContainer>;
};

const mapStateToProps = (state: any) => ({
  userData: state.auth.userData,
});

export default withRouter(connect(mapStateToProps)(NavMenuMobile));
