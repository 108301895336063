import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import f from '../../constants/formatters';
import Label from '../Label';

const InvoicesAmountCardContainer = styled.div`
  background-color: ${colors.WHITE};
  border: solid 1px ${colors.BORDER};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  height: 112px;
`;

const InvoicesAmountCardContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 20px 0;
`;

const InvoicesAmountCardAmountText = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  font-size: 26px;
  margin-top: 16px;
`;

const InvoicesAmountCard: React.FunctionComponent<{
  amount: number;
  color: string;
  title: string;
}> = ({ amount, color, title }) => {
  return (
    <InvoicesAmountCardContainer>
      <InvoicesAmountCardContent>
        <Label>{title}</Label>
        <InvoicesAmountCardAmountText color={color}>
          {f.currency(amount)}
        </InvoicesAmountCardAmountText>
      </InvoicesAmountCardContent>
    </InvoicesAmountCardContainer>
  );
};

export default InvoicesAmountCard;
