import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../constants/colors';
import getMenuConfig from '../../utils/navMenuConfiguration';
import MultiNavOption from '../MultiNavOption';
import NavOption from '../NavOption';

const Border = styled.div`
  margin-left: 32px;
  margin-right: 24px;
  border-bottom: 1px solid ${colors.BORDER};
`;

interface INavMenuProps {
  userData: IUserData;
}

const NavMenu: React.FunctionComponent<INavMenuProps> = ({ userData }) => {
  const menuConfig = getMenuConfig(userData);

  const options = menuConfig.map((option, i) => (
    <Fragment key={i}>
      {'options' in option ? (
        <MultiNavOption config={option} />
      ) : (
        <NavOption config={option} />
      )}
      <Border />
    </Fragment>
  ));

  return <Fragment>{options}</Fragment>;
};

const mapStateToProps = (state: any) => ({
  userData: state.auth.userData,
});

export default withRouter(connect(mapStateToProps)(NavMenu));
