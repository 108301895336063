import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const PASSWORD_CHANGE = createAsyncFlowTypes('PASSWORD_CHANGE');

const changePassword = createAsyncFlowCreators(PASSWORD_CHANGE);

export default {
  types: {
    PASSWORD_CHANGE,
  },
  creators: {
    changePassword,
  },
};
