import { handleActions } from 'redux-actions';

const initialState = [];

const removeToast = (state, { payload }) => {
  const { id } = payload;

  const toastToRemoveIndex = state.findIndex(toast => toast.id === id);
  const toastArrayLength = state.length;

  if (toastToRemoveIndex === -1) {
    return state;
  }

  return [
    ...state.slice(0, toastToRemoveIndex),
    ...state.slice(toastToRemoveIndex + 1, toastArrayLength),
  ];
};

const toastNotifactionsReducer = handleActions(
  {
    PUSH_TOAST: (state, { payload }) => {
      const newState = [...state];
      newState.push(payload);

      return newState;
    },
    REMOVE_TOAST: removeToast,
  },
  initialState
);

export default toastNotifactionsReducer;
