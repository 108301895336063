import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const VERIFICATION_START = createAsyncFlowTypes('VERIFICATION_START');
const VERIFICATION_WAIT_COMPLETION = createAsyncFlowTypes(
  'VERIFICATION_WAIT_COMPLETION'
);
const VERIFICATION_GET_CURP = createAsyncFlowTypes('VERIFICATION_GET_CURP');
const VERIFICATION_GET_PREFILLING_DATA = createAsyncFlowTypes(
  'VERIFICATION_GET_PREFILLING_DATA'
);
const VERIFICATION_SEND_VIA_WHATSAPP = createAsyncFlowTypes(
  'VERIFICATION_SEND_VIA_WHATSAPP'
);

const startVerification = createAsyncFlowCreators(VERIFICATION_START);
const waitForVerificationCompletion = createAsyncFlowCreators(
  VERIFICATION_WAIT_COMPLETION
);
const getCurpFromVerification = createAsyncFlowCreators(VERIFICATION_GET_CURP);
const getPrefillingData = createAsyncFlowCreators(
  VERIFICATION_GET_PREFILLING_DATA
);
const sendVerificationViaWhatsapp = createAsyncFlowCreators(
  VERIFICATION_SEND_VIA_WHATSAPP
);

export default {
  types: {
    VERIFICATION_START,
    VERIFICATION_WAIT_COMPLETION,
    VERIFICATION_GET_CURP,
    VERIFICATION_GET_PREFILLING_DATA,
    VERIFICATION_SEND_VIA_WHATSAPP,
  },
  creators: {
    startVerification,
    waitForVerificationCompletion,
    getCurpFromVerification,
    getPrefillingData,
    sendVerificationViaWhatsapp,
  },
};
