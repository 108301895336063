import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const PROMOTERS_CLIENTS_FETCH = createAsyncFlowTypes('PROMOTERS_CLIENTS_FETCH');

const fetchPromotersClients = createAsyncFlowCreators(PROMOTERS_CLIENTS_FETCH);

export default {
  types: {
    PROMOTERS_CLIENTS_FETCH,
  },
  creators: {
    fetchPromotersClients,
  },
};
