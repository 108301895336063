import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const COMMISSIONS_FETCH = createAsyncFlowTypes('COMMISSIONS_FETCH');

const fetchCommissions = createAsyncFlowCreators(COMMISSIONS_FETCH);

export default {
  types: {
    COMMISSIONS_FETCH,
  },
  creators: {
    fetchCommissions,
  },
};
