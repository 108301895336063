import fromPairs from 'ramda/src/fromPairs';
import isNil from 'ramda/src/isNil';
import { createAction } from 'redux-actions';

const ASYNC_ACTION_STEPS = ['REQUESTED', 'STARTED', 'COMPLETED', 'FAILED'];

const createAsyncFlowTypes = baseType =>
  fromPairs(ASYNC_ACTION_STEPS.map(step => [step, `${baseType}_${step}`]));

const payloadCreator = payload => (isNil(payload) ? {} : payload);

const createAsyncFlowCreators = asyncTypes => ({
  request: createAction(asyncTypes.REQUESTED, payloadCreator),
  started: createAction(asyncTypes.STARTED, payloadCreator),
  completed: createAction(asyncTypes.COMPLETED, payloadCreator),
  failed: createAction(asyncTypes.FAILED, payloadCreator),
});

export { createAsyncFlowTypes, createAsyncFlowCreators };
