import './day-picker.css';

import format from 'date-fns/format';
import parse from 'date-fns/parse';
import not from 'ramda/src/not';
import React from 'react';
import ReactDayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';

const FORMAT = 'dd/MM/yyyy';

const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

const WEEKDAYS_LONG = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
];

const WEEKDAYS_SHORT = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];

const parseDate = (value: string, formatString: string) => {
  const parsed = parse(value, formatString, new Date());

  if (ReactDayPicker.DateUtils.isDate(parsed)) {
    return parsed;
  }

  return undefined;
};

const formatDate = (date: Date, formatString: string) =>
  format(date, formatString);

export interface DayPicker {
  inputProps?: React.HTMLProps<HTMLInputElement>;
  onChange(day: Date): void;
  onDayPickerHide?(): void;
  overlayComponent?: React.ComponentType;
  value: string | Date;
}

const DayPicker: React.FunctionComponent<DayPicker> = ({
  inputProps,
  onChange,
  onDayPickerHide,
  overlayComponent,
  value,
}) => (
  <DayPickerInput
    dayPickerProps={{
      locale: 'es',
      months: MONTHS,
      weekdaysLong: WEEKDAYS_LONG,
      weekdaysShort: WEEKDAYS_SHORT,
      firstDayOfWeek: 1,
    }}
    formatDate={formatDate}
    format={FORMAT}
    inputProps={inputProps}
    keepFocus={not(overlayComponent)}
    onDayChange={onChange}
    onDayPickerHide={onDayPickerHide}
    overlayComponent={overlayComponent}
    parseDate={parseDate}
    placeholder={`${format(new Date(), FORMAT)}`}
    value={value}
  />
);

export default DayPicker;
