import { handleActions } from 'redux-actions';

const initialState = {};

const promoterProductsReducer = handleActions(
  {
    PROMOTER_PRODUCTS_FETCH_COMPLETED: (state, action) => action.payload,
  },
  initialState
);

export default promoterProductsReducer;
