import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import continueApplicationActions from '../../actions/continueApplication';
import colors from '../../constants/colors';
import Button from '../Button';
import Modal from '../Modal';
import { IProfilingFormComplete } from '../ProfilingSectionsForms';

const ButtonContainer = styled.div`
  padding: 0px 4px;
`;

const ControlContainer = styled.div`
  display: flex;
  height: 38px;
  justify-content: flex-end;
`;

const DescriptionText = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  text-align: justify;
  padding-bottom: 24px;
`;

const ModalHeader = styled.div`
  align-items: center;
  color: ${colors.PRIMARY_TEXT};
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 48px;
`;

interface IAccountCreation {
  account: null | {
    clientId: number;
    verificationId: number;
  };
  accountFormValues: null | {
    name: string;
    firstSurname: string;
  };
}

interface IApplicationCreation {
  offers: null;
  rejectionReasons: null;
}

interface ICosignerData {
  spouseAsCosigner: string;
  sameAddressAsSolicitant: string;
  employment: string;
  income?: number;
}

interface IVerificationModal {
  biometricVerificationId: null | number;
  verificationCompleted: null | number;
  curp: null | string;
  address: null | Object;
  prefillingData: null | { name: string; firstSurname: string };
}

interface IExistentClient {
  clientAccount: IUser | null;
  complianceId: number | null;
  nipVerified: boolean | null;
}

interface INewApplicationState {
  accountCreation: IAccountCreation;
  accountVerificationSucceded: null | boolean;
  accountDataNotInUse: null | boolean;
  applicationCreation: IApplicationCreation;
  profiling: IProfilingFormComplete;
  verificationModal: IVerificationModal;
  existentClient: IExistentClient;
}

interface IContinueApplicationView {
  newApplication: INewApplicationState;
  setApplicationStarted: Function;
  clearNewApplication: Function;
  redirectToProfilingStart: Function;
  redirectToAccountCreationStart: Function;
  redirectToApplicationSectionsStart: Function;
}

const content = (newApplication: INewApplicationState) => {
  const name = (() => {
    if (newApplication.accountCreation.accountFormValues) {
      return `${newApplication.accountCreation.accountFormValues.name} ${newApplication.accountCreation.accountFormValues.firstSurname}`;
    } else if (
      !isNil(newApplication.verificationModal.prefillingData) &&
      !isEmpty(newApplication.verificationModal.prefillingData.name)
    ) {
      return `${newApplication.verificationModal.prefillingData.name} ${newApplication.verificationModal.prefillingData.firstSurname}`;
    } else {
      return null;
    }
  })();
  const email = !isNil(newApplication.profiling.contact)
    ? newApplication.profiling.contact.email
    : null;
  const phoneNumber = !isNil(newApplication.profiling.contact)
    ? newApplication.profiling.contact.phoneNumber
    : null;

  return (
    <DescriptionText>
      {!isNil(name)
        ? `¿Desea continuar con el proceso de aplicación de ${name} con teléfono ${phoneNumber} y correo electrónico ${email} ?`
        : `¿Desea continuar con el proceso de aplicación con teléfono ${phoneNumber} y correo electrónico ${email} ?`}
    </DescriptionText>
  );
};

enum NewApplicationSteps {
  PROFILING_COMPLETED = 'PROFILING_COMPLETED',
  CHECK_EXISTENT_DATA_COMPLETED = 'CHECK_EXISTENT_DATA_COMPLETED',
  BIOMETRIC_VERIFICATION_START_COMPLETED = 'BIOMETRIC_VERIFICATION_START_COMPLETED',
  BIOMETRIC_VERIFICATION_WAIT_COMPLETED = 'BIOMETRIC_VERIFICATION_WAIT_COMPLETED',
  EXTRACT_CURP_COMPLETED = 'EXTRACT_CURP_COMPLETED',
  EXTRACT_PREFILLING_DATA_COMPLETED = 'EXTRACT_PREFILLING_DATA_COMPLETED',
  ACCOUNT_FORM_COMPLETED = 'ACCOUNT_FORM_COMPLETED',
  ACCOUNT_VERIFICATION_COMPLETED = 'ACCOUNT_VERIFICATION_COMPLETED',
}

const ContinueApplicationView: React.FunctionComponent<IContinueApplicationView> = ({
  newApplication,
  clearNewApplication,
  setApplicationStarted,
  redirectToProfilingStart,
  redirectToAccountCreationStart,
  redirectToApplicationSectionsStart,
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const clearAndStart = useCallback(() => {
    clearNewApplication();
    setApplicationStarted(true);
    redirectToProfilingStart();
  }, [clearNewApplication, redirectToProfilingStart, setApplicationStarted]);

  useEffect(() => {
    if (isEmpty(newApplication.profiling)) {
      clearAndStart();
    } else {
      setModalOpen(true);
    }
  }, [newApplication, clearAndStart]);

  const getStep = () => {
    if (
      !isNil(newApplication.accountVerificationSucceded) ||
      (newApplication.profiling.applicant.existentUser &&
        newApplication.existentClient.nipVerified)
    ) {
      return NewApplicationSteps.ACCOUNT_VERIFICATION_COMPLETED;
    } else if (!isNil(newApplication.accountCreation.account)) {
      return NewApplicationSteps.ACCOUNT_FORM_COMPLETED;
    } else if (
      !isNil(newApplication.verificationModal.biometricVerificationId) &&
      !isNil(newApplication.verificationModal.verificationCompleted)
    ) {
      if (!isNil(newApplication.verificationModal.prefillingData)) {
        return NewApplicationSteps.EXTRACT_PREFILLING_DATA_COMPLETED;
      } else if (!isNil(newApplication.verificationModal.curp)) {
        return NewApplicationSteps.EXTRACT_CURP_COMPLETED;
      } else if (newApplication.verificationModal.verificationCompleted === 1) {
        return NewApplicationSteps.BIOMETRIC_VERIFICATION_WAIT_COMPLETED;
      } else {
        return NewApplicationSteps.BIOMETRIC_VERIFICATION_START_COMPLETED;
      }
    } else if (!isNil(newApplication.accountDataNotInUse)) {
      return NewApplicationSteps.CHECK_EXISTENT_DATA_COMPLETED;
    } else if (!isEmpty(newApplication.profiling)) {
      return NewApplicationSteps.PROFILING_COMPLETED;
    }
  };

  const redirectTo = (redirect: Function) => {
    setApplicationStarted(true);
    redirect();
  };

  const handleRedirect = () => {
    const step = getStep();
    switch (step) {
      case NewApplicationSteps.ACCOUNT_VERIFICATION_COMPLETED:
        redirectTo(redirectToApplicationSectionsStart);
        break;
      case NewApplicationSteps.ACCOUNT_FORM_COMPLETED:
      case NewApplicationSteps.EXTRACT_PREFILLING_DATA_COMPLETED:
        redirectTo(redirectToAccountCreationStart);
        break;
      case NewApplicationSteps.EXTRACT_CURP_COMPLETED:
      case NewApplicationSteps.BIOMETRIC_VERIFICATION_WAIT_COMPLETED:
      case NewApplicationSteps.BIOMETRIC_VERIFICATION_START_COMPLETED:
      case NewApplicationSteps.CHECK_EXISTENT_DATA_COMPLETED:
      case NewApplicationSteps.PROFILING_COMPLETED:
        redirectTo(redirectToProfilingStart);
        break;
      default:
        clearAndStart();
        break;
    }
  };

  const header = <ModalHeader>Continuar solicitud</ModalHeader>;

  const controls = (
    <ControlContainer>
      <ButtonContainer>
        <Button
          variant="secondary"
          onClick={() => {
            clearAndStart();
          }}
        >
          Nueva
        </Button>
      </ButtonContainer>
      <ButtonContainer>
        <Button onClick={() => handleRedirect()}>Continuar</Button>
      </ButtonContainer>
    </ControlContainer>
  );

  return (
    <Modal
      content={() => content(newApplication)}
      controls={() => controls}
      header={() => header}
      isOpen={modalOpen}
      onRequestClose={() => setModalOpen(false)}
      shouldCloseOnOverlayClick={false}
      showCloseIcon={false}
      width={'400px'}
    />
  );
};

const mapStateToProps = (state: any) => ({
  newApplication: state.newApplication,
});

const creators = {
  clearNewApplication: continueApplicationActions.creators.clearNewApplication,
  redirectToProfilingStart:
    continueApplicationActions.creators.redirectToProfilingStart,
  redirectToAccountCreationStart:
    continueApplicationActions.creators.redirectToAccountCreationStart,
  redirectToApplicationSectionsStart:
    continueApplicationActions.creators.redirectToApplicationSectionsStart,
};

export default connect(mapStateToProps, creators)(ContinueApplicationView);
