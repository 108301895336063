import not from 'ramda/src/not';
import values from 'ramda/src/values';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import offersActions from '../../actions/offers';
import colors from '../../constants/colors';
import { isBankAnalyst, isBankUser } from '../../utils/permissions';
import Card from '../Card';
import LoadingIndicator from '../LoadingIndicator';
import OfferedApplications from '../OfferedApplicationsView';
import OfferRow from '../OfferRow';
import PipelineNavigator from '../PipelineNavigator';
import Table from '../Table';
import withUserData from '../UserDataHOC';

const TABLE_HEADERS_PROMOTER = [
  {
    text: 'ID Oferta',
    width: '10%',
  },
  {
    text: 'Otorgante',
    width: '10%',
  },
  {
    text: 'Tipo',
    width: '12.5%',
  },
  {
    text: 'Monto',
    width: '15%',
  },
  {
    text: 'Asesor',
    width: '20%',
  },
  {
    text: 'Titular',
    width: '17.5%',
  },
  {
    text: 'Días de vigencia',
    width: '15%',
  },
];

const TABLE_HEADERS_BANK = [
  {
    text: 'ID Oferta',
    width: '10%',
  },
  {
    text: 'Producto',
    width: '10%',
  },
  {
    text: 'Tipo',
    width: '12.5%',
  },
  {
    text: 'Monto',
    width: '15%',
  },
  {
    text: 'Comercio',
    width: '20%',
  },
  {
    text: 'Titular',
    width: '17.5%',
  },
  {
    text: 'Días de vigencia',
    width: '15%',
  },
];

const Background = styled.div`
  background-color: ${colors.BACKGROUND};
  width: 100%;
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

interface IPipelineView {
  currentStage: string;
  requestOffers: Function;
  fetchingOffers: boolean;
  userData: IUserData;
  offers: any[];
}

class PipelineView extends Component<IPipelineView> {
  state = {
    stage: null,
  };

  componentDidMount() {
    this.fetchOffersIfNecessary();
  }

  componentDidUpdate() {
    this.fetchOffersIfNecessary();
  }

  fetchOffersIfNecessary() {
    const { currentStage, requestOffers } = this.props;
    const { stage: lastStage } = this.state;

    if (currentStage !== lastStage) {
      requestOffers({ stage: currentStage });
      this.setState(() => ({ stage: currentStage }));
    }
  }

  render() {
    const { currentStage, fetchingOffers, offers, userData } = this.props;

    const rowProps = { stage: currentStage, userData };

    const content = fetchingOffers ? (
      <LoadingIndicatorContainer>
        <LoadingIndicator />
      </LoadingIndicatorContainer>
    ) : not(offers.length) ? (
      <LoadingIndicatorContainer>Sin resultados.</LoadingIndicatorContainer>
    ) : (
      <Background>
        <Table
          data={offers}
          headers={
            isBankUser(userData) ? TABLE_HEADERS_BANK : TABLE_HEADERS_PROMOTER
          }
          rowComponent={OfferRow}
          rowProps={rowProps}
        />
      </Background>
    );
    const pipelineNavigator =
      isBankUser(userData) && isBankAnalyst(userData) ? null : (
        <PipelineNavigator />
      );

    const contentTitle =
      isBankUser(userData) && isBankAnalyst(userData) ? 'Revisión' : 'Ofertas';

    return (
      <Fragment>
        {pipelineNavigator}
        {currentStage === 'offered' ? (
          <OfferedApplications />
        ) : (
          <Card controls={() => null} header={contentTitle}>
            {content}
          </Card>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  fetchingOffers: state.loaders.fetchingOffers,
  offers: values(state.entities.offers),
});

const creators = {
  requestOffers: offersActions.creators.fetchOffers.request,
};

export default withUserData(connect(mapStateToProps, creators)(PipelineView));
