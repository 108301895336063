import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Root from './components/Root';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import store from './store';

const withStore = app => <Provider store={store}>{app}</Provider>;

const applicationMountNode = document.getElementById('root');

ReactDOM.render(withStore(<Root />), applicationMountNode);
unregisterServiceWorker();
