import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import length from 'ramda/src/length';
import values from 'ramda/src/values';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import continueApplicationActions from '../../actions/continueApplication';
import existentClientActions from '../../actions/existentClient';
import priceQuoteActions from '../../actions/priceQuote';
import productsActions from '../../actions/products';
import profilingActions from '../../actions/profiling';
import sendApplication from '../../actions/sendApplication';
import verificationModalActions from '../../actions/verificationModal';
import colors from '../../constants/colors';
import useProfilingSuggestions from '../../hooks/useProfilingSuggestions';
import Button from '../Button';
import ExistentClientNipModal from '../ExistentClientNipModal';
import ExpandableProfilingCard from '../ExpandableProfilingCard';
import LoadingIndicator from '../LoadingIndicator';
import {
  AddressForm,
  ApplicantForm,
  CosignerForm,
  EmploymentForm,
  IAddressForm,
  IApplicantForm,
  IContactForm,
  ICosignerForm,
  IEmploymentForm,
  IExistentAccountForm,
  IProductForm,
  IProfilingForm,
  IProfilingFormComplete,
  ProductForm,
  getProfilingSchema,
  initialValues,
} from '../ProfilingSectionsForms';
import ProfilingSuggestionCards from '../ProfilingSuggestionsCards';
import SendApplicationModal from '../SendApplicationModal';
import SimpleCard from '../SimpleCard';
import VerificationModal from '../VerificationModal';
import VerificationModalCurp from '../VerificationModalCurp';

const BottomMargin = styled.div`
  margin-bottom: 20px;
`;

const MobileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 50vh;
  justify-content: center;
  width: 100%;
`;

const ContentOrganizer = styled.div`
  display: flex;
  justify-content: left;
  padding: 36px 0px;
  padding-left: 16%;
  padding-right: 4%;
  min-height: 740px;
`;

const SuggestionColumn = styled.div`
  margin-left: 40px;
  align-self: flex-end;
`;

const SuggestionColumnFixed = styled.div<{
  distanceFromTop: number;
  distanceFromBottom: number;
  reachedBottom: boolean;
}>`
  position: fixed;
  width: 270px;
  left: calc((100vw - 268px) * 0.16 + 760px);
  top: ${({ distanceFromTop }) => `${distanceFromTop}px`};
  bottom: ${({ distanceFromBottom, reachedBottom }) => {
    if (!reachedBottom) {
      return 'auto';
    } else {
      return `${distanceFromBottom}px`;
    }
  }};
`;

const SectionsColumn = styled.div`
  width: 438px;
`;

const CenterSectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

interface IExpandedForm {
  applicant: boolean;
  cosigner: boolean;
  address: boolean;
  employment: boolean;
  product: boolean;
  [key: string]: boolean;
}

interface IValidityForm {
  applicant: boolean;
  cosigner: boolean;
  address: boolean;
  employment: boolean;
  product: boolean;
}

interface IFormValues {
  values: IProfilingForm;
  formValidity: IValidityForm;
}

interface IViewProps {
  applicationStarted: boolean;
  fetchingPromoterProducts: boolean;
  redirectToApplicationStart: Function;
  requestPromoterProducts: Function;
}

interface IProfilingFormComponent {
  deviceData: IDeviceData;
  promoterProducts: { productType: string }[];
  existentProfilingData: IProfilingFormComplete;
  existentClientData: IUser | null;
  priceQuoteFormValues: any;
  verificationCompleted: boolean;
  accountDataNotInUse: boolean;
  existentAccount: boolean;
  requestProductManufacturers: Function;
  checkExistingClientData: Function;
  finishProfiling: Function;
  clientExists: Function;
  clearPriceQuoteValues: Function;
  clearSendApplicationToClient: Function;
  startVerification: Function;
}

interface IDynamicSuggestionsColumn {
  deviceData: IDeviceData;
}

const DynamicSuggestionsColumn: React.FunctionComponent<IDynamicSuggestionsColumn> = ({
  deviceData,
  children,
}) => {
  const [suggestionsScroll, setSuggestionsScroll] = useState<{
    distanceFromTop: number;
    distanceFromBottom: number;
    reachedBottom: boolean;
  }>({
    distanceFromTop: 228,
    distanceFromBottom: 90,
    reachedBottom: false,
  });

  const suggestionsParent = document.getElementById('homeMainContent');

  const handleScroll = (e: Event): void => {
    if (e.currentTarget) {
      const element = e.currentTarget as HTMLDivElement;
      setSuggestionsScroll({
        reachedBottom:
          element.scrollHeight - element.scrollTop < element.clientHeight + 16,
        distanceFromBottom:
          90 +
          (element.clientHeight + 16) -
          (element.scrollHeight - element.scrollTop),
        distanceFromTop:
          element.scrollTop <= 160 ? 228 - element.scrollTop : 68,
      });
    }
  };

  useEffect(() => {
    if (!isNil(suggestionsParent)) {
      suggestionsParent.addEventListener('scroll', handleScroll);
      return () =>
        suggestionsParent.removeEventListener('scroll', handleScroll);
    }
  }, [suggestionsParent]);

  const suggestionsAreFixed = deviceData.heightToCompare >= 800;

  return !isNil(suggestionsParent) && suggestionsAreFixed ? (
    <SuggestionColumnFixed
      reachedBottom={suggestionsScroll.reachedBottom}
      distanceFromBottom={suggestionsScroll.distanceFromBottom}
      distanceFromTop={suggestionsScroll.distanceFromTop}
    >
      {children}
    </SuggestionColumnFixed>
  ) : (
    <SuggestionColumn>{children}</SuggestionColumn>
  );
};

const ConnectedProfilingForm: React.FunctionComponent<IProfilingFormComponent> = ({
  deviceData,
  requestProductManufacturers,
  promoterProducts,
  checkExistingClientData,
  finishProfiling,
  existentProfilingData,
  existentClientData,
  clientExists,
  priceQuoteFormValues,
  clearPriceQuoteValues,
  clearSendApplicationToClient,
  startVerification,
  verificationCompleted,
  accountDataNotInUse,
  existentAccount,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const previousDataExists = !isEmpty(existentProfilingData);
  const searchParams = new URLSearchParams(window.location.search);
  const clientProductValues =
    searchParams.has('promoterUserCode') &&
    searchParams.has('brand') &&
    searchParams.has('vehicleModel') &&
    searchParams.has('price');
  const profilingInitialValues = (() => {
    if (!previousDataExists) {
      const formValues = {
        values: initialValues,
        formValidity: {
          applicant: false,
          cosigner: false,
          address: false,
          employment: false,
          product: false,
        },
      };
      if (clientProductValues) {
        const brand = searchParams.get('brand');
        const vehicleModel = searchParams.get('vehicleModel');
        const price = searchParams.get('price');
        const vehicleModelYear = searchParams.get('vehicleModelYear');
        formValues.values.product.brand = brand
          ? parseInt(brand)
          : formValues.values.product.brand;
        formValues.values.product.version = vehicleModel
          ? parseInt(vehicleModel)
          : formValues.values.product.version;
        formValues.values.product.price = price ? parseInt(price) : 0;
        formValues.values.product.model = vehicleModelYear
          ? vehicleModelYear
          : '';
        formValues.values.product.downpayment = price
          ? parseInt(price) * 0.2
          : 0;
      } else if (priceQuoteFormValues) {
        formValues.values.product.brand = priceQuoteFormValues.brand;
        formValues.values.product.version = priceQuoteFormValues.vehicleModel;
        formValues.values.product.price = priceQuoteFormValues.price;
        formValues.values.product.downpayment =
          priceQuoteFormValues.downpayment;
        formValues.values.product.insurance = priceQuoteFormValues.insurance;
        formValues.values.product.productType =
          priceQuoteFormValues.productType;
        formValues.values.product.model = priceQuoteFormValues.vehicleModelYear;
        formValues.values.product.vehicleType = priceQuoteFormValues.unitType;
        formValues.values.product.term = parseInt(
          priceQuoteFormValues.numberOfPayments
        );
        clearPriceQuoteValues();
      }
      return formValues;
    } else {
      const { contact, ...formData } = existentProfilingData;
      return {
        values: formData as IProfilingForm,
        formValidity: {
          applicant: true,
          cosigner: formData.applicant.hasCosigner,
          address: true,
          employment: true,
          product: true,
        },
      };
    }
  })();
  const [formValues, setFormValues] = useState<IFormValues>(
    profilingInitialValues
  );
  const [formExpanded, setFormExpanded] = useState<IExpandedForm>({
    applicant: true,
    cosigner: false,
    address: false,
    employment: false,
    product: false,
  });

  const { productType } = formValues.values.product;
  const productIsValid =
    formValues.values.product.brand !== 0 &&
    formValues.values.product.version !== 0 &&
    formValues.values.product.model !== '' &&
    formValues.values.product.price > 0 &&
    formValues.values.product.downpayment > 0 &&
    formValues.values.product.term > 0 &&
    formValues.values.product.insurance !== '';

  useEffect(() => {
    if (!isEmpty(productType)) {
      requestProductManufacturers({
        type: productType,
      });
    }
  }, [productType, requestProductManufacturers]);

  useEffect(() => {
    if (!formValues.values.applicant.hasCosigner) {
      formValues.values.cosigner = initialValues.cosigner;
      formValues.formValidity.cosigner = false;
      setFormValues(formValues);
    }
  }, [formValues, formValues.values.applicant.hasCosigner]);

  useEffect(() => {
    if (!isNil(existentClientData)) {
      finishProfiling({
        ...formValues.values,
        applicant: {
          ...formValues.values.applicant,
          existentUser: true,
        },
        contact: {
          email: existentClientData.email,
          phoneNumber: existentClientData.phoneNumber,
        },
      });
      if (
        deviceData.isMobile &&
        window.location.search.includes('promoterUserCode') &&
        !verificationCompleted
      ) {
        const payload = {
          email: existentClientData.email,
          phoneNumber: existentClientData.phoneNumber,
          shouldSendSMS: false,
        };
        startVerification(payload);
      }
    }
  }, [
    existentClientData,
    finishProfiling,
    formValues.values,
    deviceData.isMobile,
    startVerification,
    verificationCompleted,
  ]);

  useEffect(() => {
    if (
      accountDataNotInUse &&
      deviceData.isMobile &&
      window.location.search.includes('promoterUserCode') &&
      !verificationCompleted
    ) {
      const payload = {
        ...existentProfilingData.contact,
        shouldSendSMS: false,
      };
      startVerification(payload);
    }
  }, [
    existentProfilingData.contact,
    accountDataNotInUse,
    deviceData.isMobile,
    startVerification,
    verificationCompleted,
  ]);

  const handleChange = useCallback(
    (key: string, values: any, validity: boolean) => {
      setFormValues({
        formValidity: { ...formValues.formValidity, [key]: validity },
        values: { ...formValues.values, [key]: values },
      });
    },
    [formValues.formValidity, formValues.values]
  );

  const handleChangeApplicant = useCallback(
    (values: IApplicantForm, validity: boolean) =>
      handleChange('applicant', values, validity),
    [handleChange]
  );
  const handleChangeCosigner = useCallback(
    (values: ICosignerForm, validity: boolean) =>
      handleChange('cosigner', values, validity),
    [handleChange]
  );
  const handleChangeAddress = useCallback(
    (values: IAddressForm, validity: boolean) =>
      handleChange('address', values, validity),
    [handleChange]
  );
  const handleChangeEmployment = useCallback(
    (values: IEmploymentForm, validity: boolean) =>
      handleChange('employment', values, validity),
    [handleChange]
  );
  const handleChangeProduct = useCallback(
    (values: IProductForm, validity: boolean) =>
      handleChange('product', values, validity),
    [handleChange]
  );

  const profilingResult = useProfilingSuggestions(formValues.values);

  const submitProfilingForm = (
    values: IContactForm | IExistentAccountForm
  ): void => {
    if (existentAccount) {
      clientExists({ email: values.email });
    }
    if ('phoneNumber' in values) {
      const contactValues = {
        email: values.email,
        phoneNumber: values.phoneNumber,
      };
      finishProfiling({
        ...formValues.values,
        applicant: {
          ...formValues.values.applicant,
          existentUser: false,
        },
        contact: contactValues,
      });
      checkExistingClientData(contactValues);
    }
  };

  const handleSectionClick = (id: string): void => {
    const isOpen = formExpanded[id];
    const allClosed: IExpandedForm = {
      applicant: false,
      cosigner: false,
      address: false,
      employment: false,
      product: false,
    };
    if (!isOpen) {
      allClosed[id] = true;
    }
    setFormExpanded(allClosed);
  };

  const validationSchema = getProfilingSchema(
    formValues.values.applicant.hasCosigner
  );

  const applicantSectionHeight =
    formValues.values.applicant.maritalStatus === 'married' ? '296px' : '200px';
  const cosignerSectionHeight = (() => {
    const applicantIsMarried =
      formValues.values.applicant.maritalStatus === 'married';
    const cosignerIsEmployed = ['employed', 'independent', 'retired'].includes(
      formValues.values.cosigner.employmentStatus
    );
    if (applicantIsMarried && cosignerIsEmployed) {
      return '368px';
    } else if (applicantIsMarried || cosignerIsEmployed) {
      return '280px';
    } else {
      return '192px';
    }
  })();
  const addressSectionHeight =
    formValues.values.address.homeType === 'rent' ? '464px' : '368px';
  const employmentSectionHeight = (() => {
    const isEmployed =
      formValues.values.employment.employmentStatus === 'employed';
    const isIndependent =
      formValues.values.employment.employmentStatus === 'independent';
    const isRetired =
      formValues.values.employment.employmentStatus === 'retired';
    const hasOtherIncome =
      formValues.values.employment.sourceOtherIncome !== 'no_apply';
    if ((isEmployed && hasOtherIncome) || (isIndependent && hasOtherIncome)) {
      return '456px';
    } else if (hasOtherIncome || isRetired) {
      return '192px';
    } else if (isIndependent || isEmployed) {
      return '284px';
    } else {
      return '104px';
    }
  })();
  const productSectionHeight = (() => {
    if (deviceData.isMobile) {
      return length(promoterProducts) > 1 ? '1224px' : '1118px';
    } else {
      return length(promoterProducts) > 1 ? '1006px' : '890px';
    }
  })();

  const sections = (
    <CenterSectionsContainer>
      <ExpandableProfilingCard
        id={'applicant'}
        title={'Datos del solicitante'}
        height={applicantSectionHeight}
        handleChange={handleChangeApplicant}
        handleClick={handleSectionClick}
        isExpanded={formExpanded.applicant}
        isMobile={deviceData.isMobile}
        initialValues={formValues.values.applicant}
        validationSchema={validationSchema.applicant}
        suggestions={profilingResult.suggestions['applicant']}
        isInitialValid={previousDataExists}
        SectionForm={ApplicantForm}
      />
      {formValues.values.applicant.hasCosigner ? (
        <ExpandableProfilingCard
          id={'cosigner'}
          title={'Datos del coacreditado'}
          height={cosignerSectionHeight}
          handleChange={handleChangeCosigner}
          isExpanded={formExpanded.cosigner}
          isMobile={deviceData.isMobile}
          handleClick={handleSectionClick}
          completeFormValues={formValues.values}
          initialValues={formValues.values.cosigner}
          validationSchema={validationSchema.cosigner}
          isInitialValid={previousDataExists}
          SectionForm={CosignerForm}
        />
      ) : null}
      <ExpandableProfilingCard
        id={'address'}
        title={'Datos del domicilio'}
        height={addressSectionHeight}
        handleChange={handleChangeAddress}
        isExpanded={formExpanded.address}
        isMobile={deviceData.isMobile}
        handleClick={handleSectionClick}
        initialValues={formValues.values.address}
        validationSchema={validationSchema.address}
        isInitialValid={previousDataExists}
        SectionForm={AddressForm}
      />
      <ExpandableProfilingCard
        id={'employment'}
        title={'Datos del empleo'}
        height={employmentSectionHeight}
        handleChange={handleChangeEmployment}
        isExpanded={formExpanded.employment}
        isMobile={deviceData.isMobile}
        handleClick={handleSectionClick}
        initialValues={formValues.values.employment}
        validationSchema={validationSchema.employment}
        isInitialValid={previousDataExists}
        SectionForm={EmploymentForm}
      />
      <ExpandableProfilingCard
        id={'product'}
        height={productSectionHeight}
        title={'Datos del producto'}
        handleChange={handleChangeProduct}
        isExpanded={formExpanded.product}
        isMobile={deviceData.isMobile}
        handleClick={handleSectionClick}
        initialValues={formValues.values.product}
        validationSchema={validationSchema.product}
        suggestions={profilingResult.suggestions['product']}
        isInitialValid={previousDataExists || productIsValid}
        SectionForm={ProductForm}
      />
    </CenterSectionsContainer>
  );

  const suggestionCards = (
    <ProfilingSuggestionCards
      profilingResult={profilingResult}
      submitProfilingForm={submitProfilingForm}
      existentAccount={existentAccount}
    />
  );

  const sendApplicationToClientButton = (
    <Button variant={'primary'} onClick={() => setShowModal(true)}>
      Enviar a cliente
    </Button>
  );

  const nonMobileContent = (
    <BottomMargin>
      <SimpleCard
        title="Encuentra un producto"
        subtitle="Continua con el llenado de la información dando click al titulo de cada sección."
        isPrimary={true}
        controls={
          !window.location.search.includes('promoterUserCode')
            ? sendApplicationToClientButton
            : null
        }
      >
        <ContentOrganizer>
          <SectionsColumn>{sections}</SectionsColumn>
          <DynamicSuggestionsColumn deviceData={deviceData}>
            {suggestionCards}
          </DynamicSuggestionsColumn>
        </ContentOrganizer>
      </SimpleCard>
      <SendApplicationModal
        showModal={showModal}
        closeModal={() => {
          setShowModal(false);
          clearSendApplicationToClient();
        }}
      />
    </BottomMargin>
  );

  const mobileContent = (
    <MobileContainer>
      {sections}
      {suggestionCards}
    </MobileContainer>
  );

  const content = deviceData.isMobile ? mobileContent : nonMobileContent;
  return content;
};

const mapStateToPropsForm = (
  state: any
): {
  deviceData: IDeviceData;
  promoterProducts: { productType: string }[];
  existentProfilingData: IProfilingFormComplete;
  existentClientData: IUser | null;
  priceQuoteFormValues: any;
  verificationCompleted: boolean;
  accountDataNotInUse: boolean;
  existentAccount: boolean;
} => ({
  deviceData: state.deviceData,
  promoterProducts: values(state.entities.promoterProducts),
  existentProfilingData: state.newApplication.profiling,
  existentClientData: state.newApplication.existentClient.clientAccount,
  priceQuoteFormValues: state.entities.priceQuote.formValues,
  verificationCompleted:
    state.newApplication.biometricVerification.verificationCompleted,
  accountDataNotInUse: state.newApplication.accountDataNotInUse,
  existentAccount: state.newApplication.existentClient.isExistentClient,
});

const creatorsForm = {
  requestProductManufacturers:
    productsActions.creators.fetchProductsManufacturers.request,
  checkExistingClientData:
    profilingActions.creators.checkExistingClientData.request,
  finishProfiling: profilingActions.creators.finishProfiling,
  clientExists: existentClientActions.creators.clientExists.request,
  clearPriceQuoteValues: priceQuoteActions.creators.clearPriceQuoteOffers,
  clearSendApplicationToClient:
    sendApplication.creators.clearSendApplicationToClient,
  startVerification:
    verificationModalActions.creators.startVerification.request,
};

const ProfilingForm = connect(
  mapStateToPropsForm,
  creatorsForm
)(ConnectedProfilingForm);

const ProfilingView: React.FunctionComponent<IViewProps> = ({
  applicationStarted,
  redirectToApplicationStart,
  fetchingPromoterProducts,
  requestPromoterProducts,
}) => {
  useEffect(() => {
    requestPromoterProducts();
  }, [requestPromoterProducts]);

  useEffect(() => {
    if (!applicationStarted) {
      redirectToApplicationStart();
    }
  }, [applicationStarted, redirectToApplicationStart]);

  if (fetchingPromoterProducts) {
    return (
      <LoadingState>
        <LoadingIndicator />
      </LoadingState>
    );
  } else {
    return (
      <Fragment>
        <ProfilingForm />
        <VerificationModal />
        <VerificationModalCurp />
        <ExistentClientNipModal />
      </Fragment>
    );
  }
};

const mapStateToProps = (
  state: any
): { fetchingPromoterProducts: boolean } => ({
  fetchingPromoterProducts: state.loaders.fetchingPromoterProducts,
});

const creators = {
  redirectToApplicationStart:
    continueApplicationActions.creators.redirectToApplicationStart,
  requestPromoterProducts:
    productsActions.creators.fetchPromoterProducts.request,
};

export default connect(mapStateToProps, creators)(ProfilingView);
