import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const BIOMETRIC_VERIFICATION = createAsyncFlowTypes('BIOMETRIC_VERIFICATION');

const biometricVerification = createAsyncFlowCreators(BIOMETRIC_VERIFICATION);

export default {
  types: {
    BIOMETRIC_VERIFICATION,
  },
  creators: {
    biometricVerification,
  },
};
