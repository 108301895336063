import { createAction } from 'redux-actions';

import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const CHECK_EXISTING_CLIENT_DATA_CLEAR = 'CHECK_EXISTING_CLIENT_DATA_CLEAR';
const CHECK_EXISTING_CLIENT_DATA = createAsyncFlowTypes(
  'CHECK_EXISTING_CLIENT_DATA'
);
const PROFILING_FINISH = 'PROFILING_FINISH';
const PROFILING_STARTED = 'PROFILING_STARTED';

const clearExistingClientData = createAction(CHECK_EXISTING_CLIENT_DATA_CLEAR);
const checkExistingClientData = createAsyncFlowCreators(
  CHECK_EXISTING_CLIENT_DATA
);
const finishProfiling = createAction(PROFILING_FINISH);
const startProfiling = createAction(PROFILING_STARTED);

export default {
  types: {
    CHECK_EXISTING_CLIENT_DATA,
    PROFILING_FINISH,
    PROFILING_STARTED,
  },
  creators: {
    clearExistingClientData,
    checkExistingClientData,
    finishProfiling,
    startProfiling,
  },
};
