import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';

import auth from '../../actions/auth';
import colors from '../../constants/colors';
import { getAvatarLetters } from '../../utils/misc';

const SPECIAL_MENU_BORDER_COLOR = 'rgb(216, 220, 230)';

const AvatarContainer = styled.div<{ isMobile: boolean }>`
  margin: auto ${props => (props.isMobile ? '32px' : '52px')} auto 0px;
  height: ${props => (props.isMobile ? '24px' : '38px')};
`;

const AvatarMenu = styled.div<{ isMobile: boolean }>`
  background-color: ${colors.AQUA};
  border-radius: 19px;
  text-align: center;
  height: ${props => (props.isMobile ? '24px' : '38px')};
  width: ${props => (props.isMobile ? '24px' : '38px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvatarSpan = styled.div<{ isMobile: boolean }>`
  color: ${colors.WHITE};
  cursor: default;
  font-size: ${props => (props.isMobile ? '10px' : '16px')};
  font-weight: ${props => (props.isMobile ? 'bold' : 'normal')};
  line-height: ${props => (props.isMobile ? '1.81' : '1.5')};
  margin: auto 0px;
`;

const Dropdown = styled.div`
  position: relative;
`;

const DropDownContent = styled.div`
  display: none;
  position: absolute;
  right: 0;
  z-index: 1;

  ${Dropdown}:hover & {
    display: block;
  }
`;

const OptionsContainer = styled.div`
  margin-top: 5px;
  border-radius: 2px;
  border: 1px solid ${SPECIAL_MENU_BORDER_COLOR};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 ${colors.BACKGROUND};
  background-color: ${colors.WHITE};
`;

const Option = styled.div<{ disabled?: true }>`
  background-color: ${({ disabled }) =>
    disabled ? colors.BACKGROUND : colors.WHITE};
  color: ${({ disabled }) =>
    disabled ? colors.SECONDARY_TEXT : colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.57;
  padding: 9px 15px;
  width: 180px;

  &:hover {
    background-color: ${colors.BACKGROUND};
  }
`;

interface AvatarReduxProps {
  logout: () => void;
  user: IUserData['user'];
  isMobile: boolean;
}

type AvatarProps = RouteComponentProps & AvatarReduxProps;

const getUserFullName = (user: IUserData['user']) => {
  if (user.firstSurname) {
    return `${user.name} ${user.firstSurname}`;
  }

  return user.name;
};

const Avatar: React.FunctionComponent<AvatarProps> = ({
  history,
  logout,
  user,
  isMobile,
}) => (
  <AvatarContainer isMobile={isMobile}>
    <Dropdown>
      <AvatarMenu isMobile={isMobile}>
        <AvatarSpan isMobile={isMobile}>{getAvatarLetters(user)}</AvatarSpan>
      </AvatarMenu>
      <DropDownContent>
        <OptionsContainer>
          <Option disabled>
            <span>{getUserFullName(user)}</span>
          </Option>
          <Option onClick={() => history.push('/profile')}>
            <span>Perfil</span>
          </Option>
          <Option onClick={logout}>
            <span>Cerrar sesión</span>
          </Option>
        </OptionsContainer>
      </DropDownContent>
    </Dropdown>
  </AvatarContainer>
);

const mapStateToProps = (state: any) => ({
  user: state.auth.userData.user,
});

const creators = {
  logout: auth.creators.logout.request,
};

export default withRouter(connect(mapStateToProps, creators)(Avatar));
