import React, { Fragment } from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';

const CardContainer = styled.div`
  background-color: ${colors.WHITE};
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 ${colors.BORDER};
`;

const CardContent = styled.div`
  width: 100%;
`;

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  // auto will produce a 60px height if the header's text fits in one line
  // but using auto will allow us to resize automatically in case the user is using a mobile device
  height: auto;
  justify-content: space-between;
  padding: 16px 24px;
`;

const HeaderDivider = styled.div`
  background-color: ${colors.AQUA};
  height: 1px;
  width: 100%;
`;

const HeaderText = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 18px;
  font-weight: bold;
  line-height: 1.56;
`;

interface ICard {
  controls?: React.FunctionComponent | React.ReactNode;
  children: React.ReactNode;
  header: string;
}

const Card: React.FunctionComponent<ICard> = ({
  controls = Fragment,
  children,
  header,
}) => (
  <CardContainer>
    <HeaderContainer>
      <HeaderText>{header}</HeaderText>
      {controls instanceof Function ? controls() : controls}
    </HeaderContainer>
    <HeaderDivider />
    <CardContent>{children}</CardContent>
  </CardContainer>
);

export default Card;
