import { handleActions } from 'redux-actions';

const initialState = {
  isMobile: null,
  isiOS: null,
  clientWidth: null,
  clientHeight: null,
  innerWidth: null,
  innerHeight: null,
  widthToCompare: null,
  heightToCompare: null,
};

const deviceDataReducer = handleActions(
  {
    SET_DEVICE_DATA: (state, action) => action.payload,
  },
  initialState
);

export default deviceDataReducer;
