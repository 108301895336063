import not from 'ramda/src/not';
import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import styled from 'styled-components';

import colors from '../../constants/colors';
import Border from '../Border';

const DROPDOWN_BORDER = '#D8DCE6';

const DropdownContainer = styled.div`
  position: relative;
`;

const HeaderContainer = styled.div<{ disabled?: boolean }>`
  align-items: center;
  border-radius: 4px;
  border: solid 1px ${DROPDOWN_BORDER};
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
  background-color: ${colors.WHITE};
  color: ${({ disabled }) => (disabled ? colors.DUSK50 : '')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : '')};
  display: flex;
  font-weight: normal;
  font-size: 14px;
  height: 38px;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  width: 100%;
`;

const ListContainer = styled.div`
  background-color: ${colors.WHITE};
  border-radius: 4px;
  border: solid 1px ${DROPDOWN_BORDER};
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
  max-height: 190px;
  overflow-y: auto;
  position: absolute;
  top: 44px;
  width: 100%;
  z-index: 10;
`;

const Option = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  font-size: 14px;
  font-weight: normal;
  height: 38px;
  padding-left: 16px;

  &:hover {
    background-color: ${colors.BACKGROUND75};
  }
`;

const ArrowUp = styled.div`
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 3px solid black;
  height: 0;
  margin-bottom: 4px;
  width: 0;
`;

const ArrowDown = styled.div`
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid black;
  height: 0;
  width: 0;
`;

const noop = () => {};

const Icon = () => (
  <div>
    <ArrowUp />
    <ArrowDown />
  </div>
);

export interface Option {
  text: string;
  value: string | number;
}

interface Props {
  disabled?: boolean;
  onBlur?(): void;
  onChange(selectedOption: Option): void;
  onClose?(): void;
  onOpen?(): void;
  options: Array<Option>;
  placeholder?: string;
  value: string | number;
}

interface State {
  open: boolean;
}

class Dropdown extends Component<Props, State> {
  static defaultProps: Props = {
    disabled: false,
    onBlur: noop,
    onChange: noop,
    onClose: noop,
    onOpen: noop,
    options: [],
    placeholder: '',
    value: '',
  };

  state = {
    open: false,
  };

  toggleListOpen = () => {
    const { open } = this.state;
    const { disabled, onBlur, onClose, onOpen } = this.props;

    if (disabled) {
      return;
    }

    // Team: open discussion about wheter this should run here
    //       or in the setState callback
    if (not(open)) {
      onOpen && onOpen();
    } else {
      onClose && onClose();
      onBlur && onBlur();
    }

    this.setState(oldState => ({
      open: !oldState.open,
    }));
  };

  handleClickOutside = () => {
    const { open } = this.state;
    const { onBlur } = this.props;

    if (open) {
      this.toggleListOpen();
      onBlur && onBlur();
    }
  };

  handleClickOnOption = (option: Option) => () => {
    const { onChange } = this.props;

    onChange(option);
    this.toggleListOpen();
  };

  render() {
    const { open } = this.state;
    const { disabled, options, placeholder, value } = this.props;

    let optionList = [];

    for (let i = 0; i < options.length; i += 1) {
      optionList.push(
        <Option onClick={this.handleClickOnOption(options[i])} key={i}>
          {options[i].text}
        </Option>
      );

      if (i !== options.length - 1) {
        optionList.push(<Border key={`border${i}`} />);
      }
    }

    const selectedOption = options.find(option => option.value === value);
    const textToRender = selectedOption ? selectedOption.text : placeholder;

    return (
      <DropdownContainer>
        <HeaderContainer disabled={disabled} onClick={this.toggleListOpen}>
          {textToRender}
          <Icon />
        </HeaderContainer>
        {open ? <ListContainer>{optionList}</ListContainer> : null}
      </DropdownContainer>
    );
  }
}

export default onClickOutside<React.ComponentClass<Props, any>>(Dropdown);
