import isNil from 'ramda/src/isNil';
import prop from 'ramda/src/prop';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import Border from '../Border';
import ExpandableSectionList from '../ExpandableSectionList';
import InvoiceDocumentDetail from '../InvoiceDocumentDetail';

const Header = styled.div`
  color: ${colors.DUSK50};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  width: ${prop('width')};
`;

const HeadersContainer = styled.div`
  align-items: center;
  display: flex;
  height: 36px;
  padding-left: 36px;
  text-transform: uppercase;
  width: 100%;
`;

const InvoiceDocumentsHeaders: React.FunctionComponent<{
  mode: InvoiceDocumentMode;
}> = ({ mode }) =>
  mode === 'upload' ? (
    <HeadersContainer>
      <Header width={'30%'}>Nombre</Header>
      <Header width={'35%'}>Descripción</Header>
      <Header width={'20%'}>Estatus</Header>
      <Header width={'15%'}>Acciones</Header>
    </HeadersContainer>
  ) : (
    <HeadersContainer>
      <Header width={'30%'}>Nombre</Header>
      <Header width={'35%'}>Descripción</Header>
      <Header width={'35%'}>Estatus</Header>
    </HeadersContainer>
  );

const getMirrorMode = (mode: InvoiceDocumentMode): InvoiceDocumentMode =>
  mode === 'review' ? 'upload' : 'review';

interface IInvoiceDocuments {
  invoice: IInvoice;
  isCreditusUser: boolean;
}

const InvoiceDocuments: React.FunctionComponent<IInvoiceDocuments> = ({
  invoice,
  isCreditusUser,
}) => {
  const [overflow, setOverflow] = useState<'hidden' | 'visible'>('hidden');

  const { pdfDocument, xmlDocument, proofPaymentDocument } = invoice;

  if (isNil(pdfDocument) || isNil(xmlDocument) || isNil(proofPaymentDocument)) {
    return <div>Error</div>;
  }

  const mode = (() => {
    if (invoice.type === 'receivable') {
      return isCreditusUser ? 'upload' : 'review';
    } else {
      return isCreditusUser ? 'review' : 'upload';
    }
  })();

  const expandableListInitialSection =
    pdfDocument.status === 'approved' && xmlDocument.status === 'approved'
      ? 1
      : 0;

  const disabledSections = [
    false,
    pdfDocument.status !== 'approved' || xmlDocument.status !== 'approved',
  ];

  return (
    <ExpandableSectionList
      disabledSections={disabledSections}
      expandedSectionOverflow={overflow}
      initialExpandedSection={expandableListInitialSection}
      sectionHeights={[174, 105]}
      sectionTitles={['Facturación', 'Pago']}
      title="Documentos"
    >
      <Fragment>
        <InvoiceDocumentsHeaders mode={mode} />
        <Border />
        <InvoiceDocumentDetail
          mode={mode}
          description={'Factura en formato PDF'}
          name={'Factura PDF'}
          invoiceDocument={pdfDocument}
          invoiceId={invoice.id}
          setParentOverflow={setOverflow}
        />
        <Border />
        <InvoiceDocumentDetail
          mode={mode}
          description={'Factura en formato XML'}
          name={'Factura XML'}
          invoiceDocument={xmlDocument}
          invoiceId={invoice.id}
          setParentOverflow={setOverflow}
        />
      </Fragment>
      <Fragment>
        <InvoiceDocumentsHeaders mode={getMirrorMode(mode)} />
        <Border />
        <InvoiceDocumentDetail
          mode={getMirrorMode(mode)}
          description={'Comprobante de pago de la factura'}
          name={'Comprobante'}
          invoiceDocument={proofPaymentDocument}
          invoiceId={invoice.id}
          setParentOverflow={setOverflow}
        />
      </Fragment>
    </ExpandableSectionList>
  );
};

export default InvoiceDocuments;
