import format from 'date-fns/format';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import automaticProductsActions from '../../actions/automaticProducts';
import colors from '../../constants/colors';
import f from '../../constants/formatters';
import grammar from '../../constants/grammar';
import Border from '../Border';
import Button from '../Button';
import ExpandableCard from '../ExpandableCard';
import Input from '../Input';
import LoadingIndicator from '../LoadingIndicator';
import MultiSingleRowTable from '../MultiSingleRowTable';

const BackButtonContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const CardMargin = styled.div`
  padding: 12px 0px;
`;

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DocumentDescription = styled.div`
  padding: 8px;
  width: 480px;
`;

const DocumentEntriesContainer = styled.div`
  padding: 8px 16px;
`;

const DocumentHeader = styled.div`
  align-items: center;
  color: ${colors.DUSK50};
  font-size: 12px;
  font-weight: bold;
  height: 36px;
  line-height: 1.5;
  text-transform: uppercase;
  display: flex;
  padding: 0px 24px;
`;

const DocumentName = styled.div`
  padding: 8px;
  width: 240px;
`;

const LoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 50vh;
  justify-content: center;
  width: 100%;
`;

const housingOptions: Record<string, string> = {
  own: 'Propio',
  mortgage: 'Hipotecado',
  guest: 'Huésped',
  rent: 'Rentado',
};

const documentStagesOptions: Record<string, string> = {
  analysis: 'Requisitos para revisión',
  approval: 'Requisitos para formalización',
  'pre-formalization': 'Requisitos de formalización',
  formalization: 'Requisitos para aprobación',
};

interface INumberPaymentsOption {
  id: number;
  numberPayments: number;
}

interface IHousingTenureOption {
  id: number;
  housingTenureType: string;
}

interface IAccessory {
  id: number;
  name: string;
}

interface IStateRestriction {
  id: number;
  state: string;
}

interface IDocument {
  id: number;
  stage: string;
  name: string;
  description: string;
}

interface IProductDetails {
  id: number;
  bankId: number;
  active: boolean;
  internalName: string;
  commercialName: string;
  productType: string;
  validThrough: string;
  extensionDays: number;
  creditAmountMin: number;
  creditAmountMax: number;
  interestRate: number;
  interestRateType: string;
  fee: number;
  feeType: string;
  otherCommission: number | null;
  otherCommissionType: string | null;
  accessoriesAmount: number;
  amortizationType: string;
  promoterCommission: number;
  promoterCommissionType: string;
  downpaymentMin: number;
  vehicleValueMin: number;
  vehicleValueMax: number;
  vehicleCondition: string;
  vehicleAgeMax: number;
  ageMin: number;
  ageMax: number;
  educationLevelMin: string;
  economicDependentsMax: number | null;
  cosigner: boolean;
  cosignerAgeMin: number | null;
  cosignerAgeMax: number | null;
  housingExpensesMax: number | null;
  monthsInResidenceMin: number | null;
  employmentType: string;
  monthsInEmploymentMin: number | null;
  incomeMin: number;
  incomeFactor: number;
  noCreditHistory: number;
  ficoScoreMin: number | null;
  indebtednessLevelMax: number | null;
  monthlyEstimatedPaymentTotalIncomeRatioMax: number | null;
  creditLimitAmountRequestRatioMin: number | null;
  outstandingLatePaymentsAmountMaxHistoric: number | null;
  outstandingLatePaymentsAmountCurrent: number | null;
  createdAt: string | null;
  updatedAt: string | null;
  numberPaymentsOptions: INumberPaymentsOption[];
  housingTenureOptions: IHousingTenureOption[];
  accessories: IAccessory[];
  stateRestrictions: IStateRestriction[];
  documents: IDocument[];
}

interface IProductDetailsTableProps {
  productDetails: IProductDetails;
}

interface IProductDetailsViewProps {
  productId: number;
  productDetails: IProductDetails;
  fetchBankProductDetails: Function;
  history: RouteComponentProps['history'];
}

const ProductDetailsTable: React.FunctionComponent<IProductDetailsTableProps> = ({
  productDetails,
}) => {
  const headers = [
    [
      'ID PRODUCTO',
      'NOMBRE INTERNO',
      'NOMBRE EXTERNO',
      'TIPO',
      'FECHA DE ALTA',
      'FECHA DE VIGENCIA',
    ],
    [
      'DÍAS DE OFERTA',
      'MONTO MÍNIMO',
      'MONTO MÁXIMO',
      'TASA DE INTERÉS',
      'COMISIÓN POR APERTURA',
      'OTRA COMISIÓN',
    ],
    [
      'MONTO ACCESORIOS',
      'TIPO AMORTIZACIÓN',
      'COMISIÓN PARA COMERCIO',
      '% ENGANCHE MÍNIMO',
      'MÍNIMO VALOR DE UNIDAD',
      'MÁXIMO VALOR DE UNIDAD',
    ],
    [
      'CONDICIÓN DE UNIDAD',
      'ANTIGUEDAD MÁXIMA DE UNIDAD',
      'EDAD MÍNIMA',
      'EDAD MÁXIMA',
      'MÍNIMO GRADO DE ESTUDIOS',
      'MÁXIMO NÚMERO DE DEPENDIENTES',
    ],
    [
      'APLICA COACREDITADO',
      'EDAD MÍNIMA COACREDITADO',
      'EDAD MÁXIMA COACREDITADO',
      'RAZÓN MÁXIMA DE RENTA/INGRESOS',
      'MESES DE ANTIGUEDAD EN DOMICILIO',
      'SITUACIÓN LABORAL',
    ],
    [
      'MESES DE ANTIGUEDAD LABORAL',
      'INGRESOS TOTALES MÍNIMOS',
      'FACTOR DE CASTIGO A INGRESOS',
      'APLICA FICO SCORE',
      'MÍNIMO FICO SCORE',
      'NIVEL MÁXIMO DE ENDEUDAMIENTO',
    ],
    [
      'RAZÓN MÁXIMA DE PAGO/INGRESOS',
      'RAZÓN MÍNIMA DE HISTORIAL/SOLICITADO',
      'ATRASO MÁXIMO ACTUAL',
      'ATRASO MÁXIMO HISTÓRICO',
      'FECHA DE MODIFICACIÓN',
    ],
  ];

  const createdAt = isNil(productDetails.createdAt)
    ? 'No disponible'
    : format(new Date(productDetails.createdAt), 'yyyy-MM-dd');

  const updatedAt =
    isNil(productDetails.updatedAt) ||
    productDetails.updatedAt === productDetails.createdAt
      ? 'Sin modificar'
      : format(new Date(productDetails.updatedAt), 'yyyy-MM-dd');

  const fee =
    productDetails.feeType === 'cash'
      ? f.currency(productDetails.fee)
      : f.percentage(productDetails.fee * 100);

  const otherCommission = () => {
    if (
      isNil(productDetails.otherCommissionType) ||
      isNil(productDetails.otherCommission)
    ) {
      return 'Sin otra comisión';
    } else if (productDetails.otherCommissionType === 'amount') {
      return f.currency(productDetails.otherCommission);
    } else {
      return f.percentage(productDetails.otherCommission * 100);
    }
  };

  const promoterCommission = () => {
    if (productDetails.promoterCommissionType === 'amount') {
      return f.currency(productDetails.promoterCommission);
    } else {
      return f.percentage(productDetails.promoterCommission * 100);
    }
  };

  const economicDependentsMax = isNil(productDetails.economicDependentsMax)
    ? 'Sin máximo'
    : `${productDetails.economicDependentsMax}`;

  const cosigner = productDetails.cosigner ? 'Sí' : 'No';
  const cosignerAgeMin = isNil(productDetails.cosignerAgeMin)
    ? 'N/A'
    : `${productDetails.cosignerAgeMin}`;
  const cosignerAgeMax = isNil(productDetails.cosignerAgeMax)
    ? 'N/A'
    : `${productDetails.cosignerAgeMax}`;

  const housingExpensesMax = isNil(productDetails.housingExpensesMax)
    ? 'N/A'
    : `${productDetails.housingExpensesMax}`;

  const monthsInResidenceMin = isNil(productDetails.monthsInResidenceMin)
    ? 'Sin mínimo'
    : `${productDetails.monthsInResidenceMin} meses`;

  const monthsInEmploymentMin = isNil(productDetails.monthsInEmploymentMin)
    ? 'Sin mínimo'
    : `${productDetails.monthsInEmploymentMin} meses`;

  const ficoScoreApplies = isNil(productDetails.ficoScoreMin) ? 'No' : 'Sí';

  const ficoScoreMin = isNil(productDetails.ficoScoreMin)
    ? 'N/A'
    : `${productDetails.ficoScoreMin}`;

  const indebtednessLevelMax = isNil(productDetails.indebtednessLevelMax)
    ? 'Sin máximo'
    : `${productDetails.indebtednessLevelMax.toFixed(2)}`;

  const monthlyEstimatedPaymentTotalIncomeRatioMax = isNil(
    productDetails.monthlyEstimatedPaymentTotalIncomeRatioMax
  )
    ? 'N/A'
    : `${productDetails.monthlyEstimatedPaymentTotalIncomeRatioMax.toFixed(2)}`;

  const creditLimitAmountRequestRatioMin = isNil(
    productDetails.creditLimitAmountRequestRatioMin
  )
    ? 'N/A'
    : `${productDetails.creditLimitAmountRequestRatioMin.toFixed(2)}`;

  const outstandingLatePaymentsAmountCurrent = isNil(
    productDetails.outstandingLatePaymentsAmountCurrent
  )
    ? 'N/A'
    : f.currency(productDetails.outstandingLatePaymentsAmountCurrent);

  const outstandingLatePaymentsAmountMaxHistoric = isNil(
    productDetails.outstandingLatePaymentsAmountMaxHistoric
  )
    ? 'N/A'
    : f.currency(productDetails.outstandingLatePaymentsAmountMaxHistoric);

  const values = [
    [
      `${productDetails.id}`,
      productDetails.internalName,
      productDetails.commercialName,
      grammar[productDetails.productType],
      createdAt,
      productDetails.validThrough,
    ],
    [
      `${productDetails.extensionDays} días`,
      f.currency(productDetails.creditAmountMin),
      f.currency(productDetails.creditAmountMax),
      `${f.percentage(productDetails.interestRate * 100)} (${
        grammar[productDetails.interestRateType]
      })`,
      fee,
      otherCommission(),
    ],
    [
      f.currency(productDetails.accessoriesAmount),
      grammar[productDetails.amortizationType],
      promoterCommission(),
      f.percentage(productDetails.downpaymentMin * 100),
      f.currency(productDetails.vehicleValueMin),
      f.currency(productDetails.vehicleValueMax),
    ],
    [
      grammar[productDetails.vehicleCondition],
      `${productDetails.vehicleAgeMax} años`,
      `${productDetails.ageMin} años`,
      `${productDetails.ageMax} años`,
      grammar[productDetails.educationLevelMin],
      economicDependentsMax,
    ],
    [
      cosigner,
      cosignerAgeMin,
      cosignerAgeMax,
      housingExpensesMax,
      monthsInResidenceMin,
      grammar[productDetails.employmentType],
    ],
    [
      monthsInEmploymentMin,
      f.currency(productDetails.incomeMin),
      `${productDetails.incomeFactor.toFixed(2)}`,
      ficoScoreApplies,
      ficoScoreMin,
      indebtednessLevelMax,
    ],
    [
      monthlyEstimatedPaymentTotalIncomeRatioMax,
      creditLimitAmountRequestRatioMin,
      outstandingLatePaymentsAmountCurrent,
      outstandingLatePaymentsAmountMaxHistoric,
      updatedAt,
    ],
  ];
  return (
    <MultiSingleRowTable
      headersArray={headers}
      valuesArray={values}
      gridColumns={'repeat(6, 1fr)'}
    />
  );
};

const OtherVariablesTable: React.FunctionComponent<IProductDetailsTableProps> = ({
  productDetails,
}) => {
  const headers = [
    ['PLAZOS DISPONIBLES'],
    ['DOMICILIOS APLICABLES'],
    ['ACCESORIOS OBLIGATORIOS'],
    ['ENTIDADES DE EXCLUSIÓN'],
  ];

  const numberPayments = isEmpty(productDetails.numberPaymentsOptions)
    ? ['N/A']
    : [
        `${productDetails.numberPaymentsOptions
          .map(option => option.numberPayments)
          .join(', ')} meses`,
      ];
  const housingTenure = isEmpty(productDetails.housingTenureOptions)
    ? ['N/A']
    : productDetails.housingTenureOptions.map(
        option => housingOptions[option.housingTenureType]
      );
  const accessories = isEmpty(productDetails.accessories)
    ? ['N/A']
    : productDetails.accessories.map(option => option.name);
  const states = isEmpty(productDetails.stateRestrictions)
    ? ['N/A']
    : productDetails.stateRestrictions.map(option => option.state);

  const values = [numberPayments, housingTenure, accessories, states];
  return (
    <MultiSingleRowTable
      headersArray={headers}
      valuesArray={values}
      gridColumns={'repeat(5, 1fr)'}
    />
  );
};

const DocumentsTable: React.FunctionComponent<IProductDetailsTableProps> = ({
  productDetails,
}) => {
  const documentName = (value: string) => (
    <DocumentName>
      <Input disabled={true} value={value} label={'Nombre'} />
    </DocumentName>
  );

  const documentDescription = (value: string) => (
    <DocumentDescription>
      <Input disabled={true} value={value} label={'Descripción'} />
    </DocumentDescription>
  );

  const documentEntry = (name: string, description: string, key: number) => (
    <DocumentContainer key={key}>
      {documentName(name)}
      {documentDescription(description)}
    </DocumentContainer>
  );

  const documentHeader = (title: string) => (
    <Fragment>
      <Border />
      <DocumentHeader>{title}</DocumentHeader>
      <Border />
    </Fragment>
  );

  const documentStages = [
    'analysis',
    'approval',
    'pre-formalization',
    'formalization',
  ];
  const documentsCategorized = documentStages.map(stage => {
    return {
      stage,
      documents: productDetails.documents.filter(
        document => document.stage === stage
      ),
    };
  });

  const documentSections = documentsCategorized.map((category, i) => (
    <Fragment key={i}>
      {documentHeader(`${documentStagesOptions[category.stage]}`)}
      <DocumentEntriesContainer>
        {category.documents.map((document, i) =>
          documentEntry(document.name, document.description, i)
        )}
      </DocumentEntriesContainer>
    </Fragment>
  ));

  return <Fragment>{documentSections}</Fragment>;
};

const ProductDetailsView: React.FunctionComponent<IProductDetailsViewProps> = ({
  productId,
  productDetails,
  fetchBankProductDetails,
  history,
}) => {
  useEffect(() => {
    fetchBankProductDetails({ productId });
  }, [fetchBankProductDetails, productId]);

  const documentsCardHeight = isNil(productDetails)
    ? '0px'
    : `${208 + 72 * productDetails.documents.length}px`;

  const content = isNil(productDetails) ? (
    <LoadingState>
      <LoadingIndicator />
    </LoadingState>
  ) : (
    <Fragment>
      <CardMargin>
        <ExpandableCard
          title={`Producto ${productDetails.internalName}`}
          status={productDetails.active ? 'Activo' : 'Inactivo'}
          height={'672px'}
        >
          <ProductDetailsTable productDetails={productDetails} />
        </ExpandableCard>
      </CardMargin>
      <CardMargin>
        <ExpandableCard title={'Otras variables'} height={'384px'}>
          <OtherVariablesTable productDetails={productDetails} />
        </ExpandableCard>
      </CardMargin>
      <CardMargin>
        <ExpandableCard title={'Documentos'} height={documentsCardHeight}>
          <DocumentsTable productDetails={productDetails} />
        </ExpandableCard>
      </CardMargin>
    </Fragment>
  );

  return (
    <Fragment>
      <BackButtonContainer>
        <Button onClick={() => history.push('/products')} variant={'secondary'}>
          Regresar
        </Button>
      </BackButtonContainer>
      {content}
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  productDetails: state.entities.automaticProducts.productDetails,
});

const creators = {
  fetchBankProductDetails:
    automaticProductsActions.creators.fetchBankProductDetails.request,
};

export default connect(mapStateToProps, creators)(ProductDetailsView);
