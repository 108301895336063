import isEmpty from 'ramda/src/isEmpty';
import length from 'ramda/src/length';
import not from 'ramda/src/not';
import prop from 'ramda/src/prop';
import values from 'ramda/src/values';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import finalOffersActions from '../../actions/finalOffers';
import offerActions from '../../actions/offers';
import colors from '../../constants/colors';
import reducerUtils from '../../reducers/utils';
import { noop } from '../../utils/misc';
import {
  isBankAnalyst,
  isBankLegal,
  isBankUser,
  isCreditusUser,
  isPromoterUser,
} from '../../utils/permissions';
import Border from '../Border';
import Button from '../Button';
import Card from '../Card';
import ConfirmationDialogButton from '../ConfirmationDialogButton';
import DocumentDetail from '../DocumentDetail';
import ExpandIndicator from '../ExpandIndicator';
import FileUploadModal from '../FileUploadModal';
import FormalizationDateReview from '../FormalizationDateReview';
import FormalizationDateUpload from '../FormalizationDateUpload';
import ReleaseUnitModal from '../ReleaseUnitModal';
import withUserData from '../UserDataHOC';
import { getMode, getStagesConfig } from './offerDocumentsConfig';

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`;

const Container = styled.div`
  width: 65%;
`;

const Content = styled.div`
  background-color: white;
`;

const ExpandIndicatorContainer = styled.div`
  margin-left: 16px;
  margin-right: 8px;
`;

const ExternalContainer = styled.div`
  background-color: ${colors.WHITE};
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 ${colors.BORDER};
  margin-top: 8px;
`;

const Section = styled.div`
  height: ${({ expanded, height }) => (expanded ? height : '32px')};
  overflow: ${prop('overflow')};
  transition: height ease 0.75s;
`;

const TitleContainer = styled.div`
  align-items: center;
  color: ${colors.DUSK50};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 32px;
  line-height: 1.5;
  text-transform: uppercase;
`;

const Header = styled.div`
  color: ${colors.DUSK50};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  width: ${prop('width')};
`;

const ReviewModeSpecialHeader = styled(Header)`
  padding-left: 44px;
`;

const HeadersContainer = styled.div`
  align-items: center;
  display: flex;
  height: 36px;
  padding-left: 36px;
  text-transform: uppercase;
  width: 100%;
`;

const Headers = ({ mode }) =>
  mode === 'upload' ? (
    <HeadersContainer>
      <Header width={'30%'}>Nombre</Header>
      <Header width={'35%'}>Descripción</Header>
      <Header width={'20%'}>Estatus</Header>
      <Header width={'15%'}>Acciones</Header>
    </HeadersContainer>
  ) : (
    <HeadersContainer>
      <Header width={'30%'}>Nombre</Header>
      <Header width={'35%'}>Descripción</Header>
      <ReviewModeSpecialHeader width={'35%'}>Estatus</ReviewModeSpecialHeader>
    </HeadersContainer>
  );

class SectionComponent extends Component {
  state = {
    overflow: 'hidden',
  };

  getFormalizationDateComponent(mode) {
    const { offer, stage } = this.props;

    if (not(offer.finalOffer) || stage !== 'approval') {
      return null;
    }

    return mode === 'review' ? (
      <FormalizationDateReview
        finalOffer={offer.finalOffer}
        setSectionOverflow={this.setOverflow}
      />
    ) : (
      <FormalizationDateUpload
        finalOffer={offer.finalOffer}
        setSectionOverflow={this.setOverflow}
      />
    );
  }

  setOverflow = overflow => {
    this.setState(() => ({ overflow }));
  };

  render() {
    const { overflow } = this.state;
    const {
      documents,
      expandedSection,
      handleClickOnSection,
      stage,
      offer,
      text,
      userData,
    } = this.props;

    const mode = getMode(userData, stage);

    const documentList = values(documents)
      .filter(doc => doc.stage === stage)
      .map((doc, i) => (
        <Fragment key={i}>
          <DocumentDetail
            key={i}
            documentId={doc.id}
            mode={mode}
            setSectionOverflow={this.setOverflow}
          />
          {i !== documentCount - 1 && <Border />}
        </Fragment>
      ));

    const disabled = (() => {
      if (stage === 'release' || offer.stage === 'granted') {
        return offer.stage !== 'release' && offer.stage !== 'granted';
      } else {
        return isEmpty(documentList);
      }
    })();

    const documentCount =
      stage === 'approval' ? length(documentList) + 1 : length(documentList);

    const contentHeight = 70 + documentCount * 68 + (documentCount - 1);

    const formalizationDateDetail = this.getFormalizationDateComponent(mode);

    return (
      <Section
        expanded={expandedSection}
        height={`${contentHeight}px`}
        overflow={overflow}
      >
        <TitleContainer onClick={disabled ? () => {} : handleClickOnSection}>
          <ExpandIndicatorContainer>
            <ExpandIndicator disabled={disabled} expanded={expandedSection} />
          </ExpandIndicatorContainer>
          {text}
        </TitleContainer>
        <Border />
        <Headers mode={mode} />
        <Border />
        <Content>{documentList}</Content>
        {formalizationDateDetail}
      </Section>
    );
  }
}

const mapOfferStageToSectionIndex = (offer, userData) =>
  getStagesConfig(userData).findIndex(({ stage }) => stage === offer.stage);

class OfferFiles extends Component {
  constructor(props) {
    super(props);

    const { offer, userData } = this.props;

    let expandedSection = mapOfferStageToSectionIndex(offer, userData);

    if (expandedSection === -1) {
      expandedSection = 0;
    }

    this.state = {
      expandedSection,
      offerStage: offer.stage,
      openReleaseUnitModal: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { offerStage } = state;
    const { offer, userData } = props;

    if (offerStage !== offer.stage) {
      let expandedSection = mapOfferStageToSectionIndex(offer, userData);

      return {
        expandedSection,
        offerStage: offer.stage,
      };
    }

    return state;
  }

  componentDidMount() {
    const { fetchOfferDocuments, offer } = this.props;

    fetchOfferDocuments({ offerId: offer.id });
  }

  handleClickOnSection = index => () => {
    this.setState(oldState => ({
      expandedSection: oldState.expandedSection === index ? null : index,
    }));
  };

  renderFinalOfferButton() {
    const { documents, offer, userData } = this.props;

    const shouldButtonAppear =
      isBankUser(userData) && not(isBankLegal(userData));

    const analysisDocs = values(documents).filter(
      doc => doc.stage === 'analysis'
    );
    const approvedDocs = analysisDocs.filter(doc => doc.status === 'approved');

    if (
      shouldButtonAppear &&
      length(analysisDocs) &&
      length(analysisDocs) === length(approvedDocs)
    ) {
      return (
        <Link to={`/pipeline/offer/${offer.id}/approbation`}>
          <Button variant="primary">Crear aprobación</Button>
        </Link>
      );
    }
  }

  renderCreateFormalizationDocsButton() {
    const { documents, offer, userData } = this.props;
    const { formalizationDateStatus } = offer.finalOffer;

    const shouldButtonAppear =
      isBankUser(userData) && not(isBankAnalyst(userData));

    const approvalDocs = values(documents).filter(
      doc => doc.stage === 'approval'
    );

    const approvedDocs = approvalDocs.filter(doc => doc.status === 'approved');

    if (
      shouldButtonAppear &&
      length(approvalDocs) === length(approvedDocs) &&
      formalizationDateStatus === 'approved'
    ) {
      return (
        <Link to={`/pipeline/offer/${offer.id}/formalization-documents`}>
          <Button>Crear documentos de formalización</Button>
        </Link>
      );
    }

    return null;
  }

  renderFormalizationButton() {
    const { documents, moveOfferToFormalization, offer, userData } = this.props;

    const shouldButtonAppear = not(isBankUser(userData));

    const preformalizationDocs = values(documents).filter(
      doc => doc.stage === 'pre-formalization'
    );

    const approvedDocs = preformalizationDocs.filter(
      doc => doc.status === 'approved'
    );

    if (
      shouldButtonAppear &&
      length(preformalizationDocs) &&
      length(preformalizationDocs) === length(approvedDocs)
    ) {
      return (
        <ConfirmationDialogButton
          buttonText="Pasar a digitalización"
          dialogRender={() =>
            'Para continuar necesitarás contar con la documentación de formalización firmada por el cliente, ¿estás seguro de que deseas continuar?'
          }
          dialogTitle="Pasar a digitalización"
          onDialogCancel={noop}
          onDialogConfirm={() =>
            moveOfferToFormalization({ offerId: offer.id })
          }
          isReadOnly={isCreditusUser(userData)}
        />
      );
    }

    return null;
  }

  renderReleaseButton() {
    const { documents, userData, offer } = this.props;

    const shouldButtonAppear =
      isBankUser(userData) && not(isBankAnalyst(userData));

    const formalizationDocs = values(documents).filter(
      doc => doc.stage === 'formalization'
    );

    const approvedDocs = formalizationDocs.filter(
      doc => doc.status === 'approved'
    );

    if (
      shouldButtonAppear &&
      length(formalizationDocs) &&
      length(formalizationDocs) === length(approvedDocs)
    ) {
      return (
        <Fragment>
          <Button
            onClick={() =>
              this.setState(oldState => ({
                ...oldState,
                openReleaseUnitModal: true,
              }))
            }
          >
            Liberar Producto
          </Button>
          <ReleaseUnitModal
            showModal={this.state.openReleaseUnitModal}
            closeModal={() =>
              this.setState(oldState => ({
                ...oldState,
                openReleaseUnitModal: false,
              }))
            }
            offerId={offer.id}
          />
        </Fragment>
      );
    }

    return null;
  }

  renderGranterButton() {
    const { userData, offer, updateGrantedDate, documents } = this.props;

    const releaseDocs = values(documents).filter(
      doc => doc.stage === 'release'
    );

    const approvedDocs = releaseDocs.filter(doc => doc.status === 'approved');

    const shouldButtonAppear = isPromoterUser(userData);

    if (
      shouldButtonAppear &&
      length(releaseDocs) &&
      length(releaseDocs) === length(approvedDocs)
    ) {
      return (
        <ConfirmationDialogButton
          buttonText="Producto entregado"
          dialogRender={() =>
            'Al confirmar la entrega del producto se comunicará al otorgante que se cumplió con el proceso de salida del producto y que el producto financiero relacionado fue efectivamente dispuesto por el titular en las condiciones pactadas, ¿Estás seguro que deseas continuar?'
          }
          dialogTitle="Producto entregado"
          onDialogCancel={noop}
          onDialogConfirm={() =>
            updateGrantedDate({
              offerId: offer.finalOffer.offerId,
            })
          }
        />
      );
    }
  }

  renderActionButton() {
    const { offer } = this.props;

    switch (offer.stage) {
      case 'analysis':
        return this.renderFinalOfferButton();
      case 'approval':
        return this.renderCreateFormalizationDocsButton();
      case 'pre-formalization':
        return this.renderFormalizationButton();
      case 'formalization':
        return this.renderReleaseButton();
      case 'release':
        return this.renderGranterButton();
      default:
        return null;
    }
  }

  render() {
    const { expandedSection } = this.state;
    const { documents, offer, userData } = this.props;

    const stages = getStagesConfig(userData).map(({ stage, text }, i) => (
      <SectionComponent
        key={i}
        documents={documents}
        expandedSection={expandedSection === i}
        handleClickOnSection={this.handleClickOnSection(i)}
        offer={offer}
        stage={stage}
        text={text}
        userData={userData}
      />
    ));

    return (
      <Container>
        <Card controls={() => null} header={'Documentos'}>
          {stages[0]}
        </Card>
        {stages
          .filter((stage, i) => i !== 0)
          .map((stageComponent, i) => (
            <ExternalContainer key={i}>{stageComponent}</ExternalContainer>
          ))}
        <ActionButtonContainer>
          {this.renderActionButton()}
        </ActionButtonContainer>
        <FileUploadModal />
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { offerDocuments } = state.entities;
  const { id: offerId } = ownProps.offer;

  return {
    documents: reducerUtils.buildDictWithKey(
      values(offerDocuments).filter(doc => doc.offerId === offerId),
      'id'
    ),
  };
};

const creators = {
  fetchOfferDocuments:
    offerActions.creators.fetchOfferRequiredDocuments.request,
  moveOfferToFormalization:
    offerActions.creators.moveOfferToFormalization.request,
  updateGrantedDate: finalOffersActions.creators.updateGrantedDate.request,
};

export default withUserData(connect(mapStateToProps, creators)(OfferFiles));
