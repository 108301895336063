const thirdPartiesSectionError = {
  cosigner: {
    name: { required: 'El nombre es un campo obligatorio.' },
    firstSurname: {
      required: 'El apellido paterno es un campo obligatorio.',
    },
    secondSurname: {
      required: 'El apellido materno es un campo obligatorio.',
    },
    dateOfBirth: {
      typeError: 'La fecha de nacimiento no es válida.',
      required: 'La fecha de nacimiento es un campo obligatorio.',
      test: 'El coacreditado debe ser mayor de edad.',
      testBigger: 'La fecha de nacimiento no es válida.',
    },
    sex: {
      required: 'El sexo es un campo obligatorio.',
    },
    countryOfBirth: {
      required: 'El país de nacimiento es un campo obligatorio.',
    },
    nationality: {
      required: 'La nacionalidad es un campo obligatorio.',
    },
    stateOfBirth: {
      required: 'El estado de nacimiento es un campo obligatorio.',
    },
    curp: { required: 'Faltan campos para generar el CURP.' },
    taxPayerId: { required: 'Faltan campos para generar el RFC.' },
    taxPayerIdComplement: { matches: 'Homoclave no válida.' },

    educationLevel: {
      required: 'El grado de estudios es un campo obligatorio.',
    },
    maritalStatus: {
      required: 'El estado civil es un campo obligatorio.',
    },
    matrimonialRegime: {
      required: 'El régimen matrimonial es un campo obligatorio.',
    },
  },
  spouse: {
    name: { required: 'El nombre es un campo obligatorio.' },
    firstSurname: {
      required: 'El apellido paterno es un campo obligatorio.',
    },
    secondSurname: {
      required: 'El apellido materno es un campo obligatorio.',
    },
    dateOfBirth: {
      typeError: 'La fecha de nacimiento no es válida.',
      test: 'El cónyugue tiene que ser mayor de edad.',
      testBigger: 'La fecha de nacimiento no es válida.',
      required: 'La fecha de nacimiento es un campo obligatorio.',
    },
    sex: { required: 'El sexo es un campo obligatorio.' },
    countryOfBirth: {
      required: 'El país de nacimiento es un campo obligatorio.',
    },
    nationality: {
      required: 'La nacionalidad es un campo obligatorio.',
    },
    stateOfBirth: {
      required: 'El estado de nacimiento es un campo obligatorio.',
    },
    curp: { required: 'Faltan campos para generar el CURP.' },
    taxPayerId: { required: 'Faltan campos para generar el RFC.' },
    taxPayerIdComplement: { matches: 'Homoclave no válida.' },
    educationLevel: {
      required: 'El grado de estudios es un campo obligatorio.',
    },
  },
};

export default thirdPartiesSectionError;
