import isNil from 'ramda/src/isNil';
import { Effect, call, select, takeLatest } from 'redux-saga/effects';

import invoicesActions from '../actions/invoices';
import {
  isBankUser,
  isCreditusUser,
  isPromoterUser,
} from '../utils/permissions';
import runner from './runners';

interface IFetchInvoicePayload {
  invoiceId: number | string;
}

function* fetchInvoice(
  action: IReduxAction<IFetchInvoicePayload>
): Iterator<Effect> {
  const { invoiceId } = action.payload;

  yield call(runner, invoicesActions.creators.fetchInvoice, {
    method: 'GET',
    url: `/api/invoices/${invoiceId}`,
  });
}

interface IFetchInvoiceCommissionsPayload {
  invoiceId: number;
}

function* fetchInvoiceCommission(
  action: IReduxAction<IFetchInvoiceCommissionsPayload>
): Iterator<Effect> {
  yield call(runner, invoicesActions.creators.fetchInvoiceCommissions, {
    method: 'GET',
    url: `/api/invoices/${action.payload.invoiceId}/commissions`,
  });
}

interface IFetchInvoicesPayload {
  startDate?: Date;
  endDate?: Date;
  type?: 'payable' | 'receivable';
}

function* fetchInvoices(action: IReduxAction<IFetchInvoicesPayload>) {
  const { userData }: { userData: IUserData } = yield select(
    (state: any) => state.auth
  );

  const { type } = action.payload;

  const endpoint = (() => {
    if (isBankUser(userData)) {
      return `/api/invoices/banks/${userData.organization.bank.id}`;
    }

    if (isCreditusUser(userData)) {
      return `/api/invoices/creditus/${type}`;
    }

    if (isPromoterUser(userData)) {
      return `/api/invoices/promoters/${userData.organization.promoter.id}`;
    }
  })();

  if (isNil(endpoint)) {
    throw new Error('no endpoint or creator available for the action');
  }

  yield call(runner, invoicesActions.creators.fetchInvoices, {
    method: 'GET',
    url: endpoint,
    params: {
      ...action.payload,
      type: isCreditusUser(userData) ? undefined : action.payload.type,
    },
  });
}

interface IUpdateInvoiceDocumentStatus {
  invoiceDocumentId: number;
  invoiceId: number;
  status: DocumentStatus;
}

function* updateInvoiceDocumentStatus(
  action: IReduxAction<IUpdateInvoiceDocumentStatus>
): Iterator<Effect> {
  const { invoiceDocumentId, invoiceId, status } = action.payload;

  yield call(runner, invoicesActions.creators.updateInvoiceDocumentStatus, {
    payload: { status },
    method: 'PATCH',
    url: `api/invoices/${invoiceId}/document/${invoiceDocumentId}/status`,
  });

  yield call(fetchInvoice, { type: '', payload: { invoiceId } });
}

interface IUploadInvoiceDocumentPayload {
  file: File;
  invoiceDocumentId: number;
  invoiceId: number;
}

function* uploadInvoiceDocumentFile(
  action: IReduxAction<IUploadInvoiceDocumentPayload>
): Iterator<Effect> {
  const { file, invoiceDocumentId, invoiceId } = action.payload;

  const payload = new FormData();
  payload.append('file', file);

  yield call(runner, invoicesActions.creators.uploadInvoiceDocumentFile, {
    payload,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    url: `/api/invoices/${invoiceId}/document/${invoiceDocumentId}/file`,
  });

  yield call(fetchInvoice, { type: '', payload: { invoiceId } });
}

function* watchFetchInvoiceRequested(): Iterator<Effect> {
  yield takeLatest(invoicesActions.types.INVOICE_FETCH.REQUESTED, fetchInvoice);
}

function* watchFetchInvoiceCommissionsRequested(): Iterator<Effect> {
  yield takeLatest(
    invoicesActions.types.INVOICE_COMMISSIONS_FETCH.REQUESTED,
    fetchInvoiceCommission
  );
}

function* watchFetchInvoicesRequested(): Iterator<Effect> {
  yield takeLatest(
    invoicesActions.types.INVOICES_FETCH.REQUESTED,
    fetchInvoices
  );
}

function* watchUpdateInvoiceDocumentStatusRequested(): Iterator<Effect> {
  yield takeLatest(
    invoicesActions.types.INVOICE_DOCUMENT_STATUS_UPDATE.REQUESTED,
    updateInvoiceDocumentStatus
  );
}

function* watchUploadInvoiceDocumentFileRequested(): Iterator<Effect> {
  yield takeLatest(
    invoicesActions.types.INVOICE_DOCUMENT_FILE_UPLOAD.REQUESTED,
    uploadInvoiceDocumentFile
  );
}

export default {
  watchFetchInvoiceRequested,
  watchFetchInvoiceCommissionsRequested,
  watchFetchInvoicesRequested,
  watchUpdateInvoiceDocumentStatusRequested,
  watchUploadInvoiceDocumentFileRequested,
};
