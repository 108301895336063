import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 20 20';

const TrendIcon = ({ fill }: { fill: string }) => (
  <path
    d="M8.432 5.836l3.908.11c1.06.03 1.913.883 1.943 1.943l.11 3.908a1.001 1.001 0 0 1-2 .057l-.038-1.372a.5.5 0 0 0-.854-.34l-3.785 3.785a1 1 0 0 1-1.414-1.414l3.785-3.785a.5.5 0 0 0-.34-.853l-1.372-.039a1.001 1.001 0 0 1 .057-2M17 18H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1M0 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2"
    fill={fill}
    fillRule="evenodd"
  />
);

export default BaseIcon(TrendIcon, viewBox);
