import React, { useState } from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import Border from '../Border';
import ExpandIndicator from '../ExpandIndicator';

const CustomCardContainer = styled.div`
  background-color: ${colors.WHITE};
  padding-bottom: 12px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 ${colors.BORDER};
`;

const ExpandIndicatorContainer = styled.div`
  padding-right: 20px;
`;

const ExpandableContent = styled.div<{ expanded: boolean; height: string }>`
  height: ${({ expanded, height }) => (expanded ? height : '0px')};
  overflow: hidden;
  transition: height ease 0.5s;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
`;

const HeaderBorder = styled.div`
  background-color: ${colors.AQUA};
  height: 1px;
  width: 100%;
`;

const StatusContainer = styled.div`
  margin-left: auto;
`;

const Title = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 18px;
  font-weight: bold;
  line-height: 1.56;
`;

interface IExpandableCard {
  title: string;
  status?: string;
  type?: string;
  height: string;
}

const ExpandableCard: React.FunctionComponent<IExpandableCard> = ({
  children,
  title,
  status,
  type,
  height,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <CustomCardContainer>
      <HeaderContainer onClick={() => setExpanded(!expanded)}>
        <ExpandIndicatorContainer>
          <ExpandIndicator expanded={expanded} />
        </ExpandIndicatorContainer>
        <Title>{title}</Title>
        <StatusContainer>{status}</StatusContainer>
      </HeaderContainer>
      {type === 'secondary' ? <Border /> : <HeaderBorder />}
      <ExpandableContent expanded={expanded} height={height}>
        {children}
      </ExpandableContent>
    </CustomCardContainer>
  );
};

export default ExpandableCard;
