import React from 'react';
import styled from 'styled-components';

import Label from '../Label';
import RadioButton from '../RadioButton';

const LabelContainer = styled.div`
  margin-bottom: 16px;
`;

const Options = styled.div<{ alignment: string; width: string }>`
  display: flex;
  justify-content: ${({ alignment }) =>
    alignment === 'center' ? 'space-evenly' : 'none'};
  flex-direction: ${({ alignment }) =>
    alignment === 'vertical' ? 'column' : 'row'};
  padding-left: ${({ alignment }) => (alignment === 'vertical' ? '5%' : '0px')};
  width: ${({ width }) => width};
`;

const OptionContainer = styled.div`
  align-items: center;
  display: flex;
  margin-right: 24px;
  margin-bottom: 8px;
`;

const OptionText = styled.div`
  font-size: 14px;
  margin-left: 8px;
`;

const OptionPickerContainer = styled.div<{ height: string }>`
  height: ${props => props.height};
`;

interface IOption {
  text: string;
  value: string | boolean;
}

interface IOptionPicker {
  disabled?: boolean;
  label: string;
  onBlur?(): void;
  onChange(selectedOption: IOption): void;
  options: Array<IOption>;
  value: string | boolean;
  alignment?: string;
  width?: string;
  warning?: boolean;
  height?: string;
}

const OptionPicker: React.FunctionComponent<IOptionPicker> = ({
  label,
  onBlur,
  onChange,
  options,
  value,
  disabled = false,
  alignment = 'normal',
  width = 'inherit',
  warning = false,
  height = '88px',
}) => (
  <OptionPickerContainer height={height}>
    <LabelContainer>
      <Label>{label}</Label>
    </LabelContainer>
    <Options alignment={alignment} width={width}>
      {options.map((option, i) => (
        <OptionContainer key={i}>
          <RadioButton
            checked={value === option.value}
            onBlur={onBlur}
            onChange={isChecked => onChange(option)}
            disabled={disabled}
            warning={warning}
          />
          <OptionText>{option.text}</OptionText>
        </OptionContainer>
      ))}
    </Options>
  </OptionPickerContainer>
);

export default OptionPicker;
