import { call, takeLatest } from 'redux-saga/effects';

import clientUsersActions from '../actions/clientUsers';
import history from '../history';
import runner from './runners';

function* fetchClientCreditHistory(
  action: IReduxAction<{ applicationId: number | undefined }>
): Generator {
  const url = action.payload.applicationId
    ? `api/clients/credit-history?applicationId=${action.payload.applicationId}`
    : 'api/clients/credit-history';

  yield call(runner, clientUsersActions.creators.fetchClientCreditHistory, {
    method: 'GET',
    url: url,
  });
}

function* watchFetchClientCreditHistoryRequested(): Generator {
  yield takeLatest(
    clientUsersActions.types.CLIENT_CREDIT_HISTORY_FETCH.REQUESTED,
    fetchClientCreditHistory
  );
}

function* fetchClientApplications(action: IReduxAction<void>): Generator {
  yield call(runner, clientUsersActions.creators.fetchClientApplications, {
    method: 'GET',
    url: 'api/clients/applications',
  });
}

function* watchFetchClientApplications(): Generator {
  yield takeLatest(
    clientUsersActions.types.FETCH_CLIENT_APPLICATIONS.REQUESTED,
    fetchClientApplications
  );
}

function* clientActivateOffer(
  action: IReduxAction<{ offerId: number; applicationId: number }>
): Generator {
  yield call(
    runner,
    clientUsersActions.creators.clientActivateOffer,
    {
      method: 'POST',
      url: 'api/clients/offer',
      payload: action.payload,
    },
    { snakeCase: false }
  );

  yield call(history.push as any, '/offered-applications');
}

function* watchClientActivateOffer(): Generator {
  yield takeLatest(
    clientUsersActions.types.CLIENT_ACTIVATE_OFFER.REQUESTED,
    clientActivateOffer
  );
}

export default {
  watchFetchClientCreditHistoryRequested,
  watchFetchClientApplications,
  watchClientActivateOffer,
};
