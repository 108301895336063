import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import biometricVerification from '../../actions/biometricVerification';
import continueApplicationActions from '../../actions/continueApplication';
import colors from '../../constants/colors';
import idBack from '../../resources/id-back.png';
import idFront from '../../resources/id-front.png';
import selfie from '../../resources/selfie.png';
import { resizeImage } from '../../utils/biometricVerification';
import { addSearchParamsToUrl } from '../../utils/misc';
import Button from '../Button';
import LoadingIndicator from '../LoadingIndicator';

const MobileBiometricVerificationContainer = styled.div`
  width: 95%;
  background-color: ${colors.WHITE};
  color: ${colors.PRIMARY_TEXT};
  font-family: 'Lato', sans-serif;
  margin: 0;
`;

const Border = styled.div`
  background-color: ${colors.BORDER};
  height: 1px;
  width: 100%;
`;

const CapturePhotoContent = styled.div`
  padding-block-end: 2em;
`;

const Title = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 5em;
  padding: 0.6em;
`;

const TitleText = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 0.2em;
`;

const TitleSubText = styled.div`
  color: ${colors.SECONDARY_TEXT};
  text-align: center;
`;

const MainContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2em;
  padding: 0.6em 1em;

  & input {
    display: none;
  }
`;

const FileUploadLabel = styled.label`
  cursor: pointer;
`;

const PhotoContainer = styled.div<{ photo: boolean }>`
  border: 2px solid ${({ photo }) => (photo ? colors.AQUA : colors.BORDER)};
  border-radius: 0.5em;
  padding: 1em;
`;

const PhotoIconContainer = styled.div`
  height: 8em;
  line-height: 8em;
  text-align: center;
  width: 8em;
`;

const PhotoIcon = styled.img`
  max-width: 100%;
  max-height: 100%;
  display: inline-block;
  margin: 0 auto;
  vertical-align: middle;
`;

const PhotoName = styled.div`
  padding-bottom: 0.5em;
  text-align: center;
`;

const InstructionsContainer = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-weight: bold;
  margin-bottom: 1.5em;
  text-align: center;
`;

const LoadingIndicatorContainer = styled.div`
  margin-bottom: 0.5em;
`;

const LoadingStateContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 20em;
  justify-content: center;
  text-align: center;
`;

const VerificationCompletedContainer = styled(LoadingStateContainer)`
  display: flex;
  flex-direction: column;
`;

const VerificationCompletedText = styled.div`
  font-weight: bold;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VerificationCompletedButton = styled.div`
  height: 25%;
`;

interface IGeolocation {
  latitude: number;
  longitude: number;
}

interface IPhotos {
  [key: string]: File;
}

type PhotoTypes = 'id-front' | 'id-back' | 'selfie';

interface IPhotoConfig {
  icon: string;
  name: string;
  type: PhotoTypes;
}

const idConfig: Array<IPhotoConfig> = [
  {
    icon: idFront,
    name: 'INE - Frente',
    type: 'id-front',
  },
  {
    icon: idBack,
    name: 'INE - Reverso',
    type: 'id-back',
  },
  {
    icon: selfie,
    name: 'Selfie',
    type: 'selfie',
  },
];

const CapturePhoto: React.FunctionComponent<{
  icon: string;
  id: string;
  name: string;
  onChange(photo: File): void;
  photo?: File;
}> = ({ icon, id, name, onChange, photo }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target !== null &&
      event.target.files !== null &&
      event.target.files.length
    ) {
      const file = event.target.files[0];
      resizeImage(file, resizedPhoto => onChange(resizedPhoto));
    }
  };

  return (
    <CapturePhotoContent>
      <PhotoName>{name}</PhotoName>
      <input
        id={id}
        type="file"
        accept="image/*;capture=camera"
        onChange={handleInputChange}
      />
      <FileUploadLabel htmlFor={id}>
        <PhotoContainer photo={photo !== undefined}>
          <PhotoIconContainer>
            <PhotoIcon src={icon} />
          </PhotoIconContainer>
        </PhotoContainer>
      </FileUploadLabel>
    </CapturePhotoContent>
  );
};

const LoadingState: React.FunctionComponent = () => {
  return (
    <LoadingStateContainer>
      <LoadingIndicatorContainer>
        <LoadingIndicator />
      </LoadingIndicatorContainer>
      Por favor espera mientras verificamos tu identidad
    </LoadingStateContainer>
  );
};

const getTitleText = (
  loading: boolean,
  verificationCommpleted: boolean
): string => {
  if (loading) {
    return 'Verificación de identidad';
  } else if (verificationCommpleted) {
    return 'Verificación terminada';
  } else {
    return 'Comienza tu verificación';
  }
};

const getSubtitleText = (
  loading: boolean,
  verificationCommpleted: boolean
): string => {
  if (loading) {
    return 'Este proceso puede tardar varios minutos';
  } else if (verificationCommpleted) {
    return 'Continúa con el proceso de creación de cuenta';
  } else {
    return 'El proceso está diseñado para proteger tu identidad';
  }
};

interface IMobileBiometricVerification {
  error: string | null;
  loading: boolean;
  applicationStarted: boolean;
  verificationCompleted: boolean;
  biometricVerificationId: number;
  sendBiometricVerification: Function;
  redirectToApplicationStart: Function;
  history: RouteComponentProps['history'];
}

const MobileBiometricVerification: React.FunctionComponent<IMobileBiometricVerification> = ({
  error,
  loading,
  applicationStarted,
  verificationCompleted,
  biometricVerificationId,
  sendBiometricVerification,
  redirectToApplicationStart,
  history,
}) => {
  const photos = useRef<IPhotos>({});
  const [geolocation, setGeolocation] = useState<IGeolocation | null>(() => {
    if (process.env.NODE_ENV === 'development') {
      return { latitude: 20.720896, longitude: -103.41519 };
    }

    return null;
  });
  // refs don't update the children of an element so we use this function to update it when the photos are modified
  const [, setForceUpdate] = useState(Date.now());

  const onPhotoChange = (photo: File, index: string): void => {
    const newPhotos = { ...photos.current };
    newPhotos[index] = photo;

    photos.current = newPhotos;
    setForceUpdate(Date.now());
  };

  useEffect(() => {
    if (!applicationStarted) {
      redirectToApplicationStart();
    }
  }, [applicationStarted, redirectToApplicationStart]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => setGeolocation(position.coords),
        () => {},
        { timeout: 30000 }
      );
    }
  }, []);

  useEffect(() => {
    const removePhoto = (index: string): void => {
      const newPhotos = { ...photos.current };
      delete newPhotos[index];

      photos.current = newPhotos;
    };

    switch (error) {
      case 'FRONT_ID_PHOTOTO_IS_BW':
        removePhoto(idConfig[0].type);
        break;
      case 'BACK_ID_PHOTO_IS_BW':
        removePhoto(idConfig[1].type);
        break;
      case 'SELFIE_IS_BW':
        removePhoto(idConfig[2].type);
        break;
      case 'FRONT_ID_PHOTO_CONTAINS_NO_ID':
        removePhoto(idConfig[0].type);
        break;
      case 'SELFIE_CONTAINS_NO_VALID_FACE':
        removePhoto(idConfig[2].type);
        break;
      case 'SELFIE_CONTAINS_ID':
        removePhoto(idConfig[2].type);
        break;
      case 'BACK_ID_PHOTO_IS_INVALID':
        removePhoto(idConfig[1].type);
        break;
      case 'SELFIE_AND_ID_TOO_SIMILAR':
        removePhoto(idConfig[2].type);
        break;
      case 'SELFIE_AND_ID_VERY_DIFFERENT':
        removePhoto(idConfig[0].type);
        removePhoto(idConfig[1].type);
        removePhoto(idConfig[2].type);
        break;
      case 'DIFFERENT_SELFIE_TO_PREVIOUS_APPLICATION':
        removePhoto(idConfig[0].type);
        removePhoto(idConfig[1].type);
        removePhoto(idConfig[2].type);
        break;
      case 'BIOMETRIC_VERIFICATION_NOT_FOUND':
        removePhoto(idConfig[0].type);
        removePhoto(idConfig[1].type);
        removePhoto(idConfig[2].type);
        break;
      case 'BIOMETRIC_VERIFICATION_ALREADY_USED':
        removePhoto(idConfig[0].type);
        removePhoto(idConfig[1].type);
        removePhoto(idConfig[2].type);
        break;
    }
    setForceUpdate(Date.now());
  }, [error, photos]);

  const allPhotosTaken =
    Object.keys(photos.current).filter(
      photo => photos.current[photo] !== undefined
    ).length === Object.keys(idConfig).length;

  const onSendButtonClicked = () => {
    if (biometricVerificationId !== null && allPhotosTaken) {
      const data = new FormData();

      idConfig.forEach(photoConfig => {
        data.append('photos[]', photos.current[photoConfig.type]);
        data.append('photo_types[]', photoConfig.type);
      });

      if (geolocation !== null) {
        data.append('lat', `${geolocation.latitude}`);
        data.append('lng', `${geolocation.longitude}`);
      }
      const payload = {
        biometricVerificationId: biometricVerificationId,
        data: data,
      };
      sendBiometricVerification(payload);
    }
  };

  const renderContent = () => {
    if (loading) {
      return <LoadingState />;
    } else if (verificationCompleted) {
      return (
        <VerificationCompletedContainer>
          <VerificationCompletedText>
            Ha concluido el proceso de verificación
          </VerificationCompletedText>
          <VerificationCompletedButton>
            <Button
              onClick={() => {
                history.push(addSearchParamsToUrl('/application/profiling'));
              }}
              variant={'primary'}
            >
              Continuar
            </Button>
          </VerificationCompletedButton>
        </VerificationCompletedContainer>
      );
    } else {
      return (
        <Fragment>
          <InstructionsContainer>
            Captura las siguientes fotografías siguiendo las imágenes como guía
          </InstructionsContainer>
          {idConfig.map(photoConfig => (
            <CapturePhoto
              key={photoConfig.type}
              icon={photoConfig.icon}
              id={photoConfig.type}
              name={photoConfig.name}
              onChange={photo => onPhotoChange(photo, photoConfig.type)}
              photo={photos.current[photoConfig.type]}
            />
          ))}
          <Button
            onClick={onSendButtonClicked}
            variant={allPhotosTaken ? 'primary' : 'inactive'}
          >
            Terminar verificación
          </Button>
        </Fragment>
      );
    }
  };

  return (
    <MobileBiometricVerificationContainer>
      <Border />
      <Title>
        <TitleText>{getTitleText(loading, verificationCompleted)}</TitleText>
        <TitleSubText>
          {getSubtitleText(loading, verificationCompleted)}
        </TitleSubText>
      </Title>
      <Border />
      <MainContent>{renderContent()}</MainContent>
    </MobileBiometricVerificationContainer>
  );
};

const mapStateProps = (
  state: any
): {
  error: string;
  loading: boolean;
  verificationCompleted: boolean;
  biometricVerificationId: number;
} => ({
  error: state.newApplication.biometricVerification.error,
  loading: state.newApplication.biometricVerification.loading,
  verificationCompleted:
    state.newApplication.biometricVerification.verificationCompleted,
  biometricVerificationId:
    state.newApplication.verificationModal.biometricVerificationId,
});

const creators = {
  redirectToApplicationStart:
    continueApplicationActions.creators.redirectToApplicationStart,
  sendBiometricVerification:
    biometricVerification.creators.biometricVerification.request,
};

export default connect(mapStateProps, creators)(MobileBiometricVerification);
