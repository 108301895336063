import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import offerMessagesActions from '../../actions/offerMessages';
import colors from '../../constants/colors';
import useUserData from '../../hooks/useUserData';
import PhoneIcon from '../../resources/icons/PhoneIcon';
import { isBankUser, isPromoterUser } from '../../utils/permissions';
import Card from '../Card';
import MessageBox from '../MessageBox';
import MessagesList from '../MessagesList';

const ChatContainer = styled.div`
  margin-left: 28px;
  width: calc(35% - 28px);
`;

const MissingDocumentsAlert = styled.div`
  text-align: center;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
`;

const ChatComponentsWrapper = styled.div`
  width: 100%;
`;

const PhoneIconContainer = styled.div`
  padding: 5px 10px 0 0;
`;

const BankPhoneNumberTooltip = styled.div`
  position: absolute;
  visibility: hidden;
  left: calc(100% - 118px);
  top: calc(100% - 72px);

  &:after {
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: rgb(38, 42, 62);
    top: 100%;
    left: calc(50% - 4px);
    content: '';
    transform: rotate(45deg);
    margin-top: -4px;
  }
`;

const BankPhoneNumberContainer = styled.div`
  position: relative;

  &:hover ${BankPhoneNumberTooltip} {
    visibility: visible;
    color: #ffffff;
    background-color: rgb(38, 42, 62);
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 14px;
    white-space: nowrap;
    transition: visibility 0.5s, color 0.5s, background-color 0.5s, width 0.5s;
  }
`;

const getOtherChatPartyName = (offer: IOffer, userData: IUserData): string => {
  if (isBankUser(userData)) {
    return (offer as any).request.promoter.name;
  }

  return (offer as any).bank.name;
};

const getBankPhoneNumber = (offer: IOffer): string => {
  const phoneNumber = (offer as any).bank.phone;
  const cleaned = phoneNumber.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '';
};

interface IChatOwnProps {
  offer: IOffer;
}

interface IChatStateProps {
  messages: any[];
}

interface IChatDispatchProps {
  fetchOfferMessages: (payload: { offerId: number }) => void;
  sendMessage: (payload: { offerId: number; content: string }) => void;
}

const Chat: React.FunctionComponent<IChatOwnProps &
  IChatStateProps &
  IChatDispatchProps> = ({
  fetchOfferMessages,
  messages,
  offer,
  sendMessage,
}) => {
  useEffect(() => {
    fetchOfferMessages({ offerId: offer.id });
  }, [fetchOfferMessages, offer.id]);

  const userData = useUserData();

  const otherParty = getOtherChatPartyName(offer, userData);

  const messageBox =
    offer.stage !== 'granted' ? (
      <MessageBox
        offerId={offer.id}
        sendMessage={sendMessage}
        userData={userData}
      />
    ) : null;

  const chatContent =
    offer.stage !== 'activated' ? (
      <Fragment>
        <MessagesList
          messages={messages}
          userId={userData.user.id}
          otherParty={otherParty}
        />
        {messageBox}
      </Fragment>
    ) : (
      <MissingDocumentsAlert>
        El chat con el otorgante se habilitará una vez que se adjunten los
        documentos requeridos.
      </MissingDocumentsAlert>
    );

  const bankPhoneNumber =
    isPromoterUser(userData) && offer.stage !== 'activated' ? (
      <BankPhoneNumberContainer>
        <BankPhoneNumberTooltip>
          {`Teléfono: ${getBankPhoneNumber(offer)}`}
        </BankPhoneNumberTooltip>
        <PhoneIconContainer>
          <PhoneIcon size={39} fill={colors.DUSK} />
        </PhoneIconContainer>
      </BankPhoneNumberContainer>
    ) : null;

  return (
    <ChatContainer>
      <Card header={`Chat - ${otherParty}`} controls={bankPhoneNumber}>
        <ChatComponentsWrapper>{chatContent}</ChatComponentsWrapper>
      </Card>
    </ChatContainer>
  );
};

const mapStateToProps = (state: any, ownProps: IChatOwnProps) => ({
  messages: state.entities.offerMessages[ownProps.offer.id] || [],
});

const creators = {
  fetchOfferMessages: offerMessagesActions.creators.fetchOfferMessages.request,
  sendMessage: offerMessagesActions.creators.createOfferMessage.request,
};

export default connect(mapStateToProps, creators)(Chat);
