import { Field, FieldProps } from 'formik';
import React from 'react';

import RadioLabelsInput from '../RadioLabelsInput';

interface IFormikRadioLabels {
  disabled?: boolean;
  label: string;
  name: string;
  options: { value: number; quantity: number }[];
  isHidden?: boolean;
  warning?: boolean;
}

const FormikRadioInput = ({
  disabled = false,
  label,
  name,
  options,
  isHidden = false,
  warning = false,
}: IFormikRadioLabels) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <RadioLabelsInput
        label={label}
        value={field.value}
        onBlur={() => form.setFieldTouched(field.name)}
        onChange={(option: number) => {
          form.setFieldValue(field.name, option);
        }}
        disabled={disabled}
        options={options}
        isHidden={isHidden}
        warning={warning}
      />
    )}
  </Field>
);

export default FormikRadioInput;
