import {
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
  FormikProps,
} from 'formik';
import not from 'ramda/src/not';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import finalOfferActions from '../../actions/finalOffers';
import colors from '../../constants/colors';
import grammar from '../../constants/grammar';
import { applySelector } from '../../utils/misc';
import Border from '../Border';
import Button from '../Button';
import Card from '../Card';
import FilePickerButton from '../FilePickerButton';
import { FormikRadio, FormikText } from '../FormikInputs';
import Label from '../Label';
import LoadingIndicator from '../LoadingIndicator';

const AddDocumentButtonContainer = styled.div`
  padding-bottom: 20px;
  padding-left: 24px;

  & button {
    font-size: 24px;
    line-height: 1;
    min-width: 40px;
  }
`;

const DeleteButtonContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 19px;
`;

const FormSectionTitle = styled.div`
  align-items: center;
  color: ${colors.DUSK50};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 32px;
  padding-left: 24px;
  text-transform: uppercase;
`;

const Grid = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const ContractRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  padding-left: 24px;
  padding-right: 32px;
  padding-top: 12px;
`;

const ContractType = styled.div`
  width: 170px;
  padding-right: 15px;
`;

const ContractName = styled.div`
  width: 25%;
  padding-right: 15px;
`;

const ContractDesc = styled.div<{ width: number }>`
  width: ${props => props.width}%;
  padding-right: 15px;
`;

const ContractLink = styled.div`
  width: 25.8%;
  padding-right: 15px;
`;

const SelectButtonContainer = styled.div`
  padding-right: 15px;
  margin-top: 19px;
`;

const LabelContainer = styled.div`
  height: 20px;
`;

const LoadingIndicatorContainer = styled.div`
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: center;
  width: 148px;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

interface IAddDocumentButtonProps {
  active: boolean;
  push: (obj: any) => void;
}

interface Document {
  type: string;
  name: string;
  description: string;
  file?: {} | null;
  fileName: string;
}

interface ICreateDocumentsPayload {
  documents: Array<Document>;
  offerId: number;
}

interface IFileInputProps {
  name: string;
}

interface IFormalizationDocumentsFormProps {
  createDocuments: (payload: ICreateDocumentsPayload) => void;
  creatingFormalizationDocuments?: boolean;
  offer: {
    id: number;
    request: {
      type: string;
    };
  };
  templateDocuments: ITemplateDocument[];
}

const documentSchema = Yup.object().shape({
  name: Yup.string().required('Requerido'),
  description: Yup.string().required('Requerido'),
  type: Yup.string().required('Requerido'),
  url: Yup.string().when('type', {
    is: 'digital',
    then: Yup.string()
      .url('EL contenido debe ser una URL')
      .required('La URL del documento es requerida'),
    otherwise: Yup.string().notRequired(),
  }),
  file: Yup.object().when('type', {
    is: 'physical',
    then: Yup.object().nullable(),
    otherwise: Yup.object()
      .nullable()
      .notRequired(),
  }),
  fileName: Yup.string().when('type', {
    is: 'physical',
    then: Yup.string().required('Requerido'),
    otherwise: Yup.string().notRequired(),
  }),
});

const formalizationDocumentsFormSchema = Yup.object().shape({
  documents: Yup.array()
    .of(documentSchema)
    .required(),
});

const emptyDoc = {
  name: '',
  description: '',
  file: null,
  fileName: '',
};

const getInitialFormState = (templateDocuments: ITemplateDocument[]) => ({
  documents: templateDocuments.map(({ name, description }) => ({
    name,
    description,
    file: null,
    fileName: '',
    type: 'physical',
    url: '',
  })),
});

const AddDocumentButton: React.FunctionComponent<IAddDocumentButtonProps> = ({
  active,
  push,
}) => (
  <AddDocumentButtonContainer>
    <Button
      onClick={() => active && push({ ...emptyDoc })}
      variant={active ? 'secondary' : 'inactive'}
    >
      +
    </Button>
  </AddDocumentButtonContainer>
);

const FileInput: React.FunctionComponent<IFileInputProps> = ({ name }) => (
  <Field
    name={name}
    render={({ field, form }: FieldProps) => {
      const { errors, setFieldTouched, setFieldValue, touched } = form;

      const error =
        applySelector(touched, `${name}.fileName`) &&
        applySelector(errors, `${name}.fileName`);

      const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files !== null && e.target.files.length) {
          setFieldValue(`${name}.file`, e.target.files[0]);
          setFieldValue(`${name}.fileName`, e.target.files[0].name);
        }
      };

      return (
        <div>
          <FilePickerButton
            id={name}
            onChange={onChange}
            onClick={() => setFieldTouched(`${name}.fileName`)}
            text="Seleccionar"
          />
          <LabelContainer>
            <Label error>{error}</Label>
          </LabelContainer>
        </div>
      );
    }}
  />
);

const FormalizationDocumentsForm: React.FunctionComponent<IFormalizationDocumentsFormProps> = ({
  createDocuments,
  creatingFormalizationDocuments,
  offer,
  templateDocuments,
}) => {
  const formTitle = `Formalización - Aprobación - ${
    grammar[offer.request.type]
  } #${offer.id}`;

  const renderFormContent = (
    props: FormikProps<{ documents: Array<Document> }>
  ) => {
    const { isValid, values } = props;

    const { documents } = values;
    const submitButtonVariant = isValid ? 'primary' : 'inactive';

    return (
      <Form>
        <Card header={formTitle}>
          <FormSectionTitle>Requisitos de formalización</FormSectionTitle>
          <Border />
          <FieldArray
            name="documents"
            render={({ push, remove }) => (
              <Fragment>
                <Grid>
                  {documents.map((doc, i) => (
                    <ContractRow key={i}>
                      <ContractType>
                        <FormikRadio
                          label="Tipo de contrato"
                          name={`documents[${i}].type`}
                          options={[
                            {
                              text: 'Físico',
                              value: 'physical',
                            },
                            {
                              text: 'Digital',
                              value: 'digital',
                            },
                          ]}
                        />
                      </ContractType>
                      <ContractName>
                        <FormikText
                          label="Nombre"
                          name={`documents[${i}].name`}
                        />
                      </ContractName>
                      {documents[i].type === 'physical' ? (
                        <Fragment>
                          <ContractDesc width={48}>
                            <FormikText
                              label="Descripción"
                              name={`documents[${i}].description`}
                            />
                          </ContractDesc>
                          <SelectButtonContainer>
                            <FileInput name={`documents[${i}]`} />
                          </SelectButtonContainer>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <ContractDesc width={30}>
                            <FormikText
                              label="Descripción"
                              name={`documents[${i}].description`}
                            />
                          </ContractDesc>
                          <ContractLink>
                            <FormikText
                              label="Url del documento"
                              name={`documents[${i}].url`}
                            />
                          </ContractLink>
                        </Fragment>
                      )}
                      <DeleteButtonContainer>
                        <Button variant="destructive" onClick={() => remove(i)}>
                          Eliminar
                        </Button>
                      </DeleteButtonContainer>
                    </ContractRow>
                  ))}
                </Grid>
                <AddDocumentButton
                  active={isValid || not(documents.length)}
                  push={push}
                />
              </Fragment>
            )}
          />
        </Card>
        <SubmitButtonContainer>
          {creatingFormalizationDocuments ? (
            <LoadingIndicatorContainer>
              <LoadingIndicator />
            </LoadingIndicatorContainer>
          ) : (
            <Button type="submit" variant={submitButtonVariant}>
              Enviar documentos
            </Button>
          )}
        </SubmitButtonContainer>
      </Form>
    );
  };

  return (
    <Formik
      initialValues={getInitialFormState(templateDocuments)}
      onSubmit={values =>
        createDocuments({ offerId: offer.id, documents: values.documents })
      }
      render={renderFormContent}
      validationSchema={formalizationDocumentsFormSchema}
    />
  );
};

const mapStateToProps = (state: any) => ({
  creatingFormalizationDocuments: state.loaders.creatingFormalizationDocuments,
});

const creators = {
  createDocuments:
    finalOfferActions.creators.createFormalizationDocuments.request,
};

export default connect(mapStateToProps, creators)(FormalizationDocumentsForm);
