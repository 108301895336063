import React from 'react';
import styled from 'styled-components';

const Strong = styled.strong`
  font-size: 12px;
`;

const JustifiedText = styled.div`
  text-align: justify;
`;

export interface Caption {
  cat: string;
  webPage: string;
}

const FixedInterestCaption = ({ cat, webPage }: Caption) => (
  <JustifiedText>
    Sujeto a aprobación de crédito. La aceptación de esta oferta no implica
    ninguna obligación para el usuario ni para el otorgante.{' '}
    <Strong>*Tasa fija.</Strong> Las comisiones y tasas no incluyen IVA.{' '}
    <Strong>**CAT del: {cat} Sin IVA</Strong> (Costo Anual Total): es el costo
    anual total de financiamiento expresado en términos porcentuales anuales
    que, para fines informativos y de comparación, incorpora la totalidad de los
    costos y gastos inherentes a los créditos. CAT calculado dentro de una
    vigencia de seis meses.{' '}
    <Strong>***Consulta la totalidad de las comisiones</Strong> asociadas al
    producto en el portal del otorgante: <Strong>{webPage}</Strong>. Para
    cualquier información adicional puedes visitar directamente el portal del
    otorgante. <Strong>Advertencias:</Strong> Incumplir tus obligaciones te
    puede generar comisiones e intereses moratorios. Contratar créditos que
    excedan tu capacidad de pago afecta tu historial crediticio.
  </JustifiedText>
);

const VariableInterestCaption = ({ cat, webPage }: Caption) => (
  <JustifiedText>
    Sujeto a aprobación de crédito. La aceptación de esta oferta no implica
    ninguna obligación para el usuario ni para el otorgante.{' '}
    <Strong>*Tasa variable.</Strong> Las comisiones y tasas no incluyen IVA.{' '}
    <Strong>**CAT PROMEDIO del: {cat} Sin IVA</Strong> (Costo Anual Total): es
    el costo anual total de financiamiento expresado en términos porcentuales
    anuales que, para fines informativos y de comparación, incorpora la
    totalidad de los costos y gastos inherentes a los créditos. CAT calculado
    dentro de una vigencia de seis meses.{' '}
    <Strong>*** Consulta la totalidad de las comisiones</Strong> asociadas al
    producto en el portal del otorgante: <Strong>{webPage}</Strong> Para
    cualquier información adicional puedes visitar directamente el portal del
    otorgante. <Strong>Advertencias:</Strong> Al ser tu crédito de tasa
    variable, los intereses pueden aumentar. Incumplir tus obligaciones te puede
    generar comisiones e intereses moratorios. Contratar créditos que excedan tu
    capacidad de pago afecta tu historial crediticio.
  </JustifiedText>
);

const VariableInterestCreditCardCaption = ({ cat, webPage }: Caption) => (
  <JustifiedText>
    Sujeto a aprobación de crédito. La aceptación de esta oferta no implica
    ninguna obligación para el usuario ni para el otorgante.{' '}
    <Strong>*Tasa variable.</Strong> Las comisiones y tasas no incluyen IVA.{' '}
    <Strong>**CAT PROMEDIO del: {cat} Sin IVA</Strong> (Costo Anual Total): es
    el costo anual total de financiamiento expresado en términos porcentuales
    anuales que, para fines informativos y de comparación, incorpora la
    totalidad de los costos y gastos inherentes a los créditos. CAT calculado
    dentro de una vigencia de seis meses.{' '}
    <Strong>*** Consulta la totalidad de las comisiones</Strong> asociadas al
    producto en el portal del otorgante: <Strong>{webPage}</Strong> Para
    cualquier información adicional puedes visitar directamente el portal del
    otorgante. <Strong>Advertencias:</Strong> Al ser tu crédito de tasa
    variable, los intereses pueden aumentar. Incumplir tus obligaciones te puede
    generar comisiones e intereses moratorios. Contratar créditos que excedan tu
    capacidad de pago afecta tu historial crediticio. Realizar sólo el pago
    mínimo aumenta el tiempo de pago y el costo de la deuda.
  </JustifiedText>
);

export {
  FixedInterestCaption,
  VariableInterestCaption,
  VariableInterestCreditCardCaption,
};
