import isEmpty from 'ramda/src/isEmpty';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import colors from '../../constants/colors';
import Label from '../Label';

const LabelContainer = styled.div`
  margin-bottom: 16px;
`;

const Options = styled.div`
  width: 340px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  left: -8%;
`;

const OptionsMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OptionText = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: ${colors.PRIMARY_TEXT};
`;

const LabelText = styled.div`
  font-size: 12px;
  line-height: 24px;
  color: ${colors.PRIMARY_TEXT};
  margin-left: 4px;
`;

const RadioRangeInputContainer = styled.div<{ isHidden: boolean }>`
  height: 300px;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  opacity: ${({ isHidden }) => (isHidden ? '0' : '1')};
  transition: opacity 0.5s ease;
`;

const OptionContainer = styled.div<{
  disabled: boolean;
  isChecked: boolean;
  warning: boolean;
}>`
  margin: 4px 8px 4px 0px;
  width: 160px;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px
    ${({ isChecked, disabled, warning }) => {
      if (isChecked) {
        if (disabled) {
          return colors.DUSK50;
        }
        if (warning) {
          return colors.ORANGE;
        } else {
          return colors.AQUA50;
        }
      } else {
        return '#eee';
      }
    }};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
`;

interface IRadioLabelsInput {
  label: string;
  disabled?: boolean;
  onBlur?(): void;
  onChange(selectedOption: number): void;
  value: number;
  options: { value: number; quantity: number }[];
  isHidden: boolean;
  warning?: boolean;
  deviceData: IDeviceData;
}

interface IOptionButton {
  checked: boolean;
  onChange: (isChecked: boolean) => void;
  onBlur?(): void;
  disabled?: boolean;
  option: string;
  label: string;
  warning: boolean;
}

const OptionButton = ({
  checked,
  onChange,
  disabled = false,
  onBlur,
  option,
  label,
  warning,
}: IOptionButton): JSX.Element => {
  return (
    <OptionContainer disabled={disabled} isChecked={checked} warning={warning}>
      <input
        disabled={disabled}
        checked={checked}
        type="checkbox"
        onChange={e => onChange(e.target.checked)}
        onBlur={onBlur}
      />
      <OptionText>{option}</OptionText>
      <LabelText>{label}</LabelText>
    </OptionContainer>
  );
};

const RadioLabelsInput = ({
  disabled = false,
  onBlur,
  onChange,
  value,
  label,
  options,
  isHidden = false,
  warning = false,
  deviceData,
}: IRadioLabelsInput): JSX.Element => {
  const numberWithCommas = (x: number): string => {
    if (isEmpty(x) || x <= 0) {
      return '--';
    }
    return `$${x.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  };
  const OptionsWrapper = deviceData.isMobile ? OptionsMobile : Options;
  return (
    <RadioRangeInputContainer isHidden={isHidden}>
      <LabelContainer>
        <Label>{label}</Label>
      </LabelContainer>
      <OptionsWrapper>
        {options.map((option, i) => (
          <OptionButton
            key={i}
            checked={value === option.value}
            warning={warning}
            onChange={isChecked => onChange(option.value)}
            onBlur={onBlur}
            disabled={disabled}
            option={`${numberWithCommas(option.quantity)} |`}
            label={`${option.value} meses`}
          />
        ))}
      </OptionsWrapper>
    </RadioRangeInputContainer>
  );
};

const mapStateToProps = (state: any): { deviceData: IDeviceData } => ({
  deviceData: state.deviceData,
});

export default connect(mapStateToProps)(RadioLabelsInput);
