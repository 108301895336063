import isNil from 'ramda/src/isNil';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import automaticProducts from '../actions/automaticProducts';
import toastActions from '../actions/toastNotifications';
import {
  buildErrorToast,
  buildSuccessToast,
} from '../utils/toastNotifications';
import runner from './runners';

function* fetchBankAutomaticProducts() {
  const { userData }: { userData: IBankUserData } = yield select(
    (state: any) => state.auth
  );
  const { bankId } = userData.organization;

  yield call(runner, automaticProducts.creators.fetchBankAutomaticProducts, {
    method: 'GET',
    url: `/api/banks/${bankId}/products`,
  });
}

interface IFetchBankProductDetailsPayload {
  productId: number;
}

function* fetchBankProductDetails(
  action: IReduxAction<IFetchBankProductDetailsPayload>
) {
  const { userData }: { userData: IBankUserData } = yield select(
    (state: any) => state.auth
  );
  const { bankId } = userData.organization;
  const { productId } = action.payload;

  yield call(runner, automaticProducts.creators.fetchBankProductDetails, {
    method: 'GET',
    url: `/api/banks/${bankId}/products/${productId}`,
  });
}

const getStatusChangeToast = (error: any) => {
  const dismissIn = 3000;

  if (isNil(error)) {
    return buildSuccessToast('Estatus cambiado exitosamente.', dismissIn);
  } else {
    const { status } = error.response;
    const errorToastText = (() => {
      if (status === 409) {
        return 'La contraseña no es correcta, por favor intenta de nuevo.';
      } else {
        return 'No pudo realizarse el cambio de estatus. Contacta a soporte si el problema persiste.';
      }
    })();

    return buildErrorToast(errorToastText, dismissIn);
  }
};

interface IUpdateProductStatusPayload {
  password: number;
  productId: number;
}

function* updateProductStatus(
  action: IReduxAction<IUpdateProductStatusPayload>
) {
  const { userData }: { userData: IBankUserData } = yield select(
    (state: any) => state.auth
  );
  const { bankId } = userData.organization;
  const { password, productId } = action.payload;

  const { error } = yield call(
    runner,
    automaticProducts.creators.updateProductStatus,
    {
      method: 'PUT',
      url: `/api/banks/${bankId}/products/${productId}/status`,
      payload: {
        password,
      },
    }
  );

  yield put(toastActions.creators.showToast(getStatusChangeToast(error)));

  if (isNil(error)) {
    yield put(automaticProducts.creators.fetchBankAutomaticProducts.request());
  }
}

function* watchFetchBankAutomaticProducts() {
  yield takeLatest(
    automaticProducts.types.FETCH_BANK_AUTOMATIC_PRODUCTS.REQUESTED,
    fetchBankAutomaticProducts
  );
}

function* watchFetchBankProductDetails() {
  yield takeLatest(
    automaticProducts.types.FETCH_BANK_PRODUCT_DETAILS.REQUESTED,
    fetchBankProductDetails
  );
}

function* watchUpdateProductStatuss() {
  yield takeLatest(
    automaticProducts.types.UPDATE_PRODUCT_STATUS.REQUESTED,
    updateProductStatus
  );
}

export default {
  watchFetchBankAutomaticProducts,
  watchFetchBankProductDetails,
  watchUpdateProductStatuss,
};
