import not from 'ramda/src/not';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import offerActions from '../../actions/offers';
import colors from '../../constants/colors';
import useFetchTemplateDocuments from '../../hooks/useFetchTemplateDocuments';
import ApprobationForm from '../ApprobationForm';
import Button from '../Button';
import LoadingIndicator from '../LoadingIndicator';

const BottomMargin = styled.div`
  margin-bottom: 20px;
`;

const LoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 50vh;
  justify-content: center;
  width: 100%;
`;

interface IMatchParams {
  offerId: string;
}

interface Props extends RouteComponentProps<IMatchParams> {
  fetchOffer(payload: { id: string }): void;
  offer: any;
}

const ApprobationView: React.FunctionComponent<Props> = ({
  fetchOffer,
  history,
  match,
  offer,
}) => {
  const { offerId } = match.params;
  useEffect(() => {
    fetchOffer({ id: offerId });
  }, [fetchOffer, offerId]);

  const templateDocuments = useFetchTemplateDocuments(offerId, 'approval');

  const onBackButtonClick = () => {
    history.push(`/pipeline/offer/${offer.id}`);
  };

  if (not(offer) || templateDocuments === null) {
    return (
      <LoadingState>
        <LoadingIndicator />
      </LoadingState>
    );
  }

  return (
    <Fragment>
      <BottomMargin>
        <Button onClick={onBackButtonClick} variant="secondary">
          Regresar
        </Button>
      </BottomMargin>
      <ApprobationForm templateDocuments={templateDocuments} offer={offer} />
    </Fragment>
  );
};

const mapStateToProps = (state: any, ownProps: any) => ({
  offer: state.entities.offers[ownProps.match.params.offerId],
});

const creators = {
  fetchOffer: offerActions.creators.fetchOfferById.request,
};

export default connect(mapStateToProps, creators)(ApprobationView);
