import { values } from 'ramda';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import documentActions from '../../actions/documents';
import offerActions from '../../actions/offers';
import colors from '../../constants/colors';
import deviceSizes from '../../constants/deviceSizes';
import grammar from '../../constants/grammar';
import UploadIcon from '../../resources/icons/Upload';
import Button from '../Button';
import Card from '../Card';
import LoadingState from '../LoadingState';
import SmartTable, { TableCell } from '../SmartTable';
import StatusIndicator from '../StatusIndicator';

const Cell = styled(TableCell)`
  align-items: center;
  color: ${colors.PRIMARY_TEXT};
  display: flex;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  padding-right: 8px;
`;

const UploadIconContainer = styled.div`
  height: 24px;
  margin-right: 8px;
  padding-bottom: 4px;
  width: 20px;

  & > input {
    visibility: hidden;
    width: 0;
  }
`;

const FileUploadLabel = styled.label`
  cursor: pointer;
`;

const NoRequiredDocsContainer = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  text-align: center;
  padding: 45px 20px;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

enum offerStages {
  ACCEPTED = 'accepted',
  ACTIVATED = 'activated',
  ANALYSIS = 'analysis',
  APPROVAL = 'approval',
  FORMALIZATION = 'formalization',
  GRANTED = 'granted',
  PREACTIVATION = 'pre-activation',
  PREFORMALIZATION = 'pre-formalization',
  RELEASE = 'release',
}

interface IOfferDocument {
  id: number;
  name: string;
  description: string;
  extension: null | string;
  status: DocumentStatus;
  stage: offerStages;
  offerId: number;
  bankId: number;
  reviewer: string;
  type: string;
  url: null | string;
  created_at: null | Date;
  updated_at: null | Date;
}

interface IUploadControls {
  document: IOfferDocument;
  upload: Function;
}

const UploadControls: React.FunctionComponent<IUploadControls> = ({
  document,
  upload,
}) => {
  const uploadHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      upload({
        fileId: document.id,
        offerId: document.offerId,
        files: [event.target.files[0]],
      });
    }
  };

  return (
    <UploadIconContainer>
      <input id={`file${document.id}`} type="file" onChange={uploadHandler} />
      <FileUploadLabel htmlFor={`file${document.id}`}>
        <UploadIcon size={20} fill={colors.PRIMARY_TEXT} />
      </FileUploadLabel>
    </UploadIconContainer>
  );
};

interface IClientOffersView {
  offerId: number;
  applicationId: number;
  deviceWidth: number;
  documents: IOfferDocument[];
  fetchOfferDocuments: Function;
  upload: Function;
  history: RouteComponentProps['history'];
}

const ClientOffersView: React.FunctionComponent<IClientOffersView> = ({
  offerId,
  applicationId,
  deviceWidth,
  documents,
  fetchOfferDocuments,
  upload,
  history,
}) => {
  useEffect(() => {
    fetchOfferDocuments({ offerId });
  }, [offerId, fetchOfferDocuments]);

  const documentsTable = (): JSX.Element => {
    const pendingDocs = documents.find(
      doc =>
        (doc.status === 'required' || doc.status === 'rejected') &&
        [
          offerStages.ACCEPTED,
          offerStages.ACTIVATED,
          offerStages.ANALYSIS,
        ].includes(doc.stage)
    );

    const headers =
      deviceWidth < deviceSizes.tablet.width
        ? ['NOMBRE', 'ESTATUS', 'ENVIAR']
        : ['NOMBRE', 'DESCRIPCION', 'ESTATUS', 'ENVIAR'];

    const gridColumns =
      deviceWidth < deviceSizes.tablet.width
        ? 'repeat(3, 45% 35% 20%)'
        : 'repeat(4, 25% 40% 20% 15%)';

    if (!documents.length) {
      return <LoadingState />;
    } else if (!pendingDocs) {
      return (
        <NoRequiredDocsContainer>
          La institución financiera ha validado o se encuentra validando tu
          documentación, por el momento no se requiere ninguna acción de tu
          parte.
        </NoRequiredDocsContainer>
      );
    } else {
      return (
        <SmartTable
          data={documents.filter(
            doc => doc.status === 'required' || doc.status === 'rejected'
          )}
          gridColumns={gridColumns}
          headers={headers}
          renderRow={document => {
            return deviceWidth < deviceSizes.tablet.width
              ? [
                  <TableCell key={0}>{document.name}</TableCell>,
                  <TableCell key={1}>
                    <StatusIndicator status={document.status} />
                    {grammar[document.status]}
                  </TableCell>,
                  <Cell key={2}>
                    <Link
                      to={`/offered-applications/${applicationId}/offer/${offerId}/documents/${document.id}`}
                    >
                      <UploadIconContainer>
                        <FileUploadLabel>
                          <UploadIcon size={20} fill={colors.PRIMARY_TEXT} />
                        </FileUploadLabel>
                      </UploadIconContainer>
                    </Link>
                  </Cell>,
                ]
              : [
                  <TableCell key={0}>{document.name}</TableCell>,
                  <TableCell key={1}>{document.description}</TableCell>,
                  <TableCell key={2}>
                    <StatusIndicator status={document.status} />
                    {grammar[document.status]}
                  </TableCell>,
                  <Cell key={3}>
                    <UploadControls document={document} upload={upload} />
                  </Cell>,
                ];
          }}
        />
      );
    }
  };

  return (
    <Fragment>
      <ControlsContainer>
        <Button onClick={() => history.goBack()} variant="secondary">
          Regresar
        </Button>
      </ControlsContainer>
      <Card header="Documentos">{documentsTable()}</Card>
    </Fragment>
  );
};

const mapStateToProps = (
  state: any
): { documents: IOfferDocument[]; deviceWidth: number } => ({
  documents: values(state.entities.offerDocuments),
  deviceWidth: state.deviceData.clientWidth,
});

const creators = {
  fetchOfferDocuments:
    offerActions.creators.fetchOfferRequiredDocuments.request,
  upload: documentActions.creators.uploadDocumentFile.request,
};

export default connect(mapStateToProps, creators)(ClientOffersView);
