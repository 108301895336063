import styled from 'styled-components';

import colors from '../../constants/colors';

interface Label {
  error?: boolean;
}

const getLabelColor = ({ error }: Label) =>
  error ? colors.RED : colors.SECONDARY_TEXT;

const Label = styled.span<Label>`
  color: ${getLabelColor};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
`;

export default Label;
