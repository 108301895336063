import format from 'date-fns/format';
import values from 'ramda/src/values';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import invoicesActions from '../../actions/invoices';
import f from '../../constants/formatters';
import { invoices as invoicesGrammar } from '../../constants/grammar';
import { mapInvoiceStatusToColor } from '../../constants/invoices';
import useUserData from '../../hooks/useUserData';
import {
  isBankUser,
  isCreditusUser,
  isPromoterUser,
} from '../../utils/permissions';
import Button from '../Button';
import Card from '../Card';
import ColorLabel from '../ColorLabel';
import { CommissionsTable } from '../CommissionsView';
import GridArea from '../GridArea';
import InfoCard from '../InfoCard';
import InvoiceDocuments from '../InvoiceDocuments';
import LoadingState from '../LoadingState';
import SmartTable, { TableCell } from '../SmartTable';

interface IInvoiceViewStateProps {
  invoice: IInvoice | null;
  commissions: ICommission[];
}

interface IInvoiceViewDispatchProps {
  fetchInvoice: (payload: { invoiceId: number }) => void;
  fetchInvoiceCommissions: (payload: { invoiceId: number }) => void;
}

type IInvoiceViewRouteProps = RouteComponentProps<{ invoiceId: string }> &
  IInvoiceViewStateProps &
  IInvoiceViewDispatchProps;

const InvoiceViewGrid = styled.div`
  display: grid;
  grid-template-columns: 350px auto;
  grid-template-areas:
    'invoice-info invoice-documents'
    'invoice-recipient-data invoice-recipient-data'
    'invoice-commissions invoice-commissions';
  grid-gap: 24px;
  margin-top: 24px;
`;

const InvoiceView: React.FunctionComponent<IInvoiceViewRouteProps> = ({
  commissions,
  fetchInvoice,
  fetchInvoiceCommissions,
  history,
  invoice,
  match,
}) => {
  const { invoiceId } = match.params;

  useEffect(() => {
    fetchInvoice({ invoiceId: Number.parseInt(invoiceId) });
    fetchInvoiceCommissions({ invoiceId: Number.parseInt(invoiceId) });
  }, [fetchInvoice, fetchInvoiceCommissions, invoiceId]);

  const userData = useUserData();

  if (invoice === null) {
    return <LoadingState />;
  }

  const infoCardData: [string, React.ReactNode][] = [
    [
      'Estatus',
      <ColorLabel color={mapInvoiceStatusToColor[invoice.status]} width="120px">
        {invoicesGrammar[invoice.status]}
      </ColorLabel>,
    ],
    [
      'Periodo',
      `${format(invoice.startDate, 'dd/MM/yyyy')} - ${format(
        invoice.endDate,
        'dd/MM/yyyy'
      )}`,
    ],
    ['Comisiones', f.currency(invoice.totalBeforeTaxes)],
    ['IVA', f.currency(invoice.taxes)],
    ['Total', f.currency(invoice.totalDue)],
    ['Concepto', invoice.concept],
  ];

  const commissionsTableType = (() => {
    if (isPromoterUser(userData)) {
      return 'receivable';
    }
    if (isBankUser(userData)) {
      return 'payable';
    }
    return invoice.type;
  })();

  return (
    <Fragment>
      <Button onClick={() => history.goBack()} variant="secondary">
        Regresar
      </Button>
      <InvoiceViewGrid>
        <GridArea name="invoice-info">
          <InfoCard data={infoCardData} title={`Factura #${invoice.id}`} />
        </GridArea>
        <GridArea name="invoice-documents">
          <InvoiceDocuments
            invoice={invoice}
            isCreditusUser={isCreditusUser(userData)}
          />
        </GridArea>
        <GridArea name="invoice-recipient-data">
          <Card header="Datos de facturación">
            <SmartTable
              data={[invoice]}
              headers={[
                'Razón social',
                'RFC',
                'Domicilio fiscal',
                'Uso CFDI',
                'Forma de pago',
                'Método de pago',
              ]}
              gridColumns="repeat(6, 1fr)"
              renderRow={invoice => [
                <TableCell key="1">{invoice.recipientLegalName}</TableCell>,
                <TableCell key="2">{invoice.recipientTaxpayerId}</TableCell>,
                <TableCell key="3">{invoice.recipientAddress}</TableCell>,
                <TableCell key="4">{invoice.use}</TableCell>,
                <TableCell key="5">{invoice.paymentForm}</TableCell>,
                <TableCell key="6">{invoice.paymentMethod}</TableCell>,
              ]}
            />
          </Card>
        </GridArea>
        <GridArea name="invoice-commissions">
          <Card header="Comisiones">
            <CommissionsTable
              commissions={commissions as any}
              isCreditusUser={isCreditusUser(userData)}
              type={commissionsTableType}
            />
          </Card>
        </GridArea>
      </InvoiceViewGrid>
    </Fragment>
  );
};

const mapStateToProps = (
  state: any,
  ownProps: IInvoiceViewRouteProps
): IInvoiceViewStateProps => {
  const { invoiceId } = ownProps.match.params;

  const commissions = values(state.entities.commissions).filter(
    (commission: ICommission) =>
      commission.invoiceId === Number.parseInt(invoiceId)
  );

  return {
    invoice:
      state.entities.invoices !== null
        ? state.entities.invoices[invoiceId] || null
        : null,
    commissions,
  };
};

const creators = {
  fetchInvoice: invoicesActions.creators.fetchInvoice.request,
  fetchInvoiceCommissions:
    invoicesActions.creators.fetchInvoiceCommissions.request,
};

export default connect(mapStateToProps, creators)(InvoiceView);
