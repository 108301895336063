const sectionFormReferencesErrors = {
  name: {
    required: 'El nombre es un campo obligatorio.',
  },
  firstSurname: {
    required: 'El primer apellido es un campo obligatorio.',
  },
  secondSurname: {
    required: 'El segundo apellido es un campo obligatorio.',
  },
  phoneNumber: {
    matches: 'El teléfono debe de tener 10 dígitos.',
    required: 'El teléfono es un campo obligatorio.',
  },
  relation: {
    required: 'La relación es un campo obligatorio.',
  },
};

export default sectionFormReferencesErrors;
