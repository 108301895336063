import not from 'ramda/src/not';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import colors from '../../constants/colors';
import { getAvatarLetters, noop } from '../../utils/misc';
import {
  isBankAdmin,
  isBankAnalyst,
  isBankLegal,
  isBankUser,
  isPromoterAdmin,
  isPromoterFNI,
  isPromoterSalesperson,
  isPromoterUser,
} from '../../utils/permissions';
import Border from '../Border';
import ExpandIndicator from '../ExpandIndicator';
import Input from '../Input';
import PasswordChangeForm from '../PasswordChangeForm';
import PrivacySettingsSection from '../PrivacySettingsSection';

const AvatarContent = styled.div`
  align-items: center;
  background-color: ${colors.AQUA};
  border-radius: 30px;
  color: ${colors.WHITE};
  display: flex;
  font-size: 20px;
  height: 60px;
  justify-content: center;
  line-height: 1;
  width: 60px;
`;

const CardContainer = styled.div`
  margin-bottom: 24px;
`;

const CustomCardContainer = styled.div`
  background-color: ${colors.WHITE};
  padding-bottom: 24px;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 0 0 ${colors.BORDER};
`;

const ControlsContainer = styled.div`
  margin-left: auto;
`;

const EmailLink = styled.a`
  color: ${colors.HYPERLINK};
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const ExpandableContent = styled.div<{ expanded: boolean; height?: string }>`
  height: ${({ expanded, height }) => (expanded ? height || '188px' : '0px')};
  overflow: hidden;
  transition: height ease 0.5s;
`;

const ExpandIndicatorContainer = styled.div`
  padding-right: 24px;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 0;
  padding-left: 30px;
  padding-right: 24px;
`;

const InformationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 44px;
  grid-row-gap: 20px;
  padding: 24px 0;
  padding-left: 30px;
  padding-right: 24px;
`;

const Title = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 18px;
  margin-bottom: 8px;
`;

const Subtitle = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
`;

interface CustomCardProps {
  controls?: React.ComponentType;
  onClick?: () => void;
  title: string;
  subtitle: string | (() => JSX.Element);
}

interface ProfileViewProps {
  userData: IUserData;
}

const Avatar: React.FunctionComponent<{ userData: IUserData }> = ({
  userData,
}) => <AvatarContent>{getAvatarLetters(userData.user)}</AvatarContent>;

const CustomCard: React.FunctionComponent<CustomCardProps> = ({
  children,
  controls = Fragment,
  onClick = noop,
  title,
  subtitle,
}) => (
  <CustomCardContainer>
    <HeaderContainer onClick={onClick}>
      <div>
        <Title>{title}</Title>
        <Subtitle>
          {typeof subtitle === 'string' ? subtitle : subtitle()}
        </Subtitle>
      </div>
      <ControlsContainer>{React.createElement(controls)}</ControlsContainer>
    </HeaderContainer>
    <Border />
    {children}
    <Border />
  </CustomCardContainer>
);

const PrivacySettings = () => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <CustomCard
      controls={() => (
        <ExpandIndicatorContainer>
          <ExpandIndicator expanded={expanded} />
        </ExpandIndicatorContainer>
      )}
      title="Privacidad"
      subtitle="Establece la privacidad de tu cuenta."
      onClick={() => setExpanded(!expanded)}
    >
      <ExpandableContent expanded={expanded} height={'151px'}>
        <PrivacySettingsSection />
      </ExpandableContent>
    </CustomCard>
  );
};

const PasswordChange = () => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <CustomCard
      controls={() => (
        <ExpandIndicatorContainer>
          <ExpandIndicator expanded={expanded} />
        </ExpandIndicatorContainer>
      )}
      title="Contraseña"
      subtitle="Mantén tu contraseña segura."
      onClick={() => setExpanded(not(expanded))}
    >
      <ExpandableContent expanded={expanded}>
        <PasswordChangeForm />
      </ExpandableContent>
    </CustomCard>
  );
};

const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = phoneNumberString.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '';
};

const getUserPhoneNumber = (user: IUserData['user']) => {
  if (user.phoneExtension) {
    return `${formatPhoneNumber(user.phoneNumber)} ext. ${user.phoneExtension}`;
  }

  return formatPhoneNumber(user.phoneNumber);
};

const getUserType = (userData: IUserData) => {
  if (isBankUser(userData)) {
    if (isBankAdmin(userData)) {
      return 'Administrador';
    }
    if (isBankAnalyst(userData)) {
      return 'Analista';
    }
    if (isBankLegal(userData)) {
      return 'Jurídico';
    }
  }

  if (isPromoterUser(userData)) {
    if (isPromoterFNI(userData)) {
      return 'F&I';
    }
    if (isPromoterSalesperson(userData)) {
      return 'Asesor';
    }
    if (isPromoterAdmin(userData)) {
      return 'Administrador';
    }
  }
  if (userData.user.profile === 4) {
    return 'Web';
  }

  return '';
};

const ProfileView: React.FunctionComponent<ProfileViewProps> = ({
  userData,
}) => (
  <Fragment>
    <CardContainer>
      <CustomCard
        controls={() => <Avatar userData={userData} />}
        title="Perfil"
        subtitle="Esta es la información de tu perfil."
      >
        <InformationContainer>
          <Input disabled label="NOMBRE" value={userData.user.name} />
          <Input
            disabled
            label="PRIMER APELLIDO"
            value={userData.user.firstSurname || ''}
          />
          <Input
            disabled
            label="SEGUNDO APELLIDO"
            value={userData.user.secondSurname || ''}
          />
          <Input disabled label="EMAIL" value={userData.user.email} />
          <Input
            disabled
            label="TELÉFONO Y EXTENSIÓN"
            value={getUserPhoneNumber(userData.user)}
          />
          <Input
            disabled
            label="TIPO DE USUARIO"
            value={getUserType(userData)}
          />
        </InformationContainer>
      </CustomCard>
    </CardContainer>
    <CardContainer>
      <PrivacySettings />
    </CardContainer>
    <CardContainer>
      <PasswordChange />
    </CardContainer>
    <CardContainer>
      <CustomCard
        title="Ayuda"
        subtitle={() => (
          <span>
            En caso de requerir ayuda, contacta al equipo en{' '}
            <EmailLink href="mailto:ayuda@creditus.mx">
              ayuda@creditus.mx
            </EmailLink>{' '}
            o por medio del chat.{' '}
          </span>
        )}
      />
    </CardContainer>
  </Fragment>
);

const mapStateToProps = (state: any) => ({
  userData: state.auth.userData,
});

export default connect(mapStateToProps)(ProfileView);
