import { call, put, takeLatest } from 'redux-saga/effects';

import forgotPasswordActions from '../actions/forgotPassword';
import toastActions from '../actions/toastNotifications';
import { buildErrorToast } from '../utils/toastNotifications';
import runner from './runners';

const UNEXPECTED_ERROR_TOAST_MESSAGE =
  'Hubo un error en la verificación. Podemos atenderte en el chat de ayuda.';
const USER_NOT_FOUND =
  'No se ha encontrado un usuario con ese correo. Por favor, trate con uno diferente.';
const UNEXPECTED_ERROR_TOAST_MESSAGE_PASSWORD =
  'Hubo un error al restablecer su contraseña. Podemos atenderte en el chat de ayuda.';
const INVALID_TOKEN =
  'Esta liga es inválida o ya ha caducado. Por favor, inicia el proceso de restablecimiento de contraseña de nuevo.';

function* checkAccountExistsWithEmail({
  payload,
}: IReduxAction<{ email: string; password: string }>) {
  const { error } = yield call(
    runner,
    forgotPasswordActions.creators.checkAccountExistsWithEmail,
    {
      method: 'post',
      url: '/api/account/auth/recovery/existent-client-email',
      payload,
    },
    { snakeCase: false }
  );
  if (error) {
    const message = (() => {
      const statusCode = error.response.status;
      switch (statusCode) {
        case 404:
          return USER_NOT_FOUND;
        default:
          return UNEXPECTED_ERROR_TOAST_MESSAGE;
      }
    })();
    const toast = buildErrorToast(message, 5000);
    yield put(toastActions.creators.showToast(toast));
  }
}

function* watchCheckAccountExistsWithEmail() {
  yield takeLatest(
    forgotPasswordActions.types.ACCOUNT_WITH_EMAIL_CHECK.REQUESTED,
    checkAccountExistsWithEmail
  );
}

function* sendPasswordRecovery({
  payload,
}: IReduxAction<{ email: string; contactOption: string }>) {
  yield call(
    runner,
    forgotPasswordActions.creators.sendPasswordRecovery,
    {
      method: 'post',
      url: '/api/account/auth/recovery/send-recovery',
      payload,
    },
    { snakeCase: false }
  );
}

function* watchSendPasswordRecovery() {
  yield takeLatest(
    forgotPasswordActions.types.SEND_PASSWORD_RECOVERY.REQUESTED,
    sendPasswordRecovery
  );
}

function* resetPassword({
  payload,
}: IReduxAction<{
  token: string;
  newPassword: string;
  confirmPassword: string;
}>) {
  const { error } = yield call(
    runner,
    forgotPasswordActions.creators.resetPassword,
    {
      method: 'post',
      url: '/api/account/auth/recovery/reset-password',
      payload,
    },
    { snakeCase: false }
  );
  if (error) {
    const message = (() => {
      const statusCode = error.response.status;
      switch (statusCode) {
        case 406:
          return INVALID_TOKEN;
        default:
          return UNEXPECTED_ERROR_TOAST_MESSAGE_PASSWORD;
      }
    })();
    const toast = buildErrorToast(message, 5000);
    yield put(toastActions.creators.showToast(toast));
  }
}

function* watchResetPassword() {
  yield takeLatest(
    forgotPasswordActions.types.RESET_PASSWORD.REQUESTED,
    resetPassword
  );
}

export default {
  watchCheckAccountExistsWithEmail,
  watchSendPasswordRecovery,
  watchResetPassword,
};
