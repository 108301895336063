import { call, put, takeLatest } from 'redux-saga/effects';

import priceQuote from '../actions/priceQuote';
import toastActions from '../actions/toastNotifications';
import {
  buildErrorToast,
  buildWarningToast,
} from '../utils/toastNotifications';
import runner from './runners';

function* createPriceQuote(action: any): any {
  const { error, json } = yield call(
    runner,
    priceQuote.creators.createPriceQuote,
    {
      method: 'POST',
      url: `/api/products/price-quote`,
      payload: action.payload,
    },
    { snakeCase: false }
  );

  if (error) {
    const toast = buildErrorToast(
      'No fue posible realizar la cotización. Contacta a Soporte si el problema persiste.',
      3000
    );
    yield put(toastActions.creators.showToast(toast));
  }

  if (json && json.offers.length === 0) {
    const toast = buildWarningToast(
      'No encontramos un producto para las condiciones que buscas, intenta cambiar las condiciones.',
      3000
    );
    yield put(toastActions.creators.showToast(toast));
  }
}

function* watchCreatePriceQuote(): any {
  yield takeLatest(
    priceQuote.types.CREATE_PRICE_QUOTE.REQUESTED,
    createPriceQuote
  );
}

export default { watchCreatePriceQuote };
