import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const ACCOUNT_CREATION_CLIENT = createAsyncFlowTypes('ACCOUNT_CREATION_CLIENT');
const ACCOUNT_CREATION_CLIENT_VERIFICATION = createAsyncFlowTypes(
  'ACCOUNT_CREATION_CLIENT_VERIFICATION'
);
const ACCOUNT_CREATION_SEND_NIP_VIA_WHATSAPP = createAsyncFlowTypes(
  'ACCOUNT_CREATION_SEND_NIP_VIA_WHATSAPP'
);

const createClientAccount = createAsyncFlowCreators(ACCOUNT_CREATION_CLIENT);
const verifyClientAccount = createAsyncFlowCreators(
  ACCOUNT_CREATION_CLIENT_VERIFICATION
);
const sendClientAccountNipViaWhatsapp = createAsyncFlowCreators(
  ACCOUNT_CREATION_SEND_NIP_VIA_WHATSAPP
);

export default {
  types: {
    ACCOUNT_CREATION_CLIENT,
    ACCOUNT_CREATION_CLIENT_VERIFICATION,
    ACCOUNT_CREATION_SEND_NIP_VIA_WHATSAPP,
  },
  creators: {
    createClientAccount,
    verifyClientAccount,
    sendClientAccountNipViaWhatsapp,
  },
};
