import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import invoicesActions from '../../actions/invoices';
import colors from '../../constants/colors';
import {
  documentStatusDropdownOptions,
  documentStatusOptions,
} from '../../constants/documentStatus';
import grammar from '../../constants/grammar';
import DownloadIcon from '../../resources/icons/Download';
import UploadIcon from '../../resources/icons/Upload';
import Dropdown from '../Dropdown';
import StatusIndicator from '../StatusIndicator';

const { REACT_APP_API_HOST: HOST } = process.env;

const InvoiceDocumentContainer = styled.div`
  align-items: center;
  display: flex;
  height: 68px;
  padding-left: 36px;
`;

const NameText = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: bold;
  width: 30%;
`;

const DescriptionText = styled.div`
  color: ${colors.DUSK50};
  font-size: 14px;
  width: 35%;
`;

const DocumentStatus = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  width: 20%;
`;

const FileUploadLabel = styled.label`
  cursor: pointer;
`;

const UploadIconContainer = styled.div`
  & > input {
    visibility: hidden;
    width: 0;
  }
`;

const UploadDocumentIconButton: React.FunctionComponent<{
  invoiceDocument: IInvoiceDocument;
  invoiceId: number;
  uploadInvoiceDocumentFile: (payload: IUploadInvoiceDocumentPayload) => void;
}> = ({ invoiceDocument, invoiceId, uploadInvoiceDocumentFile }) => {
  const uploadHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files !== null && files.length) {
      uploadInvoiceDocumentFile({
        file: files[0],
        invoiceDocumentId: invoiceDocument.id,
        invoiceId,
      });
    }
  };

  return (
    <UploadIconContainer>
      <input
        id={`invoiceDocumentFile-${invoiceDocument.id}`}
        type="file"
        onChange={uploadHandler}
      />
      <FileUploadLabel htmlFor={`invoiceDocumentFile-${invoiceDocument.id}`}>
        <UploadIcon fill={colors.DUSK} size={20} />
      </FileUploadLabel>
    </UploadIconContainer>
  );
};

const DownloadIconContainer = styled.div`
  padding-top: 5px;
`;

const DownloadDocumentIconButton: React.FunctionComponent<{
  invoiceDocument: IInvoiceDocument;
  invoiceId: number;
}> = ({ invoiceDocument, invoiceId }) => {
  const downloadUrl = `${HOST}/api/invoices/${invoiceId}/document/${invoiceDocument.id}/file`;

  return (
    <a href={downloadUrl}>
      <DownloadIconContainer>
        <DownloadIcon fill={colors.DUSK} size={20} />
      </DownloadIconContainer>
    </a>
  );
};

const UploadModeControlsContainer = styled.div`
  display: flex;
`;

const UploadModeControlsUploadIconContainer = styled.div`
  margin-right: 8px;
`;

const UploadModeControls: React.FunctionComponent<{
  invoiceDocument: IInvoiceDocument;
  invoiceId: number;
  uploadInvoiceDocumentFile: (payload: IUploadInvoiceDocumentPayload) => void;
}> = ({ invoiceDocument, invoiceId, uploadInvoiceDocumentFile }) => {
  return (
    <UploadModeControlsContainer>
      {invoiceDocument.status !== 'approved' ? (
        <UploadModeControlsUploadIconContainer>
          <UploadDocumentIconButton
            invoiceDocument={invoiceDocument}
            invoiceId={invoiceId}
            uploadInvoiceDocumentFile={uploadInvoiceDocumentFile}
          />
        </UploadModeControlsUploadIconContainer>
      ) : null}
      {invoiceDocument.extension !== null ? (
        <DownloadDocumentIconButton
          invoiceDocument={invoiceDocument}
          invoiceId={invoiceId}
        />
      ) : null}
    </UploadModeControlsContainer>
  );
};

const ReviewModeControlsContainer = styled.div`
  align-items: center;
  display: flex;
`;

const ReviewModeControlsAcceptedState = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
`;

const ReviewModeControlsDownloadIconContainer = styled.div`
  margin-right: 16px;
`;

const ReviewModeControlsDropdownContainer = styled.div`
  width: 140px;
`;

const ReviewModeControls: React.FunctionComponent<{
  invoiceDocument: IInvoiceDocument;
  invoiceId: number;
  setParentOverflow: (overflow: 'hidden' | 'visible') => void;
  updateInvoiceDocumentStatus: (payload: IUpdateInvoiceDocumentStatus) => void;
}> = ({
  invoiceId,
  invoiceDocument,
  setParentOverflow,
  updateInvoiceDocumentStatus,
}) => {
  const dropdownValue = documentStatusOptions.find(
    option => option.value === invoiceDocument.status
  );

  const reviewControl = (() => {
    if (invoiceDocument.status === 'approved') {
      return (
        <ReviewModeControlsAcceptedState>
          <StatusIndicator status={invoiceDocument.status} />
          {grammar[invoiceDocument.status]}
        </ReviewModeControlsAcceptedState>
      );
    } else if (invoiceDocument.status === 'required') {
      return (
        <ReviewModeControlsAcceptedState>
          Requerido
        </ReviewModeControlsAcceptedState>
      );
    } else {
      return (
        <Dropdown
          onBlur={() => setParentOverflow('hidden')}
          onChange={option =>
            updateInvoiceDocumentStatus({
              invoiceDocumentId: invoiceDocument.id,
              invoiceId,
              status: option.value as DocumentStatus,
            })
          }
          onOpen={() => setParentOverflow('visible')}
          options={documentStatusDropdownOptions}
          value={dropdownValue ? dropdownValue.value : ''}
          placeholder={'Requerido'}
        />
      );
    }
  })();

  return (
    <ReviewModeControlsContainer>
      {invoiceDocument.extension !== null ? (
        <ReviewModeControlsDownloadIconContainer>
          <DownloadDocumentIconButton
            invoiceDocument={invoiceDocument}
            invoiceId={invoiceId}
          />
        </ReviewModeControlsDownloadIconContainer>
      ) : null}
      <ReviewModeControlsDropdownContainer>
        {reviewControl}
      </ReviewModeControlsDropdownContainer>
    </ReviewModeControlsContainer>
  );
};

interface IUploadInvoiceDocumentPayload {
  file: File;
  invoiceDocumentId: number;
  invoiceId: number;
}

interface IUpdateInvoiceDocumentStatus {
  invoiceDocumentId: number;
  invoiceId: number;
  status: DocumentStatus;
}

interface IInvoiceDocumentDetailDispatchProps {
  uploadInvoiceDocumentFile: (payload: IUploadInvoiceDocumentPayload) => void;
  updateInvoiceDocumentStatus: (payload: IUpdateInvoiceDocumentStatus) => void;
}

interface IInvoiceDocumentDetailOwnProps {
  description: string;
  invoiceDocument: IInvoiceDocument;
  invoiceId: number;
  name: string;
  mode: InvoiceDocumentMode;
  setParentOverflow: (overflow: 'hidden' | 'visible') => void;
}

type IInvoiceDocumentDetail = IInvoiceDocumentDetailOwnProps &
  IInvoiceDocumentDetailDispatchProps;

const InvoiceDocumentDetail: React.FunctionComponent<IInvoiceDocumentDetail> = ({
  description,
  invoiceDocument,
  invoiceId,
  name,
  mode,
  setParentOverflow,
  updateInvoiceDocumentStatus,
  uploadInvoiceDocumentFile,
}) => {
  return (
    <InvoiceDocumentContainer>
      <NameText>{name}</NameText>
      <DescriptionText>{description}</DescriptionText>
      {mode === 'upload' ? (
        <DocumentStatus>
          <StatusIndicator status={invoiceDocument.status} />
          {grammar[invoiceDocument.status]}
        </DocumentStatus>
      ) : null}
      {mode === 'upload' ? (
        <UploadModeControls
          invoiceDocument={invoiceDocument}
          invoiceId={invoiceId}
          uploadInvoiceDocumentFile={uploadInvoiceDocumentFile}
        />
      ) : (
        <ReviewModeControls
          invoiceDocument={invoiceDocument}
          invoiceId={invoiceId}
          setParentOverflow={setParentOverflow}
          updateInvoiceDocumentStatus={updateInvoiceDocumentStatus}
        />
      )}
    </InvoiceDocumentContainer>
  );
};

const creators = {
  uploadInvoiceDocumentFile:
    invoicesActions.creators.uploadInvoiceDocumentFile.request,
  updateInvoiceDocumentStatus:
    invoicesActions.creators.updateInvoiceDocumentStatus.request,
};

export default connect<
  {},
  IInvoiceDocumentDetailDispatchProps,
  IInvoiceDocumentDetailOwnProps
>(
  null,
  creators
)(InvoiceDocumentDetail);
