import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 38.7043 35.573299';

const PromoterIcon = ({ fill }: { fill: string }) => (
  <g transform="translate(-3.9357,-4.79)">
    <path
      d="m 12.4503,15.6449 c -3e-4,-0.1894 0,-0.222 0,0 z M 5.1857,15.1375 c 0,2.1446 1.6488,4.2584 3.6467,4.2584 1.9978,0 3.6179,-1.7392 3.6179,-3.8839 0,2.1447 1.6202,3.8839 3.618,3.8839 1.9978,0 3.618,-1.7392 3.618,-3.8839 0,2.1447 1.6201,3.8839 3.6179,3.8839 1.9979,0 3.618,-1.7392 3.618,-3.8839 0,2.1447 1.6202,3.8839 3.618,3.8839 1.9978,0 3.618,-1.7392 3.618,-3.8839 0,2.1447 1.6201,3.8839 3.6179,3.8839 1.9979,0 3.6139,-1.9242 3.6139,-4.0688 L 37.7761,6.04 H 8.8324 l -3.7258,9.435 m 29.0516,0.1811 c 0,-0.1893 0,-0.2221 0,0 z M 19.6863,15.6414 c 0,-0.1893 0,-0.2221 0,0 z m 7.2359,0.1037 c -3e-4,-0.1893 0,-0.222 0,0 z"
      fill="none"
      stroke={fill}
      strokeWidth="2.5"
    />
    <path
      d="m 7.8516,22.0716 v 14.3913 c 0,1.4576 1.1926,2.6504 2.6503,2.6504 h 25.6043 c 1.4578,0 2.6504,-1.1927 2.6504,-2.6504 V 22.0716"
      fill="none"
      stroke={fill}
      strokeWidth="2.5"
    />
    <polyline
      points="7.8516,30.5352 26.8459,30.5352 26.8459,22.0716 "
      fill="none"
      stroke={fill}
      strokeWidth="2.5"
    />
    <line
      y2="39.1133"
      x2="26.8459"
      y1="30.5352"
      x1="26.8459"
      fill="none"
      stroke={fill}
      strokeWidth="2.5"
    />
  </g>
);

export default BaseIcon(PromoterIcon, viewBox);
