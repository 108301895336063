import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 16 16';

const DashboardIcon = ({ fill }: { fill: string }) => (
  <path
    d="M4.8 14.4H2.4a.8.8 0 0 1 0-1.6h2.4a.8.8 0 0 1 0 1.6zm.8-3.2h-4A1.6 1.6 0 0 0 0 12.8v1.6A1.6 1.6 0 0 0 1.6 16h4a1.6 1.6 0 0 0 1.6-1.6v-1.6a1.6 1.6 0 0 0-1.6-1.6zm8-8h-2.4a.8.8 0 0 1 0-1.6h2.4a.8.8 0 0 1 0 1.6zm.8-3.2h-4a1.6 1.6 0 0 0-1.6 1.6v1.6a1.6 1.6 0 0 0 1.6 1.6h4A1.6 1.6 0 0 0 16 3.2V1.6A1.6 1.6 0 0 0 14.4 0zm0 13.6a.8.8 0 0 1-.8.8h-2.4a.8.8 0 0 1-.8-.8V8.8a.8.8 0 0 1 .8-.8h2.4a.8.8 0 0 1 .8.8v4.8zm0-7.2h-4A1.6 1.6 0 0 0 8.8 8v6.4a1.6 1.6 0 0 0 1.6 1.6h4a1.6 1.6 0 0 0 1.6-1.6V8a1.6 1.6 0 0 0-1.6-1.6zm-8.8.8a.8.8 0 0 1-.8.8H2.4a.8.8 0 0 1-.8-.8V2.4a.8.8 0 0 1 .8-.8h2.4a.8.8 0 0 1 .8.8v4.8zm0-7.2h-4A1.6 1.6 0 0 0 0 1.6V8a1.6 1.6 0 0 0 1.6 1.6h4A1.6 1.6 0 0 0 7.2 8V1.6A1.6 1.6 0 0 0 5.6 0z"
    fill={fill}
  />
);

export default BaseIcon(DashboardIcon, viewBox);
