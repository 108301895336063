interface IDeviceSize {
  height: number;
  width: number;
}

const mobileSmall: IDeviceSize = {
  width: 320,
  height: 480,
};

const mobileMedium: IDeviceSize = {
  width: 360,
  height: 640,
};

const mobileLarge: IDeviceSize = {
  width: 425,
  height: 731,
};

const tablet: IDeviceSize = {
  width: 810,
  height: 1080,
};

const laptop: IDeviceSize = {
  width: 1920,
  height: 1080,
};

export default {
  mobileSmall,
  mobileMedium,
  mobileLarge,
  tablet,
  laptop,
};
