import { handleActions } from 'redux-actions';

const initialState = {
  linkSent: null,
  showLoading: null,
  qrCode: null,
};

const sendApplicationReducer = handleActions(
  {
    SEND_APPLICATION_TO_CLIENT_REQUESTED: (state, { payload }) => {
      return {
        ...state,
        showLoading: true,
      };
    },
    SEND_APPLICATION_TO_CLIENT_COMPLETED: (state, { payload }) => {
      return {
        ...state,
        showLoading: false,
        linkSent: true,
        ...payload,
      };
    },
    SEND_APPLICATION_TO_CLIENT_FAILED: (state, { payload }) => {
      return {
        ...state,
        linkSent: false,
      };
    },
    CLEAR_SEND_APPLICATION_TO_CLIENT: (state, { payload }) => {
      return {
        ...initialState,
      };
    },
  },
  initialState
);

export default sendApplicationReducer;
