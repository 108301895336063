import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const FETCH_NOTIFICATIONS = createAsyncFlowTypes('FETCH_NOTIFICATIONS');
const CHANGE_NOTIFICATIONS = createAsyncFlowTypes('CHANGE_NOTIFICATIONS');

const fetchNotifications = createAsyncFlowCreators(FETCH_NOTIFICATIONS);
const changeNotifications = createAsyncFlowCreators(CHANGE_NOTIFICATIONS);

const FETCH_FOLLOW_UP_EMAILS = createAsyncFlowTypes('FETCH_FOLLOW_UP_EMAILS');
const CHANGE_FOLLOW_UP_EMAILS = createAsyncFlowTypes('CHANGE_FOLLOW_UP_EMAILS');

const fetchFollowUpEmails = createAsyncFlowCreators(FETCH_FOLLOW_UP_EMAILS);
const changeFollowUpEmails = createAsyncFlowCreators(CHANGE_FOLLOW_UP_EMAILS);

export default {
  types: {
    FETCH_NOTIFICATIONS,
    CHANGE_NOTIFICATIONS,
    FETCH_FOLLOW_UP_EMAILS,
    CHANGE_FOLLOW_UP_EMAILS,
  },
  creators: {
    fetchNotifications,
    changeNotifications,
    fetchFollowUpEmails,
    changeFollowUpEmails,
  },
};
