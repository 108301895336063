import values from 'ramda/src/values';

const buildDictWithKey = (collection: Object | Array<any>, key: string) => {
  return values(collection).reduce<{ [key: string]: any }>(
    (dict, item: any) => {
      dict[item[key]] = item;
      return dict;
    },
    {}
  );
};

export default {
  buildDictWithKey,
};
