import { createAction } from 'redux-actions';

import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const CLEAR_SEND_APPLICATION_TO_CLIENT = 'CLEAR_SEND_APPLICATION_TO_CLIENT';
const SEND_APPLICATION_TO_CLIENT = createAsyncFlowTypes(
  'SEND_APPLICATION_TO_CLIENT'
);

const clearSendApplicationToClient = createAction(
  CLEAR_SEND_APPLICATION_TO_CLIENT
);
const sendApplicationToClient = createAsyncFlowCreators(
  SEND_APPLICATION_TO_CLIENT
);

export default {
  types: {
    CLEAR_SEND_APPLICATION_TO_CLIENT,
    SEND_APPLICATION_TO_CLIENT,
  },
  creators: {
    clearSendApplicationToClient,
    sendApplicationToClient,
  },
};
