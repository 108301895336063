import React from 'react';
import styled from 'styled-components';

import Button from '../Button';

const Container = styled.div`
  & > input {
    visibility: hidden;
    width: 0;
  }

  & button {
    pointer-events: none;
  }
`;

const Label = styled.label`
  display: inline-block;
`;

interface IFilePickerButtonProps {
  id: string | number;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onClick: (e: React.MouseEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  text: string;
  variant?: 'destructive' | 'inactive' | 'secondary' | 'primary';
}

const FilePickerButton: React.FunctionComponent<IFilePickerButtonProps> = ({
  id,
  onBlur,
  onChange,
  onClick,
  text,
  variant = 'primary',
}) => (
  <Container>
    <input
      id={`file-picker-button-${id}`}
      onBlur={onBlur}
      onChange={onChange}
      onClick={onClick}
      type="file"
    />
    <Label htmlFor={`file-picker-button-${id}`}>
      <Button variant={variant}>{text}</Button>
    </Label>
  </Container>
);

export default FilePickerButton;
