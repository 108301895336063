import isNil from 'ramda/src/isNil';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import profileSettingsActions from '../../actions/profileSettings';
import ToggleCheckbox from '../ToggleCheckbox';

const ToggleContainer = styled.div`
  padding: 28px;
`;

interface IPrivacySettingsSectionStateProps {
  notifications: boolean;
  followUpEmails: boolean;
  updatingNotifications: boolean;
  updatingFollowUpEmails: boolean;
}

interface IPrivacySettingsSectionDispatchProps {
  changeNotifications: Function;
  fetchNotifications: Function;
  changeFollowUpEmails: Function;
  fetchFollowUpEmails: Function;
}

type IPrivacySettingsSectionProps = IPrivacySettingsSectionStateProps &
  IPrivacySettingsSectionDispatchProps;

const PrivacySettingsSection: React.FunctionComponent<IPrivacySettingsSectionProps> = ({
  changeNotifications,
  fetchNotifications,
  changeFollowUpEmails,
  fetchFollowUpEmails,
  notifications,
  followUpEmails,
  updatingNotifications,
  updatingFollowUpEmails,
}) => {
  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  useEffect(() => {
    fetchFollowUpEmails();
  }, [fetchFollowUpEmails]);

  return (
    <ToggleContainer>
      <ToggleCheckbox
        onChange={checked => changeNotifications({ notifications: checked })}
        value={isNil(notifications) ? false : notifications}
        title={'Notificaciones'}
        subtitle={'Notificaciones relevantes sobre trámites vía SMS'}
        disabled={updatingNotifications}
      />
      <br />
      <ToggleCheckbox
        onChange={checked => changeFollowUpEmails({ followUpEmails: checked })}
        value={isNil(followUpEmails) ? false : followUpEmails}
        title={'Seguimiento a clientes'}
        subtitle={'Correos de seguimiento y actualizaciones a clientes'}
        disabled={updatingFollowUpEmails}
      />
    </ToggleContainer>
  );
};

const mapStateToProps = (state: any): any => ({
  notifications: state.entities.profileSettings.notifications,
  updatingNotifications: state.loaders.updatingNotifications,
  followUpEmails: state.entities.profileSettings.followUpEmails,
  updatingFollowUpEmails: state.loaders.updatingFollowUpEmails,
});

const creators = {
  changeNotifications:
    profileSettingsActions.creators.changeNotifications.request,
  fetchNotifications:
    profileSettingsActions.creators.fetchNotifications.request,
  changeFollowUpEmails:
    profileSettingsActions.creators.changeFollowUpEmails.request,
  fetchFollowUpEmails:
    profileSettingsActions.creators.fetchFollowUpEmails.request,
};

export default connect<
  IPrivacySettingsSectionStateProps,
  IPrivacySettingsSectionDispatchProps,
  {}
>(
  mapStateToProps,
  creators
)(PrivacySettingsSection);
