import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const FINAL_OFFER_CREATE = createAsyncFlowTypes('FINAL_OFFER_CREATE');
const FINAL_OFFER_UPDATE_FORMALIZATION_DATE = createAsyncFlowTypes(
  'FINAL_OFFER_UPDATE_FORMALIZATION_DATE'
);
const FINAL_OFFER_UPDATE_FORMALIZATION_DATE_STATUS = createAsyncFlowTypes(
  'FINAL_OFFER_UPDATE_FORMALIZATION_DATE_STATUS'
);
const FINAL_OFFER_CREATE_FORMALIZATION_DOCUMENTS = createAsyncFlowTypes(
  'FINAL_OFFER_CREATE_FORMALIZATION_DOCUMENTS'
);
const FINAL_OFFER_UPDATE_GRANTED_DATE = createAsyncFlowTypes(
  'FINAL_OFFER_UPDATE_GRANTED_DATE'
);

const createFinalOffer = createAsyncFlowCreators(FINAL_OFFER_CREATE);
const updateFormalizationDate = createAsyncFlowCreators(
  FINAL_OFFER_UPDATE_FORMALIZATION_DATE
);
const updateFormalizationDateStatus = createAsyncFlowCreators(
  FINAL_OFFER_UPDATE_FORMALIZATION_DATE_STATUS
);
const createFormalizationDocuments = createAsyncFlowCreators(
  FINAL_OFFER_CREATE_FORMALIZATION_DOCUMENTS
);
const updateGrantedDate = createAsyncFlowCreators(
  FINAL_OFFER_UPDATE_GRANTED_DATE
);

export default {
  types: {
    FINAL_OFFER_CREATE,
    FINAL_OFFER_UPDATE_FORMALIZATION_DATE,
    FINAL_OFFER_UPDATE_FORMALIZATION_DATE_STATUS,
    FINAL_OFFER_CREATE_FORMALIZATION_DOCUMENTS,
    FINAL_OFFER_UPDATE_GRANTED_DATE,
  },
  creators: {
    createFinalOffer,
    updateFormalizationDate,
    updateFormalizationDateStatus,
    createFormalizationDocuments,
    updateGrantedDate,
  },
};
