import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 16 16';

const UserIcon = ({ fill }: { fill: string }) => (
  <path
    d="M11.266 4.8c0-1.765-1.465-3.2-3.266-3.2S4.734 3.035 4.734 4.8C4.734 6.565 6.199 8 8 8s3.266-1.435 3.266-3.2m3.09 11.2H12.9a.809.809 0 0 1-.817-.8c0-.442.366-.8.817-.8h.35c.565 0 .982-.558.769-1.07C13.029 10.958 10.707 9.6 8 9.6c-2.707 0-5.03 1.358-6.019 3.73-.213.512.204 1.07.769 1.07h.35c.451 0 .817.358.817.8 0 .442-.366.8-.817.8H1.643C.623 16-.18 15.086.035 14.109c.595-2.687 2.451-4.67 4.896-5.57A4.751 4.751 0 0 1 3.101 4.8C3.1 1.96 5.618-.305 8.586.034c2.151.244 3.933 1.924 4.256 4.022.277 1.809-.478 3.46-1.774 4.482 2.445.9 4.301 2.884 4.896 5.57.216.978-.588 1.892-1.608 1.892m-3.808-3.629c.319.313.319.82 0 1.132L8 16l-1.732-1.697a.789.789 0 0 1 0-1.132.83.83 0 0 1 1.155 0l.577.567 1.394-1.367a.83.83 0 0 1 1.155 0"
    fill={fill}
  />
);

export default BaseIcon(UserIcon, viewBox);
