import { Field, FieldProps } from 'formik';
import React from 'react';

import OptionPicker from '../OptionPicker';

interface IOption {
  text: string;
  value: string | boolean;
}

interface IFormikRadioInput {
  disabled?: boolean;
  label: string;
  name: string;
  options: IOption[];
  alignment?: string;
  warning?: boolean;
  height?: string;
}

const FormikRadioInput: React.FunctionComponent<IFormikRadioInput> = ({
  disabled,
  label,
  name,
  options,
  alignment,
  warning = false,
  height = '88px',
}) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <OptionPicker
        label={label}
        value={field.value}
        onBlur={() => form.setFieldTouched(field.name)}
        onChange={option => {
          form.setFieldValue(field.name, option.value);
        }}
        options={options}
        disabled={disabled}
        alignment={alignment}
        warning={warning}
        height={height}
      />
    )}
  </Field>
);

export default FormikRadioInput;
