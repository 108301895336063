const profilingFormSendErrors = {
  name: {
    required: 'El nombre es un campo obligatorio.',
  },
  firstSurname: {
    required: 'El primer apellido es un campo obligatorio.',
  },
  secondSurname: {
    required: 'El segundo apellido es un campo obligatorio.',
  },
  email: {
    email: 'La dirección de correo electrónico no es válida.',
    required: 'La dirección de correo electrónico es obligatoria.',
  },
  phoneNumber: {
    matches: 'El teléfono debe de tener 10 dígitos.',
    required: 'El teléfono es obligatorio.',
  },
  userType: {
    required: 'El tipo de usuario es un campo obligatorio',
  },
  password: {
    min: 'La contraseña debe de tener por lo menos 8 caracteres.',
    required: 'La contraseña es un campo obligatorio.',
  },
  passwordConfirmation: {
    min: 'La contraseña debe de tener por lo menos 8 caracteres.',
    oneOf: 'Las contraseñas deben de coincidir.',
    required: 'La confirmación de contraseña es un campo obligatorio.',
  },
};

export default profilingFormSendErrors;
