import { Formik, FormikProps } from 'formik';
import isEmpty from 'ramda/src/isEmpty';
import keys from 'ramda/src/keys';
import prop from 'ramda/src/prop';
import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import { Suggestions } from '../../hooks/useProfilingSuggestions';
import ExpandIndicator from '../ExpandIndicator';
import { IProfilingForm } from '../ProfilingSectionsForms';

const CustomCardContainer = styled.div<{ isMobile: boolean }>`
  width: ${({ isMobile }) => (isMobile ? '100%' : '440px')};
  background-color: ${colors.WHITE};
  margin: 8px 0px;
  border: solid 1px ${colors.BORDER};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
`;

const ExpandIndicatorContainer = styled.div`
  padding-right: 20px;
`;

const ExpandableContent = styled.div<{
  expanded: boolean;
  isDropdownOpen: boolean;
  height: string;
}>`
  height: ${({ expanded, height }) => (expanded ? height : '0px')};
  overflow-y: ${({ isDropdownOpen }) =>
    isDropdownOpen ? 'visible' : 'hidden'};
  transition: height ease 0.5s;
`;

const FormContent = styled.div`
  width: 64%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 18%;
  padding-top: 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`;

const HeaderBorder = styled.div`
  background-color: ${colors.AQUA};
  height: 1px;
  width: 100%;
`;

const Title = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 15px;
  font-weight: bold;
`;

const Checkmark = styled.span`
  display: block;
  position: relative;
  height: 24px;
  width: 24px;
  border: solid 1px ${colors.AQUA};
  background-image: ${colors.AQUA};
  background-color: ${colors.AQUA};
  border-radius: 50%;
  ::after {
    content: '';
    margin-left: 8px;
    margin-top: 3px;
    display: block;
    width: 6px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const DefaultIcon = styled.span<{ borderColor: string }>`
  display: block;
  position: relative;
  height: 24px;
  width: 24px;
  border: solid 1px ${prop('borderColor')};
  background-image: linear-gradient(to top, #f6f7f9, ${colors.WHITE});
  border-radius: 50%;
`;

const ErrorIcon = styled.span`
  display: block;
  position: relative;
  height: 24px;
  width: 24px;
  border: solid 1px ${colors.RED};
  background-color: ${colors.RED};
  border-radius: 50%;
  color: ${colors.WHITE};
  font-size: 21px;
  font-weight: bold;
  display: inline-block;
  line-height: 0px;
  padding-top: 11px;
  padding-left: 5px;

  ::before {
    content: '×';
  }
`;

const WarningIcon = styled.span`
  display: block;
  position: relative;
  height: 24px;
  width: 24px;
  border: solid 1px ${colors.ORANGE};
  background-color: ${colors.ORANGE};
  border-radius: 50%;
  color: black;
  font-size: 20px;
  font-weight: bold;
  display: inline-block;
  line-height: 0px;
  padding-top: 12px;
  padding-left: 8px;

  ::before {
    content: '!';
  }
`;

interface ISectionForm<FormShape> {
  formikProps: FormikProps<FormShape>;
  completeFormValues?: IProfilingForm;
  setDropdownOpen(isOpen: boolean): void;
  suggestions: Suggestions[];
}

interface IExpandableCard<FormShape> {
  id: string;
  title: string;
  height: string;
  handleChange: (values: FormShape, isValid: boolean) => void;
  isExpanded: boolean;
  isMobile: boolean;
  handleClick: (id: string) => void;
  SectionForm: React.ComponentType<ISectionForm<FormShape>>;
  initialValues: FormShape;
  completeFormValues?: IProfilingForm;
  validationSchema: any;
  suggestions?: Suggestions[];
  isInitialValid: boolean;
}

interface IFormContainer<FormShape> {
  formikProps: FormikProps<FormShape>;
  handleChange: (values: FormShape, isValid: boolean) => void;
  isExpanded: boolean;
  wasExpanded: boolean;
  SectionForm: React.ComponentType<ISectionForm<FormShape>>;
  completeFormValues?: IProfilingForm;
  setDropdownOpen(isOpen: boolean): void;
  suggestions: Suggestions[];
}

function FormContainer<FormShape>({
  formikProps,
  handleChange,
  isExpanded,
  wasExpanded,
  SectionForm,
  completeFormValues,
  setDropdownOpen,
  suggestions,
}: IFormContainer<FormShape>): JSX.Element {
  const { isValid, isValidating, submitForm, values } = formikProps;

  useEffect(() => {
    if (!isValidating) {
      handleChange(values, isValid);
    }
    // eslint-disable-next-line
  }, [values, isValid, isValidating]);

  useEffect(() => {
    if (!isExpanded && wasExpanded) {
      submitForm();
    }
  }, [isExpanded, submitForm, wasExpanded]);

  return (
    <FormContent>
      <SectionForm
        formikProps={formikProps}
        completeFormValues={completeFormValues}
        setDropdownOpen={setDropdownOpen}
        suggestions={suggestions}
      />
    </FormContent>
  );
}

function ExpandableProfilingCard<FormShape>({
  id,
  title,
  height,
  handleChange,
  handleClick,
  isExpanded,
  isMobile,
  SectionForm,
  initialValues,
  completeFormValues,
  validationSchema,
  suggestions = [],
  isInitialValid,
}: IExpandableCard<FormShape>): JSX.Element {
  const [wasExpanded, setWasExpanded] = useState<boolean>(isExpanded);
  const [isDropdownOpen, setDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    setWasExpanded(isExpanded);
  }, [isExpanded]);

  const getIcon = (formikProps: FormikProps<any>) => {
    if (
      !isEmpty(formikProps.errors) &&
      keys(formikProps.errors).some(key => !!formikProps.touched[key])
    ) {
      return <ErrorIcon />;
    } else if (!isEmpty(suggestions)) {
      return <WarningIcon />;
    } else if (formikProps.isValid) {
      return <Checkmark />;
    } else if (isExpanded) {
      return <DefaultIcon borderColor={colors.AQUA} />;
    } else {
      return <DefaultIcon borderColor={'#d8dce6'} />;
    }
  };

  return (
    <CustomCardContainer isMobile={isMobile}>
      <Formik
        initialValues={initialValues}
        onSubmit={values => handleChange(values, true)}
        validationSchema={validationSchema}
        isInitialValid={isInitialValid}
      >
        {(formikProps: FormikProps<any>) => (
          <Fragment>
            <HeaderContainer onClick={() => handleClick(id)}>
              {getIcon(formikProps)}
              <Title>{title}</Title>
              <ExpandIndicatorContainer>
                <ExpandIndicator expanded={isExpanded} />
              </ExpandIndicatorContainer>
            </HeaderContainer>
            <HeaderBorder />
            <ExpandableContent
              expanded={isExpanded}
              isDropdownOpen={isDropdownOpen}
              height={height}
            >
              <FormContainer<FormShape>
                formikProps={formikProps}
                isExpanded={isExpanded}
                wasExpanded={wasExpanded}
                handleChange={handleChange}
                SectionForm={SectionForm}
                completeFormValues={completeFormValues}
                setDropdownOpen={setDropdownOpen}
                suggestions={suggestions}
              />
            </ExpandableContent>
          </Fragment>
        )}
      </Formik>
    </CustomCardContainer>
  );
}

export default ExpandableProfilingCard;
