import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 38.156609 38.875298';

const ClientsIcon = ({ fill }: { fill: string }) => (
  <g transform="translate(-3.9367922,-3.4710002)">
    <polyline
      fill="none"
      stroke={fill}
      strokeWidth="2.5"
      points="22.9291,19.3955 26.8807,13.5997 31.2715,17.5514 36.4526,10.614 "
    />
    <path
      fill="none"
      stroke={fill}
      strokeWidth="2.5"
      d="m 21.3692,5.3451 h 16.7671 c 1.4889,0 2.7071,1.2184 2.7071,2.707 v 16.8911 c 0,1.4886 -1.2184,2.707 -2.7071,2.707 H 24.672"
    />
    <circle
      fill="none"
      stroke={fill}
      strokeWidth="2.5"
      r="4.9176602"
      cy="9.6386604"
      cx="12.644"
    />
    <path
      fill="none"
      stroke={fill}
      strokeWidth="2.5"
      d="M 8.2136,29.5593 5.2521,29.5515 5.1868,24.1197 c 0.0036,-3.4592 4.9567,-6.4809 7.4572,-6.13 1.3914,-0.1119 6.8759,1.5471 6.844,6.13 v 5.4317 h -2.15 l -1.14,11.5449 H 9.3536 Z"
    />
  </g>
);

export default BaseIcon(ClientsIcon, viewBox);
