import prop from 'ramda/src/prop';
import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import { getColor, getGrade } from '../../utils/score';

const ScoreContainer = styled.div<{ color: string }>`
  align-items: center;
  background-color: ${prop('color')};
  border-radius: 30px;
  color: ${colors.WHITE};
  display: flex;
  font-size: 20px;
  height: 60px;
  justify-content: center;
  line-height: 1;
  width: 60px;
`;

const RiskGrade: React.FunctionComponent<{ score: number }> = ({ score }) => (
  <ScoreContainer color={getColor(score)}>{getGrade(score)}</ScoreContainer>
);

export default RiskGrade;
