import range from 'ramda/src/range';
import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import Label from '../Label';

const LabelContainer = styled.div`
  margin-bottom: 16px;
`;

const Options = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OptionText = styled.div`
  font-size: 12px;
  margin-top: 4px;
`;

const RadioRangeInputContainer = styled.div`
  height: 88px;
`;

const OptionContainer = styled.div<{ disabled: boolean; isChecked: boolean }>`
  display: flex;
  justify-content: space-around;
  position: relative;
  cursor: pointer;
  height: 22px;
  width: 22px;
  background-color: ${({ isChecked, disabled }) => {
    if (isChecked) {
      if (disabled) {
        return colors.DUSK50;
      } else {
        return colors.AQUA50;
      }
    } else {
      return '#eee';
    }
  }};
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
`;

interface IRadioRangeInput {
  disabled?: boolean;
  label: string;
  onBlur?(): void;
  onChange(selectedOption: number): void;
  value: number;
  minimum: number;
  maximum: number;
  lastHasPlus?: boolean;
}

interface IOptionButton {
  checked: boolean;
  onChange: (isChecked: boolean) => void;
  onBlur?(): void;
  disabled?: boolean;
  displayValue: string;
}

const OptionButton = ({
  checked = false,
  displayValue,
  onChange,
  disabled = false,
  onBlur,
}: IOptionButton) => {
  return (
    <OptionContainer disabled={disabled} isChecked={checked}>
      <input
        disabled={disabled}
        checked={checked}
        type="checkbox"
        onChange={e => onChange(e.target.checked)}
        onBlur={onBlur}
      />
      <OptionText>{displayValue}</OptionText>
    </OptionContainer>
  );
};

const RadioRangeInput = ({
  label,
  onBlur,
  onChange,
  value,
  disabled = false,
  minimum,
  maximum,
  lastHasPlus = false,
}: IRadioRangeInput) => {
  const valuesRange = range(minimum, maximum + 1);
  return (
    <RadioRangeInputContainer>
      <LabelContainer>
        <Label>{label}</Label>
      </LabelContainer>
      <Options>
        {valuesRange.map((option, i) => (
          <OptionButton
            key={i}
            checked={value === option}
            onChange={isChecked => onChange(option)}
            onBlur={onBlur}
            disabled={disabled}
            displayValue={
              lastHasPlus && i === valuesRange.length - 1
                ? `${option}+`
                : `${option}`
            }
          />
        ))}
      </Options>
    </RadioRangeInputContainer>
  );
};

export default RadioRangeInput;
