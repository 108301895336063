import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 40.140298 36.988';

const CommissionsIcon = ({ fill }: { fill: string }) => (
  <g transform="translate(-106.56465,-150.74665)">
    <rect
      ry="3.2755001"
      rx="3.2755001"
      height="27.2393"
      width="37.369598"
      y="159.11"
      x="107.95"
      fill="none"
      stroke={fill}
      strokeWidth="2.7707"
    />
    <polyline
      points="118.529,159.11 122,152.585 122.312,152.259 122.863,152.132 130.709,152.132 131.044,152.174 131.313,152.462 135.009,159.11 "
      fill="none"
      stroke={fill}
      strokeWidth="2.7707"
    />
    <text
      fill={fill}
      fontWeight="Bold"
      fontSize="20"
      fontFamily="Arial"
      y="179"
      x="122"
    >
      $
    </text>
  </g>
);

export default BaseIcon(CommissionsIcon, viewBox);
