import isNil from 'ramda/src/isNil';
import values from 'ramda/src/values';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import banksActions from '../../actions/banks';
import colors from '../../constants/colors';
import Button from '../Button';
import Dropdown from '../Dropdown';
import Modal from '../Modal';

const { REACT_APP_API_HOST: HOST } = process.env;

const DownloadApplicationButtonContainer = styled.div`
  margin-left: 8px;
`;

const ModalControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Text = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  margin-bottom: 16px;
`;

interface IDownloadApplicationModalStateProps {
  banks: IBank[] | null;
}

interface IDownloadApplicationModalDispatchProps {
  fetchBanksByProductType: (payload: { productType: ProductTypes }) => void;
}

interface IDownloadApplicationModalOwnProps {
  applicationId: number;
  isOpen: boolean;
  onRequestClose: () => void;
  productType: ProductTypes;
}

const DownloadApplicationModal: React.FunctionComponent<IDownloadApplicationModalStateProps &
  IDownloadApplicationModalOwnProps &
  IDownloadApplicationModalDispatchProps> = ({
  applicationId,
  banks,
  fetchBanksByProductType,
  isOpen,
  onRequestClose,
  productType,
}) => {
  const [selectedBank, setSelectedBank] = useState('');

  useEffect(() => {
    fetchBanksByProductType({ productType });
  }, [fetchBanksByProductType, productType]);

  if (isNil(banks)) {
    return null;
  }

  const bankOptions = banks.map(bank => ({
    text: bank.name,
    value: `${bank.id}`,
  }));

  const modalContent = () => {
    return (
      <div>
        <Text>Selecciona la solicitud que deseas descargar</Text>
        <Dropdown
          onChange={option => {
            setSelectedBank(`${option.value}`);
          }}
          options={bankOptions}
          placeholder="Selecciona un otorgante"
          value={selectedBank}
        />
      </div>
    );
  };

  const modalControls = () => (
    <ModalControlsContainer>
      <Button variant="secondary" onClick={() => onRequestClose()}>
        Cancelar
      </Button>
      <a href={`${HOST}/api/applications/${applicationId}/pdf/${selectedBank}`}>
        <DownloadApplicationButtonContainer>
          <Button>Descargar</Button>
        </DownloadApplicationButtonContainer>
      </a>
    </ModalControlsContainer>
  );

  return (
    <Modal
      content={modalContent}
      controls={modalControls}
      isOpen={isOpen}
      header="Descargar solicitud"
      onRequestClose={onRequestClose}
      overflow="visible"
      shouldCloseOnOverlayClick={true}
      width="400px"
    />
  );
};

const mapStateToProps = (state: any) => ({
  banks: values(state.entities.banks),
});

const creators = {
  fetchBanksByProductType:
    banksActions.creators.fetchBanksByProductType.request,
};

export default connect(mapStateToProps, creators)(DownloadApplicationModal);
