import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import styled from 'styled-components';

import colors from '../../constants/colors';
import { InputMode } from '../FormikInputs/FormikNumber';
import Label from '../Label';
import ToggleCheckbox from '../ToggleCheckbox';

const HalfInput = styled.input<{ disabled?: boolean; isiOS: boolean }>`
  background-color: ${colors.WHITE};
  border: 0;
  color: ${({ disabled }) =>
    disabled ? colors.SECONDARY_TEXT : colors.PRIMARY_TEXT};
  font-family: 'Lato';
  ${props =>
    props.isiOS
      ? // make the element larger so that iOS doesn't zoom in on it and then scale it to keep it the same as the other platforms
        `
        font-size: 16px;
        transform: scale(0.875);
        transform-origin: left top;
        `
      : 'font-size: 14px;'};
  width: 48%;
  height: 22px;
`;

const LabelContainer = styled.div`
  margin-bottom: 16px;
`;

const PercentageCurrencyContainer = styled.div`
  margin-bottom: 34px;
`;

const ErrorMessageContainer = styled.div`
  margin-top: 4px;
`;

const InputModeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InputContainer = styled.div<{
  error?: string;
  isFocused: boolean;
  warning: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 16px 8px 16px;
  border-bottom: solid 2px
    ${({ error, isFocused, warning }) => {
      if (isFocused) {
        return colors.AQUA;
      } else if (error) {
        return colors.RED;
      } else if (warning) {
        return colors.ORANGE;
      } else {
        return colors.DUSK25;
      }
    }};
`;

const ModeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModeText = styled.div<{ color: string }>`
  width: 12px;
  height: 17px;
  font-family: Lato;
  color: ${({ color }) => color};
  margin: 0px 16px;
`;

const Separator = styled.div`
  width: 4%;
  height: 22px;
  font-family: Lato;
  color: ${colors.SECONDARY_TEXT};
  padding: 3px 2px 0px;
  font-size: 14px;
  margin: 0px 16px;
`;

interface IPercentageCurrencyInput {
  disabled?: boolean;
  label: string;
  onChange(selectedQuantity: number): void;
  onBlur(): void;
  value: number;
  total: number;
  error?: string;
  withErrorMessage?: boolean;
  warning?: boolean;
  inputMode?: InputMode;
  isiOS: boolean;
}

const PercentageCurrencyInput: React.FunctionComponent<IPercentageCurrencyInput> = ({
  label,
  onChange,
  onBlur,
  disabled = false,
  value,
  total,
  error,
  withErrorMessage = false,
  warning = false,
  inputMode = 'text',
  isiOS,
}) => {
  const [isPercentage, setIsPercentage] = useState<boolean>(true);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const setValue = (numberValue: number): void => {
    if (!isPercentage) {
      onChange(numberValue > total ? total : numberValue);
    }
  };

  const setValuePercentage = (percentageValue: number): void => {
    const value = (percentageValue * total) / 100;
    if (isPercentage) {
      onChange(value > total ? total : value);
    }
  };

  const errorComponent =
    error && withErrorMessage ? (
      <ErrorMessageContainer>
        <Label error>{error}</Label>
      </ErrorMessageContainer>
    ) : null;
  return (
    <PercentageCurrencyContainer>
      <LabelContainer>
        <Label>{label}</Label>
      </LabelContainer>
      <InputModeContainer>
        <ModeContainer>
          <ModeText
            color={isPercentage ? colors.SECONDARY_TEXT : colors.PRIMARY_TEXT}
          >
            $
          </ModeText>
          <ToggleCheckbox
            onChange={checked => setIsPercentage(checked)}
            value={isPercentage}
            disabled={disabled}
          />
          <ModeText
            color={isPercentage ? colors.PRIMARY_TEXT : colors.SECONDARY_TEXT}
          >
            %
          </ModeText>
        </ModeContainer>
        <InputContainer error={error} isFocused={isFocused} warning={warning}>
          <NumberFormat
            disabled={disabled || isPercentage}
            customInput={HalfInput}
            onFocus={() => setIsFocused(true)}
            onBlur={e => {
              setIsFocused(false);
              onBlur();
            }}
            onValueChange={e => setValue(e.floatValue ? e.floatValue : 0)}
            value={value}
            prefix="$ "
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator={true}
            allowNegative={false}
            inputMode={inputMode}
            isiOS={isiOS}
          />
          <Separator>/</Separator>
          <NumberFormat
            disabled={disabled || !isPercentage}
            customInput={HalfInput}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              setIsFocused(false);
              onBlur();
            }}
            onValueChange={e =>
              setValuePercentage(e.floatValue ? e.floatValue : 0)
            }
            value={total > 0 ? (value / total) * 100 : 0}
            suffix=" %"
            decimalScale={2}
            thousandSeparator={true}
            allowNegative={false}
            inputMode={inputMode}
            isiOS={isiOS}
          />
        </InputContainer>
      </InputModeContainer>
      {errorComponent}
    </PercentageCurrencyContainer>
  );
};

const mapStateProps = (state: any): { isiOS: boolean } => ({
  isiOS: state.deviceData.isiOS,
});

export default connect(mapStateProps)(PercentageCurrencyInput);
