import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const CREDITUS_PROMOTER_CREATE = createAsyncFlowTypes(
  'CREDITUS_PROMOTER_CREATE'
);

const createPromoter = createAsyncFlowCreators(CREDITUS_PROMOTER_CREATE);

export default {
  types: {
    CREDITUS_PROMOTER_CREATE,
  },
  creators: {
    createPromoter,
  },
};
