const sectionFormSendErrors = {
  employment: {
    companyName: {
      required: 'El nombre de la empresa es un campo obligatorio.',
    },
    industryType: { required: 'El tipo de industria es un campo obligatorio.' },
    activityType: { required: 'El giro es un campo obligatorio.' },
    sector: { required: 'El sector es un campo obligatorio.' },
    position: { required: 'El cargo es un campo obligatorio.' },
    street: { required: 'La calle es un campo obligatorio.' },
    exteriorNumber: {
      required: 'El número exterior es un campo obligatorio.',
    },
    postalCode: {
      matches: 'El código postal no es válido.',
      required: 'El código postal es un campo obligatorio.',
    },
    neighborhood: { required: 'La colonia es un campo obligatorio.' },
    phoneNumber: {
      matches: 'El teléfono debe tener 10 dígitos.',
      required: 'El teléfono es un campo obligatorio.',
    },
  },
  previousEmployment: {
    companyName: {
      required: 'El nombre de la empresa es un campo obligatorio.',
    },
    startDate: {
      typeError: 'La fecha de ingreso no es válida.',
      required: 'La fecha de ingreso es un campo obligatorio.',
      lessThanToday: 'La fecha no puede ser mayor a la actual.',
    },
    endDate: {
      typeError: 'La fecha de terminación no es válida.',
      required: 'La fecha de terminación es un campo obligatorio.',
      greaterThanStartDate: 'La fecha no puede ser menor a la de ingreso.',
      lessThanToday: 'La fecha no puede ser mayor a la actual.',
    },
    phoneNumber: {
      matches: 'El teléfono debe tener 10 dígitos.',
      required: 'El teléfono es un campo obligatorio.',
    },
  },
  cosignerEmployment: {
    companyName: {
      required: 'El nombre de la empresa es un campo obligatorio.',
    },
    activityType: { required: 'El giro es un campo obligatorio.' },
    sector: { required: 'El sector es un campo obligatorio.' },
    position: { required: 'El cargo es un campo obligatorio.' },
    street: { required: 'La calle es un campo obligatorio.' },
    exteriorNumber: {
      required: 'El número exterior es un campo obligatorio.',
    },
    postalCode: {
      matches: 'El código postal no es válido.',
      required: 'El código postal es un campo obligatorio.',
    },
    neighborhood: { required: 'La colonia es un campo obligatorio.' },
  },
};

export default sectionFormSendErrors;
