import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import { noop } from '../../utils/misc';

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #eee;
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const Container = styled.label<{ disabled: boolean; warning: boolean }>`
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  height: 16px;
  user-select: none;
  width: 16px;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &:hover input ~ ${Checkmark} {
    background-color: #ccc;
  }

  & input:checked ~ ${Checkmark} {
    background-color: ${({ disabled, warning }) => {
      if (disabled) {
        return colors.DUSK50;
      } else {
        return warning ? colors.ORANGE : colors.AQUA;
      }
    }};
  }

  & input:checked ~ ${Checkmark}:after {
    display: block;
  }

  & ${Checkmark}:after {
    top: 5px;
    left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: white;
  }
`;

interface IRadioButton {
  checked: boolean;
  onBlur?: ((event: React.FocusEvent<HTMLLabelElement>) => void) | undefined;
  onChange: (isChecked: boolean) => void;
  disabled?: boolean;
  warning?: boolean;
}

const RadioButton = ({
  checked = false,
  onBlur = noop,
  onChange = noop,
  disabled = false,
  warning = false,
}: IRadioButton) => (
  <Container onBlur={onBlur} disabled={disabled} warning={warning}>
    <input
      disabled={disabled}
      checked={checked}
      type="checkbox"
      onChange={e => onChange(e.target.checked)}
    />
    <Checkmark />
  </Container>
);

export default RadioButton;
