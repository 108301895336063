import isNil from 'ramda/src/isNil';
import not from 'ramda/src/not';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import documentActions from '../../actions/documents';
import colors from '../../constants/colors';
import {
  documentStatusDropdownOptions,
  documentStatusOptions,
} from '../../constants/documentStatus';
import grammar from '../../constants/grammar';
import DownloadIcon from '../../resources/icons/Download';
import UploadIcon from '../../resources/icons/Upload';
import { isCreditusUser } from '../../utils/permissions';
import ConfirmationDialog from '../ConfirmationDialog';
import Dropdown from '../Dropdown';
import Input from '../Input';
import LoadingIndicator from '../LoadingIndicator';
import StatusIndicator from '../StatusIndicator';
import TableCell from '../TableCell';

const { REACT_APP_API_HOST: HOST } = process.env;

const Cell = styled(TableCell)`
  align-items: center;
  color: ${colors.PRIMARY_TEXT};
  display: flex;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  padding-right: 8px;
`;

const DialogContent = styled.div`
  font-size: 14px;
  text-align: justify;
`;

const DescriptionCell = styled(TableCell)`
  align-items: center;
  color: ${colors.DUSK50};
  display: flex;
  font-weight: normal;
  line-height: 1.57;
  padding-right: 16px;
`;

const DocumentContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 68px;
  padding-left: 36px;
  line-height: 1.57;
`;

const FileControlsContainer = styled.div`
  align-items: center;
  display: flex;
`;

const DownloadIconContainer = styled.div`
  height: 24px;
  padding-top: 4px;
`;

const DocumentStatusContainer = styled.div`
  width: 140px;
`;

const UploadIconContainer = styled.div`
  height: 24px;
  margin-right: 8px;
  padding-bottom: 4px;
  width: 20px;

  & > input {
    visibility: hidden;
    width: 0;
  }
`;

const FileUploadLabel = styled.label`
  cursor: pointer;
`;

const ReviewModeDownloadIconContainer = styled.div`
  margin-right: 24px;
  width: 20px;
`;

const UpdatingMessageContainer = styled.div`
  margin-left: 12px;
`;

class RejectionDialog extends Component {
  state = {
    reason: '',
  };

  onCloseDialog = () => {
    const { onCloseDialog } = this.props;
    onCloseDialog();
  };

  onConfirmDialog = () => {
    const { onConfirmDialog } = this.props;
    onConfirmDialog(this.state.reason);
  };

  renderDialogContent = () => {
    return (
      <DialogContent>
        <Input
          onChange={event => {
            const reason = event.target.value;
            this.setState(() => ({
              reason,
            }));
          }}
          value={this.state.reason}
          label="Motivo por el cual se rechazó el documento"
        />
      </DialogContent>
    );
  };

  render() {
    const { open } = this.props;
    return (
      <ConfirmationDialog
        onCancel={this.onCloseDialog}
        onConfirm={this.onConfirmDialog}
        open={open}
        render={this.renderDialogContent}
        title={'Rechazo'}
      />
    );
  }
}

class DocumentReview extends Component {
  state = {
    open: false,
  };

  onDialogCancel = () => {
    this.setState(() => ({
      open: false,
    }));
  };

  onDialogConfirm = reason => {
    const { document, offerId, updateDocumentStatus } = this.props;

    this.setState(() => ({
      open: false,
    }));
    updateDocumentStatus({
      documentId: document.id,
      offerId,
      status: 'rejected',
      reason: reason,
    });
  };

  render() {
    const {
      offerId,
      document,
      loading,
      readOnly,
      setSectionOverflow,
      status,
      updateDocumentStatus,
      userData,
    } = this.props;
    const documentId = document.id;
    const documentExists =
      document.status !== 'required' && not(isNil(document.extension));
    const downloadUrl = document.url
      ? document.url
      : `${HOST}/api/offers/${offerId}/files/${documentId}`;
    const downloadControl =
      status === 'required' ? null : (
        <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
          <DownloadIconContainer>
            <DownloadIcon size={20} />
          </DownloadIconContainer>
        </a>
      );

    const onDocumentStatusChange = option => {
      if (option.value === 'rejected') {
        this.setState(() => ({
          open: true,
        }));
      } else {
        updateDocumentStatus({ documentId, offerId, status: option.value });
      }
    };

    if (loading) {
      return (
        <FileControlsContainer>
          <LoadingIndicator />
          <UpdatingMessageContainer>Actualizando</UpdatingMessageContainer>
        </FileControlsContainer>
      );
    }
    const statusComponent =
      readOnly || not(documentExists) ? (
        grammar[status]
      ) : (
        <Dropdown
          disabled={isCreditusUser(userData)}
          onOpen={() => setSectionOverflow('visible')}
          onChange={onDocumentStatusChange}
          onClose={() => setSectionOverflow('hidden')}
          options={documentStatusDropdownOptions}
          value={
            documentStatusOptions.find(option => option.value === status).value
          }
          placeholder={
            documentStatusOptions.find(option => option.value === 'required')
              .text
          }
        />
      );

    return (
      <FileControlsContainer>
        <ReviewModeDownloadIconContainer>
          {documentExists ? downloadControl : null}
        </ReviewModeDownloadIconContainer>
        <DocumentStatusContainer>{statusComponent}</DocumentStatusContainer>
        <RejectionDialog
          onCloseDialog={this.onDialogCancel}
          onConfirmDialog={this.onDialogConfirm}
          open={this.state.open}
        />
      </FileControlsContainer>
    );
  }
}
const reviewControlsCreators = {
  updateDocumentStatus: documentActions.creators.updateDocumentStatus.request,
};

const ReviewControls = connect(
  (state, ownProps) => ({
    loading: state.documentUpdatingIndicators[ownProps.document.id],
    userData: state.auth.userData,
  }),
  reviewControlsCreators
)(DocumentReview);

const UploadControls = ({ document, offerId, status, uploadHandler }) => {
  const downloadUrl = document.url
    ? document.url
    : `${HOST}/api/offers/${offerId}/files/${document.id}`;

  const downloadIcon =
    status === 'required' ? null : (
      <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
        <DownloadIconContainer>
          <DownloadIcon size={20} />
        </DownloadIconContainer>
      </a>
    );

  const uploadIcon =
    status === 'approved' ? null : (
      <UploadIconContainer>
        <input id={`file${document.id}`} type="file" onChange={uploadHandler} />
        <FileUploadLabel htmlFor={`file${document.id}`}>
          <UploadIcon size={20} />
        </FileUploadLabel>
      </UploadIconContainer>
    );

  return (
    <FileControlsContainer>
      {uploadIcon}
      {downloadIcon}
    </FileControlsContainer>
  );
};

const Document = ({ document, mode, offer, setSectionOverflow, upload }) => {
  const { id, description, name, status, offerId } = document;

  const uploadHandler = event => {
    if (event.target.files.length) {
      upload({
        fileId: id,
        offerId: offerId,
        files: [event.target.files[0]],
      });
    }
  };

  const controls =
    mode === 'upload' ? (
      <Cell width={'15%'}>
        <UploadControls
          document={document}
          offerId={offerId}
          status={status}
          uploadHandler={uploadHandler}
        />
      </Cell>
    ) : (
      <Cell width={'35%'}>
        <ReviewControls
          document={document}
          offerId={offerId}
          readOnly={offer.stage === 'granted'}
          setSectionOverflow={setSectionOverflow}
          status={status}
        />
      </Cell>
    );

  const statusIndicator =
    mode === 'upload' ? (
      <Cell width={'20%'}>
        <StatusIndicator status={status} />
        {grammar[status]}
      </Cell>
    ) : null;

  return (
    <DocumentContainer>
      <Cell width={'30%'}>{name}</Cell>
      <DescriptionCell width={'35%'}>{description}</DescriptionCell>
      {statusIndicator}
      {controls}
    </DocumentContainer>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { documentId } = ownProps;

  const document = state.entities.offerDocuments[documentId];
  const offer = state.entities.offers[document.offerId];

  return {
    document,
    offer,
  };
};

const creators = {
  upload: documentActions.creators.uploadDocumentFile.request,
};

export default connect(mapStateToProps, creators)(Document);
