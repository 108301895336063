import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import { noop } from '../../utils/misc';

const DANGER_RED = '#CF3014';
const DANGER_BUTTON_BORDER = '#BA0B15';
const INACTIVE_BUTTON_BORDER = '#D8DCE6';
const SECONDARY_WHITE = '#F6F7F9';

interface ButtonStyled {
  variant?: string;
  width?: string;
}

interface Button {
  children: React.ReactNode;
  onClick?(event: React.MouseEvent): void;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'destructive' | 'inactive' | 'secondary' | 'primary';
  width?: string;
}

const getBackgroundColorStyle = ({ variant }: ButtonStyled) => {
  switch (variant) {
    case 'destructive':
      return DANGER_RED;

    case 'inactive':
      return colors.WHITE;

    case 'secondary':
      return '';

    default:
      return colors.AQUA;
  }
};

const getBackgroundImageStyle = ({ variant }: ButtonStyled) => {
  switch (variant) {
    case 'secondary':
      return `linear-gradient(to top, ${SECONDARY_WHITE}, ${colors.WHITE})`;

    default:
      return '';
  }
};

const getBorderStyle = ({ variant }: ButtonStyled) => {
  switch (variant) {
    case 'destructive':
      return `solid 1px ${DANGER_BUTTON_BORDER}`;

    case 'inactive':
      return `solid 1px ${INACTIVE_BUTTON_BORDER}`;

    case 'secondary':
      return `solid 1px ${colors.AQUA}`;

    default:
      return '0';
  }
};

const getColorStyle = ({ variant }: ButtonStyled) => {
  switch (variant) {
    case 'inactive':
      return colors.DUSK25;

    case 'secondary':
      return colors.AQUA;

    default:
      return colors.WHITE;
  }
};

const getCursorStyle = ({ variant }: ButtonStyled) => {
  switch (variant) {
    case 'inactive':
      return 'not-allowed';

    default:
      return '';
  }
};

const getPaddingStyle = ({ variant }: ButtonStyled) => {
  switch (variant) {
    case 'destructive':
    case 'inactive':
    case 'secondary':
      return '7px 12px';

    default:
      return '8px 12px';
  }
};

const getWidthStyle = ({ width }: ButtonStyled) => (width ? width : 'auto');

const ButtonStyled = styled.button<ButtonStyled>`
  background-color: ${getBackgroundColorStyle};
  background-image: ${getBackgroundImageStyle};
  border: ${getBorderStyle};
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.05),
    inset 0 2px 0 0 rgba(255, 255, 255, 0.05);
  box-sizing: border-box;
  color: ${getColorStyle};
  cursor: ${getCursorStyle};
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 1.57;
  min-width: 75px;
  text-align: center;
  padding: ${getPaddingStyle};
  width: ${getWidthStyle};
`;

const Button: React.FunctionComponent<Button> = ({
  children,
  onClick = noop,
  type = 'button',
  variant,
  width,
}) => {
  return (
    <ButtonStyled
      onClick={onClick}
      type={type}
      variant={variant}
      width={width}
      disabled={variant === 'inactive'}
    >
      {children}
    </ButtonStyled>
  );
};

export default Button;
