import { applyMiddleware, compose, createStore } from 'redux';
import { load, save } from 'redux-localstorage-simple';
import createSagaMiddleware from 'redux-saga';

import DevTools from './components/DevTools';
import customMiddleware from './middleware';
import rootReducer from './reducers';
import rootSaga from './sagas';

const enhancers = [];

if (process.env.NODE_ENV === 'development') {
  enhancers.push(DevTools.instrument({ maxAge: 50, shouldCatchErrors: true }));
}

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware, ...customMiddleware];

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  applyMiddleware(save({ states: ['newApplication'] })),
  ...enhancers
);

const persistedState = load({ states: ['newApplication'] });
const store = createStore(rootReducer, persistedState, composedEnhancers);

sagaMiddleware.run(rootSaga);

export default store;
