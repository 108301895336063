import parse from 'date-fns/parse';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import finalOffersActions from '../../actions/finalOffers';
import colors from '../../constants/colors';
import grammar from '../../constants/grammar';
import { isCreditusUser } from '../../utils/permissions';
import Border from '../Border';
import Button from '../Button';
import DayPickerInput from '../DayPickerInput';
import LoadingIndicator from '../LoadingIndicator';
import StatusIndicator from '../StatusIndicator';
import TableCell from '../TableCell';

const Cell = styled(TableCell)`
  align-items: center;
  color: ${colors.PRIMARY_TEXT};
  display: flex;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  padding-right: 8px;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  height: 68px;
  padding-left: 36px;
`;

const DayPickerOverlayButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 8px 0;
`;

const FormalizationDateInputContainer = styled.div`
  font-weight: normal;
  line-height: 1;

  & :focus {
    outline-color: transparent;
    outline-style: none;
  }

  & input {
    text-align: center;
    width: 120px;
  }
`;

const FormalizationDateInput = ({
  finalOffer,
  setSectionOverflow,
  updateFormalizationDate,
  disabled,
}) => {
  const onImmediateButtonClick = () =>
    updateFormalizationDate({
      offerId: finalOffer.offerId,
      formalizationDateImmediate: true,
    });

  const onDayPickerChange = date =>
    updateFormalizationDate({
      offerId: finalOffer.offerId,
      formalizationDate: date,
    });

  const renderOverlay = ({ classNames, children, selectedDay, ...props }) => (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={classNames.overlay}>
        <Border />
        <DayPickerOverlayButtonContainer>
          <div>
            <Button onClick={onImmediateButtonClick}>Lo antes posible</Button>
          </div>
        </DayPickerOverlayButtonContainer>
        <Border />
        {children}
      </div>
    </div>
  );

  const dataPickerValue = finalOffer.formalizationDate
    ? parse(finalOffer.formalizationDate, 'yyyy-MM-dd', new Date())
    : new Date();

  return (
    <FormalizationDateInputContainer>
      <DayPickerInput
        inputProps={{ onFocus: () => setSectionOverflow('visible'), disabled }}
        onChange={onDayPickerChange}
        onDayPickerHide={() => {
          setSectionOverflow('hidden');
        }}
        overlayComponent={renderOverlay}
        value={dataPickerValue}
      />
    </FormalizationDateInputContainer>
  );
};

const getFormalizationDateText = finalOffer => {
  if (finalOffer.formalizationDateImmediate) {
    return 'Lo antes posible';
  }

  return finalOffer.formalizationDate;
};

const ReadOnlyDate = ({ finalOffer }) => (
  <p>{getFormalizationDateText(finalOffer)}</p>
);

const FormalizationDateUpload = ({
  finalOffer,
  setSectionOverflow,
  updateFormalizationDate,
  updatingFormalizationDate,
  userData,
}) => {
  const getDateComonent = () => {
    if (updatingFormalizationDate) {
      return <LoadingIndicator />;
    }

    return finalOffer.formalizationDateStatus === 'approved' ? (
      <ReadOnlyDate finalOffer={finalOffer} />
    ) : (
      <FormalizationDateInput
        finalOffer={finalOffer}
        setSectionOverflow={setSectionOverflow}
        updateFormalizationDate={updateFormalizationDate}
        disabled={isCreditusUser(userData)}
      />
    );
  };

  return (
    <Container>
      <Cell width={'30%'}>Fecha de formalización</Cell>
      <Cell width={'35%'}>{getDateComonent()}</Cell>
      <Cell>
        <StatusIndicator status={finalOffer.formalizationDateStatus} />
        {grammar[finalOffer.formalizationDateStatus]}
      </Cell>
    </Container>
  );
};

const mapStateToProps = state => ({
  userData: state.auth.userData,
  updatingFormalizationDate: state.loaders.updatingFormalizationDate,
});

const creators = {
  updateFormalizationDate:
    finalOffersActions.creators.updateFormalizationDate.request,
};

export default connect(mapStateToProps, creators)(FormalizationDateUpload);
