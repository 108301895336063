import isNil from 'ramda/src/isNil';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import offerActions from '../../actions/offers';
import colors from '../../constants/colors';
import { noop } from '../../utils/misc';
import Button from '../Button';
import LoadingIndicator from '../LoadingIndicator';
import Modal from '../Modal';

const ButtonContainer = styled.div`
  margin: 0px 4px;
`;

const ControlContainer = styled.div`
  height: 36px;
  display: flex;
  justify-content: flex-end;
`;

const DialogContent = styled.div`
  font-size: 14px;
  text-align: justify;
  align-items: center;
  display: flex;
  min-height: 56px;
  justify-content: center;
  width: 100%;
`;

const FileUploadButton = styled.label`
  cursor: pointer;
`;

const ModalHeader = styled.div`
  height: 48px;
  align-items: center;
  display: flex;
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: bold;
`;

const UploadButtonContainer = styled.div`
  margin: 0px 4px;

  & > input {
    visibility: hidden;
    display: none;
  }
`;

interface IReleaseUnitModal {
  offerId: number;
  showModal: boolean;
  uploadingPaymentDocument: boolean;
  closeModal: () => void;
  moveOfferToRelease: Function;
}

const ReleaseUnitModal: React.FunctionComponent<IReleaseUnitModal> = ({
  offerId,
  showModal,
  closeModal,
  moveOfferToRelease,
  uploadingPaymentDocument,
}) => {
  const [uploadingStarted, setUploadingStarted] = useState(false);
  const documentUploaded = uploadingStarted && !uploadingPaymentDocument;

  useEffect(() => {
    if (documentUploaded) {
      closeModal();
    }
  }, [closeModal, documentUploaded]);

  const renderContent = () => (
    <DialogContent>
      {uploadingPaymentDocument ? (
        <LoadingIndicator />
      ) : (
        'Al adjuntar el documento de pago cambiará el estatus del expediente a liberación y se confirmará de manera expresa al comercio que se recibió de manera completa y correcta la totalidad de la documentación requerida, por lo tanto, a partir de este momento se podrá entregar el producto al cliente, ¿Estás seguro de que deseas continuar?'
      )}
    </DialogContent>
  );

  const renderControls = () => (
    <ControlContainer>
      {uploadingPaymentDocument ? null : (
        <Fragment>
          <ButtonContainer>
            <Button variant="secondary" onClick={() => closeModal()}>
              Cancelar
            </Button>
          </ButtonContainer>
          <UploadButtonContainer>
            <input
              id="uploadPayment"
              type="file"
              onChange={event => {
                if (!isNil(event.target.files) && event.target.files!.length) {
                  moveOfferToRelease({
                    offerId: offerId,
                    file: event.target.files![0],
                  });
                  setUploadingStarted(true);
                }
              }}
            />
            <Button onClick={noop}>
              <FileUploadButton htmlFor="uploadPayment">
                Adjuntar
              </FileUploadButton>
            </Button>
          </UploadButtonContainer>
        </Fragment>
      )}
    </ControlContainer>
  );

  return (
    <Modal
      content={() => renderContent()}
      controls={() => renderControls()}
      header={() => (
        <ModalHeader>
          {uploadingPaymentDocument
            ? 'Carga de documentos'
            : 'Liberar producto'}
        </ModalHeader>
      )}
      isOpen={showModal}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      showCloseIcon={true}
      width={'524px'}
    />
  );
};

const mapStateToProps = (state: any) => ({
  uploadingPaymentDocument: state.loaders.uploadingPaymentDocument,
});

const creators = {
  moveOfferToRelease: offerActions.creators.moveOfferToRelease.request,
};

export default connect(mapStateToProps, creators)(ReleaseUnitModal);
