import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const APPLICATION_CREATION = createAsyncFlowTypes('APPLICATION_CREATION');
const SEND_APPLICATION_FEEDBACK = createAsyncFlowTypes(
  'SEND_APPLICATION_FEEDBACK'
);
const createApplication = createAsyncFlowCreators(APPLICATION_CREATION);
const sendApplicationFeedback = createAsyncFlowCreators(
  SEND_APPLICATION_FEEDBACK
);

export default {
  types: {
    APPLICATION_CREATION,
    SEND_APPLICATION_FEEDBACK,
  },
  creators: {
    createApplication,
    sendApplicationFeedback,
  },
};
