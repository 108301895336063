import { call, put, takeLatest } from 'redux-saga/effects';

import banksActions from '../actions/banks';
import toastActions from '../actions/toastNotifications';
import { buildErrorToast } from '../utils/toastNotifications';
import runner from './runners';

function* fetchBankInfo(action: IReduxAction<{ bankId: number }>) {
  const { bankId } = action.payload;

  yield call(runner, banksActions.creators.fetchBankInfo, {
    method: 'GET',
    url: `/api/banks/${bankId}`,
  });
}

function* fetchBanks() {
  yield call(runner, banksActions.creators.fetchBanks, {
    method: 'GET',
    url: '/api/banks',
  });
}

function* fetchBanksByProductType(
  action: IReduxAction<{ productType: ProductTypes }>
) {
  yield call(runner, banksActions.creators.fetchBanksByProductType, {
    method: 'GET',
    url: `/api/banks/product-types/${action.payload.productType}`,
  });
}

function* banksUpdateOfferVigency(
  action: IReduxAction<{ offerId: number; newVigency: string }>
): any {
  const { error } = yield call(
    runner,
    banksActions.creators.banksUpdateOfferVigency,
    {
      method: 'PATCH',
      url: '/api/banks/update-offer-vigency',
      payload: action.payload,
    },
    { snakeCase: false }
  );

  if (error) {
    const toast = buildErrorToast(
      'No fue posible enviar el enlace al cliente. Contacta a soporte si el problema persiste.',
      3000
    );
    yield put(toastActions.creators.showToast(toast));
  }
}

function* watchBankInfoFetchRequested() {
  yield takeLatest(
    banksActions.types.BANKS_INFO_FETCH.REQUESTED,
    fetchBankInfo
  );
}

function* watchFetchBanksRequested() {
  yield takeLatest(banksActions.types.BANKS_FETCH.REQUESTED, fetchBanks);
}

function* watchFetchBanksByProductTypeRequested() {
  yield takeLatest(
    banksActions.types.BANKS_FETCH_BY_PRODUCT_TYPE.REQUESTED,
    fetchBanksByProductType
  );
}

function* watchUpdateOfferVigency(): any {
  yield takeLatest(
    banksActions.types.BANKS_UPDATE_OFFER_VIGENCY.REQUESTED,
    banksUpdateOfferVigency
  );
}

export default {
  watchBankInfoFetchRequested,
  watchFetchBanksRequested,
  watchFetchBanksByProductTypeRequested,
  watchUpdateOfferVigency,
};
