import isNil from 'ramda/src/isNil';
import prop from 'ramda/src/prop';
import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import clientUsersActions from '../../actions/clientUsers';
import offersActions from '../../actions/offers';
import deviceSizes from '../../constants/deviceSizes';
import f from '../../constants/formatters';
import grammar from '../../constants/grammar';
import {
  getOfferData,
  getRemainingOfferValidityDays,
} from '../../utils/offers';
import Button from '../Button';
import Card from '../Card';
import ConfirmationDialogButton from '../ConfirmationDialogButton';
import LoadingIndicator from '../LoadingIndicator';
import MultiSingleRowTable, { getTableValues } from '../MultiSingleRowTable';
import OfferBankDetails from '../OfferBankDetails';
import OfferPaymentsTable from '../OfferPaymentsTable';
import OfferRequirements from '../OfferRequirements';

interface IOfferDetailView {
  activateOffer: Function;
  fetchOffer: Function;
  offer?: any;
  offerId: string;
  history: RouteComponentProps['history'];
  isReadonly?: boolean;
  deviceWidth: number;
  isClient: boolean;
  clientActivateOffer: Function;
}

interface IOfferDetailsProps {
  offer: IOffer;
  deviceWidth: number;
}

const CardBottomMargin = styled.div`
  margin-bottom: 20px;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Grid = styled.div`
  @media (max-width: ${deviceSizes.tablet.width}px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: ${deviceSizes.tablet.width}px) {
    display: grid;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    grid-template-columns: 300px 1fr;
    grid-template-areas: 'bank-requirements details-payments';
  }
`;

const GridArea = styled.div<{ name: string }>`
  @media (min-width: ${deviceSizes.tablet.width}px) {
    grid-area: ${prop('name')};
  }
`;

const List = styled.ul`
  font-size: 10px;
  padding-inline-start: 16px;
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const OfferDetails: React.FunctionComponent<IOfferDetailsProps> = ({
  offer,
  deviceWidth,
}) => {
  const formatted = getOfferData(offer);
  const accessories = formatted.accessories.map(
    (accessory: string, i: number) => <li key={i}>{accessory}</li>
  );
  const rateType = formatted.rateType === 'fixed' ? 'Fija' : 'Variable';
  const rateText = `${f.percentage(
    Number.parseFloat(formatted.rate) * 100,
    2
  )} (${rateType})`;

  const { name, lastName, secondSurname } = formatted.request.dataPersonals[0];
  const userName = `${name} ${lastName} ${secondSurname}`;

  const headers = [
    'ID Oferta',
    'Producto',
    'Tipo de producto',
    'Marca',
    'Modelo',
    'Año',
    'Precio',
    'Enganche solicitado',
    'Monto crédito',
    'Plazo',
    'Tasa de interés',
    'CAT',
    'Comisión apertura',
    'Accesorios',
    'Accesorios',
    'Pago periódico',
    'Fecha oferta',
    'Fecha vigencia',
    'Días restantes',
    'Comisión',
  ];

  const values = [
    formatted.id,
    formatted.productName,
    grammar[formatted.request.type],
    formatted.vehicleManufacturer,
    formatted.vehicleModel,
    formatted.vehicleModelYear,
    f.currency(formatted.vehicleValue),
    f.currency(formatted.downpayment),
    f.currency(formatted.creditAmount),
    formatted.requestedPeriod,
    rateText,
    f.percentage(formatted.cat * 100, 1),
    formatted.fee,
    f.currency(formatted.accessoriesAmount),
    accessories.length >= 1 ? <List>{accessories}</List> : 'Sin accesorios',
    f.currency(formatted.payment),
    f.date(formatted.dateOffer),
    f.date(formatted.dateVigency),
    getRemainingOfferValidityDays(formatted.dateVigency),
    f.percentage(formatted.salespersonCommission * 100),
  ];

  const getColumnLength = (width: number): number => {
    if (width <= 1100) {
      return 3;
    }

    return 4;
  };

  const columnLength = getColumnLength(deviceWidth);

  return (
    <CardBottomMargin>
      <Card header={`Detalle de la oferta - ${userName}`}>
        <MultiSingleRowTable
          gridColumns={`repeat(${columnLength}, 1fr)`}
          headersArray={getTableValues(headers, columnLength)}
          valuesArray={getTableValues(values, columnLength)}
        />
      </Card>
    </CardBottomMargin>
  );
};

const OfferDetailView: React.FunctionComponent<IOfferDetailView> = ({
  activateOffer,
  fetchOffer,
  offer,
  offerId,
  history,
  isReadonly = false,
  deviceWidth,
  isClient,
  clientActivateOffer,
}) => {
  const onDialogConfirm = (): void => {
    isClient
      ? clientActivateOffer({
          applicationId: offer.request.id,
          offerId: offer.id,
        })
      : activateOffer({
          id: offer.id,
          reqId: offer.request.id,
        });
  };

  useEffect(() => {
    isNil(offer) && fetchOffer({ id: offerId });
  }, [offer, offerId, fetchOffer]);

  if (isNil(offer)) {
    return (
      <LoadingIndicatorContainer>
        <LoadingIndicator />
      </LoadingIndicatorContainer>
    );
  }

  return (
    <Fragment>
      <ControlsContainer>
        <Button onClick={() => history.goBack()} variant="secondary">
          Regresar
        </Button>
        <ConfirmationDialogButton
          isReadOnly={isReadonly}
          buttonText={'Aceptar oferta'}
          dialogTitle={'Aceptar oferta'}
          dialogRender={() =>
            'Al aceptar esta oferta se iniciará el trámite para adquirir el producto seleccionado y no podrás regresar a la selección de ofertas, ¿estás seguro de que deseas continuar?'
          }
          onDialogConfirm={onDialogConfirm}
          onDialogCancel={() => 0}
        />
      </ControlsContainer>
      <Grid>
        <GridArea name={'bank-requirements'}>
          <OfferBankDetails offer={offer} />
          <OfferRequirements requirements={offer.requirements} />
        </GridArea>
        <GridArea name={'details-payments'}>
          <OfferDetails offer={offer} deviceWidth={deviceWidth} />
          <OfferPaymentsTable offer={offer} deviceWidth={deviceWidth} />
        </GridArea>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (
  state: any,
  ownProps: IOfferDetailView
): {
  offer: IOffer;
  deviceWidth: number;
} => ({
  offer: state.entities.offers[ownProps.offerId],
  deviceWidth: state.deviceData.clientWidth,
});

const creators = {
  fetchOffer: offersActions.creators.fetchOfferById.request,
  activateOffer: offersActions.creators.activateOffer.request,
  clientActivateOffer: clientUsersActions.creators.clientActivateOffer.request,
};

export default connect(mapStateToProps, creators)(OfferDetailView);
