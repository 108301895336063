import contains from 'ramda/src/contains';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  isBankAdmin,
  isBankAnalyst,
  isBankLegal,
  isBankSuperAnalyst,
  isBankUser,
  isClientUser,
  isCreditusUser,
  isPromoterAdmin,
  isPromoterUser,
} from '../../utils/permissions';
import ApplicationDetailsView from '../ApplicationDetailsView';
import ApprobationView from '../ApprobationView';
import AuditDetailsView from '../AuditDetailsView';
import AuditView from '../AuditView';
import BankCreateManualOffer from '../BankCreateManualOffer';
import BankProfileView from '../BankProfileView';
import ClientApplicationDetailsView from '../ClientApplicationDetails';
import ClientApplicationsView from '../ClientApplicationsView';
import ClientCreditHistoryView from '../ClientCreditHistoryView';
import ClientOffersView from '../ClientOffersView';
import ClientsView from '../ClientsView';
import ClientUploadDocumentView from '../ClientUploadDocumentView';
import CommissionsView from '../CommissionsView';
import ComplianceView from '../ComplianceView';
import CreateManualOfferView from '../CreateManualOfferView';
import CreatePromoterView from '../CreatePromoterView';
import CreateUserForm from '../CreateUserForm';
import CustomerSupportView from '../CustomerSupportView';
import DashboardView from '../DashboardView';
import FormalizationDocumentsView from '../FormalizationDocumentsView';
import GrantedProductsView from '../GrantedProductsView';
import IdVerificationDetailsView from '../IdVerificationDetailsView';
import IdVerificationView from '../IdVerificationView';
import InvoicesView from '../InvoicesView';
import InvoiceView from '../InvoiceView';
import NewApplicationRoutes from '../NewApplicationRoutes';
import OfferDetailView from '../OfferDetailView';
import OfferedApplicationsView from '../OfferedApplicationsView';
import OfferRecordView from '../OfferRecordView';
import PipelineView from '../PipelineView';
import PriceQuoteView from '../PriceQuotingView';
import ProductDetailsView from '../ProductDetailsView';
import ProductsView from '../ProductsView';
import ProfileView from '../ProfileView';
import PromoterProfileView from '../PromoterProfileView';
import RiskProfileView from '../RiskProfileView';
import withUserData from '../UserDataHOC';
import UsersView from '../UsersView';

const AuditViewRouteGuard = withUserData(({ userData, history }) => {
  if (
    isBankAdmin(userData) ||
    isBankSuperAnalyst(userData) ||
    isCreditusUser(userData)
  ) {
    return <AuditView history={history} />;
  }

  return <Redirect to="/pipeline" />;
});

const AuditDetailsViewRouteGuard = withUserData(
  ({ userData, match, history }) => {
    if (
      isBankAdmin(userData) ||
      isBankSuperAnalyst(userData) ||
      isCreditusUser(userData)
    ) {
      const { requestId } = match.params;
      return (
        <AuditDetailsView
          requestId={requestId}
          history={history}
          userData={userData}
        />
      );
    }

    return <Redirect to="/pipeline" />;
  }
);

const BankCreateManualOfferRouteGuards = withUserData(
  ({ history, match, userData }) => {
    if (!isBankUser(userData)) {
      return <Redirect to="/" />;
    }
    return (
      <BankCreateManualOffer
        applicationId={Number.parseInt(match.params.applicationId)}
        history={history}
      />
    );
  }
);

const ComplianceViewRouteGuards = withUserData(({ userData }) => {
  if (isCreditusUser(userData)) {
    return <ComplianceView />;
  }

  return <Redirect to="/pipeline" />;
});

const PipelineViewRouteGuards = withUserData(({ match, userData }) => {
  const { stage } = match.params;

  if (isBankUser(userData)) {
    if (isBankAdmin(userData) && stage === 'activated') {
      return <Redirect to="/pipeline/analysis" />;
    }

    if (isBankAnalyst(userData) && stage !== 'analysis') {
      return <Redirect to="/pipeline/analysis" />;
    }

    if (isBankLegal(userData) && contains(stage, ['activated', 'analysis'])) {
      return <Redirect to="/pipeline/approval" />;
    }
  } else if (isClientUser(userData)) {
    return <Redirect to="/offered-applications" />;
  }

  // Promoters have full access to the pipeline
  return <PipelineView currentStage={stage} />;
});

const NewApplicationRoutesGuard = withUserData(({ userData }) => {
  if (
    !window.location.search.includes('promoterUserCode') &&
    !isPromoterUser(userData)
  ) {
    return <Redirect to="/pipeline" />;
  }

  return <NewApplicationRoutes />;
});

const RiskViewRouteGuard = withUserData(({ userData, history, match }) => {
  if (isBankUser(userData)) {
    const { requestId } = match.params;
    return <RiskProfileView history={history} requestId={requestId} />;
  }
  return <Redirect to="/pipeline" />;
});

const ApplicationDetailsViewRouteGuard = withUserData(
  ({ userData, history, match }) => {
    const { applicationId } = match.params;
    return (
      <ApplicationDetailsView
        history={history}
        applicationId={applicationId}
        userData={userData}
      />
    );
  }
);

const CreateManualOfferViewGuard = withUserData(({ userData }) => {
  if (!isCreditusUser(userData)) {
    return <Redirect to="/pipeline" />;
  }

  return <CreateManualOfferView />;
});

const CreatePromoterViewGuard = withUserData(({ userData }) => {
  if (!isCreditusUser(userData)) {
    return <Redirect to="/pipeline" />;
  }

  return <CreatePromoterView />;
});

const CreateUserFormGuard = withUserData(({ userData }) => {
  if (isBankAdmin(userData) || isPromoterAdmin(userData)) {
    return <CreateUserForm />;
  }
  return <Redirect to="/pipeline" />;
});

const UsersViewRouteGuard = withUserData(({ userData, history }) => {
  if (isBankAdmin(userData) || isPromoterAdmin(userData)) {
    return <UsersView history={history} userData={userData} />;
  }
  return <Redirect to="/pipeline" />;
});

const ClientsViewGuard = withUserData(({ userData, history }) => {
  if (isPromoterUser(userData)) {
    return <ClientsView history={history} />;
  }
  return <Redirect to="/pipeline" />;
});

const CustomerSupportViewGuard = withUserData(({ userData }) => {
  if (isCreditusUser(userData)) {
    return <CustomerSupportView />;
  }
  return <Redirect to="/pipeline" />;
});

const BankProfileViewGuard = withUserData(({ userData }) => {
  if (isBankAdmin(userData)) {
    return <BankProfileView />;
  }
  return <Redirect to="/pipeline" />;
});

const PromoterProfileViewGuard = withUserData(({ userData }) => {
  if (isPromoterAdmin(userData)) {
    return <PromoterProfileView />;
  }
  return <Redirect to="/pipeline" />;
});

const ProductsViewGuard = withUserData(({ userData, history }) => {
  if (isBankAdmin(userData)) {
    return <ProductsView history={history} />;
  }
  return <Redirect to="/pipeline" />;
});

const ProductDetailsViewGuard = withUserData(({ userData, history, match }) => {
  if (isBankAdmin(userData)) {
    return (
      <ProductDetailsView
        history={history}
        productId={match.params.productId}
      />
    );
  }
  return <Redirect to="/pipeline" />;
});

const IdVerificationViewRouteGuards = withUserData(({ userData, history }) => {
  if (isCreditusUser(userData)) {
    return <IdVerificationView history={history} />;
  }

  return <Redirect to="/pipeline" />;
});

const IdVerificationDetailsViewRouteGuards = withUserData(
  ({ userData, history, match }) => {
    if (isCreditusUser(userData)) {
      return (
        <IdVerificationDetailsView
          history={history}
          biometricVerificationId={match.params.biometricVerificationId}
        />
      );
    }

    return <Redirect to="/pipeline" />;
  }
);

const OfferDetailViewGuard = withUserData(({ userData, match, history }) => {
  const isClient = isClientUser(userData);
  return (
    <OfferDetailView
      offerId={match.params.offerId}
      history={history}
      isClient={isClient}
    />
  );
});

const ClientCreditHistoryRouteGuard = withUserData(
  ({ userData, match, history }) => {
    if (
      isClientUser(userData) ||
      isBankAdmin(userData) ||
      isBankSuperAnalyst(userData) ||
      isCreditusUser(userData)
    ) {
      return (
        <ClientCreditHistoryView
          history={history}
          applicationId={match.params.applicationId}
        />
      );
    }

    return <Redirect to="/pipeline" />;
  }
);

const ClientApplicationsRouteGuard = withUserData(({ userData, history }) => {
  if (isClientUser(userData)) {
    return <ClientApplicationsView history={history} />;
  }

  return <Redirect to="/pipeline" />;
});

const ClientApplicationDetails = withUserData(
  ({ userData, history, match }) => {
    if (isClientUser(userData)) {
      return (
        <ClientApplicationDetailsView
          history={history}
          applicationId={parseInt(match.params.applicationId)}
        />
      );
    }

    return <Redirect to="/pipeline" />;
  }
);

const ClientOffersViewRouteGuard = withUserData(
  ({ userData, history, match }) => {
    if (isClientUser(userData)) {
      return (
        <ClientOffersView
          applicationId={parseInt(match.params.applicationId)}
          offerId={parseInt(match.params.offerId)}
          history={history}
        />
      );
    }
  }
);

const ClientUploadDocumentRouteGuard = withUserData(
  ({ userData, history, match }) => {
    if (isClientUser(userData)) {
      return (
        <ClientUploadDocumentView
          applicationId={parseInt(match.params.applicationId)}
          offerId={parseInt(match.params.offerId)}
          documentId={parseInt(match.params.documentId)}
          history={history}
        />
      );
    }
  }
);

const MainRoutes = () => (
  <Switch>
    <Route
      component={ApplicationDetailsViewRouteGuard}
      path="/application-details/:applicationId"
    />
    <Route
      component={ApprobationView}
      path="/pipeline/offer/:offerId/approbation"
    />
    <Route
      component={ClientCreditHistoryRouteGuard}
      path="/audit/:applicationId/credit-history"
    />
    <Route component={AuditDetailsViewRouteGuard} path="/audit/:requestId" />
    <Route component={AuditViewRouteGuard} path="/audit" />
    <Route
      component={BankCreateManualOfferRouteGuards}
      path="/applications/:applicationId/offer/new"
    />
    <Route component={BankProfileViewGuard} path="/bank" />
    <Route component={ClientsViewGuard} path="/clients" />
    <Route
      component={CommissionsView}
      path="/commissions/:type(receivable|payable)"
    />
    <Route component={ComplianceViewRouteGuards} path="/compliance" />
    <Route component={ClientCreditHistoryRouteGuard} path="/credit-history" />
    <Route
      component={ClientApplicationsRouteGuard}
      exact
      path="/offered-applications"
    />
    <Route
      component={ClientApplicationDetails}
      exact
      path="/offered-applications/:applicationId"
    />
    <Route
      component={ClientOffersViewRouteGuard}
      exact
      path="/offered-applications/:applicationId/offer/:offerId"
    />
    <Route
      component={ClientUploadDocumentRouteGuard}
      exact
      path="/offered-applications/:applicationId/offer/:offerId/documents/:documentId"
    />
    <Route component={CreateManualOfferViewGuard} path="/offers/new" />
    <Route component={CreatePromoterViewGuard} path="/promoters/new" />
    <Route component={CreateUserFormGuard} path="/users/new" />
    <Route component={CustomerSupportViewGuard} path="/customer-support" />
    <Route component={DashboardView} path="/dashboard" />
    <Route
      component={FormalizationDocumentsView}
      path="/pipeline/offer/:offerId/formalization-documents"
    />
    <Route component={GrantedProductsView} path="/granted-products" />
    <Route
      component={IdVerificationDetailsViewRouteGuards}
      path="/id-verification/:biometricVerificationId"
    />
    <Route component={IdVerificationViewRouteGuards} path="/id-verification" />
    <Route
      component={InvoicesView}
      path="/invoices/:type(payable|receivable)"
    />
    <Route component={InvoiceView} path="/invoices/:invoiceId" />
    <Route component={NewApplicationRoutesGuard} path="/application" />
    <Route component={NewApplicationRoutesGuard} path="/client/application" />
    <Route component={OfferDetailViewGuard} path="/offer/:offerId" />
    <Route component={OfferRecordView} exact path="/pipeline/offer/:offerId" />
    <Route
      component={ClientCreditHistoryRouteGuard}
      exact
      path="/pipeline/offer/:offerId/:applicationId/credit-history"
    />
    <Route
      component={PipelineViewRouteGuards}
      path="/pipeline/:stage(activated|analysis|approval|pre-formalization|formalization|release|offered)"
    />
    <Route component={PriceQuoteView} path="/price-quoting" />
    <Route component={ProductDetailsViewGuard} path="/products/:productId" />
    <Route component={ProductsViewGuard} path="/products" />
    <Route component={ProfileView} path="/profile" />
    <Route component={PromoterProfileViewGuard} path="/promoter" />
    <Route component={OfferedApplicationsView} path="/offers" />
    <Route component={RiskViewRouteGuard} path="/risk-profile/:requestId" />
    <Route component={UsersViewRouteGuard} path="/users" />
    <Redirect from="*" to="/pipeline/activated" />
  </Switch>
);

export default MainRoutes;
