import { Form, FormikProps } from 'formik';
import React from 'react';
import styled from 'styled-components';

import Button from '../Button';
import { FormikRadio, FormikText } from '../FormikInputs';

const FormContainer = styled.div`
  width: 280px;
`;

type SearchType = 'email' | 'phoneNumber' | 'userId';

interface ISearchUserForm {
  formikProps: FormikProps<{
    searchType: SearchType;
    data: string;
  }>;
}

const SearchUserForm: React.FunctionComponent<ISearchUserForm> = ({
  formikProps,
}) => {
  const getInputLabel = (option: SearchType): string => {
    switch (option) {
      case 'email':
        return 'Correo electrónico';
      case 'phoneNumber':
        return 'Teléfono del usuario';
      default:
        return 'ID del usuario';
    }
  };

  return (
    <Form>
      <FormContainer>
        <FormikRadio
          label="Método de búsqueda"
          name="searchType"
          alignment="vertical"
          height="118px"
          options={[
            {
              text: 'Correo electrónico',
              value: 'email',
            },
            {
              text: 'Número de teléfono',
              value: 'phoneNumber',
            },
            {
              text: 'ID de usuario',
              value: 'userId',
            },
          ]}
        />
        <FormikText
          label={getInputLabel(formikProps.values.searchType)}
          name="data"
        />
        <Button
          onClick={() => {
            formikProps.submitForm();
          }}
        >
          Buscar
        </Button>
      </FormContainer>
    </Form>
  );
};

export default SearchUserForm;
