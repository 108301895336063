import React, { Fragment } from 'react';

import TableHeader from '../TableHeader';

const Table = ({ data, headers, rowComponent: RowComponent, rowProps }) => (
  <Fragment>
    <TableHeader headers={headers} />
    {data.map((datum, i) => (
      <RowComponent
        {...rowProps}
        key={i}
        index={i}
        datum={datum}
        dataLength={data.length}
      />
    ))}
  </Fragment>
);

export default Table;
