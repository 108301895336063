import isNil from 'ramda/src/isNil';
import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import offersActions from '../actions/offers';
import toastActions from '../actions/toastNotifications';
import { isBankUser } from '../utils/permissions';
import {
  buildErrorToast,
  buildSuccessToast,
} from '../utils/toastNotifications';
import runner from './runners';

function* fetchOffers(action: IReduxAction<any>) {
  yield call(runner, offersActions.creators.fetchOffers, {
    method: 'GET',
    url: '/api/offers',
    params: action.payload,
  });
}

function* watchOffersFetchRequested() {
  yield takeLatest(offersActions.types.OFFERS_FETCH.REQUESTED, fetchOffers);
}

function* activateOffer(action: IReduxAction<any>) {
  yield call(runner, offersActions.creators.activateOffer, {
    method: 'POST',
    url: '/api/offers',
    payload: action.payload,
  });

  yield call(fetchOffers, { type: '', payload: { stage: 'activated' } });
}

function* watchActivateOfferRequested() {
  yield takeLatest(
    offersActions.types.OFFERS_ACTIVATE.REQUESTED,
    activateOffer
  );
}

function* fetchOfferCounts() {
  yield call(runner, offersActions.creators.fetchOfferCounts, {
    method: 'GET',
    url: '/api/offers/count',
  });
}

function* watchOffersFetchStarted() {
  yield takeLatest(offersActions.types.OFFERS_FETCH.STARTED, function*() {
    yield fork(fetchOfferCounts);
  });
}

function* fetchOfferById(action: IReduxAction<any>) {
  yield call(runner, offersActions.creators.fetchOfferById, {
    method: 'GET',
    url: `/api/offers/${action.payload.id}`,
  });
}

function* watchFetchOfferByIdRequested() {
  yield takeLatest(
    offersActions.types.OFFERS_FETCH_BY_ID.REQUESTED,
    fetchOfferById
  );
}

function* fetchOfferRequiredDocuments(action: IReduxAction<any>) {
  const { offerId } = action.payload;

  const { error } = yield call(
    runner,
    offersActions.creators.fetchOfferRequiredDocuments,
    {
      method: 'GET',
      url: `/api/offers/${offerId}/documents`,
    }
  );

  if (!isNil(error) && error.response.status === 403) {
    yield put(offersActions.creators.redirectToOffered());
  }
}

function* watchFetchOfferRequiredDocuments() {
  yield takeLatest(
    offersActions.types.OFFERS_FETCH_REQUIRED_DOCUMENTS.REQUESTED,
    fetchOfferRequiredDocuments
  );
}

function* moveOfferToFormalization(action: IReduxAction<any>) {
  const { offerId } = action.payload;

  yield call(runner, offersActions.creators.moveOfferToFormalization, {
    method: 'PATCH',
    payload: { stage: 'formalization' },
    url: `/api/offers/${offerId}`,
  });

  yield call(fetchOfferById, { type: '', payload: { id: offerId } });
  yield call(fetchOfferRequiredDocuments, { type: '', payload: { offerId } });
}

function* watchMoveOfferToFormalizationRequested() {
  yield takeLatest(
    offersActions.types.OFFERS_MOVE_TO_FORMALIZATION.REQUESTED,
    moveOfferToFormalization
  );
}

const UNEXPECTED_ERROR_TOAST_MESSAGE =
  'Hubo un error al liberar la unidad. Podemos atenderte en el chat de ayuda.';
const IMAGE_FORMAT_TOAST_MESSAGE =
  'El formato del documento es inválido. Por favor, suba uno diferente.';
const IMAGE_ERROR_TOAST_MESSAGE =
  'Hubo un error al subir el documento. Podemos atenderte en el chat de ayuda.';

function* moveOfferToRelease(action: IReduxAction<any>) {
  const { offerId, file } = action.payload;

  const payload = new FormData();
  payload.append('document', file);

  const response = yield call(
    runner,
    offersActions.creators.moveOfferToRelease,
    {
      payload,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'POST',
      url: `/api/offers/${offerId}/release`,
    }
  );

  if (response.error) {
    const message = (() => {
      const statusCode = response.error.response.status;
      switch (statusCode) {
        case 415:
          return IMAGE_FORMAT_TOAST_MESSAGE;
        case 503:
          return IMAGE_ERROR_TOAST_MESSAGE;
        default:
          return UNEXPECTED_ERROR_TOAST_MESSAGE;
      }
    })();
    const toast = buildErrorToast(message, 5000);
    yield put(toastActions.creators.showToast(toast));
  }

  yield call(fetchOfferById, { type: '', payload: { id: offerId } });
  yield call(fetchOfferRequiredDocuments, { type: '', payload: { offerId } });
}

function* watchMoveOfferToReleaseRequested() {
  yield takeLatest(
    offersActions.types.OFFERS_MOVE_TO_RELEASE.REQUESTED,
    moveOfferToRelease
  );
}

function* createManualOffer({ payload }: IReduxAction<any>) {
  const { userData }: { userData: IUserData } = yield select(
    (state: any) => state.auth
  );

  const endpoint = (() => {
    if (isBankUser(userData)) {
      return `/api/banks/${userData.organization.bankId}/offers`;
    } else {
      return '/api/creditus/offers';
    }
  })();

  const { error } = yield call(
    runner,
    offersActions.creators.createManualOffer,
    {
      payload,
      method: 'POST',
      url: endpoint,
    },
    { snakeCase: false }
  );

  if (error) {
    yield put(
      toastActions.creators.showToast(
        buildErrorToast(
          'Un error ocurrió al intentar crear la oferta. Por favor, contacta a Soporte.',
          2000
        )
      )
    );
  } else {
    yield put(
      toastActions.creators.showToast(
        buildSuccessToast('Oferta creada exitosamente.', 2000)
      )
    );
  }
}

function* watchCreateManualOffer() {
  yield takeLatest(
    offersActions.types.OFFERS_MANUAL_CREATE.REQUESTED,
    createManualOffer
  );
}

export default {
  watchOffersFetchRequested,
  watchOffersFetchStarted,
  watchFetchOfferByIdRequested,
  watchFetchOfferRequiredDocuments,
  watchActivateOfferRequested,
  watchMoveOfferToFormalizationRequested,
  watchMoveOfferToReleaseRequested,
  watchCreateManualOffer,
};
