const sectionFormSendErrors = {
  consent: {
    test: 'Es necesario aceptar la consulta crediticia.',
    required: 'Es necesario aceptar la consulta crediticia.',
  },
  nip: {
    matches: 'El NIP no es válido.',
    required: 'El NIP es un campo obligatorio.',
  },
  granters: {
    test: 'Debes seleccionar al menos un otorgante.',
    required: 'Debes seleccionar al menos un otorgante.',
  },
};

export default sectionFormSendErrors;
