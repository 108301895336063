import { FormikProps } from 'formik';
import length from 'ramda/src/length';
import not from 'ramda/src/not';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
  ACTIVITY_TYPE_OPTIONS,
  INDUSTRY_TYPE_OPTIONS,
} from '../../constants/applicationOptions';
import colors from '../../constants/colors';
import usePostalCodeOptions from '../../hooks/usePostalCodeOptions';
import usePostalCodeRelatedFieldsAutoFill from '../../hooks/usePostalCodeRelatedFieldsAutoFill';
import usePostalCodeRelatedFieldsCleanup from '../../hooks/usePostalCodeRelatedFieldsCleanup';
import {
  FormikDate,
  FormikDropdown,
  FormikPhone,
  FormikText,
} from '../FormikInputs';
import { Section } from '../MultiSectionForm';
import errorMsg from './errorMessages';

const Container = styled.div<{ isMobile: boolean }>`
  background-color: ${colors.WHITE};
  ${props => (props.isMobile ? '' : 'display: flex;')}
  height: 100%;
  justify-content: center;
  padding-top: 36px;
  width: 100%;

  & span {
    font-weight: normal;
  }
`;

const Grid = styled.div<{ isMobile: boolean }>`
  ${props =>
    props.isMobile
      ? 'margin: auto; width: 85%;'
      : 'display: grid; grid-column-gap: 24px; grid-template-columns: 280px 280px;'}
`;

const SubSectionName = styled.div<{ isMobile: boolean }>`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: bold;
  ${props =>
    props.isMobile
      ? 'margin: auto; text-align: center; padding-bottom: 10px;'
      : 'width: 128px;'}
`;

const postalCodeRegExp = /^\d{5}$/;

const dateLessThanToday = (date: Date) => new Date() > date;

const cosignerEmploymentSchema = Yup.object().shape({
  companyName: Yup.string().required(
    errorMsg.cosignerEmployment.companyName.required
  ),
  activityType: Yup.string().required(
    errorMsg.cosignerEmployment.activityType.required
  ),
  sector: Yup.string().required(errorMsg.cosignerEmployment.sector.required),
  position: Yup.string().required(
    errorMsg.cosignerEmployment.position.required
  ),
  street: Yup.string().required(errorMsg.cosignerEmployment.street.required),
  exteriorNumber: Yup.string().required(
    errorMsg.cosignerEmployment.exteriorNumber.required
  ),
  suiteNumber: Yup.string(),
  postalCode: Yup.string()
    .matches(postalCodeRegExp, {
      message: errorMsg.cosignerEmployment.postalCode.matches,
    })
    .required(errorMsg.cosignerEmployment.postalCode.required),
  neighborhood: Yup.string().required(
    errorMsg.cosignerEmployment.neighborhood.required
  ),
  municipality: Yup.string().required(),
  city: Yup.string(),
  state: Yup.string().required(),
  country: Yup.string().required(),
});

const employmentSchema = Yup.object().shape({
  companyName: Yup.string().required(errorMsg.employment.companyName.required),
  industryType: Yup.string().required(
    errorMsg.employment.industryType.required
  ),
  activityType: Yup.string().required(
    errorMsg.employment.activityType.required
  ),
  sector: Yup.string().required(errorMsg.employment.sector.required),
  position: Yup.string().required(errorMsg.employment.position.required),
  street: Yup.string().required(errorMsg.employment.street.required),
  exteriorNumber: Yup.string().required(
    errorMsg.employment.exteriorNumber.required
  ),
  suiteNumber: Yup.string(),
  postalCode: Yup.string()
    .matches(/^\d{5}$/, { message: errorMsg.employment.postalCode.matches })
    .required(errorMsg.employment.postalCode.required),
  neighborhood: Yup.string().required(
    errorMsg.employment.neighborhood.required
  ),
  municipality: Yup.string().required(),
  state: Yup.string().required(),
  country: Yup.string().required(),
  phoneNumber: Yup.string(),
});

const previousEmploymentSchema = Yup.object().shape({
  companyName: Yup.string().required(
    errorMsg.previousEmployment.companyName.required
  ),
  startDate: Yup.date()
    .typeError(errorMsg.previousEmployment.startDate.typeError)
    .required(errorMsg.previousEmployment.startDate.required)
    .test(
      'lessThanToday',
      errorMsg.previousEmployment.startDate.lessThanToday,
      (endDate: Date) => dateLessThanToday(endDate)
    ),
  endDate: Yup.date()
    .typeError(errorMsg.previousEmployment.endDate.typeError)
    .required(errorMsg.previousEmployment.endDate.required)
    .test(
      'greaterThanStartDate',
      errorMsg.previousEmployment.endDate.greaterThanStartDate,
      function(endDate: Date) {
        const startDate = this.resolve(Yup.ref('startDate'));
        if (startDate === 'Invalid Date') {
          return true;
        } else {
          return endDate > startDate;
        }
      }
    )
    .test(
      'lessThanToday',
      errorMsg.previousEmployment.endDate.lessThanToday,
      (endDate: Date) => dateLessThanToday(endDate)
    ),
  phoneNumber: Yup.string(),
});

const getEmploymentSectionSchema = ({
  hasEmployment,
  hasPreviousEmployment,
  shouldAskForCosignerEmployment,
}: IEmploymentSectionConfig) => {
  return Yup.object().shape({
    employment: employmentSchema.nullable(!hasEmployment),
    previousEmployment: previousEmploymentSchema.nullable(
      !hasPreviousEmployment
    ),
    cosignerEmployment: cosignerEmploymentSchema.nullable(
      !shouldAskForCosignerEmployment
    ),
  });
};

const getEmploymentSectionInitialValues = ({
  hasEmployment,
  hasPreviousEmployment,
  shouldAskForCosignerEmployment,
}: IEmploymentSectionConfig) => {
  const employmentInitialValues = {
    companyName: '',
    industryType: '',
    activityType: '',
    sector: '',
    position: '',
    street: '',
    exteriorNumber: '',
    suiteNumber: '',
    postalCode: '',
    neighborhood: '',
    municipality: '',
    city: '',
    state: '',
    country: '',
    phoneNumber: '',
  };

  const previousEmploymentInitialValues = {
    companyName: '',
    startDate: '',
    endDate: '',
    phoneNumber: '',
  };

  const cosignerEmploymentInitialValues = {
    companyName: '',
    activityType: '',
    sector: '',
    position: '',
    street: '',
    exteriorNumber: '',
    suiteNumber: '',
    postalCode: '',
    neighborhood: '',
    municipality: '',
    city: '',
    state: '',
    country: '',
  };

  return {
    employment: hasEmployment ? employmentInitialValues : null,
    previousEmployment: hasPreviousEmployment
      ? previousEmploymentInitialValues
      : null,
    cosignerEmployment: shouldAskForCosignerEmployment
      ? cosignerEmploymentInitialValues
      : null,
  };
};

interface IEmploymentSectionComponent {
  isMobile: boolean;
  formikProps: FormikProps<any>;
}

const EmploymentSectionComponent: React.FunctionComponent<IEmploymentSectionComponent> = ({
  isMobile,
  formikProps,
}) => {
  const options = usePostalCodeOptions(formikProps, 'employment.postalCode');
  usePostalCodeRelatedFieldsAutoFill(formikProps, options, 'employment');
  usePostalCodeRelatedFieldsCleanup(formikProps, 'employment');

  const cosignerEemploymentPostalCodeOptions = usePostalCodeOptions(
    formikProps,
    'cosignerEmployment.postalCode'
  );
  usePostalCodeRelatedFieldsAutoFill(
    formikProps,
    cosignerEemploymentPostalCodeOptions,
    'cosignerEmployment'
  );
  usePostalCodeRelatedFieldsCleanup(formikProps, 'cosignerEmployment');

  const renderEmploymentSubSection = formikProps.values.employment !== null;
  const renderPreviousEmploymentSubSection =
    formikProps.values.previousEmployment !== null;
  const renderCosignerEmployment =
    formikProps.values.cosignerEmployment !== null;

  return (
    <Fragment>
      {renderEmploymentSubSection ? (
        <Fragment>
          <Container isMobile={isMobile}>
            <SubSectionName isMobile={isMobile}>
              Empleo del solicitante
            </SubSectionName>
            <Grid isMobile={isMobile}>
              <FormikText
                label="NOMBRE DE LA EMPRESA"
                name="employment.companyName"
              />
              <FormikDropdown
                label="TIPO DE INDUSTRIA"
                name="employment.industryType"
                options={INDUSTRY_TYPE_OPTIONS}
                placeholder={'Tipo de industria'}
              />
              <FormikText label="GIRO" name="employment.activityType" />
              <FormikDropdown
                label="SECTOR"
                name="employment.sector"
                options={ACTIVITY_TYPE_OPTIONS}
                placeholder={'Sector'}
              />
              <FormikText label="CARGO" name="employment.position" />
            </Grid>
          </Container>
          <Container isMobile={isMobile}>
            <SubSectionName isMobile={isMobile}>
              Domicilio del empleo del solicitante
            </SubSectionName>
            <Grid isMobile={isMobile}>
              <FormikText label="CALLE" name="employment.street" />
              <FormikText
                label="NÚMERO EXTERIOR"
                name="employment.exteriorNumber"
                inputMode="numeric"
              />
              <FormikText
                label="NÚMERO INTERIOR"
                name="employment.suiteNumber"
                inputMode="numeric"
              />
              <FormikText
                label="CÓDIGO POSTAL"
                name="employment.postalCode"
                maxLength={5}
                inputMode="numeric"
              />
              <FormikDropdown
                disabled={not(length(options))}
                label="COLONIA"
                name="employment.neighborhood"
                options={options.map(option => ({
                  text: option.colonia,
                  value: option.colonia,
                }))}
                placeholder="Colonia"
              />
            </Grid>
          </Container>
        </Fragment>
      ) : null}
      {renderPreviousEmploymentSubSection ? (
        <Container isMobile={isMobile}>
          <SubSectionName isMobile={isMobile}>
            Empleo anterior del solicitante
          </SubSectionName>
          <Grid isMobile={isMobile}>
            <FormikText
              label="NOMBRE DE LA EMPRESA"
              name="previousEmployment.companyName"
            />
            <FormikDate
              label="FECHA DE INGRESO"
              name="previousEmployment.startDate"
              ignoreDay={true}
              inputMode="numeric"
            />
            <FormikDate
              label="FECHA DE TERMINACIÓN"
              name="previousEmployment.endDate"
              ignoreDay={true}
              inputMode="numeric"
            />
            <FormikPhone
              label="TELÉFONO"
              name="previousEmployment.phoneNumber"
              inputMode="numeric"
            />
          </Grid>
        </Container>
      ) : null}
      {renderCosignerEmployment ? (
        <Container isMobile={isMobile}>
          <SubSectionName isMobile={isMobile}>
            Empleo del coacreditado
          </SubSectionName>
          <Grid isMobile={isMobile}>
            <FormikText
              label="NOMBRE DE LA EMPRESA"
              name="cosignerEmployment.companyName"
            />
            <FormikText label="GIRO" name="cosignerEmployment.activityType" />
            <FormikDropdown
              label="SECTOR"
              name="cosignerEmployment.sector"
              options={ACTIVITY_TYPE_OPTIONS}
              placeholder={'Sector'}
            />
            <FormikText label="CARGO" name="cosignerEmployment.position" />
            <FormikText label="CALLE" name="cosignerEmployment.street" />
            <FormikText
              label="NÚMERO EXTERIOR"
              name="cosignerEmployment.exteriorNumber"
              inputMode="numeric"
            />
            <FormikText
              label="NÚMERO INTERIOR"
              name="cosignerEmployment.suiteNumber"
            />
            <FormikText
              label="CÓDIGO POSTAL"
              name="cosignerEmployment.postalCode"
              maxLength={5}
              inputMode="numeric"
            />
            <FormikDropdown
              disabled={not(length(cosignerEemploymentPostalCodeOptions))}
              label="COLONIA"
              name="cosignerEmployment.neighborhood"
              options={cosignerEemploymentPostalCodeOptions.map(option => ({
                text: option.colonia,
                value: option.colonia,
              }))}
              placeholder="Colonia"
            />
          </Grid>
        </Container>
      ) : null}
    </Fragment>
  );
};

const EmploymentStateProps = (state: any): { isMobile: boolean } => ({
  isMobile: state.deviceData.isMobile,
});

const EmploymentSectionWithProps = connect(EmploymentStateProps)(
  EmploymentSectionComponent
);

interface IEmploymentSectionConfig {
  hasEmployment: boolean;
  hasPreviousEmployment: boolean;
  shouldAskForCosignerEmployment: boolean;
}

const EmploymentSection = ({
  hasEmployment,
  hasPreviousEmployment,
  shouldAskForCosignerEmployment,
}: IEmploymentSectionConfig) => {
  return (
    <Section
      initialValues={getEmploymentSectionInitialValues({
        hasEmployment,
        hasPreviousEmployment,
        shouldAskForCosignerEmployment,
      })}
      name={'employment'}
      component={EmploymentSectionWithProps}
      sectionSchema={getEmploymentSectionSchema({
        hasEmployment,
        hasPreviousEmployment,
        shouldAskForCosignerEmployment,
      })}
    />
  );
};

export default EmploymentSection;
