import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../constants/colors';
import useFetchTemplateDocuments from '../../hooks/useFetchTemplateDocuments';
import Button from '../Button';
import withOfferFetching from '../FetchOfferHOC';
import FormalizationDocumentsForm from '../FormalizationDocumentsForm';
import LoadingIndicator from '../LoadingIndicator';

const BackButtonContainer = styled.div`
  margin-bottom: 20px;
`;

const LoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 50vh;
  justify-content: center;
  width: 100%;
`;

interface IProps {
  offer: any;
  offerId: string;
  loading: boolean;
}

const FormalizationDocumentsView: React.FunctionComponent<IProps> = ({
  offer,
  offerId,
  loading,
}) => {
  const documents = useFetchTemplateDocuments(offerId, 'pre-formalization');

  if (loading || documents === null) {
    return (
      <LoadingState>
        <LoadingIndicator />
      </LoadingState>
    );
  }

  return (
    <Fragment>
      <BackButtonContainer>
        <Link to={`/pipeline/offer/${offer.id}`}>
          <Button variant="secondary">Regresar</Button>
        </Link>
      </BackButtonContainer>
      <FormalizationDocumentsForm offer={offer} templateDocuments={documents} />
    </Fragment>
  );
};

const FormalizationDocumentsViewWrapper = withOfferFetching(
  (offer: any, loading: boolean, offerId: string) => (
    <FormalizationDocumentsView
      offer={offer}
      offerId={offerId}
      loading={loading}
    />
  )
);

export default FormalizationDocumentsViewWrapper;
