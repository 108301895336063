import { handleActions } from 'redux-actions';

const initialState = {};

const promoterReducer = handleActions(
  {
    PROMOTERS_INFO_FETCH_COMPLETED: (state, action) => action.payload.data,
  },
  initialState
);

export default promoterReducer;
