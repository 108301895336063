import { createAction } from 'redux-actions';

import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const AUTH_FLOW_STARTED = 'AUTH_FLOW_STARTED';
const AUTH_CHECK_SESSION = 'AUTH_CHECK_SESSION';
const AUTH_SESSION_EXPIRED = 'AUTH_SESSION_EXPIRED';
const AUTH_SESSION_VALID = 'AUTH_SESSION_VALID';
const AUTH_SESSION_NOT_FOUND = 'AUTH_SESSION_NOT_FOUND';

const AUTH_LOGIN = createAsyncFlowTypes('AUTH_LOGIN');
const AUTH_LOGOUT = createAsyncFlowTypes('AUTH_LOGOUT');
const AUTH_USER_DATA_FETCH = createAsyncFlowTypes('AUTH_USER_DATA_FETCH');
const GET_LOGIN_TYPE = createAsyncFlowTypes('GET_LOGIN_TYPE');

const startAuthFlow = createAction(AUTH_FLOW_STARTED);
const checkSession = createAction(AUTH_CHECK_SESSION);
const sessionExpired = createAction(AUTH_SESSION_EXPIRED);
const sessionValid = createAction(AUTH_SESSION_VALID);
const sessionNotFound = createAction(AUTH_SESSION_NOT_FOUND);

const fetchuserData = createAsyncFlowCreators(AUTH_USER_DATA_FETCH);
const login = createAsyncFlowCreators(AUTH_LOGIN);
const logout = createAsyncFlowCreators(AUTH_LOGOUT);
const getLoginType = createAsyncFlowCreators(GET_LOGIN_TYPE);

export default {
  types: {
    AUTH_FLOW_STARTED,
    AUTH_CHECK_SESSION,
    AUTH_SESSION_EXPIRED,
    AUTH_SESSION_VALID,
    AUTH_SESSION_NOT_FOUND,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_USER_DATA_FETCH,
    GET_LOGIN_TYPE,
  },
  creators: {
    fetchuserData,
    startAuthFlow,
    checkSession,
    sessionExpired,
    sessionValid,
    sessionNotFound,
    login,
    logout,
    getLoginType,
  },
};
