import React from 'react';
import styled from 'styled-components';

import { noop } from '../../utils/misc';
import DayPicker from '../DayPicker';
import Label from '../Label';

const LabelContainer = styled.div`
  margin-bottom: 0px;
`;

interface DayPickerInput {
  inputProps?: React.HTMLProps<HTMLInputElement>;
  label: string;
  onChange(day: Date): void;
  onDayPickerHide?(): void;
  overlayComponent?: React.ComponentType;
  value: string | Date;
}

const DayPickerInput: React.FunctionComponent<DayPickerInput> = ({
  inputProps,
  label,
  onChange = noop,
  onDayPickerHide = noop,
  overlayComponent,
  value,
}) => (
  <div>
    <LabelContainer>
      <Label>{label}</Label>
    </LabelContainer>
    <DayPicker
      inputProps={inputProps}
      onChange={onChange}
      onDayPickerHide={onDayPickerHide}
      overlayComponent={overlayComponent}
      value={value}
    />
  </div>
);

export default DayPickerInput;
