import { Field, FieldProps } from 'formik';
import React from 'react';

import { applySelector, noop } from '../../utils/misc';
import PercentageQuantityInput from '../PercentageCurrencyInput';
import { InputMode } from './FormikNumber';

interface IFormikPercentageCurrency {
  disabled?: boolean;
  label: string;
  name: string;
  total: number;
  onBlur?(): void;
  warning?: boolean;
  inputMode?: InputMode;
}

const FormikPercentageCurrency: React.FunctionComponent<IFormikPercentageCurrency> = ({
  disabled = false,
  label,
  name,
  total,
  onBlur = noop,
  warning = false,
  inputMode = 'text',
}) => (
  <Field name={name}>
    {({ field, form }: FieldProps) => (
      <PercentageQuantityInput
        label={label}
        value={field.value}
        disabled={disabled}
        onBlur={() => {
          form.setFieldTouched(field.name);
          onBlur();
        }}
        onChange={value => form.setFieldValue(field.name, value)}
        total={total}
        withErrorMessage={true}
        error={
          applySelector(form.touched, field.name) &&
          applySelector(form.errors, field.name)
        }
        warning={warning}
        inputMode={inputMode}
      />
    )}
  </Field>
);

export default FormikPercentageCurrency;
