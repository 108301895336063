import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 20 20';

const FolderIcon = ({ fill }: { fill: string }) => (
  <path
    d="M18 8H2V3c0-.552.337-1 .889-1h3.355a1 1 0 0 1 .898.56l1.136 2.319A2 2 0 0 0 10.074 6h6.815C17.441 6 18 6.448 18 7v1zm0 9c0 .552-.559 1-1.111 1h-14C2.337 18 2 17.552 2 17v-7h16v7zm-.111-13h-6.764a2 2 0 0 1-1.789-1.106l-.894-1.788A2 2 0 0 0 6.653 0H1.889C.784 0 0 .895 0 2v16c0 1.105.784 2 1.889 2h16C18.993 20 20 19.105 20 18V6c0-1.105-1.007-2-2.111-2z"
    fill={fill}
    fillRule="evenodd"
  />
);

export default BaseIcon(FolderIcon, viewBox);
