import format from 'date-fns/format';
import not from 'ramda/src/not';
import { call, put, takeLatest } from 'redux-saga/effects';

import finalOffersActions from '../actions/finalOffers';
import offersActions from '../actions/offers';
import toastActions from '../actions/toastNotifications';
import {
  buildErrorToast,
  buildSuccessToast,
} from '../utils/toastNotifications';
import runner from './runners';

function* showToast(successMessage, errorMessage, error) {
  const dismissToastIn = 5000;
  const toast = error
    ? buildErrorToast(errorMessage, dismissToastIn)
    : buildSuccessToast(successMessage, dismissToastIn);

  yield put(toastActions.creators.showToast(toast));
}

function* createFinalOffer(action) {
  const { finalOffer, offerId } = action.payload;

  const { error } = yield call(
    runner,
    finalOffersActions.creators.createFinalOffer,
    {
      method: 'POST',
      url: `/api/offers/${offerId}/final-offer`,
      payload: finalOffer,
    },
    { originalAction: action, snakeCase: false }
  );

  yield call(
    showToast,
    'Aprobación creada exitosamente.',
    'La aprobación no pudo ser creada.',
    error
  );

  if (not(error)) {
    yield put(offersActions.creators.fetchOfferById.request({ id: offerId }));
  }
}

function* watchCreateFinalOfferRequested() {
  yield takeLatest(
    finalOffersActions.types.FINAL_OFFER_CREATE.REQUESTED,
    createFinalOffer
  );
}

function* updateFormalizationDate(action) {
  const {
    offerId,
    formalizationDate,
    formalizationDateImmediate,
  } = action.payload;

  const payload = formalizationDateImmediate
    ? {
        formalizationDate: null,
        formalizationDateImmediate: true,
      }
    : {
        formalizationDate: format(formalizationDate, 'yyyy-MM-dd'),
        formalizationDateImmediate: false,
      };

  yield call(runner, finalOffersActions.creators.updateFormalizationDate, {
    method: 'PUT',
    url: `/api/offers/${offerId}/final-offer/formalization-date`,
    payload,
  });

  yield put(offersActions.creators.fetchOfferById.request({ id: offerId }));
}

function* watchUpdateFormalizationDateRequested() {
  yield takeLatest(
    finalOffersActions.types.FINAL_OFFER_UPDATE_FORMALIZATION_DATE.REQUESTED,
    updateFormalizationDate
  );
}

function* updateFormalizationDateStatus(action) {
  const { offerId, status } = action.payload;

  yield call(
    runner,
    finalOffersActions.creators.updateFormalizationDateStatus,
    {
      method: 'PUT',
      url: `/api/offers/${offerId}/final-offer/formalization-date-status`,
      payload: { formalizationDateStatus: status },
    }
  );

  yield put(offersActions.creators.fetchOfferById.request({ id: offerId }));
}

function* watchUpdateFormalizationDateStatusRequested() {
  yield takeLatest(
    finalOffersActions.types.FINAL_OFFER_UPDATE_FORMALIZATION_DATE_STATUS
      .REQUESTED,
    updateFormalizationDateStatus
  );
}

function* createFormalizationDocuments(action) {
  const { offerId } = action.payload;

  const documents = [];
  const requestPayload = new FormData();

  action.payload.documents.forEach(doc => {
    documents.push({
      name: doc.name,
      description: doc.description,
      type: doc.type,
      url: doc.url,
    });
    if (doc.type === 'physical') {
      requestPayload.append('files[]', doc.file);
    }
  });

  requestPayload.set('documents', JSON.stringify(documents));

  const { error } = yield call(
    runner,
    finalOffersActions.creators.createFormalizationDocuments,
    {
      method: 'POST',
      payload: requestPayload,
      url: `/api/offers/${offerId}/formalization`,
    },
    { originalAction: action }
  );

  yield call(
    showToast,
    'Documentos creados exitosamente.',
    'Los documentos no pudieron ser creados.',
    error
  );

  if (not(error)) {
    yield put(offersActions.creators.fetchOfferById.request({ id: offerId }));
  }
}

function* watchCreateFormalizationDocumentsRequested() {
  yield takeLatest(
    finalOffersActions.types.FINAL_OFFER_CREATE_FORMALIZATION_DOCUMENTS
      .REQUESTED,
    createFormalizationDocuments
  );
}

function* updateGrantedDate(action) {
  const { offerId } = action.payload;

  yield call(runner, finalOffersActions.creators.updateGrantedDate, {
    method: 'PUT',
    url: `/api/offers/${offerId}/final-offer/granted-date`,
  });

  yield put(offersActions.creators.fetchOfferById.request({ id: offerId }));
}

function* watchUpdateGrantedDateRequested() {
  yield takeLatest(
    finalOffersActions.types.FINAL_OFFER_UPDATE_GRANTED_DATE.REQUESTED,
    updateGrantedDate
  );
}

export default {
  watchCreateFinalOfferRequested,
  watchCreateFormalizationDocumentsRequested,
  watchUpdateFormalizationDateRequested,
  watchUpdateFormalizationDateStatusRequested,
  watchUpdateGrantedDateRequested,
};
