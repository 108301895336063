import { combineReducers } from 'redux';

import accountCreation from './accountCreation';
import accountDataCheck from './accountDataCheck';
import accountVerification from './accountVerification';
import applicationCreation from './applicationCreation';
import biometricVerification from './biometricVerification';
import existentClient from './existentClient';
import newApplicationMultiSections from './newApplicationMultiSections';
import profiling from './profiling';
import verificationModal from './verificationModal';

const newApplicationReducer = combineReducers({
  accountCreation,
  accountVerificationSucceded: accountVerification,
  accountDataNotInUse: accountDataCheck,
  applicationCreation,
  existentClient,
  profiling,
  verificationModal,
  sections: newApplicationMultiSections,
  biometricVerification,
});

export default newApplicationReducer;
