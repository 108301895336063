import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import f from '../../constants/formatters';
import grammar from '../../constants/grammar';
import useFetchCountries from '../../hooks/useFetchCountries';
import Input from '../Input';
import LoadingIndicator from '../LoadingIndicator';
import sectionNamesTranslations from './translations';
import { IApplicationDetails, buildApplicationDetailsObject } from './utils';

const Container = styled.div`
  background-color: ${colors.WHITE};
  display: flex;
  height: 100%;
  justify-content: center;
  padding-bottom: 40px;
  width: 100%;
  & span {
    font-weight: normal;
  }
`;

const Grid = styled.div`
  padding-left: 40px;
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  width: 100%;
`;

const LoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  height: 50vh;
  justify-content: center;
  width: 100%;
`;

const SectionName = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  font-weight: bold;
  width: 120px;
`;

const SectionsContainer = styled.div`
  padding: 60px 60px;
`;

interface IApplicationViewProps {
  applicationDetails: IApplicationDetails;
}

const sectionNames = [
  'asset',
  'client',
  'spouse',
  'cosigner',
  'address',
  'cosignerAddress',
  'employment',
  'employmentAddress',
  'previousEmployment',
  'cosignerEmployment',
  'cosignerEmploymentAddress',
  'references',
];

export const ApplicationDetailsComponent: React.FunctionComponent<IApplicationViewProps> = ({
  applicationDetails,
}) => {
  const countries = useFetchCountries();

  if (!(applicationDetails && countries) || isEmpty(countries)) {
    return (
      <LoadingState>
        <LoadingIndicator />
      </LoadingState>
    );
  }

  const applicationDetailsObject = buildApplicationDetailsObject(
    applicationDetails,
    countries
  );

  const renderSection = (section: any) => {
    var fields = [];
    for (const field in section) {
      if (isNil(section[field]) || isEmpty(section[field])) {
        continue;
      }
      const value = (() => {
        if (typeof section[field] === 'string') {
          switch (field) {
            case 'email':
              return section[field];
            default:
              return f.capitalize(section[field]);
          }
        } else {
          return section[field];
        }
      })();

      fields.push(
        <Input key={field} disabled label={grammar[field]} value={value} />
      );
    }
    return fields;
  };

  const renderSections = (applicationDetails: any) => {
    var sections: Array<JSX.Element> = [];
    sectionNames.forEach((section: string) => {
      if (
        !isNil(applicationDetails[section]) &&
        !isEmpty(applicationDetails[section])
      ) {
        if (section === 'references') {
          applicationDetails[section].forEach(
            (reference: any, index: number) => {
              sections.push(
                <Container key={`section${index}`}>
                  <SectionName>{`${sectionNamesTranslations[section]} ${index +
                    1}`}</SectionName>
                  <Grid>{renderSection(reference)}</Grid>
                </Container>
              );
            }
          );
        } else {
          sections.push(
            <Container key={section}>
              <SectionName>{sectionNamesTranslations[section]}</SectionName>
              <Grid>{renderSection(applicationDetails[section])}</Grid>
            </Container>
          );
        }
      }
    });
    return sections;
  };

  return (
    <SectionsContainer>
      {renderSections(applicationDetailsObject)}
    </SectionsContainer>
  );
};
