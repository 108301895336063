import { FormikProps } from 'formik';
import isNil from 'ramda/src/isNil';
import { useEffect } from 'react';

import { applySelector } from '../utils/misc';

const useClearStateOfBirth = (
  formikProps: FormikProps<any>,
  fieldGroupName?: string
) => {
  const { setFieldValue } = formikProps;
  const suffixedSelector = isNil(fieldGroupName) ? '' : `${fieldGroupName}.`;
  const countryOfBirth = applySelector(
    formikProps.values,
    `${suffixedSelector}countryOfBirth`
  );
  const stateOfBirth = applySelector(
    formikProps.values,
    `${suffixedSelector}stateOfBirth`
  );

  useEffect(() => {
    if (countryOfBirth && countryOfBirth !== 'México') {
      setFieldValue(`${suffixedSelector}stateOfBirth`, 'NE');
    } else if (countryOfBirth === 'México' && stateOfBirth === 'NE') {
      setFieldValue(`${suffixedSelector}stateOfBirth`, '');
    }
  }, [countryOfBirth, stateOfBirth, setFieldValue, suffixedSelector]);
};

export default useClearStateOfBirth;
