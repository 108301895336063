import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import finalOfferActions from '../../actions/finalOffers';
import colors from '../../constants/colors';
import {
  documentStatusDropdownOptions,
  documentStatusOptions,
} from '../../constants/documentStatus';
import Dropdown from '../Dropdown';
import LoadingIndicator from '../LoadingIndicator';
import TableCell from '../TableCell';

const Cell = styled(TableCell)`
  align-items: center;
  color: ${colors.PRIMARY_TEXT};
  display: flex;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.57;
  padding-right: 8px;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  height: 68px;
  padding-left: 36px;
`;

const DropdownContainer = styled.div`
  margin-left: 44px;
  width: 140px;
`;

const getDateCellText = finalOffer => {
  if (finalOffer.formalizationDateImmediate) {
    return 'Lo antes posible';
  }

  if (finalOffer.formalizationDate) {
    return finalOffer.formalizationDate;
  }

  return 'Por definir';
};

const FormalizationDateReview = ({
  finalOffer,
  setSectionOverflow,
  updateFormalizationDateStatus,
  updatingFormalizationDateStatus,
}) => {
  const onDropdownOptionChange = option =>
    updateFormalizationDateStatus({
      offerId: finalOffer.offerId,
      status: option.value,
    });

  const dropdownText = documentStatusOptions.find(
    option => option.value === finalOffer.formalizationDateStatus
  ).text;
  const cellText = getDateCellText(finalOffer);
  return (
    <Container>
      <Cell width={'30%'}>Fecha de formalización</Cell>
      <Cell width={'35%'}>{cellText}</Cell>
      <Cell width={'35%'}>
        <DropdownContainer>
          {updatingFormalizationDateStatus ? (
            <LoadingIndicator />
          ) : cellText === 'Por definir' ? null : (
            <Dropdown
              onChange={onDropdownOptionChange}
              onClose={() => setSectionOverflow('hidden')}
              onOpen={() => setSectionOverflow('visible')}
              options={documentStatusDropdownOptions}
              value={dropdownText}
              placeholder={dropdownText}
            />
          )}
        </DropdownContainer>
      </Cell>
    </Container>
  );
};

const mapStateToProps = state => ({
  updatingFormalizationDateStatus:
    state.loaders.updatingFormalizationDateStatus,
});

const creators = {
  updateFormalizationDateStatus:
    finalOfferActions.creators.updateFormalizationDateStatus.request,
};

export default connect(mapStateToProps, creators)(FormalizationDateReview);
