import { call, put, take, takeLatest } from 'redux-saga/effects';

import applicationCreationActions from '../actions/applicationCreation';
import continueApplicationActions from '../actions/continueApplication';
import toastActions from '../actions/toastNotifications';
import { addSearchParamsToPayload } from '../utils/misc';
import { generatePayload } from '../utils/newApplicationPayload';
import { buildErrorToast } from '../utils/toastNotifications';
import runner from './runners';

const UNEXPECTED_ERROR_TOAST_MESSAGE =
  'Hubo un error al crear la cuenta. Podemos atenderte en el chat de ayuda.';

function* createApplication(action) {
  const payload = addSearchParamsToPayload(generatePayload(action.payload), [
    'promoterUserCode',
  ]);

  const response = yield call(
    runner,
    applicationCreationActions.creators.createApplication,
    {
      method: 'POST',
      url: '/api/application/create-vehicle-application',
      payload,
    },
    { snakeCase: false }
  );

  if (response.error) {
    const toast = buildErrorToast(
      getCreditApplicationErrorMessage(response.error.response.error),
      5000
    );
    yield put(toastActions.creators.showToast(toast));
  }
}

function* watchCreateApplicationRequested() {
  yield takeLatest(
    applicationCreationActions.types.APPLICATION_CREATION.REQUESTED,
    createApplication
  );
}

function* sendApplicationFeedback(action) {
  yield call(runner, applicationCreationActions.creators.createApplication, {
    method: 'POST',
    url: '/api/application/feedback',
    payload: action.payload,
  });
  yield put(continueApplicationActions.creators.clearNewApplication());
  yield take([continueApplicationActions.types.CLEAR_NEW_APPLICATION]);
}

function* watchsendApplicationFeedbackRequested() {
  yield takeLatest(
    applicationCreationActions.types.SEND_APPLICATION_FEEDBACK.REQUESTED,
    sendApplicationFeedback
  );
}

const getCreditApplicationErrorMessage = error => {
  switch (error) {
    case 'BAD_VERIFICATION_CODE':
      return 'El NIP proporcionado es incorrecto.';

    case 'INVALID_FINANCE_TYPE':
      return 'El tipo de financiamiento ingresado es inválido.';

    case 'INVALID_COSIGNER_DATA':
      return 'Los datos del coacreditado son inválidos';

    case 'INVALID_COSIGNER_EMPLOYMENT_DATA':
      return 'Los datos del empleo del coacreditado son inválidos.';

    case 'INVALID_SPOUSE_DATA':
      return 'Los datos del conyuge son inválidos.';

    case 'INVALID_SPOUSE_AS_COSIGNER_DATA':
      return 'Los datos del conyuge son inválidos.';

    case 'INVALID_CLIENT_DATA':
      return 'Los datos del cliente son inválidos.';

    case 'INVALID_CLIENT_EMPLOYMENT_DATA':
      return 'Los datos del empleo del cliente con inválidos.';

    case 'INVALID_CLIENT_ADDRESS_DATA':
      return 'Los datos de la dirección del cliente son inválidos.';

    case 'INVALID_CLIENT_OTHER_INCOME_DATA':
      return 'Los datos de los otros ingresos del clients son inválidos.';

    case 'COMPLIANCE_RECORD_NOT_FOUND':
      return 'No se encontró la verificación de la solicitud.';

    case 'PROMOTER_NOT_FOUND':
      return 'No se encontró el promotor.';

    case 'BANK_NOT_FOUND':
      return 'No se encontró la institución financiera elegida.';

    case 'CLIENT_NOT_FOUND':
      return 'No se encontró la cuenta del cliente.';

    case 'CLIENT_DETAILS_NOT_FOUND':
      return 'No se encontraron los datos personales del cliente.';

    case 'CIRCULO_CREDITO_PROCESSING_ERROR':
      return 'Hubo un error con la comunicación a círculo de crédito. Si el problema persiste por favor contacta a soporte al número: (331) 803-7895.';

    case 'SOCKET_ERROR':
      return 'Hubo un error con la comunicación a círculo de crédito. Si el problema persiste por favor contacta a soporte al número: (331) 803-7895.';

    case 'SOCKET_TIMEOUT':
      return 'Hubo un error con la comunicación a círculo de crédito. Si el problema persiste por favor contacta a soporte al número: (331) 803-7895.';

    default:
      return UNEXPECTED_ERROR_TOAST_MESSAGE;
  }
};

export default {
  watchCreateApplicationRequested,
  watchsendApplicationFeedbackRequested,
};
