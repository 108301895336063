import differenceInYears from 'date-fns/differenceInYears';
import dropRepeats from 'ramda/src/dropRepeats';
import isNil from 'ramda/src/isNil';
import reject from 'ramda/src/reject';
import { useEffect, useState } from 'react';

import {
  IProfilingForm,
  getProfilingSchema,
} from '../components/ProfilingSectionsForms/ProfilingSectionsForms';

const MAX_FEE = 0.04;

const MAX_INTEREST_RATE_CAR_FINANCE = 0.2;
const MIN_INTEREST_RATE_CAR_FINANCE = 0.17;

const MAX_INTEREST_RATE_MOTORCYCLE_FINANCE = 0.44;
const MIN_INTEREST_RATE_MOTORCYCLE_FINANCE = 0.4;

const TAX_RATE = 0.16;

export enum Suggestions {
  CHANGE_VEHICLE = 'CHANGE_VEHICLE',
  INCREASE_DOWNPAYMENT = 'INCREASE_DOWNPAYMENT',
  INCREASE_NUMBER_OF_PAYMENTS = 'INCREASE_NUMBER_OF_PAYMENTS',
  USE_COSIGNER = 'USE_COSIGNER',
}

export enum ProfilingStatus {
  FILLING = 'FILLING',
  HIGH_PROBABILITY = 'HIGH_PROBABILITY',
  MEDIUM_PROBABILITY = 'MEDIUM_PROBABILITY',
  LOW_PROBABILITY = 'LOW_PROBABILITY',
}

interface ISuggestionsAll {
  [key: string]: Suggestions[];
}

export interface IProfilingSuggestionResult {
  profilingStatus: ProfilingStatus;
  suggestions: ISuggestionsAll;
}

const getInterestRateRange = (productType: string) => {
  switch (productType) {
    case 'motorcycle_finance':
      return {
        max: MAX_INTEREST_RATE_MOTORCYCLE_FINANCE,
        min: MIN_INTEREST_RATE_MOTORCYCLE_FINANCE,
      };
    case 'car_finance':
    default:
      return {
        max: MAX_INTEREST_RATE_CAR_FINANCE,
        min: MIN_INTEREST_RATE_CAR_FINANCE,
      };
  }
};

const calculatePayment = (
  numberOfPayments: number,
  vehicleValue: number,
  downpayment: number,
  interestRate: number,
  fee: number
): number => {
  const creditAmount = vehicleValue - downpayment;
  const finalCreditAmount = creditAmount + fee * creditAmount * (1 + TAX_RATE);

  const term1 = Math.pow(
    1 + (interestRate * (1 + TAX_RATE)) / 12,
    numberOfPayments
  );
  const term2 = (interestRate * (1 + TAX_RATE)) / 12;
  const term3 = term1 - 1;

  return (finalCreditAmount * term1 * term2) / term3;
};

const checkCreditRecord = (profile: IProfilingForm) => {
  if (profile.applicant.hasCosigner) {
    return {
      probability: 3,
      suggestions: null,
    };
  }

  switch (profile.applicant.creditRecordStatus) {
    case 'no_records':
      return {
        probability: 2,
        suggestions: {
          applicant: [Suggestions.USE_COSIGNER],
        },
      };
    case 'with_debt':
      return {
        probability: 0,
        suggestions: {
          applicant: [Suggestions.USE_COSIGNER],
        },
      };
    case 'has_debt':
      return {
        probability: 1,
        suggestions: {
          applicant: [Suggestions.USE_COSIGNER],
        },
      };
    default:
      return { probability: 3, suggestions: null };
  }
};

const checkEmployment = (profile: IProfilingForm) => {
  if (
    profile.applicant.hasCosigner ||
    isNil(profile.employment.employmentDate)
  ) {
    return {
      probability: 3,
      suggestions: null,
    };
  }

  const date = new Date(profile.employment.employmentDate);
  if (
    differenceInYears(date, new Date()) < 1 &&
    profile.applicant.creditRecordStatus !== 'no_debt'
  ) {
    return {
      probability: 1,
      suggestions: {
        applicant: [Suggestions.USE_COSIGNER],
      },
    };
  }

  return {
    probability: 3,
    suggestions: null,
  };
};

const checkTimeInCurrentAddress = (profile: IProfilingForm) => {
  const date = new Date(profile.address.antiquity);

  if (profile.applicant.hasCosigner) {
    return {
      probability: 3,
      suggestions: null,
    };
  }

  if (
    differenceInYears(date, new Date()) < 1 &&
    profile.applicant.creditRecordStatus !== 'no_debt'
  ) {
    return {
      probability: 1,
      suggestions: {
        applicant: [Suggestions.USE_COSIGNER],
      },
    };
  }

  return {
    probability: 3,
    suggestions: null,
  };
};

const checkIncomeAgainstMaxPossiblePayment = (
  profile: IProfilingForm,
  productType: string
) => {
  const { product } = profile;
  const { downpayment, price, term } = product;

  const maxMonthlyPayment = calculatePayment(
    term,
    price,
    downpayment,
    getInterestRateRange(productType).max,
    MAX_FEE
  );

  const totalIncome =
    profile.employment.sourceOtherIncome !== 'no_apply'
      ? profile.employment.otherIncomeQuantity +
        profile.employment.fixedIncomeQuantity
      : profile.employment.fixedIncomeQuantity;

  const paymentIncomeRatio = maxMonthlyPayment / totalIncome;

  if (paymentIncomeRatio >= 0.4) {
    return {
      probability: 0,
      suggestions: {
        product: [
          Suggestions.CHANGE_VEHICLE,
          Suggestions.INCREASE_DOWNPAYMENT,
          Suggestions.INCREASE_NUMBER_OF_PAYMENTS,
        ],
      },
    };
  } else if (paymentIncomeRatio >= 0.3) {
    return {
      probability: 1,
      suggestions: {
        product: [
          Suggestions.CHANGE_VEHICLE,
          Suggestions.INCREASE_DOWNPAYMENT,
          Suggestions.INCREASE_NUMBER_OF_PAYMENTS,
        ],
      },
    };
  } else if (paymentIncomeRatio >= 0.2) {
    return {
      probability: 2,
      suggestions: {
        product: [
          Suggestions.CHANGE_VEHICLE,
          Suggestions.INCREASE_DOWNPAYMENT,
          Suggestions.INCREASE_NUMBER_OF_PAYMENTS,
        ],
      },
    };
  } else {
    return {
      probability: 3,
      suggestions: null,
    };
  }
};

const initialResults: IProfilingSuggestionResult = {
  profilingStatus: ProfilingStatus.FILLING,
  suggestions: {
    applicant: [],
    product: [],
  },
};

const useProfilingSuggestions = (
  values: IProfilingForm
): IProfilingSuggestionResult => {
  const [profilingResult, setProfilingResult] = useState<
    IProfilingSuggestionResult
  >(initialResults);

  const validationSchema = getProfilingSchema(values.applicant.hasCosigner);
  let isValid = true;
  for (const sectionKey in validationSchema) {
    try {
      validationSchema[sectionKey].validateSync(values[sectionKey]);
    } catch (error) {
      isValid = false;
      break;
    }
  }

  useEffect(() => {
    if (isValid) {
      const partials = [
        checkCreditRecord(values),
        checkEmployment(values),
        checkTimeInCurrentAddress(values),
        checkIncomeAgainstMaxPossiblePayment(
          values,
          values.product.productType
        ),
      ];

      const probability = partials.reduce(
        (min, { probability }) => (min > probability ? probability : min),
        Number.MAX_SAFE_INTEGER
      );
      const status = (() => {
        switch (probability) {
          case 0:
            return ProfilingStatus.LOW_PROBABILITY;
          case 1:
          case 2:
            return ProfilingStatus.MEDIUM_PROBABILITY;
          case 3:
            return ProfilingStatus.HIGH_PROBABILITY;
          default:
            return ProfilingStatus.FILLING;
        }
      })();

      const partialSuggestions = reject(
        isNil,
        partials.map(partial => partial.suggestions)
      ) as ISuggestionsAll[];

      const suggestions = partialSuggestions.reduce((acc, current) => {
        for (const suggestionKey in current) {
          if (!isNil(acc[suggestionKey])) {
            acc[suggestionKey] = dropRepeats([
              ...acc[suggestionKey],
              ...current[suggestionKey],
            ]);
          } else {
            acc[suggestionKey] = current[suggestionKey];
          }
        }
        return acc;
      }, {} as ISuggestionsAll);

      setProfilingResult({
        profilingStatus: status,
        suggestions,
      });
    } else {
      setProfilingResult(initialResults);
    }
  }, [isValid, values]);

  return profilingResult;
};

export default useProfilingSuggestions;
