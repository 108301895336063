import React from 'react';
import styled from 'styled-components';

import Button from '../Button';
import Modal from '../Modal';

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  height: 72px;
  padding-left: 8px;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Margin = styled.div`
  margin-left: 12px;
`;

interface IConfirmationDialog {
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
  render: () => JSX.Element;
  title: string;
}

const ConfirmationDialog = ({
  title,
  onCancel,
  onConfirm,
  open,
  render,
}: IConfirmationDialog) => {
  const renderControls = () => (
    <ControlsContainer>
      <Button onClick={onCancel} variant="secondary">
        Cancelar
      </Button>
      <Margin>
        <Button onClick={onConfirm} variant="primary">
          Confirmar
        </Button>
      </Margin>
    </ControlsContainer>
  );

  const renderHeader = () => <HeaderContainer>{title}</HeaderContainer>;

  return (
    <Modal
      content={render}
      controls={renderControls}
      header={renderHeader}
      isOpen={open}
      onRequestClose={onCancel}
      shouldCloseOnOverlayClick={true}
      width={'440px'}
    />
  );
};

export default ConfirmationDialog;
