import { handleActions } from 'redux-actions';

import utils from './utils';

const initialState = {};

const offersReducer = handleActions(
  {
    OFFERS_FETCH_COMPLETED: (state, action) =>
      utils.buildDictWithKey(action.payload.data, 'id'),
    OFFERS_FETCH_BY_ID_COMPLETED: (state, action) => ({
      ...state,
      [action.payload.data.id]: action.payload.data,
    }),
  },
  initialState
);

export default offersReducer;
