import { call, put, takeLatest } from 'redux-saga/effects';

import sendApplication from '../actions/sendApplication';
import toastActions from '../actions/toastNotifications';
import { buildErrorToast } from '../utils/toastNotifications';
import runner from './runners';

function* createSendApplication(action: any): any {
  const { error } = yield call(
    runner,
    sendApplication.creators.sendApplicationToClient,
    {
      method: 'POST',
      url: `/api/requests/send-application-to-client`,
      payload: action.payload,
    },
    { snakeCase: false }
  );

  if (error) {
    const toast = buildErrorToast(
      'No fue posible enviar el enlace al cliente. Contacta a soporte si el problema persiste.',
      3000
    );
    yield put(toastActions.creators.showToast(toast));
  }
}

function* watchCreateSendApplication(): any {
  yield takeLatest(
    sendApplication.types.SEND_APPLICATION_TO_CLIENT.REQUESTED,
    createSendApplication
  );
}

export default { watchCreateSendApplication };
