import length from 'ramda/src/length';
import React, { useState } from 'react';
import styled from 'styled-components';

import client from '../../sagas/client';
import Button from '../Button';
import DayPickerInput from '../DayPickerInput';
import SimpleCard from '../SimpleCard';

const ButtonContainer = styled.div`
  margin-top: 20px;
`;

const CirculoComplianceControlsContainer = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 180px);
  column-gap: 20px;
  padding: 20px 40px;
  width: 100%;
`;

const downloadCSVFile = (startDate: Date, endDate: Date) => {
  client
    .get('/api/compliance/circulo-credito', {
      params: { startDate, endDate },
    })
    .then(response => {
      const type = response.headers['content-type'];
      const blob = new Blob([response.data], { type: type });
      const link = document.createElement('a');

      const fileName = (() => {
        const matches = /filename="[\w]+.[\w]+"/.exec(
          response.headers['content-disposition']
        );

        if (!matches || !length(matches)) {
          return 'no-name-available.csv';
        }

        return matches[0].replace('filename=', '').replace(/"/g, '');
      })();

      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    });
};

const ComplianceView = () => {
  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>({ startDate: new Date(), endDate: new Date() });

  return (
    <SimpleCard
      title="Cumplimiento Círculo de Crédito"
      subtitle="Descarga el CSV con la información de las consultas de historial crediticio"
    >
      <CirculoComplianceControlsContainer>
        <DayPickerInput
          label="Inicio"
          onChange={startDate => setDateRange({ ...dateRange, startDate })}
          value={dateRange.startDate}
        />
        <DayPickerInput
          label="Fin"
          onChange={endDate => setDateRange({ ...dateRange, endDate })}
          value={dateRange.endDate}
        />
        <ButtonContainer>
          <Button
            onClick={() =>
              downloadCSVFile(dateRange.startDate, dateRange.endDate)
            }
          >
            Descargar CSV
          </Button>
        </ButtonContainer>
      </CirculoComplianceControlsContainer>
    </SimpleCard>
  );
};

export default ComplianceView;
