import AuditIcon from '../resources/icons/Audit';
import BuildingIcon from '../resources/icons/Building';
import ClientsIcon from '../resources/icons/Clients';
import CommissionsIcon from '../resources/icons/Commissions';
import DashboardIcon from '../resources/icons/Dashboard';
import FolderIcon from '../resources/icons/Folder';
import FormIcon from '../resources/icons/Form';
import GrantedProductsIcon from '../resources/icons/GrantedProducts';
import PortfolioIcon from '../resources/icons/Portfolio';
import ProductsIcon from '../resources/icons/Products';
import PromoterIcon from '../resources/icons/Promoter';
import UserIcon from '../resources/icons/User';

interface IIcon {
  fill: string;
  rotate?: number;
  size: number;
}

interface INavOption {
  text: string;
  path: string;
  icon: React.FunctionComponent<IIcon>;
  external?: boolean;
}

interface INavOptionInner {
  text: string;
  path: string;
  external?: boolean;
}

interface INavOptionExpand {
  icon: any;
  text: string;
  options: INavOptionInner[];
}

type INavMenuOptions = Array<INavOption | INavOptionExpand>;

const creditusAdminConfig: INavMenuOptions = [
  {
    icon: CommissionsIcon,
    options: [
      {
        path: '/commissions/receivable',
        text: 'Por cobrar',
      },
      {
        path: '/commissions/payable',
        text: 'Por pagar',
      },
    ],
    text: 'Comisiones',
  },
  {
    icon: CommissionsIcon,
    options: [
      {
        path: '/invoices/receivable',
        text: 'Cobros',
      },
      {
        path: '/invoices/payable',
        text: 'Pagos',
      },
    ],
    text: 'Facturación',
  },
  {
    icon: PortfolioIcon,
    path: '/compliance',
    text: 'Cumplimiento',
  },
  {
    icon: UserIcon,
    path: '/id-verification',
    text: 'Verificaciones',
  },
  {
    icon: UserIcon,
    path: '/customer-support',
    text: 'Soporte',
  },
  {
    icon: AuditIcon,
    path: '/audit',
    text: 'Soporte - Auditoria',
  },
  {
    icon: FolderIcon,
    path: '/pipeline',
    text: 'Soporte - Trámites',
  },
  {
    icon: PromoterIcon,
    path: '/promoters/new',
    text: 'Crear promotores',
  },
  {
    icon: FolderIcon,
    path: '/offers/new',
    text: 'Crear oferta',
  },
];

const creditusAccountantConfig: INavMenuOptions = [
  {
    icon: CommissionsIcon,
    options: [
      {
        path: '/commissions/receivable',
        text: 'Por cobrar',
      },
      {
        path: '/commissions/payable',
        text: 'Por pagar',
      },
    ],
    text: 'Comisiones',
  },
  {
    icon: CommissionsIcon,
    options: [
      {
        path: '/invoices/receivable',
        text: 'Cobros',
      },
      {
        path: '/invoices/payable',
        text: 'Pagos',
      },
    ],
    text: 'Facturación',
  },
];

const creditusCustomerSupportConfig: INavMenuOptions = [
  {
    icon: UserIcon,
    path: '/id-verification',
    text: 'Verificaciones',
  },
  {
    icon: UserIcon,
    path: '/customer-support',
    text: 'Soporte',
  },
  {
    icon: AuditIcon,
    path: '/audit',
    text: 'Soporte - Auditoria',
  },
  {
    icon: FolderIcon,
    path: '/pipeline',
    text: 'Soporte - Trámites',
  },
];

const promoterFIConfig: INavMenuOptions = [
  {
    icon: FolderIcon,
    path: '/pipeline',
    text: 'Trámites',
  },
  {
    icon: GrantedProductsIcon,
    path: '/granted-products',
    text: 'Productos colocados',
  },
  {
    icon: CommissionsIcon,
    path: '/commissions/receivable',
    text: 'Comisiones',
  },
  {
    icon: CommissionsIcon,
    path: '/invoices/receivable',
    text: 'Pagos',
  },
  {
    icon: ClientsIcon,
    path: '/clients',
    text: 'Solicitudes',
  },
];

const promoterAdminConfig: INavMenuOptions = [
  {
    icon: DashboardIcon,
    path: '/dashboard',
    text: 'Dashboard',
  },
  {
    icon: FormIcon,
    path: '/application/continue',
    text: 'Crear solicitud',
  },
  {
    icon: FolderIcon,
    path: '/pipeline',
    text: 'Trámites',
  },
  {
    icon: FolderIcon,
    path: '/price-quoting',
    text: 'Cotizador',
  },
  {
    icon: GrantedProductsIcon,
    path: '/granted-products',
    text: 'Productos colocados',
  },
  {
    icon: CommissionsIcon,
    path: '/commissions/receivable',
    text: 'Comisiones',
  },
  {
    icon: CommissionsIcon,
    path: '/invoices/receivable',
    text: 'Pagos',
  },
  {
    icon: PromoterIcon,
    path: '/promoter',
    text: 'Comercio',
  },
  {
    icon: UserIcon,
    path: '/users',
    text: 'Usuarios',
  },
  {
    icon: ClientsIcon,
    path: '/clients',
    text: 'Solicitudes',
  },
];

const promoterAccountantConfig: INavMenuOptions = [
  {
    icon: GrantedProductsIcon,
    path: '/granted-products',
    text: 'Productos colocados',
  },
  {
    icon: CommissionsIcon,
    path: '/commissions/receivable',
    text: 'Comisiones',
  },
  {
    icon: CommissionsIcon,
    path: '/invoices/receivable',
    text: 'Pagos',
  },
];

const salespersonConfig: INavMenuOptions = [
  {
    icon: FormIcon,
    path: '/application/continue',
    text: 'Crear solicitud',
  },
  {
    icon: FolderIcon,
    path: '/pipeline',
    text: 'Trámites',
  },
  {
    icon: FolderIcon,
    path: '/price-quoting',
    text: 'Cotizador',
  },
  {
    icon: ClientsIcon,
    path: '/clients',
    text: 'Solicitudes',
  },
  {
    icon: CommissionsIcon,
    path: '/commissions/receivable',
    text: 'Comisiones',
  },
];

const bankAdminConfig: INavMenuOptions = [
  {
    icon: DashboardIcon,
    path: '/dashboard',
    text: 'Dashboard',
  },
  {
    icon: FolderIcon,
    path: '/pipeline',
    text: 'Trámites',
  },
  {
    icon: GrantedProductsIcon,
    path: '/granted-products',
    text: 'Otorgados',
  },
  {
    icon: CommissionsIcon,
    path: '/commissions/payable',
    text: 'Comisiones',
  },
  {
    icon: CommissionsIcon,
    path: '/invoices/payable',
    text: 'Pagos',
  },
  {
    icon: ProductsIcon,
    path: '/products',
    text: 'Productos',
  },
  {
    icon: AuditIcon,
    path: '/audit',
    text: 'Auditoria',
  },
  {
    icon: BuildingIcon,
    path: '/bank',
    text: 'Institución',
  },
  {
    icon: UserIcon,
    path: '/users',
    text: 'Usuarios',
  },
];

const bankAccountantConfig: INavMenuOptions = [
  {
    icon: GrantedProductsIcon,
    path: '/granted-products',
    text: 'Otorgados',
  },
  {
    icon: CommissionsIcon,
    path: '/commissions/payable',
    text: 'Comisiones',
  },
  {
    icon: CommissionsIcon,
    path: '/invoices/payable',
    text: 'Pagos',
  },
];

const bankLegalConfig: INavMenuOptions = [
  {
    icon: UserIcon,
    path: '/pipeline',
    text: 'Trámites',
  },
  {
    icon: GrantedProductsIcon,
    path: '/granted-products',
    text: 'Productos otorgados',
  },
];

const bankSuperAnalystConfig: INavMenuOptions = [
  {
    icon: DashboardIcon,
    path: '/dashboard',
    text: 'Dashboard',
  },
  {
    icon: UserIcon,
    path: '/pipeline/analysis',
    text: 'Tramites',
  },
  {
    icon: GrantedProductsIcon,
    path: '/granted-products',
    text: 'Productos otorgados',
  },
  {
    icon: AuditIcon,
    path: '/audit',
    text: 'Auditoria',
  },
];

const bankAnalystConfig: INavMenuOptions = [
  {
    icon: UserIcon,
    path: '/pipeline/analysis',
    text: 'Revisión',
  },
];

const menuConfigForClientUsers: INavMenuOptions = [
  {
    icon: UserIcon,
    path: '/credit-history',
    text: 'Historial',
  },
  {
    icon: FolderIcon,
    path: '/offered-applications',
    text: 'Procesos',
  },
];

const getMenuConfigForBankUsers = (
  userData: IBankUserData
): INavMenuOptions => {
  switch (userData.organization.role) {
    case 'system':
    case 'admin':
      return bankAdminConfig;
    case 'accountant':
      return bankAccountantConfig;
    case 'legal':
      return bankLegalConfig;
    case 'super-analyst':
      return bankSuperAnalystConfig;
    default:
      return bankAnalystConfig;
  }
};

const getMenuConfigForCreditusUsers = (
  userdata: ICreditusUserData
): INavMenuOptions => {
  switch (userdata.organization.role) {
    case 'admin':
      return creditusAdminConfig;
    case 'accountant':
      return creditusAccountantConfig;
    case 'customer-support':
      return creditusCustomerSupportConfig;
    default:
      return [];
  }
};

const getMenuConfigForPromoterUsers = (
  userData: IPromoterUserData
): INavMenuOptions => {
  switch (userData.organization.role) {
    case 'system':
    case 'admin':
      return promoterAdminConfig;
    case 'accountant':
      return promoterAccountantConfig;
    case 'f&i':
      return promoterFIConfig;
    case 'super-salesperson':
    case 'salesperson':
      return salespersonConfig;
    default:
      return [];
  }
};

const getMenuConfig = (userData: IUserData): INavMenuOptions => {
  switch (userData.type) {
    case 'bank':
      return getMenuConfigForBankUsers(userData);
    case 'promoter':
      return getMenuConfigForPromoterUsers(userData);
    case 'creditus':
      return getMenuConfigForCreditusUsers(userData);
    case 'client':
      return menuConfigForClientUsers;
    default:
      return [];
  }
};

export default getMenuConfig;
