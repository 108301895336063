import format from 'date-fns/format';
import { es } from 'date-fns/locale';
import subMonths from 'date-fns/subMonths';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import statsActions from '../../actions/stats';
import colors from '../../constants/colors';
import f from '../../constants/formatters';
import useUserData from '../../hooks/useUserData';
import { applySelector } from '../../utils/misc';
import DashboardDateRangeControl from '../DashboardDateRangeControl';
import DashboardPills from '../DashboardPills';
import DualScaleBarChart from '../DualScaleBarChart';
import LineChart from '../LineChart';
import LoadingIndicator from '../LoadingIndicator';
import QuickInfoCard from '../QuickInfoCard';
import { IDashboardConfig, getDashboardConfiguration } from './dashboardConfig';
import {
  DashboardDateRangeOptions,
  getCustomDateRangeConfigForDataAggregation,
  getDateRangeConfigForDataAggregation,
  getDatesForCustomDateRange,
  getDatesForSelectedDateRange,
} from './dashboardDateUtils';

const DashboardViewLoadingState = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const DashboardViewNoContent = styled.div`
  background-color: ${colors.WHITE};
  height: 100%;
  width: 100%;
`;

const DashboardContainer = styled.div`
  width: 100%;
`;

const DashboardTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const DashboardHeaderContainer = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  border: solid 1px ${colors.BORDER};
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 36px 30px;
  padding-right: 20px;
`;

const DashboardElementsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-auto-rows: 256px;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  width: 100%;
  max-width: 100%;
`;

const MainDashboardElement = styled.div`
  background-color: ${colors.WHITE};
  border: solid 1px #eaedf3;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
  grid-column-start: 1;
  grid-column-end: span 2;
  grid-row-start: 1;
  grid-row-end: span 2;
`;

const MainDashboardElementTitleContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid ${colors.BORDER};
  display: flex;
  height: 12.5%;
  padding-left: 32px;
`;

const MainDashboardElementTitleText = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 18px;
`;

const MainDashboardElementChartContainer = styled.div`
  height: 87.5%;
`;

interface IDateRangeDates {
  currentPeriod: {
    startDate: Date;
    endDate: Date;
  };
  previousPeriod: {
    startDate: Date;
    endDate: Date;
  };
}

interface IFetchStatsPayload {
  dateRange: DashboardDateRangeOptions;
  dates: IDateRangeDates;
}

interface IDashboardData {
  currentPeriod: any;
  previousPeriod: any;
}

const aggregateData = (
  dateRange: DashboardDateRangeOptions,
  dates: IDateRangeDates,
  data: IDashboardData,
  getEntities: (data: any) => any[],
  getEntityValue: (entity: any) => number,
  getEntityDate: (entity: any) => Date
): [string, number, number][] => {
  const dataAggregationConfig =
    dateRange === 'custom'
      ? getCustomDateRangeConfigForDataAggregation(
          dates.currentPeriod.startDate,
          dates.currentPeriod.endDate
        )
      : getDateRangeConfigForDataAggregation(dateRange);

  const {
    addTime,
    diffTime,
    labelFormat,
    timeIntervals,
  } = dataAggregationConfig;

  const aggregatedData: [string, number, number][] = timeIntervals.map(
    interval =>
      [
        format(addTime(dates.currentPeriod.startDate, interval), labelFormat, {
          locale: es,
        }).toUpperCase(),
        0,
        0,
      ] as [string, number, number]
  );

  const entitiesCurrentPeriod = getEntities(data.currentPeriod);

  entitiesCurrentPeriod.forEach(entity => {
    const entityDate = getEntityDate(entity);
    const entityValue = getEntityValue(entity);

    const offset = diffTime(entityDate, dates.currentPeriod.startDate);

    aggregatedData[offset][1] += entityValue;
  });

  const entitiesPreviousPeriod = getEntities(data.previousPeriod);

  entitiesPreviousPeriod.forEach(entity => {
    const entityDate = getEntityDate(entity);
    const entityValue = getEntityValue(entity);

    const offset = diffTime(entityDate, dates.previousPeriod.startDate);

    aggregatedData[offset][2] += entityValue;
  });

  return aggregatedData;
};

const capitalizeLegends = (legends: [string, string]): [string, string] => [
  f.capitalize(legends[0]),
  f.capitalize(legends[1]),
];

const getLegends = (
  selectedDateRange: DashboardDateRangeOptions,
  dates: IDateRangeDates
): [string, string] => {
  switch (selectedDateRange) {
    case 'today':
      return ['Hoy', 'Ayer'];
    case 'week':
      return ['Esta semana', 'Semana pasada'];
    case 'month':
      return [
        f.capitalize(
          format(dates.currentPeriod.startDate, 'LLLL', { locale: es })
        ),
        f.capitalize(
          format(dates.previousPeriod.startDate, 'LLLL', { locale: es })
        ),
      ];
    case 'semester':
      return [
        `${f.capitalize(
          format(dates.currentPeriod.startDate, 'LLL', {
            locale: es,
          })
        )} - ${f.capitalize(
          format(subMonths(dates.currentPeriod.endDate, 1), 'LLL', {
            locale: es,
          })
        )} ${f.capitalize(
          format(dates.currentPeriod.startDate, 'yy', { locale: es })
        )}`,
        `${f.capitalize(
          format(dates.previousPeriod.startDate, 'LLL', {
            locale: es,
          })
        )}  - ${f.capitalize(
          format(subMonths(dates.previousPeriod.endDate, 1), 'LLL', {
            locale: es,
          })
        )} ${f.capitalize(
          format(dates.previousPeriod.startDate, 'yy', { locale: es })
        )}`,
      ];
    case 'year':
      return [
        format(dates.currentPeriod.startDate, 'yyyy', {
          locale: es,
        }),
        format(dates.previousPeriod.startDate, 'yyyy', {
          locale: es,
        }),
      ];
    case 'custom':
      return [
        `${f.capitalize(
          format(dates.currentPeriod.startDate, 'LLL dd', {
            locale: es,
          })
        )} - ${f.capitalize(
          format(dates.currentPeriod.endDate, 'LLL dd', {
            locale: es,
          })
        )}`,
        `${f.capitalize(
          format(dates.previousPeriod.startDate, 'LLL dd', {
            locale: es,
          })
        )} - ${f.capitalize(
          format(dates.previousPeriod.endDate, 'LLL dd', {
            locale: es,
          })
        )}`,
      ];
  }
};

interface IDashboard {
  config: IDashboardConfig;
  fetchStats: (payload: IFetchStatsPayload) => void;
  data: {
    currentPeriod: any;
    previousPeriod: any;
  };
  dates: IDateRangeDates;
  dateRange: DashboardDateRangeOptions;
}

const Dashboard: React.FunctionComponent<IDashboard> = ({
  config,
  fetchStats,
  data,
  dates,
  dateRange,
}) => {
  const { mainChart, quickInfoCards } = config;

  // The dimensions array contents don't matter, we just need
  // to pass a new array to force the component to update
  const [, setDimensions] = useState<number[]>([]);

  useEffect(() => {
    const handleResize = (): void => {
      setDimensions([]);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    setDimensions([]);
  }, [dates]);

  const mainChartData = useMemo(
    () =>
      aggregateData(
        dateRange,
        dates,
        data,
        mainChart.getEntities,
        mainChart.getEntityValue,
        mainChart.getEntityDate
      ),
    [data, dateRange, dates, mainChart]
  );

  const legends: [string, string] = capitalizeLegends(
    getLegends(dateRange, dates)
  );

  const mainChartComponent = (() => {
    if (dateRange === 'custom') {
      return <LineChart data={mainChartData} legends={legends} />;
    } else {
      return (
        <DualScaleBarChart
          data={mainChartData}
          format={mainChart.format}
          legends={legends}
        />
      );
    }
  })();

  return (
    <DashboardContainer>
      <DashboardHeaderContainer>
        <DashboardTitle>Dashboard</DashboardTitle>
        <DashboardDateRangeControl
          startDate={dates.currentPeriod.startDate}
          endDate={dates.currentPeriod.endDate}
          onDateRangeChanged={(startDate, endDate) =>
            fetchStats({
              dateRange: 'custom',
              dates: getDatesForCustomDateRange(startDate, endDate),
            })
          }
        />
      </DashboardHeaderContainer>
      <DashboardPills
        onOptionClicked={option =>
          fetchStats({
            dateRange: option,
            dates: getDatesForSelectedDateRange(option),
          })
        }
        selectedDateRange={dateRange}
      />
      <DashboardElementsContainer>
        <MainDashboardElement>
          <MainDashboardElementTitleContainer>
            <MainDashboardElementTitleText>
              {mainChart.title}
            </MainDashboardElementTitleText>
          </MainDashboardElementTitleContainer>
          <MainDashboardElementChartContainer>
            {mainChartComponent}
          </MainDashboardElementChartContainer>
        </MainDashboardElement>
        {quickInfoCards.map(({ format, key, title }, i) => (
          <QuickInfoCard
            key={i}
            data={[
              applySelector(data.currentPeriod, key) as any,
              applySelector(data.previousPeriod, key) as any,
            ]}
            format={format}
            labels={legends}
            legendsFontSize={
              ['custom', 'week'].includes(dateRange) ? 'small' : 'normal'
            }
            title={title}
          />
        ))}
      </DashboardElementsContainer>
    </DashboardContainer>
  );
};

interface IDashboardView {
  fetchStats: (payload: IFetchStatsPayload) => void;
  data: {
    currentPeriod: any;
    previousPeriod: any;
  } | null;
  dates: IDateRangeDates | null;
  dateRange: DashboardDateRangeOptions | null;
}

const DashboardView: React.FunctionComponent<IDashboardView> = ({
  fetchStats,
  data,
  dates,
  dateRange,
}) => {
  useEffect(() => {
    if ([data, dates, dateRange].includes(null)) {
      fetchStats({
        dateRange: 'month',
        dates: getDatesForSelectedDateRange('month'),
      });
    }
  }, [fetchStats, data, dates, dateRange]);

  const userData = useUserData();

  const dashboardConfig = getDashboardConfiguration(userData);

  if (dashboardConfig === null) {
    return <DashboardViewNoContent />;
  }

  if (data === null || dates === null || dateRange === null) {
    return (
      <DashboardViewLoadingState>
        <LoadingIndicator />
      </DashboardViewLoadingState>
    );
  }

  return (
    <Dashboard
      config={dashboardConfig}
      fetchStats={fetchStats}
      data={data}
      dates={dates}
      dateRange={dateRange}
    />
  );
};

const mapStateToProps = (state: any): any => {
  return {
    data: state.stats.data,
    dates: state.stats.dates,
    dateRange: state.stats.dateRange,
  };
};

const creators = {
  fetchStats: statsActions.creators.fetchStats.request,
};

export default connect(mapStateToProps, creators)(DashboardView);
