import { useEffect, useState } from 'react';

import client from '../sagas/client';

const useFetchTemplateDocuments = (offerId: number | string, stage: string) => {
  const [templates, setTemplates] = useState<ITemplateDocument[] | null>(null);

  useEffect(() => {
    client
      .get(`/api/offers/${offerId}/template-documents/${stage}`)
      .then(res => setTemplates(res.data.templateDocuments));
  }, [offerId, stage]);

  return templates;
};

export default useFetchTemplateDocuments;
