import { call, put, takeLatest } from 'redux-saga/effects';

import profilingActions from '../actions/profiling';
import toastActions from '../actions/toastNotifications';
import { addSearchParamsToPayload } from '../utils/misc';
import { buildErrorToast } from '../utils/toastNotifications';
import runner from './runners';

function* checkExistingClientData(action) {
  const params = addSearchParamsToPayload(action.payload, ['promoterUserCode']);
  const { error } = yield call(
    runner,
    profilingActions.creators.checkExistingClientData,
    {
      method: 'POST',
      url: '/api/account/data-exists',
      payload: params,
    },
    { snakeCase: false }
  );

  if (error) {
    let msg = '';
    switch (error.response.data.error) {
      case 'EMAIL_ALREADY_USED':
        msg = 'Este correo ya ha sido registrado.';
        break;
      case 'PHONE_ALREADY_USED':
        msg = 'Este teléfono ya ha sido registrado.';
        break;
      default:
        msg =
          'Hubo un problema verificando los datos. Si el problema persiste por favor contacta a soporte al número: (331) 803-7895.';
        break;
    }
    const toast = buildErrorToast(msg, 5000);
    yield put(toastActions.creators.showToast(toast));
  }
}

function* watchCheckExistingUserDataRequested() {
  yield takeLatest(
    profilingActions.types.CHECK_EXISTING_CLIENT_DATA.REQUESTED,
    checkExistingClientData
  );
}

export default {
  watchCheckExistingUserDataRequested,
};
