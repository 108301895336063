import addHours from 'date-fns/addHours';
import format from 'date-fns/format';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import customerSupportActions from '../../actions/customerSupport';
import colors from '../../constants/colors';
import client from '../../sagas/client';
import Button from '../Button';
import { FormikDate, FormikSimpleNumber } from '../FormikInputs';
import SearchUserForm from '../SearchUserForm';
import SimpleCard from '../SimpleCard';
import SmartTable, { TableCell } from '../SmartTable';
import UpdateUserDataForm from '../UpdateUserDataForm';

const DATE_FORMAT = 'yyyy-MM-dd kk:mm:ss';

interface IBiometricVerification {
  id: number;
  email: string;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string | null;
}

interface IComplianceRecords {
  id: number;
  verificationCode: string | number;
  biometricVerificationId: number;
  userId: number;
  createdAt: string;
  updatedAt: string | null;
}

const ViewContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 32px;
`;

const CustomPre = styled.pre`
  background-color: ${colors.BACKGROUND75};
`;

const InputContainer = styled.div`
  margin-bottom: 40px;
  margin-right: 20px;
  width: 280px;
`;

const ButtonContainer = styled.div`
  margin-bottom: 40px;
  margin-right: 20px;
  width: 200px;
  height: 40px;
`;

const UserSearchContainer = styled.div`
  padding: 24px;
`;

const UserUpdateContainer = styled.div`
  padding-top: 24px;
`;

const ChangeOfferVigencyContainer = styled.div`
  padding: 24px;
  display: flex;
`;

const PendingBiometricVerificationList = (): JSX.Element => {
  const [biometricVerifications, setBiometricVerifications] = useState<
    IBiometricVerification[] | null
  >(null);
  useEffect(() => {
    client
      .get('/api/customer-support/biometric-verifications')
      .then(response =>
        setBiometricVerifications(
          response.data.biometricVerifications as IBiometricVerification[]
        )
      );
  }, []);

  if (biometricVerifications === null) {
    return <div>Loading</div>;
  }

  return (
    <SimpleCard
      title="Verificaciones biométricas"
      subtitle="Verificaciones biométricas que no han sido utilizadas (últimas 48 horas)"
    >
      <SmartTable
        data={biometricVerifications}
        gridColumns="1fr repeat(4, 2fr)"
        headers={['ID', 'Email', 'Número de teléfono', 'Creada', 'Actualizada']}
        renderRow={biometricVerification => {
          return [
            <TableCell key={1}>{biometricVerification.id}</TableCell>,
            <TableCell key={2}>{biometricVerification.email}</TableCell>,
            <TableCell key={3}>{biometricVerification.phoneNumber}</TableCell>,
            <TableCell key={4}>
              {format(
                addHours(new Date(biometricVerification.createdAt), 5),
                DATE_FORMAT
              )}
            </TableCell>,
            <TableCell key={5}>
              {biometricVerification.updatedAt
                ? format(
                    addHours(new Date(biometricVerification.updatedAt), 5),
                    DATE_FORMAT
                  )
                : 'No disponible'}
            </TableCell>,
          ];
        }}
      />
    </SimpleCard>
  );
};

const PendingComplainceRecordsList = (): JSX.Element => {
  const [complianceRecords, setComplianceRecords] = useState<
    IComplianceRecords[] | null
  >(null);
  useEffect(() => {
    client
      .get('/api/customer-support/compliance-records')
      .then(response =>
        setComplianceRecords(
          response.data.complianceRecords as IComplianceRecords[]
        )
      );
  }, []);

  if (complianceRecords === null) {
    return <div>Loading</div>;
  }

  return (
    <SimpleCard
      title="Verificaciones - NIPs"
      subtitle="Verificaciones que no han sido activadas con NIP (últimas 48 horas)"
    >
      <SmartTable
        data={complianceRecords}
        gridColumns="1fr repeat(5, 2fr)"
        headers={[
          'ID',
          'NIP',
          'ID verificación bio.',
          'Creada por usuario',
          'Creada',
          'Actualizada',
        ]}
        renderRow={complianceRecord => {
          return [
            <TableCell key={1}>{complianceRecord.id}</TableCell>,
            <TableCell key={2}>{complianceRecord.verificationCode}</TableCell>,
            <TableCell key={3}>
              {complianceRecord.biometricVerificationId}
            </TableCell>,
            <TableCell key={4}>{complianceRecord.userId}</TableCell>,
            <TableCell key={5}>
              {format(new Date(complianceRecord.createdAt), DATE_FORMAT)}
            </TableCell>,
            <TableCell key={6}>
              {complianceRecord.updatedAt
                ? format(new Date(complianceRecord.updatedAt), DATE_FORMAT)
                : 'No disponible'}
            </TableCell>,
          ];
        }}
      />
    </SimpleCard>
  );
};

interface IUpdateUserData {
  userData: IUser;
  fetchUser: Function;
  updateUserData: Function;
}

const UpdateUserData: React.FunctionComponent<IUpdateUserData> = ({
  userData,
  fetchUser,
  updateUserData,
}) => {
  type SearchType = 'email' | 'phoneNumber' | 'userId';

  return (
    <SimpleCard
      title="Cambiar datos de un usuario"
      subtitle="Ingresa el ID del usuario"
    >
      <UserSearchContainer>
        <Formik
          initialValues={{
            searchType: 'email' as SearchType,
            data: '',
          }}
          onSubmit={values => {
            fetchUser(values);
          }}
          render={formikProps => <SearchUserForm formikProps={formikProps} />}
          validationSchema={Yup.object().shape({
            searchType: Yup.string().required(),
            data: Yup.string()
              .when('searchType', {
                is: 'email',
                then: Yup.string().email('Ingrese un email válido'),
                otherwise: Yup.string().when('searchType', {
                  is: 'phoneNumber',
                  then: Yup.string()
                    .matches(/^\d{10}$/, 'Ingrese un número de teléfono válido')
                    .required(),
                  otherwise: Yup.string().required(),
                }),
              })
              .required('Ingresa un dato válido'),
          })}
        />
        {userData ? (
          <UserUpdateContainer>
            <CustomPre>{JSON.stringify(userData, null, 2)}</CustomPre>
            <Formik
              enableReinitialize={true}
              initialValues={{
                name: userData.name,
                firstSurname: userData.firstSurname,
                secondSurname: userData.secondSurname,
                email: userData.email,
                phoneNumber: userData.phoneNumber,
              }}
              onSubmit={values => {
                updateUserData({ ...values, userId: userData.id });
              }}
              render={formikProps => (
                <UpdateUserDataForm formikProps={formikProps} />
              )}
            />
          </UserUpdateContainer>
        ) : null}
      </UserSearchContainer>
    </SimpleCard>
  );
};

const mapUpdateUserDataStateToProps = (state: any): { userData: IUser } => ({
  userData: state.customerSupport.userData,
});
const updateUserDataCreators = {
  fetchUser: customerSupportActions.creators.creditusFetchUsers.request,
  updateUserData: customerSupportActions.creators.creditusUpdateUser.request,
};

const ConnectedUpdateUserData = connect(
  mapUpdateUserDataStateToProps,
  updateUserDataCreators
)(UpdateUserData);

const ConnectedChangeOfferVigency: React.FunctionComponent<{
  updateOfferVigency: Function;
}> = ({ updateOfferVigency }) => {
  return (
    <SimpleCard
      title="Extensión de vigencia de oferta"
      subtitle="Ingresa el ID de la oferta y la nueva fecha"
    >
      <Formik
        initialValues={{
          offerId: 0,
          newVigency: new Date(),
        }}
        render={formikProps => (
          <ChangeOfferVigencyContainer>
            <InputContainer>
              <FormikSimpleNumber
                label="ID DE OFERTA"
                name="offerId"
                allowNegative={false}
              />
            </InputContainer>
            <InputContainer>
              <FormikDate label="FECHA DE VIGENCIA" name="newVigency" />
            </InputContainer>
            <ButtonContainer>
              <Button onClick={() => formikProps.submitForm()}>
                Actualizar
              </Button>
            </ButtonContainer>
          </ChangeOfferVigencyContainer>
        )}
        onSubmit={values => updateOfferVigency(values)}
        validationSchema={Yup.object().shape({
          offerId: Yup.number()
            .required('Introduce un ID de oferta')
            .positive('Introduce un ID positivo'),
          newVigency: Yup.date()
            .min(
              new Date(new Date().setHours(0, 0, 0, 0)),
              'La fecha tiene que ser mayor o igual a hoy'
            )
            .required('Introduce una fecha'),
        })}
      />
    </SimpleCard>
  );
};

const changeOfferVigencyCreators = {
  updateOfferVigency:
    customerSupportActions.creators.updateOfferVigency.request,
};

const ChangeOfferVigency = connect(
  null,
  changeOfferVigencyCreators
)(ConnectedChangeOfferVigency);

const CustomerSupportView: React.FunctionComponent<{}> = () => {
  return (
    <ViewContainer>
      <PendingBiometricVerificationList />
      <PendingComplainceRecordsList />
      <ConnectedUpdateUserData />
      <ChangeOfferVigency />
    </ViewContainer>
  );
};

export default CustomerSupportView;
