import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const REQUESTS_OFFERED_FETCH = createAsyncFlowTypes('REQUESTS_OFFERED_FETCH');
const REQUEST_RISK_PROFILE_FETCH = createAsyncFlowTypes(
  'REQUEST_RISK_PROFILE_FETCH'
);
const APPLICATION_DETAILS_FETCH = createAsyncFlowTypes(
  'APPLICATION_DETAILS_FETCH'
);

const fetchRequestsOffered = createAsyncFlowCreators(REQUESTS_OFFERED_FETCH);
const fetchRequestRisk = createAsyncFlowCreators(REQUEST_RISK_PROFILE_FETCH);
const fetchApplicationDetails = createAsyncFlowCreators(
  APPLICATION_DETAILS_FETCH
);

export default {
  types: {
    REQUESTS_OFFERED_FETCH,
    REQUEST_RISK_PROFILE_FETCH,
    APPLICATION_DETAILS_FETCH,
  },
  creators: {
    fetchRequestsOffered,
    fetchRequestRisk,
    fetchApplicationDetails,
  },
};
