import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 30 30';

const TrashIcon = ({ fill }: { fill: string }) => (
  <g>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M27,6h-3h-3V3c0-0.5527-0.4478-1-1-1h-8c-0.5522,0-1,0.4473-1,1v3H8H5C4.4478,6,4,6.4473,4,7s0.4478,1,1,1h2v21    c0,0.5527,0.4478,1,1,1h16c0.5522,0,1-0.4473,1-1V8h2c0.5522,0,1-0.4473,1-1S27.5522,6,27,6z M13,4h6v2h-6V4z M23,28H9V8h3h8h3V28    z"
    ></path>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M16,11c-0.5522,0-1,0.4473-1,1v12c0,0.5527,0.4478,1,1,1s1-0.4473,1-1V12C17,11.4473,16.5522,11,16,11z"
    ></path>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M19,12v12c0,0.5527,0.4478,1,1,1s1-0.4473,1-1V12c0-0.5527-0.4478-1-1-1S19,11.4473,19,12z"
    ></path>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M12,11c-0.5522,0-1,0.4473-1,1v12c0,0.5527,0.4478,1,1,1s1-0.4473,1-1V12C13,11.4473,12.5522,11,12,11z"
    ></path>
  </g>
);

export default BaseIcon(TrashIcon, viewBox);
