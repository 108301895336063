import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';

const HeaderContainer = styled.div`
  align-items: center;
  background-color: ${colors.WHITE};
  border: solid 1px ${colors.BORDER};
  border-radius: 4px;
  box-shadow: ${colors.BOX_SHADOW};
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 36px 30px;
  padding-right: 20px;
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

interface IViewHeaderProps {
  title: string;
}

const ViewHeader: React.FunctionComponent<IViewHeaderProps> = ({
  children,
  title,
}) => (
  <HeaderContainer>
    <HeaderTitle>{title}</HeaderTitle>
    {children}
  </HeaderContainer>
);

export default ViewHeader;
