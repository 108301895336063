import * as Sentry from '@sentry/browser';
import fromPairs from 'ramda/src/fromPairs';
import { select, take, takeEvery } from 'redux-saga/effects';

import accountCreationActions from '../actions/accountCreation';
import applicationCreationActions from '../actions/applicationCreation';
import authActions from '../actions/auth';
import profilingActions from '../actions/profiling';
import verificationActions from '../actions/verificationModal';

function* initSentry() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_DOMAIN,
  });

  yield take(authActions.types.AUTH_USER_DATA_FETCH.COMPLETED);

  const { user, metadata } = yield select(state => state.auth.userData);

  const userInfo = {
    id: user.id,
    email: user.email,
    username: `${user.name} ${user.firstSurname} ${user.secondSurname}`,
    metadata,
  };

  Sentry.configureScope(scope => {
    scope.setUser(userInfo);
  });
}

function* watchForApplicationCreationRelatedActions() {
  const applicationCreationRelatedActions = [
    profilingActions.types.PROFILING_STARTED,
    verificationActions.types.VERIFICATION_START.STARTED,
    accountCreationActions.types.ACCOUNT_CREATION_CLIENT.STARTED,
    accountCreationActions.types.ACCOUNT_CREATION_CLIENT_VERIFICATION.STARTED,
    applicationCreationActions.types.APPLICATION_CREATION.STARTED,
  ];

  const actionMap = fromPairs(
    applicationCreationRelatedActions.map(action => [action, true])
  );

  const matchAction = ({ type }) => actionMap[type];

  function captureEvent(action) {
    Sentry.withScope(scope => {
      scope.setLevel('info');
      scope.setExtra('payload', action.payload);
      Sentry.captureMessage(action.type);
    });
  }

  yield takeEvery(matchAction, captureEvent);
}

function* watchForFailedActions() {
  const getActionError = ({ payload }) => {
    if (payload instanceof Error) {
      return payload;
    } else if (payload && payload.error && payload.error instanceof Error) {
      return payload.error;
    }

    return undefined;
  };

  const matchFailedAction = action => {
    const error = getActionError(action);
    const is401 = error && error.message.includes('401');

    return action.type.includes('FAILED') && !is401;
  };

  function captureEvent(action) {
    const { payload, type } = action;
    const error = getActionError(action);

    if (error) {
      Sentry.withScope(scope => {
        scope.setExtra('actionType', type);
        Sentry.captureException(error);
      });
    } else {
      Sentry.withScope(scope => {
        scope.setExtra('payload', payload);
        scope.setLevel('error');
        Sentry.captureMessage(type);
      });
    }
  }

  yield takeEvery(matchFailedAction, captureEvent);
}

export default {
  initSentry,
  watchForApplicationCreationRelatedActions,
  watchForFailedActions,
};
