import {
  isBankAdmin,
  isBankLegal,
  isBankSuperAnalyst,
  isBankUser,
  isPromoterFNI,
  isPromoterUser,
} from '../../utils/permissions';

const accepted = {
  text: 'Aceptadas',
  path: '/pipeline/activated',
  stateSelector: (state: any) => ({
    count: state.offerCounts.activated || '--',
  }),
};

const offered = {
  text: 'Ofertadas',
  path: '/pipeline/offered',
  stateSelector: (state: any) => ({
    count: state.offerCounts['pre-activation'] || '--',
  }),
};

const analysis = {
  text: 'Revisión',
  path: '/pipeline/analysis',
  stateSelector: (state: any) => ({
    count: state.offerCounts.analysis || '--',
  }),
};

const approval = {
  text: 'Aprobación',
  path: '/pipeline/approval',
  stateSelector: (state: any) => ({
    count: state.offerCounts.approval || '--',
  }),
};

const preformalization = {
  text: 'Formalización',
  path: '/pipeline/pre-formalization',
  stateSelector: (state: any) => ({
    count: state.offerCounts['pre-formalization'] || '--',
  }),
};

const formalization = {
  text: 'Digitalización',
  path: '/pipeline/formalization',
  stateSelector: (state: any) => ({
    count: state.offerCounts.formalization || '--',
  }),
};

const release = {
  text: 'Liberación',
  path: '/pipeline/release',
  stateSelector: (state: any) => ({ count: state.offerCounts.release || '--' }),
};

const promoterSteps = [
  offered,
  accepted,
  analysis,
  approval,
  preformalization,
  formalization,
  release,
];

const promoterFNISteps = [
  accepted,
  analysis,
  approval,
  preformalization,
  formalization,
  release,
];

const bankAdminSteps = [
  analysis,
  approval,
  preformalization,
  formalization,
  release,
];

const bankLegalSteps = [approval, preformalization, formalization, release];

const getNavigatorConfig = (userData: IUserData) => {
  if (isBankUser(userData)) {
    if (isBankAdmin(userData) || isBankSuperAnalyst(userData)) {
      return bankAdminSteps;
    }

    if (isBankLegal(userData)) {
      return bankLegalSteps;
    }
  }

  if (isPromoterUser(userData)) {
    if (isPromoterFNI(userData)) {
      return promoterFNISteps;
    }
  }

  return promoterSteps;
};

export default getNavigatorConfig;
