import { call, put, takeLatest } from 'redux-saga/effects';

import customerSupportActions from '../actions/customerSupport';
import toastActions from '../actions/toastNotifications';
import {
  buildErrorToast,
  buildSuccessToast,
} from '../utils/toastNotifications';
import runner from './runners';

function* updateOfferVigency(action: IReduxAction<any>): any {
  const { error } = yield call(
    runner,
    customerSupportActions.creators.updateOfferVigency,
    {
      method: 'POST',
      payload: action.payload,
      url: 'api/customer-support/update-offer-vigency',
    },
    { snakeCase: false }
  );

  yield put(
    toastActions.creators.showToast(
      error
        ? buildErrorToast('Error', 3000)
        : buildSuccessToast('Success', 3000)
    )
  );
}

function* watchUpdateOfferVigencyRequested(): any {
  yield takeLatest(
    customerSupportActions.types.CREDITUS_UPDATE_OFFER_VIGENCY.REQUESTED,
    updateOfferVigency
  );
}

function* fetchUser(action: IReduxAction<any>): any {
  const { error } = yield call(
    runner,
    customerSupportActions.creators.creditusFetchUsers,
    {
      method: 'GET',
      url: 'api/customer-support/users',
      params: action.payload,
    },
    { snakeCase: false }
  );

  yield put(
    toastActions.creators.showToast(
      error
        ? buildErrorToast('Error', 3000)
        : buildSuccessToast('Success', 3000)
    )
  );
}

function* watchFetchUserRequested(): any {
  yield takeLatest(
    customerSupportActions.types.CREDITUS_FETCH_USERS.REQUESTED,
    fetchUser
  );
}

function* updateUserData(action: IReduxAction<any>): any {
  Object.keys(action.payload).forEach(key => {
    if (!action.payload[key]) {
      delete action.payload[key];
    }
  });
  const { error } = yield call(
    runner,
    customerSupportActions.creators.creditusUpdateUser,
    {
      method: 'PATCH',
      url: `api/customer-support/users/${action.payload.userId}`,
      payload: action.payload,
    },
    { snakeCase: false }
  );

  yield put(
    toastActions.creators.showToast(
      error
        ? buildErrorToast('El email que ingresó ya está registrado', 3000)
        : buildSuccessToast('Success', 3000)
    )
  );
}

function* watchUpdateUserDataRequested(): any {
  yield takeLatest(
    customerSupportActions.types.CREDITUS_UPDATE_USER.REQUESTED,
    updateUserData
  );
}

export default {
  watchUpdateOfferVigencyRequested,
  watchFetchUserRequested,
  watchUpdateUserDataRequested,
};
