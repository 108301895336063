import format from 'date-fns/format';
import isNil from 'ramda/src/isNil';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import accountCreationActions from '../actions/accountCreation';
import toastActions from '../actions/toastNotifications';
import { addSearchParamsToPayload } from '../utils/misc';
import {
  buildErrorToast,
  buildSuccessToast,
} from '../utils/toastNotifications';
import runner from './runners';

const translateClientAccountCreationPayload = clientData => {
  const {
    name,
    firstSurname,
    secondSurname,
    email,
    phoneNumber,
    password,
    dateOfBirth,
    biometricVerificationId,
    sex,
    countryOfBirth,
    nationality,
    stateOfBirth,
    curp,
    taxPayerId,
    taxPayerIdComplement,
  } = clientData;

  return {
    name,
    firstSurname,
    secondSurname,
    email,
    phoneNumber,
    password,
    dateOfBirth: format(dateOfBirth, 'yyyy-MM-dd'),
    termsAccepted: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    biometricVerificationId,
    curp,
    taxPayerId: `${taxPayerId}${taxPayerIdComplement.toUpperCase()}`,
    sex,
    countryOfBirth,
    nationality,
    stateOfBirth,
  };
};

const translateClientAccountVerificationPayload = ({
  clientId,
  nip,
  verificationId,
}) => ({
  clientId,
  verificationCode: nip,
  verificationId,
});

const UNEXPECTED_ERROR_TOAST_MESSAGE =
  'Hubo un error al crear la cuenta. Podemos atenderte en el chat de ayuda.';

function* createClientAccount(action) {
  const { client: clientData } = action.payload;
  const params = addSearchParamsToPayload(
    translateClientAccountCreationPayload(clientData),
    ['promoterUserCode']
  );

  const { error } = yield call(
    runner,
    accountCreationActions.creators.createClientAccount,
    {
      method: 'POST',
      payload: params,
      url: '/api/requests/client-account',
    },
    { snakeCase: false }
  );

  if (error) {
    const toast = buildErrorToast(UNEXPECTED_ERROR_TOAST_MESSAGE, 5000);
    yield put(toastActions.creators.showToast(toast));
  }
}

function* watchCreateClientAccountRequested() {
  yield takeLatest(
    accountCreationActions.types.ACCOUNT_CREATION_CLIENT.REQUESTED,
    createClientAccount
  );
}

function* verifyClientAccount(action) {
  const payload = addSearchParamsToPayload(
    translateClientAccountVerificationPayload(action.payload),
    ['promoterUserCode']
  );
  const { error } = yield call(
    runner,
    accountCreationActions.creators.verifyClientAccount,
    {
      method: 'POST',
      payload: payload,
      url: '/api/requests/client-account/activate',
    },
    { snakeCase: false }
  );

  if (error) {
    if (error.response.error === 'INCORRECT_CODE') {
      yield put(
        toastActions.creators.showToast(
          buildErrorToast('El NIP proporcionado es incorrecto.', 5000)
        )
      );
    } else {
      yield put(
        toastActions.creators.showToast(
          buildErrorToast(UNEXPECTED_ERROR_TOAST_MESSAGE, 5000)
        )
      );
    }
  }
}

function* watchVerifyClientAccountRequested() {
  yield takeLatest(
    accountCreationActions.types.ACCOUNT_CREATION_CLIENT_VERIFICATION.REQUESTED,
    verifyClientAccount
  );
}

function* sendClientAccountNipViaWhatsApp(action) {
  const { phoneNumber } = action.payload;

  let complianceId = null;
  if (isNil(action.payload.complianceId)) {
    complianceId = yield select(state =>
      state.newApplication.accountCreation.account
        ? state.newApplication.accountCreation.account.verificationId
        : state.newApplication.existentClient.complianceId
    );
  } else {
    complianceId = action.payload.complianceId;
  }

  const payload = addSearchParamsToPayload(
    {
      phoneNumber,
      verificationId: complianceId,
    },
    ['promoterUserCode']
  );

  const { error } = yield call(
    runner,
    accountCreationActions.creators.sendClientAccountNipViaWhatsapp,
    {
      method: 'POST',
      payload: payload,
      url: '/api/requests/client-account/resend-verification-code',
    },
    { snakeCase: false }
  );

  if (error) {
    yield put(
      toastActions.creators.showToast(
        buildErrorToast(
          'Hubo un problema reenviando el NIP. Si el problema persiste por favor contacta a soporte al número: (331) 803-7895.',
          5000
        )
      )
    );
  } else {
    yield put(
      toastActions.creators.showToast(
        buildSuccessToast('NIP reenviado con éxito.', 5000)
      )
    );
  }
}

function* watchSendClientAccountNipViaWhatsApp() {
  yield takeLatest(
    accountCreationActions.types.ACCOUNT_CREATION_SEND_NIP_VIA_WHATSAPP
      .REQUESTED,
    sendClientAccountNipViaWhatsApp
  );
}

export default {
  watchCreateClientAccountRequested,
  watchVerifyClientAccountRequested,
  watchSendClientAccountNipViaWhatsApp,
};
