import React from 'react';

import BaseIcon from './BaseIcon';

const viewBox = '0 0 14 17';

const DownloadIcon = ({ fill }: { fill: string }) => (
  <g fill="none" fillRule="evenodd" transform="translate(14, 17) rotate(180)">
    <path
      d="M14 6h-4v-6h-6v6h-4l7 7 7-7zm-14 9v2h14v-2h-14z"
      fill={fill}
      fillRule="nonzero"
    />
  </g>
);

export default BaseIcon(DownloadIcon, viewBox);
