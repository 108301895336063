import { Formik, FormikProps } from 'formik';
import isNil from 'ramda/src/isNil';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';

import accountCreationActions from '../../actions/accountCreation';
import existentClientActions from '../../actions/existentClient';
import colors from '../../constants/colors';
import Success from '../../resources/success.png';
import Button from '../Button';
import { FormikText } from '../FormikInputs';
import LoadingIndicator from '../LoadingIndicator';
import Modal from '../Modal';
import SendViaWhatsApp from '../SendViaWhatsApp';
import errorMsg from './errorMessages';

const ControlContainer = styled.div`
  display: flex;
  height: 38px;
  justify-content: flex-end;
`;

const ModalHeader = styled.div`
  align-items: center;
  color: ${colors.PRIMARY_TEXT};
  display: flex;
  font-size: 14px;
  font-weight: bold;
  height: 48px;
`;

const DescriptionText = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 14px;
  text-align: justify;
  padding-bottom: 24px;
`;

const LoadingIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const SuccessContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const SuccessIcon = styled.img`
  height: 24px;
  margin-right: 8px;
  display: flex;
`;

interface INipCheck {
  nip: string;
}

interface INipModalForm {
  closeModal: () => void;
  showModal: boolean;
  showLoading: boolean;
  formikProps: FormikProps<INipCheck>;
  existentClientData: {
    clientAccount: IUser;
    complianceId: null | number;
    nipVerified: null | boolean;
  };
  sendClientAccountNipViaWhatsapp: Function;
}

interface IExistentClientNipModal {
  curp: string;
  biometricVerificationId: number;
  sendNip: Function;
  verifyNip: Function;
  sendClientAccountNipViaWhatsapp: Function;
  sendingNip: boolean;
  verifyingNip: boolean;
  existentClientData: {
    clientAccount: IUser | null;
    complianceId: number | null;
    nipVerified: boolean | null;
  };
}

const NipModalForm: React.FunctionComponent<INipModalForm> = ({
  closeModal,
  showModal,
  showLoading,
  formikProps,
  existentClientData,
  sendClientAccountNipViaWhatsapp,
}) => {
  const showSucceed =
    !isNil(existentClientData.nipVerified) && existentClientData.nipVerified;
  const renderContent = () => {
    if (showLoading || isNil(existentClientData)) {
      return (
        <LoadingIndicatorContainer>
          <LoadingIndicator />
        </LoadingIndicatorContainer>
      );
    } else if (showSucceed) {
      return (
        <SuccessContent>
          <SuccessIcon src={Success} />
          Verificación exitosa
        </SuccessContent>
      );
    } else {
      return (
        <Fragment>
          <DescriptionText>
            Ingresa el NIP que enviamos a tu celular.
          </DescriptionText>
          <FormikText label="NIP" name="nip" inputMode="numeric" />
          <SendViaWhatsApp
            drawerText="¿Aún no recibes el NIP? Da click aquí"
            formText="Recibe tu NIP por"
            initialPhoneValue={existentClientData.clientAccount.phoneNumber}
            inputDisabled={true}
            onSubmit={phoneNumber =>
              sendClientAccountNipViaWhatsapp({ phoneNumber })
            }
          />
        </Fragment>
      );
    }
  };

  return (
    <Modal
      content={() => renderContent()}
      controls={() => (
        <ControlContainer>
          {!(showLoading || showSucceed) ? (
            <Button onClick={() => formikProps.submitForm()}>Verificar</Button>
          ) : null}
        </ControlContainer>
      )}
      header={() => <ModalHeader>Verificación de identidad</ModalHeader>}
      isOpen={showModal}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={false}
      showCloseIcon={false}
      width={'400px'}
    />
  );
};

const ExistentClientNipModal: React.FunctionComponent<IExistentClientNipModal> = ({
  curp,
  biometricVerificationId,
  sendNip,
  verifyNip,
  sendingNip,
  sendClientAccountNipViaWhatsapp,
  verifyingNip,
  existentClientData,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (
      !showModal &&
      !isNil(curp) &&
      !isNil(existentClientData.clientAccount)
    ) {
      setShowModal(true);
      if (isNil(existentClientData.complianceId)) {
        sendNip({
          biometricVerificationId,
          clientId: existentClientData.clientAccount.id,
          phoneNumber: existentClientData.clientAccount.phoneNumber,
        });
      }
    }
  }, [biometricVerificationId, curp, existentClientData, sendNip, showModal]);

  const checkNip = (values: INipCheck) => {
    if (
      !isNil(existentClientData.complianceId) &&
      !isNil(existentClientData.clientAccount)
    ) {
      verifyNip({
        complianceNip: values.nip,
        complianceId: existentClientData.complianceId,
        clientId: existentClientData.clientAccount.id,
      });
    }
  };

  const nipRegExp = /^\d{6}$/;

  const { clientAccount } = existentClientData;

  if (isNil(clientAccount) || !showModal) {
    return null;
  } else {
    return (
      <Formik
        initialValues={{ nip: '' }}
        onSubmit={values => checkNip(values)}
        render={formikProps => (
          <NipModalForm
            closeModal={() => setShowModal(false)}
            formikProps={formikProps}
            showModal={showModal}
            showLoading={verifyingNip || sendingNip}
            existentClientData={{
              ...existentClientData,
              clientAccount,
            }}
            sendClientAccountNipViaWhatsapp={sendClientAccountNipViaWhatsapp}
          />
        )}
        validationSchema={Yup.object().shape({
          nip: Yup.string()
            .matches(nipRegExp, {
              message: errorMsg.nip.matches,
            })
            .required(errorMsg.nip.required),
        })}
      />
    );
  }
};

const mapStateToProps = (state: any) => ({
  verifyingNip: state.loaders.verifyingNip,
  sendingNip: state.loaders.sendingNip,
  curp: state.newApplication.verificationModal.curp,
  existentClientData: state.newApplication.existentClient,
  biometricVerificationId:
    state.newApplication.verificationModal.biometricVerificationId,
});

const creators = {
  sendNip: existentClientActions.creators.sendNipExistentClient.request,
  verifyNip: existentClientActions.creators.verifyNipExistentClient.request,
  sendClientAccountNipViaWhatsapp:
    accountCreationActions.creators.sendClientAccountNipViaWhatsapp.request,
};

export default connect(mapStateToProps, creators)(ExistentClientNipModal);
