import { Effect, call, put, select, takeLatest } from 'redux-saga/effects';

import statsActions from '../actions/stats';
import {
  isBankAdmin,
  isBankUser,
  isCreditusUser,
  isPromoterAdmin,
  isPromoterUser,
} from '../utils/permissions';
import client from './client';

interface IFetchStatsPayload {
  dates: {
    currentPeriod: {
      startDate: Date;
      endDate: Date;
    };
    previousPeriod: {
      startDate: Date;
      endDate: Date;
    };
  };
  dateRange: string;
}

const getStatsEndpoint = (userData: IUserData): string | null => {
  if (isBankUser(userData) && isBankAdmin(userData)) {
    return `/api/stats/bank/${userData.organization.bank.id}`;
  }

  if (isCreditusUser(userData)) {
    return '/api/stats/creditus';
  }

  if (isPromoterUser(userData) && isPromoterAdmin(userData)) {
    return `/api/stats/promoter/${userData.organization.promoter.id}`;
  }

  return null;
};

function* fetchStats(action: IReduxAction<IFetchStatsPayload>) {
  const { currentPeriod, previousPeriod } = action.payload.dates;

  try {
    const endpoint = getStatsEndpoint(
      yield select((state: any) => state.auth.userData)
    );

    if (endpoint === null) {
      throw new Error('user has no access to stats endpoint');
    }

    yield put(statsActions.creators.fetchStats.started());

    const { data: currentPeriodData } = yield call(client.get, endpoint, {
      params: {
        startDate: currentPeriod.startDate.toISOString(),
        endDate: currentPeriod.endDate.toISOString(),
      },
    });

    const { data: previousPeriodData } = yield call(client.get, endpoint, {
      params: {
        startDate: previousPeriod.startDate.toISOString(),
        endDate: previousPeriod.endDate.toISOString(),
      },
    });

    yield put(
      statsActions.creators.fetchStats.completed({
        data: {
          currentPeriod: currentPeriodData,
          previousPeriod: previousPeriodData,
        },
        dates: action.payload.dates,
        dateRange: action.payload.dateRange,
      })
    );
  } catch (e) {
    yield put(statsActions.creators.fetchStats.failed({ error: e }));
  }
}

function* watchStatsFetchRequested(): Iterator<Effect> {
  yield takeLatest(statsActions.types.STATS_FETCH.REQUESTED, fetchStats);
}

export default {
  watchStatsFetchRequested,
};
