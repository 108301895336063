const EDUCATION_LEVEL_OPTIONS = [
  { text: 'Primaria', value: 'elementary' },
  { text: 'Secundaria', value: 'middle' },
  { text: 'Preparatoria', value: 'high-school' },
  { text: 'Universidad', value: 'college' },
  { text: 'Posgrado', value: 'gratuade-school' },
];

const SEX_OPTIONS = [
  { text: 'Hombre', value: 'male' },
  { text: 'Mujer', value: 'female' },
];

const STATE_OF_BIRTH_OPTIONS = [
  { text: 'Aguascalientes', value: 'AS' },
  { text: 'Baja California Norte', value: 'BC' },
  { text: 'Baja California Sur', value: 'BS' },
  { text: 'Campeche', value: 'CC' },
  { text: 'Coahuila', value: 'CL' },
  { text: 'Colima', value: 'CM' },
  { text: 'Chiapas', value: 'CS' },
  { text: 'Chihuahua', value: 'CH' },
  { text: 'Ciudad de México', value: 'DF' },
  { text: 'Durango', value: 'DG' },
  { text: 'Guanajuato', value: 'GT' },
  { text: 'Guerrero', value: 'GR' },
  { text: 'Hidalgo', value: 'HG' },
  { text: 'Jalisco', value: 'JC' },
  { text: 'Estado de México', value: 'MC' },
  { text: 'Michoacán', value: 'MN' },
  { text: 'Morelos', value: 'MS' },
  { text: 'Nayarit', value: 'NT' },
  { text: 'Nuevo León', value: 'NL' },
  { text: 'Oaxaca', value: 'OC' },
  { text: 'Puebla', value: 'PL' },
  { text: 'Querétaro', value: 'QT' },
  { text: 'Quintana Roo', value: 'QR' },
  { text: 'San Luis Potosí', value: 'SP' },
  { text: 'Sinaloa', value: 'SL' },
  { text: 'Sonora', value: 'SR' },
  { text: 'Tabasco', value: 'TC' },
  { text: 'Tamaulipas', value: 'TS' },
  { text: 'Tlaxcala', value: 'TL' },
  { text: 'Veracruz', value: 'VZ' },
  { text: 'Yucatán', value: 'YN' },
  { text: 'Zacatecas', value: 'ZS' },
  { text: 'Nacido en el extranjero', value: 'NE' },
];

const CREDIT_RECORDS_OPTIONS = [
  {
    text: 'No cuento con Historial',
    value: 'no_records',
  },
  {
    text: 'Actualmente cuento con atrasos',
    value: 'with_debt',
  },
  {
    text: 'He tenido algunos atrasos',
    value: 'has_debt',
  },
  {
    text: 'Nunca he tenido atrasos',
    value: 'no_debt',
  },
];

const MARITAL_OPTIONS = [
  {
    text: 'Soltero',
    value: 'single',
  },
  {
    text: 'Casado',
    value: 'married',
  },
  {
    text: 'Divorciado',
    value: 'divorced',
  },
  {
    text: 'Viudo',
    value: 'widowed',
  },
  {
    text: 'Unión libre',
    value: 'domestic_partnership',
  },
];

const REGIME_OPTIONS = [
  {
    text: 'Separación de bienes',
    value: 'separate',
  },
  {
    text: 'Sociedad conyugal',
    value: 'community',
  },
];

const HOME_OPTIONS = [
  {
    text: 'Rentada',
    value: 'rent',
  },
  {
    text: 'Propia',
    value: 'own',
  },
  {
    text: 'Hipotecada',
    value: 'mortgage',
  },
  {
    text: 'Huesped',
    value: 'guest',
  },
];

const EMPLOYMENT_OPTIONS = [
  {
    text: 'Empleado',
    value: 'employed',
  },
  {
    text: 'Independiente',
    value: 'independent',
  },
  {
    text: 'Jubilado',
    value: 'retired',
  },
  {
    text: 'Estudiante',
    value: 'student',
  },
  {
    text: 'Ama de casa',
    value: 'housewife',
  },
  {
    text: 'Desempleado',
    value: 'unemployed',
  },
];

const CONTRACT_OPTIONS = [
  {
    text: 'Tiempo determinado',
    value: 'fixed_term',
  },
  {
    text: 'Tiempo indeterminado',
    value: 'undetermined_term',
  },
  {
    text: 'Temporal',
    value: 'temporary',
  },
  {
    text: 'Periodo de prueba',
    value: 'trial_period',
  },
];

const INCOME_OPTIONS = [
  {
    text: 'Ahorro',
    value: 'savings',
  },
  {
    text: 'Comisiones',
    value: 'commission',
  },
  {
    text: 'Donaciones',
    value: 'donations',
  },
  {
    text: 'Inversiones',
    value: 'investments',
  },
  {
    text: 'Renta',
    value: 'rent',
  },
  {
    text: 'Otro',
    value: 'other',
  },
  {
    text: 'No aplica',
    value: 'no_apply',
  },
];

const VEHICLE_TYPE_OPTIONS = [
  {
    text: 'Nueva',
    value: 'new',
  },
  {
    text: 'Seminueva',
    value: 'used',
  },
];

const TERM_OPTIONS = [3, 6, 12, 18, 24, 30, 36, 48, 60, 72];

const INSURANCE_OPTIONS = [
  {
    text: 'Contado',
    value: 'cash',
  },
  {
    text: 'Financiado',
    value: 'credit',
  },
  {
    text: 'Sin seguro',
    value: 'no_insurance',
  },
];

const CLIENT_INSURANCE_OPTIONS = [
  {
    text: 'Sin seguro',
    value: 'no_insurance',
  },
];

const RELATIONSHIP_OPTIONS = [
  {
    text: 'Conocido',
    value: 'acquaintance',
  },
  {
    text: 'Amigo',
    value: 'friend',
  },
  {
    text: 'Colega',
    value: 'colleague',
  },
  {
    text: 'Jefe',
    value: 'boss',
  },
  {
    text: 'Otro',
    value: 'other',
  },
];

const INDUSTRY_TYPE_OPTIONS = [
  {
    text: 'Comercio',
    value: 'commerce',
  },
  {
    text: 'Servicios',
    value: 'services',
  },
  {
    text: 'Industria',
    value: 'industry',
  },
];

const ACTIVITY_TYPE_OPTIONS = [
  {
    text: 'Privado',
    value: 'private',
  },
  {
    text: 'Público federal',
    value: 'federal',
  },
  {
    text: 'Público estatal',
    value: 'state',
  },
  {
    text: 'Público municipal',
    value: 'municipal',
  },
];

const TRUE_FALSE_OPTIONS = [
  { text: 'Sí', value: true },
  { text: 'No', value: false },
];

const PRODUCT_OPTIONS = [
  { text: 'Motocicleta', value: 'motorcycle_finance' },
  { text: 'Automotriz', value: 'car_finance' },
];

export {
  EDUCATION_LEVEL_OPTIONS,
  SEX_OPTIONS,
  STATE_OF_BIRTH_OPTIONS,
  CREDIT_RECORDS_OPTIONS,
  MARITAL_OPTIONS,
  REGIME_OPTIONS,
  HOME_OPTIONS,
  EMPLOYMENT_OPTIONS,
  CONTRACT_OPTIONS,
  INCOME_OPTIONS,
  VEHICLE_TYPE_OPTIONS,
  TERM_OPTIONS,
  INSURANCE_OPTIONS,
  RELATIONSHIP_OPTIONS,
  INDUSTRY_TYPE_OPTIONS,
  ACTIVITY_TYPE_OPTIONS,
  TRUE_FALSE_OPTIONS,
  PRODUCT_OPTIONS,
  CLIENT_INSURANCE_OPTIONS,
};
