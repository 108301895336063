import compose from 'ramda/src/compose';

const removeDuplicateOffers = (offers: IOffer[]) => {
  const offerKeyMap: { [offerKey: string]: boolean } = {};

  return offers.filter(offer => {
    const offerKey = `${offer.bankId}${offer.downpayment}${offer.requestPeriod}${offer.cat}`;

    if (!offerKeyMap[offerKey]) {
      offerKeyMap[offerKey] = true;
      return true;
    } else {
      return false;
    }
  });
};

const removeIrrelevantOffers = (offers: IOffer[]) => {
  const offersPerBank: { [bankId: number]: number } = {};

  return offers.filter(offer => {
    const bankOfferCount = offersPerBank[offer.bankId];

    if (bankOfferCount === undefined) {
      offersPerBank[offer.bankId] = 1;
      return true;
    } else {
      offersPerBank[offer.bankId] += 1;

      return offersPerBank[offer.bankId] > 2;
    }
  });
};

export default compose(removeIrrelevantOffers, removeDuplicateOffers);
