import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';

const TriangleDown = styled.div`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;

  border-top: 10px solid ${colors.AQUA};

  margin-left: auto;
`;

const TriangleUp = styled.div<{ disabled?: boolean }>`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;

  border-bottom: 10px solid
    ${({ disabled }) => (disabled ? colors.DUSK50 : colors.PRIMARY_TEXT)};

  margin-left: auto;
`;

interface ExpandIndicatorProps {
  disabled?: boolean;
  expanded: boolean;
}

const ExpandIndicator: React.FunctionComponent<ExpandIndicatorProps> = ({
  disabled,
  expanded,
}) => (expanded ? <TriangleDown /> : <TriangleUp disabled={disabled} />);

export default ExpandIndicator;
