import React from 'react';
import styled from 'styled-components';

const CustomLink = styled.a`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`;

const ExternalLink = ({ children, to }) => (
  <CustomLink href={to}>{children}</CustomLink>
);

export default ExternalLink;
