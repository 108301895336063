import { createAction } from 'redux-actions';

import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const DOCUMENT_FILE_UPLOAD = createAsyncFlowTypes('DOCUMENT_FILE_UPLOAD');
const DOCUMENT_FILE_UPLOAD_RESET_STATE = 'DOCUMENT_FILE_UPLOAD_RESET_STATE';
const DOCUMENT_GET = createAsyncFlowTypes('DOCUMENT_GET');
const DOCUMENT_UPDATE_STATUS = createAsyncFlowTypes('DOCUMENT_UPDATE_STATUS');

const getDocument = createAsyncFlowCreators(DOCUMENT_GET);
const updateDocumentStatus = createAsyncFlowCreators(DOCUMENT_UPDATE_STATUS);
const uploadDocumentFile = createAsyncFlowCreators(DOCUMENT_FILE_UPLOAD);
const clearFileUploadState = createAction(DOCUMENT_FILE_UPLOAD_RESET_STATE);

export default {
  types: {
    DOCUMENT_FILE_UPLOAD,
    DOCUMENT_FILE_UPLOAD_RESET_STATE,
    DOCUMENT_GET,
    DOCUMENT_UPDATE_STATUS,
  },
  creators: {
    getDocument,
    updateDocumentStatus,
    uploadDocumentFile,
    clearFileUploadState,
  },
};
