import { handleActions } from 'redux-actions';

const emptyPrefillingData = {
  firstSurname: '',
  secondSurname: '',
  name: '',
  dateOfBirth: '',
  stateOfBirth: '',
  countryOfBirth: '',
  nationality: '',
  sex: '',
};

const initialState = {
  biometricVerificationId: null,
  verificationCompleted: null,
  curp: null,
  address: null,
  prefillingData: null,
};

const accountCreationReducer = handleActions(
  {
    VERIFICATION_START_COMPLETED: (state, { payload }) => {
      return {
        ...state,
        biometricVerificationId: payload.id,
        verificationCompleted: 0,
      };
    },
    VERIFICATION_WAIT_COMPLETION_COMPLETED: (state, { payload }) => {
      const { biometricVerification } = payload;
      return {
        ...state,
        biometricVerificationId: biometricVerification.id,
        verificationCompleted: biometricVerification.used,
      };
    },
    VERIFICATION_GET_CURP_COMPLETED: (state, { payload }) => {
      return {
        ...state,
        curp: payload.curp,
        address: payload.address,
      };
    },
    VERIFICATION_GET_PREFILLING_DATA_COMPLETED: (state, { payload }) => {
      return {
        ...state,
        prefillingData: payload,
      };
    },
    VERIFICATION_GET_PREFILLING_DATA_FAILED: (state, { payload }) => {
      if (payload.response.status === 503) {
        return {
          ...state,
          prefillingData: { ...emptyPrefillingData },
        };
      }

      return state;
    },
    CLEAR_NEW_APPLICATION: () => initialState,
  },
  initialState
);

export default accountCreationReducer;
