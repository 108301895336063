import { createAsyncFlowCreators, createAsyncFlowTypes } from './async';

const FETCH_AUDIT_APPLICATIONS = createAsyncFlowTypes(
  'FETCH_AUDIT_APPLICATIONS'
);
const fetchAuditApplications = createAsyncFlowCreators(
  FETCH_AUDIT_APPLICATIONS
);

export default {
  types: {
    FETCH_AUDIT_APPLICATIONS,
  },
  creators: {
    fetchAuditApplications,
  },
};
