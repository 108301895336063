import { handleActions } from 'redux-actions';

const initialState = {
  hiddenData: null,
  recoverySent: null,
  passwordChanged: null,
};

const forgotPasswordReducer = handleActions(
  {
    ACCOUNT_WITH_EMAIL_CHECK_REQUESTED: () => initialState,
    ACCOUNT_WITH_EMAIL_CHECK_FAILED: () => initialState,
    ACCOUNT_WITH_EMAIL_CHECK_COMPLETED: (state, { payload }) => ({
      ...initialState,
      hiddenData: payload,
    }),
    SEND_PASSWORD_RECOVERY_REQUESTED: state => ({
      ...state,
      recoverySent: null,
    }),
    SEND_PASSWORD_RECOVERY_COMPLETED: state => ({
      ...state,
      recoverySent: true,
    }),
    SEND_PASSWORD_RECOVERY_FAILED: state => ({ ...state, recoverySent: false }),
    RESET_PASSWORD_REQUESTED: () => initialState,
    RESET_PASSWORD_FAILED: () => ({ ...initialState, passwordChanged: false }),
    RESET_PASSWORD_COMPLETED: () => ({
      ...initialState,
      passwordChanged: true,
    }),
    RESET_PASSWORD_RECOVERY: () => initialState,
  },
  initialState
);

export default forgotPasswordReducer;
