import React from 'react';
import styled from 'styled-components';

import colors from '../../constants/colors';
import f from '../../constants/formatters';
import Border from '../Border';
import Card from '../Card';
import {
  Caption,
  FixedInterestCaption,
  VariableInterestCaption,
  VariableInterestCreditCardCaption,
} from './captions';

const { REACT_APP_PUBLIC_ASSETS_URL } = process.env;

const BankLogo = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;

const BankLogoContainer = styled.div`
  padding: 16px 24px;
`;

const BankDetailsContainer = styled.div`
  margin-bottom: 20px;
`;

const Header = styled.div`
  align-items: center;
  color: ${colors.DUSK50};
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 36px;
  line-height: 1.5;
  text-transform: uppercase;
`;

const TablePadding = styled.div`
  padding: 0 24px;
`;

const TextContainer = styled.div`
  color: ${colors.PRIMARY_TEXT};
  font-size: 12px;
  font-weight: normal;
  padding: 8px 24px;
`;

const CaptionText = styled(TextContainer)`
  font-size: 10px;
`;

const getCaptionComponent = (offer: any): React.FunctionComponent<Caption> => {
  const productType = offer.request.type;
  const interestType = offer.rateOptions;

  if (interestType === 'fixed') {
    return FixedInterestCaption;
  } else {
    if (productType === 'tarjeta') {
      return VariableInterestCreditCardCaption;
    } else {
      return VariableInterestCaption;
    }
  }
};

interface Props {
  offer: any;
}

const OfferBankDetails: React.FunctionComponent<Props> = ({ offer }) => {
  const { bank } = offer;

  const logoURL = `${REACT_APP_PUBLIC_ASSETS_URL}${bank.logoBank}`;

  const Caption = getCaptionComponent(offer);
  const cat = f.percentage(offer.cat * 100, 1);

  return (
    <Card header={'Detalles del otorgante'}>
      <BankDetailsContainer>
        <BankLogoContainer>
          <BankLogo src={logoURL} />
        </BankLogoContainer>
        <Border />
        <TablePadding>
          <Header>Nombre del otorgante</Header>
        </TablePadding>
        <Border />
        <TextContainer>{offer.bank.name}</TextContainer>
        <Border />
        <TablePadding>
          <Header>Leyendas de advertencia</Header>
        </TablePadding>
        <Border />
        <CaptionText>
          <Caption cat={cat} webPage={bank.webPage} />
        </CaptionText>
      </BankDetailsContainer>
    </Card>
  );
};

export default OfferBankDetails;
