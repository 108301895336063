import * as Sentry from '@sentry/browser';
import values from 'ramda/src/values';
import { all, call } from 'redux-saga/effects';

import accountCreationSagas from './accountCreationSagas';
import accountDataCheckSagas from './accountDataCheckSagas';
import applicationCreationSagas from './applicationCreationSagas';
import auditSagas from './auditSagas';
import authSagas from './authSagas';
import automaticProducts from './automaticProductsSagas';
import banksSagas from './banksSagas';
import biometricVerificationSagas from './biometricVerificationSagas';
import clientUsersSagas from './clientUsers';
import commissionsSagas from './commissionsSagas';
import creditusSagas from './creditusSagas';
import customerSupportSagas from './customerSupportSagas';
import documentsSagas from './documentsSagas';
import existentClientSagas from './existentClientSagas';
import finalOffersSagas from './finalOffersSagas';
import forgotPasswordSagas from './forgotPasswordSagas';
import idVerificationSagas from './idVerificationSagas';
import invoicesSagas from './invoicesSagas';
import navigationSagas from './navigationSagas';
import offerMessagesSagas from './offerMessagesSagas';
import offersSagas from './offersSagas';
import passwordChangeSagas from './passwordChangeSagas';
import priceQuoteSagas from './priceQuoteSagas';
import productsSagas from './productsSagas';
import profileSettingsSagas from './profileSettings';
import promoterSagas from './promoterSagas';
import promotersClientsSagas from './promotersClientsSagas';
import requestsSagas from './requestsSagas';
import sendApplicationToClientSagas from './sendApplicationToClientSagas';
import sentrySagas from './sentrySagas';
import statsSagas from './statsSagas';
import toastNotificationsSagas from './toastNotificationsSagas';
import userManagementSagas from './userManagementSagas';
import vehiclesSagas from './vehiclesSagas';
import verificationModalSagas from './verificationModalSagas';
import zendeskSagas from './zendeskSagas';

const { NODE_ENV } = process.env;

const addUncaughtErrorsHandling = saga =>
  function*() {
    try {
      yield call(saga);
    } catch (e) {
      if (NODE_ENV === 'development') {
        console.error(e);
      } else {
        Sentry.captureException(e);
      }
    }
  };

const ifProduction = sagas => (NODE_ENV === 'production' ? sagas : []);

const instantiateSagas = sagas =>
  values(sagas).map(saga => addUncaughtErrorsHandling(saga)());

const applicationSagas = [
  ...values(accountCreationSagas),
  ...values(accountDataCheckSagas),
  ...values(applicationCreationSagas),
  ...values(auditSagas),
  ...values(authSagas),
  ...values(automaticProducts),
  ...values(banksSagas),
  ...values(clientUsersSagas),
  ...values(creditusSagas),
  ...values(customerSupportSagas),
  ...values(commissionsSagas),
  ...values(documentsSagas),
  ...values(existentClientSagas),
  ...values(finalOffersSagas),
  ...values(forgotPasswordSagas),
  ...values(idVerificationSagas),
  ...values(invoicesSagas),
  ...values(navigationSagas),
  ...values(offerMessagesSagas),
  ...values(offersSagas),
  ...values(passwordChangeSagas),
  ...values(priceQuoteSagas),
  ...values(productsSagas),
  ...values(profileSettingsSagas),
  ...values(promoterSagas),
  ...values(promotersClientsSagas),
  ...values(requestsSagas),
  ...values(statsSagas),
  ...values(toastNotificationsSagas),
  ...values(userManagementSagas),
  ...values(vehiclesSagas),
  ...values(verificationModalSagas),
  ...values(sendApplicationToClientSagas),
  ...values(biometricVerificationSagas),
  ...ifProduction(values(sentrySagas)),
  ...ifProduction(values(zendeskSagas)),
];

function* rootSaga() {
  yield all(instantiateSagas(applicationSagas));
}

export default rootSaga;
