export enum PromoterUserRoles {
  ADMIN = 'admin',
  FNI = 'f&i',
  SALESPERSON = 'salesperson',
  SUPERSALESPERSON = 'super-salesperson',
  SYSTEM = 'system',
  ACCOUNTANT = 'accountant',
}

export enum BankUserRoles {
  ACCOUNTANT = 'accountant',
  ADMIN = 'admin',
  ANALYST = 'analyst',
  LEGAL = 'legal',
  SYSTEM = 'system',
  SUPERANALYST = 'super-analyst',
}
