import { call, select, takeLatest } from 'redux-saga/effects';

import requestsActions from '../actions/requests';
import { isCreditusUser } from '../utils/permissions';
import runner from './runners';

function* fetchRequestsOffered() {
  const { userData }: { userData: IUserData } = yield select(
    (state: any) => state.auth
  );
  const url = isCreditusUser(userData)
    ? '/api/creditus/applications/offered'
    : '/api/requests/offered';
  yield call(runner, requestsActions.creators.fetchRequestsOffered, {
    method: 'GET',
    url,
  });
}

function* watchRequestsOfferedFetchRequested() {
  yield takeLatest(
    requestsActions.types.REQUESTS_OFFERED_FETCH.REQUESTED,
    fetchRequestsOffered
  );
}

function* fetchRequestRisk(action: IReduxAction<{ requestId: number }>) {
  yield call(runner, requestsActions.creators.fetchRequestRisk, {
    method: 'GET',
    url: `/api/request/${action.payload.requestId}/risk-profile`,
  });
}

function* watchRequestRiskFetchRequested() {
  yield takeLatest(
    requestsActions.types.REQUEST_RISK_PROFILE_FETCH.REQUESTED,
    fetchRequestRisk
  );
}

function* fetchApplicationDetails(
  action: IReduxAction<{ applicationId: number }>
) {
  yield call(runner, requestsActions.creators.fetchApplicationDetails, {
    method: 'GET',
    url: `/api/application/${action.payload.applicationId}`,
  });
}

function* watchApplicationDetailsFetchRequested() {
  yield takeLatest(
    requestsActions.types.APPLICATION_DETAILS_FETCH.REQUESTED,
    fetchApplicationDetails
  );
}

export default {
  watchRequestsOfferedFetchRequested,
  watchRequestRiskFetchRequested,
  watchApplicationDetailsFetchRequested,
};
