const accountCreationFormErrors = {
  name: {
    required: 'El nombre es un campo obligatorio.',
  },
  firstSurname: {
    required: 'El primer apellido es un campo obligatorio.',
  },
  secondSurname: {
    required: 'El segundo apellido es un campo obligatorio.',
  },
  dateOfBirth: {
    required: 'La fecha de nacimiento es un campo obligatorio.',
    test: 'Solo ofrecemos nuestros servicios a mayores de edad.',
    testBigger: 'La fecha de nacimiento no es válida.',
    typeError: 'La fecha de nacimiento no es válida.',
  },
  sex: {
    required: 'El sexo es un campo obligatorio.',
  },
  countryOfBirth: {
    required: 'El país de nacimiento es un campo obligatorio.',
  },
  nationality: {
    required: 'La nacionalidad es un campo obligatorio.',
  },
  stateOfBirth: {
    required: 'El estado de nacimiento es un campo obligatorio.',
  },
  curp: {
    length: 'El CURP no es válido.',
    required: 'El CURP es un campo obligatorio.',
  },
  taxPayerId: {
    length: 'El RFC no es válido.',
    required: 'El RFC es un campo obligatorio.',
  },
  taxPayerIdComplement: {
    matches: 'La homoclave no es válida.',
  },
  password: {
    min: 'La contraseña debe de tener por lo menos 8 caracteres.',
    required: 'La contraseña es un campo obligatorio.',
  },
  passwordConfirmation: {
    min: 'La contraseña debe de tener por lo menos 8 caracteres.',
    oneOf: 'Las contraseñas deben de coincidir.',
    required: 'La confirmación de contraseña es un campo obligatorio.',
  },
  acceptsTermsAndConditionsAndPrivacyPolicy: {
    test:
      'Es necesario que aceptes los Términos y condiciones y el Aviso de privacidad.',
  },
};

export default accountCreationFormErrors;
