import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AccountCreationView from '../AccountCreationView';
import ContinueApplicationView from '../ContinueApplicationView';
import MobileBiomertricVerification from '../MobileBiomertricVerification';
import NewApplicationView from '../NewApplicationView';
import ProfilingView from '../ProfilingView';
import withUserData from '../UserDataHOC';

const NewApplicationRoutes = () => {
  const [applicationStarted, setApplicationStarted] = useState(false);
  return (
    <Switch>
      <Route
        component={() => (
          <ContinueApplicationView
            setApplicationStarted={setApplicationStarted}
          />
        )}
        path="/application/continue"
      />
      <Route
        component={() => (
          <ProfilingView applicationStarted={applicationStarted} />
        )}
        path="/application/profiling"
      />
      <Route
        component={() => (
          <AccountCreationView applicationStarted={applicationStarted} />
        )}
        path="/application/account"
      />
      <Route
        component={() => (
          <NewApplicationView applicationStarted={applicationStarted} />
        )}
        path="/application/new"
      />
      <Route
        component={withUserData(({ history }) => (
          <MobileBiomertricVerification
            applicationStarted={setApplicationStarted}
            history={history}
          />
        ))}
        path="/application/biometric-verification"
      />
      <Redirect from="*" to="/application/continue" />
    </Switch>
  );
};

export default NewApplicationRoutes;
