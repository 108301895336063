import identity from 'ramda/src/identity';
import React from 'react';

import FormikNumber from './FormikNumber';

interface FormikSimpleNumber {
  label: string;
  name: string;
  warning?: boolean;
  allowNegative?: boolean;
}

const FormikSimpleNumber: React.FunctionComponent<FormikSimpleNumber> = ({
  label,
  name,
  warning = false,
  allowNegative = true,
}) => (
  <FormikNumber<number, number>
    formatValue={identity}
    label={label}
    name={name}
    parseValue={value => Number.parseFloat(value.replace(/[,$]/g, ''))}
    thousandSeparator={true}
    allowNegative={allowNegative}
    warning={warning}
  />
);

export default FormikSimpleNumber;
