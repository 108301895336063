import { handleActions } from 'redux-actions';

const initialState = {
  userData: null,
};

const customerSupportReducer = handleActions(
  {
    CREDITUS_FETCH_USERS_COMPLETED: (state, { payload }) => ({
      ...initialState,
      userData: payload[0],
    }),
    CREDITUS_UPDATE_USER_COMPLETED: state => initialState,
  },
  initialState
);

export default customerSupportReducer;
